import { Controller } from 'react-hook-form';
import { Label, Input, SingleSelect, StyledModalRowEnd } from '@shared/components';
import { object, func, number, bool } from 'prop-types';
import { RichTextEditor } from '@components/RichTextEditor';

import PollDeadline from './PollDeadline';
import { PollAnswers } from './PollAnswers';
import { PollTargetSection } from './PollTargetSection/PollTargetSection';
import PollTypeRadioButtons from './PollTypeRadioButtons';

// TODO: define pollData
CreatePollModalBody.propTypes = {
  control: object,
  register: func,
  chatId: number,
  getValues: func,
  setValue: func,
  isSurvey: bool,
};

export function CreatePollModalBody({ control, register, chatId, getValues, setValue, pollData, isSurvey }) {
  return (
    <>
      <div>
        <Label htmlFor="name">
          Название голосования <sup>*</sup>
        </Label>
        <Input id="name" register={register('title')} />
      </div>

      {isSurvey && (
        <div>
          <Label htmlFor="name">Текст сообщения к голосованию</Label>
          <Controller
            control={control}
            name="survey_text"
            render={({ field }) => <RichTextEditor {...field} ref={null} />}
          />
        </div>
      )}

      <div>
        <Label htmlFor="type">Тип опроса</Label>
        <Controller
          control={control}
          name="anonymous"
          render={({ field }) => {
            return <PollTypeRadioButtons value={field.value} onChange={field.onChange} />;
          }}
        />
      </div>

      <StyledModalRowEnd justify="space-between" wrap="wrap">
        <div>
          <Label>Вариантов ответа</Label>
          <Controller
            name="multianswer"
            control={control}
            render={({ field }) => {
              return (
                <SingleSelect
                  value={field.value}
                  onChange={(v) => field.onChange(v)}
                  options={[
                    { label: 'Один', value: 0 },
                    { label: 'Несколько', value: 1 },
                  ]}
                />
              );
            }}
          />
        </div>

        <div>
          <Label>Время на ответы</Label>
          <Controller
            name="deadline_type"
            control={control}
            render={({ field }) => {
              return (
                <SingleSelect
                  value={field.value}
                  onChange={(v) => field.onChange(v)}
                  options={[
                    { label: 'Не ограничено', value: 'unlimited' },
                    { label: 'Ограничено', value: 'limited' },
                  ]}
                />
              );
            }}
          />
        </div>
      </StyledModalRowEnd>
      <PollDeadline control={control} />

      <PollAnswers control={control} register={register} getValues={getValues} setValue={setValue} />

      <PollTargetSection
        chatId={chatId}
        control={control}
        getValues={getValues}
        setValue={setValue}
        pollData={pollData}
      />
    </>
  );
}
