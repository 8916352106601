import styled from 'styled-components';

export const StyledPollResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledPollBody = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: max-content 1fr 40px;
  gap: 0 8px;
  position: relative;

  @media (min-width: 576px) {
    grid-template-columns: max-content max-content 1fr 40px;
    gap: 8px;
    align-items: center;
  }
`;

export const StyledPollVotes = styled.span`
  color: ${({ theme }) => theme.dark};
  min-width: 60px;
  font-size: 12px;
  grid-row: 1 / 2;
  grid-column: 2 / 3;

  @media (min-width: 576px) {
    font-size: 14px;
    grid-column: auto;
    grid-row: auto;
  }
`;

export const StyledPollText = styled.span`
  grid-row: 2 / 3;
  grid-column: 2 / -1;
  margin-top: -4px;
  display: flex;
  align-items: center;

  @media (min-width: 576px) {
    grid-column: auto;
    grid-row: auto;
    margin-top: 0;
  }
`;

export const StyledRefusedText = styled.div`
  position: relative;
  text-align: left;
`;
