import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  min-width: 153px;
  position: relative;
  width: 100%;
`;

export const DropdownInput = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  min-height: 42px;
  padding: 8px 28px 8px 8px;
  position: relative;
  width: 100%;

  &:after {
    border-bottom: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    content: '';
    height: 6px;
    pointer-events: none;
    position: absolute;
    right: 13px;
    top: 45%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
    width: 6px;
  }

  ${({ $show }) =>
    $show &&
    `&:after{
        top: 43%;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }
    `}
`;

export const StyledDropdown = styled.div`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
  position: relative;

  ${({ $show }) =>
    $show &&
    `
    max-height: 270px;
    opacity: 1;
    overflow-y: auto;
    transition: all 0.2s ease-in-out;
    `}
`;

export const List = styled.ul`
  overflow-x: auto;

  ul {
    margin-left: 20px;
  }
`;

export const ListItem = styled.li`
  min-width: 100%;
  position: relative;
  width: -webkit-max-content;
  width: max-content;
`;

export const ItemLabel = styled.span`
  border-radius: 12px;
  color: #262626;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  padding: 12px 12px 12px 35px;
  position: relative;
  transition: all 0.2s;
  white-space: nowrap;
  background-color: ${({ $highlighted }) => ($highlighted ? '#bae7ff' : 'unset')};

  &:hover {
    background-color: #bae7ff;
  }

  &:before {
    background-image: url(${({ $icon }) => $icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 8px 8px;
    border-radius: 10px;
    box-sizing: content-box;
    content: '';
    height: 16px;
    left: 10px;
    padding: 5px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
    width: 16px;
  }

  ${({ $customStylesFunc, ...rest }) => typeof $customStylesFunc === 'function' && $customStylesFunc(rest)}
`;
