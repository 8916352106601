export const errorTypes = {
  DUPLICATE: 'DUPLICATE',
  NETWORK: 'NETWORK',
  REFERENCE_FILES: 'REFERENCE_FILES',
  REFERENCE_MESSAGES: 'REFERENCE_MESSAGES',
  UNKNOWN: 'UNKNOWN',
  NO_PARENT_FOLDER: 'NO_PARENT_FOLDER',
};

export const processResponseErrors = (error) => {
  if (error?.response) {
    const response = error.response;
    // const sql = error.response.data?.error?.sql;
    const sqlMessage = error.response.data?.error?.sqlMessage;
    if (response.status === 500 && response.data?.error?.code === 'ER_DUP_ENTRY') {
      return errorTypes.DUPLICATE;
    } else if (
      response.status === 500 &&
      response.data?.error?.code?.includes('REFERENCE') &&
      sqlMessage?.includes('chat_messages')
    ) {
      return errorTypes.REFERENCE_MESSAGES;
    } else if (
      response.status === 500 &&
      response.data?.error?.code?.includes('REFERENCE') &&
      sqlMessage?.includes('employee_files')
    ) {
      return errorTypes.REFERENCE_FILES;
    } else if (response.status === 400 && response.data.message === 'Parent folder does not exist') {
      return errorTypes.NO_PARENT_FOLDER;
    } else {
      return errorTypes.UNKNOWN;
    }
  } else {
    return errorTypes.NETWORK;
  }
};
