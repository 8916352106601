import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useMatch } from 'react-router-dom';
import axios from 'axios';
import { url_get_emplyee_info } from 'src/settings/base-url';

import EmployeeDismissal from '../employees/dismissal/EmployeeDismissal';
import NotFoundModal from '../NotFoundModal';

import UserProfileModal from './UserProfileModal';

const UserProfileModalContainer = ({ profileId, onClose }) => {
  const routeMatchDepartments = useMatch('/team/departments/:id');
  const routeMatchUsers = useMatch('/team/users/:id');
  // получение id автоматически с двух url - с  team departments и users
  const userId = useMemo(() => {
    if (profileId) return profileId;
    return routeMatchDepartments?.params.id || routeMatchUsers?.params.id;
  }, [routeMatchDepartments, routeMatchUsers, profileId]);

  const [dismissalModalType, setDismissalModalType] = useState();

  const [employeeData, setEmployeeData] = useState(null);
  const [notFound, setNotFound] = useState(false);

  // инфа для построения модалки профиля
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = useCallback(() => {
    if (userId) {
      setIsLoading(true);
      setNotFound(false);
      axios.get(`${url_get_emplyee_info}/${userId}`).then(
        (res) => {
          if (!Array.isArray(res.data?.employeeInfo?.employee) || !res.data.employeeInfo.employee[0]) {
            setNotFound(true);
            return;
          }
          setEmployeeData(res.data.employeeInfo);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  }, [userId]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <>
      {notFound && <NotFoundModal onClose={onClose} />}

      {!notFound && (
        <>
          {dismissalModalType && (
            <EmployeeDismissal
              close={() => setDismissalModalType(false)}
              employeeData={employeeData}
              getUserData={getUserData}
              toEdit={dismissalModalType === 'edit'}
            />
          )}

          {!dismissalModalType && (
            <UserProfileModal
              profileId={profileId}
              onClose={onClose}
              setDismissalModalType={setDismissalModalType}
              employeeData={employeeData}
              isLoading={isLoading}
              userId={userId}
              getUserData={getUserData}
            />
          )}
        </>
      )}
    </>
  );
};

export default UserProfileModalContainer;
