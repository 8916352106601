import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const StyledToastContainer = styled(ToastContainer)`
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 150;
  max-height: calc(100vh - 80px);
  width: 350px;
  max-width: 98vw;
  padding: 0;

  .Toastify__toast-body {
    padding: 0;
  }

  @media (max-width: 480px) {
    left: unset;

    .Toastify__toast {
      overflow: auto;
    }
  }
`;
