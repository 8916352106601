import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { Button } from '@shared/components';

import { configurePushSubscription, PushLocalStorageTypes, unsubscribeBrowserPush } from './lib';

function PushNotificationPermission() {
  const [isSubscribed, setIsSubscribed] = useState();
  const [isLoading, setIsLoading] = useState();

  //#region проверка push подписки
  useEffect(() => {
    setIsLoading(true);
    if (
      !('serviceWorker' in navigator) ||
      !('PushManager' in window) ||
      window.Notification?.permission !== 'granted'
    ) {
      setIsLoading(false);
      return;
    }
    const pushLocalStorageRecord = localStorage.getItem(PushLocalStorageTypes.key);
    if (pushLocalStorageRecord !== PushLocalStorageTypes.subscribed) {
      setIsLoading(false);
      return;
    }

    const storedUserSubscriptionsPromise = axios.get('/api/user_push_notifications');

    navigator.serviceWorker.ready
      .then((serviceWorker) => serviceWorker.pushManager.getSubscription())
      .then(async (subscription) => {
        if (!subscription) return;
        const response = await storedUserSubscriptionsPromise;
        const isSubscribed = response.data.pushNotifications.some(
          (serverSubscription) => serverSubscription.endpoint === subscription?.endpoint,
        );
        if (!isSubscribed) return;
        setIsSubscribed(true);
      })
      .catch(() => showSnackbar('Не удалось проверить подписки на push уведомления'))
      .finally(() => setIsLoading(false));
  }, []);
  //#endregion

  const { showSnackbar } = useContext(SnackbarContext);

  // #region запрос разрешения
  const promptPushNotificationPermission = useCallback(async () => {
    if (window.Notification.permission === 'granted') {
      await configurePushSubscription(setIsSubscribed, showSnackbar, setIsLoading);
      return;
    }

    window.Notification.requestPermission(function (result) {
      if (result !== 'granted') return;
      else configurePushSubscription(setIsSubscribed, showSnackbar, setIsLoading);
    });
  }, [configurePushSubscription]);
  // #endregion

  //#region отписка push
  const handlePushUnsubscribe = useCallback(() => {
    axios.delete('api/push_notifications/unsubscribe').catch(() => {
      showSnackbar('Возникла ошибка при попытке отключения push уведомлений');
    });

    unsubscribeBrowserPush()
      .then(() => {
        setIsSubscribed(false);
        localStorage.removeItem(PushLocalStorageTypes.key);
      })
      .catch(() => showSnackbar('Возникла ошибка при попытке отключения push уведомлений'));
  });
  //#endregion

  return (
    <>
      {'serviceWorker' in navigator && 'PushManager' in window && window.Notification?.permission !== 'denied' && (
        <Button
          color={isSubscribed ? 'light' : 'green'}
          onClick={isSubscribed ? handlePushUnsubscribe : promptPushNotificationPermission}
          disabled={isLoading}
        >
          {isLoading ? 'загрузка...' : isSubscribed ? 'Отключить push уведомления' : 'Включить push уведомления'}
        </Button>
      )}
    </>
  );
}

export default PushNotificationPermission;
