import styled, { css } from 'styled-components';

export const StyledPinnedContainer = styled.section`
  border-top: 1px solid ${({ theme }) => theme.borderSecondary};
  padding-top: 16px;
  position: relative;
  height: 50px;
  border-top: none;
  padding-top: 0;

  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border-top: none;
      padding-top: 0;
    `};

  & .swiper {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 0;
    min-width: 0;
    position: relative;
  }

  & .swiper-wrapper {
    padding-left: 16px;
  }

  & .swiper-pagination {
    left: 0;
    right: auto;
  }

  & .swiper-pagination-bullet {
    opacity: 1;
    border-radius: 0;
    background-color: ${({ theme }) => theme.mainLight};
    transform: scale(1);
    margin: 2px 0 !important;
    width: 2px;
    height: 8px;
  }

  & .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.main};
  }
`;

export const StyledPinnedMessage = styled.li`
  display: flex;
  align-items: center;
`;

export const StyledPinnedText = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
