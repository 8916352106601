import {Modal} from "@shared/components";
import {CatalogFile, CatalogFileName, StorageList, StyledIcon} from "@components/storage/styles";
import FileIcon from "@assets/images/icons/icon-file.svg";

const ReplaceFilesConfirmModal = ({filesToReplace, close, confirm}) => {
  return (
    <Modal
      onSave={confirm}
      onClose={close}
      title="Файлы с таким названием уже есть в этой папке. Заменить текущие файлы новыми?"
      confirmButtonText="Заменить"
    >
        <StorageList>
          {filesToReplace.map((fileName, i) => (
            <CatalogFile key={i}>
              <StyledIcon $fileUrl={FileIcon} />
              <CatalogFileName style={{gridColumn: "1/-1"}}>{fileName}</CatalogFileName>
            </CatalogFile>
          ))}
        </StorageList>
    </Modal>
  );
};

export default ReplaceFilesConfirmModal;
