import { useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import EmployeeSingleSelect from '@components/employees/EmployeeSingleSelect';
import { Modal } from '@shared/components/Modal/Modal';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import useAuth from 'src/hooks/useAuth';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { getFormatCalendarData } from '@shared/components/CalendarForm/CalendarForm';
import { Checkbox } from '@shared/components/Checkbox/Checkbox';
import ConfirmAction from '@components/warnings/ConfirmAction';

import VacationDateSelectController from './VacationDateSelectController';

const schema = yup.object({
  employee: yup
    .object()
    .nullable()
    .when('action', {
      is: 'create',
      then: () => yup.object().required(),
    }),
  paid: yup.boolean().required(),
  dateStart: yup.date().required(),
  dateEnd: yup.date().required(),
});

const VacationRequestModal = ({ onClose, action, getVacationsList }) => {
  const auth = useAuth();
  const { showSnackbar } = useContext(SnackbarContext);

  const [accessibleEmployees, setAccessibleEmployees] = useState([]);

  useEffect(() => {
    if (action !== 'create') return;
    axios
      .get('/api/subordinate_employees')
      .then((r) => setAccessibleEmployees(r.data.employees))
      .catch(() => showSnackbar('Возникла ошибка при получении списка работников и подчиненных'));
  }, [action]);

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    getValues,
    register,
    // watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      action: action,
      dateStart: null,
      dateEnd: null,
      paid: true,
      employee: null,
    },
    mode: 'onChange',
  });

  const [promptClose, setPromptClose] = useState();

  const handleClose = useCallback(() => {
    if (isDirty) return setPromptClose(true);
    onClose();
  }, [isDirty]);

  const [isSubmitting, setIsSubmitting] = useState();

  const requestVacation = (reqBody) => {
    axios
      .post('/api/employee_vacation_request/add', reqBody)
      .then(() => {
        showSnackbar('Запрос отправлен', 'success');
        onClose();
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при отправке запроса');
        setIsSubmitting(false);
      });
  };

  const createVacation = (reqBody) => {
    axios
      .post('/api/subordinate_employee_vacation/add', reqBody)
      .then(() => {
        onClose();
        if (getVacationsList) getVacationsList();
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при создании отпуска');
        setIsSubmitting(false);
      });
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const reqBody = {
      row: {
        creator_id: auth?.user?.id,
        date_from: getFormatCalendarData(data.dateStart),
        date_to: getFormatCalendarData(data.dateEnd),
        paid: data.paid,
      },
    };
    if (action === 'create') {
      reqBody.row.employee_id = data?.employee?.value;
      reqBody.row.approved_by_id = auth?.user?.id;
      reqBody.row.approved = 'yes';
      createVacation(reqBody);
      return;
    } else {
      reqBody.row.employee_id = auth?.user?.id;
    }
    requestVacation(reqBody);
  };

  return (
    <>
      <Modal
        type="submit"
        modalSize="767px"
        title={action === 'create' ? 'Отправить в отпуск' : 'Запрос на отпуск'}
        confirmButtonText={action === 'create' ? 'Одобрить' : 'Отправить'}
        onSave={handleSubmit(onSubmit)}
        onClose={handleClose}
        disabledSaveButton={isValid === false || isSubmitting}
      >
        <form>
          <StyledModalRowEnd>
            {action === 'create' && (
              <EmployeeSingleSelect
                control={control}
                specifiedEmployees={accessibleEmployees}
                name="employee"
                showPosition
                showDepartment
              />
            )}
            <Checkbox id="paid-vacation" register={{ ...register('paid') }}>
              Оплачиваемый
            </Checkbox>
          </StyledModalRowEnd>
          <div>
            <StyledModalRowEnd>
              <VacationDateSelectController
                control={control}
                name="dateStart"
                placeholder="Дата начала"
                restraintName="dateEnd"
              />
              <VacationDateSelectController
                control={control}
                name="dateEnd"
                placeholder="Дата окончания"
                restraintName="dateStart"
              />
            </StyledModalRowEnd>
          </div>
        </form>
      </Modal>

      {promptClose && (
        <ConfirmAction
          actionText="Уверены что хотите закрыть окно, без сохранения изменений?"
          confirm={onClose}
          cancel={() => setPromptClose(false)}
        />
      )}
    </>
  );
};

export default VacationRequestModal;
