import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {CatalogFileAuthor} from "@components/storage/styles";

const useRenderUserName = (setUserProfileId) => {
  const users = useSelector((state) => state.users.employees);

  const renderUserName = useCallback(
    (creatorId) => {
      if (!creatorId) return null;
      const creator = users.find((user) => user.id === creatorId);
      let userName = '';
      if (creator) {
        userName = `${creator.first_name || ''} ${creator.last_name || ''}`;
      }

      return (
        <CatalogFileAuthor
          onClick={(e) => {
            e.stopPropagation();
            if (setUserProfileId) setUserProfileId(creatorId);
          }}
          onKeyUp={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter') {
              if (setUserProfileId) setUserProfileId(creatorId);
            }
          }}
          role="menuitem"
          tabIndex={0}
        >
          {userName}
        </CatalogFileAuthor>
      );
    },
    [users, setUserProfileId],
  );

  return renderUserName;
};

export default useRenderUserName;
