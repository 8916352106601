import { Button } from '@shared/components';
import { memo, useState } from 'react';

import plusIcon from '@assets/images/icons/plus.svg';

import { DepartmentSelectModal } from './DepartmentSelectModal';

export const AddDepartmentButton = memo(AddDepartmentButtonFC);

function AddDepartmentButtonFC({ departments, addDepartments }) {
  const [departmentSelectModal, setDepartmentSelectModal] = useState(false);

  return (
    <>
      <Button size="sm" position="left" icon={plusIcon} onClick={() => setDepartmentSelectModal(true)}>
        Добавить по отделам
      </Button>

      {departmentSelectModal && (
        <DepartmentSelectModal
          departments={departments}
          onClose={() => setDepartmentSelectModal(false)}
          addDepartments={addDepartments}
        />
      )}
    </>
  );
}
