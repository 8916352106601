import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {StorageButtonBack, StorageList} from "@components/storage/styles";
import iconArrow from "@assets/images/icons/icon-arrow-back.svg";

import { files_url } from '../../settings/base-url';

import FileDetailsRow from './FileDetailsRow';
import FolderDetailsRow from './FolderDetailsRow';


const FolderVersionDetails = ({
  folderVersionDetails,
  renderUserName,
  setFolderVersionDetails,
  parentFolderStack,
  setParentFolderStack,
}) => {
  const { storageId } = useParams();

  const folderPath = useMemo(() => {
    let path = `${files_url}/storages/versions/folders/${storageId}/${parentFolderStack[0].id}`;
    for (const parent of parentFolderStack) {
      path += `/${parent.title}`;
    }
    return path;
  }, [parentFolderStack, storageId]);

  return (
      <div>
        <StorageButtonBack
          icon={iconArrow}
          onClick={() => {
            // убрать из стопки папок-родителей самую последнюю и открыть предыдущую папку-родитель
            const newParentsStack = [...parentFolderStack];
            newParentsStack.pop();
            const lastIndex = newParentsStack.length - 1;
            setParentFolderStack(newParentsStack);
            setFolderVersionDetails(newParentsStack[lastIndex]);
          }}
        >
          На уровень выше
        </StorageButtonBack>
      <StorageList>
        {folderVersionDetails.folders.map((folder, i) => (
          <FolderDetailsRow
            key={i}
            renderUserName={renderUserName}
            folder={folder}
            modifiedFolderOpen={() => {
              setFolderVersionDetails(folder);
              setParentFolderStack((p) => [...p, folder]);
            }}
            versionsModal
          />
        ))}
      </StorageList>
      <StorageList>
        {folderVersionDetails.files.map((fileData, i) => (
          <FileDetailsRow
            key={i}
            fileData={fileData}
            renderUserName={renderUserName}
            versionsModal
            folderPath={folderPath}
          />
        ))}
      </StorageList>
    </div>
  );
};

export default FolderVersionDetails;
