import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
// import { createLogger } from 'redux-logger';

import usersReducer from './features/usersSlice';
import tagsReducer from './features/tagsSlice';
import projectsReducer from './features/projectsSlice';
import messagesReducer from './features/messagesSlice';
import storageReducer from './features/storageSlice';
import favoritesSlice from './features/favoritesSlice';
import appVersionsReducer from './features/appVersionsSlice';
import notificationsSlice from './features/notificationsSlice';
import { rtkQueryApi } from './features/api/rtkQueryApi';
import ticketsReducer from './features/ticketsSlice';
import remindersSlice from './features/remindersSlice';

const middlewares = [rtkQueryApi.middleware];

// if (process.env.NODE_ENV === 'development') middlewares.push(createLogger({ collapsed: true }));

export const store = configureStore({
  reducer: {
    users: usersReducer,
    tags: tagsReducer,
    projects: projectsReducer,
    messages: messagesReducer,
    storage: storageReducer,
    favorites: favoritesSlice,
    appVersions: appVersionsReducer,
    ticketsForMe: ticketsReducer,
    notifications: notificationsSlice,
    allReminders: remindersSlice,
    [rtkQueryApi.reducerPath]: rtkQueryApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middlewares),
});

setupListeners(store.dispatch);
