import {css, styled} from 'styled-components';
import { Text, Title } from '@shared/components';

export const Wrapper = styled.div`
  margin: 0 -24px;
  padding: 24px;
  background-color: ${({ theme }) => theme.light};
  display: grid;
  row-gap: 24px;
`;



export const StyledSubheader = styled.div`
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate || '1fr 1fr 1fr 1fr'};
  align-items: center;
  gap: 24px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledContactsHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 24px;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding: 32px 0;
`;

export const StyledTitle = styled(Title)`
  padding: 0;
`;

export const TagIndicator = styled.div`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: ${({ color }) => color};
`;

export const StyledImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 4px;
  align-self: flex-start;
  grid-row: 1/5;

  @media (min-width: 1150px) {
    width: 140px;
    height: 100px;
    grid-row: auto;
    align-self: center;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: ${({ src }) => (src ? 'none' : '#FAFAFA')};
  border-radius: 4px;
`;

export const StyledContactName = styled.p`
  font-weight: 500;
  word-break: break-word;
`

export const StyledText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  color: ${({ theme }) => theme.dark};
  width: 100%;
  padding: 10px;
  text-align: center;
`;

export const StyledContactCard = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  padding: 12px 24px;
  margin: 0 -24px;
  gap: 12px 24px;
  transition: all 0.3s;
  align-items: start;
  cursor: pointer;

  &:hover {
    background-color: rgba(186, 231, 255, 0.5);
  }

  @media (min-width: 1150px) {
    grid-template-columns: 140px 2fr 2fr 2fr;
    gap: 24px;
    align-items: center;
  }
`;

export const StyledTagsWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: flex-start;

  @media (min-width: 1150px) {
    flex-direction: column;
  }
`;

export const StyledTagsFilter = styled.div`
  @media (min-width: 992px) {
    grid-column: span 2;
  }
`;
