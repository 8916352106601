import { Label } from '@shared/components';
import { Radio } from '@shared/components/Radio/Radio';
import React from 'react';
import { radioButtonsType } from 'src/pages/PageLinks/EditLinkModal/lib';

export const AccessRadioButtons = ({ selectedRadio, setSelectedRadio, onReset }) => {
  const radioOptions = [
    {
      id: 1,
      label: 'Всем',
      name: 'all',
      value: radioButtonsType.all,
      checked: selectedRadio === radioButtonsType.all,
      onChange: () => {
        onReset();
        setSelectedRadio(radioButtonsType.all);
      },
    },
    {
      id: 2,
      label: 'По отделам',
      name: 'departments',
      value: radioButtonsType.departments,
      checked: selectedRadio === radioButtonsType.departments,
      onChange: () => {
        onReset();
        setSelectedRadio(radioButtonsType.departments);
      },
    },
    {
      id: 3,
      label: 'По пользователям',
      name: 'users',
      value: radioButtonsType.users,
      checked: selectedRadio === radioButtonsType.users,
      onChange: () => {
        onReset();
        setSelectedRadio(radioButtonsType.users);
      },
    },
  ];

  return (
    <div>
      <Label>Доступность</Label>
      <Radio items={radioOptions} />
    </div>
  );
};
