import { useState } from 'react';
import { useSelector } from 'react-redux';
import {styled} from "styled-components";

import useAuth from '../../hooks/useAuth';
import { files_url } from '../../settings/base-url';
import GuestModalCurrent from '../../windows/profile/GuestModalCurrent';
import UserProfileModalContainer from '../../windows/profile/UserProfileModalContainer';
import { StyledProfileUserpic } from '../../windows/profile/style';

const UserStatus = ({
  userId,
  type,
  showProfileOnClick,
  firstName,
  lastName,
  projectData, // используется для показа гостевых профилей
  projectType, // используется для показа гостевых профилей
  projectId, // используется для показа гостевых профилей
  is_guest, // используется для показа гостевых профилей
}) => {
  const auth = useAuth();
  const author = useSelector((state) => state.users.employees?.find((employee) => employee.id === +userId));

  const [userProfileModal, toggleUserProfileModal] = useState(false);
  const [guestProfileModal, toggleGuestProfileModal] = useState(false);


  return (
    <>
      <StyledProfileUserpic>
        {author?.avatar ? (
          <img src={`${files_url}/employees/avatar/${author.id}/${author.avatar}?token=${auth.token}`} alt="userpic" />
        ) : (
          <StyledWithoutPicture>
            {firstName && firstName[0]}
            {lastName && lastName[0]}
          </StyledWithoutPicture>
        )}
      </StyledProfileUserpic>

      {userProfileModal && (
        <UserProfileModalContainer profileId={userId} onClose={() => toggleUserProfileModal(false)} />
      )}

      {guestProfileModal && (
        <GuestModalCurrent
          dataType={projectType}
          dataId={projectId}
          guestId={userId}
          projectData={projectData}
          close={() => toggleGuestProfileModal(false)}
        />
      )}
    </>
  );
};

const StyledWithoutPicture = styled.div`
  background-color: #dbdada;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  height: 212px;
  width: 212px;
  font-size: 60px;
`

export default UserStatus;
