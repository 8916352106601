import styled, {css} from "styled-components";

export const StyledModal = styled.section`
  position: absolute;
  bottom: 144px;
  right: 24px;
  background-color: ${({theme}) => theme.lightest};
  box-shadow: ${({ theme }) => theme.shadowModal};
  border: 1px solid ${({ theme }) => theme.borderSecondary};
  width: 300px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  
  ${props => props.$show && css`
    opacity: 1;
    visibility: visible;
    bottom: 148px;
  `}
`

export const StyledForwardButton = styled.span`
  background-color: ${({theme}) => theme.lightest};
  display: inline-block;
  font-weight: 500;
  padding: 40px;
  position: absolute;
  bottom: 40px;
  right: 24px;
  font-size: 16px;
  line-height: 22px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &::after {
    background-image: url(${(props) => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 8px;
    position: absolute;
    right: 10px;
    top: 51%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    width: 20px;
  }

  &:hover {
    color: ${({ theme }) => theme.main};

    &::after {
      filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
      right: 5px;
    }
  }
  
  ${props => props.$active && css`
    color: ${({theme}) => theme.main};

    &::after {
      filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
      right: 5px;
    }
  }
  `}
`;