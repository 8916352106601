import { Text, Title } from '@shared/components';
import { styled } from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding: 32px 0;
`;

export const StyledTable = styled.section`
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr max-content;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.light};

  @media (min-width: 768px) {
    font-size: 14px;
    grid-template-columns: repeat(3, 1fr) max-content;
  }
`;

export const StyledTableHeader = styled.p`
  display: none;

  @media (min-width: 768px) {
    border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
    background-color: ${({ theme }) => theme.light};
    font-weight: 600;
    font-size: 16px;
    padding: 16px;
    display: block;
  }
`;

export const StyledMobileHeader = styled.p`
  font-weight: 500;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledButtonWrapper = styled.div`
  grid-column: -1/-2;
  grid-row: 1/3;
  padding: 8px 16px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;

  @media (min-width: 768px) {
    padding: 16px;
  }
`;

export const StyledRow = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
  align-items: start;
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.light};
  }

  &:hover ${StyledButtonWrapper} {
    opacity: 1;
    visibility: visible;
  }

  @media (min-width: 768px) {
    align-items: center;
  }
`;

export const StyledCell = styled.div`
  word-break: break-all;
  padding: 8px 16px;

  @media (min-width: 768px) {
    padding: 16px;
  }
`;

export const StyledTitle = styled(Title)`
  padding: 0;
`;

export const StyledText = styled(Text)`
  font-weight: 600;
`;
