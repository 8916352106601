import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  favorites: null,
  isLoading: false,
};

export const getFavorites = createAsyncThunk('favorites/getFavorites', async (payload, thunkAPI) => {
  try {
    const response = await axios.get('/api/projects_tasks_starred');
    return response.data.projectsTasksStarred;
  } catch (e) {
    payload.showSnackbar('Возникла ошибка при загрузке избранных');
    return thunkAPI.rejectWithValue('ERROR WHILE FETCHING FAVORITES');
  }
});

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    removeFavoriteFromState: (state, { payload }) => {
      state.favorites = state.favorites.filter((favored) => favored.id !== payload);
    },
  },
  extraReducers: {
    [getFavorites.pending]: (state) => {
      state.isLoading = true;
    },
    [getFavorites.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.favorites = payload;
    },
    [getFavorites.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { removeFavoriteFromState } = favoritesSlice.actions;

export default favoritesSlice.reducer;
