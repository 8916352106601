import { useState, useMemo, useContext } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { number, object, string, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { Input } from '@shared/components/Input/Input';
import { Subtitle } from '@shared/components/Subtitle/Subtitle';
import StorageType from '@components/storage/StorageType';
import SelectMulty from 'src/components/form/select/SelectMulty';
import {
  formatReactSelectEmployeesOptions,
  formatReactSelectTagsOptions,
  useEventTriggerOnEscPress,
} from 'src/utilize/helper-functions';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { Text } from '@shared/components';

const formSchema = object({
  title: string().required(),
  type: string().oneOf(['independent', 'attached']).required(),
  description: string().nullable(true),
  project_id: number().nullable(true),
  storage_tags: array().of(object()),
  viewers: array().of(object()),
  writers: array().of(object()),
});

const CreateStorageModal = ({ close, editData, getData, getStoragesList }) => {
  const [modalClosePrompt, setModalClosePrompt] = useState();
  const navigate = useNavigate();

  const {
    register,
    control,
    // watch,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      type: 'independent',
    },
  });

  // установить первоначальные данные, если хранилище редактируется
  useEffect(() => {
    if (editData) {
      const { storage } = editData;
      reset({
        title: storage.title,
        type: storage.project_id ? 'attached' : 'independent',
        description: storage.description,
        project_id: storage.project_id,
        storage_tags: storage.storage_tags.map((t) => ({
          label: t.name,
          value: t.name,
          id: t.id,
        })),
        writers: storage.storage_writers.map((w) => ({
          label: `${w.last_name || ''} ${w.first_name || ''}`,
          value: `${w.last_name || ''}${w.first_name || ''}`,
          id: w.id,
        })),
        viewers: storage.storage_viewers.map((w) => ({
          label: `${w.last_name || ''} ${w.first_name || ''}`,
          value: `${w.last_name || ''}${w.first_name || ''}`,
          id: w.id,
        })),
      });
    }
  }, [editData, reset]);

  const handleCloseBtnClick = () => {
    if (modalClosePrompt) return;
    if (isDirty) {
      return setModalClosePrompt(true);
    }
    close();
  };

  useEventTriggerOnEscPress(handleCloseBtnClick);

  const employees = useSelector((state) => state.users.employees);
  const tags = useSelector((state) => state.tags.tagsList);

  // данные для Select компонента writers
  const writers = useMemo(
    () => ({
      name: 'writers',
      label: '',
      type: 'select',
      optionsType: 'tags',
      options: formatReactSelectEmployeesOptions(employees) || [],
    }),
    [employees],
  );
  // данные для Select компонента viewers
  const viewers = useMemo(
    () => ({
      name: 'viewers',
      label: '',
      type: 'select',
      optionsType: 'tags',
      options: formatReactSelectEmployeesOptions(employees) || [],
    }),
    [employees],
  );

  // данные для Select компонента tags
  const tagsData = useMemo(
    () => ({
      name: 'storage_tags',
      label: '',
      // placeholder:'Выбрать тэги',
      // label: t(`${translateKey}.viewers_role`),
      type: 'select',
      subType: 'tag',
      options: formatReactSelectTagsOptions(tags) || [],
    }),
    [tags],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const submitData = (data) => {
    const reqBody = {
      row: {
        title: data.title,
        description: data.type === 'independent' ? data.description : null,
        project_id: data.type === 'attached' ? data.project_id : null,
      },
      storage_tags: data.storage_tags?.map((tag) => ({ tag_id: tag.id })),
      writers: data.writers?.map((w) => ({ employee_id: w.id })),
      viewers: data.viewers?.map((v) => ({ employee_id: v.id })),
    };
    setIsSubmitting(true);
    if (editData) {
      reqBody.storage_id = editData.storage.id;
      axios
        .put('/api/storages/edit', reqBody)
        .then(() => {
          if (getData) getData(editData.storage.id);
          close();
        })
        .catch(() => {
          setIsSubmitting(false);
          showSnackbar('Возникла ошибка при редактировании');
        });
      return;
    }

    axios
      .post('/api/storages/add', reqBody)
      .then((r) => {
        if (getStoragesList) getStoragesList();
        close();
        navigate(`/storages/${r.data.result}`);
      })
      .catch(() => {
        setIsSubmitting(false);
        showSnackbar('Возникла ошибка при создании');
      });
  };

  return (
    <Modal
      modalSize="1200px"
      title={`${editData ? 'Редактировать' : 'Создать'} хранилище`}
      onClose={handleCloseBtnClick}
      confirmButtonText={`${editData ? 'Сохранить' : 'Создать'}`}
      disabledSaveButton={!isDirty || !isValid || isSubmitting}
      onSave={handleSubmit(submitData)}
    >
      <div>
        <Label htmlFor="name">
          Название <sup>*</sup>
        </Label>
        <Input id="name" register={register('title')} />
      </div>

      <StorageType control={control} register={register} editData={editData} />

      <div>
        <Subtitle>Кто может записывать</Subtitle>
        <SelectMulty item={writers} control={control} options={writers.options} />
      </div>

      <div>
        <Subtitle>Кто видит</Subtitle>
        <Text size={1}>
          Чтобы сделать закрытое хранилище, добавьте сюда тех, кто сможет его видеть, если поле останется пустым, то
          хранилище будет видно всем сотрудникам
        </Text>
        <SelectMulty item={viewers} control={control} options={viewers.options} />
      </div>

      <div>
        <Subtitle>Тэги</Subtitle>
        <SelectMulty item={tagsData} control={control} options={tagsData.options} />
      </div>

      {modalClosePrompt && (
        <ConfirmAction
          confirm={close}
          cancel={() => setModalClosePrompt(false)}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
    </Modal>
  );
};

export default CreateStorageModal;
