import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {StyledChatMenu, StyledChatMenuHeader, StyledChatMenuItem} from "@components/chat/styles";
import {Text} from "@shared/components";
import {StyledTag} from "@components/chat/message/styles";

const SelectTagPopover = ({ tagsList, chatOption, tagsOptionRef, selectedTags, setSelectedTags, isMenuMobile }) => {
  const { t } = useTranslation();
  const onTagSelect = (tagToAdd) => {
    const isSelected = selectedTags.find((tag) => tag.id === tagToAdd.id);
    if (isSelected) return;
    setSelectedTags((t) => [...t, tagToAdd]);
  };

  return (
    <StyledChatMenu
      $active={chatOption === 'tags'}
      ref={tagsOptionRef}
      style={ isMenuMobile? {left: '130px', bottom: 0, maxHeight: '250px', overflowY: 'auto'} : {maxHeight: '250px', overflowY: 'auto'}}
    >
      <StyledChatMenuHeader>
        <Text size={3}>{t('common.tags')}</Text>
      </StyledChatMenuHeader>

      {tagsList?.map((tag, i) => {
        return (
          <StyledChatMenuItem key={i} onClick={() => onTagSelect(tag)}>
            <StyledTag color={tag.color}>{tag.name}</StyledTag>
          </StyledChatMenuItem>
        );
      })}
    </StyledChatMenu>
  );
};

export default memo(SelectTagPopover);
