import { string, number } from 'prop-types';

import { StyledImageContainer, StyledRadioLabel } from './styles';

CustomRadioLabel.propTypes = {
  label: string,
  id: number,
  image: string,
};

export function CustomRadioLabel({ label, id, image }) {
  return (
    <StyledRadioLabel htmlFor={id}>
      {image && (
        <StyledImageContainer>
          <img src={image} alt="option" />
        </StyledImageContainer>
      )}{' '}
      {label}
    </StyledRadioLabel>
  );
}
