import { useCallback, useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

import SnackbarContext from 'src/contexts/SnackbarContext';
import {NavControlsButton, NavControlsItem} from "@components/nav/styles";
import iconSearch from "@assets/images/icons/search.svg";

import { SearchModal } from './SearchModal';
import { searchTypes } from './lib';


const formSchema = yup.object({
  searchType: yup.number().required(),
  search_expression: yup.string().nullable(),
  creator_id: yup.object().nullable(),
  responsible_id: yup.object().nullable(),
  executor_id: yup.object().nullable(),
  member_id: yup.object().nullable(),
  employee_id: yup.object().nullable(),
  tags: yup.array().nullable(),
});

export const defReactSelectValue = { label: 'Выбрать из списка', value: { id: null } };

const SearchProjectsNav = () => {
  const [searchModal, setSearchModal] = useState();

  const methods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      searchType: 1,
      search_expression: '',
      creator_id: defReactSelectValue,
      responsible_id: defReactSelectValue,
      executor_id: defReactSelectValue,
      member_id: defReactSelectValue,
      employee_id: defReactSelectValue,
      tags: [],
    },
  });

  const handleClose = useCallback(() => setSearchModal(false), []);

  const [isLoading, setIsLoading] = useState();
  const [searchResults, setSearchResults] = useState();

  // прокрутить к результатам поиска, после рендера результатов
  useEffect(() => {
    if (searchResults) {
      const searchResultsTitle = document.getElementById('search_results_title');
      if (searchResultsTitle) searchResultsTitle.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [searchResults]);

  const { showSnackbar } = useContext(SnackbarContext);

  const triggerSubmit = useCallback(() => {
    methods.handleSubmit((data) => {
      const getUrlString = () => {
        if (data.searchType === searchTypes.projectOrTask) {
          return '/api/search_projects_tasks_by_title';
        }

        if (data.searchType === searchTypes.message) {
          return '/api/search_projects_tasks_by_message';
        }

        if (data.searchType === searchTypes.fileInChat) {
          return '/api/search_in_chats';
        }

        return '/api/search_in_storages';
      };

      const url = getUrlString();

      setIsLoading(true);
      axios
        .patch(url, {
          search_expression: data.search_expression,
          creator_id: data.creator_id?.value.id,
          responsible_id: data.responsible_id?.value.id,
          executor_id: data.executor_id?.value.id,
          member_id: data.member_id?.value.id,
          employee_id: data.employee_id?.value.id,
          tags: data.tags.map((t) => t.id),
        })
        .then((res) => {
          setSearchResults({ searchType: data.searchType, ...res.data.result });
        })
        .catch(() => showSnackbar('Возникла ошибка при запросе данных'))
        .finally(() => setIsLoading(false));
    })();
  }, [methods?.handleSubmit]);

  return (
    <>
      <NavControlsItem>
        <NavControlsButton $search icon={iconSearch} onClick={() => setSearchModal(true)} />
      </NavControlsItem>
      {searchModal && (
        <SearchModal
          methods={methods}
          triggerSubmit={triggerSubmit}
          onClose={handleClose}
          isLoading={isLoading}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
        />
      )}
    </>
  );
};

export default SearchProjectsNav;
