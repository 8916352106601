import styled from 'styled-components';
import { Button } from '@shared/components';

export const StyledRadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;

  & img {
    max-height: 80px;
    max-width: 100px;
    border-radius: 2px;
  }
`;

export const StyledImageContainer = styled.span`
  width: 100px;
  flex: 0 0 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StyledPollVoteButton = styled(Button)`
  margin-bottom: 12px;
`;
