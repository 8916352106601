import styled from 'styled-components';

export const StyledPollDate = styled.p`
  color: ${({ theme }) => theme.dark};
  font-size: 12px;
  line-height: 20px;
`;

export const StyledPollQuestion = styled.p`
  font-weight: 600;
`;

export const StyledPollForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledPollChart = styled.svg`
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: 45px;
  flex: 0 0 45px;
  grid-row: 1 / 3;
  grid-column: 1 / 2;

  @media (min-width: 576px) {
    grid-column: auto;
    grid-row: auto;
  }
`;

export const StyledPollFooter = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  gap: 16px;
`;

export const StyledPollCounter = styled.span`
  background-color: ${({ theme }) => theme.light};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  padding: 1px 8px;
  width: max-content;
  font-size: 12px;
  line-height: 20px;
`;

export const StyledPollCancelButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.main};
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  font-size: 12px;
  line-height: 20px;

  &:hover {
    text-decoration: none;
  }
`;
