import { rtkQueryApi } from './rtkQueryApi';

export const surveysApi = rtkQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query({
      query: () => '/api/surveys',
      providesTags: ['Surveys'],
      transformResponse: (response) => {
        if (Array.isArray(response.surveys)) {
          return {
            surveys: response.surveys.reverse(),
          };
        }
        return response;
      },
    }),

    createSurvey: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/surveys/add',
        method: 'POST',
      }),
      invalidatesTags: ['Surveys'],
    }),

    editSurvey: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/surveys/edit',
        method: 'PUT',
      }),
      invalidatesTags: ['Surveys'],
    }),

    deleteSurvey: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/surveys/delete',
        method: 'DELETE',
      }),
      invalidatesTags: ['Surveys'],
    }),
  }),
  tagTypes: ['Surveys'],
});

export const { useGetSurveysQuery, useCreateSurveyMutation, useEditSurveyMutation, useDeleteSurveyMutation } =
  surveysApi;
