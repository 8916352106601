import { memo } from 'react';
import { func, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { StyledChatMenuItem } from '@components/chat/styles';
import { toggleMessageSelection } from 'src/redux/features/messagesSlice';

SelectMessageMenuItemFC.propTypes = {
  toggleDotsMenu: func,
  messageId: number,
};

function SelectMessageMenuItemFC({ toggleDotsMenu, messageId }) {
  const dispatch = useDispatch();
  const isSelected = useSelector((state) => state.messages.selectedMessageIds.find((id) => id === messageId));

  return (
    <StyledChatMenuItem
      onClick={() => {
        dispatch(toggleMessageSelection(messageId));
        toggleDotsMenu(false);
      }}
    >
      {isSelected ? 'Снять выбор' : 'Выбрать'}
    </StyledChatMenuItem>
  );
}

const SelectMessageMenuItem = memo(SelectMessageMenuItemFC);

export default SelectMessageMenuItem;
