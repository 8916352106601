import DateInput from '@shared/components/DateInput/DateInput';
import { SingleSelect } from '@shared/components/Select/Select';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {styled} from "styled-components";

export const TeamFilters = () => {
  const { departments, isLoading } = useSelector((state) => state.users);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  const queryParams = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);

  const departmentOptions = useMemo(
    () => departments?.map((department) => ({ label: department.title, value: department.id })),
    [departments],
  );

  useEffect(() => {
    setSearchParams({});
  }, []);

  return (
    <FiltersWrapper>
      <SingleSelect
        placeholder="Отдел"
        options={departmentOptions}
        onChange={(e) => {
          e?.value
            ? setSearchParams({ ...queryParams, department: e.value })
            : setSearchParams({ ...queryParams, department: null });
        }}
        isClearable={true}
      />

      <DateInput
        placeholder="Дата с"
        onChange={(date) => {
          setDateFrom(date);
          setSearchParams({ ...queryParams, from: date ? moment(date).format('YYYY[-]MM[-]DD') : null });
        }}
        selected={dateFrom}
        dateFormat="P"
      />

      <DateInput
        placeholder="Дата по"
        onChange={(date) => {
          setDateTo(date);
          setSearchParams({ ...queryParams, to: date ? moment(date).format('YYYY[-]MM[-]DD') : null });
        }}
        selected={dateTo}
        dateFormat="P"
      />
    </FiltersWrapper>
  );
};

const FiltersWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  
  & .css-b62m3t-container {
    width: 300px;
  }
  
  @media (max-width: 767px) {
    flex-direction: column;
    
    & > *,
    & .css-b62m3t-container {
      width: 100%;
    }
  }
`