import SelectMulty from 'src/components/form/select/SelectMulty';
import { CustomOption } from 'src/components/form/select/userSelectComponents';

const selectSettings = {
  label: '',
  name: 'poll_members',
};

const PollTarget = ({ control, employeeOptions }) => {
  return (
    <div>
      <SelectMulty
        item={selectSettings}
        control={control}
        options={employeeOptions}
        customOption={CustomOption}
        getOptionValue={(option) => option.value.id}
      />
    </div>
  );
};

export default PollTarget;
