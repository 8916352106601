import React, { useEffect, useState } from 'react';

import { Container, Icon, IconButton, Text } from '@shared/components';

import IconFolder from '@assets/images/icons/folder.svg';
import IconDots from '@assets/images/icons/subtask-dots.svg';

import { convertBytes, doubleClickHandle } from 'src/utilize/helper-functions';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';

import { Dropdown } from '@shared/components/Dropdown/Dropdown';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { useNavigate } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';

import useRenderUserName from '@components/storage/useRenderUserName';

import UserProfileModalContainer from 'src/windows/profile/UserProfileModalContainer';

import { server_url } from 'src/settings/base-url';

import FolderVersionsModal from '@components/storage/FolderVersionsModal';

import { StyledLink, StyledListItem } from '../../styles';

import { StyledMenu, StyledMenuItem, StyledRow, StyledText, StyledTextWrapper } from './styles';

export const CardFolder = ({ index, folder, onClose }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [folderVerisonsModal, setFolderVersionsModal] = useState(false);
  const [userProfileId, setUserProfileId] = useState();

  const renderUserName = useRenderUserName(setUserProfileId);
  const navigate = useNavigate();

  const auth = useAuth();

  const limit = 50;
  const getMegabytes = (size) => Number(size / 1024 / 1024).toFixed(2);

  const handleFolderDownload = () => {
    const a = document.createElement('a');
    a.href = `${server_url}/api/download_storage_folder/${folder.id}?token=${auth.token}`;
    a.download = `${folder.title}`;
    a.target = '_blank';
    a.rel = 'noreferrer';

    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  };

  const onConfirm = () => {
    handleFolderDownload();
    setConfirmModal(false);
  };

  const onClickHandler = () => {
    doubleClickHandle(
      () => {
        getMegabytes(folder.size) >= limit ? setConfirmModal(true) : handleFolderDownload();
      },
      () => {
        navigate(
          `/storages/${folder.storage_id || folder.root_storage.id}${
            folder.folder_id ? `/folder/${folder.folder_id}` : ''
          }`,
        );
        onClose();
      },
    );
  };

  useEffect(() => {}, []);
  return (
    <>
      <StyledListItem key={index}>
        <Container gap={'12px'}>
          <div>{index + 1}.</div>
          <Container direction={'column'} gap={'8px'} width={'100%'}>
            <Text size={3}>Хранилище: {folder?.root_storage.title}</Text>
            <StyledRow>
              <StyledLink target="_blank" rel="noreferrer">
                <Container
                  onClick={(e) => {
                    e.preventDefault();
                    onClickHandler();
                  }}
                  align={'center'}
                  gap={'10px'}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon icon={IconFolder} size={20} />
                  <StyledTextWrapper>{folder?.title}</StyledTextWrapper>
                </Container>
              </StyledLink>

              <StyledText>{convertBytes(folder?.size)}</StyledText>
              <StyledText>{formatDateWithDots(folder?.data_update)}</StyledText>
              <StyledText>
                {folder?.creator_first_name} {folder?.creator_last_name}
              </StyledText>
              <Dropdown
                overlay={
                  <StyledMenu>
                    <StyledMenuItem
                      onClick={() => {
                        navigate(
                          `/storages/${folder.storage_id || folder.root_storage.id}${
                            folder.folder_id ? `/folder/${folder.folder_id}` : ''
                          }`,
                        );
                        onClose();
                      }}
                    >
                      Перейти в хранилище
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={() => {
                        getMegabytes(folder.size) >= limit ? setConfirmModal(true) : handleFolderDownload();
                      }}
                    >
                      Скачать
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => setFolderVersionsModal(true)}>История версий</StyledMenuItem>
                  </StyledMenu>
                }
              >
                <IconButton icon={IconDots} />
              </Dropdown>
            </StyledRow>
          </Container>
        </Container>
      </StyledListItem>

      {folderVerisonsModal && (
        <FolderVersionsModal
          currentFolderData={folder}
          renderUserName={renderUserName}
          close={() => {
            setFolderVersionsModal(false);
          }}
        />
      )}

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onConfirm}
          actionText={`Вы уверены, что хотите скачать папку? Она весит ${convertBytes(folder?.size)}`}
          confirmButtonText={'Да'}
          cancelButtonText={'Нет'}
          isSuccess
        />
      )}

      {userProfileId && <UserProfileModalContainer profileId={userProfileId} onClose={() => setUserProfileId(null)} />}
    </>
  );
};
