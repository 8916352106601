import { useContext, useRef, useState, memo } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ReactComponent as FolderIcon } from 'src/assets/images/icons/folder.svg';
import { ReactComponent as IconDelete } from 'src/assets/images/icons/delete-circle.svg';
import { Container } from '@shared/components';
import ThreeDotsLoader from '@components/preloaders/ThreeDotsLoader';
import SnackbarContext from 'src/contexts/SnackbarContext';
import StorageModal from 'src/windows/storage/StorageModal';

import { StyledAttachItem } from './styles';

// background-image: url(../assets/images/icons/delete-circle.svg);
const DeleteBtn = styled.button`
  background-color: transparent;
  border: none;
  display: inline-flex;
  padding: 0;

  &:hover {
    -webkit-filter: brightness(0) saturate(100%) invert(20%) sepia(99%) saturate(4859%) hue-rotate(349deg)
      brightness(102%) contrast(92%);
    filter: brightness(0) saturate(100%) invert(20%) sepia(99%) saturate(4859%) hue-rotate(349deg) brightness(102%)
      contrast(92%);
  }
`;

const FolderLink = ({ removable, folderData, handleDelete }) => {
  const [isLoading, setIsLoading] = useState();
  const [storageModal, setStorageModal] = useState();
  const storageId = useRef(folderData?.storage_id);

  const { showSnackbar } = useContext(SnackbarContext);

  const handleNavigate = () => {
    if (isLoading) return;
    if (storageId.current) {
      setStorageModal(true);
      return;
    }
    setIsLoading(true);
    axios
      .get(`/api/check_storage_folder/${folderData?.storage_folder_id}`)
      .then((res) => {
        if (res.data.folder_exist === false) showSnackbar('Папка не существует или была удалена');
        else if (res.data.access === false) showSnackbar('У вас нет прав для просмотра этой папки');
        else {
          storageId.current = res.data.storage_id;
          setStorageModal(true);
        }
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div style={{ marginRight: '5px' }}>
        <StyledAttachItem>
          <Container align="center" gap="6px">
            <FolderIcon style={{cursor: "pointer"}} width="18" height="18" onClick={handleNavigate} />
            <span style={{cursor: "pointer"}} onClick={handleNavigate}>
              {folderData?.title}
            </span>
            {!isLoading && removable && (
              <DeleteBtn onClick={() => handleDelete(folderData)}>
                <IconDelete height="15px" width="15px" />
              </DeleteBtn>
            )}
            {isLoading && <ThreeDotsLoader visible height={15} width={15} />}
          </Container>
        </StyledAttachItem>
      </div>

      {storageModal && (
          <StorageModal
            noURLParams
            close={() => setStorageModal(false)}
            storageId={storageId.current}
            folderId={folderData.storage_folder_id}
          />
      )}
    </>
  );
};

export default memo(FolderLink);
