import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from 'src/assets/images/logo.svg';

import {Header, HeaderBurger, HeaderLogo, HeaderLogoWrapper, HeaderNav} from "@components/nav/styles";

import UserNavLists from './user-nav-list';
import HeaderSiteNavList from './header-site-nav-list';
import useOfflineListener from './OfflineListener/useOfflneListener';
// import OfflineListener from './OfflineListener/OfflineLIstener';

const Nav = ({ popupWindow }) => {
  const [navListOpen, setNavListOpen] = useState(true);
  const [mobilMenu, setMobilMenu] = useState(false);

  const handlerMobilMenu = (value) => {
    if (!value) {
      setMobilMenu(!mobilMenu);
      setNavListOpen(false);
    } else {
      setMobilMenu(false);
    }
  };

  const handlerSelectList = (value) => {
    value === navListOpen ? setNavListOpen(false) : setNavListOpen(value);
  };

  // для закрытия списка при клике на пустую область
  const handlerBlur = () => {
    if (!mobilMenu) setNavListOpen(false);
  };

  useOfflineListener();

  return (
    <>
      {/* <OfflineListener /> */}
      <Header>
        <HeaderNav>
          <HeaderLogoWrapper>
            <HeaderBurger
              $active={mobilMenu}
              onClick={() => handlerMobilMenu()}
            >
              <span></span>
            </HeaderBurger>
            <Link to="/" style={{width: '100px'}}>
              <HeaderLogo src={Logo} alt="Лого компании" />
            </Link>
          </HeaderLogoWrapper>

          {/* навигационная панель */}
          <HeaderSiteNavList
            popupWindow={popupWindow}
            mobilMenu={mobilMenu}
            navListOpen={navListOpen}
            setNavListOpen={setNavListOpen}
            handlerSelectList={handlerSelectList}
            handlerBlur={handlerBlur}
            handlerMobilMenu={handlerMobilMenu}
          />

          {/* избранные , прошлые действия, уведомления  */}
          <UserNavLists
            handler={handlerSelectList}
            handlerBlur={handlerBlur}
            isOpen={navListOpen}
            popupWindow={popupWindow}
          />
        </HeaderNav>
      </Header>
    </>
  );
};

export default Nav;
