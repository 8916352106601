import { StyledLayout } from '@shared/components/Layout/styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import Preloader from '@components/preloaders/Preloader';

import { useDeleteLinkMutation, useGetLinksQuery } from 'src/redux/features/api/links';

import Trash from '@assets/images/icons/delete.svg';

import { IconButton } from '@shared/components';
import { ButtonWithIcon } from '@shared/components/ButtonWithIcon/ButtonWithIcon';
import { Placeholder } from '@shared/components/Placeholder';
import iconAdd from '@assets/images/icons/plus.svg';

import SnackbarContext from 'src/contexts/SnackbarContext';

import { useSearchParams } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';

import { files_url } from 'src/settings/base-url';

import { AddLinkModal } from './AddLinkModal';
import {
  HeaderWrapper,
  StyledButtonWrapper,
  StyledCell,
  StyledMobileHeader,
  StyledRow,
  StyledTable,
  StyledTableHeader,
  StyledTitle,
} from './styles';
import { EditLinkModal } from './EditLinkModal';
import { RemoveLinkModal } from './RemoveLinkModal';

export const PageLinks = () => {
  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [deleteLinkId, setDeleteLinkId] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const { data, isLoading } = useGetLinksQuery();
  const [deleteLink, { isError }] = useDeleteLinkMutation();
  const { showSnackbar } = useContext(SnackbarContext);

  const { token, isUserRightful, rightTypes } = useAuth();

  const linkId = useMemo(() => searchParams.get('id'), [searchParams]);

  const hasRulesLinksEdit = isUserRightful(rightTypes.links_edit);

  useEffect(() => {
    if (!!linkId) {
      setEditModalVisible(true);
    }

    if (!!deleteLinkId) {
      setRemoveModalVisible(true);
    }
  }, [linkId, deleteLinkId]);

  return (
    <>
      <StyledLayout>
        {!hasRulesLinksEdit ? (
          <Placeholder text={'У вас нет прав для просмотра данной страницы'} />
        ) : (
          <>
            <HeaderWrapper>
              <StyledTitle>Ссылки</StyledTitle>
              {hasRulesLinksEdit && (
                <ButtonWithIcon color="green" position="left" icon={iconAdd} size={20} onClick={() => setVisible(true)}>
                  Добавить
                </ButtonWithIcon>
              )}
            </HeaderWrapper>

            {isLoading ? (
              <Preloader />
            ) : (
              <StyledTable>
                <StyledTableHeader>Наименование</StyledTableHeader>
                <StyledTableHeader>Ссылка</StyledTableHeader>
                <StyledTableHeader>Видимость</StyledTableHeader>
                <StyledTableHeader></StyledTableHeader>
                {data.map((item) => (
                  <StyledRow key={item.id} onClick={() => setSearchParams({ id: item.id }, { replace: true })}>
                    <StyledCell>
                      <StyledMobileHeader>Наименование</StyledMobileHeader>
                      <p>{item.title}</p>
                    </StyledCell>
                    <StyledCell>
                      <StyledMobileHeader>Ссылка</StyledMobileHeader>
                      <p>
                        <u>
                          <a
                            onClick={(e) => e.stopPropagation()}
                            href={
                              item.type === 'file'
                                ? `${files_url}/links/files/${item.id}/${item.link}?token=${token}`
                                : `${item?.link.indexOf('http') === 0 ? item.link : `//${item.link}`}`
                            }
                            rel="noopener noreferrer"
                            target="blank"
                          >
                            {item.link}
                          </a>
                        </u>
                      </p>
                    </StyledCell>

                    <StyledCell>
                      <StyledMobileHeader>Видимость</StyledMobileHeader>
                      <p>{item.is_public ? 'Видна всем' : 'Приватная'}</p>
                    </StyledCell>

                    <StyledButtonWrapper>
                      {hasRulesLinksEdit && (
                        <IconButton
                          size={18}
                          icon={Trash}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteLinkId(item.id);
                          }}
                        />
                      )}
                    </StyledButtonWrapper>
                  </StyledRow>
                ))}
              </StyledTable>
            )}
          </>
        )}
      </StyledLayout>

      {visible && <AddLinkModal onClose={() => setVisible(false)} />}
      {editModalVisible && hasRulesLinksEdit && (
        <EditLinkModal
          linkId={linkId}
          onClose={() => {
            setEditModalVisible(false);
            setSearchParams([]);
          }}
        />
      )}
      {removeModalVisible && hasRulesLinksEdit && (
        <RemoveLinkModal
          onSave={() => {
            deleteLink({ link_id: deleteLinkId }).then(() => {
              showSnackbar('Ссылка успешно удалена', 'success');
              setDeleteLinkId('');
              setRemoveModalVisible(false);
            });
          }}
          onClose={() => {
            setDeleteLinkId('');
            setRemoveModalVisible(false);
          }}
        />
      )}
    </>
  );
};
