import Resumable from 'resumablejs';
import useAuth from 'src/hooks/useAuth';
import { server_url } from 'src/settings/base-url';

import { useContext } from 'react';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { getFileNameWithoutExtension, getQueryData } from './lib';

export const useResumableForFolders = ({ storageId, folderId, setFolderUploadStatus, isLoadingCompleteRef }) => {
  const { token } = useAuth();
  const { showSnackbar } = useContext(SnackbarContext);

  const queryData = getQueryData({ storageId, folderId });

  const resumable = new Resumable({
    target: `${server_url}/api/storage_folder/add?token=${token}`,
    testTarget: `${server_url}/api/storage_folder/test_chunk?token=${token}`,
    testChunksParams: { ...queryData },
    chunkSize: 2 * 1024 * 1024,
    throttleProgressCallbacks: 100,
    query: ({ fileName }, chunk) => {
      return { ...queryData, title: getFileNameWithoutExtension(fileName) };
    },
    simultaneousUploads: 1,
  });

  resumable.on('fileProgress', (currentFile) => {
    const currentProgress = parseInt(Math.round(currentFile.progress() * 100));

    setFolderUploadStatus((files) =>
      files.map((file) => {
        if (getFileNameWithoutExtension(currentFile.fileName) === file.name) {
          file.progress = currentProgress;
          file.uploadStatus = 'loading';
          return file;
        }
        return file;
      }),
    );
  });

  resumable.on('fileSuccess', (currentFile) => {
    const successChunk = currentFile.chunks.find((chunk) => {
      return String(chunk.message()).includes('insertId');
    });

    if (successChunk) {
      const insertId = JSON.parse(successChunk.message())?.insertId;

      setFolderUploadStatus((files) =>
        files.map((file) => {
          if (getFileNameWithoutExtension(currentFile.fileName) === file.name) {
            file.uploadStatus = 'done';
            file.localId = insertId;

            return file;
          }

          return file;
        }),
      );
    }
  });

  resumable.on('complete', function () {
    isLoadingCompleteRef.current.isFoldersLoading = true;
  });

  resumable.on('error', function (message, file) {
    showSnackbar('Возникла ошибка при загрузке папки', 'error');
  });

  return resumable;
};
