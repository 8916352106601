import React, {useState, useEffect, useMemo} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import axios from 'axios';

import {StyledLayout} from '@shared/components/Layout/styles';

import useAuth from 'src/hooks/useAuth';

import {url_get_employees_tree} from 'src/settings/base-url';

import Preloader from '@components/preloaders/Preloader';

import {useSocketContext} from 'src/contexts/SocketContext';

import {Team} from "../style";

import TeamTreeListItem from './ui/TeamTreeListItem';
import {StyledTeamDepartment, TeamTreeTitle, TeamTreeWrapper} from './styles';

const TeamTree = () => {
  const auth = useAuth();
  const {socket} = useSocketContext();

  const [treeList, setTreeList] = useState(null);

  const [filteredList, setFilteredList] = useState([]);

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentDepartment = useMemo(() => searchParams.get('department'), [searchParams]);

  useEffect(() => {
    currentDepartment
      ? setFilteredList(treeList?.filter((department) => department.id === Number(currentDepartment)))
      : setFilteredList(treeList);
  }, [currentDepartment, treeList]);

  // список сотрудников, вход в комнату
  useEffect(() => {
    axios.get(url_get_employees_tree).then((res) => {
      setTreeList(res.data.employeesTree);
    });

    if (socket) {
      socket.emit('add_to_room', 'employees_tree');

      socket.on('department_changed', (data, action) => {
        updateState();
      });

      socket.on('employee_changed', (data, action) => {
        updateState();
      });
    }

    return () => {
      if (socket) socket.emit('leave_room', 'employees_tree');
    };
  }, [auth.token, socket]);

  const updateState = () => {
    axios.get(url_get_employees_tree).then((res) => {
      setTreeList(res.data.employeesTree);
    });
  };

  return (
    <>
      {!treeList && <Preloader/>}
      <div>
        <TeamTreeWrapper>
          {filteredList?.map((i) => (
            <StyledTeamDepartment key={i.id}>
              <TeamTreeTitle>{i.title}</TeamTreeTitle>
              <ul>
                <TeamTreeListItem item={i} location={location}/>
              </ul>
            </StyledTeamDepartment>
          ))}
        </TeamTreeWrapper>
      </div>
    </>
  );
};

export default TeamTree;
