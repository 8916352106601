import { server_url } from 'src/settings/base-url';
import { number } from 'prop-types';

import { Button } from '@shared/components';
import useAuth from 'src/hooks/useAuth';
import { useCallback, useContext, useState } from 'react';
import ConfirmAction from '@components/warnings/ConfirmAction';
import SnackbarContext from 'src/contexts/SnackbarContext';

DownloadAllFilesButton.propTypes = {
  messageId: number,
};

export function DownloadAllFilesButton({ messageId }) {
  const auth = useAuth();

  const [confirmDownload, setConfirmDownload] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const handleDownload = useCallback(() => {
    if (!auth?.token) return;

    const a = document.createElement('a');
    a.style.setProperty('display', 'none');
    document.body.appendChild(a);
    a.href = `${server_url}/api/download_chat_message_files/${messageId}?token=${auth.token}`;
    a.target = '_blank';
    a.rel = 'noreferrer';
    a.click();
    a.remove();
    setConfirmDownload(false);
    showSnackbar('Загрузка начнется после архивации', 'success');
  }, [auth?.token]);

  if (!auth?.token) return null;
  return (
    <>
      <Button color="secondary" size="sm" onClick={() => setConfirmDownload(true)}>
        Скачать все файлы
      </Button>

      {confirmDownload && (
        <ConfirmAction
          cancel={() => setConfirmDownload(false)}
          confirm={handleDownload}
          actionText="Все файлы из этого сообщения будут загружены одним архивом. Вы уверены?"
          confirmButtonText="Да"
          cancelButtonText="Нет"
          isSuccess
        />
      )}
    </>
  );
}
