import { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ProjectStructureContext = createContext({
  selectedTask: null,
  setSelectedTask: null,
  selectedProject: null,
  setSelectedProject: null
});

const ProjectStructureContextProvider = ({ children }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const { taskId, projectId } = useParams();

  useEffect(() => {
    if (taskId) {
      setSelectedTask(+taskId);
    }
  }, [taskId]);

  useEffect(() => {
    if (projectId) {
      setSelectedProject(+projectId);
    }
  }, [projectId]);

  return (
    <ProjectStructureContext.Provider value={{
      selectedTask,
      setSelectedTask,
      selectedProject,
      setSelectedProject
    }}>
      {children}
    </ProjectStructureContext.Provider>
  );
};

const useProjectStructureContext = () => {
  const context = useContext(ProjectStructureContext);
  if (context) {
    return context;
  }
  throw new Error('useProjectStructureContext must be used within a ProjectStructureContextProvider');
};

export { ProjectStructureContextProvider, useProjectStructureContext };
