import styled from 'styled-components';

export const StyledModalGrid = styled.div`
  position: relative;
  display: grid;
  gap: 8px 20px;
  grid-template-columns: 1fr;
  align-items: start;

  @media (min-width: 800px) {
    align-items: center;
    grid-template-columns: ${(props) => props.grid};
  }
`;
export const StyledModalGridHeader = styled.div`
  font-weight: 500;
  display: none;

  @media (min-width: 800px) {
    display: contents;
  }
`;

export const StyledModalGridRow = styled.div`
  position: relative;

  @media (min-width: 800px) {
    display: contents;
  }
`;

export const StyledSeparator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
  grid-column: 1 / -1;
  margin: 2px 0;
`;

export const StyledModalGridCell = styled.div`
  align-items: center;
  display: grid;
  padding: 4px 0;
  text-align: left;

  @media (min-width: 800px) {
    text-align: ${(props) => props.align};
  }
`;

export const StyledModalGridLabel = styled.div`
  color: ${({ theme }) => theme.dark};
  font-weight: 500;
  margin-bottom: 4px;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const StyledModalGridButtons = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  display: flex;
  gap: 12px;
  margin-left: auto;
  transition: all 0.2s;

  @media (min-width: 800px) {
    align-items: center;
    position: static;
  }
`;

export const StyledModalGridCenter = styled.p`
  text-align: center;
`;
