import { StyledTag } from '@shared/components/Tag/styles';

const Tag = ({ tag, selectedTagId, onClick, as, $active }) => {
  return (
    <StyledTag as={as} color={tag.color} $active={selectedTagId === tag.id || $active} onClick={onClick}>
      {tag.name}
    </StyledTag>
  );
};

export default Tag;
