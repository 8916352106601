import styled from 'styled-components';
import { StyledStatus } from '@shared/components/Status/style';

export const StyledForwardedBox = styled.section`
  background-color: ${({ theme }) => theme.light};
  padding: 8px 16px;
  border-left: 1px solid ${({ theme }) => theme.main};
`;

export const StyledForwardedMessage = styled.div`
  padding: 16px 0;
  display: block;
`;

export const ForwardedStatus = styled(StyledStatus)`
  user-select: none;
  display: block;

  // &:hover {
  //   filter: brightness(95%);
  // }
`;
