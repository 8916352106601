import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoute from '../../routes/components/PrivateRoute';

import NewsBlogsList from './NewsBlogsList';
import SingleNewsBlog from './SingleNewsBlog';

const NewsRoutes = () => {
  // два роута для:
  // 1) для списка новостей  2) детализация новости
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <main>
              <NewsBlogsList />
            </main>
          </PrivateRoute>
        }
      />
      <Route
        path="/:newsBlogId"
        element={
          <PrivateRoute>
            <SingleNewsBlog />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default NewsRoutes;
