import React, { useState } from 'react';
import { Checkbox, Input, Label, Modal, SingleSelect, Text, Textarea } from '@shared/components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ConfirmAction from '@components/warnings/ConfirmAction';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';

import { colors } from 'src/variables';

import { useEditAdditionalFieldMutation } from 'src/redux/features/api/additionalInfo';

import { fieldTypes, formSchema, getTextareaValue, selectOptions } from './lib';

export const EditAdditionalFieldModal = ({ item, onClose }) => {
  const [modalClosePrompt, setModalClosePrompt] = useState();

  const [editField] = useEditAdditionalFieldMutation();

  const {
    control,
    register,
    handleSubmit,
    resetField,
    watch,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: item.name,
      type: selectOptions.filter((option) => option.value === item.type)[0],
      textarea: item.values ? getTextareaValue(item.values) : '',
      visible: !!item.include ? 'visible' : false,
    },
  });

  const handleCloseBtnClick = () => {
    if (modalClosePrompt) return;
    if (isDirty) return setModalClosePrompt(true);
    onClose();
  };

  const getEditFormData = (data) => ({
    rows: [
      {
        row: {
          name: data.title,
          include: data.visible === 'visible' ? 1 : 0,
          type: item.deletable ? data.type.value : undefined,
          values: data.type.value === fieldTypes.list && item.deletable ? data.textarea.split('\n') : undefined,
        },
        additional_info_id: item.id,
      },
    ],
  });

  const onSubmit = async (data) => {
    await editField(getEditFormData(data));

    onClose();
  };

  useEventTriggerOnEscPress(handleCloseBtnClick);

  return (
      <Modal
        modalSize="880px"
        onSave={handleSubmit(onSubmit)}
        onClose={handleCloseBtnClick}
        title={`Редактировать дополнительное поле`}
        confirmButtonText={'Сохранить'}
        disabledSaveButton={!isValid || !isDirty}
      >
        <div>
          <Label htmlFor="title">Название</Label>
          <Input id="title" error={!!errors['title']} register={register('title')} autoComplete="off" />
        </div>
        <div>
          <Label>Тип</Label>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <SingleSelect
                {...field}
                options={selectOptions}
                onChange={(e) => {
                  resetField('textarea');
                  field.onChange(e);
                }}
                isDisabled={!item.deletable}
              />
            )}
          />
        </div>

        {watch('type')?.value === fieldTypes.list && (
          <div>
            <Label>
              Значения{' '}
              <Text tag={'span'} color={colors.disabled} style={{ fontStyle: 'italic' }}>
                одна строка = одно значение
              </Text>
            </Label>
            <Textarea register={register('textarea')} disabled={!item.deletable} />
          </div>
        )}

        <div>
          <Checkbox id={'visible'} register={register('visible')} checked={watch('visible')}>
            Выводим в список
          </Checkbox>
        </div>

        {modalClosePrompt && (
          <ConfirmAction
            confirm={onClose}
            cancel={() => setModalClosePrompt(false)}
            actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
          />
        )}
      </Modal>
  );
};
