import {styled} from "styled-components";

export const StyledTeamList = styled.section`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  padding: 10px 0;
`

export const TeamCard = styled.section`
  background-color: ${({theme}) => theme.light};
  border: 1px solid ${({theme}) => theme.borderSecondary};;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  position: relative;
  transition: transform 0.2s ease-in-out;
  font-size: 12px;
  line-height: 20px;
`

export const TeamCardSlash = styled.span`
  margin: 0 8px;
`

export const TeamUserWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

export const TeamSocial = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const TeamSocialItem = styled.a`
  align-items: center;
  display: flex;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  
  &:hover {
    color: ${({theme}) => theme.main};
  }
`

export const TeamSocialIcon = styled.a`
  align-items: center;
  background-color: ${({theme}) => theme.lightest};
  border: 1px solid ${({theme}) => theme.borderSecondary};
  border-radius: 50%;
  display: inline-flex;
  flex: 0 0 32px;
  height: 32px;
  justify-content: center;
  position: relative;
  width: 32px;
  margin-right: 8px;
  
  &::after {
    content: '';
    height: ${props => props.size || 15}px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.size || 15}px;
    background-image: url(${props => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
`