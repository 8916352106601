// форма логина, входа и восстановления пароля
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Button } from '@shared/components';
import WarningBox from '@shared/components/WarningBox/WarningBox';
import FormField from '@components/form/FormField/FormField';
import { StyledAuth, StyledAuthForm, StyledAuthInfo, StyledAuthLink, StyledAuthTitle } from '@components/form/styles';
import { PushLocalStorageTypes, configurePushSubscription } from '@components/notifications/lib';
import useAuth from 'src/hooks/useAuth';
import { useSocketContext } from 'src/contexts/SocketContext';
import { getOnlineUsers, getUnavailableUsers } from 'src/redux/features/usersSlice';
import SnackbarContext from 'src/contexts/SnackbarContext';

import CheckEmailNotification from './check-email-notification';

const schemaForgot = yup.object().shape({
  email: yup.string().email().required(),
  identifier: yup.string().required(),
});

const schemaLogin = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string(),
  identifier: yup.string().required(),
});

const shemaRestorePassword = yup.object().shape({
  password: yup.string().required(),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать'),
});

const Form = ({ authTitle, authInfo, submitBtnValue, sendingBtnText, inputs, action, requestURL, resetPassword }) => {
  const [checkEmail, setCheckEmail] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { token } = useParams();
  let location = useLocation();

  const getDefValues = (items) => {
    let newObj = {};
    items.forEach((el) => {
      newObj[el.name] = '';
    });
    return newObj;
  };

  const findSchema = (typeOfForm) => {
    switch (typeOfForm) {
      case 'login':
        return schemaLogin;

      case 'forgot':
        return schemaForgot;

      case 'restorePassword':
        return shemaRestorePassword;

      default:
        return schemaLogin;
    }
  };

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(findSchema(action)),
    mode: 'onChange',
    defaultValues: getDefValues(inputs),
  });

  let from = location.state?.from?.pathname || '/';

  const [isSending, setIsSending] = useState(false);
  const [requestError, setRequestError] = useState('');

  const { socket, initSocket } = useSocketContext();
  const dispatch = useDispatch();

  const { showSnackbar } = useContext(SnackbarContext);

  const onSubmit = async (credentials) => {
    setIsSending(true);
    try {
      switch (action) {
        case 'login': {
          const { data } = await requestURL(credentials);
          auth.setUser({
            name: data.first_name || '',
            surname: data.last_name || '',
            id: data.user_id,
            company_id: data.company_id,
            rights: data.rights,
            is_guest: data.is_guest,
          });
          auth.setToken(data.token);

          // обновить онлайн статус
          if (!socket?.connected) initSocket(data.token);
          else {
            dispatch(getOnlineUsers());
            dispatch(getUnavailableUsers());
          }

          const pushSubscriptionLocalStorageRecord = localStorage.getItem(PushLocalStorageTypes.key);
          if (pushSubscriptionLocalStorageRecord === PushLocalStorageTypes.loggedOut) {
            configurePushSubscription();
          }

          navigate(from, { replace: true });
          break;
        }

        case 'forgot': {
          await requestURL(credentials);
          setCheckEmail(true);
          setIsSending(false);
          break;
        }
        case 'restorePassword': {
          // тут будет отправляться токен и пароль
          await requestURL({
            password: credentials.password,
            token,
          });
          break;
        }

        default:
          break;
      }
    } catch (e) {
      setIsSending(false);
      if (
        (e.response?.status === 400 && e.response?.data?.message === 'There is no such user') ||
        (e.response?.status === 401 && e.response?.data?.message === 'Password incorrect')
      ) {
        setRequestError('Идентификатор компании, логин или пароль указаны неверно');
      } else showSnackbar('Не удалось отправить запрос. Свяжитесь с администратором.');
    }
  };

  return (
    <>
      {checkEmail && <CheckEmailNotification />}
      <StyledAuth>
        <StyledAuthTitle>{authTitle}</StyledAuthTitle>
        <StyledAuthInfo>{authInfo}</StyledAuthInfo>

        {action === 'forgot' && (
          <StyledAuthInfo>
            Вспомнили данные аккаунта? <StyledAuthLink to="/login">Войти</StyledAuthLink>
          </StyledAuthInfo>
        )}

        <StyledAuthForm onSubmit={handleSubmit(onSubmit)}>
          {inputs.map((input, i) => (
            <FormField
              key={i}
              control={control}
              settings={input}
              name={input.name}
              action={action}
              resetPassword={resetPassword}
            />
          ))}

          {requestError && <WarningBox>{requestError}</WarningBox>}

          <Button width="100%" disabled={!isValid || isSending}>
            {isSending ? sendingBtnText : submitBtnValue}
          </Button>
        </StyledAuthForm>
      </StyledAuth>
    </>
  );
};

export default Form;
