import React from 'react';

import { Label } from '@shared/components';

import {StyledAuthLink} from "@components/form/styles";

import FormInput from './FormInput';

// поля для login и возможно department,  упростить позже
const FormField = (props) => {
  const { label, name } = props.settings;
  const { resetPassword } = props;
  return (
    <div>
      <Label htmlFor="phone">{label}</Label>
      <FormInput props={props} />

      {name === 'password' && resetPassword && (
        <StyledAuthLink to="/forgot">
          <span className=""> Забыли пароль?</span>
        </StyledAuthLink>
      )}
    </div>
  );
};

export default FormField;
