import { Label } from '@shared/components/Label/Label';
import { useWatch } from 'react-hook-form';

import { searchTypes } from '../../../lib';

import { StyledContainer } from './styles';
import SelectController from './SelectController';

export const SearchFilters = ({ control }) => {
  const searchType = useWatch({ control, name: 'searchType' });

  return (
    <>
      <StyledContainer
        $hide={
          searchType === searchTypes.message ||
          searchType === searchTypes.fileOrFolder ||
          searchType === searchTypes.fileInChat
        }
      >
        <div>
          <Label>Создатель</Label>
          <SelectController control={control} name="creator_id" />
        </div>
        <div>
          <Label>Ответственный</Label>
          <SelectController control={control} name="responsible_id" />
        </div>
        <div>
          <Label>Исполнитель</Label>
          <SelectController control={control} name="executor_id" />
        </div>
        <div>
          <Label>Участник</Label>
          <SelectController control={control} name="member_id" />
        </div>
      </StyledContainer>

      <StyledContainer
        $hide={
          searchType === searchTypes.projectOrTask ||
          searchType === searchTypes.fileOrFolder ||
          searchType === searchTypes.fileInChat
        }
      >
        <div>
          <Label>Автор сообщения</Label>
          <SelectController control={control} name="employee_id" />
        </div>
      </StyledContainer>

      <StyledContainer $hide={searchType === searchTypes.message || searchType === searchTypes.projectOrTask}>
        <div>
          <Label>Кто загрузил</Label>
          <SelectController control={control} name="creator_id" />
        </div>
      </StyledContainer>
    </>
  );
};
