import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {object} from "yup";
import axios from "axios";
import {useSelector} from "react-redux";

import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";

import {Label, Modal, SingleSelect} from "@shared/components";
import ConfirmAction from "@components/warnings/ConfirmAction";
import Preloader from "@components/preloaders/Preloader";
import {CustomOption, CustomValue, getOptions} from "@components/form/select/userSelectComponents";

import {useEventTriggerOnEscPress} from "../../../utilize/helper-functions";
import SnackbarContext from "../../../contexts/SnackbarContext";
import useAuth from "../../../hooks/useAuth";


const formSchema = object({
  executor: object().required(),
});

const ChangeExecutorModal = ({taskIdToEdit, getTaskData, close}) => {
  const {
    control,
    handleSubmit,
    formState: {isDirty, isValid},
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const {showSnackbar} = useContext(SnackbarContext);
  const [isSubmitting, setIsSubmitting] = useState();
  const [isLoadingEditData, setIsLoadingEditData] = useState();
  const auth = useAuth();
  const {t} = useTranslation();
  const employees = useSelector((state) => state.users.employees);
  const options = useMemo(() => getOptions({users: employees, token: auth.token}), [employees]);

  useEffect(() => {
    if (taskIdToEdit) {
      setIsLoadingEditData(true);
      axios
        .get(`/api/task_edit/${taskIdToEdit}`)
        .then((r) => {
          const taskData = r.data.result;
          reset({
            executor: options.filter((user) => user.value.id === Number(taskData.executor_id))[0],
          });
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при запросе данных на редактирование');
        })
        .finally(() => setIsLoadingEditData(false));
    }
  }, []);


  const submitData = (data) => {
    if (auth) {
      const submitData = {
        executor_id: data.executor?.value.id,
        task_id: taskIdToEdit,
      };

      setIsSubmitting(true);

      if (taskIdToEdit) {
        axios
          .patch('/api/change_task_executor', submitData)
          .then(() => {
            getTaskData();
            showSnackbar('Исполнитель изменен', 'success');
            close();
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при изменении исполнителя');
            setIsSubmitting(false);
          });
        return;
      }
    }
  };

  // данные для Select компонента executor
  const executor = useMemo(
    () => ({
      name: 'executor',
      label: '',
      type: 'select',
      subType: 'tag',
      options: employees || [],
      value: 'last_name',
      value2: 'first_name',
    }),
    [employees, t],
  );

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handleModalClose = useCallback(() => {
    if (isDirty) {
      setModalClosePrompt(true);
    } else close();
  }, [isDirty]);

  useEventTriggerOnEscPress(handleModalClose);

  return (
    <Modal
      title={'Изменить исполнителя'}
      onClose={handleModalClose}
      confirmButtonText={!isSubmitting && t('FormButtons.save')}
      disabledSaveButton={!isValid || isSubmitting}
      onSave={handleSubmit(submitData)}
    >
      {isLoadingEditData ? (
        <Preloader/>
      ) : (
        <form>
          <div>
            <Label>
              {t(`Project.executor`)} <sup>*</sup>
            </Label>
            <Controller
              name={executor.name}
              control={control}
              render={({field}) => (
                <SingleSelect
                  {...field}
                  options={options}
                  components={{Option: CustomOption, SingleValue: CustomValue}}
                />
              )}
            />
          </div>
        </form>
      )}

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={close}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </Modal>
  );
};

export default ChangeExecutorModal;