import SelectMulty from '@components/form/select/SelectMulty';
import { Subtitle } from '@shared/components';
import { useMemo } from 'react';

const selectData = {
  name: 'projects',
};

const ProjectsMultySelect = ({ control, projects }) => {
  const options = useMemo(() => {
    return projects?.map((p) => ({ label: p.title, value: p.id }));
  }, [projects]);

  return (
    <div>
      <Subtitle>Проекты</Subtitle>
      <SelectMulty item={selectData} control={control} options={options} />
    </div>
  );
};

export default ProjectsMultySelect;
