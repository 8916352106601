import { SingleSelect } from '@shared/components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

const GroupSelect = ({ control, groups }) => {
  const selectOptions = useMemo(() => {
    if (groups) {
      return [...groups?.map((group) => ({ label: group.title, value: group.id }))];
    }
  }, [groups]);

  return (
    <Controller
      name="group"
      control={control}
      render={({ field }) => (
        <SingleSelect
          {...field}
          ref={null}
          placeholder="Группа"
          options={selectOptions}
          value={selectOptions?.find((o) => o.value === field?.value)}
          onChange={(o) => {
            field.onChange(o?.value);
          }}
          isClearable
        />
      )}
    />
  );
};

export default GroupSelect;
