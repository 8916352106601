import { func } from 'prop-types';
import ModalPortal from '@shared/components/Modal/ModalPortal';
import { Container, Modal, StyledModalSubtitle } from '@shared/components';
import { ReactComponent as IOSShareIcon } from 'src/assets/images/icons/ios-share.svg';
import { ReactComponent as DotsIcon } from 'src/assets/images/icons/subtask-dots.svg';
import appInstallSrc from 'src/assets/images/app-install.webp';
import { colors } from 'src/variables';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';

import { OrderedList } from './styles';

AppInstallInstructions.propTypes = {
  onClose: func,
};

function AppInstallInstructions({ onClose }) {
  useEventTriggerOnEscPress(onClose);

  return (
      <Modal title="Инструкции по установке" onClose={onClose} withoutButtons>
        <Container direction="column" gap="0.5rem">
          <StyledModalSubtitle size={2} as="h2">
            Для iPhone телефонов
          </StyledModalSubtitle>
          <OrderedList>
            <li>
              <div>
                Нажмите на кнопку <IOSShareIcon fill={colors.dark} style={{ marginBottom: '-3px' }} />
                &nbsp;(поделиться).
              </div>
            </li>
            <li>Нажмите на пункт меню "Добавить на домашний экран".</li>
          </OrderedList>
        </Container>
        <Container direction="column" gap="0.5rem">
          <StyledModalSubtitle size={2} as="h2">
            Для Android телефонов
          </StyledModalSubtitle>
          <OrderedList>
            <li>
              <div>
                Откройте меню браузера (кнопка{' '}
                <DotsIcon fill={colors.dark} style={{ transform: 'rotate(90deg) translateX(-2px)' }} />
                ).
              </div>
            </li>
            <li>Нажмите на пункт меню "Установить приложение / Добавить на домашний экран".</li>
          </OrderedList>
        </Container>
        <Container direction="column" gap="0.5rem">
          <StyledModalSubtitle size={2} as="h2">
            Для компьютеров и ноутбуков
          </StyledModalSubtitle>
          <OrderedList>
            <li>Откройте портал в одном из следующих браузеров: Google Chrome, Microsoft Edge или Яндекс Браузер.</li>
            <li>
              <div>
                <div>
                  В адресной строке браузера, в правом краю, нажмите на значок установки приложения.
                  <br />В разных браузерах, значок установки выглядит по-разному.
                </div>
                <img src={appInstallSrc} alt="install screenshot" />
              </div>
            </li>
            <li>Нажмите на кнопку "Установить". </li>
            <li>На рабочем столе либо в меню приложений, должен появиться значок Anicom Portal.</li>
          </OrderedList>
        </Container>
      </Modal>
  );
}

export default AppInstallInstructions;
