import styled from 'styled-components';

export const StyledIconButton = styled.div`
  width: ${(props) => props.size || 16}px;
  height: ${(props) => props.size || 16}px;
  flex: 0 0 ${(props) => props.size || 16}px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.icon});
  background-color: ${(props) => props.background || 'transparent'};
  border: none;
  border-radius: 2px;
  padding: 2px;
  cursor: pointer;
`;
