import { Text } from '@shared/components';

const { default: styled } = require('styled-components');

export const StyledText = styled(Text)`
  hyphens: none;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 600px) {
    padding-left: 20px;
    grid-template-columns: 1fr;
  }
`;
