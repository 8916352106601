import { colors } from 'src/variables';
import styled from 'styled-components';

export const ContainerNotFound = styled.div`
  text-align: center;
  margin-top: ${({ $mTop }) => $mTop || '10%'};
  margin-bottom: ${({ $mBottom }) => $mBottom || '20%'};
`;

export const NotFoundText = styled.h1`
  font-size: 36px;
  hyphens: none;
  color: ${colors.dark};

  @media (max-width: 750px) {
    font-size: 24px;
  }
`;
export const Description = styled.div`
  font-size: 20px;
  hyphens: none;
  color: ${colors.dark};

  @media (max-width: 750px) {
    font-size: 14px;
  }
`;

export const Container404 = styled.div`
  position: relative;
  height: 250px;
  padding-top: 40px;
  text-align: center;
  margin-bottom: 32px;

  ${({ $small }) => ($small ? 'height: 150px;' : '')}

  @media (max-width: 750px) {
    height: 150px;
  }
`;

export const Clip = styled.div`
  display: inline-block;
  overflow: hidden;
  position: relative;
  transform: skew(-45deg)
    translateX(
      ${({ $order }) => {
        switch ($order) {
          case 1:
            return '1px';
          case 3:
            return '-1px';
          default:
            return '0';
        }
      }}
    );

  height: ${({ $small }) => ($small ? '100px' : '180px')};

  ${({ $order, $small }) => {
    switch ($order) {
      case 1:
      case 3:
        return $small ? 'width: 100px;' : 'width: 250px;';

      case 2:
        return $small ? 'width: 70px;' : 'width: 130px;';

      default:
        return '';
    }
  }}

  @media (max-width: 750px) {
    height: 100px;
    ${({ $order }) => {
      switch ($order) {
        case 1:
        case 3:
          return `width: 100px;`;
        case 2:
          return `width: 70px;`;

        default:
          return '';
      }
    }}
  }
`;

export const Digit = styled.span`
  width: 150px;
  height: 150px;
  line-height: 150px;
  font-size: 120px;
  font-weight: bold;
  position: relative;
  top: 8%;
  color: white;
  background: ${colors.darkestSecondary};
  border-radius: 50%;
  display: inline-block;
  transform: skew(45deg);
  ${({ $order }) => {
    switch ($order) {
      case 3:
        return 'left: -22%';
      case 2:
        return 'left: -10%';
      case 1:
        return 'right: -20%';
      default:
        return '';
    }
  }};

  ${({ $small, $order }) => {
    if ($small) {
      return `
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 52px;
        ${$order === 1 ? 'right: -15%;' : ''}      
      `;
    }
  }}

  @media (max-width: 750px) {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 52px;

    ${({ $order }) => ($order === 1 ? 'right: -15%;' : '')}
  }
`;
