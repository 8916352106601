import React from 'react';

import {Avatar} from '@shared/components/Avatar';
import {Container} from '@shared/components/Container';

import {StyledLink, TeamTreeCard, TeamTreeCardPosition, TeamTreeItem} from './styles';

const TeamTreeListItem = ({item, location}) => {
  let children = null;

  if (item.employees && item.employees.length) {
    children = (
      <ul>
        {item.employees.map((i) => (
          <TeamTreeListItem item={i} key={i.id} location={location}/>
        ))}
      </ul>
    );
  }

  return (
    <TeamTreeItem>
      {(item.first_name || item.last_name) && (
        <TeamTreeCard>
          <Avatar size={64} userId={item.id} showProfileOnClick/>
          <Container direction={'column'} justify="center" gap="8px">
            {(item.last_name || item.first_name) && (
              <StyledLink to={`/team/users/${item.id}`}>
                {item.last_name + ' '}
                {item.first_name}
              </StyledLink>
            )}

            {item.position &&
              <Container gap={'4px'}>
                <TeamTreeCardPosition>{item.position}</TeamTreeCardPosition>
              </Container>
            }
          </Container>
        </TeamTreeCard>
      )}
      {children}
    </TeamTreeItem>
  );
};

export default TeamTreeListItem;
