import { styled } from 'styled-components';

export const StyledTitle = styled.h2`
  padding: 32px 0;
  margin: 0;

  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;
