import { Progress, ProgressFill } from '@components/files/styles';

const uploadStatusColor = (isRemoving, isUploaded, errorUploading) => {
  if (isRemoving || errorUploading) return 'error';
  if (isUploaded) return 'complete';
  return '';
};

const ProgressBar = ({ progressPercent, isRemoving, isUploaded, errorUploading }) => {
  return (
    <Progress>
      <ProgressFill
        state={uploadStatusColor(isRemoving, isUploaded, errorUploading)}
        style={{ width: `${progressPercent || 0}%` }}
      />
    </Progress>
  );
};

export default ProgressBar;
