import * as yup from 'yup';

export const viewSelectOptions = [
  { value: 'list', label: 'Списком' },
  { value: 'tree', label: 'Деревом' },
];

export const customSelectStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderWidth: 10,
    fontSize: 16,
    overflow: 'auto',
    transition: 'all 0.2s ease-in-out',
    width: '100%',
  }),

  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  // поле с выбранными списками
  control: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      fontSize: '16px',
      minWidth: '220px',
      height: '41px',
      opacity: '1',
      // border: isFocused ? 0 : 0,
      border: isFocused ? '1px solid  #1890ff' : '1px solid #d9d9d9',
      borderRadius: '2px',
      boxShadow: isFocused ? '0 0 4px rgba(24, 144, 255, 0.5)' : 0,
      ':hover': {
        border: isFocused ? '1px solid  #1890ff' : '1px solid #d9d9d9',
        boxShadow: isFocused ? '0 0 4px rgba(24, 144, 255, 0.5)' : 0,
      },
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  singleValue: (provided) => ({
    ...provided,
    fontSize: 16,
    color: '#8C8C8C',
  }),
};

export const schemaCreateEmployees = yup.object().shape({
  // first_name: yup.string().required(),
  // last_name: yup.string().required(),
  // // gender: "",
  // // birthday: "",
  // email: yup.string().email().required(),
  // password: yup.string().required(),
  // date_start: yup.string().required(),
  // phone: yup.string().required(),
  // }),
});
// форматируем данные для селекта, выводит только title, ttle и имя, title и имя и фамилию и тд
export const formatSelectOptions = (arr, value, value2, value3) => {
  return arr.map((el) => {
    return {
      label: el[`${value}`] + ' ' + (el[`${value2}`] || '') + (el[`${value3}`] || ''),
      // value: el.id,
      value: el.id,
      // el[`${value}`] +
      // " " +
      // (el[`${value2}`] || "") +
      // (el[`${value3}`] || ""),
      // id: el.id,
    };
  });
};
//надо подправить стили, есть синяя полоса при фокусе
// export const selectStyles = (open) => ({
//   menu: (provided) => ({
//     ...provided,
//     marginTop: 0,
//     borderwidth: 10,
//     fontSize: 16,
//     height: open ? '100px' : '0px',
//     overflow: 'auto',
//     opacity: open ? 1 : 0,
//     transition: 'all 0.2s ease-in-out',
//     visibility: open ? 'visible' : 'hidden',
//     width: '100%',
//   }),
//   multiValueRemove: (styles) => ({
//     ...styles,
//     color: 'gray',
//     borderRadius: '100px',
//     border: '1px solid gray',
//     padding: '0px',
//     marginTop: '8px',
//     height: '12px',
//     width: '12px',
//     ':hover': {
//       color: 'red',
//       border: '1px solid red',
//     },
//   }),
//   option: (styles, { isDisabled }) => {
//     return {
//       ...styles,
//       // backgroundColor: isFocused ? "#a1a1a163" : "transparent",
//       cursor: isDisabled ? 'not-allowed' : 'default',
//     };
//   },
//   // поле с выбранными списками
//   control: (styles, { isDisabled, isFocused }) => {
//     return {
//       ...styles,
//       width: '100%',
//       opacity: '1',
//       // border: isFocused ? 0 : 0,
//       border: isFocused ? '1px solid  #1890ff' : '1px solid #d9d9d9',
//       boxShadow: isFocused ? '0 0 4px rgba(24, 144, 255, 0.5)' : 0,
//       ':hover': {
//         border: isFocused ? '1px solid  #1890ff' : '1px solid #d9d9d9',
//         boxShadow: isFocused ? '0 0 4px rgba(24, 144, 255, 0.5)' : 0,
//       },
//       cursor: isDisabled ? 'not-allowed' : 'default',
//     };
//   },
//   multiValue: (styles) => {
//     return {
//       ...styles,
//       backgroundColor: 'transparent',
//     };
//   },
// });
