import styled from 'styled-components';

export const StyledRadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px 16px;
  flex-wrap: wrap;
  align-items: center;

  ${(props) =>
    props.direction === 'column' &&
    `
    flex-direction: column;
    align-items: flex-start;
  `};

  input {
    display: none;
  }

  label {
    position: relative;
    padding-left: 24px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.main};
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 50%;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
      transition: border 0.2s ease-in-out;
    }

    &::after {
      content: '';
      position: absolute;
      left: 4px;
      top: 50%;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: ${({ theme }) => theme.main};
      transform: translateY(-50%) scale(0);
      transition: transform 0.2s ease-in-out;
    }
  }

  input:checked + label {
    &::before {
      border: 1px solid ${({ theme }) => theme.main};
    }

    &::after {
      transform: translateY(-50%) scale(1);
    }
  }
`;
