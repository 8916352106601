import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Tabs, Search } from '@shared/components';

import { useGetContactsFilterDataQuery } from 'src/redux/features/api/contacts';

import { useDebounce } from '@shared/hooks/useDebounce';

import {StyledSubheader, StyledTagsFilter, Wrapper} from './styles';
import { contactTypes, tabButtons } from './lib';
import GroupsSelect from './GroupSelect';
import DepartmentSelect from './DepartmentsSelect';
import ProjectSelect from './ProjectSelect';
import TagsMultipleSelect from './TagsSelect';

export const ContactFilters = ({ isFiltersOpen, fetchContactsData, searchParams, setSearchParams }) => {
  const { control, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: contactTypes.all,
      department: null,
      group: null,
      search_expression: null,
      project: null,
      tags: null,
      page: 1,
    },
  });

  const { data } = useGetContactsFilterDataQuery();
  const debouncedFetchData = useDebounce((data) => fetchContactsData(data), 350);

  const currentPage = useMemo(() => searchParams.get('page'), [searchParams]);

  useEffect(() => {
    setSearchParams({ filter: contactTypes.all, page: 1 });

    if (currentPage) {
      fetchContactsData();
    }
  }, []);

  useEffect(() => {
    if (currentPage) {
      setValue('page', Number(currentPage));
    }
  }, [currentPage]);

  useEffect(() => {
    const subscription = watch((values) => {
      debouncedFetchData({
        ...values,
        type: values.type === contactTypes.all ? null : values.type,
        tags: !!values?.tags?.length ? values.tags : null,
      });

      return;
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Wrapper>
      <StyledSubheader gridTemplate={'1fr'}>
        <Controller
          name="type"
          control={control}
          render={({ field }) => {
            return (
              <Tabs
                defaultValue={tabButtons[0]}
                buttons={tabButtons}
                onChange={(button) => {
                  field.onChange(button.key);
                  setSearchParams({ filter: button.key, page: 1 });
                }}
              />
            );
          }}
        />
      </StyledSubheader>
      {isFiltersOpen &&
          <StyledSubheader gridTemplate={'repeat(3, 1fr)'}>
            <DepartmentSelect control={control} departments={data?.departments} />
            <GroupsSelect control={control} groups={data?.groups} />
            <ProjectSelect control={control} projects={data?.projects} />
            <Controller
              name="search_expression"
              control={control}
              render={({ field }) => {
                return (
                  <Search
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    value={field.value}
                    id="search_expression"
                  />
                );
              }}
            />
            <StyledTagsFilter>
              <TagsMultipleSelect control={control} tags={data?.tags} />
            </StyledTagsFilter>
          </StyledSubheader>
      }
    </Wrapper>
  );
};
