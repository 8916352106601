import React from 'react';
import { useTranslation } from 'react-i18next';

import FormTab from '@shared/components/FormTab';

import { StyledProjectTabList } from './styles';

const ProjectFormTabs = ({ handler, tabs }) => {
  const { t } = useTranslation();
  return (
    <StyledProjectTabList>
      {/*временно убрали этот функционал*/}
      {/*<FormTab*/}
      {/*  value={t('Project.project_create_edit_tabs.result')}*/}
      {/*  handler={() => handler('result')}*/}
      {/*  open={!!tabs.result}*/}
      {/*/>*/}
      {/*<FormTab*/}
      {/*  value={t('Project.project_create_edit_tabs.deadlines')}*/}
      {/*  handler={() => handler('deadlines')}*/}
      {/*  open={!!tabs.deadlines}*/}
      {/*/>*/}
      <FormTab value={t('Project.project_create_edit_tabs.tags')} handler={() => handler('tags')} open={!!tabs.tags} />
      {/* <FormTab
        value={t("Project.project_create_edit_tabs.incoming_data")}
        handler={() => handler("incoming_data")}
        open={!!tabs.incoming_data}
      />
      <FormTab
        value={t("Project.project_create_edit_tabs.outgoing_data")}
        handler={() => handler("outgoing_data")}
        open={!!tabs.outgoing_data}
      /> */}
    </StyledProjectTabList>
  );
};

export default ProjectFormTabs;
