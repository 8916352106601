import styled, { css } from 'styled-components';

const colorFilterStyles = `
    -webkit-filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
    filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
`;

export const FileMenuContainer = styled.div`
  position: relative;
`;

export const FileMenuBtn = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  display: inline-flex;
  height: 18px;
  justify-content: center;
  opacity: 1;
  padding: 0;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  width: 18px;
  background: url(${(props) => props.icon}) no-repeat 0% 70%;

  &:hover {
    ${colorFilterStyles}
  }

  ${(props) =>
    props.$active &&
    css`
      ${colorFilterStyles}
    `}
`;

export const FileMenu = styled.menu`
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  opacity: ${(props) => (props.$active ? 1 : 0)};
  position: absolute;
  left: 0;
  top: 32px;
  transition: all 0.2s ease-in-out;
  visibility: ${(props) => (props.$active ? 'visible' : 'hidden')};
  width: -webkit-max-content;
  width: max-content;
  z-index: 100;
`;

export const MenuItem = styled.li`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  padding: 8px 16px;
  text-align: left;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    background-color: #bae7ff;
  }
`;
