import { rtkQueryApi } from './rtkQueryApi';

export const versionsApi = rtkQueryApi.injectEndpoints({
  tagTypes: ['Versions'],
  endpoints: (builder) => ({
    getVersions: builder.query({
      query: () => '/api/versions',
      providesTags: ['Versions'],
    }),
    addNewVersion: builder.mutation({
      query: (body) => ({
        url: '/api/versions/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Versions'],
    }),
  }),
});

export const { useGetVersionsQuery, useAddNewVersionMutation } = versionsApi;
