import { useMemo, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { ButtonWithIcon } from '@shared/components';
import iconAdd from '@assets/images/icons/plus.svg';
import CreateStorageModal from 'src/windows/storage/CreateStorageModal';

import { StyledTitleWrapper, StyledTitle } from './styles';

const StoragePageHeader = () => {
  const auth = useAuth();

  const userHasRight = useMemo(() => {
    if (auth?.isUserRightful) return auth.isUserRightful('storage_create');
  }, [auth]);

  const [createModal, setCreateModal] = useState();

  return (
    <>
      <StyledTitleWrapper>
        <StyledTitle>Хранилища</StyledTitle>
        {userHasRight && (
          <ButtonWithIcon color="green" position="left" icon={iconAdd} size={20} onClick={() => setCreateModal(true)}>
            Добавить хранилище
          </ButtonWithIcon>
        )}
      </StyledTitleWrapper>

      {createModal && <CreateStorageModal close={() => setCreateModal(false)} />}
    </>
  );
};

export default StoragePageHeader;
