import { rtkQueryApi } from './rtkQueryApi';

export const notificationsApi = rtkQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    getOpenedNotifications: builder.query({
      query: () => '/api/read_notifications',
      transformResponse: (response) =>
        response.notifications
          .map((notification) => {
            let link;
            switch (notification.data_type) {
              case 'project_created_responsible':
              case 'project_created_executor':
              case 'project_created_member':
              case 'project_message':
              case 'project_mentioned_in_chat':
              case 'project_result_posted':
              case 'project_result_accepted':
              case 'project_result_rejected':
              case 'project_completed':
              case 'project_returned':
              case 'project_updated_executor':
              case 'project_updated_responsible':
              case 'project_updated_member':
                link = `/projects/${notification.data_id}`;
                break;

              case 'task_created_responsible':
              case 'task_created_executor':
              case 'task_created_member':
              case 'task_message':
              case 'task_mentioned_in_chat':
              case 'task_result_posted':
              case 'task_result_accepted':
              case 'task_result_rejected':
              case 'task_completed':
              case 'task_returned':
              case 'task_updated_executor':
              case 'task_updated_responsible':
              case 'task_updated_member':
                link = `/projects/${notification.root_project_id}/tasks/${notification.data_id}`;
                break;

              case 'ticket_assigned':
              case 'ticket_accepted':
              case 'ticket_rejected':
                link = `/tickets/me?id=${notification.data_id}`;
                break;

              case 'ticket_completed':
                link = `/tickets?filter=active&page=1&id=${notification.data_id}`;
                break;

              case 'team_new_subordinate':
              case 'team_new_colleague':
                link = `/team/users/${notification.data_id}`;
                break;

              default:
                break;
            }
            if (link) notification.link = link;
            return notification;
          })
          .sort((a, b) => {
            return new Date(b.date_update) - new Date(a.date_update);
          }),
      providesTags: ['openedNotifications'],
    }),

    getSubscriptions: builder.query({
      query: () => '/api/my_subscriptions',
      transformResponse: (response) => response.subscriptions,
      providesTags: ['notificationSubscriptions'],
    }),

    notificationSettings: builder.query({
      query: () => '/api/notification_setting',
      transformResponse: (response) => response.notification_setting,
      providesTags: ['notificationSettings'],
    }),

    updateNotificationSettings: builder.mutation({
      query: (body) => ({
        url: '/api/notification_setting',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['notificationSettings'],
    }),

    notificationSubscription: builder.mutation({
      query: ({ data_type, data_id, subscription_status }) => ({
        body: { data_type, data_id },
        url: `/api/${subscription_status === 1 ? 'unsubscribe' : 'subscribe'}`,
        method: 'PUT',
      }),

      async onQueryStarted({ subscription_status, subscription_id }, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (!data.result?.affectedRows) return;
          dispatch(
            notificationsApi.util.updateQueryData('getSubscriptions', undefined, (draft) => {
              const indexToUpdate = draft.findIndex((subscription) => subscription.id === subscription_id);
              if (indexToUpdate === -1) return draft;
              draft[indexToUpdate].subscribed = subscription_status === 1 ? 0 : 1;
            }),
          );
        } catch {
          return;
        }
      },
    }),
  }),
  tagTypes: ['openedNotifications', 'notificationSubscriptions', 'notificationSettings'],
});

export const {
  useGetOpenedNotificationsQuery,
  useGetSubscriptionsQuery,
  useNotificationSettingsQuery,
  useNotificationSubscriptionMutation,
  useUpdateNotificationSettingsMutation,
} = notificationsApi;
