import {Fragment} from 'react';

import {Avatar} from '@shared/components/Avatar';
import {IconButton} from "@shared/components";
import {
  StyledUserCard, StyledUserControls,
  StyledUserEmail,
  StyledUserName,
  StyledUserRights
} from "@components/chat/side-menu/sideMenuComponents/styles";

import iconEdit from '@assets/images/icons/edit.svg';

import useAuth from '../../../../hooks/useAuth';
import {rightsTranslation} from '../../../../utilize/helper-functions';

const AsideUserCard = ({userId, lastName, firstName, showUserProfile, guest}) => {
  const auth = useAuth();

  return (
    <>
      <StyledUserCard
        $guest={auth?.user?.is_guest}
        onClick={e => {
          e.stopPropagation();
          if (!auth?.user?.is_guest) showUserProfile(userId);
        }}>
        <Avatar userId={userId} guest={guest} firstName={firstName} lastName={lastName} showProfileOnClick={!showUserProfile}/>
        <div>
          <StyledUserName>{`${lastName || ''} ${firstName || ''}`}</StyledUserName>
          {guest && (
            <>
              <StyledUserEmail href={`mailto:${guest.email}`}>
                {guest.email}
              </StyledUserEmail>
              <StyledUserRights>
                {guest.access.map((right, i) => (
                  <Fragment key={i}>{rightsTranslation[right] ? `${rightsTranslation[right]}. ` : ''}</Fragment>
                ))}
              </StyledUserRights>
            </>
          )}
        </div>
        {guest && (
          <StyledUserControls>
            <IconButton
              icon={iconEdit}
              size={16}
              onClick={() => showUserProfile(userId, true)}
            />
          </StyledUserControls>
        )}
      </StyledUserCard>
    </>
  );
};

export default AsideUserCard;
