import { bool, string, number, shape, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StyledPollBody, StyledPollResults, StyledPollText, StyledPollVotes, StyledRefusedText } from './styles';
import PollOptionChart from './PollOptionChart/PollOptionChart';
import { CancelVoteButton } from './CancelVoteButton/CancelVoteButton';
import { VotersListPopup } from './VotersListPopup/VotersListPopup';

PollResults.propTypes = {
  isMember: bool,
  hasDeadlinePassed: bool,
  userStatus: string,
  options: arrayOf(
    shape({
      id: number,
      percentage_ratio: number,
      vote_count: number,
      label: string,
    }),
  ),
  pollId: number,
  userAnswer: arrayOf(number),
  isAnonymous: bool,
};

export function PollResults({ isMember, hasDeadlinePassed, userStatus, pollId, options, userAnswer, isAnonymous }) {
  const { t } = useTranslation();

  return (
    <StyledPollResults>
      {isMember && !hasDeadlinePassed && userStatus === 'refused_vote' && (
        <StyledRefusedText align="center">
          <CancelVoteButton pollId={pollId} />
          <span>Вы отказались от голосования</span>
        </StyledRefusedText>
      )}

      {options.map((option) => (
        <StyledPollBody key={option.id}>
          {Array.isArray(userAnswer) && userAnswer.includes(option.id) && <CancelVoteButton pollId={pollId} />}
          <PollOptionChart percent={option.percentage_ratio || 0} />
          <StyledPollVotes>{t('Chat.voteCount', { count: option.vote_count })}</StyledPollVotes>
          <StyledPollText>
            {option.label}
            &nbsp;&nbsp;
            {!isAnonymous && Array.isArray(option.members) && option.members.length > 0 && (
              <VotersListPopup members={option.members} />
            )}
          </StyledPollText>
          <div></div>
        </StyledPollBody>
      ))}
    </StyledPollResults>
  );
}
