import { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';

import { useRef } from 'react';

import SnackbarContext from 'src/contexts/SnackbarContext';

import HTMLReactParser from 'html-react-parser';

import { ButtonWithIcon } from '@shared/components/ButtonWithIcon/ButtonWithIcon';
import {Container, Title} from "@shared/components";

import iconAdd from '@assets/images/icons/plus.svg';

import AddEditNewsModal from '../../windows/news/CreateEditNewsModal';
import LocalDateTime from '../../components/shared/LocalDateTime';
import Preloader from '../../components/preloaders/Preloader';
import useAuth from '../../hooks/useAuth';

import {StyledProjectList} from "../Projects/project-list/ProjectList";

import Pagination from './Pagination';
import {StyledBlog, StyledBlogText, StyledPost, StyledPostDate, StyledPostTitle} from "./style";



const News = () => {
  const location = useLocation();
  const auth = useAuth();

  const newsBlogsType = useMemo(() => {
    if (location?.pathname.includes('/blogs')) {
      return 'blog';
    } else if (location?.pathname.includes('/news')) {
      return 'news';
    }
  }, [location]);

  const userHasRights = useMemo(() => {
    if (newsBlogsType === 'news' && auth?.isUserRightful(auth.rightTypes.add_news)) {
      return true;
    }
    if (newsBlogsType === 'blog') return true;
  }, [auth, newsBlogsType]);

  const [addNewsModal, toggleAddNewsModal] = useState();
  const [list, setList] = useState();
  const [isLoadingList, setIsLoadingList] = useState();
  const totalPages = useRef();

  const { showSnackbar } = useContext(SnackbarContext);

  const getData = useCallback(
    (pageNum) => {
      setIsLoadingList(true);
      axios
        .get(`/api/news_blog_list/${newsBlogsType}/${pageNum}`)
        .then((r) => {
          if (r.data.page_count) totalPages.current = r.data.page_count;
          setList(r.data);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при получении данных');
        })
        .finally(() => setIsLoadingList(false));
    },
    [newsBlogsType],
  );

  // получить первоначальные данные
  useEffect(() => {
    if (newsBlogsType && getData) getData(1);
  }, [newsBlogsType, getData]);

  return (
    <>
      {addNewsModal && newsBlogsType && (
        <AddEditNewsModal close={() => toggleAddNewsModal(false)} type={newsBlogsType} />
      )}

      <StyledProjectList>
        <Container align={'center'} justify={'space-between'}>
          <Title>{`${newsBlogsType === 'blog' ? 'Блоги' : 'Новости'}`}</Title>
          {userHasRights && (
            <ButtonWithIcon
              color="green"
              position="left"
              icon={iconAdd}
              size={20}
              onClick={() => toggleAddNewsModal(true)}
            >
              {`${newsBlogsType === 'blog' ? 'Написать статью' : 'Написать новость'}`}
            </ButtonWithIcon>
          )}
        </Container>
        <StyledBlog>
          {isLoadingList && <Preloader />}
          {!isLoadingList &&
            list?.newsBlogList?.map((listItem, i) => {
              // заменить html тэги в описании на простой текст
              const plainString = listItem.description?.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '');

              return (
                <StyledPost key={i}>
                  <StyledPostDate>
                    <LocalDateTime dateTime={listItem.date_created} />
                  </StyledPostDate>
                  <Link to={`/${newsBlogsType === 'news' ? 'news' : 'blogs'}/${listItem.id}`}>
                    <StyledPostTitle>{listItem.title}</StyledPostTitle>
                  </Link>
                  <StyledBlogText>
                    {listItem.description && HTMLReactParser(String(listItem.description))}
                  </StyledBlogText>
                </StyledPost>
              );
            })}
        </StyledBlog>
        <Pagination getData={getData} totalPages={totalPages.current} />
      </StyledProjectList>

    </>
  );
};

export default News;
