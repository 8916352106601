import { Container, IconButton, Input, Label, Modal, Text } from '@shared/components';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { randomSequence } from 'src/utilize/helper-functions';

import { useEditLinkMutation, useGetLinkByIdQuery } from 'src/redux/features/api/links';

import SnackbarContext from 'src/contexts/SnackbarContext';

import useAuth from 'src/hooks/useAuth';

import Preloader from '@components/preloaders/Preloader';

import DocumentIcon from '@assets/images/icons/file-other.svg';
import DeleteIcon from '@assets/images/icons/delete-circle.svg';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { t } from 'i18next';

import { linksType } from '../AddLinkModal/lib';

import { StyledText } from '../styles';

import { LinkTypeSelect } from './ui/LinkTypeSelect';
import { AccessRadioButtons } from './ui/AccessRadioButtons';
import UsersSelect from './ui/UsersSelect';
import DepartmentsSelect from './ui/DepartmentsSelect';
import { FileLoader } from './ui/FileLoader';
import { getDepartmentOptions, getUserOptions, linkTypes, radioButtonsType, schema } from './lib';

export const EditLinkModal = ({ linkId, onClose }) => {
  const { data, isLoading } = useGetLinkByIdQuery({ id: linkId });
  const [editLink, { isError }] = useEditLinkMutation();

  const [formId, setFormId] = useState(randomSequence());
  const [selectedRadio, setSelectedRadio] = useState(radioButtonsType.all);
  const [editFile, setEditFile] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const auth = useAuth();

  const getUploadedFiles = useRef();
  const removeFilesOnCloseRef = useRef();

  const isPublic = useMemo(() => data?.is_public, [data]);

  const { showSnackbar } = useContext(SnackbarContext);

  const {
    control,
    handleSubmit,
    watch,
    resetField,
    reset,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const isFileUploadTab = watch('type')?.value === linkTypes.file.value;
  const isFileUploaded = watch('hasFile');

  const onRemoveFilesHandler = async () => {
    const onRemove = removeFilesOnCloseRef.current;

    if (onRemove) {
      await onRemove();
    }
  };

  const onReset = () => {
    resetField('departments', { defaultValue: [] });
    resetField('employees', { defaultValue: [] });
  };

  const transformFormData = (data) => {
    return {
      row: {
        type: !!data.type?.value.length ? data.type.value : 'out',
        title: data.title,
        link: data.url,
        is_public: selectedRadio === radioButtonsType.all,
      },
      link_id: linkId,
      form_id: !!isFileUploadTab ? formId : null,
      departments: data.departments?.length ? data?.departments?.map((option) => option.value) : [],
      employees: data.employees?.length ? data?.employees?.map((option) => option.value.id) : [],
    };
  };

  const onSubmit = async (data) => {
    const uploadedFiles = getUploadedFiles.current ? getUploadedFiles.current() : [];

    if (!uploadedFiles.length && isFileUploadTab && !isFileUploaded) {
      return showSnackbar('Необходимо прикрепить файл', 'error');
    }

    await editLink(transformFormData(data)).then(() => {
      showSnackbar('Ссылка успешно отредактирована', 'success');
      onClose();
    });
  };

  const onCloseHadler = () => {
    onRemoveFilesHandler();
    onClose();
  };

  useEffect(() => {
    reset({
      title: data?.title,
      type: {
        label: linkTypes[data?.type]?.title,
        value: data?.type,
      },
      url: data?.link,
      departments: getDepartmentOptions(data?.link_departments),
      employees: getUserOptions(data?.link_employees, auth.token),
      hasFile: data?.type === linkTypes.file.value && !!data?.link.length,
      access: 'all',
      files: [],
    });
  }, [data]);

  return (
    <Modal
      title="Редактирование ссылки"
      modalSize="960px"
      onClose={() => {
        if (isDirty) {
          setConfirmModal(true);
          return;
        }

        onCloseHadler();
      }}
      onSave={handleSubmit(onSubmit)}
      confirmButtonText={'Редактировать'}
      disabledSaveButton={!isDirty}
    >
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div>
            <Label>Название</Label>
            <Controller
              name="title"
              control={control}
              render={({ field }) => <Input {...field} placeholder="Введите название ссылки" />}
            />
          </div>
          <LinkTypeSelect control={control} setEditFile={setEditFile} />

          {((isFileUploadTab && data?.type !== linkTypes.file.value) || (editFile && !!data?.link.length)) && (
            <FileLoader
              control={control}
              removeFilesOnCloseRef={removeFilesOnCloseRef}
              formId={formId}
              getUploadedFiles={getUploadedFiles}
            />
          )}

          {data?.type === linksType.file && !editFile && isFileUploadTab && (
            <Container direction={'column'} gap={'14px'}>
              <StyledText>Прикрепленный файл:</StyledText>
              <Container align={'center'} gap={'12px'}>
                <Container align={'center'} gap={'8px'}>
                  <img src={DocumentIcon} width={22} height={22} alt="" /> {data?.link}
                </Container>
                <IconButton
                  size={15}
                  icon={DeleteIcon}
                  onClick={() => {
                    setValue('hasFile', false);
                    setEditFile(true);
                  }}
                />
              </Container>
            </Container>
          )}

          {!isFileUploadTab && (
            <div>
              <Label>Ссылка</Label>
              <Controller
                name="url"
                control={control}
                render={({ field }) => <Input {...field} placeholder="http://example.com" />}
              />
              {errors.url && (
                <Container gap={'4px'}>
                  <Text color={'red'} size={1}>
                    {errors.url.message}
                  </Text>
                </Container>
              )}
            </div>
          )}
          <AccessRadioButtons
            control={control}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
            onReset={onReset}
            isPublic={isPublic}
            departments={data.link_departments}
            employees={data?.link_employees}
          />
          {selectedRadio === radioButtonsType.users && <UsersSelect control={control} />}
          {selectedRadio === radioButtonsType.departments && <DepartmentsSelect control={control} />}

          {confirmModal && (
            <ConfirmAction
              cancel={() => setConfirmModal(false)}
              confirm={onCloseHadler}
              actionText={t('common.confirm_modal_close')}
            />
          )}
        </>
      )}
    </Modal>
  );
};
