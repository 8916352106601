import SelectMulty from '@components/form/select/SelectMulty';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@shared/components';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { object, array, string, number } from 'yup';

const formSchema = object({
  departments: array().of(
    object({
      label: string(),
      value: number(),
      employees: array().of(object({ id: number(), last_name: string().nullable(), first_name: string() })),
    }),
  ),
});

const departmentSettings = {
  label: 'Отделы',
  name: 'departments',
};

export function DepartmentSelectModal({ onClose, departments, addDepartments }) {
  const departmentOptions = useMemo(() => {
    if (!departments || !Array.isArray(departments)) return [];
    return departments.map((department) => ({
      value: department.id,
      label: department.title,
      employees: department.employees,
    }));
  }, [departments]);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const handleDepartmentsAdd = useCallback(
    (values) => {
      addDepartments(values);
      onClose();
    },
    [addDepartments, onClose],
  );

  return (
    <Modal
      title="Добавить сотрудников по отделам"
      onClose={onClose}
      confirmButtonText="Добавить"
      onSave={handleSubmit(handleDepartmentsAdd)}
      disabledSaveButton={!isDirty || !isValid}
    >
      <div>
        <SelectMulty item={departmentSettings} options={departmentOptions} control={control} />
      </div>
    </Modal>
  );
}
