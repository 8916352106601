import styled from 'styled-components';

export const StyledLabel = styled(Text)`
  display: block;
  margin-bottom: 8px;

  & sup {
    color: ${({ theme }) => theme.warning};
  }
`;
