import SelectMulty from '@components/form/select/SelectMulty';
import { Subtitle } from '@shared/components';
import { useMemo } from 'react';

const selectData = {
  name: 'departments',
};

const DepartmentsMultySelect = ({ control, departments }) => {
  const options = useMemo(() => {
    return departments?.map((g) => ({ label: g.title, value: g.id }));
  }, [departments]);

  return (
    <div>
      <Subtitle>Отделы</Subtitle>
      <SelectMulty item={selectData} control={control} options={options} />
    </div>
  );
};

export default DepartmentsMultySelect;
