import styled from 'styled-components';

export const StyledProfileUserpic = styled.div`
  height: 212px;
  width: 212px;
  position: relative;

  & img {
    border-radius: 50%;
    height: 100%;
    object-fit: fill;
    width: 100%;
  }

  & input {
    display: none;
  }

  @media (max-width: 578px) {
    margin: 0 auto;
  }
`;

export const StyledProfileUsername = styled.p`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledProfileAttach = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
  border-top: 1px solid ${({ theme }) => theme.borderSecondary};
  padding: 32px 0;
`;

export const StyledProfileUserStatus = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
`;

export const StyledProfileChangeButton = styled.button`
  background-color: transparent;
  border: none;
  bottom: -32px;
  color: ${({ theme }) => theme.main};
  left: 50%;
  position: absolute;
  text-decoration: underline;
  transform: translateX(-50%);

  &:hover {
    text-decoration: none;
  }
`;

export const StyledProfileStatus = styled.textarea`
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  color: ${({ theme }) => theme.darkest};
  font: inherit;
  outline: none;
  padding: 8px 34px 8px 12px;
  position: relative;
  width: 100%;
  resize: none;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    border-color: ${({ theme }) => theme.main};
    box-shadow: ${({ theme }) => theme.shadowInput};
  }
`;

export const StyledAccessList = styled.ol`
  list-style: decimal;
  width: 100%;
`;

export const StyledAccessItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.disabledSecondary};
  padding: 16px 0;
  position: relative;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  gap: 8px;

  &::marker {
    font-weight: 600;
  }

  &:first-child {
    padding-top: 0;
  }
`;
