import { yupResolver } from '@hookform/resolvers/yup';
import { Label } from '@shared/components/Label/Label';
import { Modal } from '@shared/components/Modal/Modal';
import { SingleSelect } from '@shared/components/Select/Select';
import { User } from '@shared/components/User/User';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { files_url } from 'src/settings/base-url';
import StyledMockAvatar from '@assets/images/userpic.png';
import * as yup from 'yup';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import { Input } from '@shared/components/Input/Input';
import { useAddPaymentMutation, useGetUserPaymentsLastTenDaysQuery } from 'src/redux/features/api/employees';
import DateInput from '@shared/components/DateInput/DateInput';
import { Container } from '@shared/components/Container';
import { Avatar } from '@shared/components/Avatar';
import moment from 'moment';

import EditIcon from '@assets/images/icons/edit.svg';

import { Text } from '@shared/components/Text/Text';

import { IconButton } from '@shared/components/IconButton/IconButton';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { t } from 'i18next';

import { EditPaymentModal } from '../EditPaymetModal';

import { Wrapper } from './styles';

export const schema = yup.object({
  employee: yup.object().required(),
  date: yup.date(),
  type: yup.object().required(),
  amount: yup.string().required(),
});

export const formatOptionLabel = ({ pic, name, id }) => {
  return <User id={id} size="sm" picture={pic} name={name}></User>;
};

export const getOptions = (users, token) => {
  const options = users.map((user) => ({
    id: user.id,
    label: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    name: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    pic: !!user.avatar
      ? `${files_url}/employees/avatar/${user.id}/small_${user.avatar}?token=${token}`
      : StyledMockAvatar,
  }));

  return options;
};

export const PaymentsModal = ({ onClose }) => {
  const auth = useAuth();
  const users = useSelector((state) => state.users.employees);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [employee, setEmployee] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const usersOptions = useMemo(() => getOptions(users, auth.token), [users]);
  const paymentTypeOptions = [
    { label: 'Зарплата', value: 1 },
    { label: 'Премия', value: 2 },
  ];

  const [addPayment] = useAddPaymentMutation();
  const { data: paymentsListLastTenDays, isLoading } = useGetUserPaymentsLastTenDaysQuery();

  const onSubmit = async (data) => {
    await addPayment({
      row: {
        employee_id: data.employee.id,
        type: data.type.value, //salary, bonus
        money: data.amount,
        creator_id: auth.user.id,
        payout_date: moment(data.date).format('YYYY[-]MM[-]DD'),
      },
    });

    onClose();
  };

  const editButtonHanlder = (emp) => {
    setEmployee(emp);
    setEditModalVisible(true);
  };

  const getLastPaymentsList = () => {
    return (
      <>
        <Container direction="column" gap="8px">
          {!!paymentsListLastTenDays?.employee_payouts.length && (
            <Text as={'div'} size={3}>
              Выдано за последние 10 дней
            </Text>
          )}
          <Container direction="column">
            {paymentsListLastTenDays?.employee_payouts?.slice(0, 4).map((item) => (
              <Wrapper>
                <div>{moment.utc(item.payout_date, 0).format('L')}</div>
                <Container>
                  <Avatar userId={item.employee_id} size={24} />
                  <Container direction="column" gap="4px">
                    <div>
                      {item.last_name} {item.first_name}
                    </div>
                    <div></div>
                  </Container>
                </Container>
                <div>{item.type === 'bonus' ? 'Премия' : 'З/П'}</div>
                <div>{item.money}</div>

                <IconButton icon={EditIcon} onClick={() => editButtonHanlder(item)} />
              </Wrapper>
            ))}
          </Container>
        </Container>
        {editModalVisible && <EditPaymentModal employeeData={employee} onClose={() => setEditModalVisible(false)} />}
      </>
    );
  };

  return (
    <Modal
      modalSize="767px"
      title="Выдача зарплат и премий"
      onSave={handleSubmit(onSubmit)}
      onClose={() => {
        if (isDirty) {
          setConfirmModal(true);
          return;
        }

        onClose();
      }}
      confirmButtonText={'Записать'}
      footerContent={getLastPaymentsList()}
    >
      <form>
        <div>
          <Label>Сотрудник</Label>
          <Controller
            name="employee"
            control={control}
            render={({ field }) => (
              <SingleSelect
                {...field}
                options={usersOptions}
                formatOptionLabel={formatOptionLabel}
                getOptionValue={(option) => option.id}
              />
            )}
          />
        </div>

        <StyledModalRowEnd>
          <div>
            <Label>Зарплата / Премия</Label>
            <Controller
              name="type"
              control={control}
              render={({ field }) => <SingleSelect {...field} options={paymentTypeOptions} />}
            />
          </div>

          <div>
            <Label>Дата</Label>

            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DateInput
                  {...field}
                  placeholder="Дата"
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  timeIntervals={15}
                  timeCaption="Время"
                  timeFormat="p"
                  dateFormat="Pp"
                  showTimeSelect
                />
              )}
            />
          </div>

          <div>
            <Label>Сумма</Label>
            <Controller name="amount" control={control} render={({ field }) => <Input {...field} error={false} />} />
          </div>
        </StyledModalRowEnd>
      </form>

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onClose}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </Modal>
  );
};
