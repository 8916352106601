import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { FilterButton, StyledList } from './styles';
import { namesButton } from './lib';

export const Tabs = () => {
  const { request_type } = useParams();

  return (
    <StyledList>
      {namesButton.map((btn) => {
        return (
          <Link key={btn.name} to={`/requests/${btn.key || ''}`}>
            <FilterButton $active={request_type === btn.key || (!request_type && !btn.key)}>{btn.name}</FilterButton>
          </Link>
        );
      })}
    </StyledList>
  );
};
