import { useState } from 'react';
import { useGetGroupsQuery } from 'src/redux/features/api/contacts';
import CreateGroup from 'src/windows/contact-groups/CreateEditGroup';

import GroupList from './group-list/GroupList';

const ContactGroupsModal = (props) => {
  //предыдущее значение навания должности
  const [groupToEdit, setGroupToEdit] = useState(null);
  // статус модального окна
  const [modalAddEdit, setModalAddEdit] = useState({
    isOpen: false,
    action: null,
  });

  const { data, isFetching } = useGetGroupsQuery();

  // вызов модального окна для добавления должности
  const handlerAdd = () => {
    setModalAddEdit({ isOpen: true, action: 'add' });
  };

  // получить редактируемую должность при клике на карандаш, вызвать модалку редактирования
  const handlerEdit = (data) => {
    setGroupToEdit(data);
    setModalAddEdit({ isOpen: true, action: 'edit' });
  };
  // при клике на крестик модалки с списком  => вышли из комнаты
  const handlerCloseWindow = () => props.onClose();

  return (
    <>
      {(modalAddEdit.action === 'edit' || modalAddEdit.action === 'add') && (
        <CreateGroup
          close={() => {
            setModalAddEdit({ isOpen: false, action: null });
            setGroupToEdit(null);
          }}
          groupToEdit={groupToEdit}
        />
      )}
      {!modalAddEdit.isOpen && (
        <GroupList
          list={data?.companyGroups}
          isFetching={isFetching}
          onClose={handlerCloseWindow}
          onAdd={handlerAdd}
          onEdit={handlerEdit}
        ></GroupList>
      )}
    </>
  );
};

export default ContactGroupsModal;
