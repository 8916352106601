import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import LocalDateTime from 'src/components/shared/LocalDateTime';
import HTMLReactParser from 'html-react-parser';
import {useMemo} from 'react';
import {isFileNameImage} from 'src/utilize/helper-functions';
import {files_url} from 'src/settings/base-url';
import useAuth from 'src/hooks/useAuth';
import {Avatar} from '@shared/components/Avatar';
import {Container} from '@shared/components/Container';
import {Text} from "@shared/components";

import {StyledIndexPost, StyledPostDate, StyledPostImage, StyledPostMessage, StyledTitle} from './styles';

const NewsBlogItem = ({newsBlogItem, type}) => {
  const auth = useAuth();
  const creator = useSelector((state) => state.users.employees?.find((user) => user.id === newsBlogItem.creator_id));

  const imgSrc = useMemo(() => {
    if (!newsBlogItem) return;
    const imageFile = newsBlogItem.files.find((file) => isFileNameImage(file.file));
    if (!imageFile) return;

    return `${files_url}/news_blogs/files/${newsBlogItem.id}/${imageFile.file}?token=${auth.token}`;
  }, []);

  return (
    <StyledIndexPost>
      {imgSrc && (
        <StyledPostImage>
          <img src={imgSrc} alt=""/>
        </StyledPostImage>
      )}
      <StyledPostMessage>
        <Container>
          {creator && (
            <Avatar
              userId={creator.id}
              lastName={creator?.last_name}
              firstName={creator?.first_name}
              withName
            />
          )}
          <Container direction="column">
            <StyledTitle>
              {creator?.last_name} {creator?.first_name}
            </StyledTitle>
            <StyledPostDate>
              <LocalDateTime dateTime={newsBlogItem.date_created}/>
            </StyledPostDate>
          </Container>
        </Container>
        <div>
          <Link to={`/${type}/${newsBlogItem.id}`}>
            <Text tag="h3" size={3}>{newsBlogItem.title}</Text>
          </Link>
          {typeof newsBlogItem.description === 'string' ? HTMLReactParser(newsBlogItem.description) : ''}
        </div>
      </StyledPostMessage>
    </StyledIndexPost>
  );
};

export default NewsBlogItem;
