import * as yup from 'yup';

export const radioButtonsType = {
  all: 1,
  departments: 2,
  users: 3,
};

export const linksType = {
  in: 'in',
  out: 'out',
  file: 'file',
};

const URL =
  /^((http|https):\/\/|www\.)[a-zA-Z0-9_\-]+(\.[a-zA-Z]+)+((\/)[\w#%]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const schema = yup.object({
  title: yup.string().required('Введите название ссылки'),
  type: yup.object().required('Выберите тип ссылки'),
  url: yup.string().when('type', {
    is: (type) => type?.value === linksType.file,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.matches(URL, 'Введена некорректная ссылка').required('Введите значение'),
  }),
  employees: yup.array(),
  departments: yup.array(),
});
