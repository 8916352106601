import styled, {css} from 'styled-components';
import {Text} from "@shared/components";
import {StyledChatMenu, StyledMenuItem} from "@components/chat/styles";

const sharedIconStyles = `
  position: absolute;
  width: 35px;
  height: 35px;
  left: 14px;

  @media (max-width: 990px) {
    top: 10px;
  };

`;

const CatalogText = css`
  font-size: 12px;
  line-height: 20px;
  color: ${({theme}) => theme.darkest};
  grid-column: 1 / 2;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 992px) {
    grid-column: auto;
  }
`

export const StyledIcon = styled.i`
  ${sharedIconStyles};
  background-image: url(${({$fileUrl}) => $fileUrl});
  background-size: 18px;
  background-position: 0;
  background-repeat: no-repeat;
`;

export const StyledImageIcon = styled.img`
  ${sharedIconStyles};
  border-radius: 2px;
  object-fit: cover;
`;

export const UploadFilesWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`

export const CatalogFile = styled.li`
  user-select: none;
  transition: all 0.2s ease-in-out;
  grid-gap: 8px;
  align-items: start;
  border-radius: 8px;
  color: ${({theme}) => theme.dark};
  cursor: pointer;
  display: grid;
  font-weight: 500;
  gap: 8px 12px;
  grid-template-columns: 1fr max-content;
  padding: 12px 12px 12px 58px;
  position: relative;
  text-overflow: ellipsis;
  word-break: break-word;
  font-size: 12px;
  line-height: 20px;
  min-height: 52px;

  &:hover {
    background-color: ${({theme}) => theme.mainLightOpacity};
  }

  @media (min-width: 992px) {
    grid-gap: 12px;
    align-items: center;
    gap: 12px;
    grid-template-columns: minmax(120px, 1fr) 70px 70px minmax(120px, 170px) 24px 24px;
    white-space: nowrap;
  }
`

export const CatalogFileName = styled.p`
  ${CatalogText};
`

export const CatalogFileAuthor = styled.p`
  ${CatalogText};
`

export const TitlePopup = styled.div`
  position: absolute;
  border: 1px solid ${({theme}) => theme.border};
  background-color: ${({theme}) => theme.lightest};
  border-radius: 2px;
  bottom: 36px;
  left: 48px;
  padding: 4px 8px;
  max-width: 250px;
  white-space: normal;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;

  ${props => props.$active && `
    opacity: 1;
    visibility: visible;
    bottom: 40px;
  `}
`

export const StyledDotsWrapper = styled.div`
  position: relative;
  grid-column: -1/-2;
  grid-row: 1/5;

  @media (min-width: 992px) {
    grid-row: auto;
  }
`

export const StyledDotsMenu = styled(StyledChatMenu)`
  color: ${({theme}) => theme.darkest};
  right: 0;
  top: calc(100% + 5px);
`

export const StyledDotsMenuItem = styled(StyledMenuItem)`
  margin: 0;

  ${props => props.$isDelete && css`
    color: ${({theme}) => theme.warning};
  `}
`

export const CatalogFolder = styled(CatalogFile)`
  &::before {
    ${props => props.icon && css`
      background-position: 0;
      content: '';
      height: 20px;
      left: 16px;
      position: absolute;
      top: 14px;
      width: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(${props.icon});
    `}
  }

  &:hover::before {
    filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
  }
`

export const Storage = styled.section`
  display: flex;
  height: 100%;
  padding: 0;
`

export const StorageList = styled.ul`
  margin-left: -16px;
  margin-right: -12px;
  margin-bottom: 16px;
`

export const StorageContent = styled.section`
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    padding-left: 24px;
  }
`

export const StorageAside = styled.aside`
  background-color: ${({theme}) => theme.lightest};
  border-right: 1px solid ${({theme}) => theme.borderSecondary};
  bottom: -100%;
  height: 100%;
  opacity: 0;
  overflow: auto;
  padding: 20px 20px 47px;
  position: fixed;
  left: 0;
  scrollbar-width: none;
  transition: all 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 10;

  @media (min-width: 768px) {
    flex: 0 0 200px;
    margin-left: -16px;
    max-width: 200px;
    opacity: 1;
    padding: 0 16px 0 0;
    position: static;
    visibility: visible;
    z-index: 1;
  }

  @media (min-width: 992px) {
    flex: 0 0 300px;
    max-width: 300px;
  }

  @media (min-width: 1200px) {
    flex: 0 0 350px;
    max-width: 350px;
  }

  ${props => props.$active && `
      bottom: 0;
      opacity: 1;
      visibility: visible;
  `}
`

export const StorageRubric = styled.ul`
  overflow-x: auto;
`

export const StorageAsideButton = styled.button`
  background-color: ${({theme}) => theme.lightest};
  border: none;
  border-top: 2px solid ${({theme}) => theme.borderSecondary};
  bottom: 0;
  cursor: pointer;
  display: block;
  font: inherit;
  font-weight: 500;
  left: 15px;
  right: 15px;
  padding: 15px 15px 15px 45px;
  position: fixed;
  text-align: left;
  width: calc(100% - 30px);
  z-index: 13;

  @media (min-width: 768px) {
    display: none;
  }

  &::before {
    background-image: url(${props => props.iconClose});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 15px;
    left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s;
    width: 15px;
  }

  ${props => props.$active && css`
    &::before {
      background-image: url(${props => props.iconOpen});
    }
  `}
`

export const StorageRubricItem = styled.li`
  min-width: 100%;
  position: relative;
  width: max-content;
`

export const StorageRubricLink = styled.span`
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  padding: 12px 12px 12px 44px;
  position: relative;
  transition: all 0.2s;
  white-space: nowrap;
  
  &:hover {
    background-color: ${({theme}) => theme.mainLightOpacity};
  }
  
  &::before {
    background-image: url(${props => props.iconClose});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    border-radius: 10px;
    box-sizing: content-box;
    content: '';
    height: 16px;
    left: 10px;
    padding: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
    width: 16px;
  }

  ${props => props.$active && css`
    &::before {
      background-image: url(${props => props.iconOpen});
    }
  `}

  ${props => props.$highlighted && css`
    background-color: ${({theme}) => theme.mainLight};
  `}

  ${props => props.$isDelete && css`    
    &:hover {
      background-color: ${({theme}) => theme.errorLightest};
    }
    
    &::before {
      background-image: url(${props => props.icon});
    }
  `}
  
`

export const StorageHeader = styled.section`
  grid-gap: 12px;
  border-bottom: 1px solid ${({theme}) => theme.borderSecondary};
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  margin-bottom: 24px;
  padding: 12px 0;
  align-items: center;

  @media (min-width: 576px) {
    grid-template-columns: 1fr auto;
  }
`

export const StorageBreadcrumbs = styled.div`
  grid-column: 1 / -1;
`

export const StorageBreadcrumbsItem = styled.div`
  color: ${({theme}) => theme.dark};
  padding-right: 21px;
  position: relative;
  cursor: pointer;
  display: inline-block;

  &::after {
    content: "/";
    position: absolute;
    right: 5px;
    top: 0;
  }

  ${props => props.$active && css`
    color: ${({theme}) => theme.darkest};
    pointer-events: none;
  `}
`

export const StorageTitle = styled(Text)`
  padding-left: 32px;
  position: relative;
  font-weight: 600;

  &::before {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 20px;
    background-image: url(${props => props.icon});
  }
`

export const StorageHeaderButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  padding-left: 32px;
  align-self: flex-start;

  @media (min-width: 576px) {
    padding-left: 0;
  }
`

export const StorageButtonBack = styled.button`
  background-color: transparent;
  border: none;
  padding: 12px 12px 12px 32px;
  position: relative;
  
  &:hover {
    color: ${({theme}) => theme.main};
  }

  &::before {
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${props => props.icon});
  }
  
  &:hover::before {
    filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
  }
`

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`

export const StyledStorageDescription = styled.section`
  background-color: ${({theme}) => theme.light};
  border: 1px solid ${({theme}) => theme.borderSecondary};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  transition: transform 0.2s ease-in-out;
  max-width: 300px;
  opacity: 0;
  position: absolute;
  top: calc(100% + 10px);
  visibility: hidden;
  z-index: 3;

  ${props => props.$active && css`
    opacity: 1;
    top: calc(100% + 5px);
    visibility: visible;
  `}
`

export const Dropdown = styled.section`
  min-width: auto;
  width: ${props => props.width || '100%'};
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;

  ${props => props.$show && css`
    ${StyledDropdownInput} {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      
      &::after {
        transform: rotate(-135deg);
      }
    }
    
    ${StyledSelect} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      max-height: 270px;
      opacity: 1;
      overflow-y: auto;
      transition: all 0.2s ease-in-out;
    }
  `}
`

export const StyledDropdownInput = styled.div`
  border: 1px solid ${({theme}) => theme.border};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  min-height: 42px;
  padding: 8px 28px 8px 8px;
  position: relative;
  width: 100%;
  
  &::after {
    border-bottom: 1px solid ${({theme}) => theme.disabled};
    border-right: 1px solid ${({theme}) => theme.disabled};
    content: '';
    height: 6px;
    pointer-events: none;
    position: absolute;
    right: 13px;
    top: 45%;
    transform: translateY(-50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
    width: 6px;
  }
`

export const StyledSelect = styled.ul`
  position: ${props => props.position || 'relative'};
  background-color: ${({theme}) => theme.lightest};
  border: 1px solid ${({theme}) => theme.border};
  border-radius: 2px;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  top: 100%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
  font-size: 14px;
  line-height: 22px;
`

export const StyledSelectItem = styled.li`
  display: flex;
  padding: 8px;
  transition: all 0.2s;
  
  &:hover {
    background-color: ${({theme}) => theme.mainLight};
  }
`

export const ProjectSelectItem = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.borderSecondary};
  align-items: start;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  padding: 8px;
  font-size: 12px;
  line-height: 20px;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    background-color: ${({theme}) => theme.mainLightOpacity};
  }
  
  @media (min-width: 768px) {
    padding: 8px 12px;
  }

  @media (min-width: 1024px) {
    gap: 20px;
    grid-template-columns: 3fr 1fr 150px;
    padding: 12px 16px;
  }
`

export const ProjectStatus = styled.span`
  padding: 1px 8px;
  width: max-content;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
  border: 1px solid ${({theme}) => theme.main};
  color: ${({theme}) => theme.main};
  background-color: ${({theme}) => theme.lightest};

  ${(props) => props.color === 'green' &&
    css`
      border: 1px solid ${({theme}) => theme.successDark};
      color: ${({theme}) => theme.success};
    `}
`

export const ProjectSelectHeader = styled.div`
  align-items: center;
  background-color: ${({theme}) => theme.light};
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 230px;
  margin-bottom: 12px;
  padding: 16px 8px;
  position: sticky;
  top: 0;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`