import { useMemo } from 'react';
import { bool, string, number, object, arrayOf } from 'prop-types';

import { ItemLabel, List, ListItem, StyledDropdown } from './styles';
import Sublist from './Sublist';
import { useStructureDropdownContext } from './StructureDropdownContext';

DropdownPanel.propTypes = {
  show: bool,
  structureTitle: string,
  structureId: number,
  structure: arrayOf(object),
};

function DropdownPanel({ show, structureTitle, structureId, structure }) {
  const { itemLabelCustomStylesFunc, handleSelect, selectedStructureItem, structureItemIcon } =
    useStructureDropdownContext();

  const hasSublist = useMemo(() => structure?.length > 0, [structure]);

  return (
    <StyledDropdown $show={show}>
      <List>
        <ListItem
          onClick={(e) => {
            e.stopPropagation();
            handleSelect({ id: 'root' }, [{ [structureTitle]: structureId }]);
          }}
        >
          <ItemLabel
            $highlighted={selectedStructureItem?.id === 'root'}
            $hasSublist={hasSublist}
            $active={true}
            $icon={structureItemIcon}
            $customStylesFunc={itemLabelCustomStylesFunc}
          >
            {structureTitle}
          </ItemLabel>
          <Sublist structureItemsArray={structure} />
        </ListItem>
      </List>
    </StyledDropdown>
  );
}

export default DropdownPanel;
