import styled from 'styled-components';

export const StyledCheckbox = styled.div`
  label {
    font-size: 14px;
    line-height: 22px;
    position: relative;
    padding-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;

    &:hover {
      color: ${({ theme }) => theme.main};
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      margin-right: 8px;
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 3px;
      width: 16px;
      height: 16px;
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
      transition: all 0.3s;
      transform: translateY(-50%);
    }
  }

  input {
    display: none;
  }

  input:disabled,
  input:disabled + label {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      color: ${({ theme }) => theme.darkest};
    }
  }

  input:checked + label {
    &::before {
      border-color: ${({ theme }) => theme.main};
      background-image: url('./../../../assets/images/icons/checkbox.svg');
      background-color: ${({ theme }) => theme.main};
    }
  }
`;
