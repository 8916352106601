import { StyledLayout } from '@shared/components';
import { useParams } from 'react-router-dom';

import PageRequestsHeader from './PageRequestsHeader';
import { ActualRequests } from './ActualRequests';
import { RequestsForMe } from './RequestsForMe';
import { RequestsFromMe } from './RequestsFromMe';

export const PageRequests = () => {
  const { request_type } = useParams();

  return (
    <StyledLayout>
      <PageRequestsHeader />

      {!request_type && <ActualRequests />}

      {request_type === 'for_me' && <RequestsForMe />}

      {request_type === 'from_me' && <RequestsFromMe />}
    </StyledLayout>
  );
};
