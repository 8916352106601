import styled from 'styled-components';

import { StyledText } from '../Text/style';

export const StyledUser = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover img {
    transform: scale(1.1);
  }
`;

export const StyledUserPic = styled.img`
  border-radius: 50%;
  transition: all 0.3s;

  ${(props) =>
    props.size === 'sm' &&
    `
    width: 24px;
    height: 24px;
  `}

  ${(props) =>
    props.size === 'md' &&
    `
    width: 32px;
    height: 32px;
  `}

  ${(props) =>
    props.size === 'xl' &&
    `
    width: 64px;
    height: 64px;
  `}
`;

export const StyledUserName = styled(StyledText)`
  font-weight: 500;
  transition: all 0.3s;

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;
