import axios from 'axios';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@shared/components';
import { StyledModal } from '@components/chat/ModalForwardMessage/style';

import { url_get_project_list } from 'src/settings/base-url';
import SnackbarContext from 'src/contexts/SnackbarContext';
import Preloader from '@components/preloaders/Preloader';
import { checkAuthError, useOutsideTrigger } from 'src/utilize/helper-functions';
import { cancelMessageSelection, clearForwardModal } from 'src/redux/features/messagesSlice';
import { useSocketContext } from 'src/contexts/SocketContext';
import ArrowIcon from 'src/assets/images/icons/arrow-black.svg';
import useLogout from 'src/hooks/useLogout';

import ProjectSelect from '../project-transfer/ProjectSelect';
import StructureDropdown from '../project-transfer/StructureDropdown/StructureDropdown';

import ForwardButton from './ForwardModalToggleButton';

const itemLabelCustomStylesFunc = (params) => {
  let stylesString = `
    padding: 2px 2px 2px 36px;
    margin-bottom: 2px;

  `;

  if (!params.$hasSublist) {
    stylesString += `&:before {
      display: none      
    };    
    `;
  }
  if (params.$active) {
    stylesString += `&:before {
                       -webkit-transform: translateY(-50%) rotate(90deg);
                       transform: translateY(-50%) rotate(90deg);
                    };
                    `;
  }
  return stylesString;
};

function ModalForwardMessage() {
  const messageForwardModalOpen = useSelector((state) => state.messages.messageForwardModalOpen);
  const singleMessageIdToForward = useSelector((state) => state.messages.singleMessageIdToForward);
  const selectedMessageIds = useSelector((state) => state.messages.selectedMessageIds);
  const [isLoading, setIsLoading] = useState(false);
  const [projectsList, setProjectsList] = useState(null);

  const [selectedProjectOption, setSelectedProjectOption] = useState();

  const { showSnackbar } = useContext(SnackbarContext);
  const { projectId, taskId } = useParams();

  const logout = useLogout();

  useEffect(() => {
    if (!messageForwardModalOpen) return;
    setIsLoading(true);
    axios
      .get(url_get_project_list)
      .then(({ data: { result } }) => {
        setProjectsList(result.reverse());

        const currentProject = result.find((project) => project.id === +projectId);
        setSelectedProjectOption(
          currentProject
            ? { label: currentProject.title, value: currentProject.id }
            : { label: 'Текущий проект', value: +projectId },
        );
      })
      .catch((error) => {
        if (checkAuthError(error)) logout();
        else showSnackbar('Возникла ошибка при получении списка проектов');
      })
      .finally(() => setIsLoading(false));
  }, [messageForwardModalOpen]);

  const getProjectOptions = useCallback(() => {
    if (!projectsList) return;
    let isCurrentProjectAvailable = false;

    const projectsOptions = projectsList.map((project) => {
      if (project.id === +projectId) isCurrentProjectAvailable = true;
      return { label: project.title, value: project.id };
    });
    if (!isCurrentProjectAvailable) {
      projectsOptions.unshift({ label: 'Текущий проект', value: +projectId });
    }
    return projectsOptions;
  }, [projectsList, projectId]);

  const [selectedTaskData, setSelectedTaskData] = useState();

  const getProjectStructure = useCallback(
    async (project_id) => {
      setSelectedTaskData(null);
      const response = await axios.get(`/api/sidebar_project_tree/project/${project_id}`);
      if (!Array.isArray(response.data?.project?.tasks)) return;
      const projectStructure = response.data.project.tasks;

      return projectStructure;
    },
    [projectId, taskId, selectedProjectOption],
  );

  const modalRef = useRef();
  const forwardModalToggleBtnRef = useRef();

  const refsArray = useMemo(() => [modalRef, forwardModalToggleBtnRef], []);

  const dispatch = useDispatch();

  const handleOutsideClick = useCallback(() => dispatch(clearForwardModal()), [dispatch]);

  useOutsideTrigger(refsArray, handleOutsideClick, messageForwardModalOpen);

  const { forwardMessage } = useSocketContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMessageForward = useCallback(() => {
    const data = {};

    if (!selectedTaskData || !selectedProjectOption) return;

    if (selectedTaskData.id === 'root') data.project_id = selectedProjectOption?.value;
    else data.task_id = selectedTaskData.id;

    if (selectedMessageIds.length) {
      const sortedIds = selectedMessageIds.toSorted((a, b) => a - b);

      let i = 0;
      data.chat_message_id = sortedIds[i];

      const socketCallback = (response) => {
        if (response.error) {
          showSnackbar('Не удалось переслать все сообщения');
          setIsSubmitting(false);
        } else if (i === sortedIds.length - 1) {
          showSnackbar('Сообщения пересланы', 'success');
          dispatch(cancelMessageSelection());
          setIsSubmitting(false);
        } else {
          i++;
          data.chat_message_id = sortedIds[i];
          forwardMessage(data, socketCallback);
        }
      };

      setIsSubmitting(true);
      forwardMessage(data, socketCallback);
    } else if (singleMessageIdToForward) {
      data.chat_message_id = singleMessageIdToForward;
      forwardMessage(data, () => {
        showSnackbar('Сообщение переслано', 'success');
        dispatch(clearForwardModal());
      });
    }
  }, [selectedTaskData, singleMessageIdToForward, selectedProjectOption, forwardMessage]);

  return (
    <>
      <StyledModal $show={messageForwardModalOpen} ref={modalRef}>
        {isLoading ? (
          <Preloader />
        ) : (
          <>
            <ProjectSelect
              getProjectOptions={getProjectOptions}
              setSelectedProjectOption={setSelectedProjectOption}
              defaultValue={selectedProjectOption}
            />

            {selectedProjectOption && (
              <div>
                <StructureDropdown
                  structureId={selectedProjectOption.value}
                  structureTitle={selectedProjectOption.label}
                  getStructure={getProjectStructure}
                  selectedStructureItem={selectedTaskData}
                  setSelectedStructureItem={setSelectedTaskData}
                  dropdownLabel="Задача"
                  errorMsg="Возникла ошибка при запросе структуры проекта"
                  sublistKey="tasks"
                  structureItemIcon={ArrowIcon}
                  itemLabelCustomStylesFunc={itemLabelCustomStylesFunc}
                />
              </div>
            )}

            <Button
              width="100%"
              disabled={isSubmitting || (!selectedTaskData && !selectedMessageIds.length)}
              onClick={handleMessageForward}
            >
              {isSubmitting ? 'Отправка...' : 'Переслать'}
            </Button>
          </>
        )}
      </StyledModal>

      <ForwardButton forwardModalToggleBtnRef={forwardModalToggleBtnRef} />
    </>
  );
}

export default ModalForwardMessage;
