import React, { useState, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation } from 'react-i18next';

import { Label } from '@shared/components/Label/Label';
import { Modal } from '@shared/components/Modal/Modal';
import { Input } from '@shared/components/Input/Input';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import ConfirmAction from '../../components/warnings/ConfirmAction';

// настройка валидации формы
const schemaDepartment = yup.object().shape({
  title: yup.string().required(),
});

const PositionFormCreateEdit = ({ action, inputs, onCreate, onClose, prevData }) => {
  const { t } = useTranslation();

  const {
    formState: { isValid, isDirty, errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(schemaDepartment),
    mode: 'onChange',
    // значение по умолчанию ставим всегда - в зависимости от выбранного action (edit или add) ставим пустое поле или уже выбранные value
    defaultValues: {
      title: action === 'edit' ? prevData.title : '',
    },
  });

  const [modalClosePrompt, setModalClosePrompt] = useState();

  const handleClose = useCallback(() => {
    if (isDirty) {
      setModalClosePrompt(true);
    } else onClose();
  }, [onClose, isDirty, setModalClosePrompt]);

  useEventTriggerOnEscPress(handleClose);

  return (
    <>
      <Modal
        title={action === 'add' ? t('Positions.create_position') : t('Positions.edit_position')}
        onClose={onClose}
        disabledSaveButton={!isValid || !isDirty}
        onSave={handleSubmit(onCreate)}
        confirmButtonText={action === 'edit' ? 'Сохранить' : 'Добавить'}
      >
        <div>
          <Label htmlFor="title">
            Название должности <sup>*</sup>
          </Label>

          <Input error={!!errors['title']} value={inputs.value} id="title" register={register('title')} />
        </div>
      </Modal>

      {modalClosePrompt && (
        <ConfirmAction
          actionText={t('common.confirm_modal_close')}
          cancel={() => setModalClosePrompt(false)}
          confirm={onClose}
        />
      )}
    </>
  );
};

export default PositionFormCreateEdit;
