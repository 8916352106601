import styled from 'styled-components';

import { Container } from '../Container';

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 14px;
  min-height: ${({ heigth }) => heigth || '400px'};
`;
