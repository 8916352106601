import { Container } from '@shared/components';
import { Avatar } from '@shared/components/Avatar';
import { useNavigate } from 'react-router-dom';

export const BirthdayCardItem = ({ birthday }) => {
  const navigate = useNavigate();

  return (
    <Container
      align={'center'}
      width={'max-content'}
      style={{ cursor: 'pointer' }}
      onClick={() => navigate(`/birthday/${birthday.id}`)}
    >
      <Avatar userId={birthday.employee.id} showProfileOnClick={false} />
      <div>
        {birthday.employee.first_name} {birthday.employee.last_name}
      </div>
    </Container>
  );
};
