import React, { useState } from 'react';

import { FilterButton, StyledList } from './styles';

export const Tabs = ({ buttons, onChange, defaultValue }) => {
  const [activeButton, setActiveButton] = useState(defaultValue);

  return (
    <StyledList>
      {buttons.map((btn) => {
        return (
          <FilterButton
            key={btn.key}
            active={btn.key === activeButton.key}
            onClick={() => {
              setActiveButton(btn);
              if (onChange) onChange(btn);
            }}
          >
            {btn.name}
          </FilterButton>
        );
      })}
    </StyledList>
  );
};
