import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import {
  StyledAsideBackwardButton,
  StyledAsideBody,
  StyledAsideHeader,
  StyledAsideLink,
  StyledAsideParentTask,
  StyledAsideSubtitle,
  StyledAsideTagsWrapper,
  StyledAsideTitle,
} from '@components/chat/side-menu/styles';

import Tag from '@shared/components/Tag/Tag';
import iconTags from '@assets/images/icons/chat-tags.svg';
import iconClose from '@assets/images/icons/close.svg';
import { IconButton } from '@shared/components';

import UserProfileModalContainer from '../../../windows/profile/UserProfileModalContainer';
import Preloader from '../../preloaders/Preloader';

import ProjectTree from './ProjectTree';
import { renderProjectOrTaskName } from './SideMenu';
import MessageAside from './sideMenuComponents/MessageAside';

const TagsSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();
  const tags = useSelector((state) => state.tags.tagsList);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [panelData, setPanelData] = useState(null);
  const [projectTree, showProjectTree] = useState(false);
  const [userProfile, showUserProfile] = useState();
  const projectOrTaskToSearch = useRef();

  const dataType = useRef();

  useEffect(() => {
    if (taskId && dataType.current !== 'task') dataType.current = 'task';
    if (!taskId && dataType.current !== 'project') dataType.current = 'project';
  }, [taskId]);

  const fetchData = async (dataTypeToSearch, dataId, tagIdToSearch) => {
    try {
      const response = await axios.get(`/api/sidebar_tags/${dataTypeToSearch}/${dataId}/${tagIdToSearch}`);
      const chat_messages = response.data.chat_messages;
      setPanelData({
        messages: chat_messages || [],
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setErrorLoading(true);
    }
  };

  const searchByTag = (tagIdToSearch) => {
    if (tagIdToSearch && dataType.current) {
      setSelectedTagId(tagIdToSearch);
      setIsLoading(true);
      setErrorLoading(false);
      setPanelData(null);

      let dataTypeToSearch, dataId;
      if (projectOrTaskToSearch.current) {
        dataTypeToSearch = projectOrTaskToSearch.current.dataType;
        dataId = projectOrTaskToSearch.current.dataId;
      } else {
        dataTypeToSearch = dataType.current;
        dataId = projectData.id;
      }

      fetchData(dataTypeToSearch, dataId, tagIdToSearch);
    }
  };

  const showRelevantTaskInfo = (dataType, dataId, dataTitle) => {
    setPanelData(null);
    showProjectTree(false);
    setErrorLoading(false);
    projectOrTaskToSearch.current = {
      dataType,
      dataId,
      title: dataTitle,
    };
    if (selectedTagId) {
      fetchData(dataType, dataId, selectedTagId);
    }
  };

  // reset данных при закрытии
  useEffect(() => {
    if (sideMenu !== 'tags' && (panelData || projectOrTaskToSearch.current)) {
      setTimeout(() => {
        setPanelData(null);
        projectOrTaskToSearch.current = null;
        setIsLoading(false);
        setErrorLoading(false);
        setSelectedTagId(null);
      }, 1000);
    }
  }, [sideMenu, panelData, projectOrTaskToSearch]);

  return (
    <>
      {userProfile && <UserProfileModalContainer profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <>
        <StyledAsideHeader>
          <StyledAsideTitle icon={iconTags} iconsize={18}>
            {t('Project.tag_search')}
          </StyledAsideTitle>
          <IconButton
            icon={iconClose}
            size={27}
            onClick={() => {
              setTimeout(() => {
                setPanelData(null);
                projectOrTaskToSearch.current = null;
                setIsLoading(false);
                setErrorLoading(false);
                setSelectedTagId(null);
              }, [1000]);
              openSideMenu(null);
            }}
          />
        </StyledAsideHeader>
        <StyledAsideBody>
          {projectTree ? (
            <ProjectTree
              update={sideMenu === 'tags'}
              showRelevantTaskInfo={showRelevantTaskInfo}
              showProjectTree={showProjectTree}
            />
          ) : (
            <>
              <StyledAsideBackwardButton onClick={() => showProjectTree(true)}>
                Структура проекта
              </StyledAsideBackwardButton>

              <StyledAsideParentTask>
                {renderProjectOrTaskName(
                  projectOrTaskToSearch.current,
                  taskId,
                  projectOrTaskToSearch.current?.dataType,
                  projectData,
                )}
              </StyledAsideParentTask>

              {projectOrTaskToSearch.current?.dataType === 'project' && taskId && (
                <StyledAsideLink to={`/projects/${projectId}`}>{t('Project.open_project')}</StyledAsideLink>
              )}

              {projectOrTaskToSearch.current?.dataType === 'task' &&
                projectOrTaskToSearch.current?.dataId !== +taskId && (
                  <StyledAsideLink to={`/projects/${projectId}/tasks/${projectOrTaskToSearch.current?.dataId}`}>
                    {t('Project.open_task')}
                  </StyledAsideLink>
                )}

              <StyledAsideTagsWrapper>
                {tags?.map((tag, i) => (
                  <Tag tag={tag} onClick={() => searchByTag(tag.id)} selectedTagId={selectedTagId} key={i} />
                ))}
              </StyledAsideTagsWrapper>

              {isLoading && <Preloader />}

              {errorLoading && <div>{t('ErrorMessages.default')}</div>}

              {panelData && <StyledAsideSubtitle>{t('common.search_results')}</StyledAsideSubtitle>}

              {panelData?.messages?.length > 0 && (
                <>
                  {panelData.messages.map((chat_message, i) => (
                    <MessageAside
                      chat_message={chat_message}
                      key={i}
                      showUserProfile={showUserProfile}
                      dataType={projectOrTaskToSearch.current?.dataType || dataType.current}
                      dataId={projectOrTaskToSearch.current?.dataId || projectData?.id}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </StyledAsideBody>
      </>
    </>
  );
};

export default TagsSidePanel;
