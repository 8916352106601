import { AddVersionModal } from '@components/AppVersions/AddVersionModal';
import { CreateTicketModal } from '@components/Tickets/TicketsFromMe';
import { useRef, useState, useCallback, useMemo } from 'react';
import useParamsCustom from 'src/hooks/urlParams/useParamsCustom';
import CreateTaskModal from 'src/components/tasks/CreateTaskModal';
import useAuth from 'src/hooks/useAuth';
import { useOutsideTrigger } from 'src/utilize/helper-functions';
import CreateEditNewsModal from 'src/windows/news/CreateEditNewsModal';
import CreatePollModal from 'src/windows/polls/CreatePollModal';
import ProjectFormCreateEdit from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEdit';
import { CreateReminderModal } from '@components/Reminders';
import { Separator } from 'src/shared/components';
import { NavControlsButton, NavControlsItem, SiteInnerLink, StyledUserNavInnerList } from '@components/nav/styles';
import iconPlus from '@assets/images/icons/plus.svg';
import { useAddSurvey } from 'src/hooks/useAddSurvey';
import { useSelector } from 'react-redux';
import { TaskType } from 'src/redux/features/projectsSlice';

const AddTaskTypeText = {
  [TaskType.REQUEST]: 'в текущей просьбе',
  [TaskType.SECTION]: 'в текущем разделе',
};

const QuickAddNav = () => {
  const { projectId, taskProjectId, taskId } = useParamsCustom([
    '/projects/:projectId',
    '/projects/:taskProjectId/tasks/:taskId',
  ]);

  const [popup, setPopup] = useState();
  const [modal, setModal] = useState();

  const quickAddBtnRef = useRef();
  const quickAddPopup = useRef();

  const refsArray = useRef([quickAddBtnRef, quickAddPopup]);

  const hidePopup = useCallback(() => {
    setPopup(false);
  }, []);

  useOutsideTrigger(refsArray.current, hidePopup, popup);

  const auth = useAuth();
  const isDeveloper = useMemo(() => auth.user?.rights?.includes('developer'), [auth]);

  const { isUserRightful, rightTypes } = useAuth();
  const hasRightToCreateProject = useMemo(
    () => isUserRightful(rightTypes.project_create),
    [isUserRightful, rightTypes],
  );

  const hasRightToCreateSurvey = useMemo(() => isUserRightful(rightTypes.survey_create), [isUserRightful, rightTypes]);

  const { handleSurveySubmit, getPollCreateData } = useAddSurvey();

  const currentTaskType = useSelector((state) => state.projects.currentTaskType);
  console.log('%c [ currentTaskType ]-58', 'font-size:13px; background:#ccc; color:blue;', currentTaskType);

  return (
    <>
      <NavControlsItem $active={popup}>
        {/* Кнопка - зеленый плюс */}
        <NavControlsButton icon={iconPlus} $add ref={quickAddBtnRef} onClick={() => setPopup(!popup)} />
        <StyledUserNavInnerList ref={quickAddPopup} $active={popup}>
          {hasRightToCreateProject && (
            <>
              <SiteInnerLink
                onClick={() => {
                  setPopup(false);
                  setModal('project');
                }}
              >
                Проект
              </SiteInnerLink>
              <SiteInnerLink
                onClick={() => {
                  setPopup(false);
                  setModal('office');
                }}
              >
                Виртуальный офис
              </SiteInnerLink>
            </>
          )}

          {(projectId || taskProjectId) && (
            <SiteInnerLink
              onClick={() => {
                setPopup(false);
                setModal('rubric');
              }}
            >
              {`Раздел ${taskId ? AddTaskTypeText[currentTaskType] || '' : 'в текущем проекте'}`}
            </SiteInnerLink>
          )}

          {(projectId || taskProjectId) && (
            <SiteInnerLink
              onClick={() => {
                setPopup(false);
                setModal('request');
              }}
            >
              {`Просьба ${taskId ? AddTaskTypeText[currentTaskType] || '' : 'в текущем проекте'}`}
            </SiteInnerLink>
          )}
          {/*<SiteInnerLink*/}
          {/*  onClick={() => {*/}
          {/*    setPopup(false);*/}
          {/*    setModal('ticket');*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Поручение*/}
          {/*</SiteInnerLink>*/}
          <SiteInnerLink
            onClick={() => {
              setPopup(false);
              setModal('reminder');
            }}
          >
            Напоминание
          </SiteInnerLink>

          <Separator />
          {/* <span
            onClick={() => {
              setPopup(false);
              setModal('poll');
            }}
          >
            Опрос
          </span> */}
          {auth?.isUserRightful('news_add') && (
            <SiteInnerLink
              onClick={() => {
                setPopup(false);
                setModal('news');
              }}
            >
              Новость
            </SiteInnerLink>
          )}
          <SiteInnerLink
            onClick={() => {
              setPopup(false);
              setModal('blog');
            }}
          >
            Блог
          </SiteInnerLink>

          {hasRightToCreateSurvey && (
            <SiteInnerLink
              onClick={() => {
                setPopup(false);
                setModal('poll');
              }}
            >
              Опрос
            </SiteInnerLink>
          )}

          {isDeveloper && (
            <>
              <Separator />
              <SiteInnerLink
                onClick={() => {
                  setPopup(false);
                  setModal('version');
                }}
              >
                Версия портала
              </SiteInnerLink>
            </>
          )}
        </StyledUserNavInnerList>
      </NavControlsItem>

      {modal && (
        <>
          {modal === 'project' && <ProjectFormCreateEdit modalType={modal} data={{}} onClose={() => setModal(false)} />}
          {modal === 'office' && <ProjectFormCreateEdit modalType={modal} data={{}} onClose={() => setModal(false)} />}
          {modal === 'rubric' && (
            <CreateTaskModal
              modalType={modal}
              projectId={projectId || taskProjectId}
              parentTaskId={taskId}
              close={() => setModal(false)}
            />
          )}

          {modal === 'request' && (
            <CreateTaskModal
              modalType={modal}
              projectId={projectId || taskProjectId}
              parentTaskId={taskId}
              close={() => setModal(false)}
            />
          )}
          {(modal === 'news' || modal === 'blog') && (
            <CreateEditNewsModal modalType={modal} close={() => setModal(false)} />
          )}
          {modal === 'ticket' && <CreateTicketModal onClose={() => setModal(false)} />}
          {modal === 'version' && <AddVersionModal onClose={() => setModal(false)} />}
          {modal === 'reminder' && <CreateReminderModal onClose={() => setModal(false)} />}
          {modal === 'poll' && (
            <CreatePollModal
              close={() => setModal(false)}
              submitRequest={handleSurveySubmit}
              getPollCreateDataRequest={getPollCreateData}
              isSurvey
            />
          )}
        </>
      )}
    </>
  );
};

export default QuickAddNav;
