import { StyledLayout } from '@shared/components/Layout/styles';
import { Title } from '@shared/components/Title/Title';
import React, { useMemo, useState } from 'react';

import moment from 'moment';

import { useGetVersionsQuery } from 'src/redux/features/api/versions';

import Preloader from '@components/preloaders/Preloader';
import iconAdd from '@assets/images/icons/plus.svg';
import { ButtonWithIcon } from '@shared/components';

import useAuth from 'src/hooks/useAuth';

import { AddVersionModal } from '@components/AppVersions/AddVersionModal';

import { StyledTable, StyledTitle, StyledTitleWrapper } from './styles';

export const PageVersions = () => {
  const [visible, setVisible] = useState(false);
  const { data, isLoading } = useGetVersionsQuery();

  const auth = useAuth();
  const isDeveloper = useMemo(() => auth.user?.rights?.includes('developer'), [auth]);

  return (
    <>
      {isDeveloper ? (
        <StyledLayout>
          <StyledTitleWrapper>
            <StyledTitle>Версии портала</StyledTitle>
            <ButtonWithIcon color="green" position="left" icon={iconAdd} size={20} onClick={() => setVisible(true)}>
              Добавить
            </ButtonWithIcon>
          </StyledTitleWrapper>

          {isLoading ? (
            <Preloader />
          ) : (
            <StyledTable>
              <thead>
                <tr>
                  <th>Версия</th>
                  <th>Дата и время</th>
                </tr>
              </thead>
              <tbody>
                {data.versions?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.version}</td>
                    <td>{moment(item.date_created).format('LLL')}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          )}
        </StyledLayout>
      ) : (
        <StyledLayout>
          <Title>Недостаточно прав для просмотра данной страницы</Title>
        </StyledLayout>
      )}

      {visible && <AddVersionModal onClose={() => setVisible(false)} />}
    </>
  );
};
