import { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';
import {Dropdown, StyledDropdownInput, StyledSelect, StyledSelectItem} from "@components/storage/styles";

import SnackbarContext from '../../contexts/SnackbarContext';
import Preloader from '../preloaders/Preloader';

// если нужно, чтобы дропдаун "толкал" вниз остальной контент при открытии, то в position можно ничего не передавать
// если нужно, чтобы выпадало поверх контента - передать "absolute"

const StoragesDropdown = ({ handleAnotherStorageSelect, selectedStorageTitle, position }) => {
  const [showDropdown, setShowDropdown] = useState();
  const [storagesList, setStoragesList] = useState();
  const { storageId } = useParams();

  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (showDropdown && !storagesList) {
      axios
        .get('/api/storages_list')
        .then((r) => setStoragesList(r.data.result))
        .catch(() => showSnackbar('Возникла ошибка при загрузке списка хранилищ'));
    }
  }, [showSnackbar, storagesList, showDropdown]);

  return (
    <Dropdown $show={showDropdown}>
      <StyledDropdownInput onClick={() => setShowDropdown(!showDropdown)}>
        {selectedStorageTitle || 'Выбрать другое хранилище'}
      </StyledDropdownInput>

      <StyledSelect position={position}>
        {!storagesList && (
          <li>
            <Preloader />
          </li>
        )}
        {storagesList?.map((storage, i) => {
          if (storage.id === +storageId) return '';
          return (
            <StyledSelectItem
              key={i}
              onClick={() => {
                handleAnotherStorageSelect(storage);
                setShowDropdown(false);
              }}
            >
              <div>
                <b>{storage.title}</b>
                <p>{storage.project ? `К проекту: ${storage.project.title}` : 'Независимое'}</p>
              </div>
            </StyledSelectItem>
          );
        })}
      </StyledSelect>
    </Dropdown>
  );
};

export default StoragesDropdown;
