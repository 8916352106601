import React, { useContext, useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { url_get_data_create_project, url_get_project_edit } from '../../../settings/base-url';
import useAuth from '../../../hooks/useAuth';
import Preloader from '../../../components/preloaders/Preloader';

import ProjectFormCreateEditBody from './ProjectFormCreateEditBody';

const formatOptions = (arr) => {
  if (!Array.isArray(arr) || !arr.length) return null;

  return arr.map((el) => {
    return {
      label: (el.last_name || '') + ' ' + el.first_name,
      value: (el.last_name || '') + ' ' + el.first_name,
      id: el.id,
    };
  });
};

const formatTags = (arr) => {
  if (!Array.isArray(arr) || !arr.length) return null;

  return arr.map((el) => {
    return {
      label: el.name,
      value: el.name,
      color: el.color,
      id: el?.tag_id || el.id,
    };
  });
};

const ProjectFormCreateEdit = (props) => {
  const { data, onClose, modalType } = props;

  const { editable } = data || null; // если редактируем окно, editable - id редактироуемого  проекта

  // то, что я получаю с сервера при построении модалки редактирования - инфа о проекте и все сотрудники и тд
  const [dataForEditProject, setDataForEditProject] = useState(null);
  const auth = useAuth();
  const { t } = useTranslation();
  const translateKey = 'Project'; // для перевода

  // данные с сервера дл построения модалки - список сотрудников и тэги
  const [membersTags, setMembersTags] = useState({
    members: [], // члены команды
    tags: [],
  });

  const { showSnackbar } = useContext(SnackbarContext);

  // загрузка данных для создания
  useEffect(() => {
    if (editable) {
      return;
    }
    axios.get(url_get_data_create_project).then(
      (res) => {
        setMembersTags({
          members: res.data.result.members,
          tags: res.data.result.tags,
        });
      },
      () => {
        showSnackbar('Возникла ошибка при загрузке данных для создания проекта');
      },
    );
  }, [auth.token, editable]);

  // получаем пред значения о редактируемом проекте
  useEffect(() => {
    // если у нас нет editable - тогда отрабатывает useeffect для  создания модалки
    if (!editable) {
      return;
    }
    const { projectId } = props.data.editable;
    const headers = {
      Authorization: auth.token,
      project_id: projectId,
    };
    axios.get(url_get_project_edit + '/' + projectId, { headers }).then(
      (res) => {
        setDataForEditProject(res.data.result);
      },
      () => {
        showSnackbar('Возникла ошибка при загрузке данных о редактируемом проекте');
      },
    );
  }, [auth.token, editable]);

  const { title, description, members } = dataForEditProject || {};

  const inputs = {
    title: {
      name: 'title_project',
      label: t(`${translateKey}.title`) + '*',
      type: 'text',
      prev: title || null,
    },
    description: {
      name: 'description',
      label: t(`${translateKey}.description`),
      type: 'text',
      prev: description || null,
    },
    // участники и роли мультселект
    attendee: {
      name: 'attendee',
      label: '',
      // label: t(`${translateKey}.attendee_role`),
      type: 'select',
      subType: 'tag',
      options: formatOptions(members) || formatOptions(membersTags?.members) || [],
    },
    // отв и исполнитель
    // prev в селект устанавливается в get def value
    responsible: {
      name: 'responsible',
      label: '',
      //label: t(`${translateKey}.responsible`) + '*',
      type: 'select',
      subType: 'tag',
      options: members || membersTags?.members || [],
    },
    // исполнитель
    executor: {
      name: 'executor',
      label: '',
      //label: t(`${translateKey}.executor`) + '*',
      type: 'select',
      subType: 'tag',
      options: members || membersTags?.members || [],
    },
    deadlines: {
      label: t(`${translateKey}.deadlines`),
      deadline_start: {
        name: 'deadline_start',
        placeholder: t('Project.start_date'),
      },
      deadline_end: {
        name: 'deadline_end',
        placeholder: t('Project.end_date'),
      },
    },
    tags: {
      name: 'tags',
      label: '',
      // placeholder:'Выбрать тэги',
      // label: t(`${translateKey}.attendee_role`),
      type: 'select',
      subType: 'tag',
      options: formatTags(dataForEditProject?.tags) || formatTags(membersTags?.tags) || [],
    },
  };
  //
  return (
    <>
      {/* если  у нас редактирование и данные о редактируемом проекте загрузились */}
      {editable && dataForEditProject && (
        <ProjectFormCreateEditBody
          inputs={inputs}
          onClose={onClose}
          // project  data нужна потому что нет доступа к setting формы в getDefValues для установки editable value
          projectData={dataForEditProject}
          getProjectData={props.getProjectData}
          modalType={modalType}
        />
      )}
      {/* прелоадер когда редактируем проект и данные о проекте не загрузились */}
      {editable && !dataForEditProject && <Preloader />}
      {/* прелоадер когда создаем окно и данные не загрузились */}
      {!membersTags && !editable && <Preloader />}

      {/* когда создаем проект и данные для создания уже загрузились, рисуем компонент */}
      {!editable && membersTags && <ProjectFormCreateEditBody inputs={inputs} onClose={onClose} modalType={modalType} />}
    </>
  );
};

export default memo(ProjectFormCreateEdit);
