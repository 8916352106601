import { memo } from 'react';
import { number } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Checkbox } from '@shared/components';
import { toggleMessageSelection } from 'src/redux/features/messagesSlice';

MessageSelectionFC.propTypes = {
  messageId: number,
};

function MessageSelectionFC({ messageId }) {
  const dispatch = useDispatch();
  const isSelected = useSelector((state) => state.messages.selectedMessageIds.some((id) => id === messageId));

  return (
    <Checkbox
      style={{ marginTop: '16px' }}
      onChange={() => dispatch(toggleMessageSelection(messageId))}
      id={messageId}
      checked={isSelected}
    />
  );
}

const MessageSelection = memo(MessageSelectionFC);

export default MessageSelection;
