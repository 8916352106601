import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import {localDateNumberFormat} from 'src/utilize/helper-functions';
import {Avatar} from '@shared/components/Avatar';
import {renderMessage} from 'src/utilize/draftjs-helper-functions';
import AttachedFile from '@components/files/AttachedFile';
import {useFormatMessageFiles, useNavigateToMessage} from 'src/utilize/message-helper-functions';
import ImageGallery from 'src/windows/images/ImageGallery';
import {
  StyledFilesItem, StyledResultDate,
  StyledTagsWrapper, StyledUsername,
  StyledUserWrapper
} from "@components/chat/side-menu/sideMenuComponents/styles";
import {StyledResultStatus} from "@components/chat/message/styles";
import Tag from "@shared/components/Tag/Tag";
import {Container} from "@shared/components";

const MessageAside = ({chat_message, showUserProfile, dataType, dataId}) => {
  const {
    message_tags,
    employee_id,
    employee_first_name,
    employee_last_name,
    date_created,
    editor_state,
    message_files,
    id,
    chat_goal_results,
    title,
  } = chat_message;
  const {t} = useTranslation();
  const auth = useAuth();

  const {allAttachedFiles, gallerySourcesArr} = useFormatMessageFiles(message_files, id, auth?.token);
  const [openImgId, setOpenImgId] = useState(null);

  const navigateToMessage = useNavigateToMessage(dataType, dataId, id);

  return (
    <>
      <StyledFilesItem data-link="true" onClick={navigateToMessage}>
        {chat_goal_results?.length > 0 && (
          <StyledResultStatus status="pending">{t('Project.result.pending')}</StyledResultStatus>
        )}

        {message_tags.length > 0 && (
          <StyledTagsWrapper>
            {message_tags.map((tag, i) => (
              <Tag tag={tag} key={i}/>
            ))}
          </StyledTagsWrapper>
        )}
        <Container justify="space-between" align="center" gap="8px">
          <StyledUserWrapper
            $isGuest={auth?.user?.is_guest}
            // to={`/team/users/${employee_id}`}
            onClick={(e) => {
              e.stopPropagation();
              if (!auth?.user?.is_guest) showUserProfile(employee_id);
            }}
          >
            <Avatar
              userId={employee_id}
              lastName={employee_last_name}
              firstName={employee_first_name}
              showProfileOnClick={false}
            />
            <StyledUsername>
              {`${employee_first_name || ''} ${employee_last_name || ''}`}
            </StyledUsername>{' '}
          </StyledUserWrapper>

          <StyledResultDate>{localDateNumberFormat(date_created)}</StyledResultDate>
        </Container>

        {title && (
          <div>
            <strong>{title}</strong>
          </div>
        )}
        {renderMessage(editor_state)}

        {message_files?.length > 0 &&
          <Container wrap="wrap" gap="8px" align="center" onClick={(e) => e.stopPropagation()}>
            {allAttachedFiles.map((file, i) => {
              return (
                <AttachedFile
                  fileUrl={file.url}
                  fileId={file.id}
                  fileName={file.file}
                  key={i}
                  storageFileId={file.from_storage}
                  setOpenImgId={setOpenImgId}
                />
              );
            })}
          </Container>
        }
      </StyledFilesItem>

      {openImgId && gallerySourcesArr?.length && (
        <ImageGallery imgItems={gallerySourcesArr} openImgId={openImgId} close={() => setOpenImgId(null)}/>
      )}
    </>
  );
};

export default MessageAside;
