import { styled } from 'styled-components';

export const TeamTreeWrapper = styled.section`
  position: relative;

  & li ul {
    position: relative;
  }

  & li ul li {
    margin-top: 40px;
    padding-left: 60px;
    
    &::before {
      border-bottom: 1px dashed ${({theme}) => theme.disabled};
      content: "";
      left: 32px;
      position: absolute;
      top: 32px;
      width: 27px;
    }
  }
  
  
`;

export const TeamTreeTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  background-color: ${({theme}) => theme.lightest};
  margin-bottom: 32px;
`;

export const StyledTeamDepartment = styled.section`
  padding: 40px 0;
  border-bottom: 1px solid ${({theme}) => theme.borderSecondary};
`
