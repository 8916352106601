import styled from 'styled-components';

export const StyledWarningBox = styled.div`
  background-color: ${({ theme }) => theme.errorLightest};
  font-size: 12px;
  line-height: 22px;
  padding: 9px 16px 9px 42px;
  position: relative;

  &::before {
    background-image: url(${(props) => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 14px;
    left: 17px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
  }
`;
