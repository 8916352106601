import { useState, memo, useContext } from 'react';

import SnackbarContext from '../../contexts/SnackbarContext';

import { useMessageContext } from '../../contexts/MessageContext';
import useAuth from '../../hooks/useAuth';

const DropFilesWrapper = (props) => {
  const { guestRights } = useMessageContext();
  const auth = useAuth();

  return (
    <DropFiles {...props} disabled={(auth?.user?.is_guest && !guestRights?.includes('add_files')) || props.disabled} />
  );
};

const DropFiles = memo(({ children, setFiles, setDroppedItems, disabled }) => {
  const [dragging, setDragging] = useState();

  const { showSnackbar } = useContext(SnackbarContext);

  return (
    <div
      style={{ display: 'contents' }}
      onDragOver={(e) => {
        e.preventDefault();
        if (disabled) return;
        if (!dragging && e.dataTransfer.types.includes('Files')) {
          setDragging(true);
        }
      }}
      onDrop={(e) => e.preventDefault()}
    >
      {children}

      <div
        style={{
          backgroundColor: 'white',
          position: 'fixed',
          height: '100vh',
          width: '100vw',
          top: 0,
          left: 0,
          zIndex: 5,
          fontSize: '70px',
          textAlign: 'center',
          lineHeight: '80vh',
          display: `${dragging && !disabled ? 'block' : 'none'}`,
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={() => {
          setDragging(false);
        }}
        onDrop={async (e) => {
          e.preventDefault();
          // if (disabled) return;
          if (e.dataTransfer.files.length) {
            if (setFiles) {
              const items = e.dataTransfer.items;
              // проверить поддерживается ли браузером функция webkitGetAsEntry
              if (typeof items[0].webkitGetAsEntry !== 'function') {
                setFiles([...e.dataTransfer.files]);
              } else {
                const filesToDrop = [];
                const filePromises = [];
                for (let i = 0; i < items.length; i++) {
                  const entry = items[i].webkitGetAsEntry();
                  if (!entry?.isFile) {
                    continue;
                  }
                  // filesToDrop.push(e.dataTransfer.files[i]);
                  const filePromise = new Promise((resolve, reject) => {
                    entry.file(
                      (file) => {
                        filesToDrop.push(file);
                        resolve();
                      },
                      (e) => reject(e),
                    );
                  });
                  filePromise.catch(() => {
                    showSnackbar('Возникла ошибка при чтении файла');
                  });

                  filePromises.push(filePromise);
                }
                await Promise.all(filePromises);

                setFiles(filesToDrop);
              }
            }
            if (setDroppedItems) setDroppedItems(e.dataTransfer.items);
          }
          setDragging(false);
        }}
      >
        <div
          style={{
            border: '2px dashed #D9D9D9',
            borderRadius: '10px',
            margin: '10vh',
          }}
        >
          Сбросьте файлы сюда
        </div>
      </div>
    </div>
  );
});

export default DropFilesWrapper;
