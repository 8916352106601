import { colors } from 'src/variables';
import styled from 'styled-components';

export const StyledRow = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: minmax(120px, 420px) 70px 70px minmax(120px, 280px) 32px;
  grid-column-gap: 14px;
  justify-content: space-between;
`;

export const StyledTextWrapper = styled.div`
  max-height: 20px;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};

  p {
    white-space: nowrap; /* Текст не переносится */
    overflow: hidden; /* Обрезаем всё за пределами блока */
    text-overflow: ellipsis; /* Добавляем многоточие */
  }
`;

export const StyledText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${colors.dark};
  padding-top: 6px;
`;

export const StyledMenu = styled.div`
  min-width: 260px;
  font-weight: 500;

  p {
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const StyledMenuItem = styled.p`
  border: none;
  cursor: pointer;
  display: block;
  padding: 8px 16px;
  text-align: left;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.mainLight};
  }
`;
