import { func } from 'prop-types';
import { Modal } from '@shared/components';
import NotFoundComponent from '@shared/components/NotFoundComponent/NotFoundComponent';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';

NotFoundModal.propTypes = {
  onClose: func,
};

export default function NotFoundModal({ onClose }) {
  useEventTriggerOnEscPress(onClose);

  return (
    <Modal withoutButtons onClose={onClose}>
      <NotFoundComponent small />
    </Modal>
  );
}
