import { object, arrayOf } from 'prop-types';
import SelectMulty from '@components/form/select/SelectMulty';
import { CustomOption } from '@components/form/select/userSelectComponents';
import { Subtitle } from '@shared/components';
import { memo, useMemo } from 'react';

const selectData = {
  name: 'employees',
};

EmployeesMultySelectFC.propTypes = {
  control: object,
  employees: arrayOf(object),
};

function EmployeesMultySelectFC({ control, employees }) {
  const options = useMemo(() => {
    return employees?.map((e) => ({
      label: `${e.last_name ? e.last_name + ' ' : ''}${e.first_name}`,
      value: { id: e.id },
    }));
  }, [employees]);

  return (
    <div>
      <Subtitle>Работают с</Subtitle>
      <SelectMulty
        item={selectData}
        control={control}
        options={options}
        customOption={CustomOption}
        getOptionValue={(option) => option.value.id}
      />
    </div>
  );
}
const EmployeesMultySelect = memo(EmployeesMultySelectFC);

export default EmployeesMultySelect;
