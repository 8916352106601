import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { Input, Label, PhoneInput } from '@shared/components';

const EmployeesFormCol = (props) => {
  const { setValue, name } = props;
  const { label, prev, isRequired } = props.settings;
  const { field, fieldState } = useController(props);

  //  предыдущее значение поля, если мы редактируем
  useEffect(() => {
    if (prev) {
      setValue(`${name}`, prev);
    }
  }, [prev, name, setValue]);

  if (name === 'contacts_phone') {
    return (
      <div>
        <Label>Телефон</Label>
        <PhoneInput value={field.value} onChange={field.onChange} />
      </div>
    );
  }

  return (
    <div>
      <Label htmlFor={name}>
        {label} {isRequired && <sup>*</sup>}{' '}
      </Label>
      <Input error={fieldState.error} id={name} {...field} ref={null} />
    </div>
  );
};

export default EmployeesFormCol;
