import { Avatar } from '@shared/components/Avatar';
import styled from 'styled-components';

const StyledPosition = styled.div`
  font-size: 12px;
  color: #8c8c8c;
`;

const Entry = (props) => {
  const { mention, theme, searchValue, isFocused, ...parentProps } = props;
  return (
    <div {...parentProps}>
      <div className={theme?.['chat-userlist__item']}>
        <div className="chat-userlist__item">
          <Avatar userId={mention.userId} firstName={mention.firstName} lastName={mention.lastName} />
          {/* <img className="chat-userlist__userpic" src={Avatar} alt="" /> */}
          <div>
            <span className="chat-userlist__username">{mention.name}</span>
            <StyledPosition>{mention.position}</StyledPosition>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entry;
