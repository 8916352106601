import { useRef, useEffect } from 'react';
import { Modal } from '@shared/components/Modal/Modal';
import { Button } from '@shared/components/Button/Button';
import { StyledModalFooter } from '@shared/components/Modal/style';
import { Avatar } from '@shared/components/Avatar';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import { Container } from '@shared/components';
import { useTranslation } from 'react-i18next';

import { StyledGrid, StyledText } from './styles';

const ConfirmNonMembersModal = ({ cancel, confirm, selectedEmployees }) => {
  useEventTriggerOnEscPress(cancel);

  const confirmRef = useRef();

  useEffect(() => {
    confirmRef.current.focus();
  }, []);

  const { t } = useTranslation();

  return (
    <Modal onClose={cancel} withoutButtons>
      <StyledText style={{ textAlign: 'center' }} size={3}>
        {t('Chat.addSelectedNonMembers', { count: selectedEmployees.length })} Вы уверены?
      </StyledText>
      <StyledGrid>
        {Array.isArray(selectedEmployees) &&
          selectedEmployees?.map((employee) => (
            <Container key={employee.value.id} align="center" textAlign="left">
              <Avatar userId={employee.value.id} />
              {employee.value.name}
            </Container>
          ))}
      </StyledGrid>
      <StyledModalFooter>
        <Button minWidth="153px" color="green" onClick={confirm} ref={confirmRef} tabIndex={1}>
          Уверен
        </Button>
        <Button width="153px" color="red" onClick={cancel} tabIndex={2}>
          Не уверен
        </Button>
      </StyledModalFooter>
    </Modal>
  );
};

export default ConfirmNonMembersModal;
