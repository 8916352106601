import React from 'react';

import { DocsViewer } from '@shared/widgets/PreviewModal/ui/DocsViewer';

import { PluginContainer, Wrapper } from './styles';

export const PagePreview = () => {
  return (
    <Wrapper>
      <PluginContainer>
        <DocsViewer />
      </PluginContainer>
    </Wrapper>
  );
};
