import { styled, css } from 'styled-components';

const StyledRequestGrid = styled.div`
  display: grid;
  grid-template-columns: 30px 70px minmax(220px, 0.7fr) 130px 130px 130px minmax(200px, 1fr);
  gap: 16px;
  align-items: center;
  padding: 10px;
  /* width: min-content; */

  @media (max-width: 1060px) {
    width: min-content;
  }
`;

export const StyledRequestItem = styled(StyledRequestGrid)`
  margin-bottom: 2px;
  transition: 0.3s ease-out;

  &:hover {
    background-color: rgba(24, 144, 255, 0.15);
  }

  ${({ $overdue }) =>
    $overdue &&
    css`
      background-color: rgba(245, 34, 45, 0.2);

      &:hover {
        background-color: rgba(245, 34, 45, 0.3);
      }
    `}

  ${({ $deadlineToday }) =>
    $deadlineToday &&
    css`
      background-color: rgba(225, 169, 95, 0.2);

      &:hover {
        background-color: rgba(225, 169, 95, 0.3);
      }
    `}
`;

export const StyledRequestHeader = styled(StyledRequestGrid)`
  color: ${({ theme }) => theme.dark};
  margin: 28px 0 14px;

  @media (max-width: 700px) {
    margin: 14px 0 8px;
  }
`;

const secondaryTextStyles = css`
  color: ${({ theme }) => theme.dark};
  font-size: 12px;
`;

export const StyledSecondaryDiv = styled.div`
  ${secondaryTextStyles}
`;

export const StyledWarningImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const StyledGridWrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

export const StyledEllipsedText = styled.div`
  overflow: hidden;
  hyphens: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
`;

export const StyledEllipsedSecondaryText = styled(StyledEllipsedText)`
  ${secondaryTextStyles}
`;

export const StyledProjectTitle = styled(StyledEllipsedText)`
  width: 300px;

  ${({ $secondary }) => $secondary && secondaryTextStyles}

  @media (max-width: 1200px) {
    width: 200px;
  }
`;
