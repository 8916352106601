import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/ru.json';
import './styles.css';

const PhoneInputWrapper = ({ value, onChange }) => {
  return (
    <PhoneInput
      placeholder="7 (916) 099 11 22"
      country={'ru'}
      localization={ru}
      value={value}
      onChange={(value, country, e, formattedValue) => onChange(formattedValue)}
      inputClass="inputClass"
    />
  );
};

export default PhoneInputWrapper;
