import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAsideSubtitle } from '@components/chat/side-menu/styles';
import Tag from '@shared/components/Tag/Tag';

const SideMenuTags = ({ tags }) => {
  const { t } = useTranslation();
  if (tags && tags.length) {
    return (
      <>
        <StyledAsideSubtitle>{t('common.tags')}</StyledAsideSubtitle>
        {tags.map((tag, i) => {
          return <Tag tag={tag} key={i} />;
        })}
      </>
    );
  }
  return null;
};

export default memo(SideMenuTags);
