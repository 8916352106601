import styled, {css} from "styled-components";

export const EmployeeStatus = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  padding: 8px 10px;
  align-items: center;
  gap: 12px 24px;
  
  @media (min-width: 578px) {
    grid-template-columns: 1fr 220px;
  }
  
  ${props => props.status === "fired" && css`
      background-color: ${({theme}) => theme.errorLight};
  `}

  ${props => props.status === "leaving" && css`
      background-color: ${({theme}) => theme.errorLightest};
  `}

  ${props => props.status === "info" && css`
      background-color: ${({theme}) => theme.lightest};
  `}

  ${props => props.status === "go-vacation" && css`
      background-color: ${({theme}) => theme.warningLight};
  `}
  
  ${props => props.status === "on-vacation" && css`
      background-color: ${({theme}) => theme.successLight};
  `}
`

export const EmployeeButtonBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  @media (min-width: 578px) {
    justify-content: flex-end;
  }
`

export const EmployeeStatusInfo = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  gap: 12px 24px;

  @media (min-width: 578px) {
    grid-template-columns: 1fr 1fr;
  }
`