import {forwardRef, useImperativeHandle} from 'react';
import {toast} from 'react-toastify';
import {object} from 'prop-types';

import {Text} from '@shared/components/Text/Text';
import {StyledSnackbar} from '@components/warnings/Snackbar/styles';
import iconError from 'src/assets/images/icons/error.svg';
import iconWarning from 'src/assets/images/icons/warning.svg';
import iconSuccess from 'src/assets/images/icons/checkbox-green.svg';



const Snackbar = forwardRef(({snackbarDetails}, ref) => {
  const getIcon = (type) => {
    switch (type) {
      case 'success':
        return iconSuccess;
      case 'warning':
        return iconWarning;
      default:
        return iconError;
    }
  };

  useImperativeHandle(ref, () => ({
    show() {
      toast(
        <StyledSnackbar
          type={snackbarDetails.current?.type}
          icon={getIcon(snackbarDetails.current?.type)}
        >
          <Text tag="p" size={3}>
            {snackbarDetails.current.message}
          </Text>
        </StyledSnackbar>,
        {
          position: 'bottom-left',
          closeOnClick: true,
          style: {padding: 0, minHeight: 'auto'},
          toastId: snackbarDetails.current.message,
          hideProgressBar: true,
          autoClose: snackbarDetails.current.keepOpen ? false : 5000,
        },
      );
    },
  }));

  return null;
});

Snackbar.propTypes = {
  snackbarDetails: object,
};

export default Snackbar;
