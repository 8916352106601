import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Modal } from '@shared/components/Modal/Modal';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import { Checkbox } from '@shared/components/Checkbox/Checkbox';
import { Label, Textarea } from '@shared/components';
import SelectInput from 'src/components/form/form-select/SelectInput';
import {
  useDismissEmployeeMutation,
  useEditEmployeeDimissalMutation,
  useGetDismissalReasonsQuery,
} from 'src/redux/features/api/employees';
import useAuth from 'src/hooks/useAuth';
import SnackbarContext from 'src/contexts/SnackbarContext';

import DismissalDatePicker from './DismissalDatePicker';

const schemaDismissal = yup.object().shape({
  isImmediate: yup.string().nullable(),
  reason: yup.object().required(),
  date: yup.date().required(),
  marks: yup.string().nullable(),
});

const marks = {
  name: 'mark',
  label: 'Пометка',
  type: 'text',
};

// модалка увольнения сотрудника
const EmployeeDismissal = ({ close, employeeData, getUserData, toEdit }) => {
  const { data } = useGetDismissalReasonsQuery();
  const [trigger, { isLoading }] = useDismissEmployeeMutation();
  const [editDismissal, { isLoading: isEditing }] = useEditEmployeeDimissalMutation();

  const reasons = useMemo(() => {
    return {
      name: 'reason',
      options: data
        ? data.employeeDismissalReasons.map((reason) => ({
            value: reason.id,
            label: reason.title,
          }))
        : [],
      type: 'text',
      placeholder: 'Выбрать причину увольнения',
    };
  }, [data]);

  const {
    register,
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schemaDismissal),
    mode: 'onChange',
    defaultValues: {
      date: null,
      marks: '',
      isImmediate: false,
    },
  });

  // данные о последнем увольнении
  const lastDismissal = useMemo(() => {
    if (!employeeData?.dismissal.length) return null;
    return employeeData.dismissal[employeeData.dismissal.length - 1];
  }, [employeeData]);

  // при редактировании увольнения, установить соответствующие данные
  useEffect(() => {
    if (!toEdit || !lastDismissal || lastDismissal.canceled) return;

    reset({
      reason: { label: lastDismissal.reason, value: lastDismissal.reason_id },
      date: new Date(lastDismissal.date_dismissed),
      marks: lastDismissal.marks,
    });
  }, [toEdit, lastDismissal]);

  const auth = useAuth();

  const { showSnackbar } = useContext(SnackbarContext);

  const onSubmit = async (data) => {
    if (!employeeData) return;
    const row = {
      employee_id: employeeData.employee[0].id,
      reason_id: data.reason.value,
      dismissed_by_id: auth.user.id,
      date_dismissed: moment(data.date).format('YYYY-MM-DD'),
      marks: data.marks,
    };

    const response =
      toEdit && lastDismissal
        ? await editDismissal({ row, employee_dismissal_id: lastDismissal.id })
        : await trigger({ row });
    if ('error' in response) showSnackbar('Возникла ошибка при увольнении сотрудника');
    else {
      getUserData();
      close();
    }
  };

  return (
    <Modal
      title="Увольнение сотрудника"
      onClose={close}
      onSave={handleSubmit(onSubmit)}
      disabledSaveButton={!isValid || isLoading || isEditing}
      confirmButtonText="Уволить"
    >
      <StyledModalRowEnd>
        <div>
          <Label>
            Не работает с даты <sup> * </sup>
          </Label>
          <DismissalDatePicker control={control} />
        </div>
        <Checkbox id="immediate" register={register('isImmediate')}>
          Немедленно
        </Checkbox>
      </StyledModalRowEnd>
      <div>
        <Label>
          Причина <sup> * </sup>
        </Label>
        <SelectInput data={reasons} formattedOptions={reasons.options} control={control} />
      </div>
      <Controller
        control={control}
        name="marks"
        render={({ field }) => {
          return <Textarea onChange={field.onChange} value={field.value} settings={marks} rows="6" id={marks.name} />;
        }}
      />
    </Modal>
  );
};

export default EmployeeDismissal;
