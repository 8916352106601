import { Reminder } from '@shared/components/Reminder';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setDisplayedReminders, setReminders } from 'src/redux/features/remindersSlice';

export const AllReminders = () => {
  const dispatch = useDispatch();
  const reminders = useSelector((state) => state.allReminders.reminders);
  const displayedReminders = useSelector((state) => state.allReminders.displayedReminders);

  useEffect(() => {
    reminders.forEach((newReminder) => {
      if (!displayedReminders.find((item) => item.id === newReminder.id)) {
        const reminderPosition = toast(({ closeToast }) => (
          <Reminder
            reminder={newReminder}
            displayedReminders={displayedReminders}
            onClose={() => {
              setReminders((prev) => prev.filter((item) => item.id !== newReminder.id));
              closeToast();
            }}
          />
        ));

        dispatch(
          setDisplayedReminders([
            ...displayedReminders,
            { ...newReminder, isDisplayed: true, position: reminderPosition },
          ]),
        );
      }
    });
  }, [reminders, displayedReminders]);

  return <></>;
};
