import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { AuthContext } from 'src/contexts/AuthContext';
import { logoutAction } from 'src/redux/features/usersSlice';

function AuthProvider(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUserData] = useState(null);
  const [token, setTokenData] = useState(null);

  const setToken = useCallback((tokenData) => {
    setTokenData(tokenData);

    if (tokenData) Cookies.set('auth-token', tokenData);
    else Cookies.remove('auth-token');
  }, []);

  // когда в форме логина получаем user и token, отправляем колбэк => все улетело в куки и контекст, становясь доступным всему приложению
  const setUser = useCallback((userData) => {
    setUserData(userData);

    if (userData) Cookies.set('auth-user', JSON.stringify(userData));
    else Cookies.remove('auth-user');
  }, []);

  const loadData = useCallback(async () => {
    const tokenData = Cookies.get('auth-token');
    let userData = Cookies.get('auth-user');

    try {
      userData = JSON.parse(userData);
    } catch (e) {}
    if (tokenData && userData) {
      setTokenData(tokenData);
      setUserData(userData);
    } else if (window.Telegram?.WebApp?.initData) {
      await axios
        .post('/api/sign_in_telegram', { init_data: window.Telegram.WebApp.initData })
        .then((response) => {
          setUser({
            name: response.data.first_name || '',
            surname: response.data.last_name || '',
            id: response.data.user_id,
            company_id: response.data.company_id,
            rights: response.data.rights,
            is_guest: response.data.is_guest,
          });
          setToken(response.data.token);
        })
        .catch((e) => console.log(e));
    }

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    setUser(null);
    setToken(null);
    dispatch(logoutAction());
  }, [setToken, setUser, dispatch]);

  const rightTypes = useRef({
    team_edit: 'team_edit',
    team_hidden_fields: 'team_hidden_fields',
    positions_edit: 'positions_edit',
    departments_edit: 'departments_edit',
    salary_view: 'salary_view',
    payouts_view: 'payouts_view',
    payouts_edit: 'payouts_edit',
    add_news: 'add_news',
    contacts_edit: 'contacts_edit',
    links_edit: 'links_edit',
    project_create: 'project_create',
    survey_create: 'survey_create',
  });

  const isUserRightful = useCallback(
    (rightToCheck) => user?.rights?.includes('leader') || user?.rights?.includes(rightToCheck),
    [user],
  );

  const contextValue = useMemo(
    () => ({
      isLoaded,
      user,
      token,
      setUser,
      setToken,
      logOut,
      isUserRightful,
      rightTypes: rightTypes.current,
    }),
    [isLoaded, user, token, setToken, setUser, logOut, isUserRightful],
  );

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}

export default AuthProvider;
