import { useFieldArray } from 'react-hook-form';
import { StyledModalFlex, Subtitle } from '@shared/components';

import ContactCardEditable from './ContactCardEditable';

import { StyledContactsWrapper } from './styles';

import AddContactSubForm from './AddContactSubForm';

function CreateEditContactsList({ control, setValue, reset, register }) {
  const { fields, append, remove, update } = useFieldArray({ control, name: 'contacts' });

  return (
    <>
      <StyledContactsWrapper>
        <StyledModalFlex style={{ marginLeft: '24px' }}>
          <Subtitle>
            Контактные лица<sup>*</sup>
          </Subtitle>
        </StyledModalFlex>

        {fields.map((contact, index) => {
          if (contact.toDelete) return null;
          return (
            <ContactCardEditable
              key={contact.id}
              control={control}
              index={index}
              contact={contact}
              update={update}
              remove={remove}
            />
          );
        })}
      </StyledContactsWrapper>
      <AddContactSubForm
        control={control}
        append={append}
        update={update}
        setValue={setValue}
        resetParent={reset}
        registerParent={register}
      />
    </>
  );
}

export default CreateEditContactsList;
