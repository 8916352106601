import CalendarForm from '@shared/components/CalendarForm/CalendarForm';
import { useEffect } from 'react';
import { useController, useWatch } from 'react-hook-form';

const date = {
  name: 'date',
  icon: true,
  type: 'text',
};

const DismissalDatePicker = ({ control }) => {
  const isImmediate = useWatch({ control, name: 'isImmediate' });
  const { field } = useController({ control, name: 'date' });

  useEffect(() => {
    if (isImmediate !== 'immediate') return;
    field.onChange(new Date());
  }, [isImmediate]);
  return <CalendarForm data={date} control={control} disabled={isImmediate} />;
};

export default DismissalDatePicker;
