import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { Input, Label } from '@shared/components';

const EmployeesTextarea = (props) => {
  const { prev, setValue, height } = props;
  const { field, fieldState } = useController(props);
  const { label, name, isRequired } = props.settings;

  // отображаем предыдущее значение поля, если мы редактируем
  useEffect(() => {
    if (prev) {
      if (name !== 'password') {
        setValue(`${name}`, prev);
      }
    }
  }, [prev, name, setValue]);

  return (
    <div>
      <Label htmlFor={name}>
        {label} {isRequired && <sup>*</sup>}{' '}
      </Label>
      <Input error={fieldState.invalid} id={name} style={{ height: height }} {...field} />
    </div>
  );
};

export default EmployeesTextarea;
