import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import './index.css';
import App from './components/app/app';
import AuthProvider from './providers/auth-provider/AuthProvider';
import { SocketContextProvider } from './contexts/SocketContext';
import './i18n';
import { store } from './redux/store';
import { colors } from './variables';
import swDev from './swDev.js';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={colors}>
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <AuthProvider>
            <SocketContextProvider>
              <App />
            </SocketContextProvider>
          </AuthProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
);

swDev();
