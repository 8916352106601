import { Container } from '@shared/components';
import styled from 'styled-components';

export const Wrapper = styled(Container)`
  width: 100%;
  overflow: hidden;
  border-radius: 12px;

  margin-top: -18px;

  justify-content: center;
  align-self: center;

  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
`;

export const StyledContainer = styled(Container)`
  flex-direction: column;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  overflow: hidden;
`;

export const PreviewHeader = styled(Container)`
  padding: 14px 24px;
  align-items: center;
  gap: 36px;
  background-color: #f2f9ff;
  justify-content: space-between;
  width: 100%;

  button {
    border-radius: 8px !important;
    padding: 4px 20px;
    min-width: 160px;
  }
`;
