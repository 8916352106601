import styled, {css} from 'styled-components';
import {StyledInput} from "@shared/components/Input/style";


export const StyledIconSearch = styled.button`
  background-color: ${({ theme }) => theme.lightest};
  border: 1px solid ${({ theme }) => theme.border};
  border-bottom-right-radius: 2px;
  border-left: transparent;
  border-top-right-radius: 2px;
  flex: 0 0 40px;
  padding: 0;
  position: relative;
  width: 40px;

  &::before {
    content: '';
    height: 16px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    background-image: url(${(props) => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${(props) => (props.$isLoading ? 'linear 0.5s infinite searching' : 'unset')};
    filter: ${(props) =>
  props.$isLoading
    ? 'invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%)'
    : 'unset'};
  }

  @keyframes searching {
    0% {
      transform: translate(-70%, -50%);
    }
    25% {
      transform: translate(-50%, -70%);
    }
    50% {
      transform: translate(-30%, -50%);
    }
    75% {
      transform: translate(-50%, -30%);
    }
    100% {
      transform: translate(-70%, -50%);
    }
  }
`;

export const StyledSearch = styled.div`
  display: flex;
  
  ${props => props.size === "sm" && css`
    ${StyledInput} {
      font-size: 12px;
      line-height: 20px;
      padding: 1px 1px 1px 8px;
    }
    
    ${StyledIconSearch} {
      flex: 0 0 24px;
      position: relative;
      width: 24px;
      border-left: none;
      
      &::before {
        width: 16px;
        height: 16px;
      }
    }
  `}
`;
