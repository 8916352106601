import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoute from '../../routes/components/PrivateRoute';

import { MessageContextProvider } from '../../contexts/MessageContext';

import ProjectList from './project-list/ProjectList';
import ProjectOrTaskPage from './single-project/ProjectOrTaskPage';

const ProjectsRoutes = (props) => {
  // три роута для:
  // 1) списка проектов 2) детализации индивидуального проекта 3) детализация индивидуального таска
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <main>
              <ProjectList {...props} />
            </main>
          </PrivateRoute>
        }
      />

      <Route
        path="/:projectId"
        element={
          <PrivateRoute guestAccess={true}>
            <MessageContextProvider>
              <ProjectOrTaskPage />
            </MessageContextProvider>
          </PrivateRoute>
        }
      />

      <Route
        path="/:projectId/tasks/:taskId"
        element={
          <PrivateRoute>
            <MessageContextProvider>
              <ProjectOrTaskPage />
            </MessageContextProvider>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default ProjectsRoutes;
