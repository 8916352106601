import React, { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';

import eyeOpen from '@assets/images/icons/eye-visible.svg';
import eyeClose from '@assets/images/icons/eye-invisible.svg';
import iconValid from '@assets/images/icons/checkbox-green.svg';
import {
  StyledBubble,
  StyledErrorText,
  StyledIconEye,
  StyledIconValid,
  StyledInput
} from '@components/form/FormField/styles';

const nameTranslations = {
  title: 'название',
};

const Bubble = ({ text }) => {
  return (
    <StyledBubble>{text}</StyledBubble>
  );
};

const FormInput = ({ props }) => {
  const { field, fieldState } = useController(props);
  const { settings, action, prev, setValue } = props;
  const { name, icon, bubble, placeholder } = settings;
  const [passwordShown, setPasswordShown] = useState('password');
  // отображаем предыдущее значение поля, если мы редактируем
  useEffect(() => {
    if (prev) {
      setValue(`${name}`, prev);
    }
  }, [prev, name, setValue]);

  // нуна ли иконка скрытия пароля
  const hideInputValue = name === 'password' && action !== 'restorePassword' ? true : false;

  // клик на скрытие пароля (глаз)
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown === 'text' ? 'password' : 'text');
  };

  return (
    <div>
      <StyledInput
        type={hideInputValue ? passwordShown : 'text'}
        placeholder={placeholder}
        $error={fieldState.error}
        {...field}
      />
      {icon &&
        (fieldState.invalid ? (
          <StyledErrorText>Введите {nameTranslations[name] || name}</StyledErrorText>
        ) : (
          fieldState.isDirty && <StyledIconValid icon={iconValid} />
        ))}

      {bubble && <Bubble text={bubble} />}

      {hideInputValue && (
        <StyledIconEye
          icon={passwordShown === 'text' ? eyeOpen : eyeClose}
          size={18}
          onClick={togglePasswordVisiblity}
        />
      )}
    </div>
  );
};

export default FormInput;
