import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar } from '@shared/components/Avatar';
import 'swiper/css';
import 'swiper/css/pagination';

import { StyledPinnedMessage, StyledPinnedText } from '@components/chat/message/PinnedMessage/styles';

import iconFile from '@assets/images/icons/icon-file.svg';
import { IconButton } from '@shared/components';

import { getOldMessages, setColoredMsgId, setIsScrolledChatBottom } from '../../../../redux/features/messagesSlice';
import MessagePoll from '../MessagePoll/MessagePoll';

export const PinnedMessage = ({ message, projectData, swiperRef }) => {
  const dispatch = useDispatch();
  const goToMessage = (messageId) => {
    if (!messageId) return;
    const foundMessage = document.getElementById(`messageId${messageId}`);
    if (foundMessage) foundMessage.scrollIntoView({ block: 'center' });
    else dispatch(getOldMessages({ chat_id: projectData.chat.id, old_message_id: messageId }));
    dispatch(setColoredMsgId(messageId));
    dispatch(setIsScrolledChatBottom(false));
  };

  const goToMessageHandler = (id) => {
    goToMessage(id);
    if (swiperRef.current) {
      const currentIndex = swiperRef.current.activeIndex;
      const totalSlides = swiperRef.current.slides.length;

      if (currentIndex === 0) {
        swiperRef.current.slideTo(totalSlides - 1);
      } else {
        swiperRef.current.slidePrev();
      }
    }
  };

  const votedCount = useMemo(() => {
    if (
      typeof message?.props.poll?.all_vote_count !== 'number' ||
      typeof message?.props.poll.poll_results.refused_vote_count !== 'number'
    ) {
      return 0;
    }

    return message?.props.poll.all_vote_count + message?.props.poll.poll_results.refused_vote_count;
  }, [message?.props.poll]);

  return (
    <StyledPinnedMessage onClick={() => goToMessageHandler(message.props.id)}>
      <Avatar
        userId={message.props.employee_id}
        firstName={message.props.employee_first_name}
        lastName={message.props.employee_last_name}
      />
      {message.props.forwarded && (
        <>
          <IconButton icon={iconFile} size={16} style={{ marginRight: '8px' }} />
          Пересланное сообщение
        </>
      )}

      {message?.props.message_files?.length > 0 && (
        <>
          <IconButton icon={iconFile} size={16} style={{ marginRight: '8px' }} />
          Прикрепленные файлы ({message.props.message_files.length})
        </>
      )}
      <StyledPinnedText>{message.props.message}</StyledPinnedText>

      {message.props.poll && (
        <MessagePoll pollData={message.props.poll} votedCount={votedCount} message={message.props} />
      )}
    </StyledPinnedMessage>
  );
};
