import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false,
  isTicketsLoaded: false,
};

export const ticketsSlice = createSlice({
  name: 'ticketsForMe',
  initialState,
  reducers: {
    showTicketsModal: (state) => {
      state.visible = true;
      state.isTicketsLoaded = true;
    },
    hideTicketsModal: (state) => {
      state.visible = false;
    },
  },
});

export const { hideTicketsModal, showTicketsModal } = ticketsSlice.actions;

export default ticketsSlice.reducer;
