import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {IconButton} from "@shared/components";
import iconFolder from "@assets/images/icons/folder.svg"
import iconFolderNew from "@assets/images/icons/interface-folder-add.svg";
import iconFileNew from "@assets/images/icons/interface-file-add.svg";
import iconArrow from "@assets/images/icons/icon-arrow-back.svg";

import {
  StorageBreadcrumbs,
  StorageBreadcrumbsItem, StorageButtonBack,
  StorageContent,
  StorageHeader, StorageHeaderButtons,
  StorageTitle
} from "@components/storage/styles";

import { setOpenFolder } from '../../redux/features/storageSlice';

import FoldersAndFilesList from './FoldersAndFilesList';

const FolderDetails = ({
  setAddFolderModal,
  setAddFilesModal,
  renderUserName,
  handleFolderSelect,
  setOpenFolderId,
  noURLParams,
  storageIdToReq,
  addStorageFileReference,
  attachFolderReference,
}) => {
  const { openFolder, storageSearchResult, folderPath, storageTree, storageData } = useSelector(
    (state) => state.storage,
  );

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.employees);
  const navigate = useNavigate();

  // рендерить результаты поиска, если был поиск или текущую открытую папку
  const listToRender = useMemo(() => {
    if (storageSearchResult) return storageSearchResult;
    return openFolder;
  }, [openFolder, storageSearchResult]);

  if (!listToRender || !users) return null;
  return (
    <StorageContent>
      <StorageHeader>
        {!storageSearchResult && (
          <StorageBreadcrumbs>
            {folderPath?.map((path, i) => {
              for (const title in path) {
                return (
                  <StorageBreadcrumbsItem
                    key={i}
                    onClick={() => handleFolderSelect(path[title])}
                    $active={i === folderPath.length - 1}
                  >
                    {title}
                  </StorageBreadcrumbsItem>
                );
              }
              return null;
            })}
          </StorageBreadcrumbs>
        )}
        {listToRender.title && <StorageTitle tag="h2" size={4} icon={iconFolder}>{listToRender.title}</StorageTitle>}
        {storageData?.can_write && !storageSearchResult && (
          <StorageHeaderButtons>
            <IconButton
              size={24}
              icon={iconFolderNew}
              title="Добавить папку"
              onClick={() => setAddFolderModal(true)}
            />

            <IconButton
              size={22}
              icon={iconFileNew}
              title="Добавить файл"
              onClick={() => setAddFilesModal(true)}
            />
          </StorageHeaderButtons>
        )}
      </StorageHeader>
      <section>
        {!storageSearchResult && (openFolder.storage_id || openFolder.folder_id) && (
          <StorageButtonBack
            icon={iconArrow}
            onClick={() => {
              const parentFolderId = openFolder.folder_id;

              if (noURLParams) {
                if (parentFolderId) {
                  setOpenFolderId(parentFolderId);
                  return;
                }
                dispatch(setOpenFolder({ openFolder: storageTree, path: null }));
                setOpenFolderId(null);
                return;
              }

              navigate(`/storages/${storageIdToReq}${parentFolderId ? '/folder/' + parentFolderId : ''}`);
            }}
          >
            На уровень выше
          </StorageButtonBack>
        )}
        <FoldersAndFilesList
          openFolder={listToRender}
          renderUserName={renderUserName}
          handleFolderSelect={handleFolderSelect}
          storageIdToReq={storageIdToReq}
          storageData={storageData}
          attachFolderReference={attachFolderReference}
          addStorageFileReference={addStorageFileReference}
        />
      </section>
    </StorageContent>
  );
};

export default FolderDetails;
