import { object, number } from 'prop-types';
import { Label } from '@shared/components';
import { RichTextEditor } from '@components/RichTextEditor';
import { useController } from 'react-hook-form';

TextFieldArrayItem.propTypes = {
  infoField: object,
  index: number,
  control: object,
};

function TextFieldArrayItem({ infoField, index, control }) {
  const {
    field: { value, onChange },
  } = useController({ control, name: `partner_company_infos.${index}.value` });

  const handleChange = (editorState) => {
    if (editorState.getLastChangeType() === null) return;
    onChange(editorState);
  };

  return (
    <div>
      <Label>{infoField.name}</Label>
      <RichTextEditor value={value} onChange={handleChange} />
    </div>
  );
}

export default TextFieldArrayItem;
