import { useRef, useEffect } from 'react';

import { Modal } from '@shared/components/Modal/Modal';
import { Text } from '@shared/components/Text/Text';
import { Button } from '@shared/components/Button/Button';

import { StyledModalFooter } from '@shared/components/Modal/style';

import ModalPortal from '@shared/components/Modal/ModalPortal';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';

const ConfirmAction = ({
  actionText,
  cancel,
  confirm,
  isSubmitting,
  confirmButtonText,
  cancelButtonText,
  isSuccess,
}) => {
  useEventTriggerOnEscPress(cancel);

  const confirmRef = useRef();

  useEffect(() => {
    confirmRef.current.focus();
  }, []);

  return (
      <Modal onClose={cancel} withoutButtons>
        <Text style={{ textAlign: 'center' }} size={3}>
          {actionText}
        </Text>
        <StyledModalFooter>
          <Button
            minWidth={'153px'}
            color={isSuccess ? 'green' : 'red'}
            onClick={confirm}
            disabled={isSubmitting}
            ref={confirmRef}
            tabIndex={1}
          >
            {confirmButtonText || 'Закрыть'}
          </Button>
          <Button width={'153px'} color={isSuccess ? 'red' : 'green'} onClick={cancel} tabIndex={2}>
            {cancelButtonText || 'Отмена'}
          </Button>
        </StyledModalFooter>
      </Modal>
  );
};

export default ConfirmAction;
