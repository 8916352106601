import { Title } from '@shared/components';

import TaskFilters from './TaskFilters';
import TaskList from './TaskList';
import { TasksContainer } from './style';

const Tasks = () => {
  return (
    <main>
      <TasksContainer>
        <Title>Мои задачи</Title>
        <TaskFilters />
        <TaskList />
      </TasksContainer>
    </main>
  );
};

export default Tasks;
