import { useState } from 'react';
import {ButtonWithIcon, Container, IconButton} from '@shared/components';
import iconAdd from '@assets/images/icons/plus.svg';
import iconFilter from "@assets/images/icons/filter.svg";
import useAuth from 'src/hooks/useAuth';

import CreateEditContacts from './create-edit/CreateEditContacts';
import { StyledTitle, StyledTitleWrapper } from './styles';


const ContactsPageHeader = ({setIsFiltersOpen}) => {
  const [createModal, setCreateModal] = useState();
  const auth = useAuth();

  return (
    <>
      <StyledTitleWrapper>
        <StyledTitle>Контакты клиентов и партнеров</StyledTitle>
        {auth.isUserRightful('contacts_edit') && (

          <Container gap={'12px'} align={'center'}>
        <IconButton
          style={{backgroundSize: '24px'}}
          size={40}
          background="#1890FF"
          icon={iconFilter}
          onClick={() => setIsFiltersOpen((isFiltersOpen) => !isFiltersOpen)}
        />
        <ButtonWithIcon
          color="green"
          position="left"
          icon={iconAdd}
          size={20}
          onClick={() => setCreateModal(true)}
        >Добавить контакт
        </ButtonWithIcon>
      </Container>
        )}
      </StyledTitleWrapper>

      {createModal && <CreateEditContacts close={() => setCreateModal(false)} />}
    </>
  );
};

export default ContactsPageHeader;
