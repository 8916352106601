import { Container, Text } from '@shared/components';
import Tag from '@shared/components/Tag/Tag';
import React, { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { HrLine } from '@shared/components/Hr/styles';
import { ExternalLink } from '@shared/components/ExternalLink';
import { files_url } from 'src/settings/base-url';
import { removeParagraphTags, renderHTMLString, transform } from 'src/utilize/draftjs-helper-functions';

import { colors } from 'src/variables';

import {StyledContactCard, StyledContactName, StyledImageWrapper, StyledLogo, StyledTagsWrapper} from './styles';

export const ContactsListV2 = ({ partners }) => {
  const auth = useAuth();
  const getPartnerImg = ({ partnerId, fileName }) =>
    `${files_url}/partners/files/${partnerId}/${fileName}?token=${auth.token}`;

  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <section>
      {partners?.map((partner) => (
        <Fragment key={partner.id}>
          <StyledContactCard key={partner.id} onClick={() => navigate(`${partner.id}${search}`)}>
            <StyledImageWrapper>
              {partner.image && <StyledLogo src={getPartnerImg({ partnerId: partner.id, fileName: partner.image })} />}
            </StyledImageWrapper>
            <Container direction={'column'} gap={'12px'}>
              <Container direction={'column'}>
                <StyledContactName>{partner.title}</StyledContactName>
                {partner.link && (
                  <ExternalLink
                    href={`${partner.link.indexOf('http') === 0 ? partner.link : `//${partner.link}`}`}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {partner?.link}
                  </ExternalLink>
                )}
              </Container>
              {!!partner.partner_tags.length && (
                <StyledTagsWrapper direction={'column'} gap={'4px'}>
                  {partner.partner_tags.map((tag) => (
                    <Tag key={tag.id} tag={tag} />
                  ))}
                </StyledTagsWrapper>
              )}
            </Container>
            <div>
              {partner.partner_groups.map((group) => (
                <Text key={group.id} size={2}>
                  Группа: {group.title}
                </Text>
              ))}
            </div>
            <Container direction={'column'} gap={'15px'}>
              {partner.partner_additional_information.map((field) => {
                if (!field.include) return null;
                if (field.type === 'link') {
                  return (
                    <Container key={field.id} direction={'column'} gap={'4px'}>
                      <Text size={2} color={colors.grey}>
                        {field.name}:
                      </Text>
                      <ExternalLink
                        href={`//${field.value}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {field.value}
                      </ExternalLink>
                    </Container>
                  );
                }
                return (
                  <Container key={field.id} direction={'column'} gap={'4px'}>
                    <div>
                      <Text size={2} color={colors.grey}>
                        {field.name}:
                      </Text>
                    </div>
                    <div>
                      {Array.isArray(field.value) ? (
                        <>
                          {field.value.map((item, index) => (
                            <Text
                              key={item.id}
                              size={2}
                              tag={'span'}
                              color={colors.darkestSecondary}
                              style={{ listStyleType: 'disc', listStylePosition: 'inside' }}
                            >
                              {item.value}
                              {field.value.length === index + 1 ? '' : ', '}
                            </Text>
                          ))}
                        </>
                      ) : (
                        <Text key={field.id} size={2} color={colors.darkestSecondary}>
                          {field.type === 'text'
                            ? renderHTMLString(removeParagraphTags(field.value), { replace: (node) => transform(node) })
                            : field.value}
                        </Text>
                      )}
                    </div>
                  </Container>
                );
              })}
            </Container>
          </StyledContactCard>
          <HrLine />
        </Fragment>
      ))}
    </section>
  );
};
