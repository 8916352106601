import styled from 'styled-components';
export const StyledFavList = styled.ul`
  border: 1px solid ${({ theme }) => theme.borderSecondary};
`;

export const StyledFavItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 10px 16px;
  position: relative;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.light};
  }
`;

export const LinksWrapper = styled.div`
  // line-height: 12px;
  // display: flex;
  // flex-direction: column;
`;

export const StyledTitle = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;
