import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { t } from 'i18next';

import { Button } from '@shared/components/Button/Button';

import { StyledResultGrid } from '../styles';

import SelectInput from '../../../../components/form/form-select/SelectInput';

import ResultTextareaField from './ResultTextareaField';

const schemaCreateEmployees = yup.object().shape({
  // first_name: yup.string().required(),
});

const getDefValues = (prevData) => {
  // эти поля долдны совпадать с настройками в пропсах формы
  return {
    // нельзя ставить null обычному input ругается консоль
    result_description: '',
    type_of_result: prevData?.type
      ? { label: prevData?.type, value: prevData?.type }
      : { label: t('common.file'), value: 'file' },
  };
};

//prevData - предыдущее состояние проекта при редактировании
const ResultTextareaBlock = ({ setResult, editedItemResult }) => {
  const { t } = useTranslation();
  // настройки формы
  const input = {
    result: {
      name: 'result_description',
      label: t('Project.description'),
      type: 'text',
    },
    typeOfResult: {
      name: 'type_of_result',
      label: '',
      type: 'select',
      options: [
        { label: t('Goals.file'), value: 'file' },
        { label: t('Goals.text'), value: 'text' },
      ],
    },
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schemaCreateEmployees),
    mode: 'onChange',
    defaultValues: getDefValues(editedItemResult),
  });

  //  отправляем данные о поле или предыдущие данные, если редактируем
  const onSubmitCreate = (data) => {
    // если редактируем, второй параметр - id редактируемого
    if (editedItemResult) {
      setResult(
        {
          description: data.result_description || editedItemResult.title,
          type: data.type_of_result?.value || editedItemResult.type,
          id: editedItemResult.id,
          goal_id: editedItemResult.goal_id,
          isEdited: Boolean(editedItemResult.goal_id),
        },
        editedItemResult.id,
      );
    } else {
      setResult({
        description: data.result_description,
        type: data.type_of_result.value,
        id: Date.now(),
      });
    }
    reset({
      result_description: '',
      type_of_result: data.type_of_result,
    });
  };

  return (
    <StyledResultGrid>
      <ResultTextareaField
        setValue={setValue}
        editedItemResult={editedItemResult}
        control={control}
        settings={input.result}
        name={input.result.name} // name нужно для настройки react hook form
        height={114}
      />
      {/* выпадающий список */}
      <div style={{ display: 'grid', gap: '12px' }}>
        <SelectInput
          setValue={setValue}
          value="label"
          prev={editedItemResult}
          control={control}
          data={input.typeOfResult}
        />
        <Button onClick={handleSubmit(onSubmitCreate)} width="100%">
          {editedItemResult ? t('FormButtons.edit') : t('FormButtons.add')}
        </Button>
      </div>
    </StyledResultGrid>
  );
};

export default ResultTextareaBlock;
