// import { useTranslation } from 'react-i18next';

import { showLocalDateTime } from '../../utilize/helper-functions';

const LocalDateTime = ({ dateTime }) => {
  //eslint-disable-next-line
  // const { i18n } = useTranslation();

  return showLocalDateTime(dateTime, null, true);
};

export default LocalDateTime;
