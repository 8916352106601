import { useCallback } from 'react';
import { useSocketContext } from 'src/contexts/SocketContext';

import useAuth from './useAuth';

const useLogout = () => {
  const auth = useAuth();
  const { deinitSocket } = useSocketContext();

  const logout = useCallback(() => {
    if (auth?.logOut) auth.logOut();
    if (deinitSocket) deinitSocket();
  }, [auth?.logOut, deinitSocket]);

  return logout;
};

export default useLogout;
