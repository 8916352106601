import { Container, Text } from '@shared/components';
import Preloader from '@components/preloaders/Preloader';

import { searchTypes } from '@components/nav/lib';

import { useMemo } from 'react';

import { StyledList, StyledTitle } from './styles';
import { CardProjectOrTask } from './ui/CardProjectOrTask';
import { CardMessage } from './ui/CardMessage';
import { CardFile } from './ui/CardFile';
import { CardFolder } from './ui/CardFolder';
import { getFilesFromChats } from './lib';
import { CardFileFromChat } from './ui/CardFileFromChat';

export const SearchResults = ({ isLoading, searchResults, onClose }) => {
  const filesFromChats = useMemo(() => {
    if (searchResults?.searchType === searchTypes.fileInChat) return getFilesFromChats(searchResults);
  }, [searchResults]);

  return (
    <Container direction="column">
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          {searchResults && (
            <>
              <StyledTitle id="search_results_title">Результаты</StyledTitle>

              <StyledList>
                {searchResults.searchType === searchTypes.projectOrTask &&
                  [...searchResults.projects, ...searchResults.tasks].map((item, index) => {
                    return <CardProjectOrTask key={index} index={index} item={item} />;
                  })}

                {searchResults.searchType === searchTypes.message &&
                  [...searchResults.projects, ...searchResults.tasks].map((item, index) => {
                    return <CardMessage key={index} index={index} item={item} onClose={onClose} />;
                  })}

                {searchResults.searchType === searchTypes.fileOrFolder && (
                  <>
                    {!!searchResults.folders.length && <Text size={3}>Папки: </Text>}
                    {searchResults.folders.map((folder, index) => {
                      return <CardFolder key={index} index={index} folder={folder} onClose={onClose} />;
                    })}

                    {!!searchResults.files.length && <Text size={3}>Файлы: </Text>}
                    {searchResults.files.map((file, index) => {
                      return <CardFile key={index} index={index} file={file} onClose={onClose} />;
                    })}
                  </>
                )}

                {searchResults.searchType === searchTypes.fileInChat && (
                  <>
                    <Text size={3}>Файлы: </Text>
                    {filesFromChats.map((file, index) => {
                      return (
                        <CardFileFromChat
                          key={index}
                          index={index}
                          file={file}
                          searchType={searchResults.searchType}
                          onClose={onClose}
                        />
                      );
                    })}
                  </>
                )}
              </StyledList>
            </>
          )}
        </>
      )}
    </Container>
  );
};
