import React from 'react';

import ru from 'date-fns/locale/ru';

import { registerLocale } from 'react-datepicker';

import { StyledDatePicker, StyledDateWrapper } from './style';

const DateInput = ({ placeholder, dateFormat, size, selected, onChange, minDate, maxDate, ...props }) => {
  registerLocale('ru', ru);

  const onChangeHandler = (value) => onChange(value);

  return (
    <StyledDateWrapper>
      <StyledDatePicker
        {...props}
        popperProps={{ strategy: 'fixed' }}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        size={size}
        onChange={onChangeHandler}
        selected={selected}
        locale="ru"
        isClearable
        autoComplete="off"
      />
    </StyledDateWrapper>
  );
};

export default DateInput;
