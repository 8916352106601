import { useMemo } from 'react';
import { Modal } from '@shared/components/Modal/Modal';
import Preloader from '@components/preloaders/Preloader';
import useAuth from 'src/hooks/useAuth';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';

import { StyledModalGrid, StyledModalGridCell, StyledModalGridHeader } from '../../departments/department-list/styles';

import GroupItem from './GroupItem';

const GroupList = (props) => {
  const { list, onEdit, onClose, onAdd, isFetching } = props;

  const { isUserRightful, rightTypes } = useAuth();

  const hasRightToEdit = useMemo(() => isUserRightful(rightTypes.contacts_edit), [isUserRightful, rightTypes]);

  useEventTriggerOnEscPress(onClose);

  const headerButtons = [{ name: 'Добавить', action: onAdd }];

  return (
    <Modal
      modalSize="768px"
      title={'Группы контактов'}
      onClose={onClose}
      headerButtons={hasRightToEdit && headerButtons}
      withoutButtons
    >
      <StyledModalGrid grid="3fr 100px">
        <StyledModalGridHeader>
          <StyledModalGridCell>Название группы</StyledModalGridCell>
          {hasRightToEdit && <StyledModalGridCell align="right">Действия</StyledModalGridCell>}
          {(!list || isFetching) && <Preloader />}
        </StyledModalGridHeader>
        {list?.map((item, i) => (
          <GroupItem key={i} item={item} onEdit={onEdit} hasRightToEdit={hasRightToEdit} />
        ))}
      </StyledModalGrid>
    </Modal>
  );
};

export default GroupList;
