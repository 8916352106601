import { bool, func, string } from 'prop-types';

import { StyledFormTab } from './styles';

FormTab.propTypes = {
  open: bool,
  handler: func,
  value: string,
};

function FormTab({ open, handler, value }) {
  return <StyledFormTab open={open} onClick={handler}>{`${open ? '-' : '+'}  ${value}`}</StyledFormTab>;
}

export default FormTab;
