import { MultipleSelect } from '@shared/components/Select/Select';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { components } from 'react-select';

import useAuth from 'src/hooks/useAuth';
import { files_url } from 'src/settings/base-url';
import { StyledMockAvatar } from '@shared/components/Avatar/styles';
import { Avatar } from '@shared/components/Avatar';

import { Label } from '@shared/components';

import { useController } from 'react-hook-form';

import { Wrapper } from './styles';

export const getOptions = (users, token) => {
  if (!users) return [];
  const options = users.map((user) => ({
    label: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    value: {
      id: user.id,
      name: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
      pic: !!user.avatar
        ? `${files_url}/employees/avatar/${user.id}/small_${user.avatar}?token=${token}`
        : StyledMockAvatar,
    },
  }));

  return options;
};

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Wrapper align={'center'}>
        {props.data?.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.Option>
  );
};

const CustomValue = ({ children, ...props }) => {
  return (
    <components.MultiValue {...props}>
      <Wrapper align={'center'}>
        {props.data.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.MultiValue>
  );
};

const EmployeesSelect = ({ control }) => {
  const auth = useAuth();
  const users = useSelector((state) => state.users.employees);

  const options = useMemo(() => getOptions(users, auth.token), [users]);

  const { field } = useController({ name: 'employees', control });

  return (
    <div>
      <Label>Видят ссылку</Label>
      <MultipleSelect
        {...field}
        placeholder="Выбрать пользователей"
        options={options}
        components={{ Option: CustomOption, MultiValue: CustomValue }}
      />
    </div>
  );
};

export default EmployeesSelect;
