import { useCallback, useContext, useEffect, useState } from 'react';
import { boolean, object } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@shared/components/Modal/Modal';
import { Checkbox } from '@shared/components/Checkbox/Checkbox';
import { Container } from '@shared/components';
import {
  useNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from 'src/redux/features/api/notifications';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import Preloader from '@components/preloaders/Preloader';

const formSchema = object({
  notify_by_email: boolean(),
  notify_by_telegram: boolean(),
});

const NotificationsSettings = ({ close }) => {
  const { showSnackbar } = useContext(SnackbarContext);
  // для валидации и изменения state без re-rendering при вводе данных
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      notify_by_email: false,
      notify_by_telegram: false,
    },
  });

  const [trigger, { isLoading }] = useUpdateNotificationSettingsMutation();

  const submitRequest = async (data) => {
    const response = await trigger({
      row: {
        notify_by_email: data.notify_by_email,
        notify_by_telegram: data.notify_by_telegram,
      },
    });
    if ('error' in response) showSnackbar('Возникла ошибка при обновлении настроек уведомлений');
  };

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handleModalClose = useCallback(() => {
    if (isDirty) {
      setModalClosePrompt(true);
    } else close();
  }, [isDirty, close]);

  useEventTriggerOnEscPress(handleModalClose);

  const { data, isFetching } = useNotificationSettingsQuery();

  useEffect(() => {
    if (data?.notify_by_telegram || data?.notify_by_email) {
      reset({
        notify_by_email: Boolean(data.notify_by_email),
        notify_by_telegram: Boolean(data.notify_by_telegram),
      });
    }
  }, [data]);

  return (
    <Modal
      title="Отправка уведомлений"
      onClose={handleModalClose}
      disabledSaveButton={!isValid || !isDirty || isLoading}
      onSave={handleSubmit(submitRequest)}
      confirmButtonText={'Сохранить'}
    >
      {isFetching && <Preloader />}

      {!isFetching && (
        <div>
          <Container direction="column" gap="2rem">
            <Controller
              control={control}
              name="notify_by_email"
              render={({ field }) => (
                <Checkbox
                  id="notify_by_email"
                  checked={field.value}
                  onChange={field.onChange}
                  disabled={!data?.contacts_email}
                >
                  На почту {` ${data.contacts_email || '(email не указан в карточке работника)'}`}
                </Checkbox>
              )}
            />

            <Controller
              control={control}
              name="notify_by_telegram"
              render={({ field }) => (
                <Checkbox
                  id="notify_by_telegram"
                  disabled={!data?.telegram}
                  checked={field.value}
                  onChange={field.onChange}
                >
                  На телеграм бот{' '}
                  {`(${
                    data?.telegram ? 'ваш телеграм: ' + data?.telegram : 'ваш телеграм не указан в карточке работника'
                  })`}
                </Checkbox>
              )}
            />
          </Container>
        </div>
      )}

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={close}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
    </Modal>
  );
};

export default NotificationsSettings;
