import { Controller } from 'react-hook-form';
import { SingleSelect } from '@shared/components/Select/Select';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { components } from 'react-select';

import useAuth from 'src/hooks/useAuth';
import { files_url } from 'src/settings/base-url';
import { StyledMockAvatar } from '@shared/components/Avatar/styles';
import { Avatar } from '@shared/components/Avatar';
import { defReactSelectValue } from '@components/nav/SearchProjectsNav';

import { Wrapper } from './styles';

export const getOptions = (users, token) => {
  if (!users) return [];
  const options = users.map((user) => ({
    label: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    value: {
      id: user.id,
      name: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
      pic: !!user.avatar
        ? `${files_url}/employees/avatar/${user.id}/small_${user.avatar}?token=${token}`
        : StyledMockAvatar,
    },
  }));

  return [defReactSelectValue, ...options];
};

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Wrapper align={'center'}>
        {props.data?.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.Option>
  );
};

const CustomValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <Wrapper align={'center'}>
        {props.data.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.SingleValue>
  );
};

const SelectController = ({ control, name }) => {
  const auth = useAuth();
  const users = useSelector((state) => state.users.employees);

  const options = useMemo(() => getOptions(users, auth.token), [users]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <SingleSelect
            width={'100%'}
            options={options}
            value={field.value}
            onChange={(e) => field.onChange(e)}
            components={{ Option: CustomOption, SingleValue: CustomValue }}
          />
        );
      }}
    />
  );
};

export default SelectController;
