import styled, {css} from "styled-components";

export const StyledRequestPanel = styled.section`
  grid-column: 1/-1;
  border-top: 1px solid ${({theme}) => theme.borderSecondary};
  display: grid;
  align-items: center;
  gap: 12px;
  position: relative;

  ${props => props.$hide && css`
    display: none;
  `}
  
  * {
    z-index: 1;
  }
  
  ${(props) =>  props.$overdue && css`
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -16px;
      right: -16px;
      bottom: -16px;
      background-color: ${({theme}) => theme.errorLight};
      z-index: 0;
    }
  `}
`

export const StyledRequestPanelInfo = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding-top: 12px;
`

export const StyledPanelButton = styled.button`
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: auto;
  background-color: transparent;
  border: none;
  transition: all 0.3s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid ${({theme}) => theme.dark};
    transform: translate(-50%, -70%) rotate(45deg);
    display: block;
    border-left: none;
    border-top: none;
    transition: all 0.3s ease-in-out;
  }

  &:hover::before {
    border-color: ${({theme}) => theme.main};
  }

  ${props => props.$active && css`    
    &::before {
    border-color: ${({theme}) => theme.main};
      transform: translate(-50%, -30%) rotate(-135deg);
    }
  `
  }
`

export const StyledRequestPanelExpand = styled.section`
  grid-column: 1/-1;
  grid-row-start: 2;
`


export const StyledRequestPanelRow = styled.section`
  display: grid;
  gap: 12px;
  
  > *,
  > .css-b62m3t-container {
    width: 100%;
  }
  
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;

    > * {
      width: auto;
    }
    
    > .css-b62m3t-container {
      width: auto;
      min-width: 250px;
    }
  }
`

export const StyledDeadline = styled.section`
  font-size: 12px;
  line-height: 20px;
  position: relative;
  padding-right: 12px;
  width: fit-content;

  &::after {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    width: 6px;
    height: 6px;
    border: 1px solid ${({theme}) => theme.dark};
    transform: rotate(45deg);
    display: block;
    border-left: none;
    border-top: none;
  }
  
  ${props => props.$hide && css`
    display: none;
  `}
`

export const StyledWarning = styled.section `
  grid-column: 1/-1;
  hyphens: none;
  color: #262626;
  padding: 12px 16px 12px 56px;
  border-radius: 4px;
  z-index: 103;
  background-color: ${({theme}) => theme.warningLight};
  border: none;
  border-bottom: 2px solid ${({theme}) => theme.warningSecondary};
  min-width: 300px;
  width: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 12px;

  &::before {
    background-image: url(${(props) => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 20px;
    left: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
  }
`