import styled from 'styled-components';
import { Text } from '@shared/components';

export const StyledStorageHeader = styled.section`
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;

  @media (min-width: 992px) {
    grid-template-columns: 0.8fr 1.2fr 1fr;
  }

  @media (min-width: 1300px) {
    grid-template-columns: 333px 1fr 1fr;
  }
`;

export const StyledStorageTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledTextReference = styled(Text)`
  hyphens: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
