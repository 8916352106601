import { rtkQueryApi } from './rtkQueryApi';

export const additionalInfoApi = rtkQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdditionalInfoList: builder.query({
      query: () => '/api/additional_contact_infos_list',
      providesTags: ['AdditionalFieldsList'],
    }),
    createAdditionalField: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/additional_contact_infos/add',
        method: 'POST',
      }),
      invalidatesTags: ['AdditionalFieldsList', 'ContactCreateData', 'Contacts'],
    }),
    editAdditionalField: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/additional_contact_infos/edit',
        method: 'PUT',
      }),
      invalidatesTags: ['AdditionalFieldsList', 'ContactCreateData', 'SingleContact', 'Contacts'],
    }),
    editAdditionalFields: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/additional_contact_infos/edit',
        method: 'PUT',
      }),
      invalidatesTags: ['ContactCreateData', 'SingleContact', 'Contacts'],
    }),
    deleteAdditionalField: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/additional_contact_infos/delete',
        method: 'DELETE',
      }),
      invalidatesTags: ['AdditionalFieldsList', 'ContactCreateData', 'Contacts'],
    }),
  }),
});

export const {
  useGetAdditionalInfoListQuery,
  useCreateAdditionalFieldMutation,
  useEditAdditionalFieldMutation,
  useEditAdditionalFieldsMutation,
  useDeleteAdditionalFieldMutation,
} = additionalInfoApi;
