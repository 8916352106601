import SelectMulty from '@components/form/select/SelectMulty';
import { Subtitle } from '@shared/components';
import { useMemo } from 'react';

const selectData = {
  name: 'groups',
};

const GroupsMultySelect = ({ control, groups }) => {
  const options = useMemo(() => {
    return groups?.map((g) => ({ label: g.title, value: g.id }));
  }, [groups]);

  return (
    <div>
      <Subtitle>Группы</Subtitle>
      <SelectMulty item={selectData} control={control} options={options} />
    </div>
  );
};

export default GroupsMultySelect;
