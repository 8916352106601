import { colors } from 'src/variables';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledProfilePic = styled.div`
  height: 212px;
  width: 212px;
  position: relative;
  background-color: #dbdada;

  & img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  @media (max-width: 578px) {
    margin: 0 auto;
  }
`;

export const StyledTitle = styled.p`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
`;

export const FieldLabel = styled.div`
  font-size: ${({ $fontSize }) => $fontSize};
  color: ${colors.dark};
`;

export const FieldInfo = styled.div`
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: 500;
`;

export const FieldGrid = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: 0.5rem 1rem;
  @media (max-width: 578px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledLink = styled(Link)`
  transition: all 0.3s;
  &:hover {
    color: #1890ff;
  }
`;
