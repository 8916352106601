import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';
import * as yup from 'yup';

export const reminderTypes = {
  project: 'project',
  task: 'task',
  message: 'message',
  free: 'free',
};

export const options = [
  {
    label: 'В свободной форме',
    value: reminderTypes.free,
  },
  {
    label: 'О проекте',
    value: reminderTypes.project,
  },
  {
    label: 'О задаче',
    value: reminderTypes.task,
  },
  {
    label: 'О сообщении',
    value: reminderTypes.message,
  },
];

export const schema = yup.object({
  type: yup.object().required(),
  project: yup.object().when('type', {
    is: (type) => type?.value === reminderTypes.project,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  task: yup.object().when('type', {
    is: (type) => type?.value === reminderTypes.task,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  text: yup.object().required() || yup.string().required(),
  date: yup.date().required(),
});

export const transformFormData = ({ id, data }) => {
  return {
    row: {
      type: data.type.value,
      data_id: data.task?.value || data.project?.value || id,
      description: stateToHTML(data.text.getCurrentContent()),
      date: moment.utc(data.date).format('YYYY[/]MM[/]D') + ' ' + moment.utc(data.date).format('LT'),
    },
  };
};
