import styled from 'styled-components';

export const StyledSurveysList = styled.section`
  border-top: 1px solid ${({ theme }) => theme.borderSecondary};
  margin-top: 24px;
`;

export const StyledPost = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
  padding: 16px 0 8px;
`;

export const StyledPostDate = styled.p`
  color: ${({ theme }) => theme.dark};
  font-size: 12px;
  line-height: 20px;
`;

export const StyledPostTitle = styled.h3`
  font-weight: 600;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;
