import React, { useState } from 'react';

import { IconButton } from '@shared/components';
import iconDelete from '@assets/images/icons/delete.svg';
import { useSocketContext } from 'src/contexts/SocketContext';
import { removeFavoriteFromState } from 'src/redux/features/favoritesSlice';

import { useDispatch } from 'react-redux';

import { StyledIconButtonWrapper } from '../../styles';
import { RemoveModal } from '../RemoveModal';

export const RemoveButton = ({ id, removeData, isProject }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { removeFavorite } = useSocketContext();
  const dispatch = useDispatch();

  const removeFavoriteCallback = (socketResponse, favoriteId) => {
    if (socketResponse?.status === 'ok') {
      dispatch(removeFavoriteFromState(favoriteId));
    }
  };

  return (
    <StyledIconButtonWrapper>
      <IconButton size={18} icon={iconDelete} onClick={() => setModalVisible(true)} />

      {modalVisible && (
        <RemoveModal
          onClose={() => setModalVisible(false)}
          onSave={() => {
            removeFavorite(removeData, (socketResponse) => removeFavoriteCallback(socketResponse, id));
          }}
          isProject={isProject}
        />
      )}
    </StyledIconButtonWrapper>
  );
};
