import {useMemo, useState, useContext} from 'react';
import ConfirmAction from '@components/warnings/ConfirmAction';
import {useCancelEmployeeDismissalMutation} from 'src/redux/features/api/employees';
import SnackbarContext from 'src/contexts/SnackbarContext';
import moment from 'moment';
import {Button, Container, StyledModalLabelInfo, Text} from "@shared/components";

import {EmployeeButtonBox, EmployeeStatus, EmployeeStatusInfo} from "./style";

const EmploymentStatus = ({employeeData, getUserData, setDismissalModalType}) => {
  const lastDismissal = useMemo(() => {
    if (!employeeData?.dismissal?.length) return null;
    const lastDismissal = employeeData.dismissal.findLast((dismissal) => dismissal.canceled === 0);
    if (!lastDismissal) return null;
    return {
      ...lastDismissal,
      pastTerminationDate: new Date().getTime() > new Date(lastDismissal.date_dismissed).getTime(),
    };
  }, [employeeData]);

  const [confirmDismissalCancel, setConfirmDismissalCancel] = useState();

  const [trigger, {isLoading}] = useCancelEmployeeDismissalMutation();

  const {showSnackbar} = useContext(SnackbarContext);

  if (!employeeData) return null;
  return (
    <>
      {lastDismissal?.canceled === 0 && lastDismissal.pastTerminationDate && (
        <EmployeeStatus status="fired">
          <div>
            <Text size={2} tag="b">{`Уволен с ${moment(lastDismissal.date_dismissed).format('L')}`}</Text>
          </div>
          <EmployeeButtonBox>
            <Button
              onClick={() => setConfirmDismissalCancel(true)}
              color="secondary"
              size="sm">
              Отменить
            </Button>
          </EmployeeButtonBox>
        </EmployeeStatus>
      )}

      {lastDismissal?.canceled === 0 && !lastDismissal.pastTerminationDate && (
        <EmployeeStatus status="leaving">
          <div>
            <Text size={2} tag="b">{`Увольняется с ${moment(lastDismissal.date_dismissed).format('L')}`}</Text>
          </div>
          <EmployeeButtonBox>
            <Button
              color="secondary"
              size="sm">
              Изменить
            </Button>
            <Button
              color="secondary"
              size="sm">
              Отменить
            </Button>
            <Button
              color="secondary"
              size="sm">
              &#43;
            </Button>
          </EmployeeButtonBox>
        </EmployeeStatus>
      )}

      {/*<EmployeeStatus status="on-vacation">*/}
      {/*    <div>*/}
      {/*      <Text size={2} tag="b">В отпуске 12.12.2021 - 12.01.2022</Text>*/}
      {/*    </div>*/}
      {/*    <EmployeeButtonBox>*/}
      {/*      <Button*/}
      {/*        onClick={() => setDismissalModalType('edit')}*/}
      {/*        color="secondary"*/}
      {/*        size="sm">*/}
      {/*        Изменить*/}
      {/*      </Button>*/}
      {/*      <Button*/}
      {/*        onClick={() => setConfirmDismissalCancel(true)}*/}
      {/*        color="secondary"*/}
      {/*        size="sm">*/}
      {/*        Отменить*/}
      {/*      </Button>*/}
      {/*    </EmployeeButtonBox>*/}
      {/*</EmployeeStatus>*/}

      {/*<EmployeeStatus status="info">*/}
      {/*  <EmployeeStatusInfo>*/}
      {/*    <div>*/}
      {/*      <StyledModalLabelInfo>Причина увольнения</StyledModalLabelInfo>*/}
      {/*      <Text size={2} tag="b">По собственному желанию</Text>*/}
      {/*    </div>*/}
      {/*    <div>*/}
      {/*      <StyledModalLabelInfo>Комментарий</StyledModalLabelInfo>*/}
      {/*      <Text size={2} tag="b">Lorem ipsum</Text>*/}
      {/*    </div>*/}
      {/*  </EmployeeStatusInfo>*/}
      {/*</EmployeeStatus>*/}

      {/*<EmployeeStatus status="go-vacation">*/}
      {/*  <Container direction="column">*/}
      {/*    <Text size={2} tag="b">Собирается в отпуск</Text>*/}
      {/*    <Text size={2} tag="b">12.12.2021 - 12.01.2022</Text>*/}
      {/*  </Container>*/}
      {/*  <EmployeeButtonBox>*/}
      {/*    <Button*/}
      {/*      color="secondary"*/}
      {/*      size="sm">*/}
      {/*      Подтвердить*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      color="secondary"*/}
      {/*      size="sm">*/}
      {/*      Отменить*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      color="secondary"*/}
      {/*      size="sm">*/}
      {/*      &#43;*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      color="secondary"*/}
      {/*      size="sm">*/}
      {/*      Отправить сообщение*/}
      {/*    </Button>*/}
      {/*  </EmployeeButtonBox>*/}
      {/*</EmployeeStatus>*/}

      {confirmDismissalCancel && (
        <ConfirmAction
          actionText="Вы уверены что хотите отменить увольнение данного сотрудника?"
          isSubmitting={isLoading}
          confirm={async () => {
            const res = await trigger({
              employee_dismissal_id: lastDismissal.id,
            });
            if ('error' in res) showSnackbar('Возникла ошибка при отмены увольнения');
            else getUserData();
          }}
          cancel={() => setConfirmDismissalCancel(false)}
          confirmButtonText="Да"
          cancelButtonText="Нет"
        />
      )}
    </>
  );
};

export default EmploymentStatus;
