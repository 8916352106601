import { object, string } from 'yup';

export const fieldTypes = {
  string: 'string',
  text: 'text',
  list: 'list',
  link: 'link',
};

export const formSchema = object({
  title: string().required(),
  type: object().required(),
  textarea: string().when('type', {
    is: (type) => type?.value === fieldTypes.list,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  visible: string(),
});

export const selectOptions = [
  {
    label: 'Строка',
    value: fieldTypes.string,
  },
  {
    label: 'Текст',
    value: fieldTypes.text,
  },
  {
    label: 'Список',
    value: fieldTypes.list,
  },
  {
    label: 'Ссылка',
    value: fieldTypes.link,
  },
];
