import React from 'react';

import { useTranslation } from 'react-i18next';

import { useMemo } from 'react';

import { Modal } from '@shared/components/Modal/Modal';

import Preloader from '@components/preloaders/Preloader';

import useAuth from '../../../hooks/useAuth';

import { useEventTriggerOnEscPress } from '../../../utilize/helper-functions';

import { StyledModalGrid, StyledModalGridCell, StyledModalGridHeader } from '../../departments/department-list/styles';

import PositionItem from './PositionItem';

const PositionsList = (props) => {
  const { t } = useTranslation();
  const { list, onDelete, onEdit, onClose, onAdd } = props;

  const { isUserRightful, rightTypes } = useAuth();

  const hasRightToEdit = useMemo(() => isUserRightful(rightTypes.positions_edit), [isUserRightful, rightTypes]);

  useEventTriggerOnEscPress(onClose);

  const headerButtons = [{ name: 'Добавить', action: onAdd }];

  return (
    <Modal
      modalSize="768px"
      title={t('Positions.positions')}
      onClose={onClose}
      headerButtons={hasRightToEdit && headerButtons}
      withoutButtons
    >
      {!list && <Preloader />}

      <StyledModalGrid grid="2fr 1fr 100px">
        <StyledModalGridHeader>
          <StyledModalGridCell>Должность</StyledModalGridCell>
          <StyledModalGridCell>Сотрудники</StyledModalGridCell>
          <StyledModalGridCell align="right">Действия</StyledModalGridCell>
        </StyledModalGridHeader>
        {list?.map((item, i) => (
          <PositionItem key={i} item={item} onDelete={onDelete} onEdit={onEdit} hasRightToEdit={hasRightToEdit} />
        ))}
      </StyledModalGrid>
    </Modal>
  );
};

export default PositionsList;
