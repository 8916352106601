import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {Modal} from "@shared/components";

import ConfirmAction from '../warnings/ConfirmAction';

import TagsListModal from './TagsListModal';
import AddTagModal from './AddTagModal';

const TagsModal = ({ onClose }) => {
  const [addTagModal, toggleAddTagModal] = useState(false);
  const { t } = useTranslation();
  const isChanged = useRef(false);
  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const headerButtons = [
    {name: t('FormButtons.add'), action: () => toggleAddTagModal(true)}
  ]

  return (
    <>
      <Modal
        onClose={onClose}
        onSave={() => toggleAddTagModal(true)}
        title={t(addTagModal ? 'Tags.create_tag' : 'common.tags')}
        headerButtons={headerButtons}
        withoutButtons
      >
        {addTagModal ? (
          <AddTagModal
            onCancel={() => toggleAddTagModal(false)}
            close={onClose}
            isChanged={isChanged}
            setModalClosePrompt={setModalClosePrompt}
          />
        ) : (
          <TagsListModal close={onClose} />
        )}
      </Modal>

      {modalClosePrompt && (
        <ConfirmAction
          actionText={t('common.confirm_modal_close')}
          cancel={() => setModalClosePrompt(false)}
          confirm={() => {
            toggleAddTagModal(false);
            setModalClosePrompt(false);
          }}
        />
      )}
    </>
  );
};

export default TagsModal;
