import { useState, useContext, useCallback, useEffect, useRef, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { object, string, date, array, number, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@shared/components';
import { bool, func, number as numberProp } from 'prop-types';
import ConfirmAction from '@components/warnings/ConfirmAction';
import axios from 'axios';
import Preloader from '@components/preloaders/Preloader';
import { getOptions } from 'src/components/form/select/userSelectComponents';
import useAuth from 'src/hooks/useAuth';
import { files_url } from 'src/settings/base-url';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import SnackbarContext from '../../contexts/SnackbarContext';

import { CreatePollModalBody } from './CreatePollModalBody';
import ConfirmNonMembersModal from './ConfirmNonMembersModal/ConfirmNonMembersModal';

const formSchema = object({
  title: string().required(),
  survey_text: object().nullable(), // для опросов (не для голосований в чате)
  anonymous: number().required(),
  multianswer: object().required(),
  deadline_type: object().required(),
  date_finish: date().when('deadline_type', {
    is: (deadline_type) => deadline_type?.value === 'limited',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  poll_answers: array()
    .of(
      object({
        value: string().required().min(1, 'Обязательное поле'),
        answer_id: number().nullable(),
        file: mixed().nullable(),
      }),
    )
    .required()
    .min(1, 'Нужен хотя бы один вариант'),
  poll_members: array().required().min(1, 'Нужен хотя бы участник'),
  delete_poll_answers: array().of(number()),
});

CreatePollModal.propTypes = {
  close: func,
  submitRequest: func,
  chatId: numberProp,
  pollId: numberProp,
  isMemberOnly: bool,
  isSurvey: bool,
};

// модальное окно используется для создания/редактирования голосований в чате и
// для создания опросов с отдельным чатом
function CreatePollModal({ close, chatId, pollId, submitRequest, getPollCreateDataRequest, isMemberOnly, isSurvey }) {
  const [modalClosePrompt, setModalClosePrompt] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pollData, setPollData] = useState(null);
  const chatMembersSetRef = useRef(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    getValues,
    setValue,
    reset,
    // watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      anonymous: 1,
      multianswer: { label: 'Один', value: 0 },
      deadline_type: { label: 'Не ограничено', value: 'unlimited' },
    },
  });

  const handleCloseBtnClick = useCallback(() => {
    if (modalClosePrompt) return;
    if (isDirty) return setModalClosePrompt(true);
    close();
  }, [modalClosePrompt, isDirty]);

  useEventTriggerOnEscPress(handleCloseBtnClick);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const [confirmNonMembers, setConfirmNonMembers] = useState(null);

  const checkExtraMembers = useCallback((selectedEmployeesArray) => {
    const nonMembers = selectedEmployeesArray.filter(
      (selectedEmployee) => !chatMembersSetRef.current.has(selectedEmployee.value.id),
    );

    if (nonMembers.length) {
      setConfirmNonMembers(nonMembers);
      return true;
    }
  }, []);

  const handleRequestSubmit = useCallback(
    async (values) => {
      setIsSubmitting(true);
      try {
        await submitRequest(values);
        close();
      } catch (e) {
        if (typeof e?.message === 'string') {
          showSnackbar(e.message);
          setIsSubmitting(false);
        }
      }
    },
    [submitRequest, showSnackbar],
  );

  const submitData = useCallback(
    async (values) => {
      if (isMemberOnly && chatMembersSetRef.current instanceof Set) {
        const hasOutsideMembers = checkExtraMembers(values.poll_members);
        if (hasOutsideMembers) return;
      }

      await handleRequestSubmit(values);
    },
    [isMemberOnly, handleRequestSubmit, chatId, checkExtraMembers],
  );

  const auth = useAuth();

  // построение данных для редактирования созданного голосования
  useEffect(() => {
    if (!pollId || !auth?.token) return;
    setIsLoading(true);

    axios
      .get(`/api/poll_edit/${pollId}`)
      .then((response) => {
        setPollData(response.data);

        reset({
          title: response.data.poll.title,
          anonymous: response.data.poll.anonymous,
          multianswer: response.data.poll.multianswer ? { label: 'Несколько', value: 1 } : { label: 'Один', value: 0 },
          deadline_type: response.data.poll.date_finish
            ? { label: 'Ограничено', value: 'limited' }
            : { label: 'Не ограничено', value: 'unlimited' },
          date_finish: response.data.poll.date_finish ? new Date(response.data.poll.date_finish) : null,
          poll_answers: response.data.poll_answers
            ? response.data.poll_answers.map((answer) => {
                const answerField = { value: answer.answer, answer_id: answer.id };
                if (answer.image) {
                  answerField.file = {
                    src: `${files_url}/chat_messages/polls/files/${answer.id}/${answer.image}?token=${auth.token}`,
                  };
                }
                return answerField;
              })
            : [],
          poll_members: getOptions({ users: response.data.poll_members, token: auth.token }),
          delete_poll_answers: [],
          delete_poll_answer_files: [],
        });

        if (isMemberOnly && Array.isArray(response.data.chat_members)) {
          chatMembersSetRef.current = new Set(response.data.chat_members.map((member) => member.id));
        }
      })
      .catch(() => showSnackbar('Не удалось получить данные для редактирования опроса'))
      .finally(() => setIsLoading(false));
  }, [pollId, showSnackbar, auth?.token]);

  const handleGetPollCreateData = useCallback(async () => {
    if (!getPollCreateDataRequest) return;

    setIsLoading(true);
    try {
      const response = await getPollCreateDataRequest();
      if (response?.data) {
        setPollData(response.data);
      }
    } catch (e) {
      if (typeof e?.message === 'string') {
        showSnackbar(e.message);
      } else {
        showSnackbar('Не удалось получить данные для создания голосования');
      }
    }
    setIsLoading(false);
  }, [getPollCreateDataRequest, showSnackbar]);

  // получение данных для создания сообщения с голосованием
  useEffect(() => {
    handleGetPollCreateData();
  }, [handleGetPollCreateData]);

  const title = useMemo(() => {
    if (isSurvey) {
      return 'Создать опрос';
    }
    return pollId ? 'Редактировать голосование' : 'Добавить голосование в сообщение';
  }, [pollId, isSurvey]);

  return (
    <>
      <Modal
        title={title}
        onClose={handleCloseBtnClick}
        confirmButtonText={`${pollId ? 'Сохранить' : 'Добавить'}`}
        disabledSaveButton={!isDirty || !isValid || isSubmitting}
        onSave={handleSubmit(submitData)}
      >
        {isLoading ? (
          <Preloader />
        ) : (
          <CreatePollModalBody
            control={control}
            register={register}
            close={close}
            chatId={chatId}
            getValues={getValues}
            setValue={setValue}
            pollData={pollData}
            isSurvey={isSurvey}
          />
        )}
      </Modal>

      {modalClosePrompt && (
        <ConfirmAction
          confirm={close}
          cancel={() => setModalClosePrompt(false)}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}

      {Boolean(Array.isArray(confirmNonMembers) && confirmNonMembers.length) && (
        <ConfirmNonMembersModal
          confirm={() => {
            handleRequestSubmit(getValues());
            setConfirmNonMembers(null);
          }}
          cancel={() => setConfirmNonMembers(null)}
          selectedEmployees={confirmNonMembers}
        />
      )}
    </>
  );
}

export default CreatePollModal;
