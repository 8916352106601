import styled, { css } from 'styled-components';

export const StyledCalendar = styled.div`
  position: relative;

  & .react-datepicker-wrapper {
    width: 100%;
  }

  & input {
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 2px;
    color: ${({ theme }) => theme.darkest};
    outline: none;
    padding: 8px 34px 8px 12px;
    position: relative;
    width: 100%;
    font: inherit;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    &:focus {
      border-color: ${({ theme }) => theme.main};
      box-shadow: ${({ theme }) => theme.shadowInput};
    }

    ${({ $invalid, theme }) =>
      $invalid &&
      css`
        border: 1px solid ${theme.error};
        box-shadow: ${theme.shadowInputError};
      `}
  }
`;

export const StyledCalendarIcon = styled.div`
  background-image: url(${(props) => props.icon});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 14px;
  height: 14px;
  pointer-events: none;
  position: absolute;
  right: 14px;
  width: 14px;
`;

export const StyledCalendarError = styled.div`
  color: ${({ theme }) => theme.error};
  font-size: 12px;
  line-height: 22px;
`;
