import {useRef, useState, useContext, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Container, Input, Modal} from "@shared/components";
import {
  StyledErrorText,
  StyledTag,
  StyledTagInput,
  StyledTagSelect,
  StyledTagSelectItem,
  TagDropdown
} from "@components/tags/style";

import {addTag, clearErrors} from '../../redux/features/tagsSlice';
import SnackbarContext from '../../contexts/SnackbarContext';

import useAuth from '../../hooks/useAuth';
import {useEventTriggerOnEscPress} from '../../utilize/helper-functions';

const AddTagModal = ({onCancel, isChanged, setModalClosePrompt}) => {
  const auth = useAuth();

  const {showSnackbar} = useContext(SnackbarContext);

  const inputRef = useRef();
  const dispatch = useDispatch();
  const {error, isSending} = useSelector((state) => state.tags);
  const {t} = useTranslation();

  const [tagName, setTagName] = useState('');
  const [color, setColor] = useState('green');

  const [dropdown, toggleDropdown] = useState(false);

  const selectColor = (e) => setColor(e.currentTarget.dataset.option);

  const clearOnSuccess = () => {
    showSnackbar('Новый тэг добавлен', 'success');
    setTagName('');
    inputRef.current.focus();
  };

  const onCreate = async () => {
    if (auth?.user && tagName && color) {
      dispatch(
        addTag({
          data: {
            name: tagName,
            color: color,
          },
          clear: clearOnSuccess,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (tagName) {
      isChanged.current = true;
    }
  }, [tagName, isChanged]);

  const handleClose = () => {
    if (tagName) {
      setModalClosePrompt(true);
    } else onCancel();
  };

  useEventTriggerOnEscPress(handleClose);

  return (
    <Modal
      title="Создать тэг"
      onSave={onCreate}
      onClose={handleClose}
      cancelButtonText={t('FormButtons.cancel')}
      confirmButtonText={t('FormButtons.create')}
      disabledSaveButton={!tagName || isSending}
    >

      <div>
        <Container gap="24px" align="flex-start">
          <TagDropdown
            width="70px"
            $show={dropdown}
            onClick={() => toggleDropdown(!dropdown)}
          >
            <StyledTagInput>
              <StyledTag color={color} />
            </StyledTagInput>
            <StyledTagSelect>
              <StyledTagSelectItem data-option="red" onClick={selectColor}>
                <StyledTag color="red" />
              </StyledTagSelectItem>
              <StyledTagSelectItem data-option="green" onClick={selectColor}>
                <StyledTag color="green" />
              </StyledTagSelectItem>
              <StyledTagSelectItem data-option="yellow" onClick={selectColor}>
                <StyledTag color="yellow" />
              </StyledTagSelectItem>
              <StyledTagSelectItem data-option="grey" onClick={selectColor}>
                <StyledTag color="grey" />
              </StyledTagSelectItem>
            </StyledTagSelect>
          </TagDropdown>
          <Input
            ref={inputRef}
            id="name"
            placeholder={t('Tags.new_tag_name')}
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
            autoComplete="off"
          />
        </Container>

        {error && <StyledErrorText>{error}</StyledErrorText>}
      </div>
    </Modal>
  );
};

export default AddTagModal;
