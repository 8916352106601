import styled from 'styled-components';

export const StyledIconButton = styled.button`
  width: ${(props) => props.size || 16}px;
  height: ${(props) => props.size || 16}px;
  flex: 0 0 ${(props) => props.size || 16}px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.icon});
  background-color: ${(props) => props.background || 'transparent'};
  border: none;
  border-radius: 2px;
  padding: 2px;
  cursor: pointer;

  & svg {
    fill: grey;
  }

  ${(props) =>
    props.$active &&
    (props.background
      ? `filter: brightness(95%);`
      : `filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);`)}

  &:hover {
    ${(props) =>
      props.background
        ? `filter: brightness(95%);`
        : `filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);`}
  }

  &:disabled {
    cursor: not-allowed;

    filter: invert(100%) sepia(0%) saturate(0) hue-rotate(0) brightness(110%) contrast(100%);
  }
`;
