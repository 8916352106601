import { memo, useEffect } from 'react';
import { object, string } from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Input,
  Label,
  Subtitle,
  Textarea,
  StyledModalFlex,
  StyledModalRowEnd,
  Container,
  Button,
  PhoneInput,
} from '@shared/components';

const formSchema = object({
  contact_name: string().required(),
  phone: string(),
  email: string().email(),
  others: string(),
});
const defaultValues = { contact_name: '', phone: '', email: '', others: '' };

const AddContactForm = ({ control: parentControl, append, update, setValue }) => {
  const contactToUpdate = useWatch({ control: parentControl, name: 'contactToUpdate' });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (contactToUpdate) reset(contactToUpdate.contact);
  }, [contactToUpdate]);

  const addContactHandler = (data) => {
    if (contactToUpdate) {
      // если есть id присвоенный сервером, то добавить флаг toEdit, чтобы, при отправке, добавить контакт в соответствующий массив
      update(contactToUpdate.index, { ...data, toEdit: !!contactToUpdate.contact.partner_contacts_id });
      setValue('contactToUpdate', null);
    } else {
      append(data);
    }
    reset(defaultValues);
  };

  return (
    <>
      <div id="contactAddNameInput">
        <Label htmlFor="name">Имя контактного лица</Label>
        <Input id="name" error={!!errors['name']} register={register('contact_name')} />
      </div>

      <StyledModalRowEnd>
        <div>
          <Label htmlFor="phone">Телефон</Label>
          <Controller
            control={control}
            name="phone"
            render={({ field }) => {
              return <PhoneInput value={field.value} onChange={(v) => field.onChange(v)} />;
            }}
          />
        </div>
        <div>
          <Label>Электронная почта</Label>
          <Input id="email" error={!!errors['email']} register={register('email')} />
        </div>
      </StyledModalRowEnd>

      <div>
        <StyledModalFlex>
          <Subtitle>Другие способы связи</Subtitle>
        </StyledModalFlex>

        <Textarea register={register('others')} />
        <Container justify="flex-end" gap="2rem">
          <Button disabled={!isValid} onClick={handleSubmit(addContactHandler)}>
            {contactToUpdate ? 'Изменить' : 'Добавить'}
          </Button>
          {contactToUpdate && (
            <Button disabled={!isValid} onClick={handleSubmit(addContactHandler)} color="outlined">
              Отмена
            </Button>
          )}
        </Container>
      </div>
    </>
  );
};

export default memo(AddContactForm);
