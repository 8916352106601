import { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { func, object as objectType } from 'prop-types';
import { Container, Input, Label, Modal, Text } from '@shared/components';
import { object, string, array, boolean, mixed } from 'yup';
import linkifyit from 'linkify-it';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCreateCompanyContactMutation,
  useEditCompanyContactMutation,
  useGetContactCreateDataQuery,
} from 'src/redux/features/api/contacts';
import { stateToHTML } from 'draft-js-export-html';

import useAuth from 'src/hooks/useAuth';
import { files_url } from 'src/settings/base-url';
import ConfirmAction from '@components/warnings/ConfirmAction';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import { SingleSelect } from '@shared/components/Select/Select';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { randomSequence, useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import { colors } from 'src/variables';
import Preloader from '@components/preloaders/Preloader';
import FormTab from '@shared/components/FormTab';

import GroupsMultySelect from './GroupsMultySelect';
import DepartmentsMultySelect from './DepartmentsMultySelect';
import EmployeesMultySelect from './EmployeesMultySelect';
import TagsMultySelect from './TagsMultySelect';
import ProjectsMultySelect from './ProjectsMultySelect';
import AddEditContactExtraInfo from './extra-fields/AddEditContactExtraInfo';
import ImageUploader from './ImageUploader';
import useContactCreateEditDefaultValues from './useContactCreateEditDefaultValues';
import CreateEditContactsList from './CreateEditContactsList';

const linkify = linkifyit();

const isLink = (stringValue) => {
  const testString = stringValue?.trim();
  if (!testString) return true;
  const matchResult = linkify.match(testString);
  return matchResult && testString.length === matchResult[0].lastIndex;
};

const additionalInfoSchema = {
  type: string(),
  value: mixed()
    .nullable()
    .when('type', {
      is: 'link',
      then: () =>
        string().nullable().test('isUrl', 'Введена некорректная ссылка', isLink, { excludeEmptyStrings: true }),
    }),
};

const formSchema = object({
  title: string().required(),
  type: object().required(),
  link: string().nullable().test('isUrl', 'Введена некорректная ссылка', isLink, { excludeEmptyStrings: true }),
  groups: array().of(object()).nullable(),
  contacts: array()
    .of(
      object({
        contact_name: string().required(),
        phone: string().nullable(),
        email: string().email().nullable(),
        others: string().nullable(),
        toEdit: boolean().nullable(),
        toDelete: boolean().nullable(),
      }),
    )
    .required()
    .test((values) => Array.isArray(values) && values.some((v) => !v.toDelete)),
  contactToUpdate: object().nullable(),
  partner_company_infos: array().of(object().shape(additionalInfoSchema)),
});

export const contactsTypeOptions = [
  { label: 'Клиент', value: 'client' },
  { label: 'Партнер', value: 'partner' },
  { label: 'Клиент и партнер', value: 'client_partner' },
];

CreateEditContacts.propTypes = {
  close: func,
  companyToEdit: objectType,
};

function CreateEditContacts({ close, companyToEdit }) {
  const [modalClosePrompt, setModalClosePrompt] = useState();
  const { data: contactCreateData, isFetching } = useGetContactCreateDataQuery();

  const defaultValues = useContactCreateEditDefaultValues({ contactCreateData, companyToEdit });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty },
    setValue,
  } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

  useEffect(() => {
    if (defaultValues) reset(defaultValues);
  }, [defaultValues, reset]);

  const [extraInfoSection, setExtraInfoSection] = useState(false);

  // если одно из полей дополнительных данных имеет значение, то открыть вкладку "Дополнительная информация"
  useEffect(() => {
    if (
      companyToEdit?.partner_additional_information?.some(
        (infoField) =>
          (infoField.value && !Array.isArray(infoField.value)) ||
          (Array.isArray(infoField.value) && infoField.value.length),
      )
    ) {
      setExtraInfoSection(true);
    }
  }, [companyToEdit]);

  const removeFilesOnClose = useRef();

  const [createContact, { isLoading: isCreating }] = useCreateCompanyContactMutation();
  const [editContact, { isLoading: isEditing }] = useEditCompanyContactMutation();

  const { showSnackbar } = useContext(SnackbarContext);

  const formId = useRef(randomSequence());

  const submitContactHandler = async (data) => {
    const body = {
      row: {
        title: data.title,
        type: data.type.value,
        link: data.link,
      },
      form_id: formId.current,
      departments: data.departments?.map((d) => d.value),
      employees: data.employees?.map((e) => e.value.id),
      projects: data.projects?.map((p) => p.value),
      groups: data.groups?.map((g) => g.value),
      tags: data.tags?.map((t) => t.value),
      partner_company_infos: data.partner_company_infos.reduce((arr, infoField) => {
        const { contact_info_id, value } = infoField;
        switch (infoField.type) {
          case 'string':
          case 'link':
            if (!value) return arr;
            arr.push({ contact_info_id, value });
            return arr;

          case 'text': {
            if (!value || !('getCurrentContent' in value)) return arr;
            const currentContent = value.getCurrentContent();
            if (!currentContent.getPlainText('\u0001')) return arr;
            arr.push({ contact_info_id, value: stateToHTML(currentContent) });
            return arr;
          }

          case 'list':
            if (!Array.isArray(value)) return arr;
            for (const selectedItem of value) {
              arr.push({ contact_info_id, info_value_id: selectedItem.value });
            }
            return arr;

          default:
            return arr;
        }
      }, []),
      contacts_add: data.contacts
        .filter((c) => c.partner_contacts_id === undefined)
        .map((contact) => {
          delete contact.toEdit;
          return contact;
        }),
    };
    if (companyToEdit) {
      body.partner_id = companyToEdit.id;
      body.contacts_edit = data.contacts
        .filter((c) => c.toEdit && c.partner_contacts_id && !c.toDelete)
        .map((c) => {
          delete c.toEdit;
          delete c.id;
          return c;
        });
      body.contacts_delete = data.contacts
        .filter((c) => c.toDelete && c.partner_contacts_id !== undefined)
        .map((c) => c.partner_contacts_id);
    }
    const result = companyToEdit ? await editContact(body) : await createContact(body);
    if ('error' in result) {
      showSnackbar(`Возникла ошибка при ${companyToEdit ? 'редактировании' : 'создании'} контакта`);
    } else close();
  };

  const [fileToUpload, setFileToUpload] = useState();

  const closeModal = useCallback(() => {
    if (removeFilesOnClose.current && fileToUpload) removeFilesOnClose.current();
    close();
  }, [fileToUpload]);

  const handleCloseBtnClick = useCallback(() => {
    if (modalClosePrompt) return;
    if (isDirty || fileToUpload) return setModalClosePrompt(true);
    else closeModal();
  }, [modalClosePrompt, closeModal, isDirty, fileToUpload]);

  useEventTriggerOnEscPress(handleCloseBtnClick);

  const isImageUpdated = useMemo(() => companyToEdit && fileToUpload, [fileToUpload]);

  const auth = useAuth();

  const companyToEditImageLink = useMemo(() => {
    if (!companyToEdit?.image || !auth.token) return;
    return `${files_url}/partners/files/${companyToEdit.id}/${companyToEdit.image}?token=${auth.token}`;
  }, [companyToEdit, auth?.token]);

  return (
    <Modal
      modalSize="880px"
      onSave={handleSubmit(submitContactHandler)}
      onClose={handleCloseBtnClick}
      title={`${companyToEdit ? 'Редактировать' : 'Создать'} контакты клиента или партнера`}
      confirmButtonText={companyToEdit ? 'Сохранить' : 'Добавить'}
      disabledSaveButton={!isValid || (!isDirty && !isImageUpdated) || isCreating || isEditing}
    >
      {isFetching || !defaultValues ? (
        <Preloader />
      ) : (
        <>
          <StyledModalRowEnd>
            <div>
              <Label htmlFor="title">
                Название <sup>*</sup>
              </Label>
              <Input id="title" error={!!errors['title']} register={register('title')} autoComplete="off" />
            </div>
            <div>
              <Label>Тип</Label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => <SingleSelect {...field} ref={null} options={contactsTypeOptions} />}
              />
            </div>
          </StyledModalRowEnd>
          <div>
            <Label htmlFor="link">Ссылка</Label>
            <Input id="link" error={Boolean(errors['link'])} autoComplete="off" register={register('link')} />
            <Text size={1} color={colors.error}>
              {errors['link']?.message}
            </Text>
          </div>

          <DepartmentsMultySelect control={control} departments={contactCreateData?.departments} />

          <EmployeesMultySelect control={control} employees={contactCreateData?.employees} />

          <ProjectsMultySelect control={control} projects={contactCreateData?.projects} />

          <GroupsMultySelect control={control} groups={contactCreateData?.groups} />

          <TagsMultySelect control={control} tags={contactCreateData?.tags} />

          <ImageUploader
            formId={formId}
            removeFilesOnClose={removeFilesOnClose}
            fileToUpload={fileToUpload}
            setFileToUpload={setFileToUpload}
            imageLink={companyToEditImageLink}
            filesUploadPath="/api/partner_file/add"
            filesDeletePath="/api/partner_file/remove"
          />
          <Container direction="column" gap="1.5rem">
            <FormTab
              value="Дополнительная информация"
              open={extraInfoSection}
              handler={() => setExtraInfoSection((prev) => !prev)}
            />

            {extraInfoSection && <AddEditContactExtraInfo register={register} control={control} />}
          </Container>

          <CreateEditContactsList control={control} setValue={setValue} reset={reset} register={register} />

          {modalClosePrompt && (
            <ConfirmAction
              confirm={closeModal}
              cancel={() => setModalClosePrompt(false)}
              actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
            />
          )}
        </>
      )}
    </Modal>
  );
}

export default CreateEditContacts;
