import { useMemo } from 'react';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';

import { contactsTypeOptions } from './CreateEditContacts';

const useContactCreateEditDefaultValues = ({ contactCreateData, companyToEdit }) => {
  const defaultValues = useMemo(() => {
    if (!contactCreateData) return;
    if (companyToEdit) {
      const valuesToEdit = {
        title: companyToEdit.title,
        type: contactsTypeOptions.find((contactType) => contactType.value === companyToEdit.type),
        contacts: companyToEdit.partner_contacts.map((contact) => ({ ...contact, partner_contacts_id: contact.id })),
        groups: companyToEdit.partner_groups?.map((group) => ({ label: group.title, value: group.id })),
        link: companyToEdit.link,
        departments: companyToEdit.partner_departments?.map((d) => ({ label: d.title, value: d.id })),
        employees: companyToEdit.partner_employees?.map((employee) => ({
          label: `${employee.last_name ? employee.last_name + ' ' : ''}${employee.first_name || ''}`,
          value: { id: employee.id },
        })),
        projects: companyToEdit.partner_projects?.map((project) => ({ label: project.title, value: project.id })),
        tags: companyToEdit.partner_tags?.map((tag) => ({ label: tag.name, value: tag.id })),
        partner_company_info: companyToEdit.partner_additional_information?.map((infoField) => {
          const field = { ...infoField };
          field.contact_info_id = infoField.id;
          if (infoField.type === 'list') {
            field.values = infoField.standard_values;
            field.value = infoField.value?.map((selectedOptionItem) => ({
              label: selectedOptionItem.value,
              value: selectedOptionItem.id,
            }));
          } else if (infoField.type === 'text' && infoField.value) {
            field.value = EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(String(infoField.value)).contentBlocks,
                convertFromHTML(String(infoField.value)).entityMap,
              ),
            );
          }
          return field;
        }),
      };
      if (contactCreateData.additional_information?.length) {
        valuesToEdit.partner_company_infos = contactCreateData.additional_information.map((infoField) => {
          const updInfoField = { ...infoField };
          updInfoField.contact_info_id = infoField.id;
          delete updInfoField.id;
          const fieldToEdit = companyToEdit.partner_additional_information?.find(
            (field) => field.id === updInfoField.contact_info_id,
          );
          if (!fieldToEdit) return updInfoField;
          if (infoField.type === 'list' && Array.isArray(fieldToEdit.value) && fieldToEdit.value.length) {
            updInfoField.value = infoField.value?.map((selectedOptionItem) => ({
              label: selectedOptionItem.value,
              value: selectedOptionItem.id,
            }));
          } else if (infoField.type === 'text' && fieldToEdit.value) {
            updInfoField.value = EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(String(fieldToEdit.value)).contentBlocks,
                convertFromHTML(String(fieldToEdit.value)).entityMap,
              ),
            );
          }

          return updInfoField;
        });
      }
      return valuesToEdit;
    } else {
      const defaultValues = {
        type: contactsTypeOptions[0],
      };
      if (contactCreateData?.additional_information?.length) {
        defaultValues.partner_company_infos = contactCreateData.additional_information.map((infoField) => {
          const updInfoField = { ...infoField };
          updInfoField.contact_info_id = infoField.id;
          delete updInfoField.id;
          return updInfoField;
        });
      }
      return defaultValues;
    }
  }, [companyToEdit, contactCreateData]);

  return defaultValues;
};

export default useContactCreateEditDefaultValues;
