import { memo, useMemo } from 'react';
import { object } from 'prop-types';
import { renderMessage } from 'src/utilize/draftjs-helper-functions';

import { StyledChatBasis, StyledChatLink } from '../styles';

SingleProjectHeaderMessageBasisFC.propTypes = {
  projectData: object,
};

function SingleProjectHeaderMessageBasisFC({ projectData }) {
  const { messageBasis, messageBasisLink } = useMemo(() => {
    const messageBasis = projectData.base_chat_message_id;
    let messageBasisLink = '';
    if (messageBasis) {
      messageBasisLink = `/projects/${messageBasis.root_project_id}`;
      if (messageBasis.task_id) messageBasisLink += `/tasks/${messageBasis.task_id}`;
      messageBasisLink += `?msg=${messageBasis.id}`;
    }
    return { messageBasis, messageBasisLink };
  }, [projectData]);

  return (
    <>
      {messageBasis && (
        <StyledChatBasis>
          <span>Создано на основании сообщения: </span>
          <StyledChatLink to={messageBasisLink}>{renderMessage(messageBasis.editor_state, null, true)}</StyledChatLink>
        </StyledChatBasis>
      )}
    </>
  );
}

const SingleProjectHeaderMessageBasis = memo(SingleProjectHeaderMessageBasisFC);

export default SingleProjectHeaderMessageBasis;
