import React, {useState, useEffect, useContext, useCallback} from 'react';
import axios from 'axios';
import {useParams, useLocation} from 'react-router-dom';

import Preloader from 'src/components/preloaders/Preloader';
import SnackbarContext from 'src/contexts/SnackbarContext';
import StorageModal from 'src/windows/storage/StorageModal';

import Tag from "@shared/components/Tag/Tag";

import {
  ProjectCard,
  ProjectCardDescription,
  ProjectCardHeader, ProjectCardLink, ProjectCardList, ProjectCardTags, ProjectCardTitle
} from "../Projects/project-list/project-list-container/style";

import StoragePageHeader from './StoragePageHeader';

import {StorageWrapper} from "./styles";


const StorageList = () => {
  const [storagesList, setStoragesList] = useState([]);
  const location = useLocation();
  const {storageId} = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const {showSnackbar} = useContext(SnackbarContext);

  const getStoragesList = useCallback(() => {
    axios
      .get('/api/storages_list')
      .then((r) => {
        setStoragesList(r.data.result);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при загрузке списка хранилищ');
      })
      .finally(() => setIsLoading(false));
  }, [showSnackbar]);

  useEffect(() => {
    getStoragesList();
  }, [getStoragesList]);

  return (
    <>
      <main>
        <StorageWrapper>
          <StoragePageHeader/>
          {isLoading && <Preloader/>}

          <ProjectCardList>
            {storagesList.map((storage, i) => (
                <ProjectCard key={i}>
                  <ProjectCardHeader>
                    <span>{storage.project_id ? 'К проекту' : 'Самостоятельное'}</span>
                  </ProjectCardHeader>
                  <ProjectCardTitle>{storage.title}</ProjectCardTitle>
                  <ProjectCardDescription>{storage.description} &nbsp;</ProjectCardDescription>

                  <ProjectCardTags>
                    {storage.storage_tags.map((item, i) => (
                      <Tag key={i} tag={item}/>
                    ))}
                  </ProjectCardTags>
                  <ProjectCardLink to={`/storages/${storage.id}`} state={{from: location}} />
                </ProjectCard>
            ))}
          </ProjectCardList>
        </StorageWrapper>
      </main>

      {storageId && <StorageModal storageId={storageId}/>}
    </>
  );
};

export default StorageList;
