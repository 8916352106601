import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmAction from '@components/warnings/ConfirmAction';

import { IconButton } from '@shared/components/IconButton/IconButton';
import iconEdit from '@assets/images/icons/edit.svg';
import iconDelete from '@assets/images/icons/delete.svg';

import {
  StyledModalGridButtons,
  StyledModalGridCell,
  StyledModalGridLabel,
  StyledModalGridRow,
  StyledSeparator,
} from '../../departments/department-list/styles';

// компонент списка должностей -
const PositionItem = ({ item, onDelete, onEdit, hasRightToEdit }) => {
  const { title, qty } = item;
  const { t } = useTranslation();

  const [confirmDeleteModal, showConfirmDeleteModal] = useState();

  return (
    <>
      <StyledModalGridRow>
        <StyledModalGridCell>
          <StyledModalGridLabel>Должность</StyledModalGridLabel>
          <p>{title}</p>
        </StyledModalGridCell>
        <StyledModalGridCell>
          <StyledModalGridLabel>Сотрудников</StyledModalGridLabel>
          <p>{qty}</p>
        </StyledModalGridCell>
        <StyledModalGridButtons>
          {hasRightToEdit && <IconButton size={18} icon={iconEdit} onClick={() => onEdit(item)} />}
          {qty === 0 && <IconButton size={18} icon={iconDelete} onClick={() => showConfirmDeleteModal(true)} />}
        </StyledModalGridButtons>
      </StyledModalGridRow>
      <StyledSeparator />

      {confirmDeleteModal && (
        <ConfirmAction
          actionText={t('PromptMessages.confirm_delete')}
          cancel={() => showConfirmDeleteModal(false)}
          confirm={() => {
            onDelete(item);
            showConfirmDeleteModal(false);
          }}
          confirmButtonText="Удалить"
        />
      )}
    </>
  );
};

export default PositionItem;
