import { Button } from '@shared/components/Button/Button';
import { Container } from '@shared/components/Container';
import { StyledText } from '@shared/components/Text/style';
import { styled } from 'styled-components';

export const ButtonsContainer = styled(Container)`
  justify-content: center;
  flex-wrap: wrap;
  gap: 18px 20px;
`;

export const StyledDescription = styled.div`
  a {
    color: #2996da;
    text-decoration: underline;
  }
`;

export const EditButton = styled(Button)``;

export const StyledUserName = styled(StyledText)`
  font-weight: 500;
  transition: all 0.3s;
  padding-top: 2px;
  margin-left: -2px;

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;
