import { IconButton } from '@shared/components';
import styled, { css } from 'styled-components';

export const StyledListContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledIconButton = styled(IconButton)`
  transition: 0.2s;
  transform: rotate(90deg);
  filter: invert(61%) sepia(0%) saturate(0%) hue-rotate(154deg) brightness(90%) contrast(89%);

  ${({ $open }) => $open && 'transform: rotate(90deg) scaleX(-1)'};
  color: ${({ theme }) => theme.borderSecondary};
`;

export const StyledList = styled.div`
  top: 20px;
  position: absolute;
  width: 250px;
  max-height: 300px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  border-radius: 2px;
  padding: 14px 14px;
  border: 1px solid ${({ theme }) => theme.borderSecondary};
  background-color: ${({ theme }) => theme.lightest};
  overflow-x: hidden;
  overflow-y: auto;
  height: 0;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }

  ${({ $open }) =>
    $open &&
    css`
      opacity: 1;
      z-index: 10;
      visibility: visible;
      height: auto;
    `};
`;
