import { memo } from 'react';
import { object } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toggleForwardModal } from 'src/redux/features/messagesSlice';
import iconArrow from '@assets/images/icons/arrow-right.svg';

import { StyledForwardButton } from './style';

ForwardButtonFC.propTypes = { forwardModalToggleBtnRef: object };

function ForwardButtonFC({ forwardModalToggleBtnRef }) {
  const isSelectMode = useSelector((state) => state.messages.isSelectMode);

  const dispatch = useDispatch();

  return (
    <>
      {isSelectMode && (
        <StyledForwardButton
          ref={forwardModalToggleBtnRef}
          $active={true}
          icon={iconArrow}
          onClick={() => dispatch(toggleForwardModal())}
        >
          Переслать
        </StyledForwardButton>
      )}
    </>
  );
}

const ForwardButton = memo(ForwardButtonFC);

export default ForwardButton;
