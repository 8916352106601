import axios from 'axios';
import { func, string, bool } from 'prop-types';
import { useCallback, useContext, useState } from 'react';

import SnackbarContext from 'src/contexts/SnackbarContext';
import { Modal } from '@shared/components';
import ArrowIcon from 'src/assets/images/icons/arrow-black.svg';

import ProjectSelect from './ProjectSelect';
import StructureDropdown from './StructureDropdown/StructureDropdown';

export const itemLabelCustomStylesFunc = (params) => {
  if (!params.$hasSublist) {
    return `&:before {
      display: none      
    }`;
  }
  if (params.$active) {
    return `&:before {
        -webkit-transform: translateY(-50%) rotate(90deg);
        transform: translateY(-50%) rotate(90deg);
    }`;
  }
};

export const deleteCurrentTask = (tasksList, currentTaskId) => {
  for (let i = 0; i < tasksList.length; i++) {
    if (tasksList[i].id === currentTaskId) {
      tasksList.splice(i, 1);
      return true;
    } else if (tasksList[i].tasks?.length) {
      const isDeleted = deleteCurrentTask(tasksList[i].tasks, currentTaskId);
      if (isDeleted) return true;
    }
  }
};

ProjectTransferModal.propTypes = {
  close: func,
  submitRequest: func,
  currentProjectId: string,
  currentTaskId: string,
  hideCurrentProject: bool,
};

function ProjectTransferModal({ close, submitRequest, currentProjectId, currentTaskId, hideCurrentProject }) {
  const [selectedTaskData, setSelectedTaskData] = useState();

  const [selectedProjectOption, setSelectedProjectOption] = useState();

  const { showSnackbar } = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState();

  const handleSubmit = useCallback(async () => {
    if (!selectedTaskData?.id || !selectedProjectOption?.value || typeof submitRequest !== 'function') return;
    setIsSubmitting(true);
    try {
      await submitRequest({
        selectedProjectId: selectedProjectOption.value,
        selectedTaskId: selectedTaskData.id,
      });
    } catch {
      showSnackbar('Возникла ошибка при отправке запроса');
    } finally {
      setIsSubmitting(false);
    }
  }, [submitRequest, selectedTaskData]);

  const getProjectOptions = useCallback(async () => {
    const response = await axios.get('api/projects_for_convert');
    if (!Array.isArray(response.data.result)) return;
    let projectsList = response.data.result;
    if (hideCurrentProject) {
      projectsList = projectsList.filter((project) => project.id !== +currentProjectId);
    }
    return projectsList.reverse().map((project) => ({ label: project.title, value: project.id }));
  }, [currentProjectId, hideCurrentProject]);

  const getProjectStructure = useCallback(
    async (project_id) => {
      const response = await axios.get(`/api/tasks_for_convert/${project_id}`);
      if (!Array.isArray(response.data.result)) return;
      const projectStructure = response.data.result;

      if (currentTaskId && selectedProjectOption?.value === +currentProjectId) {
        deleteCurrentTask(projectStructure, Number(currentTaskId));
      }
      return projectStructure;
    },
    [currentTaskId, selectedProjectOption],
  );

  return (
    <Modal
      onClose={close}
      title="Сменить родителя"
      disabledSaveButton={!selectedTaskData || isSubmitting}
      confirmButtonText="Перенести"
      onSave={handleSubmit}
    >
      <ProjectSelect setSelectedProjectOption={setSelectedProjectOption} getProjectOptions={getProjectOptions} />

      {selectedProjectOption && (
        <StructureDropdown
          structureId={selectedProjectOption.value}
          structureTitle={selectedProjectOption.label}
          getStructure={getProjectStructure}
          selectedStructureItem={selectedTaskData}
          setSelectedStructureItem={setSelectedTaskData}
          dropdownLabel="Структура проекта"
          errorMsg="Возникла ошибка при запросе структуры проекта"
          sublistKey="tasks"
          structureItemIcon={ArrowIcon}
          itemLabelCustomStylesFunc={itemLabelCustomStylesFunc}
        />
      )}
    </Modal>
  );
}

export default ProjectTransferModal;
