import { Routes, Route } from 'react-router-dom';

import PrivateRoute from '../../routes/components/PrivateRoute';

import StorageList from './StorageList';

const StorageRoutes = () => {
  return (
    <Routes>
      {['/:storageId?', '/:storageId/folder/:folderId', '/:storageId/bin'].map((path) => (
        <Route
          key="Storage"
          path={path}
          element={
            <PrivateRoute>
              <StorageList />
            </PrivateRoute>
          }
        />
      ))}
    </Routes>
  );
};

export default StorageRoutes;
