import { Button, Modal } from '@shared/components';
import { Container } from '@shared/components/Container';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';

import { HrLine } from '@shared/components/Hr/styles';

import { SearchResults } from './ui';
import SearchForm from './SearchForm';

export const SearchModal = ({ methods, onClose, isLoading, searchResults, setSearchResults, triggerSubmit }) => {
  const {
    control,
    register,
    resetField,
    reset,
    formState: { isDirty },
  } = methods;

  useEventTriggerOnEscPress(onClose);

  return (
    <Modal title="Поиск" modalSize="1168px" onClose={onClose} withoutButtons>
      <SearchForm control={control} register={register} resetField={resetField} triggerSubmit={triggerSubmit} />
      <Container justify={'center'} gap="24px">
        <Button width={'200px'} onClick={triggerSubmit} disabled={!isDirty}>
          Найти
        </Button>
        <Button
          width={'200px'}
          onClick={() => {
            reset();
            setSearchResults();
          }}
          color="light"
        >
          Очистить
        </Button>
      </Container>
      {(searchResults || isLoading) && <HrLine />}

      <SearchResults searchResults={searchResults} isLoading={isLoading} onClose={onClose} />
    </Modal>
  );
};
