import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ButtonsContainer = styled.div``;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  margin-bottom: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #262626;
  font: inherit;
  outline: none;
  padding: 8px 8px 8px 12px;
  position: relative;
  width: 100%;

  min-height: ${({ isControlsVisible }) => (!!isControlsVisible ? '12em' : '6em')};

  & > * {
    z-index: 0 !important;
  }

  a {
    color: #0069c2;
    text-decoration: underline;
  }
`;
