import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@shared/components/IconButton/IconButton';
import iconEdit from '@assets/images/icons/edit.svg';
import iconDelete from '@assets/images/icons/delete.svg';
import { Avatar } from '@shared/components/Avatar';
import { Container } from '@shared/components';
import ConfirmAction from 'src/components/warnings/ConfirmAction';

import {
  StyledModalGridButtons,
  StyledModalGridCell,
  StyledModalGridLabel,
  StyledModalGridRow,
  StyledSeparator,
} from './styles';

const DepartmentItem = ({ item, onDelete, onEdit, hasRightToEdit }) => {
  const { title, head_id, id, employees_qty, last_name, first_name } = item;

  const [confirmDeleteModal, showConfirmDeleteModal] = useState();

  const { t } = useTranslation();

  return (
    <>
      <StyledModalGridRow>
        <StyledModalGridCell>
          <StyledModalGridLabel>Отдел</StyledModalGridLabel>
          <p>{title}</p>
        </StyledModalGridCell>
        <StyledModalGridCell>
          <StyledModalGridLabel>Руководитель</StyledModalGridLabel>
          {head_id &&
            <Container align="center">
              <Avatar userId={head_id} /> <span>{`${first_name || ''} ${last_name || ''}`}</span>
            </Container>
          }
          {!head_id && <span>Не назначен</span>}
        </StyledModalGridCell>
        <StyledModalGridCell>
          <StyledModalGridLabel>Сотрудников</StyledModalGridLabel>
          <p>{employees_qty}</p>
        </StyledModalGridCell>
        <StyledModalGridButtons>
          {hasRightToEdit && <IconButton size={18} icon={iconEdit} onClick={() => onEdit(item)} />}
          {employees_qty === 0 && (
            <IconButton size={18} icon={iconDelete} onClick={() => showConfirmDeleteModal(true)} />
          )}
        </StyledModalGridButtons>
      </StyledModalGridRow>
      <StyledSeparator />

      {confirmDeleteModal && (
        <ConfirmAction
          actionText={t('PromptMessages.confirm_delete')}
          cancel={() => showConfirmDeleteModal(false)}
          confirm={() => {
            onDelete(id);
            showConfirmDeleteModal(false);
          }}
          confirmButtonText="Удалить"
        />
      )}
    </>
  );
};

export default DepartmentItem;
