import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { server_url } from 'src/settings/base-url';

export const rtkQueryApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: server_url,
    prepareHeaders: (headers) => {
      const token = Cookies.get('auth-token');
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
