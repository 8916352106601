import { Radio } from '@shared/components/Radio/Radio';

const PollTypeRadioButtons = ({ value, onChange }) => {
  const radioButtons = [
    {
      id: 'var1',
      label: 'Анонимный',
      value: 1,
      checked: value === 1,
      name: 'anonymous',
      onChange: () => onChange(1),
    },
    {
      id: 'var2',
      label: 'Не анонимный',
      value: 0,
      checked: value === 0,
      name: 'anonymous',
      onChange: () => onChange(0),
    },
  ];

  return <Radio items={radioButtons} />;
};

export default PollTypeRadioButtons;
