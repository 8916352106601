import { styled } from 'styled-components';

export const StyledControl = styled.span`
  color: #999;
  cursor: pointer;
  margin-right: 12px;
  padding: 2px 4px;
  display: inline-block;
  font-size: 16px;
`;

export const StyledIconButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  padding-top: 8px;
`;
