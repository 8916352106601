import { useEffect, useCallback, useState, useMemo /*, memo*/ } from 'react';
// import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {StorageRubric, StorageRubricItem, StorageRubricLink} from "@components/storage/styles";
import iconCatalogClose from "@assets/images/icons/icon-folder-close.svg";
import iconCatalogOpen from "@assets/images/icons/icon-folder-open.svg";

import { setOpenFolder } from '../../redux/features/storageSlice';
// import { arraysEqual } from "../../utilize/helper-functions";

const FolderAside = ({
  data,
  openParentFolder,
  folderIdParam,
  storageId,
  folderPath,
  handleSelect,
  selectedFolderId,
}) => {
  // const navigate = useNavigate();
  const [isFolderExpanded, setIsFolderExpanded] = useState(false);

  // используется для отображения пути открытой папки в детализации папки
  const folderPathArr = useMemo(() => {
    const currentPath = { [data.title]: data.id };

    if (Array.isArray(folderPath)) {
      return [...folderPath, currentPath];
    }
    return [currentPath];
  }, [folderPath, data]);

  const dispatch = useDispatch();

  // при изменении folder id, открыть релевантные папки
  useEffect(() => {
    if (data.id === +folderIdParam && folderPathArr) {
      if (openParentFolder) openParentFolder(true);
      setIsFolderExpanded(true);
      dispatch(setOpenFolder({ openFolder: data, folderPath: folderPathArr }));
    }
  }, [data, folderIdParam, openParentFolder, dispatch, folderPathArr]);

  // закрывать папки у которых нет подпапок, если folderIdParam  не соответствует id
  // текущей папки и свойство selectedFolderId не соответствует id текущей папки
  useEffect(() => {
    if (isFolderExpanded && data.folders.length === 0 && data.id !== +folderIdParam && data.id !== selectedFolderId) {
      setIsFolderExpanded(false);
    }
  }, [data, folderIdParam, isFolderExpanded, selectedFolderId]);

  // компонент FolderAside используется в двух местах.
  // 1) в навигационной панели слева 2) в выпадашке, при переносе/копировании файлов и папок
  // когда state isFolderExpanded действительно и один из следующих условий true, то возвращать стиль раскрытой папки:
  // - у ранее кликнутой папки есть подпапки;
  // - id папки соответствует параметру folderId в URL;
  // - id папки соответствует свойству selectedFolderId этого компонента
  const renderOpenFolder = () => {
    return (isFolderExpanded && (data.folders.length > 0 || data.id === +folderIdParam || selectedFolderId === data.id))
  };

  const renderSelectedFolder = () => {
    return (data.id === +folderIdParam || selectedFolderId === data.id)
  };

  const expandCurrentFolder = useCallback(
    (openToRoot) => {
      setIsFolderExpanded((isFolderExpanded) => {
        if (!isFolderExpanded) {
          return true;
        }
        return isFolderExpanded;
      });
      // setIsFolderExpanded(true);
      if (openToRoot && openParentFolder) openParentFolder(true);
    },
    [openParentFolder],
  );

  return (
    <>
      <StorageRubricItem
        onClick={(e) => {
          e.stopPropagation();
          // если папка не раскрыта, раскрыть папку и указать в адресной строке id папки
          // если папка раскрыта, то свернуть папку
          if (!isFolderExpanded) handleSelect(data.id, folderPathArr);
          setIsFolderExpanded(!isFolderExpanded);
        }}
      >
        <StorageRubricLink
          iconClose={iconCatalogClose}
          iconOpen={iconCatalogOpen}
          $active={renderOpenFolder()}
          $highlighted={renderSelectedFolder()}
        >
          {data.title}
        </StorageRubricLink>
        <StorageRubric hidden={!isFolderExpanded}>
          {data.folders.map((folder, i) => (
            <FolderAside
              key={i}
              data={folder}
              openParentFolder={expandCurrentFolder}
              folderIdParam={folderIdParam}
              storageId={storageId}
              folderPath={folderPathArr}
              handleSelect={handleSelect}
              selectedFolderId={selectedFolderId}
            />
          ))}
        </StorageRubric>
      </StorageRubricItem>
    </>
  );
};

export default FolderAside;
