import Resumable from 'resumablejs';
import useAuth from 'src/hooks/useAuth';
import { server_url } from 'src/settings/base-url';

import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { getStorageTree } from 'src/redux/features/storageSlice';

import { getQueryData } from './lib';

export const useResumable = ({ storageId, folderId, setFilesToUpload, onClose }) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  const queryData = getQueryData({ storageId, folderId });

  const resumable = new Resumable({
    target: `${server_url}/api/storage_file/add?token=${token}`,
    testTarget: `${server_url}/api/storage_file/test_chunk?token=${token}`,
    testChunksParams: { ...queryData },
    chunkSize: 2 * 1024 * 1024,
    throttleProgressCallbacks: 100,
    query: queryData,
    simultaneousUploads: 1,
  });

  resumable.on('fileProgress', (currentFile) => {
    const currentProgress = parseInt(Math.round(currentFile.progress() * 100));
    setFilesToUpload((files) =>
      files.map((fileData) => {
        if (fileData.file.uniqueIdentifier === currentFile.uniqueIdentifier) {
          return { ...fileData, progress: currentProgress, status: 'loading' };
        }
        return fileData;
      }),
    );
  });

  resumable.on('fileSuccess', (file) => {
    const successChunk = file.chunks.find((chunk) => {
      return String(chunk.message()).includes('insertId');
    });

    if (successChunk) {
      const insertId = JSON.parse(successChunk.message())?.insertId;

      setFilesToUpload((files) =>
        files.map((fileData) => {
          if (fileData.file.uniqueIdentifier === file.uniqueIdentifier) {
            return { ...fileData, localId: insertId, status: 'done' };
          }
          return fileData;
        }),
      );
    }
  });

  resumable.on('complete', function () {
    setTimeout(() => {
      dispatch(getStorageTree({ storageId, showSnackbar }));
      onClose();
    }, 1000);
  });

  resumable.on('error', function (message, file) {
    showSnackbar('Возникла ошибка при загрузке файлов', 'error');
  });

  return resumable;
};
