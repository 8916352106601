import {useMemo} from 'react';
import {Label, Radio} from '@shared/components';
import {useController} from 'react-hook-form';

import IndependentStorage from './IndependentStorage';
import ProjectAttachedStorage from './ProjectAttachedStorage';

const StorageType = ({control, register, editData}) => {
  const {field} = useController({control, name: 'type'});

  const radioButtons = useMemo(
    () => [
      {
        id: 'var1',
        label: 'Независимое',
        value: 'independent',
        name: 'type',
        checked: field.value === 'independent',
        onChange: () => field.onChange('independent'),
      },
      {
        id: 'var2',
        label: 'Привязанное к проекту',
        value: 'attached',
        name: 'type',
        checked: field.value === 'attached',
        onChange: () => field.onChange('attached'),
      },
    ],
    [field?.onChange, field?.value],
  );

  return (
    <>
      <div>
        <Label htmlFor="type">Тип хранилища</Label>
        <Radio items={radioButtons}/>
      </div>

      <div>
        {field?.value === 'independent' && <IndependentStorage register={register}/>}
        {field?.value === 'attached' && (
          <ProjectAttachedStorage control={control} selectedProjectTitle={editData?.project_title}/>
        )}
      </div>
    </>
  );
};

export default StorageType;
