import styled from 'styled-components';

export const OrderedList = styled.ol`
  list-style: decimal;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const OrderedListItemContent = styled.div`
  display: flex;
  gap: 0.5rem;
`;
