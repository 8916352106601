import React from 'react';

import { Outlet } from 'react-router-dom';

import TeamHeader from './team-header';

const Team = () => {
  return (
    <main>
      <TeamHeader />
      {/* то что показывает вложенные роуты  */}
      <Outlet />
    </main>
  );
};

export default Team;
