import { useCallback } from 'react';
import { Input, Label } from '@shared/components';

import { SearchTypeSelect, SearchFilters, TagsSelect } from './ui';

const SearchForm = ({ control, register, resetField, triggerSubmit }) => {
  const handleEnterPress = useCallback((e) => {
    if (e.key === 'Enter' && e.target.value) triggerSubmit();
  }, []);

  return (
    <>
      <SearchTypeSelect control={control} resetField={resetField} />
      <div>
        <Label>Поисковая строка</Label>
        <Input register={{ ...register('search_expression') }} onKeyUp={handleEnterPress} />
      </div>
      <SearchFilters control={control} name="usersFilter" />
      <TagsSelect control={control} name="tags" />
    </>
  );
};

export default SearchForm;
