import {Label, Textarea} from "@shared/components";

const IndependentStorage = ({ register }) => {
  return (
    <>
      <Label htmlFor="description">
        Описание
      </Label>
      <Textarea id="description" rows="2" register={register('description')}></Textarea>
    </>
  );
};

export default IndependentStorage;
