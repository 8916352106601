import {useState, useRef, useContext, useCallback} from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import {IconButton} from "@shared/components";
import {StyledDotsMenu, StyledDotsMenuItem, StyledDotsWrapper} from "@components/storage/styles";
import iconDots from "@assets/images/icons/subtask-dots.svg";

import { useOutsideTrigger } from '../../utilize/helper-functions';
import ConfirmAction from '../warnings/ConfirmAction';
import { getStorageTree } from '../../redux/features/storageSlice';
import SnackbarContext from '../../contexts/SnackbarContext';
import useMenuPosition from "../../hooks/useCoordinates/useMenuPosition";


const FolderVersionRowMenu = ({ dataType, versionData, setIsSubmitting, userCanWrite }) => {
  const [openFolderMenu, setOpenFolderMenu] = useState(false);
  const { storageId } = useParams();

  const folderMenuBtn = useRef();
  const folderMenu = useRef();

  const [confirmPrimaryChoice, setConfirmPrimaryChoice] = useState();

  useOutsideTrigger([folderMenuBtn, folderMenu]);
  const refsArray = useRef([folderMenuBtn, folderMenu]);

  const outsideClickEvent = useCallback(() => setOpenFolderMenu(false), []);

  useOutsideTrigger(refsArray.current, outsideClickEvent, openFolderMenu);

  const coordinates = useMenuPosition({
      triggerRef: folderMenuBtn,
      tooltipRef: folderMenu,
      isVisible: openFolderMenu,
      setIsVisible: setOpenFolderMenu,
      position: 'right-top',
    },
  );

  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  const submitPrimaryChoice = () => {
    setIsSubmitting(true);
    axios
      .put('/api/set_as_current_version', {
        [`${dataType}_id`]: versionData.id,
      })
      .then(() => {
        dispatch(getStorageTree({ storageId, showSnackbar }));
        showSnackbar(`Основная версия ${dataType === 'folder' ? 'папки' : 'файла'} изменена`, 'success');
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при изменении основной версии');
      });
  };

  if (!userCanWrite) return '';
  return (
    <StyledDotsWrapper>
      <IconButton
        $ref={folderMenuBtn}
        icon={iconDots}
        background="#F0F0F0"
        size={24}
        style={{ backgroundSize: 'auto' }}
        onClick={(e) => {
          e.stopPropagation();
          setOpenFolderMenu(!openFolderMenu);
        }}
      />
        <StyledDotsMenu
          $active={openFolderMenu}
          ref={folderMenu}
          style={{...coordinates}}
        >
        <StyledDotsMenuItem
          onClick={(e) => {
            e.stopPropagation();
            setOpenFolderMenu(false);
            setConfirmPrimaryChoice(true);
          }}
        >
          Сделать основным
        </StyledDotsMenuItem>
      </StyledDotsMenu>

      {confirmPrimaryChoice && (
        <ConfirmAction
          actionText="Вы уверены, что хотите сделать эту версию основной?"
          confirm={(e) => {
            e.stopPropagation();
            submitPrimaryChoice();
            setConfirmPrimaryChoice(false);
          }}
          cancel={() => setConfirmPrimaryChoice(false)}
        />
      )}
    </StyledDotsWrapper>
  );
};

export default FolderVersionRowMenu;
