import React, { useState, useMemo, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation } from 'react-i18next';

import SelectInput from '@components/form/form-select/SelectInput';
import SelectMulty from '@components/form/select/SelectMulty';
import ConfirmAction from '@components/warnings/ConfirmAction';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { Input } from '@shared/components/Input/Input';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';

// настройка валидации формы
const schemaDepartment = yup.object().shape({
  title: yup.string().required(),
  // head_id: yup.object().shape({
  //   label: yup.string().required('Required'),
  //   value: yup.string().required('Required'),
  // }),
  // если нужно для мультиселекта
  // employees_department: yup.array()
  //   .of(
  //     yup.object().shape({
  //       label: yup.string().required(),
  //       value: yup.string().required(),
  //     })
  //   )
});
// устанавливаем значение полей пострудников, принад. отделу, массив всех сотрудников и принад.  отделу должен быть одним и тем же
const getPrevEmployees = (data) => {
  const freeAndOwnEmployees = [...data.free_employees, ...data.employees];

  // найти тех сотрудников в списке свободных, которые принадлежат отделу
  // id в двух массивах могут быть одинаковыми, сравниваем по имени тоже
  let defaultWorkers = freeAndOwnEmployees.filter((el) =>
    data.employees.some(
      (el2) => el2.id === el.id && el2?.last_name === el?.last_name && el2?.first_name === el?.first_name,
    ),
  );
  return formatOptions(defaultWorkers);
};

const formatOptions = (arr) => {
  return arr.map((el) => {
    return {
      label: `${el.first_name} ${el.last_name || ''} `,
      value: `${el.first_name} ${el.last_name || ''} `,
      id: el.id,
    };
  });
};

const DepartmentForm = ({ action, inputs, departData, onCreate, onClose, setModalAddDep }) => {
  const {
    control,
    formState: { isValid, isDirty, errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(schemaDepartment),
    mode: 'onChange',
    // значение по умолчанию ставим всегда - в зависимости от выбранного action (edit или add) ставим пустое поле или уже выбранные value
    defaultValues: {
      title: action === 'edit' ? departData.title : '',
      head_id:
        action === 'edit'
          ? {
              label: departData.head_name,
              value: departData.head_id,
            }
          : '',
      employees_department: action === 'edit' ? getPrevEmployees(departData) : '',
    },
  });

  const { t } = useTranslation();

  const headData = useMemo(() => {
    return {
      name: 'head_id',
      type: 'select',
      subType: 'tag',
      options: departData?.free_employees || [],
    };
  }, [departData, t]);

  const [modalClosePrompt, setModalClosePrompt] = useState();

  const handleClose = useCallback(() => {
    if (isDirty) {
      setModalClosePrompt(true);
    } else onClose();
  }, [onClose, isDirty, setModalClosePrompt]);

  useEventTriggerOnEscPress(handleClose);

  return (
    <>
      <Modal
        title={action === 'add' ? t('TeamHeader.create_departments') : t(`DepartmentForm.title_edit`)}
        onClose={handleClose}
        disabledSaveButton={!isValid || !isDirty}
        onSave={handleSubmit(onCreate)}
        confirmButtonText={action === 'edit' ? 'Сохранить' : 'Добавить'}
      >
        <div>
          <Label htmlFor="title">
            Название <sup>*</sup>
          </Label>

          <Input error={!!errors['title']} id="title" register={register('title')} />
        </div>

        <div>
          <Label htmlFor="">
            Руководитель отдела
          </Label>
          <SelectInput
            control={control}
            data={headData}
            // для отображения в инпуте
            value="first_name"
            value2="last_name"
          />
        </div>

        <SelectMulty
          options={formatOptions([...departData.free_employees, ...departData.employees])}
          item={inputs.employees_department}
          control={control}
        />
      </Modal>

      {modalClosePrompt && (
        <ConfirmAction
          actionText={t('common.confirm_modal_close')}
          cancel={() => setModalClosePrompt(false)}
          confirm={() => {
            setModalAddDep({ isOpen: false, action: null });
            setModalClosePrompt(false);
          }}
        />
      )}
    </>
  );
};

export default DepartmentForm;