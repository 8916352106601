import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { processResponseErrors, errorTypes } from '../../utilize/processResponseErrors';
import i18n from '../../i18n';

const initialState = {
  tagsList: null,
  isLoading: false,
  isSending: false,
  error: null,
};

export const addTag = createAsyncThunk('tags/addTag', async (payload, thunkAPI) => {
  try {
    await axios.post('/api/tags/add', {
      row: payload.data,
    });
    payload.clear();
    thunkAPI.dispatch(getTags());
  } catch (e) {
    const response = processResponseErrors(e);

    switch (response) {
      case errorTypes.NETWORK:
        return thunkAPI.rejectWithValue(i18n.t('ErrorMessages.network'));
      case errorTypes.DUPLICATE:
        return thunkAPI.rejectWithValue(i18n.t('ErrorMessages.tags.exist'));
      default:
        return thunkAPI.rejectWithValue(i18n.t('ErrorMessages.tags.default'));
    }
  }
});

export const getTags = createAsyncThunk('tags/getTags', async (payload, thunkAPI) => {
  try {
    const response = await axios.get('/api/tags');
    return response.data.tags.sort((a, b) => a.name.localeCompare(b.name));
  } catch (e) {
    return thunkAPI.rejectWithValue('ERROR WHILE FETCHING TAGS');
  }
});

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.isLoading = false;
      state.isSending = false;
      state.error = null;
    },
  },
  extraReducers: {
    [addTag.pending]: (state) => {
      state.isSending = true;
      state.error = null;
    },
    [addTag.fulfilled]: (state) => {
      state.isSending = false;
      state.error = null;
    },
    [addTag.rejected]: (state, { payload }) => {
      state.isSending = false;
      state.error = payload;
    },

    [getTags.pending]: (state) => {
      state.isLoading = true;
    },
    [getTags.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.tagsList = payload;
    },
    [getTags.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { clearErrors } = tagsSlice.actions;

export default tagsSlice.reducer;
