import React, { memo } from 'react';

import { StyledCompanyItem, StyledKey, StyledValue } from './styles';

const CompanyItem = ({ columns, contact }) => {
  return (
    <StyledCompanyItem columns={columns}>
      {contact.contact_name && (
        <StyledKey>
          Контакт: <StyledValue>{contact.contact_name}</StyledValue>
        </StyledKey>
      )}
      {contact.email && (
        <StyledKey>
          Электронная почта: <StyledValue>{contact.email}</StyledValue>
        </StyledKey>
      )}
      {contact.phone && (
        <StyledKey>
          Телефон: <StyledValue>{contact.phone}</StyledValue>
        </StyledKey>
      )}
      {contact.others && (
        <StyledKey>
          Другие контакты: <StyledValue>{contact.others}</StyledValue>
        </StyledKey>
      )}
    </StyledCompanyItem>
  );
};

export default memo(CompanyItem);
