import { useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectStructureContextProvider } from 'src/contexts/ProjectStructureContext';
import { useMessageContext } from 'src/contexts/MessageContext';
import useAuth from 'src/hooks/useAuth';

import {IconButton} from "@shared/components";
import {StyledAside, StyledAsideMenu} from "@components/chat/side-menu/styles";
import iconInfo from "@assets/images/icons/icon-info.svg";
import iconFile from "@assets/images/icons/icon-file.svg";
import iconAttach from "@assets/images/icons/icon-links.svg";
import iconStructure from "@assets/images/icons/icon-structure.svg";
import iconResults from "@assets/images/icons/icon-goals.svg";
import iconSearch from "@assets/images/icons/search.svg";
import iconTags from "@assets/images/icons/chat-tags.svg";
//import iconEvent from "@assets/images/icons/chat-event.svg";
import iconMembers from '@assets/images/icons/subtask-profile.svg';

import InfoSidePanel from './InfoSidePanel';
import FilesSidePanel from './FilesSidePanel';
import LinksSidePanel from './LinksSidePanel';
import StructureSidePanel from './StructureSidePanel';
import ResultsSidePanel from './ResultsSidePanel';
import SearchSidePanel from './SearchSidePanel';
import TagsSidePanel from './TagsSidePanel';
// import EventsSidePanel from "./EventsSidePanel";
import MembersSidePanel from './MembersSidePanel';

export const renderProjectOrTaskName = (panelData, taskId, sidebarDataType, projectData) => {
  let projectType = '';
  if (!panelData) {
    if (!projectData) return;
    projectType = taskId ? 'Задача' : 'Проект';
    return `${projectType}: ${projectData.title} `;
  }
  if (!sidebarDataType) {
    projectType = taskId ? 'Задача' : 'Проект';
  } else {
    projectType = sidebarDataType === 'task' ? 'Задача' : 'Проект';
  }
  return `${projectType}: ${panelData.title}`;
};

const SideMenuContainer = (props) => {
  const { guestRights } = useMessageContext();
  const auth = useAuth();

  return <SideMenu {...props} guestRights={guestRights} auth={auth} />;
};

const SideMenu = memo(({ projectData, guestRights, auth, showSideMenuOnSwipe }) => {
  const { t } = useTranslation();

  const [sideMenu, openSideMenu] = useState();

  const toggleSideMenu = (e) => {
    const menuName = e.target.dataset.triggerAside;
    if (sideMenu === menuName) return openSideMenu(null);
    openSideMenu(menuName);
  };

  useEffect(() => {
    if (document.body.clientWidth > 1150) openSideMenu('db');
  }, []);

  return (
    <>
      <StyledAsideMenu $active={showSideMenuOnSwipe}>
        <IconButton
          $active={sideMenu === 'info'}
          icon={iconInfo}
          size={22}
          data-trigger-aside="info"
          title={t('common.info')}
          onClick={toggleSideMenu}
        />

        <IconButton
          $active={sideMenu === 'file'}
          icon={iconFile}
          size={20}
          data-trigger-aside="file"
          title={t('common.files')}
          onClick={toggleSideMenu}
        />

        <IconButton
          $active={sideMenu === 'link'}
          icon={iconAttach}
          size={18}
          data-trigger-aside="link"
          title={t('common.links')}
          onClick={toggleSideMenu}
        />

        {(!auth?.user?.is_guest || guestRights?.includes('access_children')) && (
          <IconButton
            $active={sideMenu === 'db'}
            icon={iconStructure}
            size={20}
            data-trigger-aside="db"
            title={t('common.structure')}
            onClick={toggleSideMenu}
          />
        )}

        {(Boolean(projectData?.project_goals?.length) || Boolean(projectData?.task_goals?.length)) && (
          <IconButton
            $active={sideMenu === 'results'}
            icon={iconResults}
            size={22}
            data-trigger-aside="results"
            title={t('common.results')}
            onClick={toggleSideMenu}
          />
        )}

        <IconButton
          $active={sideMenu === 'search'}
          icon={iconSearch}
          size={20}
          data-trigger-aside="search"
          title={t('common.search')}
          onClick={toggleSideMenu}
        />

        <IconButton
          $active={sideMenu === 'tags'}
          icon={iconTags}
          size={18}
          data-trigger-aside="tags"
          title={t('common.tags')}
          onClick={toggleSideMenu}
        />

        {/*<IconButton*/}
        {/*  $active={sideMenu === 'event'}*/}
        {/*  icon={iconEvent}*/}
        {/*  size={20}*/}
        {/*  data-trigger-aside="event"*/}
        {/*  title={t('common.event')}*/}
        {/*  onClick={toggleSideMenu}*/}
        {/*/>*/}

        <IconButton
          $active={sideMenu === 'people'}
          icon={iconMembers}
          size={20}
          data-trigger-aside="people"
          title={t('common.members')}
          onClick={toggleSideMenu}
        />
      </StyledAsideMenu>
      <ProjectStructureContextProvider>
        <StyledAside $active={sideMenu}>
          {sideMenu === 'info' && (
            <InfoSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
          )}

          {sideMenu === 'file' && (
            <FilesSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
          )}

          {sideMenu === 'link' && (
            <LinksSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
          )}

          {(!auth?.user?.is_guest || guestRights?.includes('access_children')) && sideMenu === 'db' && (
            <StructureSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
          )}

          {(Boolean(projectData?.project_goals?.length) || Boolean(projectData?.task_goals?.length)) &&
            sideMenu === 'results' && (
              <ResultsSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
            )}

          {sideMenu === 'search' && (
            <SearchSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
          )}

          {sideMenu === 'tags' && (
            <TagsSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
          )}

          {/* sideMenu === 'event' && <EventsSidePanel
          sideMenu={sideMenu}
          openSideMenu={openSideMenu}
          projectData={projectData}
        />*/}

          {sideMenu === 'people' && (
            <MembersSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
          )}
        </StyledAside>
      </ProjectStructureContextProvider>
    </>
  );
});

export default SideMenuContainer;
