import { useEffect, useRef, useState } from 'react';

const useCoordinates = (deps) => {
  const [coordinates, setCoordinates] = useState({});
  const elementRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    const tooltip = tooltipRef.current;
    function handleClickOrMouseMove(evt) {
      const windowWidth = window.innerWidth;

      const { right, left, height } = evt.currentTarget.getBoundingClientRect();

      if (windowWidth - right <= tooltip?.offsetWidth) {
        setCoordinates({
          right: 0 + 'px',
          top: height + 4 + 'px',
        });
      }

      if (left <= tooltip?.offsetWidth) {
        setCoordinates({
          left: 0 + 'px',
          top: height + 4 + 'px',
        });
      }
    }

    if (element) {
      element.addEventListener('click', handleClickOrMouseMove);
      element.addEventListener('mouseenter', handleClickOrMouseMove);
    }

    return () => {
      if (element) {
        element.removeEventListener('click', handleClickOrMouseMove);
        element.removeEventListener('mouseenter', handleClickOrMouseMove);
      }
    };
  }, [deps]);

  return { coordinates, elementRef, tooltipRef };
};

export default useCoordinates;