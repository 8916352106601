export const getQueryData = ({ storageId, folderId }) => {
  if (storageId && !folderId) {
    return { storage_id: storageId };
  }

  if (storageId && folderId) {
    return {
      folder_id: folderId,
    };
  }
};

export function getFileNameWithoutExtension(filename) {
  // Проверка, что передана строка
  if (typeof filename !== 'string') {
    throw new Error('Аргумент должен быть строкой');
  }

  // Находим последний индекс точки в строке
  const lastDotIndex = filename.lastIndexOf('.');

  // Если точки нет, возвращаем оригинальное имя файла
  if (lastDotIndex === -1) {
    return filename;
  }

  // Возвращаем подстроку до последней точки
  return filename.substring(0, lastDotIndex);
}
