import React, { useEffect, useRef, useState } from 'react';
import Editor from '@draft-js-plugins/editor';

import createToolbarPlugin from '@draft-js-plugins/static-toolbar';

import { BoldButton, ItalicButton, UnderlineButton } from '@draft-js-plugins/buttons';

import { IconButton } from '@shared/components/IconButton/IconButton';

import boldTextIcon from '@assets/images/icons/chat-richtext.svg';

import { HrLine } from '@shared/components/Hr/styles';

import { EditorState } from 'draft-js';

import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';

import { Wrapper } from './styles';
import { StyledIconButtonWrapper } from './ui/InlineStyleButtons/styles';

import '@draft-js-plugins/static-toolbar/lib/plugin.css';

export function RichTextEditor({ value, onChange, extraButtons }) {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const [visible, setVisible] = useState(false);

  const [{ plugins, Toolbar }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin();
    const linkDetectionPlugin = createLinkDetectionPlugin();

    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin, linkDetectionPlugin];
    return {
      plugins,
      Toolbar,
    };
  });

  const editorRef = useRef(null);

  const focusEditor = () => {
    editorRef.current.focus();
  };

  useEffect(() => {
    value && setEditorState(value);
  }, [value]);

  return (
    <div onClick={focusEditor}>
      <Wrapper isControlsVisible={visible}>
        <div style={{ order: 2 }}>
          <Editor
            editorState={editorState}
            onChange={(value) => {
              onChange && onChange(value);
              setEditorState(value);
            }}
            plugins={plugins}
            ref={(editor) => (editorRef.current = editor)}
          />
        </div>

        {visible && (
          <div style={{ order: 1, paddingBottom: '12px' }}>
            <Toolbar>
              {(externalProps) => (
                <>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                </>
              )}
            </Toolbar>
            <HrLine />
          </div>
        )}
      </Wrapper>

      <StyledIconButtonWrapper>
        <IconButton
          size={14}
          icon={boldTextIcon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setVisible((prev) => !prev);
          }}
        />

        {extraButtons}
      </StyledIconButtonWrapper>
    </div>
  );
}
