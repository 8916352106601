import { Controller } from 'react-hook-form';
import { Checkbox } from '@shared/components';
import { object, shape, number, string, arrayOf } from 'prop-types';

import { StyledImageContainer } from '../styles';

import { StyledCheckboxLabel, StyledOptionsWrapper } from './styles';

MultiAnswer.propTypes = {
  control: object,
  options: arrayOf(
    shape({
      id: number,
      percentage_ratio: number,
      vote_count: number,
      label: string,
    }),
  ),
};

export function MultiAnswer({ control, options }) {
  const handleCheckboxChange = (isChecked, onChange, value, option) => {
    if (isChecked) onChange(value.filter((id) => id !== option.id));
    else onChange([...value, option.id]);
  };

  return (
    <StyledOptionsWrapper>
      {options.map((option) => (
        <Controller
          key={option.id}
          name="answers"
          control={control}
          render={({ field: { value, onChange } }) => {
            if (!Array.isArray(value)) return null;

            const isChecked = value?.includes(option.id);

            return (
              <Checkbox
                id={option.id}
                checked={isChecked}
                onChange={() => handleCheckboxChange(isChecked, onChange, value, option)}
              >
                <StyledCheckboxLabel>
                  {option.image && (
                    <StyledImageContainer>
                      <img src={option.image} alt="option" />
                    </StyledImageContainer>
                  )}{' '}
                  {option.label}
                </StyledCheckboxLabel>
              </Checkbox>
            );
          }}
        />
      ))}
    </StyledOptionsWrapper>
  );
}
