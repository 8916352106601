import React, { useContext, useEffect, useState } from 'react';
import { Input, Label, Modal } from '@shared/components';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import SnackbarContext from 'src/contexts/SnackbarContext';
import { useCreateGroupMutation, useEditGroupMutation } from 'src/redux/features/api/contacts';
import ConfirmAction from '@components/warnings/ConfirmAction';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';

const formSchema = object({
  title: string().required(),
});

const CreateEditGroup = ({ close, groupToEdit }) => {
  const [modalClosePrompt, setModalClosePrompt] = useState();

  const [createGroup, { isLoading: isSubmitting }] = useCreateGroupMutation();
  const [editGroup, { isLoading: isEditing }] = useEditGroupMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (groupToEdit) {
      reset({
        title: groupToEdit.title,
      });
    }
  }, [groupToEdit]);

  const handleCloseBtnClick = () => {
    if (modalClosePrompt) return;
    if (isDirty) return setModalClosePrompt(true);
    close();
  };

  const { showSnackbar } = useContext(SnackbarContext);

  const sumbitRequest = async (data) => {
    const reqBody = {
      row: {
        title: data.title,
      },
    };
    if (groupToEdit) reqBody.company_group_id = groupToEdit.id;

    const result = groupToEdit ? await editGroup(reqBody) : await createGroup(reqBody);
    if ('error' in result) showSnackbar(`Возникла ошибка при ${groupToEdit ? 'редактировании' : 'создании'} группы`);
    else close();
  };

  useEventTriggerOnEscPress(handleCloseBtnClick);

  return (
    <Modal
      modalSize="880px"
      onSave={handleSubmit(sumbitRequest)}
      onClose={handleCloseBtnClick}
      title={`${groupToEdit ? 'Редактировать' : 'Создать'} группу контактов`}
      confirmButtonText={groupToEdit ? 'Сохранить' : 'Создать'}
      disabledSaveButton={!isValid || !isDirty || isSubmitting || isEditing}
    >
      <div>
        <Label htmlFor="title">
          Название <sup>*</sup>
        </Label>
        <Input id="title" error={!!errors['title']} register={register('title')} autoComplete="off" />
      </div>

      {modalClosePrompt && (
        <ConfirmAction
          confirm={close}
          cancel={() => setModalClosePrompt(false)}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
    </Modal>
  );
};

export default CreateEditGroup;
