import React from 'react';
import {Link} from 'react-router-dom';

import {Avatar} from '@shared/components/Avatar';
import {Text} from "@shared/components";

import iconMail from "@assets/images/icons/mail.svg";
import iconPhone from "@assets/images/icons/phone.svg";

import {TeamCard, TeamCardSlash, TeamSocial, TeamSocialIcon, TeamSocialItem, TeamUserWrapper} from "./style";

const TeamListItem = ({id, first_name, last_name, status, email, contacts_phone, position, department, location}) => {
  return (
    <TeamCard>
        <span>
          {(position && position) || 'Без должности'}
          <TeamCardSlash>/</TeamCardSlash>
          <span>{(department && department) || 'Отдел не назначен'}</span>
        </span>

      <TeamUserWrapper>
        <Avatar userId={id} type="team-card__userpic"/>

        <Link to={`/team/users/${id}`}>
          <Text size={3}> {last_name} {first_name} </Text>
          <Text size={1}>{(status && status) || ''}</Text>
        </Link>
      </TeamUserWrapper>

      <TeamSocial>
        <TeamSocialItem href={`mailto:${email}`}>
          <TeamSocialIcon icon={iconMail}/>
          <Text size={2}>{email}</Text>{' '}
        </TeamSocialItem>

        {contacts_phone && (
          <TeamSocialItem href={`tel:${contacts_phone}`}>
            <TeamSocialIcon icon={iconPhone}/>
            <Text size={2}>{contacts_phone}</Text>
          </TeamSocialItem>
        )}
      </TeamSocial>
    </TeamCard>
  );
};

export default TeamListItem;
