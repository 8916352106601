import React from 'react';

import { Text } from '../Text/Text';

import { StyledContainer } from './styles';

export const Placeholder = ({ text, textSize, heigth }) => {
  return (
    <StyledContainer heigth={heigth}>
      <Text size={textSize || 4}>{text || 'Нет данных для отображения'}</Text>
    </StyledContainer>
  );
};
