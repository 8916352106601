import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { FilterButton, StyledList } from './styles';
import { namesButton } from './lib';

export const Tabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <StyledList>
      {namesButton.map((btn, i) => {
        return (
          <FilterButton
            key={i}
            $active={searchParams.get('filter') === btn.key}
            onClick={() =>
              setSearchParams((s) => {
                const params = Object.fromEntries(s);
                if (btn.key) return { ...params, filter: btn.key.toString() };
                delete params.filter;
                return params;
              })
            }
          >
            {btn.name}
          </FilterButton>
        );
      })}
    </StyledList>
  );
};
