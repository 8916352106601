import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  switchToOldMessages: false,
  requestedOldMessageId: null,
  oldMessages: null,
  loadingOldMessages: false,
  error: null,
  coloredMsgId: null,
  isScrolledChatBottom: false,
  messageForwardModalOpen: false,
  singleMessageIdToForward: null,
  isSelectMode: false,
  selectedMessageIds: [],
};

export const getOldMessages = createAsyncThunk('oldMessages/getOldMessages', async ({ chat_id, old_message_id }) => {
  try {
    const response = await axios.get(`/api/load_chat_sided_messages/${chat_id}/${old_message_id}`);
    return {
      oldMessages: response.data.messages.chat_messages,
      requestedOldMessageId: old_message_id,
    };
  } catch (e) {}
});

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setColoredMsgId: (state, action) => {
      state.coloredMsgId = action.payload;
    },
    resetColoredMsgId(state) {
      state.coloredMsgId = null;
    },
    resetOldMessagesState: () => initialState,

    addPreviousOldMessages: (state, action) => {
      if (action.payload?.length) {
        const previousMessages = action.payload.reverse();
        state.oldMessages = [...previousMessages, ...state.oldMessages];
      }
    },
    addNextOldMessages: (state, action) => {
      if (action.payload.messages?.length) {
        state.oldMessages = [...state.oldMessages, ...action.payload.messages];
      }
    },
    updateOldMessage: (state, action) => {
      if (state.oldMessages) {
        const { data, type } = action.payload;
        const foundIndex = state.oldMessages.findIndex((message) => message.id === data.id);
        if (foundIndex !== -1) {
          const updMessages = [...state.oldMessages];
          if (type === 'pinned') {
            updMessages[foundIndex] = { ...updMessages[foundIndex], pinned: 1 };
          } else if (type === 'unpinned') {
            updMessages[foundIndex] = { ...updMessages[foundIndex], pinned: 0 };
          } else if (type === 'deleted') {
            updMessages[foundIndex] = {
              ...updMessages[foundIndex],
              deleted: 1,
            };
          } else {
            updMessages[foundIndex] = {
              ...data,
              message_likes: data.likes,
              message_files: data.files,
              message_tags: data.tags,
              goals: data.chat_goal_results,
            };
          }
          state.oldMessages = updMessages;
        }
      }
    },
    setIsScrolledChatBottom: (state, action) => {
      state.isScrolledChatBottom = action.payload;
    },

    forwardSingleMessage: (state, action) => {
      state.messageForwardModalOpen = true;
      if (state.selectedMessageIds.length) state.selectedMessageIds.push(action.payload);
      else state.singleMessageIdToForward = action.payload;
    },
    clearForwardModal: (state) => {
      state.messageForwardModalOpen = false;
      state.singleMessageIdToForward = null;
    },
    cancelMessageSelection: (state) => {
      state.selectedMessageIds = [];
      state.messageForwardModalOpen = false;
      state.singleMessageIdToForward = null;
      state.isSelectMode = false;
    },
    toggleForwardModal: (state) => {
      state.messageForwardModalOpen = !state.messageForwardModalOpen;
    },
    toggleMessageSelection: (state, action) => {
      const selectedMessageIdIndex = state.selectedMessageIds.findIndex((id) => id === action.payload);
      if (selectedMessageIdIndex === -1) {
        state.selectedMessageIds.push(action.payload);
        state.isSelectMode = true;
      } else {
        state.selectedMessageIds.splice(selectedMessageIdIndex, 1);
        if (!state.selectedMessageIds.length) state.isSelectMode = false;
      }
    },
  },
  extraReducers: {
    [getOldMessages.pending]: (state, action) => {
      state.switchToOldMessages = true;
      state.loadingOldMessages = true;
      state.requestedOldMessageId = action.meta.arg.old_message_id;
    },
    [getOldMessages.fulfilled]: (state, { payload }) => {
      state.loadingOldMessages = false;
      state.error = null;
      state.oldMessages = payload.oldMessages;
    },
    [getOldMessages.rejected]: (state, { payload }) => {
      state.loadingOldMessages = false;
      state.error = payload;
    },
  },
});

export const {
  resetOldMessagesState,
  addPreviousOldMessages,
  addNextOldMessages,
  updateOldMessage,
  setColoredMsgId,
  resetColoredMsgId,
  setIsScrolledChatBottom,
  forwardSingleMessage,
  clearForwardModal,
  cancelMessageSelection,
  toggleMessageSelection,
  toggleForwardModal,
} = messagesSlice.actions;

export default messagesSlice.reducer;
