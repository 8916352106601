import { SingleSelect } from '@shared/components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

const ProjectSelect = ({ control, projects }) => {
  const selectOptions = useMemo(() => {
    if (projects) {
      return [...projects?.map((project) => ({ label: project.title, value: project.id }))];
    }
  }, [projects]);

  return (
    <Controller
      name="project"
      control={control}
      render={({ field }) => (
        <SingleSelect
          {...field}
          ref={null}
          placeholder="Проект"
          options={selectOptions}
          value={selectOptions?.find((o) => o.value === field?.value)}
          onChange={(o) => {
            field.onChange(o?.value);
          }}
          isClearable
        />
      )}
    />
  );
};

export default ProjectSelect;
