import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Label, Modal, SingleSelect } from '@shared/components';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import { Avatar } from '@shared/components/Avatar';
import { CustomOption, CustomValue, getOptions } from 'src/components/form/select/userSelectComponents';
import useAuth from 'src/hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import ConfirmAction from '@components/warnings/ConfirmAction';
import SnackbarContext from 'src/contexts/SnackbarContext';
import Preloader from '@components/preloaders/Preloader';

import { UserContainer } from './styles';

ChangeProjectCreatorModal.propTypes = {
  close: func,
};

function ChangeProjectCreatorModal({ close }) {
  const { projectId, taskId } = useParams();
  const [creator, setCreator] = useState();
  const [isLoading, setIsLoading] = useState();

  const { showSnackbar } = useContext(SnackbarContext);

  const getCreatorData = useCallback(() => {
    let requestUrl;
    setIsLoading(true);
    if (taskId) requestUrl = `/api/task_creator/${taskId}`;
    else if (projectId) requestUrl = `/api/project_creator/${projectId}`;

    axios
      .get(requestUrl)
      .then((res) => {
        if (!Array.isArray(res.data.result) || !res.data.result[0]) {
          showSnackbar('Создатель не найден');
          return;
        }
        setCreator(res.data.result[0]);
      })
      .catch(() => showSnackbar('Возникла ошибка при получении данных о создателе'))
      .finally(() => setIsLoading(false));
  }, [projectId, taskId]);

  useEffect(() => {
    getCreatorData();
  }, [getCreatorData]);

  const auth = useAuth();
  const employees = useSelector((state) => state.users.employees);

  const options = useMemo(() => {
    if (!creator?.id || !employees) return [];
    return getOptions({
      users: employees?.filter((employee) => employee.id !== creator.id),
      token: auth.token,
    });
  }, [employees, creator, auth?.token]);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm();

  const [confirmClose, setConfirmClose] = useState();

  const handleClose = useCallback(() => {
    if (isDirty) {
      setConfirmClose(true);
      return;
    }
    close();
  }, [isDirty]);

  const changeCreator = useCallback(
    (data) => {
      if (!data.newCreator?.value?.id) return;
      let requestUrl, requestBody;
      if (taskId) {
        requestUrl = '/api/change_task_creator';
        requestBody = { task_id: taskId, creator_id: data.newCreator.value.id };
      } else {
        requestUrl = '/api/change_project_creator';
        requestBody = { project_id: projectId, creator_id: data.newCreator.value.id };
      }
      axios
        .patch(requestUrl, requestBody)
        .then(() => {
          showSnackbar('Создатель успешно изменен', 'success');
          close();
        })
        .catch(() => showSnackbar('Возникла ошибка при изменении создателя'));
    },
    [taskId],
  );

  useEventTriggerOnEscPress(handleClose);

  return (
    <Modal
      onClose={handleClose}
      title={`Сменить создателя ${taskId ? 'задачи' : 'проекта'}`}
      disabledSaveButton={!isDirty}
      onSave={handleSubmit(changeCreator)}
    >
      {isLoading && <Preloader />}
      {creator && (
        <>
          <div>
            <Label>{`Текущий создатель ${taskId ? 'задачи' : 'проекта'}`}</Label>
            <UserContainer>
              <Avatar userId={creator.creatorId} />
              <span>{`${creator.creator_last_name || ''} ${creator.creator_first_name}`}</span>
            </UserContainer>
          </div>

          <Controller
            name="newCreator"
            control={control}
            render={({ field }) => (
              <SingleSelect
                {...field}
                ref={null}
                options={options}
                components={{ Option: CustomOption, SingleValue: CustomValue }}
              />
            )}
          />
        </>
      )}

      {confirmClose && (
        <ConfirmAction
          actionText="Уверены, что хотите закрыть окно, не сохранив изменения?"
          cancel={() => setConfirmClose(false)}
          confirm={close}
        />
      )}
    </Modal>
  );
}

export default ChangeProjectCreatorModal;
