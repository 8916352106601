import { yupResolver } from '@hookform/resolvers/yup';
import { Label } from '@shared/components/Label/Label';
import { Modal } from '@shared/components/Modal/Modal';
import { SingleSelect } from '@shared/components/Select/Select';
import { User } from '@shared/components/User/User';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { files_url } from 'src/settings/base-url';
import StyledMockAvatar from '@assets/images/userpic.png';
import * as yup from 'yup';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import { Input } from '@shared/components/Input/Input';
import { useSetSalaryMutation } from 'src/redux/features/api/employees';

export const schema = yup.object({
  employee: yup.object().required(),
  date: yup.date(),
  amount: yup.string().required(),
});

export const formatOptionLabel = ({ pic, name, id }) => {
  return <User id={id} size="sm" picture={pic} name={name}></User>;
};

export const getOptions = (users, token) => {
  const options = users.map((user) => ({
    id: user.id,
    label: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    name: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    pic: !!user.avatar
      ? `${files_url}/employees/avatar/${user.id}/small_${user.avatar}?token=${token}`
      : StyledMockAvatar,
  }));

  return options;
};

export const SalariesModal = ({ onSave, onClose }) => {
  const auth = useAuth();
  const users = useSelector((state) => state.users.employees);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const options = useMemo(() => getOptions(users, auth.token), [users]);

  const [setSolary, { isLoading }] = useSetSalaryMutation();

  const onSubmit = async (data) => {
    await setSolary({
      row: {
        employee_id: data.employee.id,
        salary: data.amount,
        creator_id: auth.user.id,
      },
    });

    onClose();
  };

  return (
    <Modal
      modalSize="767px"
      title="Заработная плата сотрудника"
      onSave={handleSubmit(onSubmit)}
      onClose={onClose}
      confirmButtonText={'Записать'}
    >
      <form>
        <div>
          <Label>Сотрудник</Label>
          <Controller
            name="employee"
            control={control}
            render={({ field }) => (
              <SingleSelect
                {...field}
                options={options}
                formatOptionLabel={formatOptionLabel}
                getOptionValue={(option) => option.id}
              />
            )}
          />
        </div>

        <StyledModalRowEnd>
          <div>
            <Label>Сумма</Label>
            <Controller name="amount" control={control} render={({ field }) => <Input {...field} error={false} />} />
          </div>
        </StyledModalRowEnd>
      </form>
    </Modal>
  );
};
