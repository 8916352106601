import { useCallback, useMemo } from 'react';
import { object, number, shape, func, string } from 'prop-types';

import deleteIcon from '@assets/images/icons/delete-circle.svg';

import { StyledImageContainer, StyledIconButton, StyledImage } from './styles';

PollOptionImage.propTypes = {
  field: shape({
    value: string,
    answer_id: number,
    file: object,
  }),
  index: number,
  getValues: func,
  setValue: func,
  update: func,
};

export function PollOptionImage({ field, update, getValues, setValue, index }) {
  const imageUrl = useMemo(() => {
    if (!field?.file) return;

    if (typeof field.file.src === 'string') return field.file.src;
    else if (field.file instanceof File) {
      return window.URL.createObjectURL(field.file);
    }
  }, [field?.file]);

  const handleImageRemove = useCallback(() => {
    const value = getValues(`poll_answers.${index}`);
    if (field.answer_id) {
      const imageIdsToDelete = getValues('delete_poll_answer_files');
      if (Array.isArray(imageIdsToDelete)) {
        setValue('delete_poll_answer_files', [...imageIdsToDelete, field.answer_id]);
      }
    }
    update(index, { ...value, file: null });
  }, [getValues, field?.answer_id, setValue, index]);

  return (
    <>
      {imageUrl && (
        <StyledImageContainer>
          <StyledIconButton icon={deleteIcon} onClick={handleImageRemove} />
          <StyledImage src={imageUrl} alt="option" />
        </StyledImageContainer>
      )}
    </>
  );
}
