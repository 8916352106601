import iconDoc from '@assets/images/icons/file-other.svg';

export const getFileIcon = (fileName) => {
  // if (fileName.endsWith('xls') || fileName.endsWith('xlsx')) {
  //   return iconExcelFile;
  // }

  // if (fileName.endsWith('doc') || fileName.endsWith('docx')) {
  //   return iconDocxFile;
  // }

  // if (fileName.endsWith('pdf')) {
  //   return iconPdfFile;
  // }

  return iconDoc;
};
