import styled from 'styled-components';

export const StyledCompanyHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  grid-column: 1 / -1;
`;

export const StyledContactsWrapper = styled.div`
  margin: 0 -24px;
  overflow-x: clip;
`;

export const StyledButtonsWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;

  @media (min-width: 992px) {
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
  }
`;

export const StyledCompany = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 24px;
  margin: 0 -24px;
  gap: 24px;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(186, 231, 255, 0.5);
  }

  &:hover ${StyledButtonsWrapper} {
    opacity: 1;
    visibility: visible;
  }
`;

export const StyledCompanyItem = styled.section`
  display: grid;
  gap: 4px;
  grid-template-columns: ${({ columns }) => columns || '1fr'};
  cursor: pointer;
`;

export const StyledKey = styled.p`
  color: ${({ theme }) => theme.dark};
  font-size: 14px;
  line-height: 24px;
`;

export const StyledValue = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.darkest};
`;

export const StyledCompanyWrapper = styled.section`
  transition: all 0.3s;
  display: grid;
  grid-template-columns: ${({ columns }) => columns || '1fr'};
  gap: 16px;
  align-items: start;
  padding: 24px;
  cursor: pointer;

  &:hover ${StyledButtonsWrapper} {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    background-color: rgba(186, 231, 255, 0.5);
  }
`;
