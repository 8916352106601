import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {StyledModalScrollBox, StyledModalScrollItem} from "@shared/components";

import {StyledTag} from "@components/chat/message/styles";

import { getTags } from '../../redux/features/tagsSlice';
import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';

const TagsListModal = ({ close }) => {
  const dispatch = useDispatch();
  const { tagsList } = useSelector((state) => state.tags);

  useEffect(() => {
    if (!tagsList) {
      dispatch(getTags());
    }
  }, [tagsList, dispatch]);

  useEventTriggerOnEscPress(close);

  return (
      <StyledModalScrollBox>
        {tagsList &&
          tagsList.map((tag, i) => {
            return (
              <StyledModalScrollItem key={i}>
                <StyledTag color={tag.color}>{tag.name}</StyledTag>
              </StyledModalScrollItem>
            );
          })}
      </StyledModalScrollBox>
  );
};

export default TagsListModal;
