import React, { useEffect, useContext, useState, useRef } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import SnackbarContext from 'src/contexts/SnackbarContext';
import Preloader from 'src/components/preloaders/Preloader';
import { ProjectCardList } from 'src/pages/Projects/project-list/project-list-container/style';

import TaskListItem from './TaskListItem';

const TaskList = () => {
  const [tasksList, setTasksList] = useState(null);

  const [searchParams] = useSearchParams();

  const { showSnackbar } = useContext(SnackbarContext);

  const memoizedSearchParams = useRef();

  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (memoizedSearchParams.current === searchParams) return;
    setIsLoading(true);
    memoizedSearchParams.current = searchParams;
    const filterObj = {};
    for (const [key, value] of searchParams) {
      switch (key) {
        case 'relation':
          value.split(',').forEach((v) => (filterObj[v] = true));
          break;

        case 'tags':
          filterObj.tags = value.split(',').map((tagId) => +tagId);
          break;

        default:
          break;
      }
    }
    axios
      .patch('/api/task_filter', filterObj)
      // .patch('/api/task_filter', { responsible: true, executor: true, creator: true, member: true })
      .then((res) => {
        const myTasksArr = [...res.data.projects.projects, ...res.data.tasks.tasks].sort((a, b) => {
          return new Date(a.date_created) < new Date(b.date_created);
        });
        setTasksList(myTasksArr);
      })
      .catch(() => showSnackbar('Возникла ошибка при получении списка задач'))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading && <Preloader />}

      {!isLoading && tasksList && (
        <ProjectCardList>
          {tasksList.map((el, i) => (
            <TaskListItem task={el} key={`${el.id}_${i}`} />
          ))}
        </ProjectCardList>
      )}
    </>
  );
};

export default TaskList;
