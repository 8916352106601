import styled from 'styled-components';
import { Container } from '@shared/components';

export const StyledButtonPlus = styled.button`
  height: 20px;
  padding: 0;
  position: relative;
  width: 20px;
  background-color: ${({ theme }) => theme.success};
  border: 1px solid ${({ theme }) => theme.success};
  color: #fff;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #49ad17;
  }

  &::after {
    content: '';
    height: 16px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    background-image: url(${(props) => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const Wrapper = styled(Container)`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;

  ::placeholder {
    font-size: 14px;
  }
`;
