import { styled } from 'styled-components';
import { Text } from '@shared/components/Text/Text';
import { Link } from 'react-router-dom';

export const StyledButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  padding: 15px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;

export const StyledNotify = styled.span`
  position: absolute;
  top: 15px;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.error};
  border: 1px solid ${({ theme }) => theme.lightest};
`;

export const StyledTitle = styled.p`
  font-size: 14px;
  line-height: 18px;
  transition: color 0.2s ease-in-out;
`;

export const StyledNotificationItem = styled(Link)`
  padding: 15px;

  &:hover ${StyledTitle} {
    color: ${({ theme }) => theme.main};
  }
`;

export const StyledType = styled(Text)`
  font-size: 12px;
  font-weight: 600;
`;
