import {useState} from "react";
import {StyledLayout} from '@shared/components/Layout/styles';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import ContactDetailsModalContainer from 'src/windows/contacts/ContactDetailsModalContainer';

import {useLazyGetContactsQuery} from 'src/redux/features/api/contacts';

import Preloader from '@components/preloaders/Preloader';

import Pagination from '@shared/components/Pagination';

import ContactsPageHeader from './ContactsPageHeader';
import {ContactsListV2} from './ContactsListV2';
import {ContactFilters} from './ContactFilters';

export const Contacts = (hasRightToEdit) => {
  const [fetchContactsData, {data, isFetching}] = useLazyGetContactsQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const {contactId} = useParams();
  const navigate = useNavigate();

  const queryParams = Object.fromEntries([...searchParams]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  return (
    <>
      <StyledLayout>
        <ContactsPageHeader hasRightToEdit={hasRightToEdit} setIsFiltersOpen={setIsFiltersOpen}/>
        <ContactFilters
          fetchContactsData={fetchContactsData}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          isFiltersOpen={isFiltersOpen}
        />

        {isFetching ? <Preloader/> : <ContactsListV2 partners={data?.partners}/>}

        <Pagination
          id={queryParams?.filter}
          totalPages={data?.page_count}
          onChange={(page) => setSearchParams({...queryParams, page})}
          defaultValue={queryParams?.page}
        />
      </StyledLayout>

      {contactId && (
        <ContactDetailsModalContainer contactId={contactId} onClose={() => navigate(`/contacts?${searchParams}`)}/>
      )}
    </>
  );
};
