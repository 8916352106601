import { StyledDragInput, StyledDragLabel, StyledDragnDrop, StyledDragText } from '@shared/components/DragnDrop/styles';

export const DragnDrop = ({ setUploadingFiles }) => {
  return (
    <StyledDragnDrop>
      <StyledDragLabel htmlFor="upload"></StyledDragLabel>
      <StyledDragInput
        type="file"
        id="upload"
        multiple="multiple"
        onChange={(e) => {
          if (e.target.files?.length) {
            setUploadingFiles([...e.target.files]);
          }
        }}
      />
      <StyledDragText>
        Перетяните файлы
        <br />
        или кликните, чтобы выбрать
      </StyledDragText>
    </StyledDragnDrop>
  );
};
