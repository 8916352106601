import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';

function PrivateRoute({ children, guestAccess }) {
  let location = useLocation();
  const auth = useAuth();
  // если юзер не авторизован или будучи гостем, пытается зайти в приватный роут, то переадресовать в роут логина
  return auth?.user && (!auth.user.is_guest || guestAccess) ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}

export default PrivateRoute;
