import { useState } from 'react';
import { useSocketContext } from 'src/contexts/SocketContext';
import ConfirmAction from '@components/warnings/ConfirmAction';
import iconPin from '@assets/images/icons/pin.svg';
import { StyledMessagePin } from '@components/chat/message/styles';

const MessagePinBtn = ({ pinned, messageId }) => {
  const { manageMessagePin } = useSocketContext();

  const [confirmUnpin, setConfirmUnpin] = useState();

  const handlePinClick = () => {
    if (pinned) {
      setConfirmUnpin(true);
      return;
    }
    manageMessagePin(pinned, messageId);
  };

  const handlePinConfirm = () => {
    manageMessagePin(pinned, messageId);
    setConfirmUnpin(false);
  }

  return (
    <>
      <StyledMessagePin $active={pinned} icon={iconPin} size={18} onClick={handlePinClick}></StyledMessagePin>

      {confirmUnpin && (
          <ConfirmAction
            actionText="Вы действительно хотите открепить сообщение?"
            confirm={handlePinConfirm}
            cancel={() => setConfirmUnpin(false)}
            confirmButtonText="Да"
            cancelButtonText="Нет"
          />
      )}
    </>
  );
};

export default MessagePinBtn;
