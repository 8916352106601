import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledChatMessage } from '@components/chat/message/styles';

import Preloader from '../preloaders/Preloader';

import MessageContainer from './message/MessageContainer';

const OldMessagesViewer = ({
  projectData,
  requestedOldMessageId,
  oldMessages,
  loadingOldMessages,
  messagesLoading,
  nextMessagesLoading,
}) => {
  const alreadyFocused = useRef();
  const { t } = useTranslation();

  // прокрутить к запрошенному старому сообщению
  useEffect(() => {
    if (requestedOldMessageId && oldMessages?.length) {
      const requestedOldMessage = document.getElementById(`messageId${requestedOldMessageId}`);
      if (requestedOldMessage && alreadyFocused.current !== requestedOldMessageId) {
        setTimeout(() => {
          requestedOldMessage.scrollIntoView({
            // behavior: "smooth",
            block: 'center',
            inline: 'start',
          });
          alreadyFocused.current = requestedOldMessageId;
        }, 100);
      }
    }
  }, [requestedOldMessageId, oldMessages]);

  return (
    <>
      {(loadingOldMessages || messagesLoading === 'previous') && <Preloader />}

      {messagesLoading === 'endPrevious' && <StyledChatMessage>{t('Chat.no_more')}</StyledChatMessage>}

      {oldMessages?.map((message) => {
        return (
          <MessageContainer
            key={message.id}
            {...message}
            goals={message.chat_goal_results}
            likes={message.message_likes}
            projectData={projectData}
            tags={message.message_tags}
          />
        );
      })}

      {nextMessagesLoading === 'loading' && <Preloader />}
    </>
  );
};

export default OldMessagesViewer;
