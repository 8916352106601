import styled, {css} from "styled-components";

export const Dropdown = styled.section`
  min-width: 250px;
  width: ${props => props.width || '100%'};
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;

  ${props => props.$show && css`
    ${StyledDropdownInput} {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      
      &::after {
        transform: rotate(-135deg);
      }
    }
    
    ${StyledSelect} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      max-height: 270px;
      opacity: 1;
      overflow-y: auto;
      transition: all 0.2s ease-in-out;
    }
  `}
`

export const StyledDropdownInput = styled.div`
  border: 1px solid ${({theme}) => theme.border};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  min-height: 42px;
  padding: 8px 28px 8px 8px;
  position: relative;
  width: 100%;
  
  &::after {
    border-bottom: 1px solid ${({theme}) => theme.disabled};
    border-right: 1px solid ${({theme}) => theme.disabled};
    content: '';
    height: 6px;
    pointer-events: none;
    position: absolute;
    right: 13px;
    top: 45%;
    transform: translateY(-50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
    width: 6px;
  }
`

export const StyledSelect = styled.ul`
  position: ${props => props.position || 'relative'};
  background-color: ${({theme}) => theme.lightest};
  border: 1px solid ${({theme}) => theme.border};
  border-radius: 2px;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  top: 100%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
`

export const StyledSelectItem = styled.li`
  display: flex;
  padding: 8px;
  transition: all 0.2s;
  
  &:hover {
    background-color: ${({theme}) => theme.mainLight};
  }
`