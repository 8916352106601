import { useEffect, useState, useContext } from 'react';
// import axios from 'axios';
import SnackbarContext from 'src/contexts/SnackbarContext';
// import Preloader from '@components/preloaders/Preloader';
import ThreeDotsLoader from '@components/preloaders/ThreeDotsLoader';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFavorites } from 'src/redux/features/favoritesSlice';
import iconStar from '@assets/images/icons/star.svg';
import { IconButton, Separator } from '@shared/components';
import { SiteInnerLink, StyledUserNavInnerList, StyledUserNavItem } from '@components/nav/styles';

const Starred = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { favorites, isLoading } = useSelector((state) => state.favorites);

  const { showSnackbar } = useContext(SnackbarContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen || favorites) return;
    dispatch(getFavorites({ showSnackbar }));
  }, [isOpen]);

  return (
    <StyledUserNavItem>
      <IconButton size={17} icon={iconStar} onClick={() => setIsOpen((prev) => !prev)} />

      <StyledUserNavInnerList $active={isOpen}>
        <SiteInnerLink to="/favorites" as={Link} onClick={() => setIsOpen(false)}>
          Перейти ко всем избранным
        </SiteInnerLink>
        {Boolean(favorites?.length) && <Separator />}

        {isLoading && (
          <SiteInnerLink>
            <ThreeDotsLoader visible />
          </SiteInnerLink>
        )}

        {favorites?.map((favored, i) => {
          let url = '';
          let title = '';
          if (favored.project_id) {
            url = `/projects/${favored.project_id}`;
            title = favored.project;
          } else if (favored.task_id) {
            url = `/projects/${favored.root_project}/tasks/${favored.task_id}`;
            title = favored.task;
          }

          return (
            <SiteInnerLink key={i} to={url} as={Link} onClick={() => setIsOpen(false)}>
              {title}
            </SiteInnerLink>
          );
        })}
      </StyledUserNavInnerList>
    </StyledUserNavItem>
  );
};
export default Starred;
