import { useState } from 'react';
import { oneOfType, string, number, func } from 'prop-types';
import { useContactDetailsQuery } from 'src/redux/features/api/contacts';
import CreateEditContacts from 'src/pages/Contacts/create-edit/CreateEditContacts';

import NotFoundModal from '../NotFoundModal';

import ContactModal from './ContactDetailsModal';

ContactModalContainer.propTypes = {
  contactId: oneOfType([string, number]),
  onClose: func,
};

export default function ContactModalContainer({ contactId, onClose }) {
  const { data: contactDetails, isFetching, isSuccess } = useContactDetailsQuery({ contactId });

  const [modalEdit, setModalEdit] = useState(false);

  if (isSuccess && Array.isArray(contactDetails) && !contactDetails.length) {
    return <NotFoundModal onClose={onClose} />;
  }
  return (
    <>
      {contactId && !modalEdit && (
        <ContactModal
          contactId={contactId}
          onClose={onClose}
          isLoading={isFetching}
          contactDetails={contactDetails}
          setModalEdit={setModalEdit}
        />
      )}

      {modalEdit && contactDetails && (
        <CreateEditContacts companyToEdit={contactDetails} close={() => setModalEdit(false)} />
      )}
    </>
  );
}
