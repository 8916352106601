import axios from 'axios';
import moment from 'moment';
import { randomSequence } from 'src/utilize/helper-functions';

export const handlePollEdit = async ({ formValues, pollId, editPoll, showSnackbar }) => {
  if (!pollId || !formValues || !editPoll || !showSnackbar) return;
  let hasImagesToUpload = false;
  const poll_answers = formValues.poll_answers.map((answer) => {
    if (answer.file instanceof File) {
      answer.div_id = randomSequence();
      hasImagesToUpload = true;
    }
    return answer;
  });

  const answersToAdd = poll_answers
    .filter((item) => !item.answer_id)
    .map((item) => {
      const pollOption = { answer: item.value };
      if (item.div_id) pollOption.div_id = item.div_id;
      return pollOption;
    });

  const answersToEdit = poll_answers
    .filter((answer) => answer.answer_id)
    .map((item) => {
      const pollOption = { answer: item.value, answer_id: item.answer_id };
      if (item.div_id) pollOption.div_id = item.div_id;
      return pollOption;
    });

  // return;
  if (hasImagesToUpload) {
    const promises = [];
    poll_answers.forEach((answer) => {
      if (answer.file && answer.div_id) {
        const formData = new FormData();

        formData.append('file', answer.file, answer.file.name);
        formData.append('div_id', answer.div_id);
        promises.push(
          axios.post('/api/poll_answer_file/add', formData).catch(() => showSnackbar('Не удалось отправить картинки')),
        );
      }
    });

    await Promise.all(promises);
  }

  const data = {
    poll_id: pollId,
    row: {
      title: formValues.title,
      anonymous: formValues.anonymous,
      multianswer: formValues.multianswer.value,
      date_finish: formValues.date_finish ? moment.utc(formValues.date_finish).format('YYYY-MM-DD-LT') : null,
    },
    add_poll_answers: answersToAdd,
    edit_poll_answers: answersToEdit,
    poll_members: formValues.poll_members?.map((item) => item.value.id),
    delete_poll_answers: formValues.delete_poll_answers,
    delete_poll_answer_files: formValues.delete_poll_answer_files,
  };
  return new Promise((resolve, reject) => {
    editPoll(data, (response) => {
      if (response?.status === 'ok') {
        resolve();
      } else if (response?.error === 'this element is already in use') {
        reject(new Error('Не удалось обновить: данный элемент уже используется'));
      } else {
        reject(new Error('Не удалось отредактировать голосование'));
      }
    });
  });
};
