import styled from 'styled-components';

export const StyledLayout = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 24px;
  padding-top: 52px;

  @media (max-width: 550px) {
    padding: 52px 12px 0;
  }
`;
