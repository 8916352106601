import { func, number } from 'prop-types';

import { StyledChatMenuItem } from '@components/chat/styles';
import { useDispatch } from 'react-redux';
import { forwardSingleMessage } from 'src/redux/features/messagesSlice';

ForwardMessageMenuItem.propTypes = {
  toggleDotsMenu: func,
  messageId: number,
};

function ForwardMessageMenuItem({ toggleDotsMenu, messageId }) {
  const dispatch = useDispatch();

  return (
    <StyledChatMenuItem
      onClick={() => {
        dispatch(forwardSingleMessage(messageId));
        toggleDotsMenu(false);
      }}
    >
      Переслать
    </StyledChatMenuItem>
  );
}

export default ForwardMessageMenuItem;
