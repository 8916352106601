import { StyledIconButton } from './style';

export const IconButton = ({ onClick, children, isDisabled, $ref, ...props }) => {
  const onClickHandler = (e) => {
    onClick && onClick(e);
  };

  return (
    <StyledIconButton ref={$ref} {...props} disabled={isDisabled} type="button" onClick={onClickHandler}>
      {children}
    </StyledIconButton>
  );
};
