import { rtkQueryApi } from './rtkQueryApi';

export const contactsApi = rtkQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: () => '/api/company_groups',
      providesTags: ['ContactGroups'],
    }),
    getContactCreateData: builder.query({
      query: () => '/api/partner_create',
      providesTags: ['ContactCreateData'],
    }),
    getContactsFilterData: builder.query({
      query: () => '/api/for_partners_filter',
      providesTags: ['Contacts'],
    }),
    getContacts: builder.query({
      query: (body) => ({ url: '/api/partners_filter', method: 'PATCH', body }),
      providesTags: ['Contacts'],
    }),
    contactDetails: builder.query({
      query: ({ contactId }) => `/api/partner_by_id/${contactId}`,
      providesTags: ['SingleContact'],
    }),
    createGroup: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/company_groups/add',
        method: 'POST',
      }),
      invalidatesTags: ['ContactGroups'],
    }),
    editGroup: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/company_groups/edit',
        method: 'PUT',
      }),
      invalidatesTags: ['ContactGroups', 'Contacts'],
    }),
    createCompanyContact: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/partners/add',
        method: 'POST',
      }),
      invalidatesTags: ['Contacts'],
    }),
    editCompanyContact: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/partners/edit',
        method: 'PUT',
      }),
      invalidatesTags: ['Contacts', 'SingleContact'],
    }),
    deleteCompanyContact: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/partners/delete',
        method: 'DELETE',
      }),
      invalidatesTags: ['Contacts', 'SingleContact'],
    }),
    deleteCompanyGroup: builder.mutation({
      query: (body) => ({
        body,
        url: '/api/company_groups/delete',
        method: 'DELETE',
      }),
      invalidatesTags: ['ContactGroups', 'Contacts'],
    }),
  }),
  tagTypes: ['Contacts', 'ContactGroups'],
});

export const {
  useCreateGroupMutation,
  useEditGroupMutation,
  useGetContactCreateDataQuery,
  useGetGroupsQuery,
  useGetContactsFilterDataQuery,
  useGetContactsQuery,
  useContactDetailsQuery,
  useCreateCompanyContactMutation,
  useEditCompanyContactMutation,
  useLazyGetContactsQuery,
  useDeleteCompanyContactMutation,
  useDeleteCompanyGroupMutation,
} = contactsApi;
