import React, { useState } from 'react';

import { Container, Icon, IconButton, Text } from '@shared/components';

import IconFile from '@assets/images/icons/icon-file.svg';

import { convertBytes, doubleClickHandle, isFileNameImage, isPreviewFile } from 'src/utilize/helper-functions';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';

import IconDots from '@assets/images/icons/subtask-dots.svg';

import { Dropdown } from '@shared/components/Dropdown/Dropdown';

import { useDownloader } from '@shared/hooks/useDownloader';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { useNavigate } from 'react-router-dom';

import { PreviewModal } from '@shared/widgets/PreviewModal';
import { downloadFileUrl } from 'src/settings/base-url';
import useAuth from 'src/hooks/useAuth';

import FileVersionsModal from '@components/storage/FileVersionsModal';

import useRenderUserName from '@components/storage/useRenderUserName';

import UserProfileModalContainer from 'src/windows/profile/UserProfileModalContainer';

import { StyledLink, StyledListItem } from '../../styles';

import { StyledMenu, StyledMenuItem, StyledRow, StyledText, StyledTextWrapper } from './styles';

export const CardFileFromChat = ({ index, file, searchType, onClose }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [fileVerisonsModal, setFileVersionsModal] = useState(false);
  const [userProfileId, setUserProfileId] = useState();

  const renderUserName = useRenderUserName(setUserProfileId);

  const navigate = useNavigate();

  const { downloadFile } = useDownloader();

  const auth = useAuth();

  const filePath = `/chat_messages/files/${file.chat_message_id}/${file.id}/${file.file}`;

  const url = `${downloadFileUrl}${encodeURIComponent(filePath)}?token=${auth.token}`;

  const getFilePath = () => {
    if (file.location === 'project') {
      return `/projects/${file.project_id}?msg=${file.chat_message_id}`;
    }

    if (file.location === 'task') {
      return `/projects/${file.project_id}/tasks/${file.task_id}?msg=${file.chat_message_id}`;
    }

    if (file.location === 'survey') {
      return;
    }

    if (file.location === 'news') {
      return `/news/${file.news_id}`;
    }

    if (file.location === 'blog') {
      return `/blogs/${file.blog_id}`;
    }
  };

  const onDownloadHandler = () => {
    downloadFile({ url: filePath, filename: file.file }).then((res) => {
      if (res.fileDetails.isLarge) {
        setConfirmModal(true);
      }
    });
  };

  const onConfirm = () => {
    downloadFile({ url: filePath, filename: file.file, forceDownload: true });
    setConfirmModal(false);
  };

  const onClickHandler = () => {
    doubleClickHandle(
      () => downloadFile({ url: filePath, filename: file.file, forceDownload: true }),
      () => {
        let path = getFilePath();
        navigate(path);
        onClose();
      },
    );
  };

  return (
    <>
      <StyledListItem key={index}>
        <Container gap={'12px'}>
          <div>{index + 1}.</div>
          <Container direction={'column'} gap={'8px'} width={'100%'}>
            {(file?.project_title || file?.root_project_title || file?.task_title) && (
              <Container align={'center'} gap={'36px'}>
                {file?.project_title && (
                  <StyledTextWrapper maxWidth={'45%'}>
                    <Text size={3}>Проект: {file?.project_title || file?.root_project_title}</Text>
                  </StyledTextWrapper>
                )}{' '}
                {file?.task_title && (
                  <StyledTextWrapper maxWidth={'45%'}>
                    <Text size={3}>Задача: {file?.task_title}</Text>
                  </StyledTextWrapper>
                )}
              </Container>
            )}

            {file?.survey_title && <Text size={3}>Опрос: {file?.survey_title}</Text>}
            {file?.news_title && <Text size={3}>Новость: {file?.news_title}</Text>}
            {file?.blog_title && <Text size={3}>Блог: {file?.blog_title}</Text>}

            <StyledRow>
              <StyledLink target="_blank" rel="noreferrer">
                <Container
                  onClick={(e) => {
                    e.preventDefault();
                    onClickHandler();
                  }}
                  align={'center'}
                  gap={'10px'}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon icon={IconFile} size={20} />
                  <StyledTextWrapper maxWidth={'90%'}>
                    <Text>{file?.file}</Text>
                  </StyledTextWrapper>
                </Container>
              </StyledLink>

              <StyledText>{convertBytes(file?.size)}</StyledText>
              <StyledText>{formatDateWithDots(file?.data_update)}</StyledText>
              <StyledText>
                {file?.creator_first_name} {file?.creator_last_name}
              </StyledText>
              <Dropdown
                overlay={
                  <StyledMenu>
                    {/*{(isFileNameImage(file?.file) || isPreviewFile(file?.file)) && (*/}
                    {/*  <StyledMenuItem onClick={() => setPreviewModalVisible(true)}>Просмотр</StyledMenuItem>*/}
                    {/*)}*/}
                    <StyledMenuItem
                      onClick={() => {
                        const path = getFilePath();
                        navigate(path);
                        onClose();
                      }}
                    >
                      Перейти к сообщению
                    </StyledMenuItem>
                    <StyledMenuItem onClick={onDownloadHandler}>Скачать</StyledMenuItem>
                  </StyledMenu>
                }
              >
                <IconButton icon={IconDots} />
              </Dropdown>
            </StyledRow>
          </Container>
        </Container>
      </StyledListItem>

      {fileVerisonsModal && (
        <FileVersionsModal
          close={() => setFileVersionsModal(false)}
          currentFileData={file}
          renderUserName={renderUserName}
        />
      )}

      {/*временно убрали этот функционал*/}
      {/*{previewModalVisible && (*/}
      {/*  <PreviewModal*/}
      {/*    url={url}*/}
      {/*    filePath={filePath}*/}
      {/*    fileName={file?.file}*/}
      {/*    onClose={() => setPreviewModalVisible(false)}*/}
      {/*  />*/}
      {/*)}*/}

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onConfirm}
          actionText={`Вы уверены, что хотите скачать файл? Он весит ${convertBytes(file?.size)}`}
          confirmButtonText={'Да'}
          cancelButtonText={'Нет'}
          isSuccess
        />
      )}

      {userProfileId && <UserProfileModalContainer profileId={userProfileId} onClose={() => setUserProfileId(null)} />}
    </>
  );
};
