import { bool, arrayOf, object } from 'prop-types';

import StructureItem from './StructureItem';
import { List } from './styles';

Sublist.propTypes = {
  hidden: bool,
  structureItemsArray: arrayOf(object),
  structureItemPathArray: arrayOf(object),
};

function Sublist({ hidden, structureItemsArray, structureItemPathArray }) {
  return (
    <List hidden={hidden}>
      {structureItemsArray?.map((structureItem) => (
        <StructureItem
          key={structureItem.id}
          structureItem={structureItem}
          structureItemPath={structureItemPathArray}
        />
      ))}
    </List>
  );
}

export default Sublist;
