import { Button } from '@shared/components';
import { useNotificationSubscriptionMutation } from 'src/redux/features/api/notifications';

const ManageSubscription = ({ subscription }) => {
  const [trigger, { isLoading }] = useNotificationSubscriptionMutation();

  return (
    <Button
      color={subscription.subscribed === 1 ? 'red' : ''}
      onClick={() =>
        trigger({
          data_type: subscription.data_type,
          data_id: subscription.data_id,
          subscription_status: subscription.subscribed,
          subscription_id: subscription.id,
        })
      }
      disabled={isLoading}
    >
      {subscription.subscribed === 1 ? 'Отписаться' : 'Подписаться'}
    </Button>
  );
};

export default ManageSubscription;
