import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@shared/components/Avatar';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';
import Tag from '@shared/components/Tag/Tag';


import {
  ProjectCard,
  ProjectCardDate,
  ProjectCardDescription,
  ProjectCardHeader,
  ProjectCardLink,
  ProjectCardStatus,
  ProjectCardTags,
  ProjectCardTitle,
  ProjectCardUser,
  ProjectCardUsername,
} from './style';

const projectStatus = {
  created: { text: 'Создан', color: 'grey' },
  in_progress: { text: 'В работе', color: 'yellow' },
  finished: { text: 'Завершен', color: 'green' },
};

const ProjectListItem = ({ item }) => {
  const {
    id,
    project_goals,
    date_start,
    date_finish,
    description,
    status,
    title,
    project_tags,
    responsible_id,
    responsible_last_name,
    responsible_first_name,
  } = item;

  const { t } = useTranslation();
  return (
    <ProjectCard>
      <ProjectCardHeader>
        <ProjectCardDate>
          {(date_start || date_finish) && (
            <>
              {date_start && formatDateWithDots(date_start)} - {date_finish && formatDateWithDots(date_finish)}
            </>
          )}
        </ProjectCardDate>

        {projectStatus[status] && (
          <ProjectCardStatus color={projectStatus[status].color}>
            {projectStatus[status].text}
            {/*<ProjectIconNotify icon={iconNotify} />*/}
          </ProjectCardStatus>
        )}
      </ProjectCardHeader>
      <ProjectCardTitle>{title}</ProjectCardTitle>
      {/* format--description - класс для того, чтобы было видно форматирование через тэги  */}
      {description && (
        <ProjectCardDescription>{typeof myVar === 'string' ? HTMLReactParser(description) : ''}</ProjectCardDescription>
      )}
      <span>{t('Project.responsible')}</span>
      <ProjectCardUser>
        <Avatar userId={responsible_id} firstName={responsible_first_name} lastName={responsible_last_name} />
        <ProjectCardUsername>{`${responsible_first_name || ''} ${responsible_last_name || ''}`}</ProjectCardUsername>
      </ProjectCardUser>
      <ProjectCardTags>
        {project_tags?.map((item, i) => (
          <Tag key={i} tag={item} />
        ))}
      </ProjectCardTags>
      <ProjectCardLink to={`/projects/${id}`} />
    </ProjectCard>
  );
};

export default ProjectListItem;
