import { Container } from '@shared/components/Container';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  justify-content: space-between;
  display: ${({ $hide }) => ($hide ? 'none' : 'flex')};
  flex-wrap: wrap;
  gap: 20px;
`;

export const Wrapper = styled(Container)`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;

  ::placeholder {
    font-size: 14px;
  }
`;
