import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  StyledAsideBackwardButton,
  StyledAsideBody,
  StyledAsideHeader,
  StyledAsideLink,
  StyledAsideParentTask,
  StyledAsideTitle,
} from '@components/chat/side-menu/styles';
import iconResults from '@assets/images/icons/icon-goals.svg';
import iconClose from '@assets/images/icons/close.svg';
import { IconButton } from '@shared/components';
import {
  StyledResultItem,
  StyledResultList,
  StyledResultTitle
} from "@components/chat/side-menu/sideMenuComponents/styles";

import Preloader from '../../preloaders/Preloader';
import UserProfileModalContainer from '../../../windows/profile/UserProfileModalContainer';

import ProjectTree from './ProjectTree';
import { renderProjectOrTaskName } from './SideMenu';
import ResultMessageAside from './sideMenuComponents/ResultMessageAside';

const ResultsSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();

  const [dataType, setDataType] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [userProfile, showUserProfile] = useState();
  const [projectTree, showProjectTree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  // каждый раз, когда нажимается кнопка открытия панели, производится reset данных
  useEffect(() => {
    if (sideMenu === 'results' && !panelData && dataType && projectData && !isLoading) {
      setIsLoading(true);
      axios
        .get(`/api/sidebar_goals/${dataType}/${projectData.id}`)
        .then((response) => {
          const chat_messages = response.data.chat_messages;
          const data_goals = response.data.data_goals;

          setPanelData({
            goals: data_goals,
            messages: chat_messages,
            id: projectData.id,
            title: projectData.title,
          });

          setIsLoading(false);
          setErrorLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setErrorLoading(true);
        });
    } else if (sideMenu !== 'results' && panelData) {
      setTimeout(() => {
        setPanelData(null);
        setDataType(null);
        showProjectTree(false);
        setSidebarDataType(null);
      }, 1000);
    }
  }, [sideMenu, dataType, projectData, panelData, isLoading]);

  useEffect(() => {
    if (taskId && dataType !== 'task') setDataType('task');
    if (!taskId && dataType !== 'project') setDataType('project');
  }, [taskId, dataType]);

  const [sidebarDataType, setSidebarDataType] = useState();

  const showRelevantTaskInfo = useCallback((dataType, dataId, title) => {
    setPanelData(null);
    showProjectTree(false);
    setErrorLoading(false);
    setIsLoading(true);
    axios
      .get(`/api/sidebar_goals/${dataType}/${dataId}`)
      .then((response) => {
        const chat_messages = response.data.chat_messages;
        const data_goals = response.data.data_goals;

        setPanelData({
          goals: data_goals,
          messages: chat_messages,
          id: dataId,
          title: title,
        });

        setIsLoading(false);
        setSidebarDataType(dataType);
        setErrorLoading(false);
      })
      .catch(() => {
        setPanelData({});
        setIsLoading(false);
        setErrorLoading(true);
      });
  }, []);

  return (
    <>
      {userProfile && <UserProfileModalContainer profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <>
        <StyledAsideHeader>
          <StyledAsideTitle icon={iconResults} iconsize={16}>
            {t('common.results')}
          </StyledAsideTitle>
          <IconButton
            icon={iconClose}
            size={27}
            onClick={() => {
              setTimeout(() => {
                setPanelData(null);
                setDataType(null);
              }, 1000);
              openSideMenu(null);
            }}
          />
        </StyledAsideHeader>
        <StyledAsideBody>
          {isLoading && <Preloader />}
          {errorLoading && <div>{t('ErrorMessages.default')}</div>}
          {panelData && (
            <>
              {projectTree ? (
                <ProjectTree
                  update={sideMenu === 'results'}
                  showRelevantTaskInfo={showRelevantTaskInfo}
                  showProjectTree={showProjectTree}
                />
              ) : (
                <>
                  <StyledAsideBackwardButton onClick={() => showProjectTree(true)}>
                    Структура проекта
                  </StyledAsideBackwardButton>

                  <StyledAsideParentTask>
                    {renderProjectOrTaskName(panelData, taskId, sidebarDataType, projectData)}
                  </StyledAsideParentTask>

                  {sidebarDataType === 'project' && taskId && (
                    <StyledAsideLink to={`/projects/${projectId}`}>{t('Project.open_project')}</StyledAsideLink>
                  )}

                  {sidebarDataType === 'task' && panelData && panelData.id !== +taskId && (
                    <StyledAsideLink to={`/projects/${projectId}/tasks/${panelData.id}`}>
                      {t('Project.open_task')}
                    </StyledAsideLink>
                  )}

                  <StyledResultList>
                    {Array.isArray(panelData.messages) &&
                      panelData.goals.map((goal, i) => {
                        const messagesForThisGoal = panelData.messages.filter((message) => {
                          const result = message.chat_goal_results && message.chat_goal_results[0];
                          if (!result) return false;
                          return goal.id === result.project_goal_id || goal.id === result.task_goal_id;
                        });

                        return (
                          <StyledResultItem key={i}>
                            <StyledResultTitle>{goal.description}</StyledResultTitle>
                            {messagesForThisGoal.map((chat_message, i) => (
                              <ResultMessageAside
                                chat_message={chat_message}
                                key={i}
                                showUserProfile={showUserProfile}
                                dataId={panelData?.id}
                                dataType={sidebarDataType || dataType}
                              />
                            ))}
                          </StyledResultItem>
                        );
                      })}
                  </StyledResultList>
                </>
              )}
            </>
          )}
        </StyledAsideBody>
      </>
    </>
  );
};

export default ResultsSidePanel;
