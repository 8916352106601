import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Controller } from 'react-hook-form';

import SnackbarContext from 'src/contexts/SnackbarContext';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';
import {Label, Search} from "@shared/components";
import {
  Dropdown,
  ProjectSelectHeader,
  ProjectSelectItem,
  ProjectStatus,
  StyledDropdownInput,
  StyledSelect
} from "@components/storage/styles";
import {FilterButton, StyledList} from "@shared/components/Tabs/styles";

const ProjectAttachedStorage = ({ control, selectedProjectTitle }) => {
  const [projects, setProjects] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [projectStatus, setProjectStatus] = useState('all');
  const [searchString, setSearchString] = useState('');

  const { showSnackbar } = useContext(SnackbarContext);

  // получить список проектов, доступных для создания хранилища
  useEffect(() => {
    axios
      .get('/api/storage_create')
      .then((r) => setProjects(r.data.result.projects))
      .catch(() => {
        showSnackbar('Возникла ошибка при получении данных ');
      });
  }, []);

  const renderSelectedProject = (selectedProjectId) => {
    const selectedProject = projects.find((p) => p.id === selectedProjectId);
    if (selectedProject) return selectedProject.title;
    else if (selectedProjectTitle) return selectedProjectTitle;
    return '';
  };

  return (
    <>
      <Label>Привязать к проекту</Label>

      <Controller
        control={control}
        name="project_id"
        render={({ field: { value, onChange } }) => {
          return (
            <Dropdown $show={showDropdown}>
              <StyledDropdownInput onClick={() => setShowDropdown(!showDropdown)}>
                {renderSelectedProject(value)}
              </StyledDropdownInput>
              <StyledSelect>
                <ProjectSelectHeader>
                  <StyledList as="ul">
                    <FilterButton
                      active={projectStatus === 'all'}
                      onClick={() => setProjectStatus('all')}
                    >
                      Все
                    </FilterButton>
                    <FilterButton
                      active={projectStatus === 'actual'}
                      onClick={() => setProjectStatus('actual')}
                    >
                      Актуальные
                    </FilterButton>
                    <FilterButton
                      active={projectStatus === 'completed'}
                      onClick={() => setProjectStatus('completed')}
                    >
                      Завершенные
                    </FilterButton>
                  </StyledList>
                  <Search size="sm" value={searchString} onChange={(e) => setSearchString(e.target.value)}/>
                </ProjectSelectHeader>

                <ul>
                  {projects
                    .filter((p) => {
                      if (projectStatus === 'actual' && p.status === 'finished') return false;
                      if (projectStatus === 'completed' && p.status !== 'finished') return false;
                      if (searchString && !p.title.includes(searchString)) return false;
                      return true;
                    })
                    .map((p, i) => (
                      <ProjectSelectItem
                        key={i}
                        onClick={() => {
                          onChange(p.id);
                          setShowDropdown(false);
                        }}
                      >
                        <b>{p.title}</b>
                        <ProjectStatus color={p.status === 'finished' ? '' : 'green'}>
                          {p.status === 'finished' ? 'Завершенный' : 'Актуальный'}
                        </ProjectStatus>

                        {(p.date_real_start || p.date_real_finish) && (
                          <span>
                            {formatDateWithDots(p.date_real_start)} -&nbsp;
                            {formatDateWithDots(p.date_real_finish)}
                          </span>
                        )}
                      </ProjectSelectItem>
                    ))}
                </ul>
              </StyledSelect>
            </Dropdown>
          );
        }}
      />
    </>
  );
};

export default ProjectAttachedStorage;
