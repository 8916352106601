import { useState } from 'react';
import { func } from 'prop-types';

import ChangeProjectCreatorModal from 'src/windows/projects/change-project-creator/ChangeProjectCreatorModal';

import { StyledChatMenuItem } from '../styles';

MenuItemChangeCreator.propTypes = {
  toggleOptionsMenu: func,
};

function MenuItemChangeCreator({ toggleOptionsMenu }) {
  const [changeProjectCreatorModal, setChangeCreatorModal] = useState();

  return (
    <>
      <StyledChatMenuItem
        onClick={() => {
          setChangeCreatorModal(true);
          toggleOptionsMenu(false);
        }}
      >
        Сменить создателя
      </StyledChatMenuItem>

      {changeProjectCreatorModal && <ChangeProjectCreatorModal close={() => setChangeCreatorModal(false)} />}
    </>
  );
}

export default MenuItemChangeCreator;
