import {css, styled} from 'styled-components';

export const StyledStatusInfo = styled.p`
  font-size: 12px;
  background-color: ${({ theme }) => theme.light};
  box-shadow: ${({ theme }) => theme.shadowModal};
  color: ${({ theme }) => theme.darkest};
  min-width: max-content;
  opacity: 0;
  padding: 8px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  z-index: 3;
`;

export const StyledStatusDate = styled.span`
  color: ${({ theme }) => theme.darkest};
`;

export const StyledAvatar = styled.div`
  position: relative;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-right: 12px;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(props) => props.status});
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${(props) => props.state});
    background-size: cover;
  }

  & img {
    border-radius: 50%;
    width: ${({ size }) => size || 32}px;
    min-width: ${({ size }) => size || 32}px;
    height: ${({ size }) => size || 32}px;
  }

  ${props => props.size === 64 && css`
    &::before, &::after {
      width: 15px;
      height: 15px;
    }
  `}

  &:hover ${StyledStatusInfo} {
    opacity: 1;
    visibility: visible;
  }
`;

export const StyledMockAvatar = styled.div`
  font-size: 16px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;

  background-color: ${({theme}) => theme.disabled};

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 12px;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(props) => props.status});
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${(props) => props.state});
    background-size: cover;
  }
  
  ${props => props.size === 64 && css`
    font-size: 26px;
    
    &::before, &::after {
      width: 15px;
      height: 15px;
    }
  `}

  &:hover ${StyledStatusInfo} {
    opacity: 1;
    visibility: visible;
  }
`;
