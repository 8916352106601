import { useEffect, useMemo, useState } from 'react';
import {
  StyledPagination,
  StyledPaginationItem,
  StyledPaginationNext,
  StyledPaginationPrev,
} from '@shared/components/Pagination/style';

const Pagination = ({ id, totalPages, onChange, defaultValue }) => {
  const [currPageNum, setCurrPageNum] = useState(defaultValue || 1);

  const onChangeHalder = (page) => {
    if (onChange) {
      onChange(page);
    }
  };

  // react массив кнопок для отображения
  const buttons = useMemo(() => {
    const buttonsArr = [];

    if (!totalPages) return '';

    // cчитает максимальное кол-тво кнопок предыдущих страниц (до 5)
    const prevBtnsAmount = currPageNum - totalPages + 5;
    // цикл для добавления кнопок до кнопки текущей страницы
    // повторять цикл пока предыдущие кнопки не достигли первой страницы
    // и кол-тво добавленных кнопок меньше 3 или пока не достигнута максимальное количество (prevBtnsAmount)
    for (let i = 1, j = currPageNum; j > 1 && (i < 3 || i < prevBtnsAmount); i++, j--) {
      buttonsArr.unshift(
        <StyledPaginationItem
          key={currPageNum - i}
          onClick={() => {
            setCurrPageNum(currPageNum - i);

            onChangeHalder(currPageNum - i);
          }}
        >
          {currPageNum - i}
        </StyledPaginationItem>,
      );
    }
    // добавляет кнопку текущей страницы
    buttonsArr.push(
      <StyledPaginationItem $active={true} key={currPageNum}>
        {currPageNum}
      </StyledPaginationItem>,
    );

    // cчитает максимальное кол-тво кнопок следующих страниц (до 5)
    const nextBtnsAmount = 5 - currPageNum;
    // цикл для добавления кнопок до кнопки текущей страницы
    // повторять цикл пока предыдущие кнопки не достигли последней страницы
    // и кол-тво добавленных кнопок меньше 3 или пока не достигнута максимальное количество (nextBtnsAmount)
    for (let i = 1, j = currPageNum; j < totalPages && (i <= nextBtnsAmount || i < 3); i++, j++) {
      buttonsArr.push(
        <StyledPaginationItem
          key={currPageNum + i}
          onClick={() => {
            setCurrPageNum(currPageNum + i);

            onChangeHalder(currPageNum + i);
          }}
        >
          {currPageNum + i}
        </StyledPaginationItem>,
      );
    }

    return buttonsArr;
  }, [totalPages, currPageNum, onChange]);

  const resetValues = () => {
    setCurrPageNum(defaultValue ? Number(defaultValue) : 1);
  };

  useEffect(() => {
    if (Number(totalPages) < currPageNum) {
      resetValues();
      return;
    }
  }, [currPageNum, totalPages]);

  useEffect(() => {
    // Если в рамках одного компонента мы используем разные вкладки и т.п., прокидываем id для сброса
    if (id) {
      resetValues();
    }
  }, [id]);

  useEffect(() => {
    if (defaultValue) {
      setCurrPageNum(Number(defaultValue));
    }
  }, [defaultValue]);

  return (
    <StyledPagination>
      <StyledPaginationPrev
        disabled={currPageNum < 4 || totalPages < 6}
        onClick={() => {
          const pageToNavigate = totalPages - currPageNum < 3 ? totalPages - 5 : currPageNum - 3;

          setCurrPageNum(pageToNavigate);

          onChangeHalder(pageToNavigate);
        }}
      />
      {buttons}
      <StyledPaginationNext
        disabled={(currPageNum < 3 && totalPages < 6) || totalPages - (currPageNum + 3) < 0}
        onClick={() => {
          const pageToNavigate = currPageNum < 3 ? 6 : currPageNum + 3;

          setCurrPageNum(pageToNavigate);

          onChangeHalder(pageToNavigate);
        }}
      />
    </StyledPagination>
  );
};

export default Pagination;
