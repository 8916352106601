import { MultipleSelect } from '@shared/components/Select/Select';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { components } from 'react-select';

import useAuth from 'src/hooks/useAuth';
import { Avatar } from '@shared/components/Avatar';

import { Label } from '@shared/components';

import { useController } from 'react-hook-form';

import { Wrapper } from './styles';

export const getOptions = (departments, token) => {
  if (!departments) return [];
  const options = departments.map((department) => ({
    label: `${department?.title}`,
    value: department.id,
  }));

  return options;
};

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Wrapper align={'center'}>
        {props.data?.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.Option>
  );
};

const CustomValue = ({ children, ...props }) => {
  return (
    <components.MultiValue {...props}>
      <Wrapper align={'center'}>
        {props.data.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.MultiValue>
  );
};

const DepartmentsSelect = ({ control }) => {
  const auth = useAuth();
  const departments = useSelector((state) => state.users.departments);

  const options = useMemo(() => getOptions(departments, auth.token), [departments]);

  const { field } = useController({ name: 'departments', control });

  return (
    <div>
      <Label>Видят ссылку</Label>
      <MultipleSelect
        {...field}
        placeholder="Выбрать отделы"
        options={options}
        components={{ Option: CustomOption, MultiValue: CustomValue }}
      />
    </div>
  );
};

export default DepartmentsSelect;
