import { useEffect, useMemo, useState } from 'react';

import { Modal } from '@shared/components';

import useAuth from 'src/hooks/useAuth';

import Preloader from '@components/preloaders/Preloader';

import { DragDropContext } from 'react-beautiful-dnd';

import { useEditAdditionalFieldsMutation, useGetAdditionalInfoListQuery } from 'src/redux/features/api/additionalInfo';

import { Placeholder } from '@shared/components/Placeholder';

import { CreateAdditionalFieldModal } from './ui/CreateAdditionalFieldModal';
import { StyledModalGrid, StyledModalGridCell, StyledModalGridHeader } from './styles';
import { FieldItem } from './ui/ListItem';
import { StrictModeDroppable } from './lib';

export const AdditionalInfoModal = ({ onClose }) => {
  const { data, isFetching } = useGetAdditionalInfoListQuery();
  const [editFields] = useEditAdditionalFieldsMutation();

  const [fieldsList, setFieldsList] = useState([]);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const { isUserRightful, rightTypes } = useAuth();
  const hasRightToEdit = useMemo(() => isUserRightful(rightTypes.contacts_edit), [isUserRightful, rightTypes]);

  const headerButtons = [{ name: 'Добавить', action: () => setAddModalVisible(true) }];

  const handlerCloseWindow = () => onClose();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getOrderedFieldsList = (fields) => {
    return JSON.parse(JSON.stringify(fields)).sort((a, b) => a.order - b.order);
  };

  const getOrderedFieldsListData = (fields) => {
    return fields.map((field) => ({
      row: {
        // name: field.name,
        order: field.order,
        // include: field.include,
        // type: field.type,
        // values: field.type === fieldTypes.list ? field.values : undefined,
      },
      additional_info_id: field.id,
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const orderedFieldsList = getOrderedFieldsList(
      reorder(fieldsList, result.source.index, result.destination.index).map((field, index) => ({
        ...field,
        order: index + 1,
      })),
    );
    const orderedFieldsListData = getOrderedFieldsListData(orderedFieldsList);

    editFields({
      rows: [...orderedFieldsListData],
    });

    setFieldsList(orderedFieldsList);
  };

  useEffect(() => {
    if (data) setFieldsList(getOrderedFieldsList(data?.companyGroups));
  }, [data]);

  return (
    <>
      {!addModalVisible && (
        <Modal
          modalSize="960px"
          title={'Дополнительная информация в контакты'}
          onClose={handlerCloseWindow}
          headerButtons={hasRightToEdit && headerButtons}
          withoutButtons
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId={'list'}>
              {(provided) => (
                <>
                  <StyledModalGrid grid="3.5fr 1fr 1.5fr 100px" style={{ marginLeft: '20px' }}>
                    <StyledModalGridHeader>
                      <StyledModalGridCell>Название</StyledModalGridCell>
                      <StyledModalGridCell>Тип</StyledModalGridCell>
                      <StyledModalGridCell>Выводим в список</StyledModalGridCell>
                      {hasRightToEdit && <StyledModalGridCell align="right">Действия</StyledModalGridCell>}
                    </StyledModalGridHeader>
                  </StyledModalGrid>
                  {!data?.companyGroups?.length && !isFetching ? (
                    <Placeholder heigth={'60px'} textSize={2} />
                  ) : isFetching ? (
                    <Preloader />
                  ) : (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {fieldsList?.map((item, index) => (
                        <FieldItem key={item.id} item={item} index={index} hasRightToEdit={hasRightToEdit} />
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </Modal>
      )}

      {addModalVisible && (
        <CreateAdditionalFieldModal
          order={fieldsList.length + 1}
          close={() => {
            setAddModalVisible(false);
          }}
        />
      )}
    </>
  );
};
