export const getQueryData = ({ storageId, folderId }) => {
  if (storageId && !folderId) {
    return { storage_id: storageId };
  }

  if (storageId && folderId) {
    return {
      folder_id: folderId,
    };
  }
};
