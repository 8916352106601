import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation, useSearchParams} from 'react-router-dom';

import Preloader from '../../../../components/preloaders/Preloader';
import {
  getFilteredProjectsList,
  // getProjectsList,
} from '../../../../redux/features/projectsSlice';
import { project_window } from '../../../../settings/window-types';

import ProjectListItem from './ProjectListItem';
import { ProjectCardList } from './style';

const ProjectListContainer = ({ popupWindow, location }) => {
  const dispatch = useDispatch();
  let filteredProjectsList = useSelector((state) => state.projects.filteredProjectsList);
  const isLoading = useSelector((state) => state.projects.isLoadingProjectsList);

  const [filterParams] = useSearchParams();

  const type = location.pathname.includes('projects') ? 'project' : 'office';

  useEffect(() => {
    dispatch(getFilteredProjectsList({ filterParams, type }));
    // dispatch(getProjectsList());
  }, [dispatch, filterParams]);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <ProjectCardList>
          {/* список проектов по результатам фильтрации */}
          {filteredProjectsList?.map((item, i) => (
            <ProjectListItem
              key={i}
              item={item}
              onEdit={() => {
                popupWindow.props.onEdit(project_window, item);
              }}
            />
          ))}
        </ProjectCardList>
      )}
    </>
  );
};

export default ProjectListContainer;
