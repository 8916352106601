import React, { useRef, useState } from 'react';

import FilesUploadManage from '@components/files/FilesUploadManage';

import { Container } from '@shared/components';

import { DndWrapper, FilesContainer } from './styles';

export const FileLoader = ({ removeFilesOnClose, getUploadedFiles, formId }) => {
  const [drag, setDrag] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isFilesUploading, setIsFilesUploading] = useState();

  const inputRef = React.useRef();
  const clearFileManager = useRef();

  const dragStartHanlder = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const onDropHadler = (e) => {
    e.preventDefault(e);
    clearFileManager.current();
    setFilesToUpload([...e.dataTransfer.files]);
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      clearFileManager.current();
      setFilesToUpload([...e.target.files]);
    }
  };

  return (
    <Container direction="column">
      <DndWrapper
        onDragStart={(e) => dragStartHanlder(e)}
        onDragOver={(e) => dragStartHanlder(e)}
        onDragLeave={(e) => dragLeaveHandler(e)}
        onDrop={(e) => onDropHadler(e)}
        isDrag={drag}
        onClick={() => {
          inputRef.current.click();
        }}
      >
        Загрузите файл
        <input ref={inputRef} type="file" multiple={false} onChange={handleChange} />
      </DndWrapper>

      <FilesContainer>
        <FilesUploadManage
          formId={formId}
          filesToUpload={filesToUpload}
          filesUploadPath="/api/link_file/add"
          filesDeletePath="/api/link_file/remove"
          setIsFilesUploading={setIsFilesUploading}
          removeFilesOnClose={removeFilesOnClose}
          clearFileManager={clearFileManager}
          getUploadedFiles={getUploadedFiles}
          removeById
          notMultiple
        />
      </FilesContainer>
    </Container>
  );
};
