import { useMemo, memo } from 'react';
import { object, number } from 'prop-types';
import SelectMulty from '@components/form/select/SelectMulty';

ListFieldArrayItemFC.propTypes = {
  infoField: object,
  index: number,
  control: object,
};

function ListFieldArrayItemFC({ infoField, index, control }) {
  const item = useMemo(
    () => ({ name: `partner_company_infos.${index}.value`, label: infoField?.name }),
    [index, infoField?.name],
  );

  const options = useMemo(() => {
    if (!infoField?.values) return [];
    return infoField.values.map((option) => ({ label: option.value, value: option.id }));
  }, [infoField?.values]);

  return <SelectMulty item={item} control={control} options={options} />;
}

const LinkFieldArrayItem = memo(ListFieldArrayItemFC);

export default LinkFieldArrayItem;
