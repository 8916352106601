import axios from 'axios';
import Cookies from 'js-cookie';

import { server_url } from '../../../settings/base-url';

const axiosInstance = axios.create({
  // baseURL: "portal.starkandbau.ru:88",
  baseURL: server_url,
  // baseURL: "http://nestjs-boilerplate-test.herokuapp.com/api",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = Cookies.get('auth-token');

    if (authToken) {
      config.headers.authorization = `Bearer ${authToken}`;
      //
      //
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
