import { styled } from 'styled-components';
import { Title } from '@shared/components';

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding: 32px 0;
`;
export const StyledTitle = styled(Title)`
  padding: 0;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 14px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 800px;

  & thead tr {
    background-color: rgb(246, 248, 250);
    color: #ffffff;
    text-align: left;
  }

  & th,
  & td {
    padding: 12px 15px;
    color: rgb(87, 96, 106);
    font-weight: 600;
    width: 40%;
  }

  & tbody tr {
    border-bottom: 1px solid #dddddd;
    color: rgb(36, 41, 47);
    font-weight: 600;

    &:hover {
      background: #f0f0f0;
    }
  }
`;
