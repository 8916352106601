import styled from "styled-components";

export const StyledUserCard = styled.section`
  padding: 8px 0;
  align-items:  ${props => props.$guest ? 'start' : 'center'};
  cursor: ${props => props.$guest ? 'default' : 'pointer'};
  user-select: ${props => props.$guest ? 'none' : 'all'};
  display: grid;
  justify-content: space-between;
  grid-template-columns: 32px 1fr max-content;
  gap: 12px;
  transition: all 0.3s ease-in-out;
`

export const StyledUserName = styled.span`
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  margin-right: 8px;
  
  &:hover {
    color: ${({theme}) => theme.main};
  }
`

export const StyledUserEmail = styled.a`
  display: inline-block;
  font-size: 12px;
  position: relative;
  transition: all 0.3s;
  z-index: 3;

  &:hover {
    color: ${({theme}) => theme.main};
    text-decoration: underline;
  }
`

export const StyledUserRights = styled.p`
  color: ${({theme}) => theme.dark};
  display: block;
  font-size: 12px;
  font-style: italic;
  margin-top: -5px;
`

export const StyledUserControls = styled.div`
  display: flex;
  gap: 4px;
  opacity: 0;
  visibility: hidden;
  align-items: center;
  transition: all 0.2s ease-in-out;
  
  ${StyledUserCard}:hover & {
    opacity: 1;
    visibility: visible;
  }
`

export const StyledResultTask = styled.div`
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.borderSecondary};
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 16px 0;
`

export const StyledResultStatusWrapper = styled.div`
  grid-column: 1/-1;
`

export const StyledUserWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  ${props => props.$isGuest && `
    pointer-events: none;
    cursor: auto;
  `}
`

export const StyledUsername = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  transition: color 0.3s ease-in-out;
  
  &:hover {
    color: ${({theme}) => theme.main};
  }
`

export const StyledTagsWrapper = styled.div`
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  grid-column: 1/-1;
`

export const StyledResultDate = styled.p`
  color:  ${({theme}) => theme.dark};
  text-align: right;
  font-size: 12px;
  line-height: 20px;
`

export const StyledResultText = styled.p`
  font-weight: 500;
  grid-column: 1/-1;
`

export const StyledFilesItem = styled.section`
  background-color: ${({theme}) => theme.light};
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 16px;
  gap: 16px;
  display: grid;
`

export const StyledResultList = styled.ol`
  list-style: decimal;
`

export const StyledResultItem = styled.li`
  margin-left: 16px;
`

export const StyledResultTitle = styled.h3`
  font-weight: 600;
  margin: 16px 0;
  font-size: 16px;
  line-height: 24px;
`