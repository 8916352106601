import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Text } from '@shared/components/Text/Text';
import useAuth from 'src/hooks/useAuth';

import { generatePdfUrl } from 'src/settings/base-url';

import { Button } from '@shared/components';
import { useDownloader } from '@shared/hooks/useDownloader';

import { DocViewerHeader, PluginContainer, StyledContainer } from './styles';

export const DocsViewer = ({ filePath, fileName }) => {
  const auth = useAuth();
  const { downloadFile } = useDownloader();

  const docs = [
    {
      uri: `${generatePdfUrl}${encodeURIComponent(filePath)}?token=${auth.token}`,
    },
  ];

  return (
    <PluginContainer>
      <StyledContainer>
        <DocViewerHeader>
          <Text size={4}>
            Предпросмотр файла <b>"{fileName}"</b>
          </Text>

          <Button onClick={() => downloadFile({ url: filePath, filename: fileName })}>Скачать</Button>
        </DocViewerHeader>

        <DocViewer
          config={{
            pdfVerticalScrollByDefault: false,
            pdfZoom: {
              defaultZoom: 0.85,
            },
          }}
          documents={docs}
          pluginRenderers={DocViewerRenderers}
        />
      </StyledContainer>
    </PluginContainer>
  );
};
