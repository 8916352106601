import { styled, css } from 'styled-components';
import {Text} from '@shared/components/Text/Text';

export const StyledVacationContainer = styled.section`
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 24px;

  @media (max-width: 550px) {
    padding: 0 12px;
  }
`
export const StyledVacationItem = styled.td`
  position: relative;
  vertical-align: middle;
  padding: 1px;
  border-radius: 5px;
  padding-top: ${(props) => (props.$firstItem ? '10px' : '1px')};
  padding-bottom: ${(props) => (props.$isLastItem ? '10px' : '1px')};
`;

export const StyledVacationLine = styled.div`
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 32px;
  border-radius: 5px;
  width: ${(props) => props.width || 30}px;
  gap: 8px;
  color: ${({ theme }) => theme.lightest};
  background-color: ${({ theme }) => theme.disabled};

  ${(props) =>
    props.$approved &&
    css`
      background-color: ${({ theme }) => theme.success};
    `}
`;

export const StyledVacationText = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin-right: auto;
`;

export const StyledVacationUser = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${({ $firstItem, $isLastItem }) => {
    if ($firstItem && $isLastItem) return 0;
    if ($firstItem) return '5px';
    if ($isLastItem) return '-5px';
    return 0;
  }};
  left: 100%;
  // border: 1px solid red;
  justify-content: center;
  height: 100%;
  padding-left: 10px;
`;

export const StyledVacationName = styled.p`
  color: ${({ theme }) => theme.darkest};
  font-weight: 600;
  font-size: 14px;
  min-width: max-content;
`;

export const StyledVacationPosition = styled.span`
  color: ${({ theme }) => theme.dark};
  font-weight: 400;
  font-size: 12px;
  margin-left: 8px;
`;

export const VacationTitle = styled(Text)`
  margin-bottom: 8px;
`

export const Select = styled.section`
  min-width: auto;
  width: ${props => props.width || '100%'};
  position: relative;
  cursor: pointer;

  ${props => props.$show && css`
    ${StyledSelectInput} {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      
      &::after {
        transform: rotate(-135deg);
      }
    }
    
    ${StyledSelect} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      max-height: 270px;
      opacity: 1;
      overflow-y: auto;
      transition: all 0.2s ease-in-out;
    }
  `}
`

export const StyledSelectInput = styled.div`
  border: 1px solid ${({theme}) => theme.border};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  min-height: 42px;
  padding: 8px 28px 8px 8px;
  position: relative;
  width: 100%;
  
  &::after {
    border-bottom: 1px solid ${({theme}) => theme.disabled};
    border-right: 1px solid ${({theme}) => theme.disabled};
    content: '';
    height: 6px;
    pointer-events: none;
    position: absolute;
    right: 13px;
    top: 45%;
    transform: translateY(-50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
    width: 6px;
  }
`

export const StyledSelect = styled.ul`
  position: absolute;
  background-color: ${({theme}) => theme.lightest};
  border: 1px solid ${({theme}) => theme.border};
  border-radius: 2px;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  top: 100%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
`

export const StyledSelectItem = styled.li`
  display: flex;
  padding: 8px;
  transition: all 0.2s;
  
  &:hover {
    background-color: ${({theme}) => theme.mainLight};
  }
`

export const StyledVacationCard = styled.section`
  margin-bottom: 24px;
  border-bottom: 1px solid ${({theme}) => theme.borderSecondary};
  padding: 8px 0;
  cursor: pointer;
`

export const VacationUser = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const VacationDate = styled(Text)`
  color: ${({theme}) => theme.dark};
  margin-bottom: 12px;
`

export const StyledVacation = styled.section`
  margin-left: 150px;
  overflow-x: auto;
  cursor: grab;
  user-select: none;
`

export const VacationTable = styled.table`
  border: 1px solid #f0f0f0;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0 1em;
`

export const VacationStickyCol = styled.td`
  background-color: #fff;
  border: 1px solid #f0f0f0;
  font-weight: 600;
  left: 0;
  padding: 22px 24px;
  position: absolute;
  width: 150px;
`

export const VacationTableWeek = styled.td`
  border: 1px solid #f0f0f0;
  padding: 5px 16px;
  white-space: nowrap;
`

export const VacationTableDay = styled.td`
  border: 1px solid #f0f0f0;
  padding: 5px 7px;
`

export const VacationTableDep = styled.td`
  background-color: #fff;
  left: 0;
  padding: 22px 24px;
  position: absolute;
  width: 150px;
`

export const VacationTableRow = styled.tr`

`