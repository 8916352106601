import { Container } from '@shared/components/Container';
import { styled } from 'styled-components';

export const StyledContainer = styled(Container)`
  flex-direction: column;
`;

export const ButtonsContainer = styled(Container)`
  justify-content: center;
  gap: 12px 24px;
`;

export const StyledIconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  padding-top: 8px;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 12px;
  gap: 8px;
`;
