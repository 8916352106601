import React, { useState, useRef, memo } from 'react';
import Select, { components } from 'react-select';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Tag from '@shared/components/Tag/Tag';
import { IconButton } from '@shared/components';
import DeleteCircleButton from '@shared/components/DeleteCircleButton';

import iconAdd from '@assets/images/icons/plus.svg';
import iconFilter from "@assets/images/icons/filter.svg";

import { SelectItem, SelectWrapper, TagsWrapper } from './style';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconButton icon={iconAdd} size={20} background={'#52c41a'} />
    </components.DropdownIndicator>
  );
};

// const ValueContainer = () => {};

const MultiValue = (props) => {
  return <components.MultiValue {...props}>
    {props.children}
  </components.MultiValue>;
};

const colorStyles = (provided) => ({
  ...provided,
  container: (styles) => ({
    ...styles,
    width: 'fit-content',
    minWidth: '200px',
    maxWidth: '100%',
  }),
  control: (styles) => ({
    ...styles,
    borderWidth: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row-reverse',
    width: 'fit-content',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorContainer: () => ({
    padding: 0
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    display: 'flex',
    gap: '8px',
    flexWrap: 'nowrap',
    alignItems: 'center',
    overflowX: 'auto',
    maxWidth: '100%',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      width: 0,
      height: 0
    },
  }),
  multiValueLabel: () => {
    return {
      position: 'relative',
      width: '100%',
    };
  },
  multiValue: () => {
    return {
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'nowrap',
      border: '1px solid #d9d9d9',
      borderRadius: '2px',
      padding: '1px 8px',
      fontSize: '12px',
      lineHeight: '20px',
      backgroundColor: 'transparent',
    };
  },
  // кнопка удаления
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#d9d9d9',
    borderRadius: '100px',
    border: '1px solid #d9d9d9',
    padding: '0px',
    marginLeft: '12px',
    height: '14px',
    width: '14px',
    ':hover': {
      color: 'red',
      border: '1px solid red',
    },
  }),
});

const options = [
  { label: 'Я автор', value: 'creator' },
  { label: 'Я ответственный', value: 'responsible' },
  { label: 'Я исполнитель', value: 'executor' },
  { label: 'Я участник', value: 'member' },
];

const TaskFiltersFC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tagsFilter, setTagsFilter] = useState([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const tagsList = useSelector((state) => state.tags.tagsList);

  // пришлось убрать makeAnimated, он мешал overflow
  // const animatedComponents = makeAnimated({
  //   MultiValue,
  //   DropdownIndicator,
  // });

  const reactSelectRef = useRef();

  useEffect(() => {
    const searchParamsObj = {};
    for (const [key, value] of searchParams) searchParamsObj[key] = value;

    if (searchParamsObj.relation) {
      const relationsArr = [];
      searchParamsObj.relation.split(',').forEach((value) => {
        const validOption = options.find((option) => option.value === value);
        if (validOption) relationsArr.push({ value, label: validOption.label });
      });
      const currentValue = reactSelectRef.current.getValue();
      if (relationsArr.length !== currentValue.length) {
        reactSelectRef.current.setValue(relationsArr);
      }
    }

    if (searchParamsObj.tags) {
      let selectedTagsArr = [];
      selectedTagsArr = searchParamsObj.tags.split(',').map((tagId) => +tagId);
      setTagsFilter(selectedTagsArr);
    }
  }, [searchParams]);

  //

  const handleSelectChange = (data) => {
    const newSearchParams = {};
    for (const [key, value] of searchParams) newSearchParams[key] = value;
    data.length > 0
      ? (newSearchParams.relation = data.map((option) => option.value).join())
      : delete newSearchParams.relation;
    // setTimeout для завершения анимации в react-select
    setTimeout(() => {
      setSearchParams(newSearchParams);
    }, 350);
  };

  const handleTagSelect = (tagId, isActive) => {
    const newTagsFilter = isActive ? tagsFilter.filter((id) => id !== tagId) : [...tagsFilter, tagId];

    const newSearchParams = {};
    for (const [key, value] of searchParams) newSearchParams[key] = value;

    newTagsFilter.length > 0 ? (newSearchParams.tags = newTagsFilter.join()) : delete newSearchParams.tags;

    // setTagsFilter(newTagsFilter);
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <SelectWrapper>
        <Select
          ref={reactSelectRef}
          isMulti
          components={{
            MultiValue,
            DropdownIndicator,
          }}
          styles={colorStyles()}
          options={options}
          placeholder=""
          onChange={handleSelectChange}
          // value={selectedValues}
        />
        <IconButton
          style={{backgroundSize: '14px'}}
          size={20}
          background="#1890FF"
          icon={iconFilter}
          onClick={() => setIsFiltersOpen((isFiltersOpen) => !isFiltersOpen)}
        />
      </SelectWrapper>
      {isFiltersOpen && <TagsWrapper>
        {tagsList?.map((tag, i) => {
          const isActive = tagsFilter.includes(tag.id) ? 'tag--active' : '';
          return <Tag key={i} tag={tag} $active={isActive} onClick={() => handleTagSelect(tag.id, isActive)} />;
        })}
      </TagsWrapper>}
    </>
  );
};

const TaskFilters = memo(TaskFiltersFC);

export default TaskFilters;
