import {Avatar} from '@shared/components/Avatar';
import moment from 'moment';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useSocketContext} from 'src/contexts/SocketContext';
import useAuth from 'src/hooks/useAuth';
import {
  employeesApi,
  useDeletePaymentMutation,
  useGetFilteredPaymentsListQuery,
} from 'src/redux/features/api/employees';

import {useSearchParams} from 'react-router-dom';

import {nanoid} from 'nanoid';

import EditIcon from '@assets/images/icons/edit.svg';
import DeleteIcon from '@assets/images/icons/delete.svg';

import {IconButton} from '@shared/components/IconButton/IconButton';

import {Placeholder} from '@shared/components/Placeholder';

import {Container} from '@shared/components';

import {TeamFilters} from '../ui/TeamFilters';

import {EditPaymentModal} from '../ui/EditPaymetModal';
import {
  Salary,
  StyledStickyCell,
  StyledTable,
  StyledTableCol, StyledTableDescription, StyledTableFooter,
  StyledTableHeader, StyledTablePosition, StyledTableUsername, StyledTableWrapper,
  StyledTitle, StyledUserWrapper
} from "../PageSalaries/styles";

import {FiltersWrapper, StyledRow} from './styles';
import {RemovePaymentModal} from './RemovePaymentModal';

const PagePayments = () => {
  const [filteredList, setFilteredList] = useState([]);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [employee, setEmployee] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const auth = useAuth();
  const {socket} = useSocketContext();
  const dispatch = useDispatch();

  const {isUserRightful, rightTypes} = useAuth();

  const departmentId = useMemo(() => searchParams.get('department'), [searchParams]);
  const dateFrom = useMemo(() => searchParams.get('from'), [searchParams]);
  const dateTo = useMemo(() => searchParams.get('to'), [searchParams]);

  const {data, isLoading} = useGetFilteredPaymentsListQuery({
    dateFrom,
    dateTo,
    departmentId,
  });

  const [deletePayment] = useDeletePaymentMutation();

  const onDelete = async (id) => {
    await deletePayment({
      employee_payout_id: id,
    });
    setRemoveModalVisible(false);
  };

  const editButtonHanlder = (emp) => {
    setEmployee(emp);
    setEditModalVisible(true);
  };

  useEffect(() => {
    if (socket) {
      socket.emit('add_to_room', 'employee_payouts');

      socket.on('department_changed', () => {
        dispatch(employeesApi.util.invalidateTags(['Employees']));
      });

      socket.on('employee_changed', () => {
        dispatch(employeesApi.util.invalidateTags(['Employees']));
      });
    }

    return () => {
      if (socket) socket.emit('leave_room', 'employee_payouts');
    };
  }, [auth.token, socket]);

  return (
    <Salary>
      {!isUserRightful(rightTypes.salary_view) ? (
        <Placeholder text={'У вас нет прав для просмотра данной страницы'}/>
      ) : (
        <>
          <FiltersWrapper>
            <TeamFilters/>
          </FiltersWrapper>
        <StyledTableWrapper>
          {!isLoading &&
            data?.employee_payouts?.map((item) => (
              <Fragment key={nanoid()}>
                <StyledTable>
                  <thead>
                  <tr>
                    <StyledStickyCell>
                      <StyledTitle size={4}>{item.title}</StyledTitle>
                    </StyledStickyCell>
                  </tr>
                  <StyledTableHeader>
                    <StyledTableCol>Кто</StyledTableCol>
                    <StyledTableCol>Должность</StyledTableCol>
                    <StyledTableCol>Дата</StyledTableCol>
                    <StyledTableCol>Основание</StyledTableCol>
                    <StyledTableCol>Руб/месяц</StyledTableCol>
                  </StyledTableHeader>
                  </thead>
                  <tbody>
                  {item.employeePayouts &&
                    item.employeePayouts.map((emp) => (
                      <StyledRow key={nanoid()}>
                        <StyledTableCol>
                          <StyledUserWrapper>
                            <Avatar userId={emp.employee_id} style={{alignSelf: "center"}}/>
                            <StyledTableUsername>
                              {emp.last_name} {emp.first_name}
                            </StyledTableUsername>
                          </StyledUserWrapper>
                        </StyledTableCol>
                        <StyledTableCol>
                          <StyledTableDescription>Должность</StyledTableDescription>
                          {emp.position && <StyledTablePosition>{emp.position}</StyledTablePosition>}
                        </StyledTableCol>
                        <StyledTableCol>
                          <StyledTableDescription>Дата</StyledTableDescription>
                          <span>{moment.utc(emp.payout_date).format('L')}</span>
                        </StyledTableCol>
                        <StyledTableCol>
                          <StyledTableDescription>Основание</StyledTableDescription>
                          <span>{emp.type === 'bonus' ? 'Премия' : 'З/П'}</span>
                        </StyledTableCol>
                        <StyledTableCol>
                          <StyledTableDescription>Руб/мес</StyledTableDescription>
                          <Container align={'center'} justify={'space-between'}>
                            <span>{emp.money}</span>

                            <Container align={'center'} gap={'12px'}>
                              <IconButton size={18} icon={EditIcon} onClick={() => editButtonHanlder(emp)}/>
                              <IconButton
                                size={18}
                                icon={DeleteIcon}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEmployee(emp);
                                  setRemoveModalVisible(true);
                                }}
                              />
                            </Container>
                          </Container>
                        </StyledTableCol>
                      </StyledRow>
                    ))}
                  </tbody>
                  {item.employeePayouts && !!item.employeePayouts.length && (
                    <StyledTableFooter>
                    <tr>
                      <StyledTableCol>Итого:</StyledTableCol>
                      <StyledTableCol></StyledTableCol>
                      <StyledTableCol></StyledTableCol>
                      <StyledTableCol></StyledTableCol>
                      <StyledTableCol>
                        {item.employeePayouts.reduce((acc, current) => (acc += current.money), 0)}
                      </StyledTableCol>
                    </tr>
                    </StyledTableFooter>
                  )}
                </StyledTable>
              </Fragment>
            ))}
        </StyledTableWrapper>
        </>
      )}

      {editModalVisible && <EditPaymentModal employeeData={employee} onClose={() => setEditModalVisible(false)}/>}
      {removeModalVisible && (
        <RemovePaymentModal onSave={() => onDelete(employee.id)} onClose={() => setRemoveModalVisible(false)}/>
      )}
    </Salary>
  );
};

export default PagePayments;
