import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { EditorState, RichUtils, ContentState, convertFromHTML, convertFromRaw, Editor } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import iconBold from '@assets/images/icons/chat-richtext.svg';
import iconAttach from '@assets/images/icons/icon-file.svg';

import {
  StyledEditorControlItem,
  StyledEditorControls,
  StyledToolbar,
  StyledToolbarItem,
} from '@components/text-editor/style';

import editorStyles from './MultiMentionTriggers.module.css';

const createHighlightPlugin = () => {
  return {
    customStyleMap: {
      HIGHLIGHT: {
        background: '#fffe0d',
      },
    },
    keyBindingFn: (e) => {
      if (e.metaKey && e.key === 'h') {
        return 'highlight';
      }
    },
    handleKeyCommand: (command, editorState, { setEditorState }) => {
      if (command === 'highlight') {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'HIGHLIGHT'));
        return true;
      }
    },
  };
};

const highlightPlugin = createHighlightPlugin();

const TextEditor = ({ prev, onSubmitFiles, sendFormat, height, messageBasis }) => {
  const ref = useRef(null);
  const [toolbar, setToolBar] = useState(false);

  const initPrevState = useCallback(() => {
    let prevState = convertFromHTML(prev);
    const content = ContentState.createFromBlockArray(prevState.contentBlocks, prevState.entityMap);
    return content;
  }, [prev]);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    // если у нас нет prev - тогда отрабатывает useeffect для  создания модалки
    if (!prev) {
      return;
    }
    setEditorState(EditorState.createWithContent(initPrevState()));
    //
  }, [prev, initPrevState]);

  const { plugins } = useMemo(() => {
    // eslint-disable-next-line no-shadow
    const plugins = [highlightPlugin];
    return { plugins };
  }, []);

  const onChange = useCallback((_editorState) => {
    setEditorState(_editorState);
  }, []);

  // связанное с клавиатурой и выделением текста
  // const handleKeyCommand = (command) => {
  //   const newState = RichUtils.handleKeyCommand(
  //     this.state.editorState,
  //     command
  //   );
  //   if (newState) {
  //     this.onChange(newState);
  //     return "handled";
  //   }
  //   return "not-handled";
  // };

  const onBoldClick = (e) => {
    e.preventDefault();
    const styled = RichUtils.toggleInlineStyle(editorState, 'BOLD');
    onChange(styled, 'BOLD');
    sendFormat(stateToHTML(styled.getCurrentContent()));
  };

  const onUnderlineClick = (e) => {
    e.preventDefault();
    const styled = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE');
    onChange(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
    sendFormat(stateToHTML(styled.getCurrentContent()));
  };

  const isStyleSelected = (styleType) => {
    const currentInlineStyle = editorState.getCurrentInlineStyle();
    return currentInlineStyle.has(styleType) ? 'selected' : '';
  };

  // если задача создается на основе сообщения, то указать текст сообщения в качестве описания задачи
  useEffect(() => {
    if (messageBasis) {
      try {
        let editorState;
        if (messageBasis.editor_state) {
          editorState = convertFromRaw(JSON.parse(messageBasis.editor_state));
        } else if (messageBasis.plainText) {
          editorState = ContentState.createFromText(messageBasis.plainText);
        }

        if (editorState) {
          setEditorState(EditorState.createWithContent(editorState));
          sendFormat(stateToHTML(editorState));
        }
      } catch (e) {}
    }
  }, [messageBasis, sendFormat]);

  const fileUploadInputRef = useRef();

  return (
    <>
      {toolbar && (
        <StyledToolbar>
          <StyledToolbarItem className={isStyleSelected('BOLD')} onMouseDown={onBoldClick} type="button">
            <b>B</b>
          </StyledToolbarItem>
          <StyledToolbarItem className={isStyleSelected('UNDERLINE')} onMouseDown={onUnderlineClick} type="button">
            U
          </StyledToolbarItem>
        </StyledToolbar>
      )}

      <div>
        <div
          className={editorStyles.editor}
          onClick={() => {
            ref.current.focus();
          }}
          onBlur={() => {
            sendFormat(stateToHTML(editorState.getCurrentContent()));
          }}
        >
          <Editor
            editorState={editorState}
            // blockStyleFn={myBlockStyleFn}
            onChange={onChange}
            ref={ref}
            // handleKeyCommand={handleKeyCommand}
            plugins={plugins}
            // id={name}
            rows="2"
            style={{ height: height }}
          />
        </div>

        <StyledEditorControls>
          <StyledEditorControlItem
            onClick={() => {
              setToolBar((prev) => !prev);
            }}
            icon={iconBold}
          ></StyledEditorControlItem>

          {/* иконка открытия диалога выбора файлов */}
          {onSubmitFiles && (
            <StyledEditorControlItem icon={iconAttach} onClick={() => fileUploadInputRef.current.click()}>
              <input
                type="file"
                ref={fileUploadInputRef}
                multiple="multiple"
                onChange={(e) => onSubmitFiles(e.target.files)}
                style={{ display: 'none' }}
              />
            </StyledEditorControlItem>
          )}
        </StyledEditorControls>
      </div>
    </>
  );
};

export default TextEditor;
