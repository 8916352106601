import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

export const StyledAside = styled.section`
  background-color: ${({ theme }) => theme.lightest};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: calc(100vh - 52px);
  max-width: 480px;
  opacity: 0;
  overflow-x: hidden;
  position: sticky;
  top: 52px;
  visibility: hidden;
  transition: all 0.3s linear;
  width: 0;

  ${(props) =>
    props.$active &&
    `
    opacity: 1;
    visibility: visible;
    width: 100%;
    transition: all 0.4s linear;
  `};

  @media (max-width: 1150px) {
    position: fixed;
    right: 0;
    top: 52px;
    z-index: 2;
    transform: translateX(100%);
    transition: transform 0.4s linear;
    width: 100%;

    ${(props) =>
      props.$active &&
      `
      transform: translateX(0);
  `};
  }
`;

export const StyledAsideHeader = styled.header`
  background-color: ${({ theme }) => theme.lightest};
  display: grid;
  grid-template-columns: 1fr 24px;
  padding: 16px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const StyledAsideTitle = styled.h3`
  font-weight: 600;
  padding-left: ${(props) => (props.icon ? '38px' : 0)};
  position: relative;
  font-size: 18px;
  line-height: 24px;

  &::before {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${(props) => props.iconsize + 'px' || '18px'};
    height: 100%;
    background-image: url(${(props) => props.icon});
  }
`;

export const StyledAsideBackwardButton = styled.button`
  border: none;
  background-color: transparent;
  display: block;
  margin-bottom: 12px;
  padding-left: 16px;
  position: relative;
  transition: all 0.3s ease-in-out;

  &::before {
    border-bottom: 5px solid transparent;
    border-right: 7px solid #262626;
    border-top: 5px solid transparent;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    width: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.main};

    &::before {
      border-right-color: ${({ theme }) => theme.main};
    }
  }
`;

export const StyledAsideForwardButton = styled.button`
  border: none;
  background-color: transparent;
  display: block;
  margin-bottom: 12px;
  padding-left: 0;
  padding-right: 16px;
  position: relative;
  transition: all 0.3s ease-in-out;

  &::before {
    border-bottom: 5px solid transparent;
    border-left: 7px solid #262626;
    border-top: 5px solid transparent;
    content: '';
    height: 0;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    width: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.main};

    &::before {
      border-left-color: ${({ theme }) => theme.main};
    }
  }
`;

export const StyledAsideBody = styled.section`
  overflow-y: auto;
  padding: 0 16px 16px;
`;
export const StyledAsideMenu = styled.section`
  border: 1px solid ${({ theme }) => theme.borderSecondary};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.lightest};
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 16px;
  padding: 16px;
  position: absolute;
  top: 52px;
  gap: 16px;
  z-index: 2;
  right: -15px;
  transform: translateX(200%);
  transition: all 0.3s;

  ${(props) =>
    props.$active &&
    `
    transform: translateX(0);
  `};

  @media (min-width: 768px) {
    position: sticky;
    transform: translateX(0);
  }
`;

export const StyledAsideSubtitle = styled.p`
  font-weight: 600;
  margin: 24px 0 8px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledAsideTagsWrapper = styled.section`
  padding: 16px 0;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const StyledAsideParentTask = styled.div`
  margin-bottom: 8px;
`;

export const StyledAsideLink = styled(Link)`
  color: #1890ff;
  display: block;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledResult = styled.ul`
  background-color: ${({ theme }) => theme.lightest};
  list-style-type: decimal;
  margin-bottom: 16px;
  max-width: 480px;
  padding-left: 16px;
`;

export const StyledResultItem = styled.li`
  margin-bottom: 8px;
`;

export const StyledResultHeader = styled.header`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const StyledResultTitle = styled.h4`
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const StyledResultType = styled.p`
  color: ${({ theme }) => theme.dark};
  font-size: 12px;
  line-height: 20px;
`;

export const StyledResultState = styled.p`
  ${(props) =>
    props.state === 'done' &&
    `
    color: ${props.theme.success}
  `};
  font-size: 12px;
  line-height: 20px;
`;

export const StyledProjectAccess = styled.div`
  color: ${({ theme }) => theme.dark};
  display: block;
  font-size: 12px;
  font-style: italic;
  margin-top: -5px;
`;

export const StyledProjectAccessText = styled.div`
  color: ${({ theme }) => theme.dark};
`;

export const StyledProjectAccessButton = styled.button`
  background-color: transparent;
  border: none;
  padding-left: 24px;
  position: relative;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.main};

    &::before {
      filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
    }
  }

  &::before {
    content: '';
    height: 17px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 17px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${(props) => props.icon});
  }
`;

export const StyledProjectTree = styled.ul`
  background-color: ${({theme}) => theme.lightest};
  cursor: pointer;
`

export const StyledProjectTreeItem = styled.li`
  position: relative;

  & ul {
    margin-left: 24px;
  }

  & [data-spoiler] {
    margin-left: 12px;
    position: relative;
    transition: all 0.3s ease-in-out;

    &::before {
      background-image: url(${props => props.icon});
      background-position: 50%;
      background-repeat: no-repeat;
      content: '';
      filter: brightness(0) saturate(100%);
      height: 5px;
      left: -12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      transition: all 0.3s ease-in-out;
      width: 9px;
    }

    ${props => props.$active && css`
    &::before {
      transform: translateY(-50%) rotate(0deg);
    }
  `}
  }
  `

export const StyledProjectTreeTitle = styled.p`
  font-weight: 600;
  padding: 4px 12px;
  transition: all 0.3s ease-in-out;
  
  &:hover {
    background-color: ${props => props.theme.mainLightOpacity};
  }
`

export const StyledIcon = styled.span`
  height: 15px;
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease-in-out;
  width: 15px;
  visibility: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  
  ${StyledProjectTreeTitle}:hover & {
    filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
    background-image: url(${props => props.iconhover});
    opacity: 1;
    visibility: visible;
  }
  
  ${props => props.$done && css`
    background-image: url(${props => props.icondone});
    opacity: 1;
    visibility: visible;
  `}
`