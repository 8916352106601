import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useGetOpenedNotificationsQuery } from 'src/redux/features/api/notifications';
import { useSocketContext } from 'src/contexts/SocketContext';
import { clearAllNotifications } from 'src/redux/features/notificationsSlice';
import { StyledLayout } from '@shared/components';
import { NotificationCard } from '@components/notifications/NotificationCard/NotificationCard';
import Preloader from '@components/preloaders/Preloader';

import { NotificationsContainer } from './styles';
import PageNotificationsHeader from './PageNotificationsHeader';

export const PageNotifications = () => {
  const [searchParams] = useSearchParams();

  const currentFilter = useMemo(() => searchParams.get('filter'), [searchParams]);

  const { unreadNotifications, isLoading } = useSelector((state) => state.notifications);
  const { readNotification } = useSocketContext();
  const dispatch = useDispatch();

  const clearAll = useCallback(() => {
    const noteIds = unreadNotifications.map((n) => n.id);
    readNotification(noteIds, () => dispatch(clearAllNotifications()));
  }, [unreadNotifications, readNotification]);

  const { data: openedNotifications, isFetching } = useGetOpenedNotificationsQuery(undefined, {
    skip: searchParams.get('status') !== 'read',
  });

  const { notificationsToRender, status } = useMemo(() => {
    return searchParams.get('status') === 'read'
      ? { notificationsToRender: openedNotifications, status: 'opened' }
      : { notificationsToRender: unreadNotifications, status: 'unread' };
  }, [searchParams, openedNotifications, unreadNotifications]);

  return (
    <StyledLayout>
      <PageNotificationsHeader clearAll={clearAll} />

      <NotificationsContainer>
        {(isLoading || isFetching) && <Preloader />}
        {notificationsToRender
          ?.filter((note) => {
            if (!currentFilter) return true;
            return note.notification_group === currentFilter;
          })
          .map((note, i) => (
            <NotificationCard key={i} notification={note} status={status} />
          ))}
      </NotificationsContainer>

      {
        // <Pagination
        //   totalPages={currentFilter === 'active' ? activeTickets?.page_count : completedTickets?.page_count}
        // />
        // <Pagination totalPages={1} />
      }
    </StyledLayout>
  );
};
