import React, { memo, useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectableTextAreaMouseUp } from 'src/utilize/message-helper-functions';
import LocalDateTime from '@components/shared/LocalDateTime';

import { PinnedMessage } from '@components/chat/message/PinnedMessage/PinnedMessage';

import { resetColoredMsgId } from 'src/redux/features/messagesSlice';

import Message from './Message';
import { StyledChatMessage } from './styles';
import MessageSelection from './MessageSelection';

function MessageBoxFC(props) {
  const {
    id: messageId,
    marked,
    pinned,
    pinnedMessageType,
    showPopover,
    date_created,
    employee_first_name,
    employee_last_name,
    style,
    isInModal,
  } = useMemo(() => props, [props]);

  const dispatch = useDispatch();

  const fullName = useMemo(
    () => `${employee_first_name || ''} ${employee_last_name || ''}`,
    [employee_first_name, employee_last_name],
  );

  const isHighlighted = useSelector((state) => state.messages.coloredMsgId === messageId);
  const [isMarked, setIsMarked] = useState(marked);

  const isMousePressedHere = useRef(false);

  const localDateCreated = useMemo(() => <LocalDateTime dateTime={date_created} />, [date_created]);

  const handleMouseDown = useCallback(() => {
    isMousePressedHere.current = true;
  }, []);

  const handleMouseUp = useCallback(
    (event) => {
      selectableTextAreaMouseUp(event, isMousePressedHere, showPopover, localDateCreated, fullName, messageId);
    },
    [showPopover, localDateCreated, fullName, messageId],
  );

  const isSelectMode = useSelector((state) => state.messages.isSelectMode);

  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    setHighlighted(false);

    if (isHighlighted) {
      setHighlighted(true);

      const animationTimer = setTimeout(() => {
        setHighlighted(false);
        dispatch(resetColoredMsgId());
      }, 2000);

      return () => clearTimeout(animationTimer);
    }
  }, [isHighlighted, dispatch]);

  return !pinnedMessageType ? (
    <StyledChatMessage
      $style={style}
      $pinned={Boolean(pinned)}
      $highlighted={highlighted && !isInModal}
      $marked={!!isMarked}
      $selected={isSelectMode}
      // не указывать id в DOM, если это закрепленное сообщение, чтобы не было возможности скролла по ссылкам на это закрепленное сообщение
      // id этого div используется для скролла на этот div при клике на ссылкку к этому сообщению
      id={pinnedMessageType ? '' : `messageId${messageId}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {isSelectMode && <MessageSelection messageId={messageId} />}
      <Message
        {...props}
        isMarked={isMarked}
        setIsMarked={setIsMarked}
        localDateCreated={localDateCreated}
        fullName={fullName}
      />
    </StyledChatMessage>
  ) : (
    <PinnedMessage {...props} />
  );
}

const MessageBox = memo(MessageBoxFC);

export default MessageBox;
