import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {Dropdown, StyledDropdownInput, StyledSelect, StyledSelectItem} from "@components/notifications/style";

const NotificationsTypeSelect = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [dropdown, setDropdown] = useState(false);

  const renderSelectedView = () => {
    if (searchParams.get('status') === 'read') return 'Прочитанные';
    return 'Непрочитанные';
  };

  return (
    <Dropdown $show={dropdown}>
        <StyledDropdownInput onClick={() => setDropdown(!dropdown)}>{renderSelectedView()}</StyledDropdownInput>
      <StyledSelect position="absolute">
        <StyledSelectItem
          onClick={() => {
            setDropdown(false);
            setSearchParams((s) => {
              const params = Object.fromEntries(s);
              delete params.status;
              return params;
            });
          }}
        >
          Непрочитанные
        </StyledSelectItem>
        <StyledSelectItem
          onClick={() => {
            setDropdown(false);
            setSearchParams((s) => {
              const params = Object.fromEntries(s);
              return { ...params, status: 'read' };
            });
          }}
        >
          Прочитанные
        </StyledSelectItem>
      </StyledSelect>
    </Dropdown>
  );
};

export default NotificationsTypeSelect;
