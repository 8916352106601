import { styled } from 'styled-components';
import DatePicker from 'react-datepicker';
import { IconButton } from '@shared/components';

export const ProjectListFilters = styled.form`
  display: grid;
  gap: 16px;
  padding: 36px 0;
`;

export const ProjectFiltersRow = styled.div`
  display: grid;
  align-items: flex-end;
  padding: 12px 0;
  gap: 24px;

  @media (min-width: 1650px) {
    grid-template-columns: 1fr max-content;
    gap: 100px;
  }

  @media (min-width: 1920px) {
    gap: 200px;
  }
`;

export const ProjectFiltersCol = styled.div`
  display: grid;
  gap: 16px;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ProjectListTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const DateRange = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 8px 0;

  & .react-datepicker__tab-loop {
    position: absolute;
  }
`;

export const DateRangeTitle = styled.p`
  grid-column: 1/-1;
  font-weight: 600;
`;

export const StyledDatePicker = styled(DatePicker)`
  cursor: pointer;
  font: inherit;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  color: ${({ theme }) => theme.darkest};
  outline: none;
  padding: 7px 34px 7px 12px;
  position: relative;
  width: 100%;

  ${(props) =>
    props.position === 'start' &&
    `
    border-bottom-right-radius: 0;
    border-right-color: transparent;
    border-top-right-radius: 0;
  `}

  ${(props) =>
    props.position === 'end' &&
    `
    border-bottom-left-radius: 0;
    border-left-color: transparent;
    border-top-left-radius: 0;
  `};

  @media (min-width: 1024px) {
    padding: 1px 34px 1px 12px;
  }
`;

export const IconCalendar = styled(IconButton)`
  right: 8px;
  bottom: 13px;
  position: absolute;
  pointer-events: none;

  @media (min-width: 1024px) {
    bottom: 7px;
  }
`;

export const IconArrow = styled(IconButton)`
  position: absolute;
  bottom: 13px;
  right: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    bottom: 7px;
  }
`;
