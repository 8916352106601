import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import UserProfileModalContainer from 'src/windows/profile/UserProfileModalContainer';
import GuestModalCurrent from 'src/windows/profile/GuestModalCurrent';
import useAuth from 'src/hooks/useAuth';
import useCoordinates from 'src/hooks/useCoordinates';
import { files_url } from 'src/settings/base-url';
import { useGetEmployeesDismissalsQuery } from 'src/redux/features/api/employees';

import iconOnline from 'src/assets/images/icons/icon-status-online.svg';
import iconOffline from 'src/assets/images/icons/icon-status-offline.svg';
import iconFired from 'src/assets/images/icons/icon-status-fired.svg';
import iconLeaving from 'src/assets/images/icons/icon-status-leaving.svg';
import iconVacation from 'src/assets/images/icons/icon-status-not-work.svg';

import { StyledAvatar, StyledMockAvatar, StyledStatusDate, StyledStatusInfo } from './styles';

const today = new Date();

export const Avatar = ({
  userId,
  size = 32,
  showProfileOnClick = true,
  projectData, // используется для показа гостевых профилей
  projectType, // используется для показа гостевых профилей
  projectId, // используется для показа гостевых профилей
  is_guest, // используется для показа гостевых профилей
}) => {
  const [userProfileModal, toggleUserProfileModal] = useState(false);
  const [guestProfileModal, toggleGuestProfileModal] = useState(false);

  const auth = useAuth();
  const user = useSelector((state) => state.users.employees?.find((employee) => employee.id === userId));
  const userOnline = useSelector((state) => state.users.onlineEmployees?.find((user) => user.id === userId));

  const userUnavailable = useSelector((state) =>
    state.users.unavailableEmployees?.findLast((employee) => employee.employee_id === user?.id),
  );

  const { data: dismissedEmployees } = useGetEmployeesDismissalsQuery();

  const status = userOnline ? iconOnline : iconOffline;

  const handleAvatarClick = useCallback(() => {
    if (!showProfileOnClick) return;
    if (is_guest && projectType && projectId) {
      return toggleGuestProfileModal(true);
    }
    toggleUserProfileModal(true);
  }, [showProfileOnClick, is_guest, projectId, projectType]);

  const { currentState, currentIcon, PopoverMessage } = useMemo(() => {
    if (dismissedEmployees && dismissedEmployees[userId]) {
      if (moment(today).isAfter(dismissedEmployees[userId].date_dismissed)) {
        return {
          currentState: 'fired',
          currentIcon: iconFired,
          PopoverMessage: (
            <>
              Уволен с{' '}
              <StyledStatusDate>{moment(dismissedEmployees[userId].date_dismissed).format('L')}</StyledStatusDate>
            </>
          ),
        };
      }
      return {
        currentState: 'fired',
        currentIcon: iconLeaving,
        PopoverMessage: (
          <>
            Увольняется с{' '}
            <StyledStatusDate>{moment(dismissedEmployees[userId].date_dismissed).format('L')}</StyledStatusDate>
          </>
        ),
      };
    }

    return userUnavailable &&
      moment(today).isAfter(userUnavailable?.date_from) &&
      moment(today).isBefore(userUnavailable?.date_to)
      ? { currentState: 'unavailable', currentIcon: iconVacation }
      : { currentState: null, currentIcon: null };
  }, [userUnavailable, dismissedEmployees]);

  const { coordinates, elementRef, tooltipRef } = useCoordinates(currentState);

  return (
    <>
      {!user?.avatar ? (
        <StyledMockAvatar
          ref={elementRef}
          onClick={handleAvatarClick}
          size={size}
          status={status}
          state={currentIcon}
        >
          {user?.first_name[0]}
        </StyledMockAvatar>
      ) : (
        <StyledAvatar ref={elementRef} onClick={handleAvatarClick} size={size} status={status} state={currentIcon}>
          <img
            src={`${files_url}/employees/avatar/${user.id}/small_${user.avatar}?token=${auth.token}`}
            alt="pic"
            loading="lazy"
          />

          {!!currentState && (
            <StyledStatusInfo ref={tooltipRef} style={{ ...coordinates }}>
              {currentState === 'fired' && PopoverMessage}
              {/*возможны другие вариации текста (увольняется с..., недоступен с... по...)*/}
              {currentState === 'unavailable'
                ? `Недоступен с ${moment.utc(userUnavailable.date_from).format('L')} по ${moment
                    .utc(userUnavailable.date_to)
                    .format('L')}`
                : undefined}
            </StyledStatusInfo>
          )}
        </StyledAvatar>
      )}

      {userProfileModal && (
        <UserProfileModalContainer profileId={userId} onClose={() => toggleUserProfileModal(false)} />
      )}

      {guestProfileModal && (
        <GuestModalCurrent
          dataType={projectType}
          dataId={projectId}
          guestId={userId}
          projectData={projectData}
          close={() => toggleGuestProfileModal(false)}
        />
      )}
    </>
  );
};
