import { forwardRef } from 'react';

import { StyledButton } from './style';

// color: green | red | light | outlined | outlined-green | secondary (если не добавлять атрибут - кнопка будет синяя)
// width: любая строка с указанием единиц измерения (px, % и тд). В модалках по макету ширина 153px
// если не указывать width, то по дефолту fit-content
// size: если передать значение sm - кнопка будет маленькой (такой вариант встречается в чате и модалках)
export const Button = forwardRef(({ onClick, size, width, color, children, minWidth, ...props }, ref) => {
  return (
    <StyledButton {...props} ref={ref} size={size} width={width} minwidth={minWidth} color={color} onClick={onClick}>
      {children}
    </StyledButton>
  );
});
