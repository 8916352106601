import { SingleSelect } from '@shared/components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

const DepartmentSelect = ({ control, departments }) => {
  const selectOptions = useMemo(() => {
    if (departments) {
      return [...departments?.map((department) => ({ label: department.title, value: department.id }))];
    }
  }, [departments]);

  return (
    <Controller
      name="department"
      control={control}
      render={({ field }) => (
        <SingleSelect
          {...field}
          placeholder="Отдел"
          options={selectOptions}
          value={selectOptions?.find((o) => o.value === field?.value)}
          onChange={(o) => {
            field.onChange(o?.value);
          }}
          isClearable
        />
      )}
    />
  );
};

export default DepartmentSelect;
