import { memo, useCallback, useRef, useState } from 'react';
import { object, func, string, arrayOf } from 'prop-types';

import FilesUploadManage from '@components/files/FilesUploadManage';
import Dropzone from '@shared/components/Dropzone/Dropzone';
import { isFileNameImage } from 'src/utilize/helper-functions';
import DeleteCircleButton from '@shared/components/DeleteCircleButton';
import { Container } from '@shared/components';

import { StyledImage } from './styles';

ImageUploaderFC.propTypes = {
  removeFilesOnClose: object,
  formId: object,
  fileToUpload: arrayOf(object),
  setFileToUpload: func,
  imageLink: string,
  filesUploadPath: string,
  filesDeletePath: string,
};

function ImageUploaderFC({
  removeFilesOnClose,
  formId,
  fileToUpload,
  setFileToUpload,
  imageLink,
  filesUploadPath,
  filesDeletePath,
}) {
  const clearFileManager = useRef();

  const handleDrop = useCallback(
    async (e) => {
      e.preventDefault();
      if (
        !e.dataTransfer.files?.length ||
        !isFileNameImage(e.dataTransfer.files[0].name) ||
        !e.dataTransfer.files[0].size
      ) {
        return;
      }
      if (fileToUpload && clearFileManager.current) clearFileManager.current();
      setFileToUpload([e.dataTransfer.files[0]]);
    },
    [fileToUpload],
  );

  const handleImageSelect = useCallback(
    async (e) => {
      if (!e.target.files?.length) return;
      if (fileToUpload && clearFileManager.current) clearFileManager.current();
      setFileToUpload([e.target.files[0]]);
    },
    [fileToUpload],
  );

  const [hideImage, setHideImage] = useState(false);

  return (
    <>
      {imageLink && !hideImage && (
        <Container justify="center">
          <StyledImage src={imageLink} alt="pic" />
          <DeleteCircleButton type="button" onClick={() => setHideImage(true)} />
        </Container>
      )}
      {(!imageLink || hideImage) && (
        <Dropzone
          onDrop={handleDrop}
          description="Перетащите или выберите изображение"
          inputAccept="image/*"
          handleFilesSelect={handleImageSelect}
        />
      )}
      <FilesUploadManage
        removeFilesOnClose={removeFilesOnClose}
        clearFileManager={clearFileManager}
        formId={formId.current}
        filesUploadPath={filesUploadPath}
        filesDeletePath={filesDeletePath}
        notMultiple
        filesToUpload={fileToUpload}
      />
    </>
  );
}

const ImageUploader = memo(ImageUploaderFC);

export default ImageUploader;
