import {useState} from 'react';
import useAuth from 'src/hooks/useAuth';
import {Avatar} from '@shared/components/Avatar';
import LocalDateTime from '@components/shared/LocalDateTime';
import {renderMessage} from 'src/utilize/draftjs-helper-functions';
import AttachedFile from '@components/files/AttachedFile';
import {useTranslation} from 'react-i18next';
import {useFormatMessageFiles, useNavigateToMessage} from 'src/utilize/message-helper-functions';
import ImageGallery from 'src/windows/images/ImageGallery';
import {StyledResultStatus} from "@components/chat/message/styles";
import {
  StyledResultDate, StyledResultStatusWrapper,
  StyledResultTask, StyledResultText, StyledTagsWrapper,
  StyledUsername,
  StyledUserWrapper
} from "@components/chat/side-menu/sideMenuComponents/styles";
import {Container} from "@shared/components";
import Tag from "@shared/components/Tag/Tag";

const ResultMessageAside = ({chat_message, showUserProfile, dataType, dataId}) => {
  const auth = useAuth();
  const {t} = useTranslation();
  const {
    employee_id,
    employee_first_name,
    employee_last_name,
    date_created,
    editor_state,
    message_files,
    message_tags,
    id,
    chat_goal_results,
  } = chat_message;

  const {allAttachedFiles, gallerySourcesArr} = useFormatMessageFiles(message_files, id, auth?.token);
  const [openImgId, setOpenImgId] = useState(null);

  // при клике на сообщение, идет навигация на это сообщение
  const navigateToMessage = useNavigateToMessage(dataType, dataId, id);

  return (
    <>
      <StyledResultTask onClick={navigateToMessage}>
        <StyledResultStatusWrapper>
          {chat_goal_results[0].result === 'wait' &&
            <StyledResultStatus status="pending">{t('Goals.pending')}</StyledResultStatus>}
          {chat_goal_results[0].result === 'ok' && <StyledResultStatus status="accept">Принят</StyledResultStatus>}
          {chat_goal_results[0].result === 'declined' &&
            <StyledResultStatus status="decline">Не принят</StyledResultStatus>}
        </StyledResultStatusWrapper>

        {message_tags.length > 0 && (
          <StyledTagsWrapper>
            {message_tags.map((tag, i) => (
              <Tag tag={tag} key={i}/>
            ))}
          </StyledTagsWrapper>
        )}

        <StyledUserWrapper
          $isGuest={auth?.user?.is_guest}
          onClick={(e) => {
            e.stopPropagation();
            if (!auth?.user?.is_guest) showUserProfile(employee_id);
          }}
        >
          <Avatar
            userId={employee_id}
            lastName={employee_last_name}
            firstName={employee_first_name}
            showProfileOnClick={false}
          />
          <StyledUsername>{`${employee_first_name || ''} ${employee_last_name || ''}`}</StyledUsername>
        </StyledUserWrapper>

        <StyledResultDate>
          <LocalDateTime dateTime={date_created}/>
        </StyledResultDate>


        <StyledResultText>{renderMessage(editor_state)}</StyledResultText>

        {allAttachedFiles?.length > 0 &&
          <Container wrap="wrap" gap="8px" align="center" onClick={(e) => e.stopPropagation()}>
            {allAttachedFiles.map((file, i) => (
              <AttachedFile
                fileUrl={file.url}
                setOpenImgId={setOpenImgId}
                fileName={file.file}
                fileId={file.id}
                key={i}
                storageFileId={file.from_storage}
              />
            ))}
          </Container>
        }
      </StyledResultTask>

      {openImgId && gallerySourcesArr?.length && (
        <ImageGallery imgItems={gallerySourcesArr} openImgId={openImgId} close={() => setOpenImgId(null)}/>
      )}
    </>
  );
};

export default ResultMessageAside;
