import { Title } from '@shared/components/Title/Title';
import styled from 'styled-components';

export const StyledTitle = styled(Title)`
  padding: 0;
  margin-bottom: 18px;
`;

export const StyledList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledListItem = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTruncatedText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${({ rows }) => rows};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Дополнительные свойства для кросс-браузерной поддержки в Сафари*/
  max-height: ${({ rows }) => rows * 22}px; /* Высота, соответствующая указанным в rows строкам текста */
  line-height: 22px; /* Высота одной строки текста */
`;

export const StyledLink = styled.a`
  transition: color 0.3s;

  &:hover {
    color: ${(props) => (props.hover ? props.hover : '#1890ff')};
  }
`;
