import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const ThreeDotsLoader = ({ visible, style, height, width }) => {
  let loader = {
    Component: ThreeDots,
    props: {
      color: '#1890FF',
      height: height || 30,
      width: width || 30,
    },
    name: 'LineWave',
  };

  return (
    <span style={{ ...style, visibility: `${visible ? 'visible' : 'hidden'}` }}>
      <loader.Component {...loader.props} />
    </span>
  );
};

export default ThreeDotsLoader;
