import {useDispatch} from 'react-redux';
import {rtkQueryApi} from 'src/redux/features/api/rtkQueryApi';
import {Link} from 'react-router-dom';
import {Text} from '@shared/components/Text/Text';
import {Container} from '@shared/components/Container';
import {useSocketContext} from 'src/contexts/SocketContext';
import {removeNotification} from 'src/redux/features/notificationsSlice';
import {showLocalDateTime} from 'src/utilize/helper-functions';
import {Avatar} from "@shared/components/Avatar";
import {formatDate} from "@shared/components/CalendarForm/CalendarForm";

import {notificationTypes} from '../notifications.types';
import {ProjectCardUsername} from "../../../pages/Projects/project-list/project-list-container/style";

import {Card, StyledDate, StyledLink, Title} from './styles';

export const NotificationCard = ({notification, status}) => {
  const dispatch = useDispatch();
  const {readNotification} = useSocketContext();

  const readNoteCallback = (data) => {
    if (data?.status === 'ok') {
      dispatch(removeNotification(notification?.id));
      dispatch(rtkQueryApi.util.invalidateTags(['openedNotifications']));
    }
  };

  const requestTemplates = {
    request_created: (
      <>
        К вам обратились с просьбой: <Title>{notification?.task_title}</Title>
      </>
    ),
    request_executor_changed: (
      <>
        Первоначальный исполнитель просьбы <Title>{notification?.task_title}</Title> назначил нового
        исполнителя вместо себя.
      </>
    ),
    executor_changed_request_deadline: (
      <>
        Новый дедлайн просьбы <Title>{notification?.task_title}</Title> - {formatDate(notification?.date_finish)}
      </>
    ),
    responsible_changed_request_deadline: (
      <>
        Новый дедлайн просьбы <Title>{notification?.task_title}</Title> - {formatDate(notification?.date_finish)}
      </>
    ),
    request_took_on: (
      <>
        Просьба <Title>{notification?.task_title}</Title> принята в работу
      </>
    ),
    request_on_clarification: (
      <>
        Исполнитель обратился к вам за уточнением просьбы: <Title>{notification?.task_title}</Title>
      </>
    ),
    request_under_review: (
      <>
        Исполнитель уведомляет о выполнении просьбы: <Title>{notification?.task_title}</Title>
      </>
    ),
    request_accepting_redirect_executor: (
      <>
        Автор просьбы <Title>{notification?.task_title}</Title>
        перенаправил результат исполнения просьбы на проверку другому сотруднику.
      </>
    ),
    request_accepting_redirect_responsible: (
      <>
        Вас попросили проверить выполнение просьбы <Title>{notification?.task_title}</Title>
      </>
    ),
    request_clarified: (
      <>
        Автор уточнил просьбу <Title>{notification?.task_title}</Title>
      </>
    ),
    request_for_revision: (
      <>
        Автор просьбы <Title>{notification?.task_title}</Title> вернул вам ее на доработку
      </>
    ),
    request_canceled: (
      <>
        Автор просьбы <Title>{notification?.task_title}</Title> отменил ее
      </>
    ),
    request_accepted: (
      <>
        Автор принял результат выполнения просьбы <Title>{notification?.task_title}</Title>
      </>
    ),
    request_returned: (
      <>
        Автор просьбы <Title>{notification?.task_title}</Title> вернул ее в работу.
      </>
    ),

    request_executer_dismissed: (
      <>
        Уволенный сотрудник {`${notification?.executer?.first_name} ${notification?.executer?.last_name}`} не завершил просьбу <Title>{notification?.task_title}</Title>
      </>
    ),
    executor_overdue_request: (
      <>
        Ваш сотрудник {`${notification?.executer?.first_name} ${notification?.executer?.last_name}`} не успел завершить просьбу <Title>{notification?.task_title}</Title> в срок
      </>
    ),
    request_stuck_on_clarification: (
      <>
        Ваш сотрудник {`${notification?.responsible?.first_name} ${notification?.responsible?.last_name}`} долго не уточняет просьбу <Title>{notification?.task_title}</Title>
      </>
    ),
    request_stuck_under_review: (
      <>
        Ваш сотрудник {`${notification?.responsible?.first_name} ${notification?.responsible?.last_name}`} долго не принимает просьбу <Title>{notification?.task_title}</Title>
      </>
    ),
  };

  return (
    <Card>
      <Container width="100%" justify="space-between" align={'start'}>
        {notification.data_type.includes('request') ? (
            <Container direction="column">
              <>
                <Text size={1} style={{marginBottom: '12px'}}>{notificationTypes[notification.data_type]?.text}</Text>
                <StyledLink
                  to={notification.link}
                  onClick={() => {
                    if (status === 'opened') return;
                    readNotification([notification.id], readNoteCallback);
                  }}
                >
                  <Container align="center" gap="8px">
                    {requestTemplates[notification.data_type]}
                  </Container>
                </StyledLink>

                {notification.responsible && (
                  <Container direction="column" gap={'4px'}>
                    Автор просьбы:
                    <Container align={'center'}>
                      <Avatar
                        userId={notification.responsible.id}
                        firstName={notification.responsible.first_name}
                        lastName={notification.responsible.last_name}
                      />
                      <ProjectCardUsername>
                        {`${notification.responsible.first_name || ''} ${notification.responsible.last_name || ''}`}
                      </ProjectCardUsername>
                    </Container>
                  </Container>
                )}
              </>
            </Container>
          )
          : (
            <Link
              to={notification.link}
              onClick={() => {
                if (status === 'opened') return;
                readNotification([notification.id], readNoteCallback);
              }}
            >
              <Container direction="column" gap={'4px'}>
                <Text size="2">{notificationTypes[notification.data_type]?.text}</Text>
                <Container align="center">
                  <Title>
                    {notification.project_title ||
                      notification.task_title ||
                      notification.ticket_title ||
                      `${notification.employee_last_name || ''} ${notification.employee_first_name}`}
                  </Title>
                </Container>
              </Container>
            </Link>
          )
        }
        <StyledDate>
          <Text as="span" size={2}>
            {showLocalDateTime(notification.date_update, null, true)}
            {/* {moment(notificationDate).format('L')} {moment(notificationDate).subtract('03:00:00').format('LT')} */}
          </Text>
        </StyledDate>
      </Container>
    </Card>
  );
};
