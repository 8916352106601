import { MutatingDots } from 'react-loader-spinner';
import { StyledPreloader } from '@components/preloaders/styles';

const Preloader = () => {
  let loader = {
    Component: MutatingDots,
    props: {
      color: '#1890FF',
      secondaryColor: '#BAE7FF',
      height: 100,
      width: 100,
    },
    name: 'MutatingDots',
  };

  return (
    <StyledPreloader>
      <loader.Component {...loader.props} />
    </StyledPreloader>
  );
};

export default Preloader;
