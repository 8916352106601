import { Container, Input, Label, Modal, Text } from '@shared/components';
import React, { useContext, useRef, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { randomSequence } from 'src/utilize/helper-functions';

import { useCreateLinkMutation } from 'src/redux/features/api/links';

import SnackbarContext from 'src/contexts/SnackbarContext';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { t } from 'i18next';

import { radioButtonsType } from '../EditLinkModal/lib';

import { LinkTypeSelect } from './ui/LinkTypeSelect';
import { AccessRadioButtons } from './ui/AccessRadioButtons';
import UsersSelect from './ui/UsersSelect';
import DepartmentsSelect from './ui/DepartmentsSelect';
import { FileLoader } from './ui/FileLoader';
import { linksType, schema } from './lib';

export const AddLinkModal = ({ onClose }) => {
  const {
    control,
    handleSubmit,
    watch,
    resetField,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [formId, setFormId] = useState(randomSequence());
  const [selectedRadio, setSelectedRadio] = useState(radioButtonsType.all);

  const [confirmModal, setConfirmModal] = useState(false);

  const isFileUploadTab = watch('type')?.value === linksType.file;

  const getUploadedFiles = useRef();
  const removeFilesOnClose = useRef();

  const [createLink, { isLoading, isError }] = useCreateLinkMutation();

  const { showSnackbar } = useContext(SnackbarContext);

  const onRemoveFilesHandler = async () => {
    const onRemove = removeFilesOnClose.current;

    if (onRemove) {
      await onRemove();
    }
  };

  const onReset = () => {
    resetField('departments');
    resetField('employees');
  };

  const transformFormData = (data) => {
    return {
      row: {
        type: !!data.type?.value.length ? data.type.value : 'out',
        title: data.title,
        link: data.url,
        is_public: selectedRadio === radioButtonsType.all,
      },
      form_id: !!isFileUploadTab ? formId : null, // if type: 'file'
      departments: data.departments?.length ? data?.departments?.map((option) => option.value) : [], // if is_public:false
      employees: data.employees?.length ? data?.employees?.map((option) => option.value.id) : [], // if is_public:false
    };
  };

  const onSubmit = async (data) => {
    const uploadedFiles = getUploadedFiles.current ? getUploadedFiles.current() : [];

    if (!uploadedFiles.length && isFileUploadTab) {
      return showSnackbar('Необходимо прикрепить файл', 'error');
    }

    await createLink(transformFormData(data)).then(() => {
      showSnackbar('Ссылка успешно создана', 'success');
      onClose();
    });
  };

  const onCloseHadler = () => {
    onRemoveFilesHandler();
    onClose();
  };

  return (
    <Modal
      title="Создание ссылки"
      modalSize="960px"
      onClose={() => {
        if (isDirty) {
          setConfirmModal(true);
          return;
        }

        onCloseHadler();
      }}
      onSave={handleSubmit(onSubmit)}
      confirmButtonText={'Создать'}
    >
      <div>
        <Label>Название</Label>
        <Controller
          name="title"
          control={control}
          render={({ field }) => <Input {...field} placeholder="Введите название ссылки" />}
        />
        <Text color={'red'} size={1}>
          {errors.title?.message}
        </Text>
      </div>

      <div>
        <LinkTypeSelect control={control} />
        <Text color={'red'} size={1}>
          {errors.type?.message}
        </Text>
      </div>

      {isFileUploadTab && (
        <FileLoader removeFilesOnClose={removeFilesOnClose} getUploadedFiles={getUploadedFiles} formId={formId} />
      )}

      {!isFileUploadTab && (
        <div>
          <Label>Ссылка</Label>
          <Controller
            name="url"
            control={control}
            render={({ field }) => <Input {...field} placeholder="http://example.com" />}
          />
          {errors.url && (
            <Container gap={'4px'}>
              <Text color={'red'} size={1}>
                {errors.url.message}
              </Text>
            </Container>
          )}
        </div>
      )}

      <AccessRadioButtons
        control={control}
        selectedRadio={selectedRadio}
        setSelectedRadio={setSelectedRadio}
        onReset={onReset}
      />

      {selectedRadio === radioButtonsType.users && <UsersSelect control={control} />}
      {selectedRadio === radioButtonsType.departments && <DepartmentsSelect control={control} />}

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onCloseHadler}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </Modal>
  );
};
