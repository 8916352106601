import { Label } from '@shared/components';
import { SingleSelect } from '@shared/components/Select/Select';

import { useController } from 'react-hook-form';

export const LinkTypeSelect = ({ control }) => {
  const options = [
    {
      label: 'Внешняя',
      value: 'out',
    },
    {
      label: 'Внутренняя',
      value: 'in',
    },
    {
      label: 'На файл',
      value: 'file',
    },
  ];

  const { field } = useController({ name: 'type', control });

  return (
    <div>
      <Label>Тип ссылки</Label>

      <SingleSelect {...field} placeholder="Выберите тип ссылки" options={options} />
    </div>
  );
};
