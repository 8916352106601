import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Avatar } from '@shared/components/Avatar';
import ConfirmAction from '@components/warnings/ConfirmAction';
import {
  StyledVacationItem,
  StyledVacationLine,
  StyledVacationName,
  StyledVacationPosition,
  StyledVacationText,
  StyledVacationUser,
} from '@components/employees/vacations/styles';
import { IconButton } from '@shared/components/IconButton/IconButton';
import iconCancel from '@assets/images/icons/vacation-cancel.svg';
import iconConfirm from '@assets/images/icons/vacation-confirm.svg';

// import iconChat from '@assets/images/icons/vacation-chat.svg';
import { processVacationRequest } from '../../../redux/features/usersSlice';

const VacationItem = (props) => {
  const dispatch = useDispatch();

  const [confirmAction, setConfirmAction] = useState();

  return (
    <>
      <StyledVacationItem colSpan={props.colSpan} $firstItem={props.firstItem} $isLastItem={props.isLastItem}>
        <StyledVacationLine $approved={props.approved} width="100%">
          {!props.approved ? (
            <>
              {!props.hideRequestText && <StyledVacationText>Запрос</StyledVacationText>}
              <IconButton size={14} icon={iconConfirm} onClick={() => setConfirmAction('yes')}></IconButton>
              <IconButton size={14} icon={iconCancel} onClick={() => setConfirmAction('no')}></IconButton>
              {/* <span>
              <IconButton size={14} icon={iconChat} onClick={() => {}}></IconButton> (2)
            </span> */}
            </>
          ) : (
            ''
            // <IconButton size={14} icon={iconCancel} onClick={() => {}}></IconButton>
          )}
        </StyledVacationLine>

        <StyledVacationUser $firstItem={props.firstItem} $isLastItem={props.isLastItem}>
          <Avatar userId={props.userId} size={24} showProfileOnClick />
          <StyledVacationName>
            {`${props.vacationData.last_name || ''} ${props.vacationData.first_name || ''}`},
            <StyledVacationPosition>{props.vacationData.position}</StyledVacationPosition>
          </StyledVacationName>
        </StyledVacationUser>
      </StyledVacationItem>

      {confirmAction && (
          <ConfirmAction
            actionText={confirmAction === 'yes' ? 'Одобрить отпуск?' : 'Отклонить запрос на отпуск?'}
            confirm={() =>
              dispatch(processVacationRequest({ decision: confirmAction, vacationId: props.vacationData.id }))
            }
            cancel={() => setConfirmAction(null)}
          />
      )}
    </>
  );
};

export default VacationItem;
