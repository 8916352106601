import React, { useRef, useState } from 'react';

import FilesUploadManage from '@components/files/FilesUploadManage';

import { Container } from '@shared/components';

import { useController } from 'react-hook-form';

import { DndWrapper, FilesContainer } from './styles';

export const FileLoader = ({ control, removeFilesOnCloseRef, formId, getUploadedFiles }) => {
  const [drag, setDrag] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isFilesUploading, setIsFilesUploading] = useState();

  const clearFileManager = useRef();
  const inputRef = React.useRef();

  const { field } = useController({ name: 'files', control });

  const dragStartHanlder = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const onDropHadler = (e) => {
    e.preventDefault(e);
    clearFileManager.current();

    field.onChange(() => setFilesToUpload([...e.dataTransfer.files]));
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      clearFileManager.current();

      field.onChange(() => setFilesToUpload([...e.target.files]));
    }
  };

  return (
    <Container direction="column">
      <DndWrapper
        onDragStart={(e) => dragStartHanlder(e)}
        onDragOver={(e) => dragStartHanlder(e)}
        onDragLeave={(e) => dragLeaveHandler(e)}
        onDrop={(e) => onDropHadler(e)}
        isDrag={drag}
        onClick={() => {
          inputRef.current.click();
        }}
      >
        Загрузите файл
        <input ref={inputRef} type="file" multiple={false} onChange={handleChange} />
      </DndWrapper>

      <FilesContainer>
        <FilesUploadManage
          formId={formId}
          filesToUpload={filesToUpload}
          filesUploadPath="/api/link_file/add"
          filesDeletePath="/api/link_file/remove"
          setIsFilesUploading={setIsFilesUploading}
          removeFilesOnClose={removeFilesOnCloseRef}
          clearFileManager={clearFileManager}
          getUploadedFiles={getUploadedFiles}
          removeById
          notMultiple
        />
      </FilesContainer>
    </Container>
  );
};
