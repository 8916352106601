import { IconButton } from '@shared/components';
import styled from 'styled-components';

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: -20px;
  top: 0;

  &:hover {
    filter: brightness(0) saturate(100%) invert(20%) sepia(99%) saturate(4859%) hue-rotate(349deg) brightness(102%)
      contrast(92%);
  }
`;

export const StyledImageContainer = styled.div`
  width: 150px;
  margin-top: 10px;
  position: relative;
`;

export const StyledImage = styled.img`
  border-radius: 2px;
  max-width: 150px;
  width: 100%;
`;
