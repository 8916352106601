import SelectMulty from '@components/form/select/SelectMulty';
import { Subtitle } from '@shared/components';
import { useMemo } from 'react';

const selectData = {
  name: 'tags',
};

const TagsMultySelect = ({ control, tags }) => {
  const options = useMemo(() => {
    return tags?.map((t) => ({ label: t.name, value: t.id }));
  }, [tags]);

  return (
    <div>
      <Subtitle>Тэги</Subtitle>
      <SelectMulty item={selectData} control={control} options={options} />
    </div>
  );
};

export default TagsMultySelect;
