import { rtkQueryApi } from './rtkQueryApi';

export const linksApi = rtkQueryApi.injectEndpoints({
  tagTypes: ['Links'],
  endpoints: (builder) => ({
    getLinks: builder.query({
      query: () => '/api/links',
      providesTags: ['Links'],
    }),
    getLinkById: builder.query({
      query: ({ id }) => `/api/link_edit/${id}`,
      providesTags: ['Links'],
    }),
    createLink: builder.mutation({
      query: (body) => ({
        url: '/api/links/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Links'],
    }),
    editLink: builder.mutation({
      query: (body) => ({
        url: '/api/links/edit',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Links'],
    }),
    deleteLink: builder.mutation({
      query: (body) => ({
        url: '/api/links/delete',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Links'],
    }),
  }),
});

export const {
  useGetLinksQuery,
  useCreateLinkMutation,
  useDeleteLinkMutation,
  useGetLinkByIdQuery,
  useEditLinkMutation,
} = linksApi;
