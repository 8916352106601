// import HTMLReactParser from 'html-react-parser';
import { removeParagraphTags, renderHTMLString, transform } from 'src/utilize/draftjs-helper-functions';
import { ExternalLink } from '@shared/components';

export const contactTypes = {
  client: 'Клиент',
  partner: 'Партнер',
  client_partner: 'Клиент и партнер',
};

export const formatAdditionalInfo = (infoField) => {
  if (!infoField) return null;
  switch (infoField.type) {
    case 'string':
      return infoField.value;
    case 'text':
      // return HTMLReactParser(infoField.value);
      return renderHTMLString(removeParagraphTags(infoField.value), { replace: (node) => transform(node) });
    case 'link':
      return (
        <ExternalLink
          href={infoField.value.indexOf('http') === 0 ? infoField.value : `//${infoField.value}`}
          target="_blank"
          rel="noreferrer"
        >
          {infoField.value}
        </ExternalLink>
      );
    case 'list':
      return infoField.value.reduce((stringToRender, listItem, i, array) => {
        stringToRender += `${listItem.value}${i === array.length - 1 ? '' : ','} `;
        return stringToRender;
      }, '');

    default:
      return null;
  }
};
