import { useEffect, useContext, useCallback } from 'react';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import VacationsList from '@components/employees/vacations/VacationsList';
import VacationsTimetable from '@components/employees/vacations/VacationsTimetable';
import { getVacationsList } from 'src/redux/features/usersSlice';
import Preloader from '@components/preloaders/Preloader';

const Vacations = () => {
  const { showSnackbar } = useContext(SnackbarContext);
  const { vacationsList, isLoadingVacations, vacationsLoadingError } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const getVacationsData = useCallback(() => {
    dispatch(getVacationsList());
  }, []);

  useEffect(() => {
    getVacationsData();
  }, [getVacationsData]);

  useEffect(() => {
    if (vacationsLoadingError) {
      showSnackbar(vacationsLoadingError);
    }
  }, [vacationsLoadingError]);

  return (
    <main>
      {isLoadingVacations ? (
        <Preloader />
      ) : (
        <Routes>
          <Route
            path="/"
            element={<VacationsList employeeVacations={vacationsList} getVacationsData={getVacationsData} />}
          />

          <Route path="timetable" element={<VacationsTimetable employeeVacations={vacationsList} />} />
        </Routes>
      )}
    </main>
  );
};

export default Vacations;
