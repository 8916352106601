import styled from 'styled-components';
import HTMLReactParser from "html-react-parser";
import Tag from "@shared/components/Tag/Tag";
import {StyledStorageDescription, TagsWrapper} from "@components/storage/styles";
import {useCallback, useRef, useState} from "react";
import {Button, IconButton} from "@shared/components";
import iconInfo from "@assets/images/icons/icon-info.svg";

import {useOutsideTrigger} from "../../utilize/helper-functions";

const StorageProjectTitle = styled.div`
  font-size: 14px;
  margin-bottom: 14px;

  & span {
    font-weight: 600;
  }
`;

const StorageDescription = ({storageData, setEditStorageModal}) => {
  const [showDescription, setShowDescription] = useState(false);

  const descriptionBtn = useRef();
  const descriptionPopup = useRef();
  const refsArray = useRef([descriptionBtn, descriptionPopup]);
  const outsideClickEvent = useCallback(() => setShowDescription(false), []);
  useOutsideTrigger(refsArray.current, outsideClickEvent, showDescription);

  if (
    !storageData ||
    ((typeof storageData?.storage?.description !== 'string' || storageData.storage.description.length === 0) &&
      (typeof storageData.project_description !== 'string' || storageData.project_description.length === 0) &&
      !storageData.can_edit)
  ) {
    return '';
  }

  return (
    <>
      <IconButton
        ref={descriptionBtn}
        size={20}
        icon={iconInfo}
        onClick={() => setShowDescription(!showDescription)}
      />

      <StyledStorageDescription $active={showDescription} ref={descriptionPopup}>
        {storageData?.storage?.project_id && storageData.project_title && (
          <StorageProjectTitle>
            К проекту: <span>{` ${storageData.project_title}`}</span>
          </StorageProjectTitle>
        )}
        {storageData?.storage?.description ||
          (typeof storageData?.project_description === 'string'
            ? <div>{HTMLReactParser(storageData?.project_description)}</div>
            : '')}

        {storageData?.storage.storage_tags.length > 0 && (
          <TagsWrapper>
            {storageData?.storage.storage_tags.map((tag, i) => (
              <Tag tag={tag} key={i}/>
            ))}
          </TagsWrapper>
        )
        }

        {storageData?.can_edit && (
          <Button
            width="100%"
            size="sm"
            onClick={() => setEditStorageModal(true)}
          >
            Редактировать
          </Button>
        )}
      </StyledStorageDescription>
    </>
  );
};

export default StorageDescription;
