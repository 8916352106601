import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '@shared/components/Avatar';
import iconArrow from '@assets/images/icons/arrow.svg';
import { Separator } from '@shared/components';

import NavListItemInstallApp from './NavListItemInstallApp/NavListItemInstallApp';
import { SiteInnerLink, StyledUserNavInnerList, StyledUserNavItem, StyledUserNavProfile, Username } from './styles';

const UserNavListItem = ({ id, handler, isOpen, linkList, profile, userId, firstName, lastName }) => {
  return (
    <StyledUserNavItem key={id}>
      {/* закрыть список */}
      <div onClick={() => handler(id)}>
        {profile && (
          <StyledUserNavProfile icon={iconArrow}>
            <Avatar userId={userId} showProfileOnClick={false}/>
          </StyledUserNavProfile>
        )}
      </div>

      <StyledUserNavInnerList $active={isOpen === id}>
        <>
          <Username size={2}>
            {firstName} {lastName}
          </Username>
          {Object.keys(linkList).map((oneKey, i) => {
            if (linkList[oneKey].handler) {
              return (
                <Fragment key={i}>
                  <SiteInnerLink
                    onClick={() => {
                      linkList[oneKey].handler();
                    }}
                  >
                    {oneKey}
                  </SiteInnerLink>
                  {linkList[oneKey].separator && <Separator />}
                </Fragment>
              );
            } else {
              return (
                <SiteInnerLink as={Link} to={linkList[oneKey].to} key={linkList[oneKey]}>
                  {oneKey}
                </SiteInnerLink>
              );
            }
          })}
          <NavListItemInstallApp />
        </>
      </StyledUserNavInnerList>
    </StyledUserNavItem>
  );
};
export default UserNavListItem;
