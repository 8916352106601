import { StyledPollChart, StyledPollChartBg, StyledPollChartProgress } from './styles';

const PollOptionChart = ({ percent }) => {
  return (
    <StyledPollChart viewBox="0 0 36 36">
      <StyledPollChartBg
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      ></StyledPollChartBg>
      <StyledPollChartProgress
        stroke-dasharray={`${percent}, 100`}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      ></StyledPollChartProgress>
      <text x="18" y="22" className="chart__value">
        {`${percent}%`}
      </text>
    </StyledPollChart>
  );
};

export default PollOptionChart;
