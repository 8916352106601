import Resumable from 'resumablejs';
import useAuth from 'src/hooks/useAuth';
import { server_url } from 'src/settings/base-url';

import { useContext } from 'react';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { getQueryData } from './lib';

export const useResumableForFiles = ({ storageId, folderId, setFilesToUpload, isLoadingCompleteRef, onClose }) => {
  const { token } = useAuth();

  const { showSnackbar } = useContext(SnackbarContext);

  const queryData = getQueryData({ storageId, folderId });

  const resumable = new Resumable({
    target: `${server_url}/api/storage_file/add?token=${token}`,
    testTarget: `${server_url}/api/storage_file/test_chunk?token=${token}`,
    testChunksParams: { ...queryData },
    chunkSize: 2 * 1024 * 1024,
    throttleProgressCallbacks: 100,
    query: queryData,
    simultaneousUploads: 1,
  });

  resumable.on('fileProgress', (resumableFile) => {
    const currentProgress = parseInt(Math.round(resumableFile.progress() * 100));
    setFilesToUpload((files) =>
      files.map((file) => {
        if (file.uniqueIdentifier === resumableFile.uniqueIdentifier) {
          file.progress = currentProgress;
          file.status = 'loading';
          return file;
        }
        return file;
      }),
    );
  });

  resumable.on('fileSuccess', (resumableFile) => {
    const successChunk = resumableFile.chunks.find((chunk) => {
      return String(chunk.message()).includes('insertId');
    });

    if (successChunk) {
      const insertId = JSON.parse(successChunk.message())?.insertId;

      setFilesToUpload((files) =>
        files.map((file) => {
          if (file.uniqueIdentifier === resumableFile.uniqueIdentifier) {
            file.status = 'done';
            file.localId = insertId;

            return file;
          }

          return file;
        }),
      );
    }
  });

  resumable.on('complete', function () {
    isLoadingCompleteRef.current.isFilesLoading = true;
  });

  resumable.on('error', function (message, file) {
    showSnackbar('Возникла ошибка при загрузке файлов', 'error');
  });

  return resumable;
};
