import styled, { css } from 'styled-components';

export const StyledButtonsWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 40px;
  flex-wrap: wrap;
  hyphens: none;

  ${({ $threeColumns }) =>
    $threeColumns &&
    css`
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px 15px;
    `}

  @media (max-width: 420px) {
    grid-template-columns: 1fr;
  }

  & > * {
    width: 100%;
  }
`;
