import { useFieldArray, useFormState } from 'react-hook-form';
import { StyledButtonPlus } from '@components/form/select/styles';
import { Container, Label } from '@shared/components';
import plusIcon from '@assets/images/icons/plus.svg';
import { memo } from 'react';

import PollOptionField from './PollOptionField/PollOptionField';

export const PollAnswers = memo(PollAnswersFC);

function PollAnswersFC({ control, register, getValues, setValue }) {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'poll_answers',
  });

  const { errors } = useFormState({ control, name: 'poll_answers' });

  return (
    <div>
      <Container gap="1rem">
        <Label>
          Варианты ответа <sup>*</sup>
        </Label>
        <StyledButtonPlus icon={plusIcon} onClick={() => append({ value: '' })} />
      </Container>
      {/* <IconButton icon={plusIcon}  /> */}
      <ol style={{ paddingLeft: '20px', listStyle: 'decimal' }}>
        {fields.map((field, index) => {
          return (
            <PollOptionField
              field={field}
              index={index}
              key={index}
              register={register}
              remove={remove}
              control={control}
              error={Boolean(errors?.poll_answers?.[index])}
              update={update}
              getValues={getValues}
              setValue={setValue}
            />
          );
        })}
      </ol>
    </div>
  );
}
