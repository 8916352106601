import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import TransferCopyFilesModal from '@components/storage/TransferCopyFilesModal';
import { getFileSize } from '@shared/hooks/useDownloader';

import SnackbarContext from 'src/contexts/SnackbarContext';
import dotsIcon from 'src/assets/images/icons/subtask-dots.svg';

import { FileMenu, FileMenuBtn, FileMenuContainer, MenuItem } from './styles';

AttachedFileMenu.propTypes = {
  contextMenu: PropTypes.array,
  fileData: PropTypes.shape({
    fileId: PropTypes.number,
    fileUrl: PropTypes.string,
    fileName: PropTypes.string,
    creator_id: PropTypes.number,
  }),
};

function AttachedFileMenu({ contextMenu, fileData }) {
  const [active, setActive] = useState(false);
  const [transferFileData, setTransferFileData] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);

  return (
    <>
      <FileMenuContainer onBlur={() => setActive(false)}>
        <FileMenuBtn icon={dotsIcon} onClick={() => setActive((prev) => !prev)} $active={active} />
        <FileMenu $active={active}>
          {contextMenu.includes('storageTransfer') && (
            <MenuItem
              onClick={async () => {
                setActive(false);
                try {
                  const { data } = await getFileSize(fileData.fileUrl);
                  setTransferFileData({
                    id: fileData.fileId,
                    filePath: fileData.fileUrl,
                    title: fileData.fileName,
                    creator_id: fileData.creator_id,
                    size: data.size,
                  });
                  setActive(false);
                } catch {
                  showSnackbar('Возникла ошибка при определении размера файла');
                  return;
                }
              }}
            >
              Скопировать в хранилище
            </MenuItem>
          )}
        </FileMenu>
      </FileMenuContainer>

      {transferFileData && (
          <TransferCopyFilesModal
            close={() => setTransferFileData(null)}
            type="file"
            data={transferFileData}
            fileUrl={fileData.fileFullUrl}
            mode="fromChat"
          />
      )}
    </>
  );
}

export default AttachedFileMenu;
