import { Modal, Text } from '@shared/components';

export const RemoveReminderModal = ({ onSave, onClose }) => {
  return (
      <Modal
        type={'delete'}
        modalSize="548px"
        onSave={onSave}
        onClose={onClose}
        confirmButtonText={'Да'}
        cancelButtonText={'Нет'}
      >
        <Text style={{ textAlign: 'center' }} size={3} tag="p">
          Вы уверены, что хотите удалить напоминание?
        </Text>
      </Modal>
  );
};
