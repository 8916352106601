import { ButtonWithIcon, Container, Tabs } from '@shared/components';
import { StyledLayout } from '@shared/components/Layout/styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import iconAdd from '@assets/images/icons/plus.svg';

import { useDeleteReminderMutation, useGetRemindersQuery } from 'src/redux/features/api/reminders';

import SnackbarContext from 'src/contexts/SnackbarContext';

import { CreateReminderModal, EditReminderModal, RemoveReminderModal } from '@components/Reminders';

import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { deleteReminder } from 'src/redux/features/remindersSlice';

import { StyledTitle, StyledTitleWrapper, TabsContainer } from './styles';
import { ReminderCard } from './ReminderCard';

const PageReminders = () => {
  const displayedReminders = useSelector((state) => state.allReminders.displayedReminders);

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [currentReminder, setCurrentReminder] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);

  const reminderId = useMemo(() => searchParams.get('id'), [searchParams]);

  const currentFilter = useMemo(() => searchParams.get('filter'), [searchParams]);

  const { showSnackbar } = useContext(SnackbarContext);

  const tabButtons = [
    { name: 'Непрочитанные', key: 'unread' },
    { name: 'Отмеченные прочитанными', key: 'read' },
  ];

  const { data } = useGetRemindersQuery();
  const [onDelete] = useDeleteReminderMutation();

  const dispatch = useDispatch();

  const onReset = () => {
    searchParams.delete('id');
    setSearchParams(searchParams, { replace: true });
    setEditModalVisible(false);
    setCurrentReminder({});
  };

  useEffect(() => {
    setSearchParams({ filter: tabButtons[0].key }, { replace: true });
  }, []);

  useEffect(() => {
    reminderId ? setEditModalVisible(true) : setEditModalVisible(false);
  }, [reminderId]);

  return (
    <>
      <StyledLayout>
        <StyledTitleWrapper>
          <StyledTitle>Напоминания</StyledTitle>
          <ButtonWithIcon
            color="green"
            position="left"
            icon={iconAdd}
            size={20}
            onClick={() => setCreateModalVisible(true)}
          >
            Создать
          </ButtonWithIcon>
        </StyledTitleWrapper>
        <TabsContainer>
          <Tabs
            buttons={tabButtons}
            defaultValue={tabButtons[0]}
            onChange={(tab) => {
              setSearchParams({ filter: tab.key });
            }}
          />
        </TabsContainer>
        <Container direction={'column'}>
          {currentFilter === 'unread' &&
            !!data?.reminders.unread_reminders.length &&
            data?.reminders.unread_reminders.map((reminder) => (
              <ReminderCard
                key={reminder.id}
                reminder={reminder}
                queryParams={queryParams}
                setSearchParams={setSearchParams}
                setRemoveModalVisible={setRemoveModalVisible}
                setCurrentReminder={setCurrentReminder}
              />
            ))}

          {currentFilter === 'read' &&
            !!data?.reminders.read_reminders.length &&
            data?.reminders.read_reminders.map((reminder) => (
              <ReminderCard
                key={reminder.id}
                reminder={reminder}
                queryParams={queryParams}
                setSearchParams={setSearchParams}
                setRemoveModalVisible={setRemoveModalVisible}
                setCurrentReminder={setCurrentReminder}
              />
            ))}
        </Container>
      </StyledLayout>

      {createModalVisible && <CreateReminderModal onClose={() => setCreateModalVisible(false)} />}
      {editModalVisible && <EditReminderModal reminder={currentReminder} onClose={onReset} />}
      {removeModalVisible && (
        <RemoveReminderModal
          onSave={() => {
            onDelete({ reminder_id: currentReminder?.id }).then(() => {
              if (currentReminder) {
                dispatch(deleteReminder(currentReminder));
                const deletedReminder = displayedReminders.filter((item) => item.id === currentReminder?.id)[0];
                deletedReminder && toast.dismiss(deletedReminder.position);
              }

              showSnackbar('Напоминание успешно удалено', 'success');
              setCurrentReminder(null);
              setRemoveModalVisible(false);
            });
          }}
          onClose={() => setRemoveModalVisible(false)}
        />
      )}
    </>
  );
};

export default PageReminders;
