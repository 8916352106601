import { components } from 'react-select';

import { Container } from '@shared/components';

import { TagIndicator } from './styles';

export const contactTypes = {
  all: 'all',
  partner: 'partner',
  client: 'client',
};

export const tabButtons = [
  { name: 'Все', key: contactTypes.all },
  { name: 'Партнеры', key: contactTypes.partner },
  { name: 'Клиенты', key: contactTypes.client },
];

export const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Container gap={'8px'} align={'center'}>
        <TagIndicator color={props.data.color} /> {children}
      </Container>
    </components.Option>
  );
};

export const CustomValue = ({ children, ...props }) => {
  return (
    <components.MultiValue {...props}>
      <Container gap={'8px'} align={'center'}>
        <TagIndicator color={props.data.color} /> {children}
      </Container>
    </components.MultiValue>
  );
};
