import { useState, useContext, useEffect, useMemo, useRef, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import JSZip from 'jszip';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import Dropzone from 'src/shared/components/Dropzone/Dropzone';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { incrementLastNumberInParentheses } from 'src/utilize/helper-functions';
import { Input, Label, Modal } from '@shared/components';
import FileToUpload from '@components/files/FileToUpload';
import { UploadFilesWrapper } from '@components/storage/styles';
import ProgressBar from '@components/files/ProgressBar_2';

import { useDispatch } from 'react-redux';

import { getStorageData, getStorageTree } from 'src/redux/features/storageSlice';

import { useResumable } from './useResumable';
import { getQueryData } from './lib';

const formSchema = object({ title: string().required() });

// dataId - id родителя (хранилище или папка)
export const AddFolderModalV2 = ({ storageId, folderId, close }) => {
  const {
    register,
    handleSubmit,
    // watch,
    formState: { isDirty, isValid },
    setFocus,
    control,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: { title: '' },
  });

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [folderUploadStatus, setFolderUploadStatus] = useState();
  const [isSubmitting, setIsSubmitting] = useState();
  const [replacementData, setReplacementData] = useState();

  const dispatch = useDispatch();

  const [resumable] = useState(
    useResumable({
      folderId,
      storageId,
      setFolderUploadStatus,
      onClose: close,
    }),
  );

  const [confirmClose, setConfirmClose] = useState();

  const handleClose = () => {
    if (isDirty) return setConfirmClose(true);
    close();
  };

  const { showSnackbar } = useContext(SnackbarContext);

  const { dataType, dataId } = useMemo(() => {
    const dataType = folderId ? 'folder' : 'storage';
    const dataId = folderId || storageId;
    return { dataType, dataId };
  }, [folderId, storageId]);

  // сфокусировать на текстовом поле
  useEffect(() => {
    setFocus('title');
  }, [setFocus]);

  const createFolder = async (data) => {
    if (filesToUpload.length) {
      const zip = new JSZip();
      for (let file = 0; file < filesToUpload.length; file++) {
        let filename = filesToUpload[file].name;
        const filePath = filesToUpload[file].filePath;
        // если файл с таким названием уже внесен в список для архивации, то добавить номер в названии файла
        while (zip.files[filename]) {
          filename = incrementLastNumberInParentheses(filename);
        }
        zip.file(filePath || filename, filesToUpload[file], {
          createFolders: true,
        });
      }

      setFolderUploadStatus({ status: 'archiving' });
      const blob = await zip.generateAsync({ type: 'blob' }).catch(() => {
        showSnackbar('Возникла ошибка при архивации файлов в папке');
        setIsSubmitting(false);
        setFolderUploadStatus(null);
      });

      const file = new File([blob], `${data.title}.zip`, { type: 'application/zip' });
      resumable.addFile(file);
      resumable.opts.query.title = data.title;

      setFolderUploadStatus({ status: 'uploading', progress: 0 });

      setTimeout(() => {
        resumable.upload();
      }, [1000]);
    } else {
      await axios
        .post('/api/storage_folders/create', {
          title: data.title,
          storage_id: folderId ? null : storageId,
          folder_id: folderId,
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при создании папки');
          setIsSubmitting(false);
          return;
        });

      dispatch(getStorageData({ storageId, showSnackbar }));
      dispatch(getStorageTree({ storageId, showSnackbar }));
      close();
    }
  };

  const checkFolderExistence = (data) => {
    setIsSubmitting(true);
    axios
      .get(`/api/storage_folder_exist/${dataType}/${dataId}/${data.title}`)
      .then((r) => {
        const { storage_folder_exist } = r.data;
        if (storage_folder_exist) {
          return setReplacementData(data);
        }

        createFolder(data);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при проверке наличия папки');
        setIsSubmitting(false);
      });
  };

  const removeFileHandler = async () => {
    const queryData = getQueryData({ storageId, folderId });

    const currentFile = resumable.files[0];

    try {
      if (currentFile) {
        currentFile.cancel();

        await axios.delete('/api/storage_folder/cancel', {
          data: {
            uniqueIdentifier: currentFile.uniqueIdentifier,
            ...queryData,
          },
        });
      }
    } catch (e) {
      showSnackbar('Возникла ошибка при удалении папки', 'error');
    }
  };

  // проверить поддерживается ли функция webkitdirectory по перекидке папки в браузер
  const folderUploadSupport = useMemo(() => {
    const input = document.createElement('input');

    return typeof input.webkitdirectory === 'boolean';
  }, []);

  const handleFolderSelect = (files, fieldOnChange) => {
    if (files.length) {
      const relativePath = files[0].webkitRelativePath;
      if (relativePath) {
        const folderName = relativePath.split('/')[0];
        fieldOnChange(folderName);
      }
      return setFilesToUpload((f) => [
        ...f,
        ...[...files].map((f) => {
          let filePath = '';
          if (f.webkitRelativePath) {
            filePath = f.webkitRelativePath.split('/');
            filePath.shift();
            filePath = filePath.join('/');
            f.filePath = filePath;
          }
          return f;
        }),
      ]);
    }
  };

  const handleFolderDrop = useCallback(
    async (e, fieldOnChange) => {
      e.preventDefault();
      if (isSubmitting) return;
      const dataTransferEntry = e.dataTransfer.items[0].webkitGetAsEntry();
      if (dataTransferEntry.isDirectory) {
        const folderName = dataTransferEntry.fullPath.split('/')[1];
        if (folderName) fieldOnChange(folderName);
        const foldersArr = [dataTransferEntry];
        const filesArr = [];
        while (foldersArr.length) {
          const entry = foldersArr.shift();
          const entryPromise = new Promise((resolve, reject) => {
            entry.createReader().readEntries(
              async (entries) => {
                if (!entries?.length) resolve();
                for (const entry of entries) {
                  if (entry.isDirectory) {
                    foldersArr.push(entry);
                  } else if (entry.isFile) {
                    let filePath = '';
                    if (typeof entry.fullPath === 'string') {
                      filePath = entry.fullPath.split('/');
                      filePath.splice(0, 2);
                      filePath = filePath.join('/');
                    }
                    const fileExtractPromise = new Promise((resolve, reject) => {
                      entry.file(
                        (file) => {
                          file.filePath = filePath;
                          filesArr.push(file);
                          resolve();
                        },
                        (error) => reject(error),
                      );
                    });
                    await fileExtractPromise.catch((e) => reject(e));
                  }
                }
                resolve();
              },
              (error) => {
                reject(error);
              },
            );
          });
          await entryPromise.catch(() => {
            showSnackbar('Возникла ошибка при чтении файлов в папке');
          });
        }
        if (filesArr) setFilesToUpload(filesArr);
      }
    },
    [isSubmitting],
  );

  const dropzoneDescription = useRef(
    <>
      или перетяните существующую папку с файлами
      <br />
      или кликните, чтобы выбрать папку
    </>,
  );

  return (
    <>
      <Modal
        title="Создать папку"
        onClose={handleClose}
        onSave={handleSubmit(checkFolderExistence)}
        disabledSaveButton={!isValid || !isDirty || isSubmitting}
        confirmButtonText="Создать"
      >
        <Controller
          control={control}
          name="title"
          render={({ field: { value, onChange, ref } }) => {
            //
            return (
              <div>
                <Label htmlFor="folderTitle">Название папки</Label>
                <Input ref={ref} id="folderTitle" value={value} onChange={onChange} register={register('title')} />
              </div>
            );
          }}
        />

        {filesToUpload.length > 0 && (
          <div>
            <Label>Файлы в папке</Label>
            <UploadFilesWrapper>
              {filesToUpload.map((file, i) => (
                <FileToUpload
                  file={file}
                  progressPercent={100}
                  isUploaded
                  removeFile={() => {
                    setFilesToUpload(filesToUpload.filter((file, ind) => ind !== i));
                  }}
                  key={i}
                  index={i}
                />
              ))}
            </UploadFilesWrapper>
          </div>
        )}

        {folderUploadSupport && !folderUploadStatus && (
          <Controller
            name="title"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Dropzone
                  onDrop={(e) => handleFolderDrop(e, onChange)}
                  handleFilesSelect={(e) => handleFolderSelect(e.target.files, onChange)}
                  description={dropzoneDescription.current}
                  onlyFolderSelect
                />
              );
            }}
          />
        )}

        {folderUploadStatus && (
          <div>
            <Label>Статус отправки:</Label>
            {folderUploadStatus.status === 'archiving' && <div>Идет архивация файлов в папке</div>}
            {folderUploadStatus.status === 'uploading' && <ProgressBar progressPercent={folderUploadStatus.progress} />}
          </div>
        )}
      </Modal>

      {confirmClose && (
        <ConfirmAction
          confirm={() => {
            removeFileHandler();
            close();
          }}
          cancel={() => setConfirmClose(false)}
          actionText="Вы уверены что хотите закрыть форму, не сохранив изменения?"
        />
      )}

      {replacementData && (
        <ConfirmAction
          isSuccess
          actionText="Папка с таким названием уже существует. Вы хотите заменить эту папку?"
          confirmButtonText={'Заменить'}
          confirm={() => {
            createFolder(replacementData);
            setReplacementData(null);
          }}
          cancel={() => {
            setReplacementData(null);
            setIsSubmitting(false);
          }}
        />
      )}
    </>
  );
};
