import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useCheckNotification } from './useCheckNotification';

const useNewNotification = () => {
  const checkNotification = useCheckNotification();
  const newNotification = useSelector((state) => state.notifications.newNotification);

  useEffect(() => {
    if (!newNotification) return;
    if (!Array.isArray(newNotification)) {
      checkNotification([newNotification]);
      return;
    }
    checkNotification(newNotification);
  }, [newNotification]);
};

export default useNewNotification;
