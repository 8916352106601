import { useState, useCallback, useEffect, useRef, useContext, memo } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsScrolledChatBottom } from 'src/redux/features/messagesSlice';
import DropFilesWrapperContainer from '@components/files/DropFilesWrapper';
import Preloader from 'src/components/preloaders/Preloader';
import SnackbarContext from 'src/contexts/SnackbarContext';
import UserTextingListener from 'src/components/chat/UserTextingListener';
import ChatTextEditorContainer from 'src/components/text-editor/ChatTextEditorContainer';
import { useSocketContext } from 'src/contexts/SocketContext';
import { useChatListener, formatMessages, requestNextMessages } from 'src/utilize/message-helper-functions';
import { MessageContextProvider } from 'src/contexts/MessageContext';
import MessageTextSelectionPopoverContainer from 'src/components/chat/MessageTextSelectionPopover';
import NotFoundComponent from '@shared/components/NotFoundComponent/NotFoundComponent';

import { StyledChatBody, StyledChatFooter } from '@components/chat/styles';

import { StyledChat, StyledChatContainer, StyledChatInner, StyledChatWrapper } from '../Projects/single-project/style';

import ArticleMessage from './ArticleMessage';

import { ArticleBody } from './style';

const SingleNewsBlog = () => {
  const dispatch = useDispatch();

  // reset state
  useEffect(() => {
    dispatch(setIsScrolledChatBottom(false));
  }, []);

  const { newsBlogId } = useParams();

  const [newsBlogData, setNewsBlogData] = useState();
  const [notFound, setNotFound] = useState();
  const [messagesList, setMessagesList] = useState([]);

  const [loading, setLoading] = useState(true);

  const [isChatEnd, setIsChatEnd] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  //#region get data
  const getData = useCallback(() => {
    if (newsBlogId) {
      setLoading(true);
      setNotFound(false);
      axios
        .get(`/api/news_blog_data/${newsBlogId}`)
        .then((r) => {
          const newsBlogData = r.data.newsBlogData;
          if (!newsBlogData || Array.isArray(newsBlogData)) {
            setNotFound(true);
            return;
          }
          const formattedMessages = formatMessages({
            projectData: newsBlogData,
            messages: newsBlogData.chat.messages,
            additionalMessageProps: { hideProjectOptions: true, hidePin: true },
          });

          setNewsBlogData(r.data.newsBlogData);
          setMessagesList(formattedMessages);
          if (formattedMessages.length < 20) setIsChatEnd(true);
        })
        .catch(() => showSnackbar('Возникла ошибка при получении данных'))
        .finally(() => setLoading(false));
    }
  }, [newsBlogId]);
  // получить данные по новости/блогу
  useEffect(() => getData(), [getData]);
  //#endregion

  const { socket, addToRoom, onChatChange, offChatChange } = useSocketContext();

  //#region enter socket room
  // войти в комнату socket для чата
  useEffect(() => {
    if (socket?.connected && newsBlogData?.chat?.id) {
      addToRoom(`chat_room_${newsBlogData.chat.id}`);
    }
  }, [socket, newsBlogData, addToRoom]);
  //#endregion

  const chatPageRef = useRef();

  const [nextMessagesLoading, setNextMessagesLoading] = useState('');

  const additionalMessageProps = useRef({
    hideProjectOptions: true,
    hidePin: true,
  });

  //#region chat listener
  // custom react hook для слушания за изменением чата со стороны других юзеров
  const chatChangeListener = useChatListener({
    isChatEnd,
    projectData: newsBlogData,
    chatPageRef,
    setMessagesList,
    additionalMessageProps: additionalMessageProps.current,
  });

  // cлушать за изменением чата со стороны других пользователей
  useEffect(() => {
    if (socket?.connected) {
      onChatChange(chatChangeListener);

      return () => offChatChange();
    }
  }, [socket, chatChangeListener]);
  //#endregion

  //#region handle scroll
  const handleScroll = useCallback(
    (element) => {
      if (
        chatPageRef.current.scrollHeight - element.target.scrollTop - element.target.clientHeight < 30 &&
        messagesList.length >= 20 &&
        nextMessagesLoading !== 'loading'
      ) {
        if (isChatEnd) {
          dispatch(setIsScrolledChatBottom(true));
          return;
        }
        if (!newsBlogData?.chat?.id) return;
        setNextMessagesLoading('loading');

        const lastMessageId = messagesList[messagesList.length - 1].props.id;

        requestNextMessages(newsBlogData.chat.id, lastMessageId)
          .then((response) => {
            const messages = formatMessages({
              projectData: newsBlogData,
              messages: response.data.messages.chat_messages,
              additionalMessageProps: { hideProjectOptions: true, hidePin: true },
            });

            setMessagesList((m) => [...m, ...messages]);
            dispatch(setIsScrolledChatBottom(false));
            setNextMessagesLoading(null);
            if (response.data.messages.end) setIsChatEnd(true);
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при запросе последующих сообщений');
          });
      } else {
        dispatch(setIsScrolledChatBottom(false));
      }
    },
    [messagesList, nextMessagesLoading, isChatEnd, newsBlogData, showSnackbar],
  );
  //#endregion

  // для прикрепления файлов при отправке сообщения с файлами
  const [draggedFiles, setDraggedFiles] = useState();
  const [disableChatFilesDrop, setDisableChatFilesDrop] = useState();

  return (
    <MessageContextProvider>
      <StyledChatWrapper>
        {notFound && <NotFoundComponent />}

        {!notFound && (
          <>
            {loading ? (
              <Preloader />
            ) : (
              <StyledChat>
                <StyledChatContainer>
                  {newsBlogData && (
                    <StyledChatInner>
                      <ArticleBody>
                        <DropFilesWrapperContainer setFiles={setDraggedFiles} disabled={disableChatFilesDrop}>
                          <StyledChatBody
                            onScroll={handleScroll}
                            style={{ justifyContent: 'flex-start', position: 'relative' }}
                            ref={chatPageRef}
                          >
                            <ArticleMessage newsBlogData={newsBlogData} getData={getData} messagesList={messagesList} />

                            {/* список самых первых сообщений */}
                            {messagesList}

                            {nextMessagesLoading === 'loading' && <Preloader />}

                            <MessageTextSelectionPopoverContainer hideProjectOptions={true} />
                          </StyledChatBody>

                          {newsBlogData && (
                            <StyledChatFooter>
                              <UserTextingListener chatId={newsBlogData.chat.id} />
                              <ChatTextEditorContainer
                                chatId={newsBlogData.chat.id}
                                draggedFiles={draggedFiles}
                                setDisableChatFilesDrop={setDisableChatFilesDrop}
                              />
                              {/* показывает контекстное меню при выделении текста в любом сообщении */}
                            </StyledChatFooter>
                          )}
                        </DropFilesWrapperContainer>
                      </ArticleBody>
                    </StyledChatInner>
                  )}
                </StyledChatContainer>
              </StyledChat>
            )}
          </>
        )}
      </StyledChatWrapper>
    </MessageContextProvider>
  );
};

export default memo(SingleNewsBlog);
