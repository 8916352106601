import styled from 'styled-components';
import { Title } from '@shared/components';
import { Link } from 'react-router-dom';

export const TabsContainer = styled.div`
  padding: 24px;
  margin: 0 -24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.light};

  @media (max-width: 600px) {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    margin: 0 -16px;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding: 32px 0;
`;
export const StyledTitle = styled(Title)`
  padding: 0;
`;

export const StyledLink = styled(Link)`
  color: #1890ff;
  text-decoration: underline;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  grid-column: -1/-2;
  grid-row: 1/3;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  gap: 8px;
`;

export const StyledCard = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  justify-content: space-between;
  align-items: start;
  padding: 20px 0;
  border-bottom: 2px solid ${({ theme }) => theme.disabledSecondary};
  transition: all 0.2s;
  word-break: break-word;

  &:hover ${StyledButtonWrapper} {
    opacity: 1;
    visibility: visible;
  }
`;
