import React from 'react';

import { StyledContainer } from './style';

export const Container = ({ direction, align, justify, children, gap, width, onClick, ...props }) => {
  return (
    <StyledContainer
      onClick={onClick}
      direction={direction}
      align={align}
      justify={justify}
      gap={gap}
      width={width}
      {...props}
    >
      {children}
    </StyledContainer>
  );
};
