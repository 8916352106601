import { Container } from '@shared/components/Container';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  flex-direction: column;
`;

export const DocViewerHeader = styled(Container)`
  padding: 14px 24px;
  align-items: center;
  gap: 36px;
  background-color: #f2f9ff;
  justify-content: space-between;
  width: 100%;

  button {
    border-radius: 8px !important;
    padding: 4px 20px;
    min-width: 160px;
  }
`;

export const PluginContainer = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 12px;

  min-height: 100vh;

  margin-top: -18px;

  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);

  #pdf-download,
  #file-name,
  #header-bar {
    display: none;
  }

  #pdf-controls {
    box-shadow: none;
  }
`;
