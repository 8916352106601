import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import iconWarning from 'src/assets/images/icons/error.svg';
import { Text } from '@shared/components';
import { StyledSnackbar } from '@components/warnings/Snackbar/styles';

function useOfflineListener() {
  const toastId = useRef();

  useEffect(() => {
    const onlineCallback = () => {
      toast.dismiss(toastId.current);
      toastId.current = null;
    };
    const offlineCallback = () => {
      if (toastId.current) return;
      const offlineText = 'Нет сети. Приложение работает в оффлайн режиме.';

      toastId.current = toast(
        <StyledSnackbar icon={iconWarning}>
          <Text tag="p" size={3}>
            {offlineText}
          </Text>
        </StyledSnackbar>,
        {
          position: 'bottom-left',
          closeOnClick: true,
          style: { padding: 0 },
          toastId: offlineText,
        },
      );
    };

    if (window.navigator.onLine === false) offlineCallback();

    window.addEventListener('online', onlineCallback);
    window.addEventListener('offline', offlineCallback);

    return () => {
      window.removeEventListener('online', onlineCallback);
      window.removeEventListener('offline', offlineCallback);
    };
  }, []);
}

export default useOfflineListener;
