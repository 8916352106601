import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { files_url } from 'src/settings/base-url';
import useAuth from 'src/hooks/useAuth';
import { Separator } from '@shared/components';
import {SiteInnerLink, SiteInnerList} from "@components/nav/styles";

// выпадающий список
const SiteNavInnerList = ({ handlerMobilMenu, list, links }) => {
  const { token } = useAuth();

  return (
    <SiteInnerList>
      {list &&
        Object.keys(list).map((oneKey, i) => {
          if (list[oneKey].handler) {
            return (
              list[oneKey].visible && (
                <SiteInnerLink
                  onClick={() => {
                    list[oneKey].handler();
                  }}
                  key={i}
                >
                  {oneKey}
                </SiteInnerLink>
              )
            );
          } else {
            return (
              list[oneKey].visible && (
                <Fragment key={i}>
                  {list[oneKey].separator && <Separator />}
                  <SiteInnerLink
                    as={Link}
                    to={list[oneKey].to}
                    key={i}
                    onClick={() => handlerMobilMenu && handlerMobilMenu('close')}
                  >
                    {oneKey}
                  </SiteInnerLink>
                </Fragment>
              )
            );
          }
        })}

      {links &&
        links.map((link) => (
          <SiteInnerLink
            as="a"
            key={link.id}
            onClick={(e) => e.stopPropagation()}
            href={
              link.type === 'file'
                ? `${files_url}/links/files/${link.id}/${link.link}?token=${token}`
                : `${link.link.indexOf('http') === 0 ? link.link : `//${link.link}`}`
            }
            rel="noopener noreferrer"
            target="blank"
          >
            {link.title}
          </SiteInnerLink>
        ))}
    </SiteInnerList>
  );
};

export default SiteNavInnerList;
