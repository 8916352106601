import {css, styled} from "styled-components";
import {Link} from "react-router-dom";

export const StyledSubheader = styled.div`
  background-color: ${({theme}) => theme.light};
`;

export const StyledContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 24px;

  @media (max-width: 600px) {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 550px) {
    padding: 24px 12px;
  }
`

export const TeamLink = styled(Link)`
  transition: color 0.3s ease-in-out;
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;

  &:hover {
    color: ${({theme}) => theme.main};
  }

  ${props => props.$highlighted && css`
    color: ${({theme}) => theme.main};
  `}
`