import styled from 'styled-components';

import { StyledButton } from '../Button/style';

export const StyledButtonIcon = styled(StyledButton)`
  position: relative;
  padding-left: ${(props) => props.position === 'left' && (props.size + 16 || 32)}px;
  padding-right: ${(props) => props.position === 'right' && (props.size + 16 || 32)}px;
  font-weight: 500;

  &::before {
    content: '';
    left: ${(props) => props.position === 'left' && 10}px;
    right: ${(props) => props.position === 'right' && 10}px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${(props) => props.size || 16}px;
    height: ${(props) => props.size || 16}px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${(props) => props.icon});
  }
`;
