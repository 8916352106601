import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getVacationsList } from 'src/redux/features/usersSlice';
import VacationsViewDropdown from '@components/employees/vacations/VacationsViewDropdown';
import EmployeesForm from '@components/employees/employees-form/EmployeesForm';
import iconAdd from '@assets/images/icons/plus.svg';
import {ButtonWithIcon, Container} from '@shared/components';
import useAuth from 'src/hooks/useAuth';
import VacationRequestModal from 'src/windows/employees/VacationRequest';

import { SalariesModal } from './ui/SalariesModal';
import { PaymentsModal } from './ui/PaymentsModal';
import {StyledContainer, StyledSubheader, TeamLink} from "./style";

const TeamHeader = () => {
  const { isUserRightful, rightTypes } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();

  const userHasRight = useMemo(() => isUserRightful(rightTypes.team_edit), [isUserRightful, rightTypes]);
  const [modalAddEmployee, setModalAddEmployee] = useState(false);
  const [salariesModalVisible, setSalariesModalVisible] = useState(false);
  const [paymentsModalVisible, setPaymentsModalVisible] = useState(false);
  const [vacationsModalVisible, setVacationsModalVisible] = useState(false);

  //  тут делаем запрос на получние данных для формы
  const onOpenModal = () => {
    setModalAddEmployee(true);
  };
  // закрыть окно и сбросить все состояния формы, сотрудников и тд
  const onCloseModal = () => {
    setModalAddEmployee(false);
  };

  return (
    <StyledSubheader>
      <StyledContainer>
        <Container gap="12px 32px" align="center" wrap="wrap">
          <TeamLink
            $highlighted={location.pathname.includes('users')}
            to="/team/users"
          >
            Команда
          </TeamLink>
          {/*<TeamLink*/}
          {/*  $highlighted={location.pathname.includes('guests')}*/}
          {/*  to="/team/guests"*/}
          {/*>*/}
          {/*  Гостевые доступы*/}
          {/*</TeamLink>*/}

          {/*{isUserRightful(rightTypes.salary_view) && (*/}
          {/*  <TeamLink*/}
          {/*    $highlighted={location.pathname.includes('salaries')}*/}
          {/*    to="salaries"*/}
          {/*  >*/}
          {/*    Зарплаты*/}
          {/*  </TeamLink>*/}
          {/*)}*/}

          {/*{isUserRightful(rightTypes.payouts_view) && (*/}
          {/*  <TeamLink*/}
          {/*    $highlighted={location.pathname.includes('payments')}*/}
          {/*    to="payments"*/}
          {/*  >*/}
          {/*    Выплаты*/}
          {/*  </TeamLink>*/}
          {/*)}*/}

          <TeamLink
            $highlighted={location.pathname.includes('vacations')}
            to="vacations"
          >
            Отпуска
          </TeamLink>
        </Container>

          {userHasRight && location.pathname.includes('/users') && (
            <ButtonWithIcon color="green" position="left" icon={iconAdd} size={20} onClick={onOpenModal}>
              Добавить сотрудника
            </ButtonWithIcon>
          )}

          {userHasRight && location.pathname.includes('/salaries') && (
            <ButtonWithIcon
              color="green"
              position="left"
              icon={iconAdd}
              size={20}
              onClick={() => setSalariesModalVisible(true)}
            >
              Добавить зарплату
            </ButtonWithIcon>
          )}

          {userHasRight && location.pathname.includes('/payments') && (
            <ButtonWithIcon
              color="green"
              position="left"
              icon={iconAdd}
              size={20}
              onClick={() => setPaymentsModalVisible(true)}
            >
              Добавить выплату
            </ButtonWithIcon>
          )}

          {location.pathname.includes('/vacations') && (
            <Container gap="24px" align="center">
              <VacationsViewDropdown />
              <ButtonWithIcon
                color="green"
                position="left"
                icon={iconAdd}
                size={20}
                onClick={() => setVacationsModalVisible(true)}
              >
                Добавить
              </ButtonWithIcon>
            </Container>
          )}
      </StyledContainer>

      {modalAddEmployee && userHasRight && <EmployeesForm onCloseModal={onCloseModal} action="add" />}
      {salariesModalVisible && userHasRight && <SalariesModal onClose={() => setSalariesModalVisible(false)} />}
      {paymentsModalVisible && userHasRight && <PaymentsModal onClose={() => setPaymentsModalVisible(false)} />}
      {vacationsModalVisible && (
        <VacationRequestModal
          onClose={() => setVacationsModalVisible(false)}
          action="create"
          getVacationsList={() => dispatch(getVacationsList())}
        />
      )}
    </StyledSubheader>
  );
};

export default TeamHeader;
