import { StyledChatButtonWrapper } from '@components/chat/styles';
import { IconButton } from '@shared/components';
import { memo, useCallback, useContext, useState } from 'react';
import iconPoll from '@assets/images/icons/poll.png';
import { bool, func, number } from 'prop-types';
import CreatePollModal from 'src/windows/polls/CreatePollModal';
import { useSocketContext } from 'src/contexts/SocketContext';
import moment from 'moment';
import { randomSequence } from 'src/utilize/helper-functions';
import axios from 'axios';
import SnackbarContext from 'src/contexts/SnackbarContext';

export const PollButton = memo(PollButtonFC);

PollButtonFC.propTypes = {
  isMenuMobile: bool,
  setChatOption: func,
  setIsMenuOpen: func,
  chatId: number,
  isMemberOnly: bool,
};

function PollButtonFC({ setChatOption, isMenuMobile, setIsMenuOpen, chatId, isMemberOnly }) {
  const [pollModal, setPollModal] = useState(false);

  const { createPoll, socket } = useSocketContext();
  const { showSnackbar } = useContext(SnackbarContext);

  const handlePollSubmit = useCallback(
    async (formValues) => {
      if (!chatId || !formValues) return;

      let hasImagesToUpload = false;
      const poll_answers = formValues.poll_answers.map((answer) => {
        if (answer.file instanceof File) {
          answer.div_id = randomSequence();
          hasImagesToUpload = true;
        }
        return answer;
      });

      if (hasImagesToUpload) {
        const promises = [];
        let error = false;
        poll_answers.forEach((answer) => {
          if (answer.file && answer.div_id) {
            const formData = new FormData();

            formData.append('file', answer.file, answer.file.name);
            formData.append('div_id', answer.div_id);
            promises.push(axios.post('/api/poll_answer_file/add', formData).catch(() => (error = true)));
          }
        });

        await Promise.all(promises);
        if (error) {
          showSnackbar('Не удалось загрузить картинки');
          return;
        }
      }

      const data = {
        chat_id: chatId,
        poll: {
          title: formValues.title,
          anonymous: formValues.anonymous,
          multianswer: formValues.multianswer.value,
          date_finish: formValues.date_finish ? moment.utc(formValues.date_finish).format('YYYY-MM-DD-LT') : null,
        },
        poll_answers: poll_answers.map((item) => {
          const pollOption = { answer: item.value };
          if (item.div_id) pollOption.div_id = item.div_id;
          return pollOption;
        }),
        poll_members: formValues.poll_members?.map((item) => item.value.id),
      };
      return new Promise((resolve, reject) => {
        createPoll(data, (response) => {
          if (response?.status === 'ok') resolve();
          else reject(new Error('Не удалось создать голосование'));
        });
      });
    },
    [chatId, socket],
  );

  const getPollCreateData = useCallback(async () => {
    return axios.get(`/api/poll_create/${chatId}`);
  }, [chatId]);

  return (
    <>
      <StyledChatButtonWrapper>
        <IconButton
          icon={iconPoll}
          size={20}
          title="Сообщение с голосованием"
          onClick={() => {
            setChatOption(null);
            isMenuMobile && setIsMenuOpen(false);
            setPollModal(true);
          }}
        />
      </StyledChatButtonWrapper>

      {pollModal && (
        <CreatePollModal
          close={() => setPollModal(false)}
          submitRequest={handlePollSubmit}
          getPollCreateDataRequest={getPollCreateData}
          chatId={chatId}
          isMemberOnly={isMemberOnly}
        />
      )}
    </>
  );
}
