import { memo, useRef } from 'react';
import { IconButton, Textarea } from '@shared/components';
import iconDelete from '@assets/images/icons/delete.svg';
import iconAttach from '@assets/images/icons/icon-links.svg';
import { bool, func, number, shape, string, object } from 'prop-types';

import { PollOptionImage } from '../PollOptionImage/PollOptionImage';

import { ListItem, StyledPollOptionContainer } from './styles';

export const PollOptionField = memo(PollOptionFieldFC);

PollOptionFieldFC.propTypes = {
  index: number,
  field: shape({
    value: string,
    answer_id: number,
    file: object,
  }),
  register: func,
  remove: func,
  error: bool,
  getValues: func,
  setValue: func,
  update: func,
};

function PollOptionFieldFC({ field, index, register, remove, error, getValues, setValue, update }) {
  const pollOptionFileInputRef = useRef();

  return (
    <ListItem key={field.id}>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={pollOptionFileInputRef}
        onChange={(e) => {
          if (!e.target.files?.[0]) return;
          if (field.answer_id) {
            const imageIdsToDelete = getValues('delete_poll_answer_files');
            if (Array.isArray(imageIdsToDelete) && imageIdsToDelete.includes(field.answer_id)) {
              setValue(
                'delete_poll_answer_files',
                imageIdsToDelete.filter((id) => id !== field.answer_id),
              );
            }
          }

          const value = getValues(`poll_answers.${index}`);
          update(index, { ...value, file: e.target.files[0] });
        }}
        accept="image/*"
      />
      <StyledPollOptionContainer align="center" gap="20px">
        <Textarea rows={1} error={error} key={field.id} register={{ ...register(`poll_answers.${index}.value`) }} />
        <IconButton icon={iconAttach} onClick={() => pollOptionFileInputRef.current.click()} />
        <IconButton
          icon={iconDelete}
          onClick={() => {
            if (field.answer_id) {
              const answersIdsToDelete = getValues('delete_poll_answers');
              if (Array.isArray(answersIdsToDelete)) {
                setValue('delete_poll_answers', [...answersIdsToDelete, field.answer_id]);
              }
            }
            remove(index);
          }}
        />
      </StyledPollOptionContainer>
      <PollOptionImage index={index} field={field} update={update} getValues={getValues} setValue={setValue} />
    </ListItem>
  );
}

export default PollOptionField;
