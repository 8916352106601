import { useState } from 'react';
import { func } from 'prop-types';
import { Button, Container, Title } from '@shared/components';
import NotificationsTypeSelect from '@components/notifications/NotificationsTypeSelect';
import { Tabs } from '@components/notifications/Tabs';
import ConfirmAction from '@components/warnings/ConfirmAction';
import PushNotificationPermission from '@components/notifications/PushNotificationPermission';

import { FlexItem, StyledSubheader } from './styles';

PageNotificationsHeader.propTypes = {
  clearAll: func,
};

function PageNotificationsHeader({ clearAll }) {
  const [confirmClearAll, setConfirmClearAll] = useState(false);

  return (
    <>
      <Container justify="space-between" align="center" wrap="wrap">
        <Container gap="0 24px" align="center" wrap="wrap">
          <Title>Уведомления</Title>
        </Container>
        <Container wrap="wrap" gap="24px" align="center">
          <FlexItem>
            <NotificationsTypeSelect />
          </FlexItem>
          <Button onClick={() => setConfirmClearAll(true)}>Пометить все как прочитанные</Button>
          <PushNotificationPermission />
        </Container>
      </Container>
      <StyledSubheader>
        <Tabs />
      </StyledSubheader>

      {confirmClearAll && (
        <ConfirmAction
          confirmButtonText="Да"
          cancelButtonText="Нет"
          actionText={'Вы уверены, что хотите пометить все уведомления как прочитанные?'}
          confirm={() => {
            clearAll();
            setConfirmClearAll(false);
          }}
          cancel={() => setConfirmClearAll(false)}
        />
      )}
    </>
  );
}

export default PageNotificationsHeader;
