import React, { useMemo, useContext, useState, useRef, useCallback } from 'react';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { SingleSelect } from '@shared/components/Select/Select';
import { Input } from '@shared/components/Input/Input';
import { IconButton } from '@shared/components/IconButton/IconButton';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import DateInput from '@shared/components/DateInput/DateInput';

import databaseIcon from '@assets/images/icons/chat-storage.svg';
import attachIcon from '@assets/images/icons/icon-links.svg';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SnackbarContext from 'src/contexts/SnackbarContext';

import axios from 'axios';
import { server_url } from 'src/settings/base-url';

import { ticketsApi } from 'src/redux/features/api/tickets';

import { ModalStatusInfo } from '@shared/components/Modal/ModalStatusInfo';
import { Text } from '@shared/components/Text/Text';
import { Link } from '@shared/components/Link/Link';

import FilesUploadManage from '@components/files/FilesUploadManage';

import { randomSequence } from 'src/utilize/helper-functions';

import FileToUpload from '@components/files/FileToUpload';

import StorageModal from 'src/windows/storage/StorageModal';

import { RichTextEditor } from '@components/RichTextEditor';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { t } from 'i18next';

import { useParams } from 'react-router-dom';

import { components } from 'react-select';

import { Avatar } from '@shared/components/Avatar';

import { FilesContainer, StyledForm, Wrapper } from './styles';
import { getOptions, schema, transformFormData } from './lib';

export const CreateTicketModal = ({ projectTitle, taskTitle, messageId, message, onClose }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesFromStorage, setFilesFromStorage] = useState([]);
  const [isFilesUploading, setIsFilesUploading] = useState();
  const [showStorage, setShowStorage] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formId, setFormId] = useState(randomSequence());

  const removeFilesOnClose = useRef();
  const clearFileManager = useRef();

  const { projectId, taskId } = useParams();

  const auth = useAuth();
  const users = useSelector((state) => state.users.employees);

  const options = useMemo(() => getOptions(users, auth.token), [users]);

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { showSnackbar } = useContext(SnackbarContext);

  const addFilesFromStorage = async () => {
    const filesIds = filesFromStorage.map((file) => file.id);

    await axios
      .post('/api/ticket_action_files/add_from_storage', {
        form_id: formId,
        storage_files: filesIds,
      })
      .then(() => showSnackbar('Поручение успешно создано', 'success'))
      .catch(() => showSnackbar('Возникла ошибка при отправке файлов из хранилища'));
  };

  const addStorageFileReference = useCallback((storageFileData) => {
    setFilesFromStorage((files) => {
      const alreadyAdded = files.some((fileData) => fileData.id === storageFileData.id);
      if (alreadyAdded) return files;
      return [...files, storageFileData];
    });
    showSnackbar('Ссылка на файл из хранилища добавлена', 'success');
  }, []);

  const createTicket = async (data) => {
    try {
      if (filesFromStorage.length) {
        await addFilesFromStorage();
      }

      await axios.post(`${server_url}/api/tickets/add`, data);
      showSnackbar('Поручение успешно создано', 'success');
      dispatch(ticketsApi.util.invalidateTags(['Tickets', 'FromMe']));

      onClose();
    } catch (error) {
      showSnackbar('Возникла ошибка при создании поручения');
    }
  };

  const onSubmit = (data) => {
    createTicket(transformFormData(data, messageId, formId));
  };

  const onCloseHandler = async () => {
    const onRemove = removeFilesOnClose.current;

    if (onRemove) {
      await onRemove();
    }

    onClose();
  };

  const openFileSelectPopup = () => {
    document.getElementById('upfile').click();
  };

  const CustomOption = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <Wrapper align={'center'}>
          {props.data?.value && <Avatar size={24} userId={props.data.value} />} {children}
        </Wrapper>
      </components.Option>
    );
  };

  const CustomValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <Wrapper align={'center'}>
          {props.data.value && <Avatar size={24} userId={props.data.value} />} {children}
        </Wrapper>
      </components.SingleValue>
    );
  };

  return (
    <>
      <Modal
        type="submit"
        modalSize="767px"
        title="Создать поручение"
        confirmButtonText={'Создать'}
        onSave={handleSubmit(onSubmit)}
        onClose={() => {
          if (isDirty) {
            setConfirmModal(true);
            return;
          }
          onCloseHandler();
        }}
        disabledSaveButton={isValid === false}
      >
        {messageId && (
          <ModalStatusInfo>
            <Text tag="p">
              Поручение на основе сообщения из {taskId === undefined ? 'проекта' : 'задачи'}
              <Link tag="b" hover="var(--main)">
                {` "${projectTitle}".`}
              </Link>
            </Text>
            <Text tag="p">{message}</Text>
          </ModalStatusInfo>
        )}

        <StyledForm>
          <div>
            <Label>Кому</Label>
            <Controller
              name="user"
              control={control}
              render={({ field }) => (
                <SingleSelect
                  {...field}
                  options={options}
                  components={{ Option: CustomOption, SingleValue: CustomValue }}
                />
              )}
            />
          </div>

          <div>
            <Label>Название</Label>
            <Controller name="title" control={control} render={({ field }) => <Input {...field} error={false} />} />
          </div>

          <div>
            <Label>Описание</Label>

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <RichTextEditor
                  {...field}
                  extraButtons={
                    <>
                      <IconButton size={14} icon={attachIcon} onClick={openFileSelectPopup}>
                        <input
                          type="file"
                          multiple
                          id="upfile"
                          style={{ display: 'none' }}
                          onChange={(e) => setFilesToUpload([...e.target.files])}
                        />
                      </IconButton>
                      <IconButton size={14} icon={databaseIcon} onClick={() => setShowStorage(true)}></IconButton>
                    </>
                  }
                />
              )}
            />

            {(filesToUpload?.length > 0 || filesFromStorage.length > 0) && (
              <FilesContainer>
                {filesFromStorage.map((f, i) => (
                  <FileToUpload
                    file={f}
                    progressPercent={100}
                    isUploaded
                    removeFile={() => {
                      setFilesFromStorage((files) => files.filter((file, index) => index !== i));
                    }}
                    key={i}
                    index={i}
                    isFromStorage
                  />
                ))}

                <FilesUploadManage
                  formId={formId}
                  filesToUpload={filesToUpload}
                  filesUploadPath="/api/ticket_action_files/add"
                  filesDeletePath="/api/ticket_action_files/remove"
                  setIsFilesUploading={setIsFilesUploading}
                  removeFilesOnClose={removeFilesOnClose}
                  clearFileManager={clearFileManager}
                  removeById
                />
              </FilesContainer>
            )}
          </div>

          <div>
            <Label>Срок исполнения</Label>
            <StyledModalRowEnd>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    placeholder="Дата"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    timeIntervals={15}
                    timeCaption="Время"
                    timeFormat="p"
                    dateFormat="Pp"
                    showTimeSelect
                  />
                )}
              />
            </StyledModalRowEnd>
          </div>
        </StyledForm>
      </Modal>

      {showStorage && (
          <StorageModal
            addStorageFileReference={addStorageFileReference}
            noURLParams
            close={() => setShowStorage(false)}
          />
      )}

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onCloseHandler}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </>
  );
};
