import { useEffect, useState } from 'react';
import VacationCard from '@components/employees/vacations/VacationCard';
import {StyledVacationContainer, VacationTitle} from "@components/employees/vacations/styles";

const addDepartmentData = (vacationData, department_title, department_id) => {
  if (!vacationData) return;
  return { ...vacationData, department_title, department_id };
};

const VacationsList = ({ employeeVacations, getVacationsData, processVacationRequest }) => {
  const [vacationsList, setVacationsList] = useState();

  useEffect(() => {
    if (!employeeVacations?.length) return;
    const vacationRequests = [];
    const approvedVacations = [];

    for (const deptt of employeeVacations) {
      const toVacation = deptt.to_vacation.map((vacationData) =>
        addDepartmentData(vacationData, deptt.title, deptt.id),
      );
      const inVacation = deptt.in_vacation.map((vacationData) =>
        addDepartmentData(vacationData, deptt.title, deptt.id),
      );
      vacationRequests.push(...toVacation);
      approvedVacations.push(...inVacation);
    }

    setVacationsList({ vacationRequests, approvedVacations });
  }, [getVacationsData, employeeVacations]);

  return (
    <StyledVacationContainer>
      {!!vacationsList?.vacationRequests?.length && (
        <>
          <VacationTitle tag="h2" size={3}>Запросы на отпуск</VacationTitle>
          {vacationsList.vacationRequests.map((vacationRequest, i) => (
            <VacationCard
              vacationData={vacationRequest}
              key={i}
              getVacationsList={getVacationsData}
              processVacationRequest={processVacationRequest}
              forApproval
            />
          ))}
        </>
      )}
      {!!vacationsList?.approvedVacations?.length && (
        <>
          <VacationTitle tag="h2" size={3}>Отпуска</VacationTitle>
          {vacationsList.approvedVacations.map((approvedVacation, i) => (
            <VacationCard vacationData={approvedVacation} key={i} />
          ))}
        </>
      )}
    </StyledVacationContainer>
  );
};

export default VacationsList;
