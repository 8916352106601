import { useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useState } from 'react';

import { object, string, boolean, array, number } from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal } from '@shared/components/Modal/Modal';
import { Subtitle } from '@shared/components/Subtitle/Subtitle';
import { Text } from '@shared/components/Text/Text';
import { IconButton } from '@shared/components/IconButton/IconButton';
import { Checkbox } from '@shared/components/Checkbox/Checkbox';

import {
  StyledModalFlex,
  StyledModalIconButtonWrapper,
  StyledModalLabelInfo,
  StyledModalRowStart,
  StyledModalValueInfo,
} from '@shared/components/Modal/style';

import Preloader from '../../components/preloaders/Preloader';
import SnackbarContext from '../../contexts/SnackbarContext';
import ConfirmAction from '../../components/warnings/ConfirmAction';

import iconEdit from './../../assets/images/icons/edit.svg';
import iconDelete from './../../assets/images/icons/delete.svg';
import { StyledAccessItem, StyledAccessList } from './style';

const formSchema = object({
  accessFields: array().of(
    object({
      title: string(),
      type: string(),
      id: number(),
      access: array(),
      toEdit: boolean(),
      toDelete: boolean(),
    }),
  ),
});

const GuestFullProfileModal = ({ close, getGuestList }) => {
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });
  const { fields, update } = useFieldArray({
    control,
    name: 'accessFields',
  });

  const { guest_id } = useParams();
  const [guest, setGuest] = useState();

  // получить данные по текущему гостю
  useEffect(() => {
    axios
      .get(`/api/guest_full/${guest_id}`)
      .then((r) => {
        const { projects, tasks } = r.data.guest;
        reset({
          accessFields: [...projects, ...tasks].map((data) => {
            const dataType = data.project ? 'project' : 'task';
            const id = data[`${dataType}_id`];
            return {
              title: data[dataType],
              type: dataType,
              data_id: id,
              access: data.access,
              toEdit: false,
              toDelete: false,
              root_project_id: data.root_project_id,
            };
          }),
        });

        setGuest(r.data.guest);
      })
      .catch(() => {
        showSnackbar('Ошибка при получении данных гостя');
      });
  }, [guest_id, reset]);

  const navigate = useNavigate();

  const { showSnackbar } = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // для отправки запроса по редактированию гостя
  const editGuest = async ({ accessFields }) => {
    setIsSubmitting(true);
    const requestPromises = [];
    for (const guestAccess of accessFields) {
      const requestBody = { guest_id };

      guestAccess.type === 'project'
        ? (requestBody.project_id = guestAccess.data_id)
        : (requestBody.task_id = guestAccess.data_id);

      if (guestAccess.toDelete) {
        requestPromises.push(
          axios.delete('/api/guest_rights/delete', { data: requestBody }).catch(() => {
            showSnackbar('Ошибка при обновлении гостевого доступа');
          }),
        );
      } else {
        requestBody.rights = guestAccess.access;

        requestPromises.push(
          axios.put('/api/guests/edit', requestBody).catch(() => {
            showSnackbar('Ошибка при обновлении гостевого доступа');
          }),
        );
      }
    }
    await Promise.all(requestPromises);
    showSnackbar('Гостевой доступ обновлен', 'success');
    navigateOnClose();
  };

  const deleteAllAccessibleRights = () => {
    if (guest) {
      axios
        .delete('/api/guest/delete', {
          data: {
            guest_id: guest.id,
          },
        })
        .then(() => {
          getGuestList();
          close();
        })
        .catch(() => showSnackbar('Возникла ошибка при удалении гостевого доступа'));
    }
  };

  const [confirmDeleteAll, setConfirmDeleteAll] = useState(false);

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const navigateOnClose = () => navigate('/team/guests');

  const handleModalClose = () => {
    if (isDirty) {
      setModalClosePrompt(true);
    } else navigateOnClose();
  };

  const headerButtons = [{ name: 'Удалить', action: () => setConfirmDeleteAll(true) }];

  return (
    <Modal
      headerButtons={headerButtons}
      title="Просмотр гостевого доступа"
      onClose={handleModalClose}
      disabledSaveButton={!isDirty || isSubmitting}
      onSave={handleSubmit(editGuest)}
      confirmButtonText="Сохранить"
    >
      {guest ? (
        <>
          <StyledModalRowStart>
            <div>
              <StyledModalLabelInfo>Имя</StyledModalLabelInfo>
              <StyledModalValueInfo>{guest.first_name}</StyledModalValueInfo>
            </div>
            <div>
              <StyledModalLabelInfo>Электронная почта</StyledModalLabelInfo>
              <StyledModalValueInfo>{guest.email}</StyledModalValueInfo>
            </div>
          </StyledModalRowStart>

          <div>
            <StyledModalFlex>
              <Subtitle>Права доступа</Subtitle>
            </StyledModalFlex>

            <StyledAccessList>
              {fields?.map((field, i) => {
                if (field.toDelete) return '';

                const url = `/projects/${
                  field.type === 'project' ? field.data_id : `${field.root_project_id}/tasks/${field.data_id}`
                }`;

                return (
                  <StyledAccessItem key={field.id}>
                    <Text tag="span" size="2">
                      {`${field.type === 'project' ? 'Проект' : 'Задача'}: `} <Link to={url}>{field.title}</Link>
                    </Text>

                    <StyledModalIconButtonWrapper>
                      <IconButton
                        icon={iconEdit}
                        size={16}
                        onClick={() =>
                          update(i, {
                            ...field,
                            toEdit: !field.toEdit,
                          })
                        }
                      ></IconButton>
                      <IconButton
                        icon={iconDelete}
                        size={16}
                        onClick={() => update(i, { ...field, toDelete: true })}
                      ></IconButton>
                    </StyledModalIconButtonWrapper>

                    <div>
                      <Checkbox
                        disabled={!field.toEdit}
                        id="access_children"
                        register={register(`accessFields.${i}.access`)}
                      >
                        Доступ к дочерним задачам
                      </Checkbox>
                      <Checkbox disabled={!field.toEdit} id="write" register={register(`accessFields.${i}.access`)}>
                        Может писать
                      </Checkbox>
                      <Checkbox disabled={!field.toEdit} id="vote" register={register(`accessFields.${i}.access`)}>
                        Может голосовать
                      </Checkbox>
                      <Checkbox disabled={!field.toEdit} id="add_files" register={register(`accessFields.${i}.access`)}>
                        Может добавлять картинки и файлы
                      </Checkbox>
                    </div>
                  </StyledAccessItem>
                );
              })}
            </StyledAccessList>
          </div>
        </>
      ) : (
        <Preloader />
      )}

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={navigateOnClose}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
      {confirmDeleteAll && (
        <ConfirmAction
          cancel={() => setConfirmDeleteAll(false)}
          confirm={deleteAllAccessibleRights}
          actionText="Вы собираетесь удалить гостевые доступы данного пользователя из всех проектов и задач, в которых Вы являетесь создателем или ответственным."
          confirmButtonText="Удалить"
        />
      )}
    </Modal>
  );
};

export default GuestFullProfileModal;
