import React, { useState } from 'react';

import { Button, Text } from '@shared/components';
import ReactImageGallery from 'react-image-gallery';

import Preloader from '@components/preloaders/Preloader';

import { useDownloader } from '@shared/hooks/useDownloader';

import { PreviewHeader, StyledContainer, Wrapper } from './styles';

export const ImagesViewer = ({ url, filePath, fileName }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { downloadFile } = useDownloader();

  return (
    <Wrapper>
      {isLoading ? <Preloader /> : <></>}
      <StyledContainer visible={!isLoading} width={isLoading ? 0 : '100%'}>
        <PreviewHeader>
          <Text size={3}>
            Предпросмотр файла <b>"{fileName}"</b>
          </Text>

          <Button onClick={() => downloadFile({ url: filePath, filename: fileName })}>Скачать</Button>
        </PreviewHeader>
        <ReactImageGallery
          items={[{ original: url }]}
          showThumbnails={false}
          showPlayButton={false}
          onImageLoad={() => setIsLoading(false)}
          lazyLoad
        />
      </StyledContainer>
    </Wrapper>
  );
};
