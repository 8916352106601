import {styled} from "styled-components";

export const Team = styled.section`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 24px;
  
  @media (max-width: 550px) {
    padding: 0 12px;
  }
`

export const TeamFilter = styled.form`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  gap: 24px;

  @media (max-width: 1150px) {
    align-items: flex-start;
    flex-direction: column;
  }
`

export const TeamFilterCol = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media (max-width: 1150px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`