import { useMemo, useState } from 'react';
import {
  StyledPagination,
  StyledPaginationItem,
  StyledPaginationNext,
  StyledPaginationPrev
} from "@shared/components/Pagination/style";

const Pagination = ({ totalPages, getData }) => {
  const [currPageNum, setCurrPageNum] = useState(1);

  // react массив кнопок для отображения
  const buttons = useMemo(() => {
    const buttonsArr = [];

    if (!totalPages) return '';

    // cчитает максимальное кол-тво кнопок предыдущих страниц (до 5)
    const prevBtnsAmount = currPageNum - totalPages + 5;
    // цикл для добавления кнопок до кнопки текущей страницы
    // повторять цикл пока предыдущие кнопки не достигли первой страницы
    // и кол-тво добавленных кнопок меньше 3 или пока не достигнута максимальное количество (prevBtnsAmount)
    for (let i = 1, j = currPageNum; j > 1 && (i < 3 || i < prevBtnsAmount); i++, j--) {
      buttonsArr.unshift(
        <StyledPaginationItem
          key={currPageNum - i}
          onClick={() => {
            getData(currPageNum - i);
            setCurrPageNum(currPageNum - i);
          }}
        >
          {currPageNum - i}
        </StyledPaginationItem>,
      );
    }
    // добавляет кнопку текущей страницы
    buttonsArr.push(
      <StyledPaginationItem $active={true} key={currPageNum}>
        {currPageNum}
      </StyledPaginationItem>,
    );

    // cчитает максимальное кол-тво кнопок следующих страниц (до 5)
    const nextBtnsAmount = 5 - currPageNum;
    // цикл для добавления кнопок до кнопки текущей страницы
    // повторять цикл пока предыдущие кнопки не достигли последней страницы
    // и кол-тво добавленных кнопок меньше 3 или пока не достигнута максимальное количество (nextBtnsAmount)
    for (let i = 1, j = currPageNum; j < totalPages && (i <= nextBtnsAmount || i < 3); i++, j++) {
      buttonsArr.push(
        <StyledPaginationItem
          key={currPageNum + i}
          onClick={() => {
            getData(currPageNum + i);
            setCurrPageNum(currPageNum + i);
          }}
        >
          {currPageNum + i}
        </StyledPaginationItem>,
      );
    }

    return buttonsArr;
  }, [totalPages, currPageNum, getData]);

  return (
    <StyledPagination>
      <StyledPaginationPrev
        disabled={currPageNum < 4 || totalPages < 6}
        onClick={() => {
          const pageToNavigate = totalPages - currPageNum < 3 ? totalPages - 5 : currPageNum - 3;
          getData(pageToNavigate);
          setCurrPageNum(pageToNavigate);
        }}
      ></StyledPaginationPrev>
      {buttons}
      <StyledPaginationNext
        disabled={(currPageNum < 3 && totalPages < 6) || totalPages - (currPageNum + 3) < 0}
        onClick={() => {
          const pageToNavigate = currPageNum < 3 ? 6 : currPageNum + 3;
          getData(pageToNavigate);
          setCurrPageNum(pageToNavigate);
        }}
      ></StyledPaginationNext>
    </StyledPagination>
  );
};

export default Pagination;
