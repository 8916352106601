import { StyledText } from '@shared/components/Text/style';
import styled from 'styled-components';

export const StyledLink = styled(StyledText)`
  color: ${(props) => props.color};
  transition: all 0.3s;

  &:hover {
    color: ${(props) => (props.hover ? props.hover : '#1890ff')};
  }
`;
