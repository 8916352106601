import { Button } from '@shared/components';
import { styled } from 'styled-components';

export const StyledChatTextEditor = styled.section`
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  color: ${({ theme }) => theme.darkest};
  font: inherit;
  outline: none;
  padding: 8px 12px;
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 24px;

  span {
    word-break: break-all;
  }

  :global(.public-DraftEditor-content) {
    min-height: 20px;
  }
`;

export const StyledToolbar = styled.section`
  display: flex;
  align-items: center;
`;

export const StyledToolbarItem = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.light};
  cursor: pointer;
  width: 32px;
  height: 32px;
  font-size: 16px;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.mainLight};
  }

  &.selected {
    background-color: ${({ theme }) => theme.mainLight};
  }
`;

export const ScrollWrapper = styled.div`
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0
  }
`

export const AttachWrapper = styled.div`
  background-color: ${(props) => props.theme.light};
  margin-bottom: 8px;
  padding: 12px;
  align-items: center;
  display: flex;
  gap: 16px;
  width: max-content;
`;

export const AttachTag = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledEditorControls = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px 0;
`;

export const StyledEditorControlItem = styled.div`
  height: 15px;
  width: 15px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.icon});
  cursor: pointer;

  &:hover {
    filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
  }
`;

export const SubmitButton = styled(Button)`
  @media (max-width: 767px) {
    display: none;
  }
`;
