import styled, { css, keyframes } from 'styled-components';

const moveAnimation = keyframes`
  100% {
    background-position: 5px 0;
  }
`;

export const StyledAttachItem = styled.div`
  align-items: center;
  display: inline-flex;
  margin: 4px 4px 4px 0;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  gap: 8px;
  width: 100%;
`;

export const StyledAttachImage = styled.a`
  height: 27px;
  object-fit: cover;
  width: 48px;

  & img {
    border-radius: 4px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  ${(props) =>
    props.icon &&
    `
    background-image: url(${props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto;
    height: 27px;
    width: 24px;
  `}
`;

export const StyledVersion = styled.section`
  position: relative;
  margin-left: 16px;
`;

export const StyledVersionButton = styled.button`
  vertical-align: bottom;
  background-color: ${({ theme }) => theme.success};
  border: none;
  border-radius: 2px;
  width: 22px;
  height: 22px;
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 15px;
  transition: all 0.3s;

  &:hover {
    filter: brightness(95%);
  }
`;

export const StyledVersionTitle = styled.h4`
  padding: 4px 8px;
  font-weight: 700;
`;

export const StyledVersionList = styled.div`
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px);
  width: max-content;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.lightest};
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  ${(props) =>
    props.$active &&
    css`
      top: calc(100% + 4px);
      opacity: 1;
      visibility: visible;
    `}
`;

export const StyledVersionItem = styled.div`
  padding: 4px 8px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.mainLight};
  }
`;

export const Progress = styled.div`
  height: 5px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  border: 1px solid grey;
  padding: 1px;
`;

export const ProgressFill = styled.div`
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 88%,
    transparent 88%,
    transparent 100%
  );
  background-size: 5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.success};
  width: 65%;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${moveAnimation} 0.2s linear infinite;
  transition: width 2s linear;

  ${(props) =>
    props.state === 'complete' &&
    css`
      background-color: ${({ theme }) => theme.main};
      animation: none;
      background-image: none;
      transition: none;
    `}

  ${(props) =>
    props.state === 'error' &&
    css`
      background-color: ${({ theme }) => theme.warning};
      animation: none;
    `}
`;
