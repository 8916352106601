import styled from 'styled-components';

export const StyledInput = styled.input`
  position: relative;
  outline: none;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  padding: 8px 34px 8px 12px;
  width: 100%;
  font: inherit;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.darkest};

  &::placeholder {
    color: ${({ theme }) => theme.dark};
  }

  &:focus {
    border-color: ${({ theme }) => theme.main};
    box-shadow: ${({ theme }) => theme.shadowInput};

    ${({ $error, theme }) =>
      $error &&
      `
        border-color: ${theme.error};
        box-shadow: ${theme.shadowInputError}
    `}
  }

  ${({ $error, theme }) =>
    $error &&
    `
      border-color: ${theme.error};
      box-shadow: ${theme.shadowInputError};
  `}
`;
