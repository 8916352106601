import { useRef, useState, useCallback, useMemo, useContext } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useStorageContext } from 'src/windows/storage/StorageModal';
import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';
import { downloadFile } from '@shared/utils/downloadFile';
import { FileLoader } from '@shared/widgets/FileLoader';
import { PreviewModal } from '@shared/widgets/PreviewModal';
import {IconButton} from "@shared/components";

import {StyledChatMenuSeparator} from "@components/chat/styles";

import SnackbarContext from 'src/contexts/SnackbarContext';
import useAuth from 'src/hooks/useAuth';
import { getStorageTree, searchStorage } from 'src/redux/features/storageSlice';
import { files_url } from 'src/settings/base-url';
import {
  convertBytes,
  doubleClickHandle,
  isFileNameImage,
  isPreviewFile,
  useOutsideTrigger,
} from 'src/utilize/helper-functions';
import { errorTypes, processResponseErrors } from 'src/utilize/processResponseErrors';
import FileIcon from 'src/assets/images/icons/icon-file.svg';
import iconDots from "@assets/images/icons/subtask-dots.svg";

import ConfirmAction from '../warnings/ConfirmAction';
import useMenuPosition from "../../hooks/useCoordinates/useMenuPosition";

import FileVersionsModal from './FileVersionsModal';
import RenameModal from './RenameModal';
import TransferCopyFilesModal from './TransferCopyFilesModal';
import {
  CatalogFile,
  CatalogFileName,
  StyledDotsMenu,
  StyledDotsMenuItem, StyledDotsWrapper,
  StyledIcon,
  StyledImageIcon,
  TitlePopup
} from './styles';



const FileDetailsRow = ({
  fileData,
  renderUserName,
  versionsModal,
  folderPath,
  removeItem,
  userCanWrite,
  openTrashStack, // стэк открытых папок корзины
  storageId,
  // addStorageFileReference,
}) => {
  const auth = useAuth();
  const [openFileVersions, setOpenFileVersions] = useState();
  const [previewModalVisible, setPreviewModalVisible] = useState();

  const { addStorageFileReference } = useStorageContext();

  const fileSrc = useMemo(() => {
    if (folderPath) {
      return {
        url: `${folderPath}/${fileData.title}?token=${auth.token}`,
        path: `${folderPath}/${fileData.title}`,
      };
    } else if (openTrashStack) {
      return {
        url: `${files_url}/storages/trash/files/${storageId}/${fileData.id}/${fileData.title}?token=${auth.token}`,
        path: `/storages/trash/files/${storageId}/${fileData.id}/${fileData.title}`,
      };
    } else {
      return {
        url: `${files_url}/storages/${fileData.path}?token=${auth.token}`,
        path: `/storages/${fileData.path}`,
      };
    }
  }, [fileData, auth, openTrashStack, storageId, folderPath]);

  const fileMenu = useRef();
  const fileMenuBtn = useRef();
  const [openFileMenu, setOpenFileMenu] = useState(false);
  const outsideClickEvent = useCallback(() => setOpenFileMenu(false), []);
  const refsArray = useRef([fileMenu, fileMenuBtn]);
  useOutsideTrigger(refsArray.current, outsideClickEvent, openFileMenu);

  const coordinates = useMenuPosition({
      triggerRef: fileMenuBtn,
      tooltipRef: fileMenu,
      isVisible: openFileMenu,
      setIsVisible: setOpenFileMenu,
      position: 'right-top',
    },
  );

  const [renameModal, setRenameModal] = useState();
  const [transferCopyModal, setTransferCopyModal] = useState();
  const [confirmTrashing, setConfirmTrashing] = useState();
  const [confirmDelete, setConfirmDelete] = useState();

  const [isSubmitting, setIsSubmitting] = useState();
  const [showFullTitle, setShowFullTitle] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const searchExpression = useSelector((state) => state.storage.searchExpression);

  const sendToTrash = () => {
    setIsSubmitting(true);
    axios
      .patch('/api/storage_files/to_trash', {
        file_id: fileData.id,
      })
      .then(() => {
        dispatch(getStorageTree({ storageId, showSnackbar }));
        if (searchExpression) dispatch(searchStorage({ storage_id: storageId, search_expression: searchExpression }));
        setConfirmTrashing(false);
        setIsSubmitting(false);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при удалении файла в корзину');
        setIsSubmitting(false);
      });
  };

  const deleteFile = () => {
    setIsSubmitting(true);
    axios
      .patch('/api/storage_files/remove', {
        file_id: fileData.id,
      })
      .then(() => {
        removeItem({ dataType: 'files', dataId: fileData.id });
        setConfirmDelete(false);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при удалении файла');
        setIsSubmitting(false);
      });
  };

  const restoreFile = () => {
    axios
      .patch('/api/restore_from_trash', {
        file_id: fileData.id,
      })
      .then(() => {
        removeItem({ dataType: 'files', dataId: fileData.id });
        dispatch(getStorageTree({ storageId, showSnackbar }));
        showSnackbar('Файл восстановлен', 'success');
      })
      .catch((e) => {
        const errorType = processResponseErrors(e);
        if (errorType === errorTypes.NO_PARENT_FOLDER) {
          showSnackbar('Ошибка при восстановлении: родительская папка была удалена');
        } else {
          showSnackbar('Возникла ошибка при восстановлении файла');
        }
      });
  };

  const onClickHandler = () => {
    // если детализация папки открыта через модальное окно версий папки
    if (versionsModal || openTrashStack)
      downloadFile({
        url: `/storages/${fileData.path}`,
        filename: fileData.title,
        token: auth.token,
      });
    // при двойном клике открыть/скачать файл
    // при одиночном клике открыть версии файла
    else {
      doubleClickHandle(
        () => {
          // if (isPreviewFile(fileData.title) || isImage) {
          //   setPreviewModalVisible(true);
          //   return;
          // }

          downloadFile({
            url: `/storages/${fileData.path}`,
            filename: fileData.title,
            token: auth.token,
          });
        },
        () => setOpenFileVersions(true),
      );
    }
  };

  const isImage = useMemo(() => isFileNameImage(fileData?.title), [fileData?.title]);

  if (!auth?.token) return '';
  return (
    <>
      <CatalogFile onClick={onClickHandler}>
        {isImage ? <StyledImageIcon src={fileSrc.url} loading="lazy" /> : <StyledIcon $fileUrl={FileIcon} />}
        <TitlePopup $active={showFullTitle}>{fileData.title}</TitlePopup>
        <CatalogFileName
          onMouseEnter={() => setShowFullTitle(true)}
          onMouseLeave={() => setShowFullTitle(false)}
        >
          {fileData.title}
        </CatalogFileName>
        <p>{convertBytes(fileData.size)}</p>
        <p>{formatDateWithDots(fileData.date_created)}</p>
        {renderUserName(fileData.creator_id)}


        <StyledDotsWrapper>
          <IconButton
            $ref={fileMenuBtn}
            icon={iconDots}
            background="#F0F0F0"
            size={24}
            style={{ backgroundSize: 'auto' }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenFileMenu(!openFileMenu);
            }}
          />
          <StyledDotsMenu
            style={{...coordinates}}
            $active={openFileMenu}
            ref={fileMenu}
            onClick={(e) => e.stopPropagation()}
          >
            <FileLoader
              url={`/storages/${fileData.path}`}
              filename={fileData.title}
              token={auth.token}
              customComponent={
                <StyledDotsMenuItem
                  onClick={() => {
                    setOpenFileMenu(false);
                  }}
                >
                  Скачать
                </StyledDotsMenuItem>
              }
            />
            <StyledChatMenuSeparator/>

            {/*{(isImage || isPreviewFile(fileData.title)) && (*/}
            {/*  <>*/}
            {/*    <StyledDotsMenuItem*/}
            {/*      onClick={() => {*/}
            {/*        setPreviewModalVisible(true);*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Предпросмотр*/}
            {/*    </StyledDotsMenuItem>*/}
            {/*    <StyledChatMenuSeparator/>*/}
            {/*  </>*/}
            {/*)}*/}

            {addStorageFileReference && !openTrashStack && (
              <StyledDotsMenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  addStorageFileReference({
                    ...fileData,
                    src: fileSrc.url,
                    name: fileData.title,
                  });
                  setOpenFileMenu(false);
                }}
              >
                Добавить ссылку на документ в сообщении
              </StyledDotsMenuItem>
            )}

            <StyledChatMenuSeparator/>

            {!versionsModal && !openTrashStack && (
              <StyledDotsMenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenFileVersions(true);
                  setOpenFileMenu(false);
                }}
              >
                История версий
              </StyledDotsMenuItem>
            )}

            <StyledChatMenuSeparator/>

            {/* функционал, когда файл не в корзине    */}
            {!openTrashStack && userCanWrite && (
              <>
                <StyledDotsMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setTransferCopyModal({ mode: 'transfer' });
                    setOpenFileMenu(false);
                  }}
                >
                  Перенести в папку этого хранилища
                </StyledDotsMenuItem>

                <StyledDotsMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setTransferCopyModal({ mode: 'copy' });
                    setOpenFileMenu(false);
                  }}
                >
                  Скопировать в другое хранилище
                </StyledDotsMenuItem>

                <StyledChatMenuSeparator/>

                <StyledDotsMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setRenameModal(true);
                    setOpenFileMenu(false);
                  }}
                >
                  Переименовать
                </StyledDotsMenuItem>

                <StyledDotsMenuItem
                  $isDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmTrashing(true);
                    setOpenFileMenu(false);
                  }}
                >
                  Удалить в корзину
                </StyledDotsMenuItem>
              </>
            )}

            {openTrashStack && userCanWrite && (
              <>
                {!!fileData.recoverable && (
                  <StyledDotsMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      restoreFile();
                      setOpenFileMenu(false);
                    }}
                  >
                    Восстановить
                  </StyledDotsMenuItem>
                )}
                <StyledChatMenuSeparator/>
                <StyledDotsMenuItem
                  $isDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmDelete(true);
                    setOpenFileMenu(false);
                  }}
                >
                  Удалить навсегда
                </StyledDotsMenuItem>
              </>
            )}
          </StyledDotsMenu>
        </StyledDotsWrapper>
      </CatalogFile>

      {openFileVersions && (
        <FileVersionsModal
          currentFileData={fileData}
          close={() => setOpenFileVersions(false)}
          renderUserName={renderUserName}
          userCanWrite={userCanWrite}
        />
      )}

      {renameModal && (
        <RenameModal
          close={() => setRenameModal(false)}
          type="file"
          data={fileData}
          storageId={storageId}
          renderUserName={renderUserName}
        />
      )}

      {transferCopyModal && (
        <TransferCopyFilesModal
          close={() => setTransferCopyModal(false)}
          data={fileData}
          fileUrl={fileSrc.url}
          type="file"
          mode={transferCopyModal.mode}
          renderUserName={renderUserName}
          storageId={storageId}
        />
      )}

      {confirmTrashing && (
        <ConfirmAction
          actionText="Вы уверены что хотите удалить этот файл в корзину?"
          cancel={() => setConfirmTrashing(false)}
          confirm={sendToTrash}
          isSubmitting={isSubmitting}
          confirmButtonText="Удалить"
        />
      )}

      {confirmDelete && (
        <ConfirmAction
          actionText="Вы уверены что хотите полностью удалить этот файл?"
          cancel={() => setConfirmDelete(false)}
          confirm={deleteFile}
          isSubmitting={isSubmitting}
          confirmButtonText="Удалить"
        />
      )}

      {/*временно убрали этот функционал*/}
      {/*{previewModalVisible && (*/}
      {/*  <PreviewModal*/}
      {/*    url={fileSrc.url}*/}
      {/*    filePath={fileSrc.path}*/}
      {/*    fileName={fileData.title}*/}
      {/*    onClose={() => setPreviewModalVisible(false)}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default FileDetailsRow;
