import { Container } from '@shared/components';
import styled from 'styled-components';

export const StyledForm = styled.form`
  display: grid;
  gap: 32px;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 12px;
  gap: 8px;
`;

export const MessageContainer = styled.div`
  padding: 16px 60px;
  background-color: ${({ theme }) => theme.mainLight};
  margin: -8px -60px 0;
`;

export const StyledMenuList = styled(Container)`
  flex-direction: column;
  width: 94%;

  & > div {
    border-radius: 8px !important;
  }
`;

export const StyledMenuTitle = styled.div`
  padding: 0 12px 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;

  cursor: auto;
`;
