import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { SingleSelect } from '@shared/components/Select/Select';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import DateInput from '@shared/components/DateInput/DateInput';

import { components } from 'react-select';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { Text } from '@shared/components/Text/Text';

import { RichTextEditor } from '@components/RichTextEditor';

import {
  useAddReminderMutation,
  useGetReminderModalDataQuery,
  useGetReminderProjectTreeQuery,
} from 'src/redux/features/api/reminders';

import { Container, HrLine } from '@shared/components';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { t } from 'i18next';

import { MessageContainer, StyledForm, StyledMenuList, StyledMenuTitle } from './styles';
import { options, reminderTypes, schema, transformFormData } from './lib';

export const CreateReminderModal = ({ projectId, taskId, messageId, message, onClose }) => {
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    watch,
    reset,
    resetField,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const watchSelectType = watch('type');
  const watchSelectProject = watch('project');
  const watchSelectTask = watch('task');

  const [addReminder] = useAddReminderMutation();

  const { data: modalData } = useGetReminderModalDataQuery();
  const { data: projectTree, isLoading: projectTreeIsLoading } = useGetReminderProjectTreeQuery(
    {
      type: reminderTypes.project,
      id: watchSelectProject?.value || projectId,
    },
    { skip: watchSelectType?.value !== reminderTypes.task },
  );

  const isProjectVisible = useMemo(
    () =>
      watchSelectType?.value === 'project' ||
      (watchSelectType?.value === reminderTypes.task && !!watchSelectTask?.value && !!watchSelectProject?.value),
    [watchSelectType, watchSelectTask, watchSelectProject],
  );

  const currentReminderType = useMemo(() => {
    if (messageId !== undefined) {
      return reminderTypes.message;
    }

    if (projectId !== undefined && taskId === undefined) {
      return reminderTypes.project;
    }

    if (taskId !== undefined) {
      return reminderTypes.task;
    }
  }, [projectId, taskId, messageId]);

  const typeOptions =
    messageId !== undefined || taskId !== undefined
      ? options
      : options.filter((opt) => opt.value !== reminderTypes.message && opt.value !== reminderTypes.task);

  const projectOptions = useMemo(
    () => modalData?.projects.map((project) => ({ label: project.title, value: project.id })),
    [modalData],
  );

  const taskOptions = useMemo(
    () => projectTree?.project?.tasks.map((task) => ({ label: task.title, value: task.id })),
    [projectTree],
  );

  const { showSnackbar } = useContext(SnackbarContext);

  const CustomMenuList = ({ children, ...props }) => {
    return (
      <components.MenuList {...props}>
        <Container justify={'center'}>
          <StyledMenuList>{children}</StyledMenuList>
        </Container>
      </components.MenuList>
    );
  };

  const CustomMenu = ({ children, ...props }) => {
    const projectOption = watchSelectProject;

    return (
      <components.Menu {...props}>
        <Container direction={'column'} gap={'8px'} style={{ padding: '12px 0' }}>
          {projectOption?.value && !!projectTree?.project?.tasks.length && (
            <>
              <StyledMenuTitle>{projectOption?.label}</StyledMenuTitle> <HrLine />
            </>
          )}

          {children}
        </Container>
      </components.Menu>
    );
  };

  const onSubmit = (data) => {
    addReminder(transformFormData({ data, id: messageId || taskId || projectId })).then(() => {
      showSnackbar('Напоминание успешно создано', 'success');
      onClose();
    });
  };

  useEffect(() => {
    reset({
      type: options.filter((opt) => opt.value === currentReminderType)[0],
      project:
        currentReminderType === reminderTypes.project || currentReminderType === reminderTypes.task
          ? projectOptions?.filter((project) => project.value === Number(projectId))[0]
          : undefined,
      task:
        currentReminderType === reminderTypes.task
          ? taskOptions?.filter((task) => task.value === Number(taskId))[0]
          : undefined,
    });
  }, [projectId, taskId, messageId, modalData]);

  useEffect(() => {
    if (watchSelectType?.value === reminderTypes.task && !projectTreeIsLoading) {
      !!Number(taskId)
        ? resetField('task', { defaultValue: taskOptions?.filter((opt) => opt.value === Number(taskId))[0] })
        : resetField('task', { defaultValue: null });
    }
  }, [taskOptions, projectTreeIsLoading]);

  return (
    <>
      <Modal
        type="submit"
        modalSize="767px"
        title="Создать напоминание"
        confirmButtonText={'Создать'}
        onSave={handleSubmit(onSubmit)}
        onClose={() => {
          if (isDirty) {
            setConfirmModal(true);
            return;
          }

          onClose();
        }}
        disabledSaveButton={isValid === false}
      >
        {messageId && (
          <MessageContainer>
            <Text tag="p">
              <b>Напоминание на основе сообщения:</b> {message}
            </Text>
          </MessageContainer>
        )}

        <StyledForm>
          {!(watchSelectType?.value === reminderTypes.message) && (
            <div>
              <Label>Тип напоминания</Label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <SingleSelect {...field} options={typeOptions} isDisabled={!!projectId || !!messageId || taskId} />
                )}
              />
            </div>
          )}

          {isProjectVisible && (
            <div>
              <Label>Проект</Label>
              <Controller
                name="project"
                control={control}
                render={({ field }) => (
                  <SingleSelect
                    {...field}
                    onChange={(value) => {
                      field.onChange(value);
                      resetField('task', { defaultValue: null });
                    }}
                    options={projectOptions}
                    isDisabled={!!projectId}
                  />
                )}
              />
            </div>
          )}

          {watchSelectType?.value === reminderTypes.task && (
            <div>
              <Label>Задача</Label>
              <Controller
                name="task"
                control={control}
                render={({ field }) => (
                  <SingleSelect
                    {...field}
                    options={taskOptions}
                    components={{ Menu: CustomMenu, MenuList: CustomMenuList }}
                    isLoading={projectTreeIsLoading}
                    isDisabled={!!taskId}
                  />
                )}
              />
            </div>
          )}

          <div>
            <Label>Текст напоминания</Label>

            <Controller name="text" control={control} render={({ field }) => <RichTextEditor {...field} />} />
          </div>

          <div>
            <Label>Срок исполнения</Label>
            <StyledModalRowEnd>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    placeholder="Дата"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    timeIntervals={15}
                    timeCaption="Время"
                    timeFormat="p"
                    dateFormat="Pp"
                    showTimeSelect
                  />
                )}
              />
            </StyledModalRowEnd>
          </div>
        </StyledForm>
      </Modal>

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onClose}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </>
  );
};
