export const getFilesFromChats = (searchResults) => {
  const projectFiles = searchResults?.projects.reduce((acc, project) => {
    const files = project.chatMessageFiles.map((file) => ({
      project_id: project.project_id,
      project_title: project.project_title,
      location: 'project',
      ...file,
    }));
    return [...acc, ...files];
  }, []);

  const taskFiles = searchResults?.tasks.reduce((acc, task) => {
    const files = task.chatMessageFiles.map((file) => ({
      project_id: task.root_project_id,
      project_title: task.root_project_title,
      task_id: task.task_id,
      task_title: task.task_title,
      location: 'task',
      ...file,
    }));
    return [...acc, ...files];
  }, []);

  const surveysFiles = searchResults?.surveys.reduce((acc, survey) => {
    const files = survey.chatMessageFiles.map((file) => ({
      survey_id: survey.survey_id,
      survey_title: survey.survey_title,
      location: 'survey',
      ...file,
    }));
    return [...acc, ...files];
  }, []);

  const newsFiles = searchResults?.news.reduce((acc, _news) => {
    const files = _news.chatMessageFiles.map((file) => ({
      news_id: _news.news_id,
      news_title: _news.news_title,
      location: 'news',
      ...file,
    }));
    return [...acc, ...files];
  }, []);

  const blogsFiles = searchResults?.blogs.reduce((acc, blog) => {
    const files = blog.chatMessageFiles.map((file) => ({
      blog_id: blog.blog_id,
      blog_title: blog.blog_title,
      location: 'blog',
      ...file,
    }));
    return [...acc, ...files];
  }, []);

  return [...projectFiles, ...taskFiles, ...newsFiles, ...blogsFiles, ...surveysFiles];
};
