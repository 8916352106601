import NotFoundComponent from '@shared/components/NotFoundComponent/NotFoundComponent';

function NotFound() {
  return (
    <main>
      <NotFoundComponent />
    </main>
  );
}

export default NotFound;
