import { Controller } from 'react-hook-form';
import { IconButton } from '@shared/components';
import iconEdit from '@assets/images/icons/edit.svg';
import iconDelete from '@assets/images/icons/delete.svg';

import Contact from './company/Contact';

import { StyledButtonsWrapper, StyledCompanyWrapper } from './styles';

const ContactCardEditable = ({ contact, control, index, update, remove }) => {
  return (
    <StyledCompanyWrapper columns="1fr max-content">
      <Contact columns="1fr" contact={contact} />

      <StyledButtonsWrapper>
        <Controller
          name="contactToUpdate"
          control={control}
          render={({ field }) => {
            return (
              <IconButton
                size={18}
                icon={iconEdit}
                onClick={() => {
                  field.onChange({ contact, index });
                  document.getElementById('contactAddNameInput')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }}
              />
            );
          }}
        />
        <IconButton
          size={18}
          icon={iconDelete}
          onClick={() => {
            if (contact.partner_contacts_id !== undefined) return update(index, { ...contact, toDelete: true });
            remove(index);
          }}
        />
      </StyledButtonsWrapper>
    </StyledCompanyWrapper>
  );
};

export default ContactCardEditable;
