import { Fragment, useMemo, useRef } from 'react';
import moment from 'moment';

import {
  StyledVacation,
  VacationStickyCol,
  VacationTable,
  VacationTableDay, VacationTableWeek
} from "@components/employees/vacations/styles";

import VacationRow from './VacationRow';

const weekStart = moment().startOf('isoWeek');

const VacationsTimetable = ({ employeeVacations }) => {
  // для рендера шапки таблицы, начиная от понедельника текущей недели и до последнего отпуска
  const { datesRow, daysRow } = useMemo(() => {
    if (!employeeVacations) return {};
    const datesRow = [];
    const daysRow = [];

    let numWeeks = 0;

    const momentDates = [weekStart.clone().add(27, 'days')];

    for (const department of employeeVacations) {
      for (const vacationData of department.in_vacation) {
        momentDates.push(moment(vacationData.date_to));
      }
      for (const vacationData of department.to_vacation) {
        momentDates.push(moment(vacationData.date_to));
      }
    }
    const latestVacationDay = moment.max(momentDates);
    const diff = latestVacationDay.diff(weekStart, 'weeks');

    numWeeks = diff + 4;

    const startingDate = weekStart.clone().subtract(1, 'days');

    for (let i = 0; i < numWeeks; i++) {
      datesRow.push(
        <VacationTableWeek colSpan="7" key={i}>
          {`${startingDate.add(1, 'days').format('L')} - ${startingDate.add(6, 'days').format('L')}`}
        </VacationTableWeek>,
      );
      daysRow.push(
        <Fragment key={i}>
          <VacationTableDay>пн</VacationTableDay>
          <VacationTableDay>вт</VacationTableDay>
          <VacationTableDay>ср</VacationTableDay>
          <VacationTableDay>чт</VacationTableDay>
          <VacationTableDay>пт</VacationTableDay>
          <VacationTableDay>сб</VacationTableDay>
          <VacationTableDay>вс</VacationTableDay>
        </Fragment>,
      );
    }
    return { datesRow, daysRow };
  }, [employeeVacations]);

  // для прокрутки по горизонтали при зажатии на ЛКМ
  const mouseLastPositionX = useRef(null);

  const trackMouseMove = useRef((e) => {
    if (!mouseLastPositionX.current) return;
    const direction = mouseLastPositionX.current - e.pageX;
    mouseLastPositionX.current = e.pageX;
    e.currentTarget.scrollLeft += direction;
  });

  const untrackMouseMove = useRef((e) => {
    mouseLastPositionX.current = null;
    e.currentTarget.classList.remove('grabbing');
  });

  return (
      <StyledVacation
        onMouseDown={(e) => {
          mouseLastPositionX.current = e.pageX;
          e.currentTarget.classList.add('grabbing');
        }}
        onMouseUp={untrackMouseMove.current}
        onMouseLeave={untrackMouseMove.current}
        onMouseMove={trackMouseMove.current}
      >
        <VacationTable>
          <thead>
            <tr>
              <VacationStickyCol rowSpan="2">
                Отдел
              </VacationStickyCol>
              {datesRow}
            </tr>
            <tr>{daysRow}</tr>
          </thead>
          <tbody>
            {employeeVacations?.map((department, i) => {
              const vacationsArr = [...department.to_vacation, ...department.in_vacation];
              const vacationsToRender = [];
              let depttRowSpan = 0;

              for (const item of vacationsArr) {
                const vacationStartDate = moment(item.date_from);
                const vacationEndDate = moment(item.date_to);
                if (vacationEndDate.diff(weekStart, 'days') < 0) continue;
                const offsetDays = vacationStartDate.diff(weekStart, 'days');
                let vacationDaysLeft = vacationEndDate.diff(vacationStartDate, 'days');
                if (offsetDays < 0) vacationDaysLeft += offsetDays;
                else if (offsetDays >= 0) vacationDaysLeft++;
                if (vacationDaysLeft <= 0) continue;
                depttRowSpan++;
                const vacationData = { ...item };
                vacationData.vacationDaysLeft = vacationDaysLeft;
                vacationData.offsetDays = offsetDays;
                vacationsToRender.push(vacationData);
              }

              if (!vacationsArr.length) return null;
              return (
                <Fragment key={i}>
                  {vacationsToRender.map((vacationItem, index, arr) => (
                    <VacationRow
                      key={vacationItem.id}
                      depttRowSpan={index === 0 ? depttRowSpan : null}
                      index={index}
                      isLast={arr.length - 1 === index}
                      depttTitle={department.title}
                      vacationRowData={vacationItem}
                      weekStart={weekStart}
                      approved={vacationItem.approved === 'yes'}
                    />
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </VacationTable>
      </StyledVacation>
  );
};

export default VacationsTimetable;
