import styled from 'styled-components';

export const DropzoneText = styled.p`
  pointer-events: none;
  padding-top: 30px;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:before {
    height: 22px;
    top: 0;
    width: 2px;
  }
  &:after,
  &:before {
    background-color: #8c8c8c;
    content: '';
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  &:after {
    height: 2px;
    top: 11px;
    width: 22px;
  }
`;

export const DropzoneWrapper = styled.section`
  background-color: #f5f5f5;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  color: #8c8c8c;
  padding: 24px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #fff;
    color: #262626;
  }
  &:hover ${DropzoneText} {
    &:before,
    &:after {
      -webkit-transform: translateX(-50%) scale(1.1);
      transform: translateX(-50%) scale(1.1);
    }
  }

  ${({ $isDragging }) =>
    $isDragging &&
    ` 
      background-color: #fff;
      color: #262626;
      ${DropzoneText} {
        &:before,
        &:after {
          -webkit-transform: translateX(-50%) scale(1.1);
          transform: translateX(-50%) scale(1.1);
        }
      }
    `}

  input {
    display: none;
  }
`;
