import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';
import * as yup from 'yup';

export const getOptions = (users) => {
  const options = users.map((user) => ({
    label: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    value: user.id,
  }));

  return options;
};

export const schema = yup.object({
  user: yup.object().required(),
  title: yup.string().required(),
  description: yup.object(),
  date: yup.date(),
});

export const transformFormData = (data, messageId, formId) => {
  return {
    row: {
      title: data.title,
      description: stateToHTML(data.description.getCurrentContent()),
      executor_id: data.user.value,
      base_chat_message_id: messageId,
      date_finish: moment.utc(data.date).format('YYYY[/]MM[/]D') + ' ' + moment.utc(data.date).format('LT'),
    },
    form_id: formId,
  };
};
