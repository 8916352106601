import styled from 'styled-components';
import iconDelete from '@assets/images/icons/delete-circle.svg';

const DeleteCircleButton = ({onClick, onMouseDown, size, disabled}) => {
  return <StyledDeleteCircleButton
            size={size}
            icon={iconDelete}
            onClick={onClick}
            onMouseDown={onMouseDown}
            disabled={disabled}
          />
}

const StyledDeleteCircleButton = styled.button`
  background-image: url(${props => props.icon});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: none;
  display: inline-flex;
  height: ${props => props.size || 15}px;
  margin-left: 8px;
  width: ${props => props.size || 15}px;

  &:hover {
    filter: brightness(0) saturate(100%) invert(20%) sepia(99%) saturate(4859%) hue-rotate(349deg) brightness(102%) contrast(92%);
  }

  ${props => props.disabled && `
    cursor: not-allowed;
  `}
`;

export default DeleteCircleButton;
