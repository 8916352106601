// Список сообщений работает по принципу загрузки последних 20 сообщений с прокруткой вверх для загрузки
// предыдущих сообщений.
// Если юзер нажимает на сообщение, которое еще не загружено (например в сообщении-ответе на старое сообщение)
// то загружаются 40 старых сообщений с интересующим сообщением по-середине. В таком случае, прокрутку можно
// делать в обе стороны для загрузки предыдущих или последующих сообщений.

import axios from 'axios';
import {useEffect, useRef, useState, useCallback, useContext, useMemo} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useSwipeable} from 'react-swipeable';

import SnackbarContext from 'src/contexts/SnackbarContext';
import NotFound from '@shared/components/NotFoundComponent/NotFoundComponent';
import ModalForwardMessage from '@components/chat/ModalForwardMessage/ModalForwardMessage';
import {
  addPreviousOldMessages,
  addNextOldMessages,
  resetOldMessagesState,
  getOldMessages,
  setColoredMsgId,
  setIsScrolledChatBottom,
} from 'src/redux/features/messagesSlice';
import {
  clearProjectStructure,
  // selectGuestProjectRights,
} from 'src/redux/features/projectsSlice';
import SingleProjectHeader from '@components/chat/project-header/SingleProjectHeader';
import SingleProjectChat from 'src/components/chat/SingleProjectChat';
import MessageContainer from 'src/components/chat/message/MessageContainer';
import Preloader from 'src/components/preloaders/Preloader';

import {requestPreviousMessages, requestNextMessages, formatMessages} from 'src/utilize/message-helper-functions';

import {StyledChat, StyledChatContainer, StyledChatInner, StyledChatPage, StyledChatWrapper} from './styles.js';

const BirthdayChatPage = () => {
  const {birthdayId} = useParams();
  const dispatch = useDispatch();
  const initialScrollToLastMessage = useRef(false);
  const specificChatStatusLoadedOnce = useRef(false);

  const [birthdayData, setBirthdayData] = useState();
  const [notFound, setNotFound] = useState();
  const [pinnedMessages, setPinnedMessages] = useState();
  const [messagesList, setMessagesList] = useState([]);
  // для показа значка загрузки при загрузке предыдущих сообщений
  const [messagesLoading, setMessagesLoading] = useState('');
  // для показа значка загрузки при загрузке последующих сообщения с сервера (когда юзер открыл старое сообщение)
  const [nextMessagesLoading, setNextMessagesLoading] = useState('');

  // после ухода из страницы чата, очистить все данные по старым сообщениям (на случай перехода на страницу другого чата)
  useEffect(() => {
    return () => {
      if (birthdayData?.id) {
        dispatch(resetOldMessagesState());
        dispatch(clearProjectStructure());
      }
    };
  }, [dispatch, birthdayId]);

  // нужно для прокрутки страницы чата к загруженным сообщениям
  const currentScrollHeight = useRef();
  const chatPageRef = useRef();

  const scrollToLastMessage = useCallback(() => {
    chatPageRef.current?.scrollTo(0, chatPageRef.current.scrollHeight);
    dispatch(setIsScrolledChatBottom(true));
  }, []);

  // прокрутить к последнему сообщению, при первоначальной загрузке страницы
  useEffect(() => {
    if (messagesList.length && pinnedMessages && !initialScrollToLastMessage.current) {
      scrollToLastMessage();
      initialScrollToLastMessage.current = true;
    }
  }, [messagesList, pinnedMessages]);

  //#region GET PROJECT DATA
  const getBirthdayData = useCallback(async () => {
    const url = `/api/birthday_data/${birthdayId}`;

    return axios
      .get(url)
      .then((response) => {
        const {birthdayData: result} = response.data;

        if (Array.isArray(result) && !result.length) {
          setNotFound(true);
          return null;
        }
        const birthdayData = result;
        setBirthdayData(birthdayData);
        return birthdayData;
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          setNotFound(true);
          return;
        }
        showSnackbar('Возникла ошибка во время запроса данных');
      });
  }, [birthdayId]);
  //#endregion

  // запросить с сервера данные по текущему пользователю
  useEffect(() => {
    const getData = async () => {
      initialScrollToLastMessage.current = false;
      specificChatStatusLoadedOnce.current = false;

      const birthdayData = await getBirthdayData();
      // запросить закрепленные сообщения
      if (birthdayData?.chat?.id) {
        axios
          .get(`api/pinned_messages/${birthdayData.chat.id}`)
          .then((response) => {
            const pinnedMessagesList = formatMessages({
              projectData: birthdayData,
              messages: response.data.messages.pinned_messages.reverse(),
              isPinned: true,
            });
            setPinnedMessages(pinnedMessagesList);
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при получении списка закрепленных сообщений');
          });

        // получить последние 20 сообщений чата
        const messagesList = formatMessages({
          projectData: birthdayData,
          messages: birthdayData?.chat.messages?.reverse(),
        });

        setMessagesList(messagesList);
      }
    };
    getData();
  }, [getBirthdayData]);

  const [searchParams] = useSearchParams();

  const {requestedOldMessageId, oldMessages} = useSelector((state) => state.messages);
  // const oldMessagesLoadedUpToDate = useRef(false);

  // прокрутить страницу чата до блока загруженных сообщений
  useEffect(() => {
    if ((messagesList.length > 20 || oldMessages?.length > 20) && currentScrollHeight.current) {
      const scrollDiff = chatPageRef.current.scrollHeight - currentScrollHeight.current;

      chatPageRef.current.scrollTop = scrollDiff;
      currentScrollHeight.current = null;
    }
  }, [messagesList, oldMessages]);

  // для показа кнопки прокрутки до конца страницы (до последнего сообщения)
  const [scrollToEndButton, showScrollToEndButton] = useState(false);

  const {showSnackbar} = useContext(SnackbarContext);

  const getPreviousMessages = (target) => {
    if (birthdayData?.chat) {
      const chatId = birthdayData.chat.id;
      // запрашивать данные по предыдущим сообщениям от старого сообщения, если юзер открыл старое сообщение
      if (requestedOldMessageId) {
        if (oldMessages?.length) {
          // если список старых сообщений загружен, то загружать предыдущие сообщения от
          // загруженного первого старого сообщения
          const earliestOldMessageId = oldMessages[0].id;
          setMessagesLoading('previous');
          requestPreviousMessages(chatId, earliestOldMessageId)
            .then((response) => {
              if (response.data.messages?.chat_messages?.length) {
                // определить текущую высоту прокрутки страницы
                currentScrollHeight.current = target.scrollHeight + 30;
                // добавить вновь загруженные из сервера старые сообщения к ранее загруженным старым сообщениям
                dispatch(addPreviousOldMessages(response.data.messages.chat_messages));
                if (messagesLoading !== 'endPrevious') setMessagesLoading(null);
              } else setMessagesLoading('endPrevious');
            })
            .catch(() => {
              showSnackbar('Возникла ошибка при загрузке предыдущих сообщений');
            });
        }
      }
        // если список загруженных сообщений не пустой и юзер не открыл старое (не из последнего feeda) сообщение,
      // то выбрать наиболее раннее, для загрузки предыдущих сообщений
      else if (messagesList?.length) {
        const earliestMessageId = messagesList[0].props.id;
        setMessagesLoading('previous');
        requestPreviousMessages(chatId, earliestMessageId)
          .then((response) => {
            let previousMessages = response.data.messages?.chat_messages;
            if (previousMessages?.length) {
              currentScrollHeight.current = target.scrollHeight + 30;
              previousMessages = previousMessages.reverse().map((message) => {
                return <MessageContainer key={message.id} {...message} projectData={birthdayData}/>;
              });
            } else {
              return setMessagesLoading('end');
            }
            setMessagesList([...previousMessages, ...messagesList]);
            setMessagesLoading('');
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при загрузке предыдущих сообщений');
            setMessagesLoading('');
          });
      }
    }
  };

  //#region HANDLE CHAT SCROLL
  // получить предыдущие 20 сообщений при прокрутке вверх
  const handleScroll = useCallback(
    (element) => {
      if (element.target.scrollTop === 0 && messagesLoading !== 'loading' && messagesLoading !== 'end') {
        getPreviousMessages(element.target);
      } else if (element.target.scrollHeight - element.target.clientHeight - element.target.scrollTop < 30) {
        if (!requestedOldMessageId) {
          // скрыть кнопку-скроллер, если в конце страницы и юзер не просматривает старое сообщение
          if (scrollToEndButton) {
            showScrollToEndButton(false);
            dispatch(setIsScrolledChatBottom(true));
          }
        } else if (nextMessagesLoading !== 'loading' && birthdayData?.chat && oldMessages?.length) {
          // получить последующие от старого сообщения, при прокрутке в конец страницы, если юзер просматривал старое сообщение
          const lastMessageId = oldMessages[oldMessages.length - 1].id;
          setNextMessagesLoading('loading');

          requestNextMessages(birthdayData.chat.id, lastMessageId)
            .then((response) => {
              if (response.data.messages?.end) {
                const messages = formatMessages({
                  projectData: birthdayData,
                  messages: [...oldMessages, ...response.data.messages.chat_messages],
                });
                setMessagesList(messages);
                // oldMessagesLoadedUpToDate.current = true;
                dispatch(resetOldMessagesState());
                return;
              }

              dispatch(addNextOldMessages({messages: response.data.messages?.chat_messages}));
            })
            .catch(() => showSnackbar('Возникла ошибка при загрузке последующих сообщений'))
            .finally(() => setNextMessagesLoading(null));
        }
      } else if (!scrollToEndButton) {
        // показать кнопку-скроллер в конец страницы, если юзер не находится в конце страницы
        showScrollToEndButton(true);
        dispatch(setIsScrolledChatBottom(false));
      }
    },
    [messagesLoading, requestedOldMessageId, scrollToEndButton, oldMessages, nextMessagesLoading, birthdayData?.chat],
  );
  //#endregion

  // для перехода от старых сообщений к последним актуальным сообщениям
  const resetToLatestMessages = useCallback(
    (noScroll) => {
      dispatch(resetOldMessagesState());
      if (noScroll) return;
      setTimeout(scrollToLastMessage, 100);
    },
    [dispatch, messagesList],
  );

  //#region HANDLE MSG SEARCH PARAM
  // если в URL указан msgId, проверить уже загружено ли сообщение вместе с первоначалальными данными,
  // если не загружено, то сделать запрос на получение состояния чата вокруг этого сообщения
  useEffect(() => {
    const msgIdParam = searchParams.get('msg');
    if (!msgIdParam || !birthdayData) return;
    if (specificChatStatusLoadedOnce.current === msgIdParam) return;
    if (birthdayId && birthdayData.id !== +birthdayId) return;
    // указать id сообщения, которое будет подсвечиваться
    dispatch(setColoredMsgId(+msgIdParam));
    dispatch(setIsScrolledChatBottom(false));
    // проверить, есть ли в последних сообщениях, id нужного сообщения
    const messageAlreadyLoaded = birthdayData.chat.messages.find((msg) => msg.id === +msgIdParam);
    if (messageAlreadyLoaded) {
      if (messagesList) {
        // если требуемое сообщение находится в последних сообщениях и юзер просматривает старые сообщения,
        // то показать последние сообщения
        if (oldMessages) {
          resetToLatestMessages(true);
          return;
        }
        const renderedMessage = document.getElementById(`messageId${messageAlreadyLoaded.id}`);
        if (!renderedMessage) return;
        renderedMessage.scrollIntoView({block: 'center'});
        specificChatStatusLoadedOnce.current = msgIdParam;
      }
    } else {
      // если в последних сообщениях нет нужного сообщения, то запросить статус чата вокруг
      // нужного сообщения
      dispatch(
        getOldMessages({
          chat_id: birthdayData.chat.id,
          old_message_id: msgIdParam,
        }),
      );
      specificChatStatusLoadedOnce.current = msgIdParam;
    }
  }, [searchParams, birthdayData, messagesList, oldMessages]);
  //#endregion

  const [showSideMenuOnSwipe, setShowSideMenuOnSwipe] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setShowSideMenuOnSwipe((prevState) => !prevState);
    },
  });

  const {notFoundTitle, notFountDescription} = useMemo(() => {
    return {
      notFoundTitle: 'Данные не найдены.',
      notFountDescription: `Проверьте правильность введенного адреса.`,
    };
  }, [birthdayId]);

  return (
    <StyledChatWrapper {...handlers}>
      {notFound && <NotFound title={notFoundTitle} description={notFountDescription} mTop="5%"/>}

      {!notFound && (
        <>
          <StyledChat>
            <StyledChatContainer>
              <StyledChatInner>
                <StyledChatPage>
                  {(!birthdayData || !pinnedMessages) && <Preloader/>}

                  {birthdayData && pinnedMessages && (
                    <>
                      <SingleProjectHeader
                        projectData={birthdayData}
                        getProjectData={getBirthdayData}
                        pinnedMessagesList={pinnedMessages}
                        showLockButton={false}
                        showHeaderMenu={false}
                      />
                      {birthdayData.id && (
                        <SingleProjectChat
                          handleScroll={handleScroll}
                          messagesLoading={messagesLoading}
                          projectData={birthdayData}
                          messagesList={messagesList}
                          setMessagesList={setMessagesList}
                          setPinnedMessages={setPinnedMessages}
                          nextMessagesLoading={nextMessagesLoading}
                          resetToLatestMessages={resetToLatestMessages}
                          scrollToEndButton={scrollToEndButton}
                          chatPageRef={chatPageRef}
                          scrollToLastMessage={scrollToLastMessage}
                        />
                      )}
                      <ModalForwardMessage/>
                    </>
                  )}
                </StyledChatPage>
              </StyledChatInner>
            </StyledChatContainer>
          </StyledChat>
        </>
      )}
    </StyledChatWrapper>
  );
};

export default BirthdayChatPage;
