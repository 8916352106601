import {
  StyledPollCancelButton,
  StyledPollCounter,
  StyledPollDate,
  StyledPollFooter,
  StyledPollForm,
  StyledPollQuestion,
} from '@components/chat/message/MessagePoll/styles';
import { shape, string, objectOf, number, arrayOf, bool } from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { files_url } from 'src/settings/base-url';
import useAuth from 'src/hooks/useAuth';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';

import { PollResults } from './PollResults/PollResults';
import { PollOptions } from './PollOptions/PollOptions';
import { VotersListPopup } from './PollResults/VotersListPopup/VotersListPopup';

MessagePoll.propTypes = {
  pollData: shape({
    title: string,
    id: number,
    anonymous: number,
    multianswer: number,
    date_created: string,
    date_finish: string,
    chat_message_id: number,
    isMember: bool,
    poll_results: shape({
      answers: objectOf(
        shape({
          id: number,
          percentage_ratio: number,
          vote_count: number,
        }),
      ),
      waiting_vote: arrayOf(
        shape({
          id: number,
          avatar: string,
          first_name: string,
          last_name: string,
        }),
      ),
      poll_member_count: number,
    }),
    user_status: string,
  }),
  votedCount: number,
};

function MessagePoll({ pollData, votedCount }) {
  const auth = useAuth();

  const { showSnackbar } = useContext(SnackbarContext);

  const submitAnswer = useCallback(
    (answers) => {
      if (!Array.isArray(answers)) return;

      axios
        .post('/api/poll_results/add', {
          poll_id: pollData.id,
          answers,
        })
        .catch(() => showSnackbar('Не удалось проголосовать'));
    },
    [pollData.id],
  );

  const options = useMemo(() => {
    if (!pollData.poll_results?.answers) return [];
    return Object.entries(pollData.poll_results.answers)
      .map(([pollOption, value]) => {
        const answer = {
          value: value.id,
          id: value.id,
          label: pollOption,
          name: pollData.chat_message_id,
          percentage_ratio: value.percentage_ratio,
          vote_count: value.vote_count,
          members: value.members,
          onChange: (e) => submitAnswer([+e.target.value]),
        };
        if (value.image && auth?.token) {
          answer.image = `${files_url}/chat_messages/polls/files/${value.id}/${value.image}?token=${auth.token}`;
        }
        return answer;
      })
      .sort((a, b) => a.id - b.id);
  }, [pollData, submitAnswer, auth?.token]);

  const { control, getValues } = useForm({
    defaultValues: {
      answers: [],
    },
  });

  const hasDeadlinePassed = useMemo(() => {
    return Boolean(pollData.date_finish && moment().isAfter(pollData.date_finish));
  }, [pollData.date_finish]);

  const refuseVote = useCallback(() => {
    axios
      .post('/api/refuse_to_vote', {
        poll_id: pollData.id,
      })
      .catch(() => showSnackbar('Не удалось отказаться от голосования'));
  }, [pollData.id]);

  const { t } = useTranslation();

  return (
    <div>
      {hasDeadlinePassed ? (
        <StyledPollDate>
          Опрос окончен
          {(pollData.user_status === 'did not vote' && ', вы не голосовали') ||
            (pollData.user_status === 'refused_vote' && ', вы отказались от голосования')}
        </StyledPollDate>
      ) : (
        pollData.date_finish && (
          <StyledPollDate>Опрос до {moment(pollData.date_finish).format('D MMMM YYYY, HH:mm')}</StyledPollDate>
        )
      )}

      <StyledPollForm>
        <StyledPollQuestion>{pollData.title}</StyledPollQuestion>

        {(!pollData.isMember || hasDeadlinePassed || pollData.user_status !== 'did not vote') && (
          <PollResults
            isMember={pollData.isMember}
            hasdeadlinePassed={hasDeadlinePassed}
            userStatus={pollData.user_status}
            options={options}
            pollId={pollData.id}
            userAnswer={pollData.user_answer}
            isAnonymous={Boolean(pollData.anonymous)}
          />
        )}

        {pollData.isMember && pollData.user_status === 'did not vote' && !hasDeadlinePassed && (
          <PollOptions
            pollData={pollData}
            options={options}
            control={control}
            getValues={getValues}
            votedCount={votedCount}
            submitAnswer={submitAnswer}
          />
        )}

        <StyledPollFooter>
          <StyledPollCounter>
            {t('Chat.votedMembers', { count: votedCount, total: pollData.poll_results.poll_member_count })}
          </StyledPollCounter>

          {Array.isArray(pollData.poll_members) && pollData.poll_members.length > 0 && (
            <VotersListPopup members={pollData.poll_members} />
          )}

          {pollData.isMember && pollData.user_status === 'did not vote' && !hasDeadlinePassed && (
            <StyledPollCancelButton type="button" onClick={refuseVote}>
              Отказаться от голосования
            </StyledPollCancelButton>
          )}
        </StyledPollFooter>
      </StyledPollForm>
    </div>
  );
}

export default MessagePoll;
