import { useLocation, matchPath } from 'react-router-dom';
// react-router не определяет параметры в url за пределами соответствующих компонентов.
// данная функция вручную проверяет текущий url на соответствие адресам с параметрами
function useParamsCustom(pathsArr) {
  const { pathname } = useLocation();
  let params = {};
  for (const path of pathsArr) {
    const match = matchPath({ path }, pathname);
    if (match?.params) {
      params = { ...params, ...match.params };
    }
  }
  return params;
}

export default useParamsCustom;
