import { rtkQueryApi } from './rtkQueryApi';

export const requestsApi = rtkQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    getActualRequests: builder.query({
      query: () => '/api/actual_requests',
      providesTags: ['ActualRequests'],
    }),
    getRequestsForMe: builder.query({
      query: () => '/api/requests_for_me',
      providesTags: ['RequestsForMe'],
    }),
    getRequestsFromMe: builder.query({
      query: () => '/api/requests_from_me',
      providesTags: ['RequestsFromMe'],
    }),
  }),
  tagTypes: ['ActualRequests', 'RequestsForMe', 'RequestsFromMe'],
});

export const { useGetActualRequestsQuery, useGetRequestsFromMeQuery, useGetRequestsForMeQuery } = requestsApi;
