import { useRef, useState, useContext, useMemo } from 'react';

import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { Input } from '@shared/components/Input/Input';
import { DragnDrop } from '@shared/components/DragnDrop/DragnDrop';
import {Container} from "@shared/components";
import { stateToHTML } from 'draft-js-export-html';

import { RichTextEditor } from '@components/RichTextEditor';
import DropFilesWrapperContainer from '@components/files/DropFilesWrapper';

import { ContentState, EditorState, convertFromHTML } from 'draft-js';

import { randomSequence, useEventTriggerOnEscPress } from '../../utilize/helper-functions';

import FilesUploadManage from '../../components/files/FilesUploadManage';
import SnackbarContext from '../../contexts/SnackbarContext';
import ConfirmAction from '../../components/warnings/ConfirmAction';
import { files_url } from '../../settings/base-url';
import useAuth from '../../hooks/useAuth';


const formSchema = object({
  title: string().required(),
  description: object(),
});

const CreateEditNewsModal = ({ close, type, dataToEdit, getData }) => {
  const formId = useRef(randomSequence());

  const [isSubmitting, setIsSubmitting] = useState();
  const { showSnackbar } = useContext(SnackbarContext);

  const navigate = useNavigate();

  const [filesDeleteArr, setFilesDeleteArr] = useState([]);

  const submitData = async (data) => {
    setIsSubmitting(true);
    const submitData = {
      row: {
        title: data.title,
        description: stateToHTML(data.description.getCurrentContent()),
        type: type,
      },
      form_id: formId.current,
    };

    if (dataToEdit) {
      submitData.news_blog_id = dataToEdit.id;
      if (filesDeleteArr.length) {
        submitData.deleted_files = filesDeleteArr;
      }
      axios
        .put('/api/news_blogs/edit', submitData)
        .then(() => {
          close();
          getData();
        })
        .catch(() => {
          showSnackbar('Ошибка при отправке запроса');
        });
    } else {
      axios
        .post('/api/news_blogs/add', submitData)
        .then((r) => {
          navigate(`/${type === 'news' ? 'news' : 'blogs'}/${r.data.result}`);
          close();
        })
        .catch(() => {
          setIsSubmitting(false);
          showSnackbar('Ошибка при отправке запроса');
        });
    }
  };

  const defaultEditorState = useMemo(() => {
    return dataToEdit?.description
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(String(dataToEdit.description)).contentBlocks,
            convertFromHTML(String(dataToEdit.description)).entityMap,
          ),
        )
      : EditorState.createEmpty();
  }, [dataToEdit]);

  const {
    register,
    control,
    handleSubmit,
    formState: { isValid, dirtyFields },
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      title: dataToEdit?.title || '',
      description: defaultEditorState,
    },
  });
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const removeFilesOnClose = useRef();

  const [modalClosePrompt, setModalClosePrompt] = useState();

  const closeModal = () => {
    if (removeFilesOnClose.current) removeFilesOnClose.current();
    close();
  };

  const handleCloseBtnClick = () => {
    const descriptionPlainText = getValues('description')?.getCurrentContent().getPlainText();

    if (
      dirtyFields['title'] ||
      descriptionPlainText !== defaultEditorState.getCurrentContent().getPlainText() ||
      uploadingFiles.length ||
      filesDeleteArr.length
    ) {
      return setModalClosePrompt(true);
    }
    closeModal();
  };

  useEventTriggerOnEscPress(handleCloseBtnClick);

  const auth = useAuth();

  const filesToEdit = useMemo(() => {
    if (dataToEdit?.files && auth?.token) {
      return dataToEdit.files.map((file) => ({
        ...file,
        name: file.file,
        src: `${files_url}/news_blogs/files/${dataToEdit.id}/${file.file}?token=${auth.token}`,
      }));
    }
  }, [dataToEdit, auth]);

  const filesAddFormData = useMemo(() => {
    if (!dataToEdit) return;
    return { type: 'news_blog_id', id: dataToEdit.id };
  }, [dataToEdit]);

  const removeFilesReqData = useMemo(() => {
    const reqBody = {};
    if (dataToEdit) reqBody.news_blog_id = dataToEdit.id;
    else reqBody.form_id = formId.current;
    return reqBody;
  }, [dataToEdit]);

  return (
    <Modal
      title={`Создать ${type === 'news' ? 'новости' : 'блог'}`}
      onClose={handleCloseBtnClick}
      disabledSaveButton={!isValid || isSubmitting}
      onSave={handleSubmit(submitData)}
      confirmButtonText={`${dataToEdit ? 'Сохранить' : 'Создать'}`}
    >
      <DropFilesWrapperContainer setFiles={setUploadingFiles}>
        <div>
          <Label htmlFor="name">Заголовок</Label>
          <Input id="name" placeholder="Введите заголовок" register={register('title')} />
        </div>

        <div>
          <Label htmlFor="name">Описание</Label>
          <Controller control={control} name="description" render={({ field }) => <RichTextEditor {...field} />} />
        </div>

        <DragnDrop setUploadingFiles={setUploadingFiles} />
        {(uploadingFiles.length > 0 || dataToEdit?.files.length > 0) && (
          <Container wrap="wrap" gap="16px">
            <FilesUploadManage
              filesToUpload={uploadingFiles}
              filesToEdit={filesToEdit}
              filesDeleteArr={filesDeleteArr}
              setFilesDeleteArr={setFilesDeleteArr}
              formId={formId.current}
              filesAddFormData={filesAddFormData}
              removeFilesOnClose={removeFilesOnClose}
              filesUploadPath="/api/news_blogs/files/add"
              filesDeletePath="/api/news_blogs/files/remove"
              removeFilesReqBody={removeFilesReqData}
            />
          </Container>
        )}
      </DropFilesWrapperContainer>

      {modalClosePrompt && (
        <ConfirmAction
          confirm={closeModal}
          cancel={() => setModalClosePrompt(false)}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
    </Modal>
  );
};

export default CreateEditNewsModal;
