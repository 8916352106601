import { styled } from 'styled-components';

export const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const StyledIndexPost = styled.section`
  align-items: flex-start;
  display: flex;
  margin-bottom: 24px;
`

export const StyledPostImage = styled.div`
  flex: 0 0 53px;
  height: 40px;
  margin-right: 16px;
  object-fit: cover;
  width: 53px;
  
  & img {
    border-radius: 4px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`

export const StyledPostMessage = styled.div`
  align-items: start;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  transition: background-color 0.3s ease-in-out;
`

export const StyledPostDate = styled.p`
  color: #8c8c8c;
  font-size: 12px;
  line-height: 20px;
`
