import { StyledModalHeaderButton } from '@shared/components';
import styled from 'styled-components';

export const StyledAddFilesButton = styled(StyledModalHeaderButton)``;

export const StyledProfileAttachList = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
  border-top: 1px solid ${({ theme }) => theme.borderSecondary};
  padding: 32px 0;
  display: flex;
  flex-wrap: wrap;
`;
