import ConfirmAction from '@components/warnings/ConfirmAction';
import { IconButton } from '@shared/components';
import React, { useState } from 'react';
import iconDownload from '@assets/images/icons/icon-download.svg';
import { downloadFile } from '@shared/utils/downloadFile';
import axios from 'axios';
import { getFileSizeUrl } from 'src/settings/base-url';

export const FileLoader = ({ url, filename, token, customComponent, customDownloadHanlder, size, isFolder }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [fileSize, setFileSize] = useState();

  const limit = 50;

  const getMegabytes = (size) => Number(size / 1024 / 1024).toFixed(2);

  const getFileSize = () => axios.get(`${getFileSizeUrl}${encodeURIComponent(url)}`);

  const onConfirm = () => {
    customDownloadHanlder ? customDownloadHanlder() : downloadFile({ url: url, filename: filename, token: token });
    setConfirmModal(false);
  };

  const onCloseHandler = () => setConfirmModal(false);

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (customDownloadHanlder) {
      let currentSize = getMegabytes(size);
      setFileSize(currentSize);
      currentSize >= limit ? setConfirmModal(true) : customDownloadHanlder();
      return;
    }

    getFileSize().then(({ data }) => {
      let size = getMegabytes(data.size);
      setFileSize(size);
      size >= limit ? setConfirmModal(true) : downloadFile({ url: url, filename: filename, token: token });
    });
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {customComponent ? (
        <div onClick={onClickHandler}>{customComponent}</div>
      ) : (
        <IconButton size={14} icon={iconDownload} onClick={onClickHandler} />
      )}
      {confirmModal && (
        <ConfirmAction
          cancel={onCloseHandler}
          confirm={onConfirm}
          actionText={`Вы уверены, что хотите скачать ${isFolder ? 'папку' : 'файл'}? ${
            isFolder ? 'Она' : 'Он'
          } весит ${fileSize} Мб`}
          confirmButtonText={'Да'}
          cancelButtonText={'Нет'}
          isSuccess
        />
      )}
    </div>
  );
};
