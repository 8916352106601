import {css, styled} from "styled-components";

export const StyledPagination = styled.section`
  display: flex;
  padding: 40px 0;
  gap: 8px;
`

export const StyledPaginationItem = styled.button`
  align-items: center;
  background-color: ${({theme}) => theme.lightest};
  border: 1px solid ${({theme}) => theme.border};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  transition: all 0.3s ease-in-out;
  width: 32px;
  position: relative;
  
  &:hover {
    border-color: ${({theme}) => theme.main};
    color: ${({theme}) => theme.main};
    
    &::after {
      border-bottom: 1px solid ${({theme}) => theme.main};
      border-right: 1px solid ${({theme}) => theme.main};
    }
  }
  
  ${props => props.$active && css`
    border-color: ${({theme}) => theme.main};
    color: ${({theme}) => theme.main};
    pointer-events: none;
    cursor: auto;
    font-weight: 600;
  `}
  
  &[disabled] {
    background-color: ${({theme}) => theme.disabledSecondary};
    pointer-events: none;

    &::after {
      border-bottom: 1px solid ${({theme}) => theme.disabled};
      border-right: 1px solid ${({theme}) => theme.disabled};
    }
  }
`
export const StyledPaginationPrev = styled(StyledPaginationItem)`
  &::after {
    transform: translate(-30%, -50%) rotate(135deg);
    border-bottom: 1px solid ${({theme}) => theme.darkest};
    border-right: 1px solid ${({theme}) => theme.darkest};
    content: '';
    height: 7px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 7px;
    transition: all 0.3s ease-in-out;
  }
`

export const StyledPaginationNext = styled(StyledPaginationItem)`
  &::after {
    transform: translate(-70%, -50%) rotate(-45deg);
    border-bottom: 1px solid ${({theme}) => theme.darkest};
    border-right: 1px solid ${({theme}) => theme.darkest};
    content: '';
    height: 7px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 7px;
    transition: all 0.3s ease-in-out;
  }
`