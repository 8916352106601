import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {StorageAside, StorageAsideButton, StorageRubric, StorageRubricLink} from "@components/storage/styles";

import iconCatalogOpen from "@assets/images/icons/icon-folder-open.svg";
import iconCatalogClose from "@assets/images/icons/icon-folder-close.svg";
import iconDelete from "@assets/images/icons/delete.svg";

import {setOpenFolder} from '../../redux/features/storageSlice';

import FolderAside from './FolderAside';


const StorageTree = ({handleFolderSelect, openFolderId}) => {
  // const { folderId, storageId } = useParams();
  const params = useParams();
  const storageTree = useSelector((state) => state.storage.storageTree);
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openCatalog, setOpenCatalog] = useState(false);

  // установить корневой каталог как открытый при первоначальном рендере
  useEffect(() => {
    if (storageTree && !openFolderId && !params['*'].includes('/bin')) {
      dispatch(setOpenFolder({openFolder: storageTree, folderPath: null}));
    }
  }, [storageTree, dispatch, params, openFolderId]);

  return (
    <>
      <StorageAsideButton
        $active={openCatalog}
        onClick={() => setOpenCatalog(!openCatalog)}
        iconClose={iconCatalogClose}
        iconOpen={iconCatalogOpen}
      >
        Каталог
      </StorageAsideButton>
      <StorageAside
        $active={openCatalog}
      >
        <StorageRubric>
          {storageTree?.folders?.map((folder, i) => (
            <FolderAside
              key={i}
              data={folder}
              folderIdParam={openFolderId}
              storageId={params.storageId}
              handleSelect={handleFolderSelect}
            />
          ))}
        </StorageRubric>

        {storageTree?.id && (
          <StorageRubricLink
            $isDelete
            icon={iconDelete}
            // onClick={() => navigate(`/storages/${params.storageId}/bin`)}
            onClick={() => handleFolderSelect('bin')}
          >
            Корзина
          </StorageRubricLink>
        )}
      </StorageAside>
    </>
  );
};

export default StorageTree;
