import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isEmpty } from 'src/utilize/helper-functions';

const initialState = {
  notFound: false,
  storageData: null,
  storageTree: null,
  isLoading: false,
  isLoadingStorageData: false,
  isLoadingStorageTree: false,
  error: null,
  storageTreeError: null,
  openFolder: null,
  isSearchingStorage: false,
  searchExpression: null,
  storageSearchResult: null,
};

export const getStorageData = createAsyncThunk('storage/getStorageData', async (payload, thunkAPI) => {
  try {
    const response = await axios.get(`/api/storage_view/${payload.storageId}`);

    return response.data.result;
  } catch (e) {
    payload.showSnackbar('Возникла ошибка при получении данных о хранилище');

    return thunkAPI.rejectWithValue('ERROR WHILE FETCHING STORAGE DATA');
  }
});

export const getStorageTree = createAsyncThunk('storage/getStorageTree', async (payload, thunkAPI) => {
  try {
    const response = await axios.get(`/api/storage_tree/${payload.storageId}`);
    return response.data.storage_tree;
  } catch (e) {
    if (e?.response?.status === 403) {
      return thunkAPI.rejectWithValue('UNAUTHORIZED');
    }
    payload.showSnackbar('Возникла ошибка при получении данных о хранилище');
    return thunkAPI.rejectWithValue('ERROR WHILE FETCHING STORAGE TREE');
  }
});

export const searchStorage = createAsyncThunk('storage/searchStorage', async (payload) => {
  try {
    const response = await axios.patch('api/search_in_storage', {
      search_expression: payload.search_expression,
      storage_id: payload.storage_id,
    });
    return { searchResult: response.data.result, searchExpression: payload.search_expression };
  } catch (e) {
    payload.showSnackbar('Возникла ошибка при поиске данных');
    return;
  }
});

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    clearState: (state) => {
      state = { ...initialState, isLoading: state.isLoading };
      return state;
    },
    setOpenFolder: (state, { payload }) => {
      state.openFolder = payload.openFolder;
      state.folderPath = payload.folderPath;
    },
    clearStorageSearchResults: (state) => {
      if (state.storageSearchResult) {
        state.storageSearchResult = null;
        state.isSearchingStorage = false;
        state.searchExpression = null;
      }
    },
  },
  extraReducers: {
    [getStorageData.pending]: (state) => {
      state.isLoading = true;
      state.notFound = false;
      state.isLoadingStorageData = true;
    },
    [getStorageData.fulfilled]: (state, { payload }) => {
      state.isLoadingStorageData = false;
      if (!state.isLoadingStorageTree) state.isLoading = false;
      if (!payload || isEmpty(payload)) state.notFound = true;
      else state.storageData = payload;
    },
    [getStorageData.rejected]: (state, { payload }) => {
      state.isLoadingStorageData = false;
      if (!state.isLoadingStorageTree) state.isLoading = false;
      state.error = payload;
    },

    [getStorageTree.pending]: (state) => {
      state.isLoading = true;
      state.storageTreeError = null;
      state.isLoadingStorageTree = true;
    },
    [getStorageTree.fulfilled]: (state, { payload }) => {
      state.isLoadingStorageTree = false;
      state.storageTreeError = null;
      if (!state.isLoadingStorageData) state.isLoading = false;
      state.storageTree = payload;
    },
    [getStorageTree.rejected]: (state, { payload }) => {
      state.isLoadingStorageTree = false;
      if (!state.isLoadingStorageData) state.isLoading = false;
      state.storageTreeError = payload;
    },

    [searchStorage.pending]: (state) => {
      state.isSearchingStorage = true;
    },
    [searchStorage.fulfilled]: (state, { payload }) => {
      state.storageSearchResult = payload.searchResult;
      state.searchExpression = payload.searchExpression;
      state.isSearchingStorage = false;
    },
    [searchStorage.rejected]: (state) => {
      state.isSearchingStorage = false;
    },
  },
});

export const { clearState, setOpenFolder, clearStorageSearchResults } = storageSlice.actions;

export default storageSlice.reducer;
