import React, { useContext, useState } from 'react';

import { Modal } from '@shared/components/Modal/Modal';
import DateInput from '@shared/components/DateInput/DateInput';

import SnackbarContext from 'src/contexts/SnackbarContext';

import { Text } from '@shared/components/Text/Text';

import { useSetReminderLaterMutation } from 'src/redux/features/api/reminders';

import HTMLReactParser from 'html-react-parser';

import moment from 'moment';

import { useDispatch } from 'react-redux';

import { deleteReminder } from 'src/redux/features/remindersSlice';

import { MessageContainer } from './styles';

export const SetReminderLaterModal = ({ reminder, onClose, onCloseReminder }) => {
  const [date, setDate] = useState(new Date(reminder.date));

  const [setReminderLater] = useSetReminderLaterMutation();

  const { showSnackbar } = useContext(SnackbarContext);

  const dispatch = useDispatch();

  const onSubmit = () => {
    setReminderLater({
      reminder_id: reminder.id,
      date:
        moment.utc(date || new Date().toISOString()).format('YYYY[/]MM[/]D') +
        ' ' +
        moment.utc(date || new Date().toISOString()).format('LT'),
    }).then(() => {
      dispatch(deleteReminder(reminder));
      onCloseReminder();
      onClose();
      showSnackbar('Уведомление отложено', 'success');
    });
  };

  return (
      <Modal
        type="submit"
        modalSize="767px"
        title="Напомнить позже"
        confirmButtonText={'Сохранить'}
        onSave={onSubmit}
        onClose={onClose}
      >
        {!!reminder.message && (
          <MessageContainer>
            <b>Напоминание на основе сообщения:</b> <Text tag="p">{HTMLReactParser(String(reminder.message))}</Text>
          </MessageContainer>
        )}

        <div>
          <DateInput
            placeholder="Дата и время"
            onChange={(date) => setDate(date)}
            selected={date}
            timeIntervals={15}
            timeCaption="Время"
            timeFormat="p"
            dateFormat="Pp"
            showTimeSelect
          />
        </div>
      </Modal>
  );
};
