import { IconButton, Text } from '@shared/components';
import HTMLReactParser from 'html-react-parser';
import moment from 'moment';
import React from 'react';
import IconTrash from '@assets/images/icons/delete.svg';
import iconEdit from '@assets/images/icons/edit.svg';

import { StyledButtonWrapper, StyledCard, StyledLink } from '../styles';

import { StyledText } from './styles';

export const ReminderCard = ({ reminder, setSearchParams, queryParams, setRemoveModalVisible, setCurrentReminder }) => {
  return (
    <StyledCard key={reminder.id}>
      <div>
        <Text as="div" size={1} color={'#8c8c8c'}>
          {moment(new Date(reminder.date)).format('L')} {moment(new Date(reminder.date)).format('LT')}
        </Text>
        <StyledText as="div" size={2}>
          {HTMLReactParser(String(reminder.description))}
        </StyledText>

        {reminder?.project && (
          <Text>
            Проект: <StyledLink to={`/projects/${reminder.data_id}`}>{reminder.project}</StyledLink>
          </Text>
        )}
        {reminder?.task && (
          <Text>
            Задача:{' '}
            <StyledLink
              to={`/projects/${reminder.root_project}/tasks/${reminder.data_id}`}
              onClick={(e) => e.stopPropagation()}
            >
              {reminder.task}
            </StyledLink>
          </Text>
        )}
      </div>

      <StyledButtonWrapper>
        <IconButton
          icon={iconEdit}
          size={18}
          onClick={() => {
            setSearchParams({ ...queryParams, id: reminder.id }, { replace: true });
            setCurrentReminder(reminder);
          }}
        />

        <IconButton
          icon={IconTrash}
          size={18}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentReminder(reminder);
            setRemoveModalVisible(true);
          }}
        />
      </StyledButtonWrapper>
    </StyledCard>
  );
};
