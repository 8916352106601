import revoteIcon from '@assets/images/icons/revote.svg';
import { useCallback, useContext, useState } from 'react';
import axios from 'axios';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { number } from 'prop-types';

import { StyledIconButton } from './styles';

CancelVoteButton.propTypes = {
  pollId: number,
};

export function CancelVoteButton({ pollId }) {
  const [cancellingVote, setCancellingVote] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const cancelVote = useCallback(() => {
    setCancellingVote(true);
    axios
      .post('/api/revote', {
        poll_id: pollId,
      })
      .catch(() => showSnackbar('Не удалось отправить запрос'))
      .finally(() => setCancellingVote(false));
  }, [pollId]);

  return <StyledIconButton title="Переголосовать" icon={revoteIcon} onClick={cancelVote} $active={cancellingVote} />;
}
