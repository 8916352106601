import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {Select,
  StyledSelect,
  StyledSelectInput,
  StyledSelectItem
} from "@components/employees/vacations/styles";

const VacationsViewDropdown = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [dropdown, setDropdown] = useState(false);

  const renderSelectedView = () => {
    if (location.pathname.includes('/vacations/timetable')) return 'На графике';
    return 'Списком';
  };

  return (
    <>
      <Select $show={dropdown} width="200px">
        <StyledSelectInput onClick={() => setDropdown(!dropdown)}>{renderSelectedView()}</StyledSelectInput>
        <StyledSelect>
          <StyledSelectItem
            onClick={() => {
              setDropdown(false);
              navigate('/team/vacations/timetable');
            }}
          >
            На графике
          </StyledSelectItem>
          <StyledSelectItem
            onClick={() => {
              setDropdown(false);
              navigate('/team/vacations');
            }}
          >
            Списком
          </StyledSelectItem>
        </StyledSelect>
      </Select>
    </>
  );
};

export default VacationsViewDropdown;
