import { Container } from '@shared/components';
import styled from 'styled-components';

export const StyledResultItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: 550px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const StyledResultTitle = styled.span`
  flex: 0 1 50%;

  @media (max-width: 550px) {
    margin-bottom: 16px;
  }
`;

export const StyledResultLink = styled.div`
  color: ${({ theme }) => theme.main};
  margin: 0 40px 0 auto;

  @media (max-width: 550px) {
    margin: 0 0 16px;
  }
`;

export const StyledResultControlBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export const StyledResultGrid = styled.div`
  display: grid;
  align-items: end;
  gap: 24px;

  @media (min-width: 578px) {
    grid-template-columns: 1.7fr 1fr;
  }
`;

export const StyledProjectTabList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Wrapper = styled(Container)`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;

  ::placeholder {
    font-size: 14px;
  }
`;
