import { Text } from '@shared/components';
import styled, { css } from 'styled-components';

export const Header = styled.header`
  background-color: ${({ theme }) => theme.darkestSecondary};
  color: ${({ theme }) => theme.lightest};
  position: fixed;
  width: 100%;
  z-index: 20;
`;

export const HeaderNav = styled.nav`
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 24px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 550px) {
    padding: 0 12px;
  }
`;

export const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderBurger = styled.button`
  display: none;

  @media (max-width: 1150px) {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    flex: 0 0 18px;
    height: 16px;
    margin-right: 15px;
    position: relative;
    z-index: 5;

    & span,
    &::before,
    &::after {
      background-color: ${({ theme }) => theme.lightest};
      height: 2px;
      left: 0;
      position: absolute;
      transition: all 0.3s ease 0s;
      width: 100%;
    }

    & span {
      top: 50%;
      transform: scale(1) translateY(-50%);
    }

    &::before {
      content: "";
      top: 0;
    }

    &::after {
      content: "";
      bottom: 0;
    }

    ${(props) =>
      props.$active &&
      css`
        &::before {
          top: 50%;
          transform: rotate(-45deg) translateY(-50%);
        }

        &::after {
          bottom: 50%;
          transform: rotate(45deg) translateY(50%);
        }

        & span {
          transform: scale(0) translateY(-50%);
        }
      `}
  }
}`;

export const HeaderLogo = styled.img`
  vertical-align: middle;
`;

export const HeaderSiteNav = styled.section`
  @media (max-width: 1150px) {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    overflow: auto;
    padding-top: 52px;
    position: absolute;
    z-index: 3;
    background-color: ${({ theme }) => theme.darkestSecondary};
    left: -100%;
    top: 0;
    transition: left 0.3s ease-in-out;
    width: 100%;

    &::before {
      background-color: ${({ theme }) => theme.darkestSecondary};
      left: -100%;
      top: 0;
      transition: left 0.3s ease-in-out;
      width: 100%;
    }

    ${(props) =>
      props.$active &&
      css`
        left: 0;

        &::before {
          left: 0;
        }
      `}
  }
`;

export const SiteNavList = styled.section`
  display: inline-block;
  position: relative;

  ${(props) =>
    props.$active &&
    css`
      ${SiteNavLink}::after {
        transform: translateY(-50%) rotate(180deg);

        @media (max-width: 1150px) {
          transform: rotate(180deg);
        }
      }

      ${SiteInnerList} {
        opacity: 1;
        visibility: visible;

        @media (max-width: 1150px) {
          position: static;
        }
      }
    `}
`;

export const SiteNavLink = styled.span`
  cursor: pointer;
  display: inline-flex;
  margin-right: 10px;
  padding: 15px;
  transition: color 0.2s ease-in-out;
  white-space: nowrap;

  &::after {
    background-image: url(${(props) => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 6px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    width: 9px;
  }

  &:hover {
    color: ${({ theme }) => theme.main};
  }

  @media (max-width: 1150px) {
    display: flex;

    &::after {
      top: 24px;
    }
  }
`;

export const SiteInnerList = styled.div`
  min-width: 223px;
  background-color: ${({ theme }) => theme.darkestSecondary};
  display: flex;
  flex-direction: column;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 100%;
  transition: all 0.3s ease-in-out;
  visibility: hidden;

  @media (max-width: 1150px) {
    background-color: ${({ theme }) => theme.borderSecondary};
    color: ${({ theme }) => theme.darkestSecondary};
    position: absolute;
    width: 100%;
    transition: all 0.2s;
  }
`;

export const SiteInnerLink = styled.div`
  cursor: pointer;
  display: inline-flex;
  padding: 15px;
  transition: color 0.2s ease-in-out;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;

export const UserNav = styled.section`
  display: flex;
  align-items: center;
  flex: 0 1 450px;
  justify-content: space-between;
  gap: 12px;

  @media (max-width: 1350px) {
    flex: 0 1 300px;
    gap: 24px;
  }

  @media (max-width: 1150px) {
    justify-content: flex-end;
  }

  @media (max-width: 480px) {
    gap: 16px;
  }
`;

export const UserNavControls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (min-width: 768px) {
    gap: 24px;
  }
`;

export const NavControlsItem = styled.div`
  cursor: pointer;
  position: relative;
  height: 52px;
  display: flex;
  align-items: center;
`;

export const NavControlsButton = styled.button`
  align-items: center;
  border-radius: 2px;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 48px;
  position: relative;
  border: none;
  user-select: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(90%);
  }

  @media (max-width: 550px) {
    padding: 12px;
    width: auto;
    height: auto;
  }

  ${(props) =>
    props.$add &&
    css`
      background-color: ${({ theme }) => theme.success};

      &::after {
        content: '';
        height: 16px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        background-image: url(${(props) => props.icon});
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
      }
    `};
  
  ${(props) =>
    props.$search &&
    css`
      background-color: ${({ theme }) => theme.lightest};

      &::after {
        content: '';
        height: 16px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        background-image: url(${(props) => props.icon});
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
      }
    `}
}
`;

export const StyledUserNavList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const StyledUserNavInnerList = styled.div`
  background-color: ${({ theme }) => theme.darkestSecondary};
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  right: -10px;
  top: 100%;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  white-space: nowrap;
  z-index: 2;

  @media (max-width: 550px) {
    position: fixed;
    left: 0;
    top: 52px;
    width: 100%;
  }

  ${(props) =>
    props.$active &&
    `
    opacity: 1;
    visibility: visible;
  `}
`;

export const StyledUserNavItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: center;
  position: relative;
`;

export const StyledUserNavProfile = styled(StyledUserNavItem)`
  flex: 0 0 52px;
  height: auto;
  padding: 0;
  width: 52px;

  &::after {
    background-image: url(${(props) => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 4px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    width: 7px;
  }
`;

export const Username = styled(Text)`
  padding: 10px 15px 4px 15px;
  color: rgb(24, 144, 255);
  font-weight: 600;
  font-size: 14px;

  cursor: auto;
`;
