import React from 'react';

import { Container, Text } from '@shared/components';
import HTMLReactParser from 'html-react-parser';

import { StyledLink, StyledListItem, StyledTruncatedText } from '../../styles';

export const CardProjectOrTask = ({ index, item, onClose }) => {
  let link = `/projects/${item.root_project_id || item.id}` + `${item.root_project_id ? '/tasks/' + item.id : ''}`;

  return (
    <StyledListItem key={index}>
      <Container gap={'12px'}>
        <div>{index + 1}.</div>
        <Container direction={'column'} gap={'8px'}>
          <StyledLink href={link} target="_blank" rel="noreferrer">
            <Text size={3}>Проект: {item.root_project_title || item.title}</Text>
            {item.root_project_id && <Text size={3}>Задача: {item.title}</Text>}
          </StyledLink>

          {item.description && (
            <StyledTruncatedText rows={2} size={2}>
              {HTMLReactParser(item.description)}
            </StyledTruncatedText>
          )}
        </Container>
      </Container>
    </StyledListItem>
  );
};
