import {useState, memo} from 'react';

import UserProfileModalContainer from '../../windows/profile/UserProfileModalContainer';

import FolderDetails from './FolderDetails';
import StorageTrash from './StorageTrash';
import useRenderUserName from './useRenderUserName';

const StorageContent = ({
                          setAddFilesModal,
                          setAddFolderModal,
                          handleFolderSelect,
                          openFolderId,
                          setOpenFolderId,
                          noURLParams,
                          storageIdToReq,
                          addStorageFileReference,
                          attachFolderReference,
                        }) => {
  const [userProfileId, setUserProfileId] = useState();

  const renderUserName = useRenderUserName(setUserProfileId);

  return (
    <>
      {openFolderId === 'bin' ? (
        <StorageTrash renderUserName={renderUserName} storageId={storageIdToReq}/>
      ) : (
        <FolderDetails
          setAddFolderModal={setAddFolderModal}
          setAddFilesModal={setAddFilesModal}
          renderUserName={renderUserName}
          handleFolderSelect={handleFolderSelect}
          setOpenFolderId={setOpenFolderId}
          noURLParams={noURLParams}
          storageIdToReq={storageIdToReq}
          addStorageFileReference={addStorageFileReference}
          attachFolderReference={attachFolderReference}
        />
      )}

      {userProfileId && <UserProfileModalContainer profileId={userProfileId} onClose={() => setUserProfileId(null)}/>}
    </>
  );
};

export default memo(StorageContent);
