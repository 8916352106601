import { Avatar } from '@shared/components/Avatar';
import moment from 'moment';
import { useMemo } from 'react';
import useAuth from 'src/hooks/useAuth';
import { shape, number, string } from 'prop-types';
import warningIcon from '@assets/images/icons/warning.svg';
import { status } from '@components/chat/project-header/SingleProjectHeader';
import { ProjectStatusWithText } from '@components/chat/styles';

import {
  StyledEllipsedText,
  StyledRequestItem,
  StyledSecondaryDiv,
  StyledWarningImage,
  StyledEllipsedSecondaryText,
  StyledProjectTitle,
} from './styles';

const responsibleActions = ['on_clarification', 'under_review'];
const executorActions = ['created', 'in_progress', 'overdue'];

RequestItem.propTypes = {
  requestData: shape({
    executor_id: number,
    executor_first_name: string,
    executor_last_name: string,
    executor_department: string,
    responsible_id: number,
    responsible_first_name: string,
    responsible_last_name: string,
    responsible_department: string,
    date_created: string,
    date_update: string,
    date_finish: string,
    title: string,
    project_title: string,
    status: string,
  }),
};
export function RequestItem({ requestData }) {
  const auth = useAuth();

  const { avatarId, name, departmentName } = useMemo(() => {
    let avatarId = null;
    let name = null;
    let departmentName = null;
    if (!auth?.user?.id) return { avatarId, name, departmentName };
    if (requestData.responsible_id === auth.user.id) {
      avatarId = requestData.executor_id;
      name = `${requestData.executor_first_name || ''} ${requestData.executor_last_name}`;
      departmentName = requestData.executor_department;
    } else {
      avatarId = requestData.responsible_id;
      name = `${requestData.responsible_first_name || ''} ${requestData.responsible_last_name}`;
      departmentName = requestData.responsible_department;
    }
    return { avatarId, name, departmentName };
  }, [requestData, auth?.user?.id]);

  const isActionRequired = useMemo(() => {
    return (
      (auth?.user?.id === requestData.responsible_id && responsibleActions.includes(requestData.status)) ||
      (auth?.user?.id === requestData.executor_id && executorActions.includes(requestData.status))
    );
  }, [requestData, auth?.user?.id]);

  const isOverdue = useMemo(() => {
    if (requestData.status === 'finished' || requestData.status === 'canceled') return false;
    return moment().isAfter(requestData.date_finish);
  }, [requestData]);

  const isDeadlineToday = useMemo(() => {
    if (requestData.status === 'finished' || requestData.status === 'canceled') return false;
    const now = moment();
    return now.isBefore(requestData.date_finish) && now.add(24, 'hours').isAfter(requestData.date_finish);
  }, [requestData]);

  if (!auth?.user?.id) return null;
  return (
    <StyledRequestItem $overdue={isOverdue} $deadlineToday={isDeadlineToday}>
      <div>
        {isActionRequired && <StyledWarningImage src={warningIcon} alt="warning" title="Ждет ваших действий" />}
      </div>
      <div>{requestData.creator_id === auth.user.id ? 'От меня' : 'Мне от'}</div>
      <div style={{ display: 'flex' }}>
        {avatarId && name && (
          <>
            <span>
              <Avatar userId={avatarId} showProfileOnClick={false} />
            </span>
            <div>
              <StyledEllipsedText $width="170px">{name}</StyledEllipsedText>
              <StyledEllipsedSecondaryText $width="170px">{departmentName}</StyledEllipsedSecondaryText>
            </div>
          </>
        )}
      </div>
      <div>
        <ProjectStatusWithText $color={status[requestData.status].color}>
          {status[requestData.status].text}
        </ProjectStatusWithText>

        <StyledSecondaryDiv>с {moment(requestData.date_update).format('DD.MM.YYYY, HH:mm')}</StyledSecondaryDiv>
      </div>
      <div>{moment(requestData.date_created).format('DD.MM.YYYY, HH:mm')}</div>
      <div>{moment(requestData.date_finish).format('DD.MM.YYYY, HH:mm')}</div>
      <div>
        <StyledProjectTitle>{requestData.title}</StyledProjectTitle>
        <StyledProjectTitle $secondary> {requestData.project_title}</StyledProjectTitle>
      </div>
    </StyledRequestItem>
  );
}
