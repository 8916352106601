import { memo } from 'react';
import { object, func } from 'prop-types';
import { Input, Label } from '@shared/components';
import { useFieldArray } from 'react-hook-form';

import LinkFieldArrayItem from './LinkFieldArrayItem';
import ListFieldArrayItem from './ListFieldArrayItem';
import TextFieldArrayItem from './TextFieldArrayItem';

AddEditContactExtraInfoFC.propTypes = {
  register: func.isRequired,
  control: object.isRequired,
};

function AddEditContactExtraInfoFC({ register, control }) {
  const { fields } = useFieldArray({ control, name: 'partner_company_infos' });

  return (
    <>
      {fields?.map((infoField, index) => {
        switch (infoField.type) {
          case 'string':
            return (
              <div key={infoField.id}>
                <Label>{infoField.name}</Label>
                <Input register={register(`partner_company_infos.${index}.value`)} />
              </div>
            );
          case 'text':
            return <TextFieldArrayItem key={infoField.id} control={control} infoField={infoField} index={index} />;
          case 'link':
            return (
              <LinkFieldArrayItem
                key={infoField.id}
                infoField={infoField}
                index={index}
                register={register}
                control={control}
              />
            );
          case 'list':
            return <ListFieldArrayItem key={infoField.id} infoField={infoField} control={control} index={index} />;

          default:
            return null;
        }
      })}
    </>
  );
}

const AddEditContactExtraInfo = memo(AddEditContactExtraInfoFC);

export default AddEditContactExtraInfo;
