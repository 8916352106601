import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StyledUserWriting } from '@components/chat/styles';

import { useSocketContext } from '../../contexts/SocketContext';
import useAuth from '../../hooks/useAuth';

let writingUserTimeout;

const UserTextingListener = ({ chatId }) => {
  const auth = useAuth();
  const { onUserWrites, offUserWrites, onUserStoppedWriting } = useSocketContext();
  const { employees } = useSelector((state) => state.users);
  const [writingUser, setWritingUser] = useState({ id: null, name: null });

  // функция-коллбэк когда слушают socket как другие юзеры печатают
  const userWritesListener = useCallback(
    (userId, serverChatId) => {
      if (
        !auth?.user?.id ||
        serverChatId !== chatId ||
        userId === auth.user.id ||
        writingUser.id === userId ||
        !Array.isArray(employees)
      ) {
        return;
      }

      const writingEmployee = employees.find((employee) => employee.id === userId);
      if (!writingEmployee) return;
      setWritingUser({
        id: userId,
        name: `${writingEmployee.first_name || ''} ${writingEmployee.last_name || ''}`,
      });

      clearTimeout(writingUserTimeout);

      writingUserTimeout = setTimeout(() => {
        setWritingUser({ id: null, name: null });
      }, 30000);
    },
    [auth, employees, writingUser, chatId],
  );

  // функция-коллбэк когда слушают socket как другой юзер перестает печатать
  const userStoppedWritingListener = useCallback(
    (employee_id) => {
      if (writingUser.id === employee_id) {
        setWritingUser({ id: null, name: null });
      }
    },
    [writingUser],
  );

  // запустить функции для отслеживания socket событий из сервера
  useEffect(() => {
    onUserWrites(userWritesListener);
    onUserStoppedWriting(userStoppedWritingListener);

    return () => offUserWrites();
  }, [userWritesListener, userStoppedWritingListener]);

  return <StyledUserWriting>{writingUser.name ? `${writingUser.name} набирает сообщение...` : ''}</StyledUserWriting>;
};

export default UserTextingListener;
