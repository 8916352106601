import { Container } from '@shared/components/Container';
import styled from 'styled-components';

export const Wrapper = styled(Container)`
  justify-content: center;

  #pdf-download,
  #file-name,
  #header-bar {
    display: none;
  }

  #pdf-controls {
    box-shadow: none;
  }
`;

export const PluginContainer = styled.div`
  width: 74%;
  margin-top: 90px;
  overflow: hidden;
  border-radius: 12px;

  min-height: 100vh;

  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
`;
