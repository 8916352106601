import Preloader from '@components/preloaders/Preloader';
import { useGetRequestsForMeQuery } from 'src/redux/features/api/requests';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { RequestItem } from '@components/requests/RequestItem';
import { StyledRequestHeader, StyledGridWrapper } from '@components/requests/styles';

export function RequestsForMe() {
  const { data, isFetching, refetch } = useGetRequestsForMeQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <StyledGridWrapper>
      {Array.isArray(data?.requests) && data.requests.length > 0 && (
        <StyledRequestHeader>
          <div></div>
          <div></div>
          <div>Автор </div>
          <div>Статус</div>
          <div>Создана</div>
          <div>Дедлайн</div>
          <div>Просьба</div>
        </StyledRequestHeader>
      )}

      {isFetching && <Preloader />}

      {!isFetching &&
        Array.isArray(data?.requests) &&
        data.requests.map((requestData) => (
          <Link key={requestData.id} to={`/projects/${requestData.root_project}/tasks/${requestData.id}`}>
            <RequestItem requestData={requestData} />
          </Link>
        ))}
    </StyledGridWrapper>
  );
}
