import ReactSelect from 'react-select';
import styled from 'styled-components';

export const StyledSelect = styled(ReactSelect)`
  cursor: pointer;
  
  & .css-1p3m7a8-multiValue {
    background-color: ${({theme}) => theme.light};
    border: 1px solid ${({theme}) => theme.border};
  }

  & .css-wsp0cs-MultiValueGeneric {
    padding: 0 0 0 6px;
    font-size: 12px;
  }
  
  & .css-12a83d4-MultiValueRemove:hover {
    cursor: pointer;
    background-color: transparent;
  }

  & .css-1nmdiq5-menu {
    display: block;
  }

  &.css-b62m3t-container {
    position: relative;
    outline: none;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 2px;
    width: ${(props) => props.width || '100%'};
    font: inherit;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.darkest};
  }
`;
