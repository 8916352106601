// import moment from 'moment';
import VacationItem from '@components/employees/vacations/VacationItem';
import {VacationTableDep, VacationTableRow} from "@components/employees/vacations/styles";

const VacationRow = ({ vacationRowData, approved, depttRowSpan, depttTitle, index, isLast }) => {
  return (
    <VacationTableRow style={{ borderTop: index === 0 ? '1px solid #f0f0f0' : 'none' }}>
      {index === 0 ? (
        <VacationTableDep rowSpan={depttRowSpan}>
          {depttTitle || 'Без отдела'}
        </VacationTableDep>
      ) : null}
      {!Number.isNaN(vacationRowData.offsetDays) && vacationRowData.offsetDays > 0 && (
        <td colSpan={vacationRowData.offsetDays}></td>
      )}
      {!Number.isNaN(vacationRowData.vacationDaysLeft) && (
        <VacationItem
          firstItem={index === 0}
          isLastItem={isLast}
          vacationData={vacationRowData}
          colSpan={vacationRowData.vacationDaysLeft}
          userId={vacationRowData.employee_id}
          approved={approved}
          hideRequestText={vacationRowData.vacationDaysLeft <= 2 && !approved}
        />
      )}
    </VacationTableRow>
  );
};

export default VacationRow;
