import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;

  a {
    color: ${({ theme }) => theme.main};
    text-decoration: underline;
  }

  & .DraftEditor-root {
    height: fit-content;
    max-height: 140px;
    overflow-y: auto;
  }
  & .DraftEditor-editorContainer,
  & .public-DraftEditor-content {
    height: 100%;
  }
  @media (max-width: 767px) {
    & .DraftEditor-root {
      max-height: 100px;
    }
  }
`;
