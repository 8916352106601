import { useState, useCallback, memo, useRef } from 'react';
import { func, object, bool, string, oneOfType } from 'prop-types';

import { DropzoneText, DropzoneWrapper } from './styles';

DropzoneFC.propTypes = {
  onDrop: func,
  handleFilesSelect: func,
  description: oneOfType([object, string]),
  inputMultipleSelect: bool,
  onlyFolderSelect: bool,
  inputAccept: string,
};

function DropzoneFC({ onDrop, handleFilesSelect, description, inputMultipleSelect, onlyFolderSelect, inputAccept }) {
  const [isDragging, setIsDragging] = useState(false);

  const inputRef = useRef();
  const handleClick = useCallback(() => inputRef.current.click(), []);

  const handleDragEnter = useCallback(() => setIsDragging(true), []);

  const handleDragLeave = useCallback(() => setIsDragging(false), []);

  const handleDragOver = useCallback((e) => e.preventDefault(), []);

  const handleDrop = useCallback(
    (e) => {
      onDrop(e);
      setIsDragging(false);
    },
    [onDrop],
  );

  return (
    <DropzoneWrapper
      onClick={handleClick}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      $isDragging={isDragging}
    >
      <input
        type="file"
        multiple={inputMultipleSelect}
        onChange={handleFilesSelect}
        ref={inputRef}
        webkitdirectory={onlyFolderSelect && 'true'}
        mozdirectory={onlyFolderSelect && 'true'}
        directory={onlyFolderSelect && 'true'}
        accept={inputAccept || '*'}
      />
      <DropzoneText>{description}</DropzoneText>
    </DropzoneWrapper>
  );
}

const Dropzone = memo(DropzoneFC);

export default Dropzone;
