import { MultipleSelect } from '@shared/components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { CustomOption, CustomValue } from './lib';

const TagsMultipleSelect = ({ control, tags }) => {
  const selectOptions = useMemo(() => {
    if (tags) {
      return [...tags?.map((tag) => ({ label: tag.name, value: tag.id, color: tag.color }))];
    }
  }, [tags]);

  return (
    <Controller
      name="tags"
      control={control}
      render={({ field }) => (
        <MultipleSelect
          {...field}
          ref={null}
          placeholder="Теги"
          options={selectOptions}
          components={{ Option: CustomOption, MultiValue: CustomValue }}
          value={selectOptions?.find((o) => o.value === field.value)}
          onChange={(options) => {
            field.onChange(options.map((option) => option.value));
          }}
          isClearable
        />
      )}
    />
  );
};

export default TagsMultipleSelect;
