import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { getFormatCalendarData } from '@shared/components/CalendarForm/CalendarForm';

import i18n from '../../i18n';

import { url_get_project_list } from '../../settings/base-url';

export const TaskType = {
  REQUEST: 'REQUEST',
  SECTION: 'SECTION',
};

const initialState = {
  isLoadingProjectsList: false,
  projectsList: null,
  filteredProjectsList: null,
  currentRequestId: null,
  errorProjectsList: null,
  projectStructure: null,
  projectGuestRights: null,
  isLoadingProjectStructure: false,
  projectStructureError: null,
  currentGuestRights: null,
  currentTaskType: null,
  // currentGuestRights: ["a", "b"],
};

export const getProjectsList = createAsyncThunk('projects/getProjectsList', async (payload, thunkApi) => {
  try {
    const response = await axios.get(url_get_project_list);
    if (Array.isArray(response?.data?.result)) {
      return response.data.result.reverse();
    }
    return thunkApi.rejectWithValue(i18n.t('ErrorMessages.default'));
  } catch (e) {
    if (e.response) {
      return thunkApi.rejectWithValue(i18n.t('ErrorMessages.default'));
    }
    return thunkApi.rejectWithValue(i18n.t('ErrorMessages.network'));
  }
});

export const getFilteredProjectsList = createAsyncThunk(
  'projects/getFilteredProjectsList',
  async ({ filterParams, type }) => {
    const filterObj = {};
    const reqBody = { type };
    // URLSearchParams в объект
    for (const [key, value] of filterParams) filterObj[key] = value;
    // переделка в нужный формат запроса
    for (const el in filterObj) {
      switch (el) {
        case 'date_start_from':
          if (!reqBody.date_start) {
            reqBody.date_start = {};
          }
          reqBody.date_start.from = getFormatCalendarData(new Date(+filterObj[el]));
          if (!filterObj.date_start_to) reqBody.date_start.to = '9999/11/11';
          break;

        case 'date_start_to':
          if (!reqBody.date_start) {
            reqBody.date_start = {};
          }
          reqBody.date_start.to = getFormatCalendarData(new Date(+filterObj[el]));
          if (!filterObj.date_start_from) reqBody.date_start.from = '0001/01/01';
          break;

        case 'date_finish_from':
          if (!reqBody.date_finish) {
            reqBody.date_finish = {};
          }
          reqBody.date_finish.from = getFormatCalendarData(new Date(+filterObj[el]));
          if (!filterObj.date_finish_to) reqBody.date_finish.to = '9999/11/11';
          break;

        case 'date_finish_to':
          if (!reqBody.date_finish) {
            reqBody.date_finish = {};
          }
          reqBody.date_finish.to = getFormatCalendarData(new Date(+filterObj[el]));
          if (!filterObj.date_finish_from) reqBody.date_finish.from = '0001/01/01';
          break;

        case 'date_created_from':
          if (!reqBody.date_created) {
            reqBody.date_created = {};
          }
          reqBody.date_created.from = getFormatCalendarData(new Date(+filterObj[el]));
          if (!filterObj.date_created_to) reqBody.date_created.to = '9999/11/11';
          break;

        case 'date_created_to':
          if (!reqBody.date_created) {
            reqBody.date_created = {};
          }
          reqBody.date_created.to = getFormatCalendarData(new Date(+filterObj[el]));
          if (!filterObj.date_created_from) reqBody.date_created.from = '0001/01/01';
          break;

        case 'tags':
          reqBody.tags = filterObj[el].split(',');
          break;

        default:
          reqBody[el] = filterObj[el];
      }
    }

    const response = await axios.patch('/api/project_filter', reqBody);
    return response.data.result.projects.reverse();
  },
);

export const getProjectStructure = createAsyncThunk('projects/getProjectStructure', async (payload, thunkApi) => {
  try {
    const dataType = payload.taskId ? 'task' : 'project';
    const dataId = payload.taskId || payload.projectId;

    const response = await axios.get(`/api/sidebar_project_tree/${dataType}/${dataId}`);
    return response.data.project;
  } catch (e) {
    if (e.response) {
      return thunkApi.rejectWithValue(i18n.t('ErrorMessages.default'));
    } else {
      return thunkApi.rejectWithValue(i18n.t('ErrorMessages.network'));
    }
  }
});

export const getCurrentGuestRights = createAsyncThunk('projects/getCurrentGuestRights', async (guestId, thunkApi) => {
  try {
    const response = await axios.get(`/api/guest_full/${guestId}`);
    return response.data.guest;
  } catch (e) {
    if (e.response) {
      return thunkApi.rejectWithValue(i18n.t('ErrorMessages.default'));
    } else {
      return thunkApi.rejectWithValue(i18n.t('ErrorMessages.network'));
    }
  }
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.isLoadingProjectStructure = false;
      state.projectStructureError = null;
    },
    clearProjectStructure: () => initialState,
    setCurrentTaskType: (state, { payload }) => {
      state.currentTaskType = payload;
    },
  },
  extraReducers: {
    [getProjectsList.pending]: (state) => {
      state.isLoadingProjecstList = true;
      state.projectsListError = null;
    },
    [getProjectsList.fulfilled]: (state, { payload }) => {
      state.projectsList = payload;
      state.isLoadingProjectsList = false;
    },
    [getProjectsList.rejected]: (state, { payload }) => {
      state.isLoadingProjectsList = false;
      state.projectsListError = payload;
    },

    [getFilteredProjectsList.pending]: (state, action) => {
      state.currentRequestId = action.meta.requestId;
      state.isLoadingProjectsList = true;
    },
    [getFilteredProjectsList.fulfilled]: (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.filteredProjectsList = action.payload;
        state.isLoadingProjectsList = false;
      }
    },

    [getFilteredProjectsList.rejected]: () => {},

    [getProjectStructure.pending]: (state) => {
      state.isLoadingProjectStructure = true;
      state.projectStructureError = null;
    },
    [getProjectStructure.fulfilled]: (state, { payload }) => {
      state.projectStructure = payload;
      state.isLoadingProjectStructure = false;
    },
    [getProjectStructure.rejected]: (state, { payload }) => {
      state.isLoadingProjectStructure = false;
      state.projectStructureError = payload;
    },

    // [getCurrentGuestRights.pending]: () => {
    //
    // },
    [getCurrentGuestRights.fulfilled]: (state, { payload }) => {
      const { projects, tasks } = payload;
      state.currentGuestRights = { projects, tasks };
    },
    // [getCurrentGuestRights.rejected]: () => {
    //
    // },
  },
});

export const selectGuestProjectRights = (projectType, projectId) => (state) => {
  if (!state?.projects?.currentGuestRights) return null;
  if (!projectType || !projectId) return undefined;
  if (projectType === 'project') {
    return state.projects.currentGuestRights.projects.find((project) => project.project_id === projectId)?.access;
  }
  if (projectType === 'task') {
    return state.project.currentGuestRights.tasks.find((task) => task.task_id === projectId)?.access;
  }
};

export const selectGuestRights = (state, projectType, projectId) => {
  if (!state?.projects?.currentGuestRights) return null;
  if (!projectType || !projectId) return undefined;
  if (projectType === 'project') {
    return state.projects.currentGuestRights.projects.find((project) => project.project_id === projectId)?.access;
  }
  if (projectType === 'task') {
    return state.project.currentGuestRights.tasks.find((task) => task.task_id === projectId)?.access;
  }
};

export const { clearErrors, clearProjectStructure, setCurrentTaskType } = projectsSlice.actions;

export default projectsSlice.reducer;
