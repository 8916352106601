import React from 'react';
import Preloader from 'src/components/preloaders/Preloader';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';

import DepartmentItem from './DepartmentItem';

import { StyledModalGrid, StyledModalGridCell, StyledModalGridHeader } from './styles';

const DepartmentList = ({ departments, onEdit, onDelete, onCloseDepartmentList, hasRightToEdit }) => {
  useEventTriggerOnEscPress(onCloseDepartmentList);

  return (
    <>
      {!departments && <Preloader />}
      <StyledModalGrid grid="2fr 2fr 1fr 100px">
        <StyledModalGridHeader>
          <StyledModalGridCell>Название отдела</StyledModalGridCell>
          <StyledModalGridCell>Руководитель</StyledModalGridCell>
          <StyledModalGridCell>Сотрудники</StyledModalGridCell>
          <StyledModalGridCell align="right">Действия</StyledModalGridCell>
        </StyledModalGridHeader>
        {departments?.map((item, i) => (
          <DepartmentItem key={i} item={item} onDelete={onDelete} onEdit={onEdit} hasRightToEdit={hasRightToEdit} />
        ))}
      </StyledModalGrid>
    </>
  );
};

export default DepartmentList;
