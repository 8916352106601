import styled, { css } from 'styled-components';

export const StyledTag = styled.button`
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  padding: 1px 8px 1px 28px;
  position: relative;
  white-space: nowrap;
  width: max-content;
  font-size: 12px;
  line-height: 20px;

  ${(props) =>
    props.$active &&
    `
      background-color: ${props.theme.lightest};
      border: 1px solid ${props.theme.main};
  `}

  &::before {
    background-color: #8c8c8c;
    border-radius: 50%;
    content: '';
    height: 12px;
    left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;

    ${(props) =>
      props.color === 'blue' &&
      css`
        background-color: ${props.theme.main};
      `}

    ${(props) =>
      props.color === 'red' &&
      css`
        background-color: ${props.theme.error};
      `}

    ${(props) =>
      props.color === 'green' &&
      css`
        background-color: ${props.theme.success};
      `}

    ${(props) =>
      props.color === 'yellow' &&
      css`
        background-color: ${props.theme.warningSecondary};
      `}

    ${(props) =>
      props.color === 'grey' &&
      css`
        background-color: ${props.theme.disabled};
      `}
  }
`;
