import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reminders: [],
  displayedReminders: [],
};

export const remindersSlice = createSlice({
  name: 'allReminders',
  initialState,
  reducers: {
    setReminders: (state, { payload }) => {
      if (state.reminders.find((reminder) => reminder.id === payload.id)) return;
      state.reminders = [...state.reminders, { ...payload, isDisplayed: false }];
    },
    setDisplayedReminders: (state, { payload }) => {
      state.displayedReminders = payload;
    },
    deleteReminder: (state, { payload }) => {
      state.reminders = state.reminders.filter((item) => item.id !== payload.id);
      state.displayedReminders = state.displayedReminders.filter((item) => item.id !== payload.id);
    },
  },
});

export const { setReminders, setDisplayedReminders, deleteReminder } = remindersSlice.actions;

export default remindersSlice.reducer;
