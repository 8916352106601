import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledAuth = styled.section`
  margin: 0 auto;
  max-width: 370px;
  padding: 80px 12px 0;
  width: 100%;
`;

export const StyledAuthForm = styled.form`
  display: grid;
  gap: 24px;
`;

export const StyledAuthTitle = styled.h1`
  font-size: 38px;
  font-weight: 500;
  line-height: 46px;
  text-align: center;
  margin-bottom: 16px;
`;

export const StyledAuthInfo = styled.p`
  margin-bottom: 32px;
  text-align: center;
`;

export const StyledAuthLink = styled(Link)`
  color: ${({ theme }) => theme.main};
  margin-left: 8px;
  text-decoration: underline;
`;
