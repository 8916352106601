import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { SingleSelect } from '@shared/components/Select/Select';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import DateInput from '@shared/components/DateInput/DateInput';
import { components } from 'react-select';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { Text } from '@shared/components/Text/Text';

import { RichTextEditor } from '@components/RichTextEditor';

import {
  remindersApi,
  useEditReminderMutation,
  useGetReminderByIdQuery,
  useGetReminderProjectTreeQuery,
} from 'src/redux/features/api/reminders';

import { ContentState, EditorState, convertFromHTML } from 'draft-js';

import Preloader from '@components/preloaders/Preloader';

import HTMLReactParser from 'html-react-parser';

import { useDispatch, useSelector } from 'react-redux';

import { Container, HrLine } from '@shared/components';

import { StyledLink } from '@shared/components/Reminder';

import { deleteReminder } from 'src/redux/features/remindersSlice';

import { toast } from 'react-toastify';

import ConfirmAction from '@components/warnings/ConfirmAction';

import { t } from 'i18next';

import { InfoContainer, StyledForm, StyledMenuList, StyledMenuTitle } from './styles';
import { options, reminderType, schema, transformFormData } from './lib';

export const EditReminderModal = ({ reminder, onClose }) => {
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, dirtyFields },
    watch,
    reset,
    resetField,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: options.filter((opt) => opt.value === reminder?.type)[0],
    },
    mode: 'onChange',
  });

  const displayedReminders = useSelector((state) => state.allReminders.displayedReminders);

  const watchSelectType = watch('type');
  const watchSelectProject = watch('project');

  const { data, isLoading } = useGetReminderByIdQuery({ id: reminder?.id });
  const [editReminder] = useEditReminderMutation();

  const { data: projectTree, isLoading: projectTreeIsLoading } = useGetReminderProjectTreeQuery(
    {
      type: reminderType.project,
      id: watchSelectProject?.value || reminder?.root_project,
    },
    { skip: watchSelectType?.value !== reminderType.task },
  );

  const typeOptions =
    reminder?.type === reminderType.task || reminder?.type === reminderType.message
      ? options
      : options.filter((opt) => opt.value !== reminderType.message && opt.value !== reminderType.task);

  const projectOptions = useMemo(
    () => data?.projects?.map((project) => ({ label: project.title, value: project.id })),
    [data],
  );

  const taskOptions = useMemo(
    () => projectTree?.project?.tasks.map((task) => ({ label: task.title, value: task.id })),
    [projectTree],
  );

  const { showSnackbar } = useContext(SnackbarContext);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    editReminder(transformFormData({ id: reminder?.id, data })).then(() => {
      dispatch(remindersApi.util.invalidateTags(['Reminders']));
      dispatch(deleteReminder(reminder));
      const currentReminder = displayedReminders.filter((item) => item.id === reminder?.id)[0];
      if (currentReminder) toast.dismiss(currentReminder.position);

      showSnackbar('Напоминание успешно отредактировано', 'success');
      onClose();
    });
  };

  const getMessageLink = (reminder) => {
    if (reminder?.chat?.task_id === null) {
      return `/projects/${reminder?.root_project}?msg=${reminder?.data_id}`;
    } else {
      return `/projects/${reminder?.root_project}/tasks/${reminder?.chat?.task_id}?msg=${reminder?.data_id}`;
    }
  };

  const CustomMenuList = ({ children, ...props }) => {
    return (
      <components.MenuList {...props}>
        <Container justify={'center'}>
          <StyledMenuList>{children}</StyledMenuList>
        </Container>
      </components.MenuList>
    );
  };

  const CustomMenu = ({ children, ...props }) => {
    const projectOption = watch('project');

    return (
      <components.Menu {...props}>
        <Container direction={'column'} gap={'8px'} style={{ padding: '12px 0' }}>
          {projectOption?.value && !!projectTree?.project?.tasks.length && (
            <>
              <StyledMenuTitle>{projectOption?.label}</StyledMenuTitle> <HrLine />
            </>
          )}

          {children}
        </Container>
      </components.Menu>
    );
  };

  useEffect(() => {
    if (!isLoading && data) {
      reset({
        type: options.filter((opt) => opt.value === data?.reminder?.type)[0],
        project:
          data?.reminder?.type === reminderType.project || data?.reminder?.type === reminderType.task
            ? projectOptions?.filter((opt) => opt.value === reminder.root_project)[0]
            : undefined,
        task:
          data?.reminder?.type === reminderType.task
            ? taskOptions?.filter((opt) => opt.value === data?.reminder.data_id)[0]
            : undefined,
        description:
          data?.reminder?.description &&
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(String(data.reminder.description)).contentBlocks,
              convertFromHTML(String(data.reminder.description)).entityMap,
            ),
          ),
        date: data?.reminder?.date && new Date(data?.reminder?.date),
      });
    }
  }, [data, isLoading, taskOptions]);

  if (!reminder) {
    onClose();
    return;
  }

  return (
    <>
      <Modal
        type="submit"
        modalSize="767px"
        title="Редактировать напоминание"
        confirmButtonText={'Редактировать'}
        onSave={handleSubmit(onSubmit)}
        onClose={() => {
          if (isDirty) {
            setConfirmModal(true);
            return;
          }

          onClose();
        }}
        disabledSaveButton={!isValid && !isDirty}
      >
        {!!data?.reminder?.message && (
          <InfoContainer>
            <b>Напоминание на основе сообщения:</b>{' '}
            <Text tag="p">
              <StyledLink to={getMessageLink(reminder)}>{HTMLReactParser(String(data.reminder.message))}</StyledLink>
            </Text>
          </InfoContainer>
        )}
        {isLoading ? (
          <Preloader />
        ) : (
          <StyledForm>
            {!(reminder.type === reminderType.task) ? (
              <>
                {!(reminder.type === reminderType.message) && (
                  <div>
                    <Label>Тип напоминания</Label>
                    <Controller
                      name="type"
                      control={control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          options={typeOptions}
                          isDisabled={reminder.type === reminderType.message || reminder.type === reminderType.task}
                        />
                      )}
                    />
                  </div>
                )}

                {(watchSelectType?.value === reminderType.project || watchSelectType?.value === reminderType.task) && (
                  <div>
                    <Label>Проект</Label>
                    <Controller
                      name="project"
                      control={control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          onChange={(value) => {
                            field.onChange(value);
                            resetField('task', { defaultValue: null });
                          }}
                          options={projectOptions}
                          isDisabled={reminder.type === reminderType.task}
                        />
                      )}
                    />
                  </div>
                )}

                {watchSelectType?.value === reminderType.task && (
                  <div>
                    <Label>Задача</Label>
                    <Controller
                      name="task"
                      control={control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          options={taskOptions}
                          components={{ Menu: CustomMenu, MenuList: CustomMenuList }}
                          isLoading={projectTreeIsLoading}
                          isDisabled={reminder.type === reminderType.task}
                        />
                      )}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <Container direction={'column'}>
                  <Text>Тип напоминания:</Text>
                  <b>Напоминание о задаче</b>
                </Container>
                <InfoContainer>
                  {data.reminder?.root_project?.title && (
                    <Text tag="p">
                      Проект:{' '}
                      <StyledLink to={`/projects/${data.reminder.root_project?.id}`}>
                        {data.reminder.root_project?.title}
                      </StyledLink>
                    </Text>
                  )}
                  {data.reminder.task && (
                    <Text tag="p">
                      Задача:{' '}
                      <StyledLink to={`/projects/${reminder?.root_project}/tasks/${data.reminder.data_id}`}>
                        {data.reminder.task}
                      </StyledLink>
                    </Text>
                  )}
                </InfoContainer>
              </>
            )}
            <div>
              <Label>Текст напоминания</Label>

              <Controller name="description" control={control} render={({ field }) => <RichTextEditor {...field} />} />
            </div>

            <div>
              <Label>Срок исполнения</Label>
              <StyledModalRowEnd>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      placeholder="Дата"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      timeIntervals={15}
                      timeCaption="Время"
                      timeFormat="p"
                      dateFormat="Pp"
                      showTimeSelect
                    />
                  )}
                />
              </StyledModalRowEnd>
            </div>
          </StyledForm>
        )}
      </Modal>

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onClose}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </>
  );
};
