import { useEffect, memo, useRef } from 'react';
import { bool, string } from 'prop-types';
import { colors } from 'src/variables';

import { Link } from '../Link/Link';
import { Container } from '../Container';

import { ContainerNotFound, Container404, Clip, Digit, NotFoundText, Description } from './styles';

const randomNum = () => {
  return Math.floor(Math.random() * 9) + 1;
};

NotFoundComponent.propTypes = {
  small: bool,
  mTop: string,
  mBottom: string,
  title: string,
  description: string,
};

function NotFoundComponent({ small, mTop, mBottom, title, description }) {
  const firstDigit = useRef();
  const secondDigit = useRef();
  const thirdDigit = useRef();

  useEffect(() => {
    const interval = 90;
    let loop1,
      loop2,
      loop3,
      i = 0;

    loop3 = setInterval(function () {
      if (!thirdDigit.current) {
        clearInterval(loop3);
        return;
      }
      if (i > 15) {
        clearInterval(loop3);
        thirdDigit.current.textContent = 4;
      } else {
        thirdDigit.current.textContent = randomNum();
        i++;
      }
    }, interval);

    loop2 = setInterval(function () {
      if (!secondDigit.current) {
        clearInterval(loop2);
        return;
      }
      if (i > 30) {
        clearInterval(loop2);
        secondDigit.current.textContent = 0;
      } else {
        secondDigit.current.textContent = randomNum();
        i++;
      }
    }, interval);

    loop1 = setInterval(function () {
      if (!firstDigit.current) {
        clearInterval(loop1);
        return;
      }
      if (i > 45) {
        clearInterval(loop1);
        firstDigit.current.textContent = 4;
      } else {
        firstDigit.current.textContent = randomNum();
        i++;
      }
    }, interval);
  }, []);

  return (
    <ContainerNotFound $mTop={mTop} $mBottom={mBottom}>
      <Container404 $small={small}>
        <Clip $order={1} $small={small}>
          <Digit ref={thirdDigit} $order={1} $small={small} />
        </Clip>
        <Clip $order={2} $small={small}>
          <Digit ref={secondDigit} $order={2} $small={small} />
        </Clip>
        <Clip $order={3} $small={small}>
          <Digit ref={firstDigit} $order={3} $small={small} />
        </Clip>
      </Container404>
      <Container direction="column" gap="1.5rem">
        <NotFoundText>{title || 'Страница не найдена.'}</NotFoundText>
        {description && <Description>{description}</Description>}
        <Link size={3} link="/" color={colors.darkestSecondary}>
          Вернуться на главную
        </Link>
      </Container>
    </ContainerNotFound>
  );
}

export default memo(NotFoundComponent);
