import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarContext from 'src/contexts/SnackbarContext';
import Preloader from '@components/preloaders/Preloader';
import { getFavorites } from 'src/redux/features/favoritesSlice';

import { StyledLayout } from '@shared/components/Layout/styles';
import { Title } from '@shared/components/Title/Title';

import { StyledFavHeader, StyledFavItem, StyledFavLink, StyledFavList } from './styles';
import { RemoveButton } from './ui/RemoveButton';

const Favorites = () => {
  const { favorites, isLoading } = useSelector((state) => state.favorites);

  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (!favorites) {
      dispatch(getFavorites(showSnackbar));
    }
  }, []);

  return (
    <StyledLayout>
      <Title>Избранное</Title>
      {isLoading && <Preloader />}
      <StyledFavList>
        <StyledFavHeader>Название проекта/задачи</StyledFavHeader>
        {favorites?.map((favored) => {
          let url = '';
          let title = '';
          let removeData = {};
          if (favored.project_id) {
            url = `/projects/${favored.project_id}`;
            title = favored.project;
            removeData.project_id = favored.project_id;
          } else if (favored.task_id) {
            url = `/projects/${favored.root_project}/tasks/${favored.task_id}`;
            title = favored.task;
            removeData.task_id = favored.task_id;
          }

          return (
            <StyledFavItem>
              <StyledFavLink to={url}></StyledFavLink>

              <p>{title}</p>

              <RemoveButton id={favored.id} removeData={removeData} isProject={!!favored.project_id} />
            </StyledFavItem>
          );
        })}
      </StyledFavList>
    </StyledLayout>
  );
};

export default Favorites;
