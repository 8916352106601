const { DateInput } = require('@shared/components');
const { Controller, useWatch } = require('react-hook-form');

const VacationDateSelectController = ({ control, name, placeholder, restraintName }) => {
  const restraint = useWatch({ control, name: restraintName });

  return (
    <Controller
      name={name}
      control={control}
      render={(data) => {
        return (
          <DateInput
            {...data.field}
            ref={null}
            placeholder={placeholder}
            onChange={(date) => data.field.onChange(date)}
            selected={data.field.value}
            dateFormat="P"
            minDate={restraintName === 'dateStart' && restraint}
            maxDate={restraintName === 'dateEnd' && restraint}
          />
        );
      }}
    />
  );
};

export default VacationDateSelectController;
