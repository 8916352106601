import { styled } from 'styled-components';

export const StyledContactsWrapper = styled.div`
  margin: 0 -24px;
  overflow-x: clip;
`;

export const StyledImage = styled.img`
  max-height: 200px;
`;

export const StyledButtonsWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;

  @media (min-width: 992px) {
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
  }
`;

export const StyledCompanyWrapper = styled.section`
  transition: all 0.3s;
  display: grid;
  grid-template-columns: ${({ columns }) => columns || '1fr'};
  gap: 16px;
  align-items: start;
  padding: 24px;
  cursor: pointer;

  &:hover ${StyledButtonsWrapper} {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    background-color: rgba(186, 231, 255, 0.5);
  }
`;
