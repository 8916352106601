import {Link} from 'react-router-dom';
import React from "react";
import {Status} from "@shared/components/Status";
import {Text} from "@shared/components";
import {Avatar} from "@shared/components/Avatar";

import iconMail from "@assets/images/icons/mail.svg";

import {TeamCard, TeamSocial, TeamSocialIcon, TeamSocialItem, TeamUserWrapper} from "../TeamMain/TeamList/style";

const GuestListItem = ({guest}) => {
  return (
    <TeamCard>
      <Status color="light">Гостевой доступ</Status>
      <TeamUserWrapper>
        <Avatar userId={guest.id} size={64} is_guest />
        <Link to={`/team/guests/${guest.id}`}>
          <Text size={3}>{guest.first_name}</Text>
          <span>Доступ к <b>{guest.count}</b> задачам и проектам</span>
        </Link>
      </TeamUserWrapper>

      <TeamSocial>
        <TeamSocialItem href={`mailto:${guest.email}`}>
          <TeamSocialIcon icon={iconMail}/>
          <Text size={2}>{guest.email}</Text>{' '}
        </TeamSocialItem>
      </TeamSocial>
    </TeamCard>
  );
};

export default GuestListItem;
