import { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { UnavailableModal } from '@components/UnavailableModal';
import VacationRequestModal from 'src/windows/employees/VacationRequest';
import useAuth from 'src/hooks/useAuth';
import { NotificationBell } from '@components/NotificationBell/NotificationBell';
import MyProfileModal from 'src/windows/profile/MyProfileModal';
import MyRightsModal from 'src/windows/profile/MyRightsListModal';
import { StyledUserNavList, UserNav, UserNavControls } from '@components/nav/styles';
import { PushLocalStorageTypes, unsubscribeBrowserPush } from '@components/notifications/lib';
import { checkAuthError } from 'src/utilize/helper-functions';
import useLogout from 'src/hooks/useLogout';

import QuickAddNav from './QuickAddNav/QuickAddNav';
import UserNavListItem from './user-nav-list-item';
import Favorites from './Favorites';
import SearchProjectsNav from './SearchProjectsNav';

// регистрация русской локализации для календарей
registerLocale('ru', ru);
setDefaultLocale('ru');

const UserNavList = ({ handler, isOpen, handlerBlur }) => {
  const { t /*, i18n*/ } = useTranslation();
  const auth = useAuth();

  const logout = useLogout();

  const onLogOut = useCallback(() => {
    if (window.Notification?.permission === 'granted' && 'serviceWorker' in navigator && 'PushManager' in window) {
      unsubscribeBrowserPush().then(() => {
        localStorage.setItem(PushLocalStorageTypes.key, PushLocalStorageTypes.loggedOut);
      });
    }
    logout();
  }, [logout]);

  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  //   // установка локализации для всех дат
  //   moment.locale(language);
  //   // установка локализации для всех календерей
  //   setDefaultLocale(language);
  // };

  const [myProfileModal, setMyProfileModal] = useState(false);
  const [myRightsModal, setMyRightsModal] = useState(false);
  const [unavailableModalVisible, setUnavailableModalVisible] = useState(false);
  const [vacationRequestModal, setVacationRequestModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const { showSnackbar } = useContext(SnackbarContext);

  const getUserData = useCallback(async () => {
    if (auth?.user?.id && !auth.user.is_guest) {
      await axios
        .get(`/api/personal_data/${auth.user.id}`)
        .then((res) => {
          if (res.data.personalData) {
            setUserData(res.data.personalData);
          }
        })
        .catch((error) => {
          if (checkAuthError(error)) logout();
          else showSnackbar('Возникла ошибка при получении данных о профиле');
        });
    }
  }, [logout]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const currentUserData = useSelector((state) => state.users.employees?.find((emp) => emp.id === auth?.user?.id));

  const userNavLinkList = useMemo(() => {
    const linkList = {
      [t('UserNavList.edit')]: {
        handler: () => {
          setMyProfileModal(true);
          handlerBlur(false);
        },
        separator: false,
      },
      'Мои права': {
        handler: () => {
          setMyRightsModal(true);
          handlerBlur(false);
        },
        separator: true,
      },

      [t('UserNavList.be_unavailable')]: {
        handler: () => {
          setUnavailableModalVisible(true);
          handlerBlur(false);
        },
      },

      [t('UserNavList.request_for_vacation')]: {
        handler: () => {
          setVacationRequestModal(true);
          handlerBlur(false);
        },
        separator: true,
      },

      [t('UserNavList.log_out')]: {
        handler: onLogOut,
      },
    };
    if (auth?.user?.is_guest === 1) {
      delete linkList[t('UserNavList.edit')];
      delete linkList[t('UserNavList.be_unavailable')];
    }
    // Не показывать меню запроса на отпуск, если: -юзер-гость; -у юзера нет руководителя; -не закреплен за отделом
    if (auth?.user?.is_guest === 1 || !currentUserData?.head_id || !currentUserData.department_id) {
      delete linkList[t('UserNavList.request_for_vacation')];
    }

    return linkList;
  }, [auth?.user?.is_guest, onLogOut, t, currentUserData]);

  return (
    <>
      <UserNav
        role="menuitem"
        tabIndex={0}
        onBlur={() => {
          handlerBlur(false);
        }}
      >
        <UserNavControls>
          {auth?.user?.is_guest === 0 && <QuickAddNav />}
          <SearchProjectsNav />
        </UserNavControls>

        <StyledUserNavList>
          {auth?.user?.is_guest === 0 && <Favorites />}
          <NotificationBell />
          <UserNavListItem
            handler={handler}
            isOpen={isOpen}
            userId={auth?.user?.id}
            firstName={auth?.user?.name}
            lastName={auth?.user?.surname}
            profile
            id="user-nav__link--profile"
            linkList={userNavLinkList}
          />

          {/* кнопки для переключения языков */}
          {/* <button onClick={() => changeLanguage("en")}>EN</button>
        <button onClick={() => changeLanguage("ru")}>RU</button> */}
        </StyledUserNavList>
      </UserNav>

      {unavailableModalVisible && <UnavailableModal onClose={() => setUnavailableModalVisible(false)} />}
      {vacationRequestModal && <VacationRequestModal onClose={() => setVacationRequestModal(false)} />}

      {myProfileModal && (
        <MyProfileModal close={() => setMyProfileModal(false)} userData={userData} getUserData={getUserData} />
      )}

      {myRightsModal && <MyRightsModal close={() => setMyRightsModal(false)} />}
    </>
  );
};

export default UserNavList;
