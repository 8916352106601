import { useState, useMemo } from 'react';
import { arrayOf, object } from 'prop-types';

import { ItemLabel, ListItem } from './styles';
import Sublist from './Sublist';
import { useStructureDropdownContext } from './StructureDropdownContext';

StructureItem.propTypes = {
  structureItem: object,
  structureItemPath: arrayOf(object),
};

function StructureItem({ structureItem, structureItemPath }) {
  const { structureItemIcon, itemLabelCustomStylesFunc, handleSelect, sublistKey, selectedStructureItem } =
    useStructureDropdownContext();
  const [isStructureItemExpanded, setIsStructureItemExpanded] = useState(false);

  // используется для отображения пути выбранного элемента структуры
  const structureItemPathArray = useMemo(() => {
    const currentPath = { [structureItem.title]: structureItem.id };

    if (Array.isArray(structureItemPath)) {
      return [...structureItemPath, currentPath];
    }
    return [currentPath];
  }, [structureItemPath, structureItem]);

  const hasSublist = useMemo(() => structureItem[sublistKey]?.length > 0, [structureItem, sublistKey]);

  return (
    <ListItem
      onClick={(e) => {
        e.stopPropagation();
        // если элемент структуры не раскрыт, раскрыть этот элемент и выбрать этот элемент
        // если элемент раскрыт, то свернуть этот элемент
        if (!isStructureItemExpanded) handleSelect(structureItem, structureItemPathArray);
        if (hasSublist) setIsStructureItemExpanded(!isStructureItemExpanded);
      }}
    >
      <ItemLabel
        $highlighted={selectedStructureItem?.id === structureItem.id}
        $active={isStructureItemExpanded}
        $hasSublist={hasSublist}
        $icon={structureItemIcon}
        $customStylesFunc={itemLabelCustomStylesFunc}
      >
        {structureItem.title}
      </ItemLabel>
      <Sublist
        hidden={!isStructureItemExpanded}
        structureItemsArray={structureItem[sublistKey]}
        structureItemPathArray={structureItemPathArray}
      />
    </ListItem>
  );
}

export default StructureItem;
