import { Label, Modal } from '@shared/components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from 'src/redux/features/appVersionsSlice';

export const AppVerionInfoModal = () => {
  const dispatch = useDispatch();

  return (
      <Modal
        modalSize="767px"
        title="Обновление портала"
        confirmButtonText={'Перезагрузить'}
        onSave={() => window.location.reload()}
        cancelButtonText={'Через две минуты'}
        onClose={() => {
          setTimeout(() => {
            dispatch(showModal());
          }, 1000 * 60 * 2);

          dispatch(hideModal());
        }}
      >
        <Label>Версия портала была обновлена. Необходимо перезагрузить портал.</Label>
      </Modal>
  );
};
