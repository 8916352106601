import { useState, useContext, useEffect, useMemo, useRef } from 'react';
import JSZip from 'jszip';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Modal, Text } from '@shared/components';
import { StorageList } from '@components/storage/styles';
import FileToUpload from '@components/files/FileToUpload';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { incrementLastNumberInParentheses, useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import { getStorageData, getStorageTree } from 'src/redux/features/storageSlice';
import ConfirmAction from '@components/warnings/ConfirmAction';

import { nanoid } from 'nanoid';

import { useResumableForFiles } from './useResumableForFiles';
import { getFileNameWithoutExtension, getQueryData } from './lib';
import { useResumableForFolders } from './useResumableForFolders';

// dataId - id родителя (хранилище или папка)
export const DropFoldersAndFilesModalV2 = ({ storageId, folderId, close, droppedItems }) => {
  const [confirmClose, setConfirmClose] = useState();
  const [isSubmitting, setIsSubmitting] = useState();

  const [folderEntriesToUpload, setFolderEntriesToUpload] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const isLoadingCompleteRef = useRef({ isFoldersLoading: false, isFilesLoading: false });

  const isLoadingComplete = useMemo(() => {
    const isLoadingComplete = isLoadingCompleteRef.current;
    return isLoadingComplete.isFilesLoading && isLoadingComplete.isFoldersLoading;
  }, [isLoadingCompleteRef.current.isFilesLoading, isLoadingCompleteRef.current.isFoldersLoading]);

  const onClose = () => {
    setTimeout(() => {
      dispatch(getStorageData({ storageId, showSnackbar }));
      dispatch(getStorageTree({ storageId, showSnackbar }));
    }, [1000]);

    close();
  };

  const [resumableForFiles] = useState(
    useResumableForFiles({
      folderId,
      storageId,
      setFilesToUpload,
      isLoadingCompleteRef,
      onClose,
    }),
  );

  const [resumableForFolders] = useState(
    useResumableForFolders({
      folderId,
      storageId,
      setFolderUploadStatus: setFolderEntriesToUpload,
      isLoadingCompleteRef,
      onClose,
    }),
  );

  const { showSnackbar } = useContext(SnackbarContext);

  const dispatch = useDispatch();

  const { dataType, dataId } = useMemo(() => {
    const dataType = folderId ? 'folder' : 'storage';
    const dataId = folderId || storageId;
    return { dataType, dataId };
  }, [folderId, storageId]);

  const memoizedDroppedItems = useRef();

  // отсортировать подброшенные папки и файлы в соответствующие массивы, для их дальнейшей отправки на сервер
  useEffect(() => {
    const handleDrop = async () => {
      if (droppedItems === memoizedDroppedItems.current) return;
      if (!droppedItems.length) return;
      memoizedDroppedItems.current = droppedItems;

      const folderEntries = [];
      const filesArr = [];
      const filePromises = [];
      for (let i = 0; i < droppedItems.length; i++) {
        if (!droppedItems[i].webkitGetAsEntry) continue;
        const entry = droppedItems[i].webkitGetAsEntry();

        if (entry.isDirectory) {
          entry.uploadStatus = 'wait';
          folderEntries.push(entry);
        } else if (entry.isFile) {
          const filePromise = new Promise((resolve, reject) => {
            entry.file(
              (file) => {
                file.uploadStatus = 'wait';
                filesArr.push(file);
                resolve();
              },
              (e) => reject(e),
            );
          });
          filePromise.catch(() => {
            showSnackbar('Возникла ошибка при чтении файла');
          });
          filePromises.push(filePromise);
        }
      }
      await Promise.all(filePromises);

      if (folderEntries.length) setFolderEntriesToUpload(folderEntries);
      if (filesArr.length) {
        setFilesToUpload(
          filesArr.map((file) => {
            file.uniqueIdentifier = nanoid(10);
            return file;
          }),
        );
      }
    };
    handleDrop();
  }, [droppedItems]);

  const renderTitle = () => {
    if (folderEntriesToUpload.length && !filesToUpload.length) return ' папки';
    else if (!folderEntriesToUpload.length && filesToUpload.length) return ' файлы';
    else return ' папки и файлы';
  };

  const folderNameDuplicateFlag = useRef();
  const fileNameDuplicateFlag = useRef();
  const [nameDuplicatesWarning, setNameDuplicatesWarning] = useState();

  // после проверки дубликатов на сервере, отслеживать убрали ли из списка дубликаты
  // если убрали дубликаты, то предупреждение скрыть
  useEffect(() => {
    if (!folderNameDuplicateFlag.current) return;
    if (
      nameDuplicatesWarning &&
      !folderEntriesToUpload.some((folder) => folder.nameExists) &&
      !filesToUpload.some((file) => file.nameExists)
    ) {
      setNameDuplicatesWarning(false);
    }
  }, [folderEntriesToUpload, filesToUpload]);

  const checkFolderExistence = (title, index) => {
    setIsSubmitting(true);
    return axios
      .get(`/api/storage_folder_exist/${dataType}/${dataId}/${title}`)
      .then((r) => {
        const { storage_folder_exist } = r.data;
        if (storage_folder_exist) {
          folderNameDuplicateFlag.current = true;
          setFolderEntriesToUpload((f) => {
            const updFolderEntriesToUpload = [...f];
            updFolderEntriesToUpload[index].nameExists = true;
            return updFolderEntriesToUpload;
          });
        }
      })
      .catch(() => {
        showSnackbar(`Возникла ошибка при попытке проверки наличия папки с названием "${title}"`);
        setIsSubmitting(false);
      });
  };

  const checkFilesExistence = () => {
    const reqBody = {
      [`${dataType}_id`]: dataId,
      files: filesToUpload.map((file) => file.name),
    };

    return axios
      .post('/api/storage_file_exist', reqBody)
      .then((r) => {
        const { storage_files_exist } = r.data;
        if (storage_files_exist.length) {
          fileNameDuplicateFlag.current = true;
          setFilesToUpload((f) =>
            f.map((file) => {
              if (!storage_files_exist.includes(file.name)) return file;
              file.nameExists = true;
              return file;
            }),
          );
        }
      })
      .catch(() => {
        showSnackbar('Ошибка при проверке наличия таких файлов на сервере');
        setIsSubmitting(false);
      });
  };

  const uploadFoldersAndFiles = async () => {
    if (filesToUpload.length) {
      filesToUpload.forEach((file) => {
        if (!!resumableForFiles.getFromUniqueIdentifier(file?.uniqueIdentifier)) {
        } else {
          resumableForFiles.addFile(file);
        }
      });
    }

    for (let ind = 0; ind < folderEntriesToUpload.length; ind++) {
      const subfoldersArr = [folderEntriesToUpload[ind]];
      const filesToZipArr = [];
      while (subfoldersArr.length) {
        const folder = subfoldersArr.shift();
        const entryPromise = new Promise((resolve, reject) => {
          folder.createReader().readEntries(
            async (entries) => {
              if (!entries?.length) resolve();
              for (const entry of entries) {
                if (entry.isDirectory) {
                  subfoldersArr.push(entry);
                } else if (entry.isFile) {
                  // filePath нужен для сохранения структуры папки (пути файлов внутри папки)
                  let filePath = '';
                  if (typeof entry.fullPath === 'string') {
                    filePath = entry.fullPath.split('/');
                    filePath.splice(0, 2);
                    filePath = filePath.join('/');
                  }
                  const fileExtractPromise = new Promise((resolve, reject) => {
                    entry.file(
                      (file) => {
                        file.filePath = filePath;
                        filesToZipArr.push(file);
                        resolve();
                      },
                      (error) => reject(error),
                    );
                  });
                  await fileExtractPromise.catch(() => reject());
                }
              }
              resolve();
            },
            (error) => {
              reject(error);
            },
          );
        });
        await entryPromise.catch(() => {
          showSnackbar('Возникла ошибка при чтении файлов в папке');
        });
      }
      const zip = new JSZip();

      for (const fileToZip of filesToZipArr) {
        let filename = fileToZip.name;
        const filePath = fileToZip.filePath;
        // если файл с таким названием уже внесен в список для архивации, то добавить номер в названии файла
        while (zip.files[filename]) {
          filename = incrementLastNumberInParentheses(filename);
        }
        zip.file(filePath || filename, fileToZip, {
          createFolders: true,
        });
      }

      const blob = await zip.generateAsync({ type: 'blob' }).catch(() => {
        showSnackbar('Возникла ошибка при архивации файлов в папке');
      });

      if (blob) {
        const file = new File([blob], `${folderEntriesToUpload[ind].name}.zip`, { type: 'application/zip' });
        resumableForFolders.addFile(file);
      }
    }

    setTimeout(() => {
      resumableForFiles.upload();
      resumableForFolders.upload();
    }, 1000);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (nameDuplicatesWarning) {
      folderNameDuplicateFlag.current = false;
      setNameDuplicatesWarning(false);
      uploadFoldersAndFiles();
      return;
    }
    folderNameDuplicateFlag.current = false;
    fileNameDuplicateFlag.current = false;
    const nameDuplicateRequests = [];
    for (let i = 0; i < folderEntriesToUpload.length; i++) {
      nameDuplicateRequests.push(checkFolderExistence(folderEntriesToUpload[i].name, i));
    }
    nameDuplicateRequests.push(checkFilesExistence());

    await Promise.all(nameDuplicateRequests);
    if (folderNameDuplicateFlag.current || fileNameDuplicateFlag.current) {
      setIsSubmitting(false);
      return setNameDuplicatesWarning(true);
    } else return uploadFoldersAndFiles();
  };

  const handleClose = () => {
    if (folderEntriesToUpload.length || filesToUpload.length) {
      return setConfirmClose(true);
    }
    close();
  };

  const removeFolderHandler = async ({ currentFolderFile, index }) => {
    if (typeof index === 'number') {
      setFolderEntriesToUpload(folderEntriesToUpload.filter((file, ind) => ind !== index));
    }

    const queryData = getQueryData({ storageId, folderId });

    const resumableFolderFile = resumableForFolders.files.find(
      (file) => getFileNameWithoutExtension(file.fileName) === currentFolderFile?.name,
    );

    try {
      if (resumableFolderFile) {
        resumableFolderFile.cancel();
      }

      if (currentFolderFile.uploadStatus === 'loading') {
        await axios.delete('/api/storage_folder/cancel', {
          data: {
            uniqueIdentifier: resumableFolderFile.uniqueIdentifier,
            ...queryData,
          },
        });
      }

      if (currentFolderFile.uploadStatus === 'done') {
        await axios.patch('/api/storage_folders/remove', {
          folder_id: currentFolderFile.localId,
        });
      }
    } catch (e) {
      showSnackbar('Возникла ошибка при удалении папки', 'error');
    }
  };

  const removeFileHandler = async ({ currentFile, index }) => {
    setFilesToUpload(filesToUpload.filter((file, ind) => ind !== index));

    const queryData = getQueryData({ storageId, folderId });

    const resumableFile = resumableForFiles.getFromUniqueIdentifier(currentFile.uniqueIdentifier);

    try {
      if (resumableFile) {
        resumableFile.cancel();
      }

      if (currentFile.status === 'done') {
        axios.patch('/api/storage_files/remove', {
          files: currentFile.localId,
        });
      }

      if (currentFile.status === 'loading') {
        await axios.delete('/api/storage_file/cancel', {
          data: {
            uniqueIdentifier: currentFile.uniqueIdentifier,
            ...queryData,
          },
        });
      }
    } catch (e) {
      showSnackbar('Возникла ошибка при удалении файла', 'error');
    }
  };

  const removeFilesAndFoldersHandler = async () => {
    const uploadedFiles = filesToUpload.filter((file) => file.status === 'done');
    const loadingFiles = filesToUpload.filter((file) => file.status === 'loading');

    try {
      // Удаление файлов, которые находятся в процессе загрузки, а также тех, которые уже загружены

      if (filesToUpload.length) {
        resumableForFiles.cancel();
        setFilesToUpload([]);
      }

      if (uploadedFiles.length) {
        axios.patch('/api/storage_files/remove', {
          files: uploadedFiles.map((file) => file.localId),
        });
      }

      if (loadingFiles.length) {
        const queryData = getQueryData({ storageId, folderId });

        for (const loadingFile of loadingFiles) {
          await axios.delete('/api/storage_file/cancel', {
            data: {
              uniqueIdentifier: loadingFile.uniqueIdentifier,
              ...queryData,
            },
          });
        }
      }

      // Удаление папок, которые находятся в процессе загрузки

      if (folderEntriesToUpload.length) {
        for (const folderFile of folderEntriesToUpload) {
          await removeFolderHandler({ currentFolderFile: folderFile });
        }

        setFolderEntriesToUpload([]);
      }
    } catch (e) {
      showSnackbar('Возникла ошибка при удалении', 'error');
    }
  };

  useEventTriggerOnEscPress(handleClose);

  useEffect(() => {
    if (isLoadingComplete) {
      onClose();
    }
  }, [isLoadingComplete]);

  return (
    <>
      <Modal
        title={`Добавить ${renderTitle()}`}
        onClose={handleClose}
        onSave={handleSubmit}
        disabledSaveButton={(!filesToUpload.length && !folderEntriesToUpload.length) || isSubmitting}
        confirmButtonText={`${nameDuplicatesWarning ? 'Подтвердить' : 'Добавить'}`}
      >
        <StorageList>
          {folderEntriesToUpload.map((folder, i) => {
            return (
              <FileToUpload
                $error={folder.nameExists}
                file={folder}
                progressPercent={folder.progress}
                isUploaded
                removeFile={() => {
                  removeFolderHandler({ currentFolderFile: folder, index: i });
                }}
                key={i}
                index={i}
              />
            );
          })}

          {filesToUpload.map((file, i) => (
            <FileToUpload
              $error={file.nameExists}
              file={file}
              progressPercent={file.progress}
              isUploaded
              removeFile={() => removeFileHandler({ currentFile: file, index: i })}
              key={i}
              index={i}
            />
          ))}
        </StorageList>

        {nameDuplicatesWarning && (
          <Text size={1} color="#FF4D4F">
            Названия выделенных папок или файлов существуют по этому пути. Подтвердите, что хотите загрузить эти папки и
            файлы как новые версии или удалите из списка.
          </Text>
        )}
      </Modal>

      {confirmClose && (
        <ConfirmAction
          confirm={() => {
            removeFilesAndFoldersHandler();

            onClose();
          }}
          cancel={() => setConfirmClose(false)}
          actionText="Вы уверены что хотите закрыть форму, не сохранив изменения?"
        />
      )}
    </>
  );
};
