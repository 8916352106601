import useAuth from 'src/hooks/useAuth';

import {downloadFileUrl, files_url} from 'src/settings/base-url';
import DeleteCircleButton from '@shared/components/DeleteCircleButton';
import {Container} from "@shared/components";
import { unique13NumRegex } from 'src/utilize/regex';
import { isFileNameImage } from 'src/utilize/helper-functions';
import {Text} from "@shared/components";

import ProgressBar from '../files/ProgressBar_2';

import { StyledAttachImage, StyledAttachItem } from './styles';
import { getFileIcon } from './lib';

const FileToUpload = ({
  $error,
  file,
  progressPercent,
  removeFile,
  index,
  isRemoving,
  isUploaded,
  errorUploading,
  removable = true,
  isFromStorage,
}) => {
  const auth = useAuth();

  let childContent;
  let src = '';
  let href = '';

  if (file.src) {
    const url = `${downloadFileUrl}${encodeURIComponent(file.src)}?token=${auth.token}`;
    href = isFromStorage ? file.src : url;
    src = `${files_url}/${encodeURIComponent(file.src)}?token=${auth.token}`
  } else if (file instanceof File || file instanceof Blob) {
    src = URL.createObjectURL(file);
  }

  if ((file.type && file.type.includes('image')) || isFileNameImage(file.name)) {
    childContent = (
      <Container
        align="center"
        gap="8px"
        style={{width: "max-content"}}
      >
        <StyledAttachImage href={href || src} target="_blank" rel="noreferrer">
          <img src={src || href} alt="att" />
        </StyledAttachImage>
        <Text style={{whiteSpace: "nowrap"}} size={1} color={$error ? "#FF4D4F" : "#262626"}>{file.name?.replace(unique13NumRegex, '.')}</Text>
      </Container>
    );
  } else {
    childContent = (
      <Container
        style={{width: "100%"}}
        align="center"
        gap="8px"
        as="a"
        href={src}
        target="_blank"
        rel="noreferrer"
      >
        <StyledAttachImage as="span" icon={getFileIcon(file.name)} />
        <Text size={1} color={$error ? "#FF4D4F" : "#262626"}>{file.name?.replace(unique13NumRegex, '.')}</Text>
      </Container>
    );
  }

  return (
    <div>
      <StyledAttachItem>
        {childContent}
        {removable && (
          <DeleteCircleButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              removeFile(file, index);
            }}
          />
        )}
      </StyledAttachItem>
      <ProgressBar
        progressPercent={progressPercent}
        isRemoving={isRemoving}
        isUploaded={isUploaded}
        errorUploading={errorUploading}
      />
    </div>
  );
};

export default FileToUpload;
