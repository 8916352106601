import { styled } from 'styled-components';

export const FiltersWrapper = styled.div`
  padding-top: 32px;
  
`;

export const StyledRow = styled.tr`
  & button {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    button {
      visibility: visible;
      opacity: 1;
    }
  }
`;
