import { shape, object, func } from 'prop-types';
import {useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {ButtonWithIcon, Container, HrLine, IconButton, Title} from '@shared/components';
import { styled } from 'styled-components';
import iconAdd from '@assets/images/icons/plus.svg';
import iconFilter from "@assets/images/icons/filter.svg";

import useAuth from "../../../hooks/useAuth";

import { project_window, office_window} from '../../../settings/window-types';

import ProjectListFilter from './project-list-filter/ProjectListFilter';
// import ProjectListTabs from "./project-list-tabs/ProjectListTabs";
import ProjectListContainer from './project-list-container/ProjectListContainer';


ProjectList.propTypes = {
  popupWindow: shape({
    props: shape({
      data: object,
      onClose: func,
      onEdit: func,
      onOpen: func,
    }),
  }),
};

function ProjectList(props) {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const location = useLocation();

  const { isUserRightful, rightTypes } = useAuth();
  const hasRightToCreateProject = useMemo(() => isUserRightful(rightTypes.project_create), [isUserRightful, rightTypes]);

  return (
    <StyledProjectList>
        <StyledProjectHeader>
          {location.pathname === '/offices' ? 'Виртуальные офисы' : 'Проекты'}

          <Container gap={'12px'} align={'center'}>
            <IconButton
              style={{backgroundSize: '24px'}}
              size={40}
              background="#1890FF"
              icon={iconFilter}
              onClick={() => setIsFiltersOpen((isFiltersOpen) => !isFiltersOpen)}
            />

            { hasRightToCreateProject && (
              location.pathname === '/offices' ? (
                <ButtonWithIcon
                  color="green"
                  position="left"
                  icon={iconAdd}
                  size={20}
                  onClick={() => {
                    props.popupWindow.props.onOpen(office_window);
                  }}
                >Добавить офис
                </ButtonWithIcon>
              ) : (
                <ButtonWithIcon
                  color="green"
                  position="left"
                  icon={iconAdd}
                  size={20}
                  onClick={() => {
                    props.popupWindow.props.onOpen(project_window);
                  }}
                >Добавить проект
                </ButtonWithIcon>
              )
            )}

          </Container>
        </StyledProjectHeader>

      {/*<ProjectListTabs />*/}
      {/* панель фильтрации проектов */}

      <HrLine />

      {isFiltersOpen && <ProjectListFilter />}

      {/* список проектов */}
      <ProjectListContainer {...props} location={location} />
    </StyledProjectList>
  );
}

export const StyledProjectList = styled.section`
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 24px;

  @media (max-width: 550px) {
    padding: 0 12px;
  }
`;

export const StyledProjectHeader = styled(Title)`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
  align-items: center;
  
  @media (min-width: 578px) {
    grid-template-columns: 1fr max-content;
  }
`

export default ProjectList;
