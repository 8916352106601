import { useCallback, useEffect, useMemo, useState } from 'react';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createMentionPlugin from '@draft-js-plugins/mention';
import { useSelector } from 'react-redux';
import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';
import { BoldButton, ItalicButton, UnderlineButton } from '@draft-js-plugins/buttons';

import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import { StyledMessageUsername } from '@components/chat/message/styles';

import './mentionsStyles.css';
import Entry from './Entry';
import { Wrapper } from './styles';

const EditorWithMentions = ({
  editorState,
  setEditorState,
  handleKeyCommand,
  quoteMessage,
  editorRef,
  containerRef,
  utilsVisible,
}) => {
  const employees = useSelector((state) => state.users.employees);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const usersMentionData = useMemo(() => {
    if (employees?.length) {
      return employees.map((e) => ({
        name: `${e.first_name ? e.first_name : ''} ${e.last_name ? e.last_name : ''}`,
        firstName: e.first_name,
        lastName: e.last_name,
        email: e.email,
        userId: e.id,
        position: e.position,
      }));
    } else return [];
  }, [employees]);

  const [{ MentionSuggestions, plugins, Toolbar }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin();
    const linkDetectionPlugin = createLinkDetectionPlugin();

    const { Toolbar } = toolbarPlugin;

    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionComponent(mentionProps) {
        return <StyledMessageUsername>{mentionProps.children}</StyledMessageUsername>;
      },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [toolbarPlugin, mentionPlugin, linkDetectionPlugin];
    return { plugins, MentionSuggestions, Toolbar };
  });

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }) => {
      setSuggestions(usersMentionData.filter((user) => user.name.toLowerCase().includes(String(value).toLowerCase())));

      // setSuggestions(defaultSuggestionsFilter(value, mentions));
    },
    [usersMentionData],
  );

  useEffect(() => {
    if (quoteMessage?.quoteText) {
      if (editorRef.current) {
        editorRef.current.focus();
      }
    }
  }, [quoteMessage, editorRef, containerRef]);

  return (
    <Wrapper
      onClick={() => {
        if (editorRef.current) editorRef.current.focus();
      }}
      ref={containerRef}
    >
      <div style={{ order: 2 }}>
        <Editor
          editorKey={'editor'}
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
          ref={(editor) => (editorRef.current = editor)}
          handleKeyCommand={handleKeyCommand}
        />
      </div>

      <div style={{ order: 1, display: utilsVisible ? 'unset' : 'none' }}>
        {utilsVisible && (
          <Toolbar>
            {(externalProps) => (
              <>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
              </>
            )}
          </Toolbar>
        )}
      </div>
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        entryComponent={Entry}
      />
    </Wrapper>
  );
};

export default EditorWithMentions;
