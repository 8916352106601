import { Container, Title } from '@shared/components';
import { Tabs } from '@components/requests/Tabs';

import { StyledSubheader } from './styles';

function PageRequestsHeader() {
  return (
    <>
      <Container justify="space-between" align="center" wrap="wrap">
        <Container gap="0 24px" align="center" wrap="wrap">
          <Title>Просьбы</Title>
        </Container>
      </Container>
      <StyledSubheader>
        <Tabs />
      </StyledSubheader>
    </>
  );
}

export default PageRequestsHeader;
