import { shape, arrayOf, number, func, object } from 'prop-types';
import {StorageList} from "@components/storage/styles";

import FolderDetailsRow from './FolderDetailsRow';
import FileDetailsRow from './FileDetailsRow';

FoldersAndFilesList.propTypes = {
  openFolder: shape({
    folders: arrayOf(object),
    files: arrayOf(object),
  }),
  renderUserName: func,
  handleFolderSelect: func,
  storageIdToReq: number,
  storageData: object,
  attachFolderReference: func,
  addStorageFileReference: func,
};

function FoldersAndFilesList({
  openFolder,
  renderUserName,
  handleFolderSelect,
  storageIdToReq,
  storageData,
  attachFolderReference,
  addStorageFileReference,
}) {
  return (
    <StorageList>
      {openFolder?.folders?.map((folder) => (
        <FolderDetailsRow
          key={folder.id}
          renderUserName={renderUserName}
          folder={folder}
          handleFolderSelect={handleFolderSelect}
          storageId={storageIdToReq}
          userCanWrite={storageData.can_write}
          attachFolderReference={attachFolderReference}
        />
      ))}

      {openFolder?.files?.map((fileData) => (
        <FileDetailsRow
          key={fileData.id}
          fileData={fileData}
          renderUserName={renderUserName}
          storageId={storageIdToReq}
          addStorageFileReference={addStorageFileReference}
          userCanWrite={storageData.can_write}
        />
      ))}
    </StorageList>
  );
}

export default FoldersAndFilesList;
