import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const TeamTreeItem = styled.li`
  position: relative;
`;

export const TeamTreeCard = styled.div`
  display: flex;
  position: relative;
  width: fit-content;

  &:after {
    z-index: -1;
    border-left: 1px dashed ${({theme}) => theme.disabled};
    bottom: 32px;
    content: '';
    height: 100px;
    top: -70px;
    left: -28px;
    position: absolute;
  }

  &:before {
    z-index: -1;
    border-bottom: 1px ${({theme}) => theme.disabled};
    content: '';
    left: -28px;
    position: absolute;
    top: 32px;
    width: 27px;
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 600;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({theme}) => theme.main};
  }
`;

export const TeamTreeCardPosition = styled.span`
  border: 1px solid #91d5ff;
  border-radius: 2px;
  color: ${({theme}) => theme.main};
  display: inline-block;
  padding: 1px 8px;
  width: max-content;
`;

export const TeamTreeCardStatus = styled.span`
  border: 1px solid #91d5ff;
  border-radius: 2px;
  color: ${({theme}) => theme.main};
  display: inline-block;
  padding: 1px 8px;
  width: max-content;
  white-space: nowrap;
`;
