import React, { useState } from 'react';
import { Checkbox, Input, Label, Modal, SingleSelect, Text, Textarea } from '@shared/components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ConfirmAction from '@components/warnings/ConfirmAction';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';

import { colors } from 'src/variables';

import { useCreateAdditionalFieldMutation } from 'src/redux/features/api/additionalInfo';

import { fieldTypes, formSchema, selectOptions } from './lib';

export const CreateAdditionalFieldModal = ({ order, close }) => {
  const [modalClosePrompt, setModalClosePrompt] = useState();

  const [createAdditionalFields, { isLoading }] = useCreateAdditionalFieldMutation();

  const {
    control,
    register,
    handleSubmit,
    resetField,
    watch,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const handleCloseBtnClick = () => {
    if (modalClosePrompt) return;
    if (isDirty) return setModalClosePrompt(true);
    close();
  };

  const getListValues = (values) => {
    if (!values?.length) {
      return [];
    }

    const listValues = values.split('\n');
    return listValues;
  };

  const onSubmit = async (data) => {
    await createAdditionalFields({
      row: {
        name: data.title,
        order,
        include: data.visible === 'visible' ? 1 : 0,
        type: data.type.value,
        values: data.type.value === fieldTypes.list ? getListValues(data.textarea) : undefined, //если type='list'
      },
    });

    close();
  };

  useEventTriggerOnEscPress(handleCloseBtnClick);

  return (
    <Modal
      modalSize="880px"
      onSave={handleSubmit(onSubmit)}
      onClose={handleCloseBtnClick}
      title={`Добавить дополнительное поле`}
      confirmButtonText={'Добавить'}
      disabledSaveButton={!isValid || !isDirty || isLoading}
    >
      <div>
        <Label htmlFor="title">Название</Label>
        <Input id="title" error={!!errors['title']} register={register('title')} autoComplete="off" />
      </div>
      <div>
        <Label>Тип</Label>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <SingleSelect
              {...field}
              options={selectOptions}
              onChange={(e) => {
                resetField('textarea');
                field.onChange(e);
              }}
            />
          )}
        />
      </div>

      {watch('type')?.value === fieldTypes.list && (
        <div>
          <Label>
            Значения{' '}
            <Text tag={'span'} color={colors.disabled} style={{ fontStyle: 'italic' }}>
              одна строка = одно значение
            </Text>
          </Label>
          <Textarea register={register('textarea')} />
        </div>
      )}

      <div>
        <Checkbox id={'visible'} register={register('visible')}>
          Выводим в список
        </Checkbox>
      </div>

      {modalClosePrompt && (
        <ConfirmAction
          confirm={close}
          cancel={() => setModalClosePrompt(false)}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
    </Modal>
  );
};
