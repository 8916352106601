import React from 'react';
import {Controller} from 'react-hook-form';
import Select, {components} from 'react-select';
import {Label} from '@shared/components';
import {StyledButtonPlus} from '@components/form/select/styles';
import iconAdd from '@assets/images/icons/plus.svg';
import DeleteCircleButton from "@shared/components/DeleteCircleButton";

import {SelectItem} from "../../../pages/Tasks/style";
// import makeAnimated from "react-select/animated";

// надстройка стилей react-select
const colourStyles = {
  // выпадающие штуки списка
  // data - инфа о item списка - label, value
  option: (styles, {isDisabled, isFocused}) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#BAE7FF' : 'transparent',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  // поле с выбранными списками
  control: (styles, {isDisabled}) => {
    //
    return {
      ...styles,
      width: '100%',
      opacity: '1',
      alignItems: 'start',
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr',
      gap: '16px',
      minHeight: 'auto',
      // border: isFocused ? "2px solid  #1890ff" : "1px solid #d9d9d9",
      border: 'none',
      boxShadow: 'none',
      // boxShadow: isFocused ? "0 0 4px rgba(24, 144, 255, 0.5)" : 0,
      ':hover': {
        // border: isFocused ? "2px solid  #1890ff" : "1px solid #d9d9d9",
        border: 'none',
        boxShadow: 'none',
        // boxShadow: isFocused ? "0 0 4px rgba(24, 144, 255, 0.5)" : 0,
      },
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  multiValue: () => {
    return {
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'nowrap',
      border: '1px solid #d9d9d9',
      borderRadius: '2px',
      padding: '1px 8px',
      fontSize: '12px',
      lineHeight: '20px',
      backgroundColor: 'transparent'
    };
  },
  // кнопка удаления
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#d9d9d9',
    borderRadius: '100px',
    border: '1px solid #d9d9d9',
    padding: '0px',
    marginLeft: '12px',
    height: '14px',
    width: '14px',
    ':hover': {
      color: 'red',
      border: '1px solid red',
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    maxHeight: '20px',
    position: 'relative',
    top: '2px'
  }),
  // то что внутри инпута
  multiValueLabel: (/*styles, { data }*/) => {
    // const { color } = data;
    return {
      position: 'relative',
      width: '100%',
    };
  },
  // вертик полоска справа
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      paddingLeft: '20px',
    };
  },
  // иконка выпадающего списка
  dropdownIndicator: () => {
    return {
      alignSelf: 'center',
      maxHeight: '20px'
    };
  },
  input: (styles) => ({
    ...styles,
    padding: 0,
    margin: 0
  }),

  valueContainer: () => ({
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    order: '2',
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledButtonPlus type="button" icon={iconAdd}/>
    </components.DropdownIndicator>
  );
};

const MultiValue = (props) => {
  return <components.MultiValue {...props}>
    {props.children}
  </components.MultiValue>;
};

// компонент для тегов, мультивыбора с выпадашкой и крестиком, переиспользуемый
const SelectMulty = ({item, control, options, customOption, getOptionValue}) => {
  return (
    <div>
      {/* label необходим, иначе не работает выбор в списке */}
      <Label>{item.label}</Label>

      <Controller
        name={item.name}
        control={control}
        render={({field}) => {
          return (
            <Select
              {...field}
              styles={colourStyles}
              closeMenuOnSelect={false}
              components={{DropdownIndicator, MultiValue, Option: customOption ? customOption : components.Option}}
              placeholder={item?.placeholder || ''}
              isMulti
              name="colors"
              isClearable={false}
              options={options} // список выпадашки. по дефолту значения устанавлиаваются в настройках формы в родителе
              hideSelectedOptions
              getOptionValue={getOptionValue}
            />
          );
        }}
      />
    </div>
  );
};

export default SelectMulty;
