import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { notificationTypes } from '@components/notifications/notifications.types';
import { rtkQueryApi } from 'src/redux/features/api/rtkQueryApi';

import { useSocketContext } from 'src/contexts/SocketContext';
import { removeNotification } from 'src/redux/features/notificationsSlice';

import { Container } from '@shared/components/Container';
import { StyledNotificationItem, StyledTitle, StyledType } from '@components/NotificationBell/styles';

const NotificationItem = ({ notificationData, setIsOpen }) => {
  const { readNotification } = useSocketContext();
  const dispatch = useDispatch();

  const readNoteCallback = (data) => {
    if (data?.status === 'ok') {
      dispatch(removeNotification(notificationData.id));
      dispatch(rtkQueryApi.util.invalidateTags(['openedNotifications']));
      setIsOpen(false);
    }
  };

  return (
    <StyledNotificationItem to={notificationData.link}>
      <Container
        onClick={() => {
          readNotification([notificationData.id], readNoteCallback);
        }}
        direction="column"
        align="flex-start"
      >
        <StyledType size="1">{notificationTypes[notificationData.data_type]?.text}</StyledType>

        <StyledTitle>
          {notificationData.project_title ||
            notificationData.task_title ||
            notificationData.ticket_title ||
            `${notificationData.employee_last_name || ''} ${notificationData.employee_first_name}`}
        </StyledTitle>
      </Container>
    </StyledNotificationItem>
  );
};

export default memo(NotificationItem);
