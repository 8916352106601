import { Modal, Text } from '@shared/components';

export const RemovePaymentModal = ({ onSave, onClose }) => {
  return (
    <Modal
      type={'delete'}
      modalSize="548px"
      onSave={onSave}
      onClose={onClose}
      confirmButtonText={'Да'}
      cancelButtonText={'Нет'}
    >
      <Text style={{ textAlign: 'center' }} size={3} tag="p">
        Вы действительно хотите удалить запись о выдаче зарплаты/премии?
      </Text>
    </Modal>
  );
};
