import { useLayoutEffect, useState } from 'react';

const useMenuPosition = ({
                           triggerRef,
                           tooltipRef,
                           position = 'right-top',
                           isVisible,
                           setIsVisible
                         }) => {
  const [coordinates, setCoordinates] = useState({
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
  });

  const scrollPositions = {};

  useLayoutEffect(() => {
    const updatePosition = () => {
      if (!triggerRef?.current || !tooltipRef?.current || !isVisible) return;

      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const positions = {
        'right-top': {
          top: `${triggerRect.bottom + 4}px`,
          right: `${window.innerWidth - triggerRect.right}px`,
        },
        'right-bottom': {
          bottom: `${window.innerHeight - triggerRect.top + 4}px`,
          right: `${window.innerWidth - triggerRect.right}px`,
        },
        'left-top': {
          top: `${triggerRect.bottom + 4}px`,
          left: `${triggerRect.left}px`,
        },
        'left-bottom': {
          bottom: `${window.innerHeight - triggerRect.top + 4}px`,
          left: `${triggerRect.left}px`,
        },
        'top-center': {
          bottom: `${window.innerHeight - triggerRect.top + 4}px`,
          left: `${triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2}px`,
        },
        'bottom-center': {
          top: `${triggerRect.bottom + 4}px`,
          left: `${triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2}px`,
        },
      };

      let chosenPosition = positions[position];

      if (position.includes('top') && tooltipRect.height + triggerRect.bottom + 4 > window.innerHeight) {
        chosenPosition = positions[position.replace('top', 'bottom')];
      } else if (position.includes('bottom') && triggerRect.top < tooltipRect.height + 4) {
        chosenPosition = positions[position.replace('bottom', 'top')];
      }

      setCoordinates((prev) => ({
        ...prev,
        ...chosenPosition,
      }));
    };

    const handleScroll = (e) => {
      const scrolledElement = e.target;

      // Проверка, является ли элемент прокручиваемым
      const isScrollable = scrolledElement.scrollHeight > scrolledElement.clientHeight;
      if (!isScrollable) return;

      // Текущее значение прокрутки
      const currentScrollY = scrolledElement.scrollTop;

      // Получаем предыдущую позицию прокрутки (или инициализируем нулем)
      const prevScrollY = scrollPositions[scrolledElement] || 0;

      // Если разница в прокрутке превышает 10 пикселей, скрываем тултип
      if (Math.abs(currentScrollY - prevScrollY) > 10) {
        setIsVisible(false);
      }

      // Обновляем предыдущую позицию прокрутки
      scrollPositions[scrolledElement] = currentScrollY;
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    isVisible && window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [triggerRef, tooltipRef, position, isVisible, setIsVisible]);

  return coordinates;
};

export default useMenuPosition;
