import React from 'react';

import { StyledText } from './style';

// size (размер текста) может быть от 1 до 6, через as динамически меняется тег элемента
export const Text = ({ tag, size, color, style, className, children }) => {
  return (
    <StyledText as={tag} size={size} color={color} style={style} className={className}>
      {children}
    </StyledText>
  );
};
