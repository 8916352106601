import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  StyledAsideBackwardButton,
  StyledAsideBody,
  StyledAsideHeader,
  StyledAsideLink,
  StyledAsideParentTask,
  StyledAsideTitle,
} from '@components/chat/side-menu/styles'
import { IconButton } from '@shared/components';
import iconAttach from "@assets/images/icons/icon-links.svg";
import iconClose from "@assets/images/icons/close.svg";

import useAuth from '../../../hooks/useAuth';
import UserProfileModalContainer from '../../../windows/profile/UserProfileModalContainer';
import Preloader from '../../preloaders/Preloader';
import { selectGuestProjectRights } from '../../../redux/features/projectsSlice';

import { renderProjectOrTaskName } from './SideMenu';

import ProjectTree from './ProjectTree';
import MessageAside from './sideMenuComponents/MessageAside';

const LinksSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const auth = useAuth();
  const { t } = useTranslation();
  const [projectTree, showProjectTree] = useState(false);
  const [dataType, setDataType] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const [userProfile, showUserProfile] = useState();

  // каждый раз, когда нажимается кнопка открытия панели, производится reset данных
  useEffect(() => {
    if (sideMenu === 'link' && !panelData && dataType && projectData && !isLoading) {
      setIsLoading(true);
      setErrorLoading(false);
      axios
        .get(`/api/sidebar_links/${dataType}/${projectData.id}`)
        .then((response) => {
          const chat_messages = response.data.chat_messages;
          setPanelData({
            messages: chat_messages,
            id: projectData.id,
            title: projectData.title,
          });

          setIsLoading(false);
        })
        .catch(() => {
          setPanelData({});
          setIsLoading(false);
          setErrorLoading(true);
        });
    } else if (sideMenu !== 'link' && panelData) {
      setTimeout(() => {
        setPanelData(null);
        setDataType(null);
        showProjectTree(false);
        setSidebarDataType(null);
      }, 1000);
    }
  }, [sideMenu, dataType, projectData, panelData, isLoading]);

  useEffect(() => {
    if (taskId && dataType !== 'task') setDataType('task');
    if (!taskId && dataType !== 'project') setDataType('project');
  }, [taskId, dataType]);

  const [sidebarDataType, setSidebarDataType] = useState();

  const showRelevantTaskInfo = useCallback((dataType, dataId, title) => {
    setPanelData(null);
    showProjectTree(false);
    setErrorLoading(false);
    setIsLoading(true);
    axios
      .get(`/api/sidebar_links/${dataType}/${dataId}`)
      .then((response) => {
        const chat_messages = response.data.chat_messages;

        setPanelData({
          messages: chat_messages,
          id: dataId,
          title: title,
        });

        setIsLoading(false);
        setSidebarDataType(dataType);
        setErrorLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setErrorLoading(true);
      });
  }, []);

  const guestRights = useSelector(selectGuestProjectRights(sidebarDataType || dataType, panelData?.id));

  return (
    <>
      {userProfile && <UserProfileModalContainer profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <>
        <StyledAsideHeader>
          <StyledAsideTitle icon={iconAttach} iconsize={18}>
            {t('common.links')}
          </StyledAsideTitle>
          <IconButton
            icon={iconClose}
            size={27}
            onClick={() => {
              setTimeout(() => {
                setPanelData(null);
                setDataType(null);
                showProjectTree(false);
              }, 1000);
              openSideMenu(null);
            }}
          />
        </StyledAsideHeader>
        <StyledAsideBody>
          {errorLoading && <div>{t('ErrorMessages.default')}</div>}

          {projectTree ? (
            <ProjectTree
              update={sideMenu === 'link'}
              showRelevantTaskInfo={showRelevantTaskInfo}
              showProjectTree={showProjectTree}
            />
          ) : (
            <>
              {(!auth?.user?.is_guest || guestRights?.includes('access_children')) && (
                <StyledAsideBackwardButton onClick={() => showProjectTree(true)}>
                  Структура проекта
                </StyledAsideBackwardButton>
              )}

              <StyledAsideParentTask>
                {renderProjectOrTaskName(panelData, taskId, sidebarDataType, projectData)}
              </StyledAsideParentTask>

              {isLoading && <Preloader />}

              {sidebarDataType === 'project' && taskId && (
                <StyledAsideLink to={`/projects/${projectId}`}>{t('Project.open_project')}</StyledAsideLink>
              )}

              {sidebarDataType === 'task' && panelData && panelData.id !== +taskId && (
                <StyledAsideLink to={`/projects/${projectId}/tasks/${panelData.id}`}>
                  {t('Project.open_task')}
                </StyledAsideLink>
              )}

              {panelData &&
                Array.isArray(panelData.messages) &&
                panelData.messages.map((chat_message, i) => (
                  <MessageAside
                    key={i}
                    chat_message={chat_message}
                    showUserProfile={showUserProfile}
                    dataType={dataType || sidebarDataType}
                    dataId={panelData?.id}
                  />
                ))}
            </>
          )}
        </StyledAsideBody>
      </>
    </>
  );
};

export default LinksSidePanel;
