import { Container } from '@shared/components/Container';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  display: ${({ $hide }) => ($hide ? 'none' : 'grid')};
  grid-template-columns: 1fr;
  gap: 24px;
  
  @media (min-width: 578px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Wrapper = styled(Container)`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 16px;

  ::placeholder {
    font-size: 16px;
  }
`;
