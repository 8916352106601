import { useEffect, useState, Fragment, useMemo } from 'react';
import axios from 'axios';

import { useContext } from 'react';

import { Modal } from '@shared/components/Modal/Modal';
import { Subtitle } from '@shared/components/Subtitle/Subtitle';
import { Checkbox } from '@shared/components/Checkbox/Checkbox';

import {
  StyledModalFlex,
  StyledModalLabelInfo,
  StyledModalRowStart,
  StyledModalValueInfo,
} from '@shared/components/Modal/style';

import { rightsTranslation, useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import ConfirmAction from '../../components/warnings/ConfirmAction';
import Preloader from '../../components/preloaders/Preloader';
import SnackbarContext from '../../contexts/SnackbarContext';
import useAuth from '../../hooks/useAuth';

import CreateEditGuestModal from './CreateEditGuestModal';

import { StyledAccessList } from './style';

const GuestModalCurrent = ({ toEdit, dataType, dataId, guestId, close, projectData, fetchData }) => {
  const [editGuest, setGuestEdit] = useState(toEdit);
  const [guestData, setGuestData] = useState();

  // получить данные по просматриваемому гостю
  useEffect(() => {
    if (dataType && dataId && guestId) {
      axios
        .get(`/api/guest/${dataType}/${dataId}/${guestId}`)
        .then((r) => {
          setGuestData(r.data.guest);
        })
        .catch(() => showSnackbar('Ошибка при получении данных гостя'));
    }
  }, [dataType, dataId, guestId]);

  // проверить есть ли у юзера права на изменение статуса проекта/таска
  // const userHasRights = useMemo(() => {
  //   if (!auth?.user?.id || !auth?.rightTypes) return false;
  //   if (auth?.isUserRightful()) return true;
  //   if (auth.user.id === projectData?.creator_id) return true;
  //   if (auth.user.id === projectData?.responsible_id) return true;
  //   return false;
  // }, [auth, projectData]);

  const auth = useAuth();

  const hasRightToEdit = useMemo(() => {
    if (!auth?.user?.id || !auth?.rightTypes) return false;
    if (auth?.isUserRightful(auth.rightTypes.team_edit)) return true;
    if (auth.user.id === projectData?.creator_id) return true;
    if (auth.user.id === projectData?.responsible_id) return true;
  }, [auth, projectData]);

  const [isSubmitting, setIsSubmitting] = useState();

  const [confirmModal, showConfirmModal] = useState();
  const { showSnackbar } = useContext(SnackbarContext);

  const deleteAccess = () => {
    setIsSubmitting(true);
    const requestBody = {
      guest_id: guestId,
    };

    dataType === 'project' ? (requestBody.project_id = dataId) : (requestBody.task_id = dataId);

    axios
      .delete('/api/guest_rights/delete', { data: requestBody })
      .then(() => {
        showSnackbar('Гостевые права удалены', 'success');
        if (fetchData) fetchData();
        close();
      })
      .catch(() => {
        showSnackbar('Ошибка при удалении');
      });
  };

  useEventTriggerOnEscPress(() => {
    if (!editGuest) close();
  });

  const headerButtons = [{ name: 'Удалить', action: () => showConfirmModal(true) }];

  return (
    <>
      {editGuest ? (
        <CreateEditGuestModal
          toEdit={true}
          close={close}
          guestData={guestData}
          dataType={dataType}
          projectData={projectData}
          fetchData={fetchData}
        />
      ) : (
        <Modal
          headerButtons={guestData && hasRightToEdit && headerButtons}
          title="Просмотр гостевого доступа"
          onClose={close}
          disabledSaveButton={!hasRightToEdit || isSubmitting}
          onSave={() => setGuestEdit(true)}
          confirmButtonText="Редактировать"
        >
          {guestData ? (
            <>
              <StyledModalRowStart>
                <div>
                  <StyledModalLabelInfo>Имя</StyledModalLabelInfo>
                  <StyledModalValueInfo>{guestData.first_name}</StyledModalValueInfo>
                </div>
                <div>
                  <StyledModalLabelInfo>Электронная почта</StyledModalLabelInfo>
                  <StyledModalValueInfo>{guestData.email}</StyledModalValueInfo>
                </div>
              </StyledModalRowStart>

              <div>
                <StyledModalFlex>
                  <Subtitle>Права доступа</Subtitle>
                </StyledModalFlex>

                <StyledAccessList>
                  {guestData.rights.map((accessType, i) => {
                    if (!rightsTranslation[accessType]) return '';
                    return (
                      <Checkbox key={i} id={accessType} disabled="disabled" checked="checked">
                        {rightsTranslation[accessType]}
                      </Checkbox>
                    );
                  })}
                </StyledAccessList>
              </div>
            </>
          ) : (
            <Preloader />
          )}
        </Modal>
      )}
      {confirmModal && (
        <ConfirmAction
          confirm={deleteAccess}
          cancel={() => showConfirmModal(false)}
          actionText="Вы уверены, что хотите удалить этот гостевой доступ?"
          confirmButtonText="Удалить"
        />
      )}
    </>
  );
};

export default GuestModalCurrent;
