import {useState, useContext, useEffect, useRef, useMemo} from 'react';
import {useForm} from 'react-hook-form';

import {string, object} from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {formatDateWithDots} from '@shared/components/CalendarForm/CalendarForm';
import {Input, Label, Modal} from "@shared/components";
import {
  CatalogFile,
  CatalogFileName,
  CatalogFolder,
  StorageList,
  StyledIcon,
} from "@components/storage/styles";
import iconFolder from "@assets/images/icons/folder.svg"
import FileIcon from "@assets/images/icons/icon-file.svg";

import ConfirmAction from '../warnings/ConfirmAction';
import SnackbarContext from '../../contexts/SnackbarContext';
import {convertBytes, useEventTriggerOnEscPress} from '../../utilize/helper-functions';
import {getStorageTree, searchStorage} from '../../redux/features/storageSlice';

const formSchema = object({title: string().required()});

// извлечь тип файла после точки, для прикрепления этого типа при отправке запроса
const extractFileExtension = (filename, extensionRef) => {
  const splitName = filename.split('.');
  if (splitName.length > 1) {
    extensionRef.current = splitName.pop();
    return splitName.join('.');
  } else return filename;
};

const RenameModal = ({data, type, close, storageId, renderUserName}) => {
  const extensionRef = useRef('');

  const {
    register,
    handleSubmit,
    // watch,
    formState: {isDirty, isValid},
    setFocus,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      title: type === 'folder' ? data.title : extractFileExtension(data.title, extensionRef),
    },
  });

  const [confirmClose, setConfirmClose] = useState();

  const handleClose = () => {
    if (isDirty) return setConfirmClose(true);
    close();
  };

  useEventTriggerOnEscPress(handleClose);

  const {showSnackbar} = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState();

  const dispatch = useDispatch();

  // сфокусировать на текстовом поле
  useEffect(() => {
    setFocus('title');
  }, [setFocus]);

  const searchExpression = useSelector((state) => state.storage.searchExpression);

  const submitName = async (state) => {
    setIsSubmitting(true);
    let title = state.title;
    if (type === 'file') title += '.' + extensionRef.current;
    axios
      .put('/api/rename_storage_content', {
        title: title,
        [`${type}_id`]: data.id,
      })
      .then(() => {
        dispatch(getStorageTree({storageId, showSnackbar}));
        if (searchExpression) {
          dispatch(searchStorage({storage_id: storageId, search_expression: searchExpression}));
        }
        close();
      })
      .catch((e) => {
        if (!e.response) showSnackbar('Ошибка сервера');
        if (e.response.status === 409) {
          showSnackbar(`${type === 'folder' ? 'Папка' : 'Файл'} с таким названием уже существует`);
        } else {
          showSnackbar('Возникла ошибка при переименовании');
        }
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal
        title={`Переименовать ${type === 'folder' ? 'папку' : 'файл'}`}
        onClose={handleClose}
        onSave={handleSubmit(submitName)}
        disabledSaveButton={!isValid || !isDirty || isSubmitting}
        confirmButtonText="Сохранить"
      >
        {data && (
          <StorageList>
            {type === 'folder' && (
              <CatalogFolder icon={iconFolder}>
                <CatalogFileName>{data.title}</CatalogFileName>
                <p>{convertBytes(data.size)}</p>
                <p>{formatDateWithDots(data.date_created)}</p>
                {renderUserName(data.creator_id)}
              </CatalogFolder>
            )}
            {type === 'file' && (
              <CatalogFile>
                <StyledIcon $fileUrl={FileIcon} />
                <CatalogFileName>{data.title}</CatalogFileName>
                <p>{convertBytes(data.size)}</p>
                <p>{formatDateWithDots(data.date_created)}</p>
                {renderUserName(data.creator_id)}
              </CatalogFile>
            )}
          </StorageList>
        )}
        <div>
          <Label htmlFor="folderTitle">
            {`Название ${type === 'folder' ? 'папки' : 'файла'}`}
          </Label>
          <Input id="folderTitle" register={register('title')}/>
        </div>
      </Modal>
      {confirmClose && (
        <ConfirmAction
          confirm={close}
          cancel={() => setConfirmClose(false)}
          actionText="Вы уверены что хотите закрыть форму, не сохранив изменения?"
        />
      )}
    </>
  );
};

export default RenameModal;
