import React, { useEffect, useState } from 'react';

import HTMLReactParser from 'html-react-parser';

import { useReadReminderMutation } from 'src/redux/features/api/reminders';

import { SetReminderLaterModal } from '@components/Reminders/SetReminderLaterModal';

import styled from 'styled-components';

import { Link } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';

import { useDispatch } from 'react-redux';

import { deleteReminder } from 'src/redux/features/remindersSlice';

import { Container } from '../Container';
import { Text } from '../Text/Text';
import { Button } from '../Button/Button';

import { StyledText } from './styles';

export const Reminder = ({ reminder, onClose }) => {
  const [readReminder] = useReadReminderMutation();
  const [modalVisible, setModalVisible] = useState(false);

  const auth = useAuth();
  const dispatch = useDispatch();

  const getMessageLink = (reminder) => {
    if (reminder?.chat?.task_id === null) {
      return `/projects/${reminder.root_project}?msg=${reminder?.data_id}`;
    } else {
      return `/projects/${reminder.root_project}/tasks/${reminder?.chat?.task_id}?msg=${reminder?.data_id}`;
    }
  };

  useEffect(() => {
    if (!auth.user) {
      onClose();
      dispatch(deleteReminder(reminder));
    }
  }, [auth]);

  return (
    <>
      <Container direction={'column'} gap={'8px'}>
        <Text size={2}>
          <b>Напоминание</b>
        </Text>
        <Container direction={'column'} gap={'4px'}>
          <StyledText>{HTMLReactParser(reminder.description)}</StyledText>
          {reminder?.message && (
            <Text size={1}>
              <b>Сообщение:</b>{' '}
              <StyledLink to={getMessageLink(reminder)} onClick={(e) => e.stopPropagation()}>
                {HTMLReactParser(reminder.message)}
              </StyledLink>
            </Text>
          )}
          {reminder?.project && (
            <Text>
              Проект:{' '}
              <StyledLink to={`/projects/${reminder.data_id}`} onClick={(e) => e.stopPropagation()}>
                {reminder.project}
              </StyledLink>
            </Text>
          )}
          {reminder?.task && (
            <Text>
              Задача:{' '}
              <StyledLink
                to={`/projects/${reminder.root_project}/tasks/${reminder.data_id}`}
                onClick={(e) => e.stopPropagation()}
              >
                {reminder.task}
              </StyledLink>
            </Text>
          )}
        </Container>
        <Container justify={'space-between'}>
          <Button
            color="green"
            size="sm"
            onClick={() => {
              readReminder({ reminder_id: reminder.id }).then(() => onClose());
            }}
          >
            Отметить прочтенным
          </Button>
          <Button
            color="outlined"
            size="sm"
            onClick={() => {
              setModalVisible(true);
            }}
          >
            Напомнить позже
          </Button>
        </Container>
      </Container>

      {modalVisible && (
          <SetReminderLaterModal
            reminder={reminder}
            onCloseReminder={onClose}
            onClose={() => {
              setModalVisible(false);
            }}
          />
      )}
    </>
  );
};

export const StyledLink = styled(Link)`
  color: #1890ff;
  text-decoration: underline;
`;
