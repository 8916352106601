import styled, { keyframes, css } from 'styled-components';
import { IconButton } from '@shared/components';

const rotation = keyframes`
  to { transform: translateY(-50%) rotate(360deg); }
`;

const rotatingAnimation = css`
  animation: ${rotation} 0.8s linear infinite;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -26px;

  ${({ $active }) => $active && rotatingAnimation};
`;
