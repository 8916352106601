import React, { useContext } from 'react';

import { Modal } from '@shared/components/Modal/Modal';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import DateInput from '@shared/components/DateInput/DateInput';

import useAuth from 'src/hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SnackbarContext from 'src/contexts/SnackbarContext';
import * as yup from 'yup';
import axios from 'axios';
import { server_url } from 'src/settings/base-url';
import { useDispatch, useSelector } from 'react-redux';
import { getUnavailableUsers } from 'src/redux/features/usersSlice';
import moment from 'moment';

export const UnavailableModal = ({ onClose }) => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const { showSnackbar } = useContext(SnackbarContext);

  const userUnavailable = useSelector((state) =>
    state.users.unavailableEmployees?.findLast((employee) => employee.employee_id === auth.user.id),
  );

  const schema = yup.object({
    dateStart: yup.date().notRequired(),
    dateEnd: yup.date().notRequired(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      dateStart: moment(new Date()).isBefore(userUnavailable?.date_to) ? new Date(userUnavailable?.date_from) : null,
      dateEnd:
        (userUnavailable && !moment(new Date()).isBefore(userUnavailable?.date_to)) || !userUnavailable?.date_to
          ? null
          : new Date(userUnavailable?.date_to),
    },
  });

  const editUnavailableStatus = async (data, id) => {
    try {
      await axios.put(`${server_url}/api/employee_unavailable/edit`, {
        row: {
          date_from: data.dateStart ? data.dateStart : new Date(),
          date_to: data.dateEnd ? data.dateEnd : null,
        },
        employee_unavailable_id: id,
      });
      dispatch(getUnavailableUsers());
    } catch (error) {
      showSnackbar('Возникла ошибка при отправке запроса');
    }
  };

  const addUnavailableStatus = async (data) => {
    try {
      const userStatus = await axios.get(`${server_url}/api/employee_unavailable_by_employee`).then((res) => res.data);

      if (!!userStatus.employeeUnavailable.length) {
        editUnavailableStatus(data, userStatus.employeeUnavailable[userStatus.employeeUnavailable.length - 1].id);

        showSnackbar('Данные успешно обновлены', 'success');

        onClose();
        return;
      }

      await axios.post(`${server_url}/api/employee_unavailable/add`, {
        row: {
          date_from: data.dateStart ? data.dateStart : new Date(),
          date_to: data.dateEnd ? data.dateEnd : null,
        },
      });

      dispatch(getUnavailableUsers());

      showSnackbar('Данные успешно обновлены', 'success');

      onClose();
    } catch (error) {
      showSnackbar('Возникла ошибка при отправке запроса');
    }
  };

  const onSubmit = (data) => {
    addUnavailableStatus(data);
  };

  return (
    <>
      <Modal
        type="submit"
        modalSize="767px"
        title="Буду недоступен"
        confirmButtonText={'Сохранить'}
        onSave={handleSubmit(onSubmit)}
        onClose={onClose}
        disabledSaveButton={isValid === false}
      >
        <form>
          <div>
            <StyledModalRowEnd>
              <Controller
                name="dateStart"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    placeholder="Дата начала"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    timeIntervals={15}
                    timeCaption="Время"
                    timeFormat="p"
                    dateFormat="Pp"
                    showTimeSelect
                    maxDate={watch('dateEnd')}
                  />
                )}
              />

              <Controller
                name="dateEnd"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    placeholder="Дата окончания"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    timeIntervals={15}
                    timeCaption="Время"
                    timeFormat="p"
                    dateFormat="Pp"
                    showTimeSelect
                    minDate={watch('dateStart')}
                  />
                )}
              />
            </StyledModalRowEnd>
          </div>
        </form>
      </Modal>
    </>
  );
};
