import styled from 'styled-components';

export const StyledPollChart = styled.svg`
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: 45px;
  flex: 0 0 45px;
  grid-row: 1 / 3;
  grid-column: 1 / 2;

  @media (min-width: 576px) {
    grid-column: auto;
    grid-row: auto;
  }
`;

export const StyledPollChartBg = styled.path`
  fill: none;
  stroke: #d9e1e9;
  stroke-width: 3;
`;

export const StyledPollChartProgress = styled.path`
  fill: none;
  stroke: #1890ff;
  stroke-width: 3;
  -webkit-animation: progress 1s ease-out forwards;
  animation: progress 1s ease-out forwards;

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
`;
