import React from 'react';

import { useTranslation } from 'react-i18next';

import { Subtitle } from '@shared/components/Subtitle/Subtitle';
import { StyledModalFlex } from '@shared/components/Modal/style';

import ResultItem from './ResultItem';
import ResultTextareaBlock from './ResultTextareaBlock';

const ProjectFormResult = ({ setResult, results, initEditableResult, editedItemResult, deleteResult }) => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledModalFlex>
        <Subtitle>{t('common.result')}</Subtitle>
      </StyledModalFlex>

      <ol>
        {results.length > 0 &&
          results.map((res, ind) => {
            if (res.toDelete) return null;
            return (
              <ResultItem
                key={ind}
                item={res}
                ind={ind}
                initEditableResult={initEditableResult}
                deleteResult={deleteResult}
              />
            );
          })}
        {/*  */}
      </ol>
      {/* поле ввода для добавления результата */}
      <ResultTextareaBlock editedItemResult={editedItemResult} results={results} setResult={setResult} />
    </div>
  );
};

export default ProjectFormResult;
