import { StyledInput } from './style';

export const Input = ({ register, error, type = 'text', value, placeholder, onChange, ...props }) => {
  const onChangeHandler = (evt) => {
    onChange(evt);
  };

  return (
    <StyledInput
      $error={error}
      type={type}
      value={value}
      onChange={onChangeHandler}
      placeholder={placeholder}
      {...register}
      {...props}
    />
  );
};
