import styled, { css } from 'styled-components';

export const StyledSnackbar = styled.section`
  font-family: Montserrat;
  hyphens: none;
  color: #262626;
  padding: 12px 16px 12px 56px;
  border-radius: 4px;
  z-index: 103;
  background-color: ${({ theme }) => theme.errorLightest};
  border: 2px solid ${({ theme }) => theme.errorDark};

  &::before {
    background-image: url(${(props) => props.icon});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 20px;
    left: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
  }

  ${({ type, theme }) =>
    type === 'success' &&
    css`
      background-color: ${theme.successLight};
      border: none;
      border-bottom: 2px solid ${theme.success};
    `}

  ${({ type, theme }) =>
          type === 'warning' &&
          css`
            background-color: ${theme.warningLight};
            border: none;
            border-bottom: 2px solid ${theme.warningSecondary};
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
    `}
`;