import { downloadFile } from '@shared/utils/downloadFile';
import axios from 'axios';
import useAuth from 'src/hooks/useAuth';
import { getFileSizeUrl } from 'src/settings/base-url';

export const getFileSize = (url) => axios.get(`${getFileSizeUrl}${encodeURIComponent(url)}`);

export const useDownloader = () => {
  const { token } = useAuth();
  const limit = 50;

  const getMegabytes = (size) => Number(size / 1024 / 1024).toFixed(2);

  const onDownloadFileHandler = async ({ url, filename, forceDownload }) => {
    let size;
    let isLarge;

    // Если необходимо загрузить файл без проверки его размера
    if (forceDownload) {
      downloadFile({ url: url, filename: filename, token });
      return;
    }

    await getFileSize(url).then(({ data }) => {
      size = getMegabytes(data.size);
      isLarge = size >= limit;
    });

    // Если файл меньше 50мб, то происходит его загрузка
    if (!isLarge) {
      downloadFile({ url: url, filename: filename, token });
    }

    // Возвращается информация о размере файла
    return { fileDetails: { size, isLarge } };
  };

  return { downloadFile: onDownloadFileHandler };
};
