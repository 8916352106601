import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAsideSubtitle } from '@components/chat/side-menu/styles';

import AsideUserCard from './AsideUserCard';

const SideMenuMembers = ({ members, showUserProfile }) => {
  const { t } = useTranslation();

  if (members && members.length) {
    return (
      <>
        <StyledAsideSubtitle>{t('common.members')}</StyledAsideSubtitle>
        {members.map((member, i) => (
          <AsideUserCard
            key={i}
            userId={member.id}
            lastName={member.last_name}
            firstName={member.first_name}
            showUserProfile={showUserProfile}
          />
        ))}
      </>
    );
  }
  return null;
};

export default memo(SideMenuMembers);
