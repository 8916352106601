import styled from 'styled-components';
import { Title } from '@shared/components';

export const StorageWrapper = styled.section`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 24px;
  
  @media (max-width: 550px) {
    padding: 0 12px;
  }
`

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding: 32px 0;
`;

export const StyledTitle = styled(Title)`
  padding: 0;
`;
