import { StyledSelect } from './style';

// стили для динамически изменяемых классов в реакт-селект
const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? '1px solid var(--main)' : 0,
    boxShadow: state.isFocused ? 'var(--shadow-input)' : 'none',
    borderRadius: '2px',
  }),
  option: (base, state) => ({
    ...base,
    opacity: state.data === state.selectProps.value ? '0.5' : '1',
    pointerEvents: state.data === state.selectProps.value ? 'none' : 'all',
    // backgroundColor: state.isFocused ? 'var(--main-light)' : '',
    color: 'var(--darkest)',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: 0,
  }),
};

export const SingleSelect = ({
  // ref,
  options,
  formatOptionLabel,
  placeholder = 'Выбрать из списка',
  onChange,
  defaultValue,
  filterOption,
  getOptionValue,
  width,
  isClearable,
  components,
  isDisabled,
  ...props
}) => {
  return (
    <StyledSelect
      {...props}
      // ref={ref}
      styles={style}
      formatOptionLabel={formatOptionLabel}
      components={components}
      options={options}
      getOptionValue={getOptionValue}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      filterOption={filterOption}
      hideSelectedOptions
      noOptionsMessage={() => 'Ничего не найдено'}
      width={width}
      isClearable={isClearable}
      isDisabled={isDisabled}
    />
  );
};

export const MultipleSelect = ({
  options,
  components,
  formatOptionLabel,
  placeholder = 'Выбрать из списка',
  onChange,
  defaultValue,
  filterOption,
  width,
  isClearable,
  ...props
}) => {
  return (
    <StyledSelect
      {...props}
      styles={style}
      formatOptionLabel={formatOptionLabel}
      options={options}
      components={components}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      filterOption={filterOption}
      hideSelectedOptions
      noOptionsMessage={() => 'Ничего не найдено'}
      width={width}
      isClearable={isClearable}
      isMulti
    />
  );
};
