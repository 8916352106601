import styled from 'styled-components';

export const DndWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #333;
  background-color: ${({ isDrag }) => (isDrag ? '#f5f5f5' : '#fafafa')};
  width: 100%;
  height: 100px;
  cursor: pointer;

  input {
    display: none;
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 12px;
  gap: 8px;
`;
