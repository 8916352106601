import { Label } from '@shared/components';
import { Radio } from '@shared/components/Radio/Radio';
import React, { useEffect } from 'react';

import { useController } from 'react-hook-form';

import { radioButtonsType } from '../../lib';

export const AccessRadioButtons = ({
  control,
  selectedRadio,
  setSelectedRadio,
  onReset,
  isPublic,
  departments,
  employees,
}) => {
  const { field } = useController({ name: 'access', control });

  const radioOptions = [
    {
      id: 1,
      label: 'Всем',
      name: 'all',
      value: radioButtonsType.all,
      checked: selectedRadio === radioButtonsType.all,
      onChange: () => {
        onReset();
        field.onChange(() => setSelectedRadio(radioButtonsType.all));
      },
    },
    {
      id: 2,
      label: 'По отделам',
      name: 'departments',
      value: radioButtonsType.departments,
      checked: selectedRadio === radioButtonsType.departments,
      onChange: () => {
        onReset();
        field.onChange(() => setSelectedRadio(radioButtonsType.departments));
      },
    },
    {
      id: 3,
      label: 'По пользователям',
      name: 'users',
      value: radioButtonsType.users,
      checked: selectedRadio === radioButtonsType.users,
      onChange: () => {
        onReset();
        field.onChange(() => setSelectedRadio(radioButtonsType.users));
      },
    },
  ];

  useEffect(() => {
    if (!isPublic && departments?.length === 0 && employees?.length === 0) {
      setSelectedRadio(radioButtonsType.departments);
      return;
    }

    if (!!departments?.length) {
      setSelectedRadio(radioButtonsType.departments);
    } else if (!!employees?.length) {
      setSelectedRadio(radioButtonsType.users);
    }
  }, [departments, employees, isPublic]);

  return (
    <div>
      <Label>Доступность</Label>
      <Radio items={radioOptions} />
    </div>
  );
};
