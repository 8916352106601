import React, { useContext, useState } from 'react';
import ConfirmAction from '@components/warnings/ConfirmAction';
import { IconButton } from '@shared/components/IconButton/IconButton';
import iconEdit from '@assets/images/icons/edit.svg';
import iconDelete from '@assets/images/icons/delete.svg';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { useDeleteCompanyGroupMutation } from 'src/redux/features/api/contacts';

import {
  StyledModalGridButtons,
  StyledModalGridCell,
  StyledModalGridLabel,
  StyledModalGridRow,
  StyledSeparator,
} from '../../departments/department-list/styles';

// компонент списка групп контактов
const GroupItem = ({ item, onEdit, hasRightToEdit }) => {
  const { title } = item;

  const [confirmDeleteModal, showConfirmDeleteModal] = useState();
  const [deleteGroup, { isLoading }] = useDeleteCompanyGroupMutation();

  const { showSnackbar } = useContext(SnackbarContext);

  const handleDelete = async () => {
    const result = await deleteGroup({
      company_group_id: item.id,
    });
    if (result.error) showSnackbar('Возникла ошибка при удалении группы');
    else showConfirmDeleteModal(false);
  };

  return (
    <>
      <StyledModalGridRow>
        <StyledModalGridCell>
          <StyledModalGridLabel>Группа контактов</StyledModalGridLabel>
          <p>{title}</p>
        </StyledModalGridCell>

        <StyledModalGridButtons>
          {hasRightToEdit && (
            <>
              <IconButton size={18} icon={iconEdit} onClick={() => onEdit(item)} />
              <IconButton size={18} icon={iconDelete} onClick={() => showConfirmDeleteModal(true)} />
            </>
          )}
        </StyledModalGridButtons>
      </StyledModalGridRow>
      <StyledSeparator />

      {confirmDeleteModal && (
        <ConfirmAction
          actionText={'Вы уверены что хотите удалить данную группу контактов?'}
          cancel={() => showConfirmDeleteModal(false)}
          confirm={handleDelete}
          isSubmitting={isLoading}
          confirmButtonText="Удалить"
        />
      )}
    </>
  );
};

export default GroupItem;
