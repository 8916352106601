import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@shared/components/IconButton/IconButton';
import iconEdit from '@assets/images/icons/edit.svg';
import iconDelete from '@assets/images/icons/delete.svg';

import { StyledResultControlBox, StyledResultItem, StyledResultLink, StyledResultTitle } from '../styles';

const ResultItem = ({ item, ind, initEditableResult, deleteResult }) => {
  const { t } = useTranslation();

  return (
    <StyledResultItem>
      <StyledResultTitle>
        {ind + 1}. {item.description}
      </StyledResultTitle>
      <StyledResultLink>
        {item.type === 'file' && t('common.file')}
        {item.type === 'text' && 'Текст'}
      </StyledResultLink>
      <StyledResultControlBox>
        <IconButton size={18} icon={iconEdit} onClick={() => initEditableResult(item)} />
        <IconButton size={18} icon={iconDelete} onClick={() => deleteResult(item)} />
      </StyledResultControlBox>
    </StyledResultItem>
  );
};

export default ResultItem;
