import {Avatar} from '@shared/components/Avatar';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSocketContext} from 'src/contexts/SocketContext';
import useAuth from 'src/hooks/useAuth';
import {employeesApi, useGetSalariesListQuery} from 'src/redux/features/api/employees';

import {Placeholder} from '@shared/components/Placeholder';

import {
  Salary,
  StyledStickyCell,
  StyledTable, StyledTableCol, StyledTableDescription, StyledTableFooter,
  StyledTableHeader, StyledTablePosition, StyledTableUsername,
  StyledTableWrapper,
  StyledTitle,
  StyledUserWrapper
} from './styles';

const PageSalaries = () => {
  const {data, isLoading} = useGetSalariesListQuery();

  const auth = useAuth();
  const {socket} = useSocketContext();
  const dispatch = useDispatch();

  const {isUserRightful, rightTypes} = useAuth();

  useEffect(() => {
    if (socket) {
      socket.emit('add_to_room', 'employee_salaries');

      socket.on('department_changed', () => {
        dispatch(employeesApi.util.invalidateTags(['Employees']));
      });

      socket.on('employee_changed', () => {
        dispatch(employeesApi.util.invalidateTags(['Employees']));
      });
    }

    return () => {
      if (socket) socket.emit('leave_room', 'employee_salaries');
    };
  }, [auth.token, socket]);

  return (
    <Salary>
      {!isUserRightful(rightTypes.salary_view) ? (
        <Placeholder text={'У вас нет прав для просмотра данной страницы'}/>
      ) : (<StyledTableWrapper>
          {!isLoading &&
            data?.employee_salaries.map((item) => (
              <StyledTable>
                <thead>
                <tr>
                  <StyledStickyCell>
                    <StyledTitle size={4}>{item.title}</StyledTitle>
                  </StyledStickyCell>
                </tr>
                <StyledTableHeader>
                  <StyledTableCol>Кто</StyledTableCol>
                  <StyledTableCol>Должность</StyledTableCol>
                  <StyledTableCol>Руб/месяц</StyledTableCol>
                </StyledTableHeader>
                </thead>
                <tbody>
                {item.employee_salaries.map((emp) => (
                  <tr>
                    <StyledTableCol>
                      <StyledUserWrapper>
                        <Avatar userId={emp.employee_id} style={{alignSelf: "center"}}/>
                        <StyledTableUsername>
                          {emp.last_name} {emp.first_name}
                        </StyledTableUsername>
                      </StyledUserWrapper>
                    </StyledTableCol>
                    <StyledTableCol>
                      <StyledTableDescription>Должность</StyledTableDescription>
                      {emp.position && <StyledTablePosition>{emp.position}</StyledTablePosition>}
                    </StyledTableCol>
                    <StyledTableCol>
                      <StyledTableDescription>Ставка руб/мес</StyledTableDescription>
                      <span>{emp.salary}</span>
                    </StyledTableCol>
                  </tr>
                ))}
                </tbody>
                {!!item.employee_salaries.length && (
                  <StyledTableFooter>
                    <tr>
                      <StyledTableCol>Итого:</StyledTableCol>
                      <StyledTableCol></StyledTableCol>
                      <StyledTableCol>
                        {item.employee_salaries.reduce((acc, current) => (acc += current.salary), 0)}
                      </StyledTableCol>
                    </tr>
                  </StyledTableFooter>
                )}
              </StyledTable>
            ))}
        </StyledTableWrapper>
      )}
    </Salary>
  );
};

export default PageSalaries;
