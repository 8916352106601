import { object, func, number } from 'prop-types';
import { useFormState } from 'react-hook-form';
import { Input, Label, Text } from '@shared/components';
import { colors } from 'src/variables';

LinkFieldArrayItem.propTypes = {
  infoField: object,
  register: func,
  index: number,
  control: object,
};

function LinkFieldArrayItem({ infoField, register, index, control }) {
  const {
    errors: { partner_company_infos },
  } = useFormState({ control, name: `partner_company_infos.${index}.value` });

  return (
    <div key={infoField.id}>
      <Label>{infoField.name}</Label>
      <Input
        error={Boolean(partner_company_infos && partner_company_infos[index])}
        register={register(`partner_company_infos.${index}.value`)}
      />
      {partner_company_infos && (
        <Text size={1} color={colors.error}>
          {partner_company_infos[index]?.value?.message}
        </Text>
      )}
    </div>
  );
}

export default LinkFieldArrayItem;
