import React, {useEffect, useContext, useCallback} from 'react';
import axios from 'axios';
import {useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import SnackbarContext from 'src/contexts/SnackbarContext';
import {Search} from "@shared/components";

import Preloader from '../../../components/preloaders/Preloader';

import GuestFullProfileModal from '../../../windows/profile/GuestFullProfileModal';
import {StyledTeamList} from "../TeamMain/TeamList/style";
import {TeamFilterCol, TeamFilter, Team} from "../TeamMain/style";

import GuestListItem from './GuestListItem';


const GuestList = () => {
  const {guest_id} = useParams();
  const [guestList, setGuestList] = useState(null);
  const [filteredGuestList, setFilteredGuestList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const {showSnackbar} = useContext(SnackbarContext);

  const getGuestList = useCallback(() => {
    axios
      .get('/api/guests_list/')
      .then((r) => {
        setGuestList(r.data.guests);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при получении списка гостевых доступов');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getGuestList();
  }, []);

  const searchGuests = (e) => {
    if (e.target.value) {
      return setFilteredGuestList(
        guestList.filter((guest) => guest.first_name.toLowerCase().includes(e.target.value.toLowerCase())),
      );
    }
    return setFilteredGuestList(null);
  };

  const navigate = useNavigate();

  return (
    <>
      {guest_id && <GuestFullProfileModal close={() => navigate('/team/guests/')} getGuestList={getGuestList}/>}

        <Team>
            <TeamFilter>
              <TeamFilterCol>
                <Search
                  placeholder="Поиск по гостям"
                  onChange={searchGuests}
                />
              </TeamFilterCol>
            </TeamFilter>
            {isLoading && <Preloader/>}

            {Array.isArray(filteredGuestList) &&
              filteredGuestList.map((guest, i) => <GuestListItem guest={guest} key={i}/>)}

            {!filteredGuestList && Array.isArray(guestList) && (
              <StyledTeamList>
                {guestList.map((guest, i) => (
                  <GuestListItem guest={guest} key={i}/>
                ))}
              </StyledTeamList>
            )}
        </Team>
    </>
  );
};

export default GuestList;
