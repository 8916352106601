import { styled } from 'styled-components';

export const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 20px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const FilterButton = styled.div`
  border: 1px solid transparent;
  border-radius: 2px;
  padding: 0 8px;
  width: auto;
  font-size: 12px;
  line-height: 20px;
  color: ${({ $active, theme }) => ($active ? theme.main : theme.darkest)};
  border-color: ${({ active, theme }) => (active ? theme.main : 'transparent')};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #1890ff;
  }
`;
