// import NotificationsTypeSelect from '@components/notifications/NotificationsTypeSelect';
import { styled } from 'styled-components';

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledSubheader = styled.div`
  margin: 0 -24px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.light};

  @media (max-width: 600px) {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FlexItem = styled.div`
  flex: 1;
  max-width: 295px;
`;
