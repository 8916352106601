import arrowIcon from '@assets/images/icons/arrow-black.svg';
import { useCallback, useRef, useState } from 'react';

import { Avatar } from '@shared/components/Avatar';
import { Container } from '@shared/components';
import { useOutsideTrigger } from 'src/utilize/helper-functions';

import { StyledIconButton, StyledList, StyledListContainer } from './styles';

export function VotersListPopup({ members }) {
  const [listOpen, setListOpen] = useState(false);

  const listRef = useRef();
  const buttonRef = useRef();
  const refsArray = useRef([listRef, buttonRef]);

  const closeList = useCallback(() => setListOpen(false), []);

  useOutsideTrigger(refsArray.current, closeList, listOpen);

  return (
    <StyledListContainer ref={buttonRef}>
      <StyledIconButton icon={arrowIcon} $open={listOpen} onClick={() => setListOpen((prev) => !prev)} />
      <StyledList $open={listOpen} ref={listRef}>
        {members.map((member) => (
          <Container ref={listRef} align="center" key={member.id}>
            <Avatar userId={member.id} showProfileOnClick={false} />
            <span>{`${member.first_name} ${member.last_name || ''}`}</span>
          </Container>
        ))}
      </StyledList>
    </StyledListContainer>
  );
}
