import { StyledMockAvatar } from '@shared/components/Avatar/styles';
import { files_url } from 'src/settings/base-url';
import { components } from 'react-select';

import { Avatar } from '@shared/components/Avatar';

import { Wrapper } from './styles';

export const getOptions = ({ users, token }) => {
  if (!users) return [];
  const options = users.map((user) => ({
    label: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    value: {
      id: user.id,
      name: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
      pic: !!user.avatar
        ? `${files_url}/employees/avatar/${user.id}/small_${user.avatar}?token=${token}`
        : StyledMockAvatar,
    },
  }));

  return options;
};

export const CustomValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <Wrapper align={'center'}>
        {props.data.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.SingleValue>
  );
};

export const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Wrapper align={'center'}>
        {props.data?.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.Option>
  );
};
