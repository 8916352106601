import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SnackbarContext from 'src/contexts/SnackbarContext';
import Preloader from '@components/preloaders/Preloader';
import { useGetSubscriptionsQuery } from 'src/redux/features/api/notifications';
import { Title, StyledLayout, StyledLink } from '@shared/components';

import { StyledFavItem, StyledFavList, LinksWrapper, StyledTitle } from './styles';
import ManageSubscription from './ManageSubscription';

const Subscriptions = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  const { data: subscriptions, isFetching, isError } = useGetSubscriptionsQuery();

  useEffect(() => {
    if (isError) showSnackbar('Возникла ошибка при получении подписок');
  }, [isError]);

  return (
    <StyledLayout>
      <Title>Подписка на уведомления</Title>

      {isFetching && <Preloader />}

      <StyledFavList>
        {subscriptions?.map((subscription) => {
          let url = '';
          let title = '';
          if (subscription.data_type === 'project') {
            url = `/projects/${subscription.project.id}`;
            title = subscription.project.title;
          } else if (subscription.data_type === 'task') {
            url = `/projects/${subscription.task.root_project_id}/tasks/${subscription.task.id}`;
            title = subscription.task.title;
          }

          return (
            <StyledFavItem key={subscription.id}>
              <LinksWrapper>
                <StyledTitle>
                  <StyledLink>
                    <Link to={url}>{title}</Link>
                  </StyledLink>
                </StyledTitle>
                <StyledLink>
                  <Link to={url}>{title}</Link>
                </StyledLink>
              </LinksWrapper>
              <ManageSubscription subscription={subscription} />
            </StyledFavItem>
          );
        })}
      </StyledFavList>
    </StyledLayout>
  );
};

export default Subscriptions;
