import { rtkQueryApi } from './rtkQueryApi';

export const remindersApi = rtkQueryApi.injectEndpoints({
  tagTypes: ['Reminders'],
  endpoints: (builder) => ({
    getReminders: builder.query({
      query: () => '/api/reminders_list',
      providesTags: ['Reminders'],
    }),
    getReminderModalData: builder.query({
      query: () => '/api/reminder_create',
      providesTags: ['Reminders'],
    }),
    getReminderById: builder.query({
      query: ({ id }) => `/api/reminder_edit/${id}`,
      providesTags: ['Reminders'],
    }),
    getReminderProjectTree: builder.query({
      query: ({ id, type }) => `/api/sidebar_project_tree/${type}/${id}`,
      providesTags: ['Reminders'],
    }),
    addReminder: builder.mutation({
      query: (body) => ({
        url: '/api/reminders/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Reminders'],
    }),
    editReminder: builder.mutation({
      query: (body) => ({
        url: '/api/reminders/edit',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Reminders'],
    }),
    deleteReminder: builder.mutation({
      query: (body) => ({
        url: '/api/reminders/delete',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Reminders'],
    }),
    readReminder: builder.mutation({
      query: (body) => ({
        url: '/api/set_as_read_remind',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Reminders'],
    }),
    setReminderLater: builder.mutation({
      query: (body) => ({
        url: '/api/remind_later',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Reminders'],
    }),
  }),
});

export const {
  useGetRemindersQuery,
  useAddReminderMutation,
  useDeleteReminderMutation,
  useGetReminderModalDataQuery,
  useGetReminderByIdQuery,
  useEditReminderMutation,
  useReadReminderMutation,
  useSetReminderLaterMutation,
  useGetReminderProjectTreeQuery,
} = remindersApi;
