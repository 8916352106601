import { StyledMockAvatar } from '@shared/components/Avatar/styles';
import { files_url } from 'src/settings/base-url';
import * as yup from 'yup';

export const radioButtonsType = {
  all: 'all',
  departments: 'departments',
  users: 'users',
};

export const linkTypes = {
  in: {
    title: 'Внутрення',
    value: 'in',
  },
  out: {
    title: 'Внешняя',
    value: 'out',
  },
  file: {
    title: 'Файл',
    value: 'file',
  },
};

const URL =
  /^((http|https):\/\/|www\.)[a-zA-Z0-9_\-]+(\.[a-zA-Z]+)+((\/)[\w#%]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const schema = yup.object({
  title: yup.string().required('Введите название ссылки'),
  type: yup.object().required('Выберите тип ссылки'),
  url: yup.string().when('type', {
    is: (type) => type.value === linkTypes.file.value,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.matches(URL, 'Введена некорректная ссылка').required('Введите значение'),
  }),
  employees: yup.array(),
  departments: yup.array(),
  hasFile: yup.boolean(),
  access: yup.string(),
  files: yup.mixed(),
});

export const getDepartmentOptions = (departments) => {
  if (!departments) return [];
  const options = departments.map((department) => ({
    label: `${department?.title}`,
    value: department.id,
  }));

  return options;
};

export const getUserOptions = (users, token) => {
  if (!users) return [];
  const options = users.map((user) => ({
    label: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    value: {
      id: user.id,
      name: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
      pic: !!user.avatar
        ? `${files_url}/employees/avatar/${user.id}/small_${user.avatar}?token=${token}`
        : StyledMockAvatar,
    },
  }));

  return options;
};
