import { object, func, number, shape, string, bool, objectOf, arrayOf } from 'prop-types';
import { Radio } from '@shared/components';

import { StyledPollVoteButton } from './styles';
import { CustomRadioLabel } from './CustomRadioLabel';
import { MultiAnswer } from './MultiAnswer/MultiAnswer';

PollOptions.propTypes = {
  pollData: shape({
    title: string,
    id: number,
    anonymous: number,
    multianswer: number,
    date_created: string,
    date_finish: string,
    chat_message_id: number,
    isMember: bool,
    poll_results: shape({
      answers: objectOf(
        shape({
          id: number,
          percentage_ratio: number,
          vote_count: number,
        }),
      ),
      waiting_vote: arrayOf(
        shape({
          id: number,
          avatar: string,
          first_name: string,
          last_name: string,
        }),
      ),
      poll_member_count: number,
      user_status: string,
    }),
  }),
  control: object,
  getValues: func,
  votedCount: number,
  submitAnswer: func,
  options: arrayOf(
    shape({
      id: number,
      percentage_ratio: number,
      vote_count: number,
      label: string,
    }),
  ),
};

export function PollOptions({ pollData, options, control, getValues, submitAnswer }) {
  return (
    <>
      {pollData.multianswer ? (
        <MultiAnswer options={options} control={control} />
      ) : (
        <Radio items={options} direction="column" CustomLabel={CustomRadioLabel} />
      )}

      {Boolean(pollData.multianswer) && (
        <StyledPollVoteButton
          size="sm"
          type="button"
          onClick={() => {
            const values = getValues();
            submitAnswer(values.answers);
          }}
        >
          Голосовать
        </StyledPollVoteButton>
      )}
    </>
  );
}
