import React from 'react';

const CheckEmailNotification = () => {
  return (
    <div>
      <p>Проверьте почту, там новый пароль</p>
    </div>
  );
};

export default CheckEmailNotification;
