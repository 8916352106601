import { StyledUser, StyledUserName, StyledUserPic } from './style';

// размер картинки - sm | md | xl
// размер текста от 1 до 6, в as можно прописать любой необходимый тег
export const User = (props) => {
  return (
    <StyledUser>
      <StyledUserPic size={props.size} src={props.picture} />
      <StyledUserName as="p" size={1}>
        {props.name}
      </StyledUserName>
    </StyledUser>
  );
};
