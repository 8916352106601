import axios from '../axios';

const endpoints = {
  login: (data) => axios.post('/api/login', data),
  // напомнить пароль
  restorePassword: (data) => axios.post('/api/password_restore', data),

  resetPasword: (data) => axios.put('/api/password_reset', data),
};

export default endpoints;
