import React, {useCallback, useContext, useEffect, useState} from 'react';
import {date, object} from "yup";
import axios from "axios";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import CalendarForm, {getFormatCalendarData} from "@shared/components/CalendarForm/CalendarForm";
import {useTranslation} from "react-i18next";

import {Label, Modal} from "@shared/components";
import ConfirmAction from "@components/warnings/ConfirmAction";
import Preloader from "@components/preloaders/Preloader";

import {formatDateforPicker, useEventTriggerOnEscPress} from "../../../utilize/helper-functions";
import SnackbarContext from "../../../contexts/SnackbarContext";
import useAuth from "../../../hooks/useAuth";


const formSchema = object({
  deadline_end: date().required(),
});

const ChangeDeadlineModal = ({taskIdToEdit, getTaskData, close}) => {
  const {
    control,
    handleSubmit,
    formState: {isDirty, isValid},
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const {showSnackbar} = useContext(SnackbarContext);
  const [isSubmitting, setIsSubmitting] = useState();
  const [isLoadingEditData, setIsLoadingEditData] = useState();
  const auth = useAuth();
  const {t} = useTranslation();

  useEffect(() => {
    if (taskIdToEdit) {
      setIsLoadingEditData(true);
      axios
        .get(`/api/task_edit/${taskIdToEdit}`)
        .then((r) => {
          const taskData = r.data.result;
          reset({
            deadline_end: formatDateforPicker(taskData.date_finish),
          });
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при запросе данных на редактирование');
        })
        .finally(() => setIsLoadingEditData(false));
    }
  }, []);


  const submitData = (data) => {
    if (auth) {
      const submitData = {
        date_finish: getFormatCalendarData(data.deadline_end),
        task_id: taskIdToEdit,
      };

      setIsSubmitting(true);

      if (taskIdToEdit) {
        axios
          .patch('/api/change_task_deadline', submitData)
          .then(() => {
            getTaskData();
            showSnackbar('Дедлайн изменен', 'success');
            close();
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при изменении дедлайна');
            setIsSubmitting(false);
          });
        return;
      }
    }
  };

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handleModalClose = useCallback(() => {
    if (isDirty) {
      setModalClosePrompt(true);
    } else close();
  }, [isDirty]);

  useEventTriggerOnEscPress(handleModalClose);

  return (
    <Modal
      title={'Изменить дедлайн'}
      onClose={handleModalClose}
      confirmButtonText={!isSubmitting && t('FormButtons.save')}
      disabledSaveButton={!isValid || isSubmitting}
      onSave={handleSubmit(submitData)}
    >
      {isLoadingEditData ? (
        <Preloader/>
      ) : (
        <form>
          <div>
            <Label>
              Дедлайн <sup>*</sup>
            </Label>
            <CalendarForm
              data={{
                name: 'deadline_end',
                placeholder: t('Project.end_date'),
              }}
              control={control}
              minDate={new Date()}
              //prev={projectData?.date_finish}
            />
          </div>
        </form>
      )}

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={close}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </Modal>
  );
};

export default ChangeDeadlineModal;