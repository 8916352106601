import { useMemo } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import useAuth from 'src/hooks/useAuth';
import { useGetPortalRightsListQuery } from 'src/redux/features/api/employees';
import { Modal, Text } from '@shared/components';
import Preloader from '@components/preloaders/Preloader';
import { rightsRussianLabels } from 'src/utilize/rights-russian-labels';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';

const RightLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const RightListItem = styled.li`
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
  color: ${({ granted }) => (granted ? 'unset' : '#c8c8c8')};
`;

MyRightsModal.propTypes = {
  close: func.isRequired,
};

function MyRightsModal({ close }) {
  const auth = useAuth();

  const { data, isFetching } = useGetPortalRightsListQuery();

  const rightsToRender = useMemo(() => {
    if (!Array.isArray(data?.rights) || !Array.isArray(auth?.user?.rights)) return null;
    const portalRightsList = [...data.rights].sort((a, b) => {
      return rightsRussianLabels[a]?.localeCompare(rightsRussianLabels[b]);
    });

    return (
      <ul>
        {portalRightsList.map((right) => {
          // эти два права пока нигде не используются
          if (right === 'team_search_view' || right === 'team_search_edit') return null;
          return (
            <RightListItem key={right} granted={auth.user.rights.includes(right)}>
              {rightsRussianLabels[right]}
            </RightListItem>
          );
        })}
      </ul>
    );
  }, [data]);

  useEventTriggerOnEscPress(close);

  return (
    <Modal modalSize="600px" title="Мои права" onClose={close} withoutButtons>
      <div>
        {isFetching || !Array.isArray(auth?.user?.rights) ? (
          <Preloader />
        ) : (
          <>
            {auth.user.rights.includes('leader') ? (
              <>
                <RightLabel>Лидер</RightLabel>
                <Text color="#8C8C8C" style={{ textAlign: 'justify' }}>
                  У вас есть доступ ко всем функциям портала, а также всем проектам, задачам и хранилищам, вне
                  зависимости от настройки доступов к ним
                </Text>
              </>
            ) : (
              rightsToRender
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

export default MyRightsModal;
