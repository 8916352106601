import styled from 'styled-components';

export const StyledText = styled.p`
  color: ${(props) => props.color};

  ${(props) =>
    props.size === 1 &&
    `
    font-size: 12px;
    line-height: 20px;
  `}

  ${(props) =>
    props.size === 2 &&
    `
    font-size: 14px;
    line-height: 22px;
  `}

  ${(props) =>
    props.size === 3 &&
    `
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  `}

  ${(props) =>
    props.size === 4 &&
    `
    font-size: 18px;
    line-height: 24px;
  `}

  ${(props) =>
    props.size === 5 &&
    `
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  `}

  ${(props) =>
    props.size === 6 &&
    `
    font-weight: 500;
    font-size: 38px;
    line-height: 46px;
  `}
`;
