import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false,
};

export const appVersionsSlice = createSlice({
  name: 'appVersions',
  initialState,
  reducers: {
    showModal: (state) => {
      state.visible = true;
    },
    hideModal: (state) => {
      state.visible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { hideModal, showModal } = appVersionsSlice.actions;

export default appVersionsSlice.reducer;
