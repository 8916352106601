import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';
import { Avatar } from '@shared/components/Avatar';
import {Button, Text} from "@shared/components";
import UserProfileModalContainer from 'src/windows/profile/UserProfileModalContainer';
import useAuth from 'src/hooks/useAuth';
import ConfirmAction from '@components/warnings/ConfirmAction';
import { processVacationRequest } from 'src/redux/features/usersSlice';
import {StyledVacationCard, VacationDate, VacationUser} from "@components/employees/vacations/styles";
import {Container} from "@shared/components";

const VacationCard = ({ vacationData, forApproval }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [userProfileModal, setUserProfileModal] = useState();
  const [confirmAction, setConfirmAction] = useState();

  const fullName = useMemo(() => {
    return `${vacationData.last_name || ''} ${vacationData.first_name}`;
  }, [vacationData]);

  return (
    <>
      <StyledVacationCard>
        <VacationDate size={1}>{`${formatDateWithDots(vacationData.date_from)} - ${formatDateWithDots(vacationData.date_to,)}`}</VacationDate>
        <VacationUser>
          <Avatar userId={vacationData.employee_id} size={48} showProfileOnClick />
          <div>
            <Text size={3} onClick={() => setUserProfileModal(true)}>
              {`${fullName}${vacationData.position ? ', ' + vacationData.position : ''}`}
            </Text>
            <Text size={2} color="#8C8C8C">{vacationData.department_title}</Text>
          </div>
        </VacationUser>
        {forApproval && (auth?.user?.id !== vacationData.employee_id || auth?.isUserRightful()) ? (
          <Container gap="12px" align="center" style={{marginLeft: '60px'}}>
            <Button size="sm" color="green" onClick={() => setConfirmAction('yes')}>
              Одобрить
            </Button>
            <Button size="sm" color="red" onClick={() => setConfirmAction('no')}>
              Отклонить
            </Button>
          </Container>
        ) : (
          ''
        )}
      </StyledVacationCard>

      {userProfileModal && (
        <UserProfileModalContainer profileId={vacationData.employee_id} onClose={() => setUserProfileModal(false)} />
      )}

      {confirmAction && (
        <ConfirmAction
          actionText={confirmAction === 'yes' ? 'Одобрить отпуск?' : 'Отклонить запрос на отпуск?'}
          confirm={() => dispatch(processVacationRequest({ decision: confirmAction, vacationId: vacationData.id }))}
          cancel={() => setConfirmAction(null)}
        />
      )}
    </>
  );
};

export default VacationCard;
