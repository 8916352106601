import React from 'react';
import {Label} from "@shared/components";
import {StyledRadioWrapper} from "@shared/components/Radio/style";

export const RadioItem = (props) => {
  const { prev, register, input } = props;
  const { label, name, value } = input;
  return (
    <>
      <input
        type="radio"
        name={name}
        id={label}
        value={value}
        {...register(`${name}`)}
        //  checked по умолчанию
        defaultChecked={prev === value}
      />
      <label htmlFor={label}>{label}</label>
    </>
  );
};
const RadioItems = ({ register, settings }) => {
  return (
    <>
      <Label>{settings.label}</Label>
      <StyledRadioWrapper>
        {settings.radio_btns.map((el, i) => {
          return <RadioItem register={register} key={i} input={el} prev={settings.prev} />;
        })}
      </StyledRadioWrapper>
    </>
  );
};
export default RadioItems;
