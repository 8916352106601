import { useState, useMemo, useContext, useCallback, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { func, bool, object } from 'prop-types';
import { Modal } from '@shared/components/Modal/Modal';
import { StyledModalRowCenter, StyledModalRowStart } from '@shared/components/Modal/style';
import Tag from '@shared/components/Tag/Tag';
import { Container, Subtitle, Text, ExternalLink } from '@shared/components';
import { Avatar } from '@shared/components/Avatar';
import useAuth from 'src/hooks/useAuth';
import Preloader from 'src/components/preloaders/Preloader';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { useDeleteCompanyContactMutation } from 'src/redux/features/api/contacts';
import { files_url } from 'src/settings/base-url';
import { randomSequence, useEventTriggerOnEscPress } from 'src/utilize/helper-functions';

import { FieldLabel, FieldInfo, StyledProfilePic, StyledTitle, StyledLink, FieldGrid } from './style';
import { contactTypes, formatAdditionalInfo } from './lib';

ContactDetails.propTypes = {
  onClose: func,
  isLoading: bool,
  contactDetails: object,
};

export default function ContactDetails({ onClose, isLoading, contactDetails, setModalEdit }) {
  const navigate = useNavigate();
  let location = useLocation();
  const auth = useAuth();

  // статус модалки для редактирования
  // подтверждаем удаление сотрудника
  const [confirmRemove, setConfirmRemove] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const [deleteCompany, { isLoading: isDeleting }] = useDeleteCompanyContactMutation();

  const handleContactDelete = async () => {
    const response = await deleteCompany({ partner_id: contactDetails.id });
    if ('error' in response) {
      showSnackbar('Возникла ошибка при удалении');
      setConfirmRemove(false);
    } else onClose();
  };

  const headerButtons = useMemo(() => {
    if (!auth?.isUserRightful(auth.rightTypes.contacts_edit)) return [];
    return [
      { name: 'Ред.', action: () => setModalEdit(true) },
      { name: 'Удалить', action: () => setConfirmRemove(true) },
    ];
  }, [auth]);

  // при закрытии модалки отправляем на пред страницу
  const handlerCloseModal = useCallback(() => {
    if (onClose) return onClose();
    let from = location.state?.backgroundLocation?.pathname || '/';
    navigate(from, { replace: true });
  }, [onClose, location, navigate]);

  // функция для закрытия модального окна, при нажатии на кнопку Esc
  useEventTriggerOnEscPress(handlerCloseModal);

  return (
    <Modal title="Контакт" onClose={handlerCloseModal} headerButtons={headerButtons} withoutButtons>
      {isLoading || !contactDetails ? (
        <Preloader />
      ) : (
        <>
          <StyledModalRowCenter>
            <StyledProfilePic>
              {contactDetails.image && (
                <img
                  src={`${files_url}/partners/files/${contactDetails.id}/${contactDetails.image}?token=${auth.token}`}
                  alt="profile"
                />
              )}
            </StyledProfilePic>
            <Container direction="column" justify="space-between" height="100%">
              <div>
                <StyledTitle>{contactDetails.title}</StyledTitle>
                <Container gap="0.5rem" direction="column">
                  <Text>{contactTypes[contactDetails.type]}</Text>
                  <Container wrap="wrap" gap="1rem">
                    {contactDetails.partner_groups?.map((group) => {
                      return (
                        <FieldLabel key={group.id} $fontSize="12px">
                          {group.title}
                        </FieldLabel>
                      );
                    })}
                  </Container>
                  <div>
                    {contactDetails.link && (
                      <ExternalLink
                        href={
                          contactDetails.link.indexOf('http') === 0 ? contactDetails.link : `//${contactDetails.link}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {contactDetails.link}
                      </ExternalLink>
                    )}
                  </div>
                </Container>
              </div>
              <Container gap="0.5rem" wrap="wrap">
                {contactDetails.partner_tags?.map((tag) => (
                  <Tag tag={tag} key={tag.id} />
                ))}
              </Container>
            </Container>
          </StyledModalRowCenter>

          {Boolean(contactDetails.partner_departments?.length) && (
            <FieldGrid>
              <FieldLabel>Отдел: </FieldLabel>
              <Container gap="1rem" align="center" wrap="wrap">
                {contactDetails.partner_departments.map((dept) => {
                  return <FieldInfo key={dept.id}>{dept.title}</FieldInfo>;
                })}
              </Container>
            </FieldGrid>
          )}

          {Boolean(contactDetails.partner_employees?.length) && (
            <FieldGrid>
              <FieldLabel>Работают с: </FieldLabel>
              <Container gap="0.2rem 1.5rem" align="center" wrap="wrap">
                {contactDetails.partner_employees.map((user) => {
                  return (
                    <Container key={user.id} align="center">
                      <Avatar userId={user.id} size={28} />
                      <span>{`${user.last_name || ''} ${user.first_name || ''} `}</span>
                    </Container>
                  );
                })}
              </Container>
            </FieldGrid>
          )}

          {Boolean(contactDetails.partner_projects?.length) && (
            <FieldGrid>
              <FieldLabel>Проекты: </FieldLabel>
              <Container gap="1rem" align="center" wrap="wrap">
                {contactDetails.partner_projects.map((project) => {
                  return (
                    <FieldInfo key={randomSequence()}>
                      <StyledLink to={`/projects/${project.id}`}>{project.title}</StyledLink>
                    </FieldInfo>
                  );
                })}
              </Container>
            </FieldGrid>
          )}
          {Boolean(contactDetails.partner_additional_information?.length) && (
            <>
              <Subtitle>Дополнительная информация</Subtitle>
              <StyledModalRowStart>
                {contactDetails.partner_additional_information.map((infoField) => {
                  if (
                    !infoField.include ||
                    !infoField.value ||
                    (Array.isArray(infoField.value) && !infoField.value.length)
                  ) {
                    return null;
                  }
                  return (
                    <Container key={infoField.id} direction="column" wrap="wrap">
                      <FieldLabel>{infoField.name}</FieldLabel>
                      <FieldInfo>{formatAdditionalInfo(infoField)}</FieldInfo>
                    </Container>
                  );
                })}
              </StyledModalRowStart>
            </>
          )}
          {Boolean(contactDetails.partner_contacts?.length) && (
            <>
              <Subtitle>Контактные лица</Subtitle>
              <StyledModalRowStart>
                {contactDetails.partner_contacts.map((contact) => {
                  return (
                    <Fragment key={contact.id}>
                      <Container direction="column">
                        <Container gap="0 1rem" wrap="wrap">
                          <FieldLabel $fontSize="12px">Контакт</FieldLabel>
                          <FieldInfo $fontSize="12px">{contact.contact_name}</FieldInfo>
                        </Container>
                        {contact.email && (
                          <Container gap="0 1rem" wrap="wrap">
                            <FieldLabel $fontSize="12px">Электронная почта</FieldLabel>
                            <FieldInfo $fontSize="12px" as="a" href={`mailto:${contact.email}`}>
                              {contact.email}
                            </FieldInfo>
                          </Container>
                        )}
                      </Container>
                      <Container direction="column">
                        {contact.phone && (
                          <Container gap="0 1rem" wrap="wrap">
                            <FieldLabel $fontSize="12px">Телефон</FieldLabel>
                            <FieldInfo $fontSize="12px" as="a" href={`tel:${contact.phone}`}>
                              {contact.phone}
                            </FieldInfo>
                          </Container>
                        )}
                        {contact.others && (
                          <Container gap="0 1rem" wrap="wrap">
                            <FieldLabel $fontSize="12px">Другие контакты</FieldLabel>
                            <FieldInfo $fontSize="12px">{contact.others}</FieldInfo>
                          </Container>
                        )}
                      </Container>
                    </Fragment>
                  );
                })}
              </StyledModalRowStart>
            </>
          )}
        </>
      )}

      {confirmRemove && (
        <ConfirmAction
          isSubmitting={isDeleting}
          actionText="Уверены что хотите удалить контакт?"
          confirm={handleContactDelete}
          cancel={() => setConfirmRemove(false)}
          confirmButtonText="Удалить"
        />
      )}
    </Modal>
  );
}
