import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getNotificationsOnLoad } from 'src/redux/features/notificationsSlice';
import { IconButton } from '@shared/components/IconButton/IconButton';
import NotifyIcon from '@assets/images/icons/icon-notifications.svg';
import { Separator } from '@shared/components';
import useNewNotification from 'src/hooks/notifications/useNewNotification';
import { useSocketContext } from 'src/contexts/SocketContext';

import { StyledUserNavInnerList, StyledUserNavItem } from '@components/nav/styles';

import { StyledButton, StyledNotify } from './styles';
import NotificationItem from './NotificationItem';

export const NotificationBell = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  // зарегистрировать хук для отслеживания новых уведомлений
  useNewNotification();

  const { socket, readNotification } = useSocketContext();

  useEffect(() => {
    if (!socket?.connected) return;
    dispatch(getNotificationsOnLoad({ readNotification }));
  }, [socket]);

  const unreadNotifications = useSelector((state) => state.notifications.unreadNotifications);

  const renderNotifications = useCallback(() => {
    const notificationsArr = [];

    for (let i = 0; i < 10 && i < unreadNotifications.length; i++) {
      notificationsArr.push(
        <NotificationItem notificationData={unreadNotifications[i]} key={i} setIsOpen={setIsOpen} />,
      );
    }
    return notificationsArr;
  }, [unreadNotifications]);

  return (
    <StyledUserNavItem onBlur={() => setIsOpen(false)}>
      <IconButton size={17} icon={NotifyIcon} onClick={() => setIsOpen((prev) => !prev)} />
      {!!unreadNotifications.length && <StyledNotify />}

      <StyledUserNavInnerList $active={isOpen}>
        <StyledButton
          onClick={() => {
            navigate('/notifications');
            setIsOpen(false);
          }}
        >
          Показать все уведомления
        </StyledButton>
        {Boolean(unreadNotifications?.length) && <Separator />}
        {renderNotifications()}
      </StyledUserNavInnerList>
    </StyledUserNavItem>
  );
};
