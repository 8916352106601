import { StyledCheckbox } from './style';

export const Checkbox = (props) => {
  const onChangeHandler = (evt) => props.onChange && props.onChange(evt.currentTarget.checked);

  return (
    <StyledCheckbox style={props.style}>
      <input
        disabled={props.disabled}
        checked={props.checked}
        type="checkbox"
        value={props.id}
        onChange={onChangeHandler}
        id={props.id}
        {...props.register}
      />
      <label htmlFor={props.id}>{props.children}</label>
    </StyledCheckbox>
  );
};
