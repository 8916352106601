const { default: styled } = require('styled-components');

export const ListItem = styled.li`
  padding-left: 20px;
  margin: 20px 0;

  @media (max-width: 380px) {
    padding-left: 0;
  }
`;

export const StyledPollOptionContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 380px) {
    gap: 10px;
  }
`;
