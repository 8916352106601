import { useState, useCallback, memo, useMemo, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTicketModal } from '@components/Tickets/TicketsFromMe';
import { CreateReminderModal } from '@components/Reminders';
import useAuth from 'src/hooks/useAuth';
import { useSocketContext } from 'src/contexts/SocketContext';
import { useMessageContext } from 'src/contexts/MessageContext';
import { randomSequence, useOutsideTrigger } from 'src/utilize/helper-functions';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import CreateTaskModal from 'src/components/tasks/CreateTaskModal';
import ProjectFormCreateEditBody from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEditBody';
import LocalDateTime from 'src/components/shared/LocalDateTime';
import { StyledChatMenu, StyledChatMenuItem, StyledChatMenuSeparator } from '@components/chat/styles';

import { StyledControls, StyledControlWrapper, StyledMessageDots } from '@components/chat/message/styles';

import iconDots from '@assets/images/icons/subtask-dots.svg';
import ModalPortal from '@shared/components/Modal/ModalPortal';
import CreatePollModal from 'src/windows/polls/CreatePollModal';

import SnackbarContext from '../../../contexts/SnackbarContext';
import useMenuPosition from '../../../hooks/useCoordinates/useMenuPosition';

import MessageTagsModal from './MessageTagsModal';
import MessagePinBtn from './MessagePinBtn';
import ForwardMessageMenuItem from './MessageControlItems/ForwardMessageMenuItem';
import SelectMessageMenuItem from './MessageControlItems/SelectMessageMenuItem';
import { handlePollEdit } from './lib';

const MessageControlsContainer = (props) => {
  const { guestRights } = useMessageContext();

  return <MessageControls {...props} guestRights={guestRights} />;
};

const MessageControls = memo(
  ({
    projectTitle,
    date_created,
    editor_state,
    employee_id,
    fullName,
    guestRights,
    handleMessageEditClick,
    hideProjectOptions,
    hidePin,
    messageId,
    message,
    messageTags,
    pinned,
    isMarked,
    setIsMarked,
    goToMessage,
    projectId,
    taskId,
    isForwarded,
    replyByQuoting,
    pollId,
    canEditPoll,
    isProjectLocked,
  }) => {
    const auth = useAuth();
    // const guestRights = null;
    const [dotsMenu, toggleDotsMenu] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState();
    const [taskOrProjectCreateModal, showTaskOrProjectCreateModal] = useState();
    const [pollEditModal, setPollEditModal] = useState(false);
    const [ticketModalVisible, setTicketModalVisible] = useState(false);
    const [reminderModalVisible, setReminderModalVisible] = useState(false);
    const [tagsModal, toggleTagsModal] = useState(false);
    const { t } = useTranslation();
    const { manageMessagePin, deleteMessage, markMessage, unmarkMessage } = useSocketContext();
    const { showSnackbar } = useContext(SnackbarContext);

    const dotsButtonRef = useRef(null);
    const dotsMenuRef = useRef(null);
    // динамическое позиционирование меню

    const coordinates = useMenuPosition({
      triggerRef: dotsButtonRef, // кнопка
      tooltipRef: dotsMenuRef, // всплывашка
      isVisible: dotsMenu, // состояние
      setIsVisible: toggleDotsMenu, //состояние
      position: 'right-top', // расположение относительно меню
    });

    const refsForPopup = useRef([dotsMenuRef, dotsButtonRef]);
    const closePopup = useCallback(() => toggleDotsMenu(false), []);

    useOutsideTrigger(refsForPopup.current, closePopup, dotsMenu);

    const messageBasis = useMemo(
      () => ({
        message_id: messageId,
        editor_state,
        author: fullName,
        LocalDateTime: <LocalDateTime dateTime={date_created} />,
      }),
      [messageId, editor_state, fullName, date_created],
    );

    const { isUserRightful, rightTypes } = useAuth();
    const hasRightToCreateProject = useMemo(
      () => isUserRightful(rightTypes.project_create),
      [isUserRightful, rightTypes],
    );

    const copyLinkToMessage = (messageId) => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      if (params.has('msg')) params.delete('msg');
      params.set('msg', messageId);

      const linkToMessage = `${url.origin}${url.pathname}?${params.toString()}`;

      navigator.clipboard
        .writeText(linkToMessage)
        .then(() => showSnackbar('Ссылка скопирована в буфер обмена', 'success'))
        .catch(() => showSnackbar('Возникла ошибка при копировании ссылки'));
    };

    const { editPoll } = useSocketContext();

    const submitPollEdit = useCallback(
      (formValues) => {
        return handlePollEdit({ formValues, pollId, editPoll, showSnackbar });
      },
      [pollId, showSnackbar, editPoll],
    );

    return (
      <>
        <StyledControls $active={dotsMenu}>
          <StyledControlWrapper>
            {!hidePin && auth?.user && !auth.user.is_guest && <MessagePinBtn pinned={pinned} messageId={messageId} />}
          </StyledControlWrapper>
          <StyledControlWrapper>
            <StyledMessageDots
              icon={iconDots}
              size={18}
              onClick={() => toggleDotsMenu((prev) => !prev)}
              ref={dotsButtonRef}
              type="button"
            />
            <ModalPortal>
              <StyledChatMenu style={{ ...coordinates }} $active={dotsMenu} ref={dotsMenuRef}>
                <div>
                  {(!auth?.user?.is_guest || guestRights?.includes('write')) && (
                    <>
                      {pinned === 1 && (
                        <>
                          <StyledChatMenuItem
                            onClick={() => {
                              goToMessage(messageId);
                              toggleDotsMenu(false);
                            }}
                          >
                            Перейти к сообщению
                          </StyledChatMenuItem>
                          <StyledChatMenuSeparator />
                        </>
                      )}

                      {auth?.user?.id === employee_id && (!pollId || canEditPoll) && (
                        <>
                          {!isForwarded && (
                            <StyledChatMenuItem
                              onClick={() => {
                                toggleDotsMenu(false);
                                if (pollId) {
                                  setPollEditModal(true);
                                  return;
                                }
                                handleMessageEditClick();
                              }}
                            >
                              {t('FormButtons.edit')}
                            </StyledChatMenuItem>
                          )}

                          <StyledChatMenuItem
                            onClick={() => {
                              setConfirmDelete(true);
                              toggleDotsMenu(false);
                            }}
                          >
                            {t('FormButtons.delete')}
                          </StyledChatMenuItem>
                          <StyledChatMenuSeparator />
                        </>
                      )}

                      <StyledChatMenuItem
                        onClick={() => {
                          toggleDotsMenu(false);
                          replyByQuoting(message);
                        }}
                      >
                        Ответить
                      </StyledChatMenuItem>

                      <StyledChatMenuItem
                        onClick={() => {
                          if (!!isMarked) {
                            unmarkMessage(messageId);
                          } else {
                            markMessage(messageId);
                          }

                          setIsMarked((prev) => !prev);
                          toggleDotsMenu(false);
                        }}
                      >
                        {!!isMarked ? 'Снять отметку' : 'Отметить сообщение'}
                      </StyledChatMenuItem>

                      <StyledChatMenuItem
                        onClick={() => {
                          toggleTagsModal(true);
                          toggleDotsMenu(false);
                        }}
                      >
                        Поставить тэги
                      </StyledChatMenuItem>
                    </>
                  )}

                  <StyledChatMenuItem
                    onClick={() => {
                      toggleDotsMenu(false);
                      copyLinkToMessage(messageId);
                    }}
                  >
                    Ссылка на сообщение
                  </StyledChatMenuItem>

                  {!hideProjectOptions && auth?.user && !auth.user.is_guest && (
                    <>
                      {/*функционал поручений пока убрали*/}
                      {/*<StyledChatMenuItem*/}
                      {/*  onClick={() => {*/}
                      {/*    toggleDotsMenu(false);*/}
                      {/*    showTaskOrProjectCreateModal('task');*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  {t('Project.based_task_create')}*/}
                      {/*</StyledChatMenuItem>*/}
                      {/*<StyledChatMenuItem*/}
                      {/*  onClick={() => {*/}
                      {/*    toggleDotsMenu(false);*/}
                      {/*    setTicketModalVisible(true);*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  {t('Project.based_ticket_create')}*/}
                      {/*</StyledChatMenuItem>*/}
                      <StyledChatMenuItem
                        onClick={() => {
                          toggleDotsMenu(false);
                          setReminderModalVisible(true);
                        }}
                      >
                        {t('Project.based_reminder_create')}
                      </StyledChatMenuItem>

                      <StyledChatMenuItem
                        onClick={() => {
                          toggleDotsMenu(false);
                          showTaskOrProjectCreateModal('request');
                        }}
                      >
                        Просьба на основе сообщения
                      </StyledChatMenuItem>

                      {hasRightToCreateProject && (
                        <StyledChatMenuItem
                          onClick={() => {
                            toggleDotsMenu(false);
                            showTaskOrProjectCreateModal('project');
                          }}
                        >
                          {t('Project.based_project_create')}
                        </StyledChatMenuItem>
                      )}
                      <ForwardMessageMenuItem toggleDotsMenu={toggleDotsMenu} messageId={messageId} />
                      <SelectMessageMenuItem toggleDotsMenu={toggleDotsMenu} messageId={messageId} />
                    </>
                  )}
                </div>
              </StyledChatMenu>
            </ModalPortal>
          </StyledControlWrapper>
        </StyledControls>

        {confirmDelete && (
          <ConfirmAction
            actionText={t('Chat.confirm_delete')}
            cancel={() => setConfirmDelete(false)}
            confirm={() => {
              deleteMessage(messageId);
              setConfirmDelete(false);
              if (pinned) manageMessagePin(pinned, messageId);
            }}
            confirmButtonText="Удалить"
          />
        )}

        {tagsModal && (
          <MessageTagsModal messageTags={messageTags} messageId={messageId} onClose={() => toggleTagsModal(false)} />
        )}

        {taskOrProjectCreateModal === 'rubric' && (
          <CreateTaskModal
            messageBasis={messageBasis}
            close={() => showTaskOrProjectCreateModal(false)}
            modalType="rubric"
          />
        )}

        {taskOrProjectCreateModal === 'request' && (
          <CreateTaskModal
            messageBasis={messageBasis}
            close={() => showTaskOrProjectCreateModal(false)}
            modalType="request"
          />
        )}

        {taskOrProjectCreateModal === 'project' && (
          <ProjectFormCreateEditBody
            formId={randomSequence()}
            onClose={() => showTaskOrProjectCreateModal(false)}
            messageBasis={messageBasis}
            modalType="project"
          />
        )}

        {ticketModalVisible && (
          <CreateTicketModal
            projectTitle={projectTitle}
            messageId={messageId}
            message={message}
            onClose={() => setTicketModalVisible(false)}
          />
        )}
        {reminderModalVisible && (
          <CreateReminderModal
            projectTitle={projectTitle}
            projectId={projectId}
            taskId={taskId}
            messageId={messageId}
            message={message}
            onClose={() => setReminderModalVisible(false)}
          />
        )}

        {pollEditModal && (
          <CreatePollModal
            close={() => setPollEditModal(false)}
            pollId={pollId}
            submitRequest={submitPollEdit}
            isMemberOnly={isProjectLocked}
          />
        )}
      </>
    );
  },
);

export default MessageControlsContainer;
