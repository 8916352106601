import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledIconButtonWrapper = styled.div`
  position: relative;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
`;

export const StyledFavList = styled.ul`
  border: 1px solid ${({ theme }) => theme.borderSecondary};
`;

export const StyledFavHeader = styled.li`
  background-color: ${({ theme }) => theme.light};
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
  font-weight: 600;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledFavItem = styled.li`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr max-content;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.light};
  }

  &:hover ${StyledIconButtonWrapper} {
    opacity: 1;
    visibility: visible;
  }
`;

export const StyledFavLink = styled(Link)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
