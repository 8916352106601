import styled from 'styled-components';

export const StyledDragText = styled.p`
  padding-top: 30px;
  position: relative;

  &::before,
  &::after {
    background-color: ${({ theme }) => theme.dark};
    content: '';
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
  }

  &::before {
    height: 22px;
    top: 0;
    width: 2px;
  }

  &::after {
    height: 2px;
    top: 11px;
    width: 22px;
  }
`;

export const StyledDragnDrop = styled.section`
  background-color: ${({ theme }) => theme.disabledSecondary};
  border: 1px dashed ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.dark};
  height: 132px;
  padding: 24px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.lightest};
    color: ${({ theme }) => theme.darkest};

    ${StyledDragText} {
      &::before,
      &::after {
        transform: translateX(-50%) scale(1.1);
      }
    }
  }
`;

export const StyledDragLabel = styled.label`
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
`;

export const StyledDragInput = styled.input`
  display: none;
`;
