import { styled } from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: ${({ width }) => (width ? width : 'max-content')};
  min-width: 120px;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.lightest};

  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  opacity: ${({ $visible }) => ($visible ? '1' : '0')};

  position: absolute;
  top: ${({ marginTop }) => marginTop || 26}px;
  right: ${({ position }) => {
    if (position === 'left') return 0;
    if (position === 'right') return undefined;
  }};

  transition: all 0.3s ease-in-out;

  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);

  z-index: 100;
`;
