import { Label } from '@shared/components/Label/Label';
import { components } from 'react-select';
import { MultipleSelect } from '@shared/components/Select/Select';
import React, { useMemo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Container } from '@shared/components/Container';

import { searchTypes } from '../../../lib';

import { TagIndicator } from './styles';

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Container gap={'12px'} align={'center'}>
        <TagIndicator color={props.data.color} /> {children}
      </Container>
    </components.Option>
  );
};

const CustomValue = ({ children, ...props }) => {
  return (
    <components.MultiValue {...props}>
      <Container gap={'12px'} align={'center'}>
        <TagIndicator color={props.data.color} /> {children}
      </Container>
    </components.MultiValue>
  );
};

export const TagsSelect = ({ control, name }) => {
  const { field } = useController({ control, name });
  const searchType = useWatch({ control, name: 'searchType' });

  const formatReactSelectTagsOptions = (arr) => {
    if (Array.isArray(arr)) {
      return arr.map((el) => {
        return {
          label: el.name,
          value: el.name,
          color: el.color,
          id: el?.tag_id || el.id,
        };
      });
    } else return [];
  };

  const tags = useSelector((state) => state.tags.tagsList);

  const tagsOptions = useMemo(() => formatReactSelectTagsOptions(tags) || [], [tags]);

  if (
    searchType === searchTypes.message ||
    searchType === searchTypes.fileOrFolder ||
    searchType === searchTypes.fileInChat
  ) {
    return '';
  }

  return (
    <div>
      <Label>Теги</Label>
      <MultipleSelect
        components={{ Option: CustomOption, MultiValue: CustomValue }}
        options={tagsOptions}
        onChange={(value) => field.onChange(value)}
        value={field.value}
      />
    </div>
  );
};
