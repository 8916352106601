import { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';

import { showModal } from 'src/redux/features/appVersionsSlice';
import { addNewNotification } from 'src/redux/features/notificationsSlice';
import { server_url } from 'src/settings/base-url';
import {
  getOnlineUsers,
  getUnavailableUsers,
  setBirthdays,
  setUnavailableEmployees,
  userConnected,
  userDisconnected,
} from 'src/redux/features/usersSlice';
import useAuth from 'src/hooks/useAuth';

import { setReminders } from 'src/redux/features/remindersSlice';

import SnackbarContext from './SnackbarContext';

const SocketContext = createContext({
  socket: null,
  initSocket: () => {},
  deinitSocket: () => {},
  addToRoom: () => {},
  leaveRoom: () => {},

  // УВЕДОМЛЕНИЯ
  // onNotification: () => {},
  readNotification: () => {},

  //ПРОЕКТЫ, ЗАДАЧИ
  startTask: () => {},
  finishTask: () => {},
  unfinishTask: () => {},
  onTaskStatusChange: () => {},

  // ЧАТ СООБЩЕНИЯ
  onChatChange: () => {},
  offChatChange: () => {},
  sendMessage: () => {},
  userWrites: () => {},
  onUserWrites: () => {},
  offUserWrites: () => {},
  userStoppedWriting: () => {},
  onUserStoppedWriting: () => {},
  likeMessage: () => {},
  dislikeMessage: () => {},
  manageMessagePin: () => {},
  sendMessageEdit: () => {},
  deleteMessage: () => {},
  recoverMessage: () => {},
  sendResultFeedback: () => {},
  markMessage: () => {},
  unmarkMessage: () => {},
  createPoll: () => {},
  editPoll: () => {},

  // ЮЗЕРЫ, ОТДЕЛЫ
  onDepartmentChange: () => {},
  offDepartmentChange: () => {},

  // ИЗБРАННОЕ
  addToFavorites: () => {},
  removeFavorite: () => {},
});

const SocketContextProvider = ({ children }) => {
  const socketConnection = useRef();
  const isConnecting = useRef();
  const [socket, setSocket] = useState();
  const { showSnackbar } = useContext(SnackbarContext);

  const dispatch = useDispatch();
  const auth = useAuth();

  const deinitSocket = useCallback(() => {
    if (socketConnection.current?.connected) socketConnection.current.disconnect();
    setSocket(null);
    dispatch(userDisconnected('all'));
    isConnecting.current = false;
    socketConnection.current = null;
  }, []);

  const initSocket = useCallback((token) => {
    if (!isConnecting.current && !socketConnection.current) {
      isConnecting.current = true;
      socketConnection.current = io(server_url, {
        auth: { token },
        transports: ['websocket', 'polling', 'flashsocket'],
      });

      socketConnection.current?.on('connect_error', (error) => {
        if (
          error.message?.includes('invalid signature') ||
          error.message?.includes('invalid token') ||
          error.message?.includes('jwt expired')
        ) {
          socketConnection.current = null;
          isConnecting.current = false;
        }
      });

      socketConnection.current?.on('connect', () => {
        if (socketConnection.current?.connected) {
          setSocket(socketConnection.current);
          dispatch(getOnlineUsers());
          dispatch(getUnavailableUsers());
        }
        isConnecting.current = false;
      });

      socketConnection.current?.on('disconnect', () => {
        dispatch(userDisconnected('all'));
        setSocket(null);
      });
    }
  }, []);

  useEffect(() => {
    if (socket?.connected) {
      socket.on('employee_connected', (userId) => {
        if (userId === auth?.user?.id) return;

        dispatch(userConnected(userId));
      });

      socket.on('employee_disconnected', (userId) => {
        dispatch(userDisconnected(userId));
      });

      socket.on(`version_changed`, () => {
        dispatch(showModal());
      });

      socket.on('unavailable_employees', (data) => {
        dispatch(setUnavailableEmployees(data));
      });

      if (auth?.user?.id) {
        socket.emit('add_to_room', `common_${auth.user.id}`);
        socket.on('send_notification', (data) => {
          if (data.sender !== auth.user.id) {
            dispatch(addNewNotification(data));
          }
        });

        // Напоминания
        socket.on('send_reminder', (reminder) => {
          dispatch(setReminders(reminder));
        });
      }

      socket.on('birthdays', (data) => {
        dispatch(setBirthdays(data));
      });

      return () => {
        socket.off('unavailable_employees');
        socket.off('version_changed');
        socket.off('employee_connected');
        socket.off('employee_disconnected');
        socket.off('send_notification');
        socket.off('birthdays');
        socket.emit('leave_room', `common_${auth?.user?.id}`);
      };
    }
  }, [socket, dispatch, auth]);

  const addToRoom = (roomName) => {
    if (socket?.connected) {
      socket.emit('add_to_room', roomName, () => {
        // joinedRooms.current.push(roomName);
      });
    }
  };

  const leaveRoom = (roomName) => {
    if (socket?.connected) {
      socket.emit('leave_room', roomName, () => {
        // joinedRooms.current = joinedRooms.current.filter((r) => r !== roomName);
      });
    }
  };

  // УВЕДОМЛЕНИЯ

  const readNotification = (noteId, callback) => {
    if (socket?.connected) {
      socket.emit('notification_is_read', noteId, callback);
    }
  };

  // ПРОЕКТЫ, ЗАДАЧИ

  const startTask = (data) => {
    if (socket?.connected) {
      socket.emit('project_task_start', data, () => {});
    }
  };

  const finishTask = (data) => {
    if (socket?.connected) {
      socket.emit('project_task_finish', data, () => {});
    }
  };

  const unfinishTask = (data) => {
    socket?.emit('project_task_return', data, () => {});
  };

  const onTaskStatusChange = (callback) => {
    if (socket?.connected) {
      socket.on('project_task_status_changed', callback);
    }
  };

  // ЧАТ СООБЩЕНИЯ
  const onChatChange = (callback) => {
    if (socket?.connected) socket.on('chat_message_changed', callback);
  };

  const offChatChange = () => {
    socket?.off('chat_message_changed');
  };

  const sendMessage = (data, callback) => {
    if (socket?.connected) socket.emit('send_message', data, callback);
  };

  const sendMessageEdit = (data, callback) => {
    if (socket?.connected) socket.emit('edit_message', data, callback);
  };

  const userWrites = (chatId) => {
    if (socket?.connected) socket.emit('user_writes', chatId, () => {});
  };

  const userStoppedWriting = (chatId) => {
    if (socket?.connected) socket.emit('user_stopped_writing', chatId, () => {});
  };

  const onUserStoppedWriting = (callback) => {
    if (socket?.connected) socket.on('user_stopped_writing', callback);
  };

  const onUserWrites = (callback) => {
    if (socket?.connected) socket.on('user_writes', callback);
  };

  const offUserWrites = () => {
    socket?.off('user_writes');
    socket?.off('user_stopped_writing');
  };

  const markMessage = (messageId) => {
    if (socket && socket.connected) {
      socket.emit('mark_message', messageId, () => {});
    }
  };

  const unmarkMessage = (messageId) => {
    if (socket && socket.connected) {
      socket.emit('unmark_message', messageId, () => {});
    }
  };

  const cancelLikeDislike = (messageId) => {
    if (socket?.connected) {
      socket.emit('cancel_like_dislike', messageId, () => {});
    }
  };

  const likeMessage = (likesState, messageId) => {
    if (socket && socket.connected) {
      if (!likesState.iLiked) {
        socket.emit('message_like', messageId, () => {});
      } else cancelLikeDislike(messageId);
    }
  };

  const dislikeMessage = (likesState, messageId) => {
    if (socket && socket.connected) {
      if (!likesState.iDisliked) {
        socket.emit('message_dislike', messageId, () => {});
      } else cancelLikeDislike(messageId);
    }
  };

  const manageMessagePin = (isPinned, messageId) => {
    if (socket && socket.connected) {
      if (isPinned) {
        socket.emit('message_unpin', messageId, () => {});
      } else {
        socket.emit('message_pin', messageId, () => {});
      }
    }
  };

  const deleteMessage = (messageId) => {
    if (socket && socket.connected) {
      socket.emit('message_delete', messageId, () => {});
    }
  };

  const recoverMessage = (messageId) => {
    if (socket && socket.connected) {
      socket.emit('message_recover', messageId, () => {});
    }
  };

  // ЮЗЕРЫ, ОТДЕЛЫ
  const onDepartmentChange = (callback) => {
    if (socket?.connected) {
      socket.on('department_changed', callback);
    }
  };

  const offDepartmentChange = () => {
    if (socket?.connected) {
      socket.off('department_changed');
    }
  };

  const sendResultFeedback = (chat_goal_result_id, feedback) => {
    const data = { chat_goal_result_id, result: feedback };

    if (socket?.connected) {
      socket.emit('goal_accept', data);
    } else {
      showSnackbar('Нет соединения с сервером');
    }
  };

  // ИЗБРАННОЕ

  const addToFavorites = (data, callback = () => {}) => {
    if (socket?.connected) {
      socket.emit('starred_add', data, callback);
    }
  };

  const removeFavorite = (data, callback) => {
    if (socket?.connected) {
      socket.emit('starred_remove', data, callback);
    }
  };

  const forwardMessage = (data, callback = () => {}) => {
    if (socket?.connected) {
      socket.emit('forward_message', data, callback);
    }
  };

  const createPoll = (data, callback = () => {}) => {
    if (socket?.connected) {
      socket.emit('create_poll', data, callback);
    }
  };

  const editPoll = (data, callback = () => {}) => {
    if (socket?.connected) {
      socket.emit('edit_poll', data, callback);
    }
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        initSocket,
        deinitSocket,
        addToRoom,
        leaveRoom,
        // onNotification,
        readNotification,
        startTask,
        finishTask,
        unfinishTask,
        onTaskStatusChange,
        onChatChange,
        offChatChange,
        sendMessage,
        userWrites,
        onUserWrites,
        offUserWrites,
        userStoppedWriting,
        onUserStoppedWriting,
        likeMessage,
        dislikeMessage,
        manageMessagePin,
        sendMessageEdit,
        deleteMessage,
        recoverMessage,
        onDepartmentChange,
        offDepartmentChange,
        sendResultFeedback,
        addToFavorites,
        removeFavorite,
        markMessage,
        unmarkMessage,
        forwardMessage,
        createPoll,
        editPoll,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocketContext = () => {
  const context = useContext(SocketContext);

  if (context) {
    return context;
  }
  throw new Error('useSocketContext must be used within a SocketContextProvider');
};

export { useSocketContext, SocketContextProvider };
