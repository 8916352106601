import { Input } from '@shared/components/Input/Input';
import { Label } from '@shared/components/Label/Label';
import { Modal } from '@shared/components/Modal/Modal';
import React, { useState } from 'react';
import { useAddNewVersionMutation } from 'src/redux/features/api/versions';

export const AddVersionModal = ({ onClose }) => {
  const [value, setValue] = useState('');

  const [addVersion, { isLoading, isError }] = useAddNewVersionMutation();

  const onSubmitHandler = async () => {
    await addVersion({
      row: {
        version: value,
      },
    });

    onClose();
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Modal
      modalSize="767px"
      title="Добавить новую версию портала"
      confirmButtonText={'Добавить и уведомить пользователей'}
      onSave={onSubmitHandler}
      onClose={onClose}
    >
      <div>
        <Label>Номер версии</Label>
        <Input value={value} onChange={onChange} placeholder={'0.0.0.0'} error={false} />
      </div>
    </Modal>
  );
};
