import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { object, arrayOf, func } from 'prop-types';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Avatar } from '@shared/components/Avatar';
import ImageGallery from 'src/windows/images/ImageGallery';
import { isFileNameImage, useOutsideTrigger } from 'src/utilize/helper-functions';
import useAuth from 'src/hooks/useAuth';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import SnackbarContext from 'src/contexts/SnackbarContext';
import CreateEditNewsModal from 'src/windows/news/CreateEditNewsModal';
import { files_url } from 'src/settings/base-url';
import AttachedFile from 'src/components/files/AttachedFile';

import iconDots from '@assets/images/icons/subtask-dots.svg';

import { StyledChatMenu, StyledChatMenuItem } from '@components/chat/styles';
import {
  StyledChatMessage,
  StyledControls,
  StyledMessageAttach,
  StyledMessageBody,
  StyledMessageDate,
  StyledMessageDots,
  StyledMessageHeader,
  StyledMessageInfo,
  StyledMessageUsername,
} from '@components/chat/message/styles';

import { StyledBlogTitle } from './style';

ArticleMessage.propTypes = {
  newsBlogData: object,
  messagesList: arrayOf(object),
  getData: func,
};

export default function ArticleMessage({ newsBlogData, messagesList, getData }) {
  const { pathname } = useLocation();

  const articleType = useMemo(() => {
    if (pathname?.includes('news')) return 'news';
    if (pathname?.includes('blog')) return 'blog';
  }, [pathname]);

  const author = useSelector((state) => {
    if (newsBlogData) {
      return state.users.employees?.find((employee) => employee.id === newsBlogData.creator_id);
    }
  });

  const [openImgId, setOpenImgId] = useState(null);

  const auth = useAuth();

  // проверить есть ли у юзера права на изменение статуса проекта/таска
  const userHasRights = useMemo(() => {
    if (!auth?.user?.id || !auth?.rightTypes) return false;
    if (articleType === 'news' && auth?.isUserRightful(auth.rightTypes.add_news)) {
      return true;
    }
    return (articleType === 'blogs' && auth?.isUserRightful()) || auth.user.id === newsBlogData?.creator_id;
  }, [auth, newsBlogData, articleType]);

  // форматировать данные о файлах (присвоить url и подготовить массив галереи изображений)
  const { allAttachedFiles, gallerySourcesArr } = useMemo(() => {
    const gallerySourcesArr = [];
    const allAttachedFiles = newsBlogData?.files.map((file) => {
      const url = `${files_url}/news_blogs/files/${newsBlogData.id}/${file.file}?token=${auth.token}`;
      if (isFileNameImage(file.file)) {
        gallerySourcesArr.push({
          original: url,
          thumbnail: url,
          loading: 'lazy',
          alt: file.file,
          imgid: file.id,
        });
      }
      file.url = url;
      return file;
    });
    return { gallerySourcesArr, allAttachedFiles };
  }, [newsBlogData?.files, auth?.token]);

  const optionsMenuRef = useRef();
  const optionsBtnRef = useRef();
  const refsArray = useRef([optionsMenuRef, optionsBtnRef]);

  const clearOptionsMenu = useCallback(() => {
    setOptionsMenu(false);
  }, []);

  const [editModal, setEditModal] = useState();

  const [optionsMenu, setOptionsMenu] = useState(false);

  // для закрытия всплывашек когда кликают вне этих всплывашек
  useOutsideTrigger(refsArray.current, clearOptionsMenu, optionsMenu);

  const [confirmDelete, setConfirmDelete] = useState();

  const [isDeleting, setIsDeleting] = useState();

  const { showSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const handleDelete = async () => {
    if (newsBlogData) {
      setIsDeleting(true);
      if (newsBlogData.files?.length) {
        await axios
          .patch('/api/news_blogs/files/remove', {
            news_blog_id: newsBlogData.id,
            files: newsBlogData.files.map((file) => file.file),
          })
          .catch(() => {
            showSnackbar('Ошибка при удалении файлов');
            return setIsDeleting(false);
          });
      }

      axios
        .delete('/api/news_blogs/delete', {
          data: {
            news_blog_id: newsBlogData.id,
          },
        })
        .then(() => {
          showSnackbar(articleType === 'news' ? 'Новость удалена' : 'Блог удален', 'success');
          return navigate(articleType === 'news' ? '/news' : '/blogs');
        })
        .catch(() => {
          showSnackbar('Произошла ошибка при удалении');
          setIsDeleting(false);
        });
    }
  };

  return (
    <>
      <StyledChatMessage>
        <div>
          {author && (
            <StyledMessageHeader>
              <Avatar userId={author.id} firstName={author.first_name} lastName={author.last_name} showProfileOnClick />
              <StyledMessageInfo>
                <StyledMessageUsername>{`${author.first_name} ${author.last_name || ''}`}</StyledMessageUsername>
                <StyledMessageDate>{moment.utc(newsBlogData.date_created).format('LLL')}</StyledMessageDate>
              </StyledMessageInfo>
            </StyledMessageHeader>
          )}
          <StyledMessageBody>
            <StyledBlogTitle>{newsBlogData.title}</StyledBlogTitle>
            {typeof newsBlogData.description === 'string' ? HTMLReactParser(newsBlogData.description) : ''}
          </StyledMessageBody>
          <StyledMessageAttach>
            {allAttachedFiles?.length > 0 && (
              <>
                {allAttachedFiles.map((file) => (
                  <AttachedFile
                    key={file.id}
                    setOpenImgId={setOpenImgId}
                    fileUrl={file.url}
                    fileId={file.id}
                    fileName={file.file}
                  />
                ))}
              </>
            )}
          </StyledMessageAttach>
        </div>
        {userHasRights && (
          <StyledControls $active={true}>
            <StyledMessageDots
              icon={iconDots}
              size={18}
              onClick={() => setOptionsMenu(!optionsMenu)}
              ref={optionsBtnRef}
              type="button"
            />
            <StyledChatMenu $active={optionsMenu} ref={optionsMenuRef}>
              <StyledChatMenuItem
                onClick={() => {
                  setOptionsMenu(false);
                  setEditModal(true);
                }}
              >
                Редактировать
              </StyledChatMenuItem>

              {!messagesList.length && (
                <StyledChatMenuItem
                  onClick={() => {
                    setOptionsMenu(false);
                    setConfirmDelete(true);
                  }}
                >
                  Удалить
                </StyledChatMenuItem>
              )}
            </StyledChatMenu>
          </StyledControls>
        )}
      </StyledChatMessage>

      {openImgId && Boolean(gallerySourcesArr?.length) && (
        <ImageGallery imgItems={gallerySourcesArr} openImgId={openImgId} close={() => setOpenImgId(null)} />
      )}

      {editModal && articleType && (
        <CreateEditNewsModal
          dataToEdit={newsBlogData}
          type={articleType}
          close={() => setEditModal(false)}
          getData={getData}
        />
      )}

      {confirmDelete && (
        <ConfirmAction
          confirm={() => handleDelete()}
          cancel={() => setConfirmDelete(false)}
          actionText="Пожалуйста, подтвердите удаление."
          isSubmitting={isDeleting}
        />
      )}
    </>
  );
}
