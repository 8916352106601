import styled from 'styled-components';

export const StyledTerm = styled.span`
  font-size: 12px;
  line-height: 20px;
  background-color: ${({ theme }) => theme.light};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  color: ${({ theme }) => theme.darkest};
  padding: 1px 8px;
  margin: 0 4px;
  width: max-content;
  display: inline-block;
`;
