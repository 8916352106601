import { useState, useEffect } from 'react';
import { Modal } from '@shared/components/Modal/Modal';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import {
  Button,
  StyledCloseModalButton,
  StyledModal,
  StyledModalBody, StyledModalFooter,
  StyledModalHeader, StyledModalHeaderButton, StyledModalHeaderButtonWrapper,
  StyledModalInner, StyledModalTitle,
  StyledModalWrapper
} from "@shared/components";
import ModalPortal from "@shared/components/Modal/ModalPortal";

const ImageGallery = ({ close, imgItems, openImgId }) => {
  const [startIndex, setStartIndex] = useState(null);

  useEventTriggerOnEscPress(close);

  // найти индекс файла по id файла и отобразить слайд по найденному индексу
  useEffect(() => {
    if (!openImgId || !imgItems?.length) return;
    const indToOpen = imgItems.findIndex((img) => img.imgid === openImgId);
    if (indToOpen === -1) return;
    setStartIndex(indToOpen);
  }, [imgItems, openImgId]);

  if (startIndex === null || !imgItems?.length) return '';
  return (
  <ModalPortal>
    <StyledModal>
      <StyledModalWrapper>
        <StyledModalInner modalsize="1200px" style={{width: 'max-content'}}>
          <StyledModalBody style={{padding: '4px 8px', gap: '4px'}}>
            <StyledModalHeader>
              <StyledCloseModalButton onClick={close} />
            </StyledModalHeader>

            <ReactImageGallery
              items={imgItems}
              startIndex={startIndex}
              infinite={false}
              showPlayButton={false}
              showFullscreenButton={false}
            />

          </StyledModalBody>
        </StyledModalInner>
      </StyledModalWrapper>
    </StyledModal>
  </ModalPortal>
  );
};

export default ImageGallery;
