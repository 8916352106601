import styled from 'styled-components';

export const StyledOptionsWrapper = styled.div`
  & > * {
    margin-bottom: 10px;
  }
`;

export const StyledCheckboxLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;

  & img {
    max-height: 80px;
    max-width: 100px;
    border-radius: 2px;
  }
`;
