import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ProjectFilterSelect from 'src/components/form/select/ProjectFilterSelect';
import { isEmpty } from 'src/utilize/helper-functions';
import Tag from '@shared/components/Tag/Tag';
import {useWindowWidth} from "@shared/hooks/useWindowWidth";

import iconCalendar from '@assets/images/icons/calendar.svg';
import iconArrow from '@assets/images/icons/calendar-arrow.svg';

import {
  DateRange,
  DateRangeTitle,
  IconArrow,
  IconCalendar,
  ProjectFiltersCol,
  ProjectFiltersRow,
  ProjectListFilters,
  ProjectListTags,
  StyledDatePicker,
} from './style';

const ProjectListFilter = () => {
  const users = useSelector((state) => state.users.employees);
  const tags = useSelector((state) => state.tags.tagsList);

  const [filters, setFilters] = useState({
    responsible: {
      label: 'Любой ответственный',
      value: '',
    },
    executor: {
      label: 'Любой исполнитель',
      value: '',
    },
    creator: {
      label: 'Любой создатель',
      value: '',
    },
    tags: [],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  // поставить фильтры в селектах, если в адресной строке указаны параметры фильтрации
  useEffect(() => {
    // const newFiltersState = { ...filters };
    const newFiltersState = {};
    for (const [key, value] of searchParams) {
      if (key === 'responsible_id' && value && !filters.responsible.value && users?.length) {
        const userResponsible = users.find((user) => user.id === +value);
        if (userResponsible) {
          newFiltersState.responsible = {
            label: `${userResponsible.last_name || ''} ${userResponsible.first_name}`,
            value: userResponsible.id,
          };
        }
      }

      if (key === 'executor_id' && value && !filters.executor.value && users?.length) {
        const userExecutor = users.find((user) => user.id === +value);
        if (userExecutor) {
          newFiltersState.executor = {
            label: `${userExecutor.last_name || ''} ${userExecutor.first_name}`,
            value: userExecutor.id,
          };
        }
      }

      if (key === 'creator_id' && value && !filters.creator.value && users?.length) {
        const userCreator = users.find((user) => user.id === +value);
        if (userCreator) {
          newFiltersState.creator = {
            label: `${userCreator.last_name || ''} ${userCreator.first_name}`,
            value: userCreator.id,
          };
        }
      }

      if (key === 'date_start_from' && value && !filters.date_start_from) {
        newFiltersState.date_start_from = new Date(+value);
      }

      if (key === 'tags' && value && !filters.tags.length) {
        newFiltersState.tags = value.split(',').map((id) => +id);
      }

      if (!isEmpty(newFiltersState)) setFilters((f) => ({ ...f, ...newFiltersState }));
    }
  }, [searchParams, filters, users]);

  // обновить state селекта, изменить параметры в адресной строке,
  const handleResponsibleChange = (optionResponsible) => {
    if (optionResponsible.value !== filters.responsible?.value) {
      const newSearchParams = {};
      for (const [key, value] of searchParams) newSearchParams[key] = value;

      optionResponsible.value
        ? (newSearchParams.responsible_id = optionResponsible.value)
        : delete newSearchParams.responsible_id;

      setFilters((f) => ({ ...f, responsible: optionResponsible }));
      setSearchParams(newSearchParams);
    }
  };

  const handleExecutorChange = (optionExecutor) => {
    if (optionExecutor.value !== filters.creator?.value) {
      const newSearchParams = {};
      for (const [key, value] of searchParams) newSearchParams[key] = value;

      optionExecutor.value ? (newSearchParams.executor_id = optionExecutor.value) : delete newSearchParams.executor_id;

      setFilters((f) => ({ ...f, executor: optionExecutor }));
      setSearchParams(newSearchParams);
    }
  };

  const handleCreatorChange = (optionCreator) => {
    if (optionCreator.value !== filters.creator?.value) {
      const newSearchParams = {};
      for (const [key, value] of searchParams) newSearchParams[key] = value;

      optionCreator.value ? (newSearchParams.creator_id = optionCreator.value) : delete newSearchParams.creator_id;

      setFilters((f) => ({ ...f, creator: optionCreator }));
      setSearchParams(newSearchParams);
    }
  };

  const handleDateChange = (date, type) => {
    if (date instanceof Date) {
      const dateNum = date.getTime();

      const newSearchParams = {};
      for (const [key, value] of searchParams) newSearchParams[key] = value;

      setFilters((f) => ({ ...f, [type]: date }));
      setSearchParams({ ...newSearchParams, [type]: dateNum });
    }
  };

  const handleTagSelect = (tagId) => {
    const newFilters = { ...filters };
    const newSearchParams = {};
    for (const [key, value] of searchParams) newSearchParams[key] = value;

    if (newFilters.tags.includes(tagId)) {
      newFilters.tags = newFilters.tags.filter((id) => id !== tagId);
      setFilters(newFilters);
    } else {
      newFilters.tags.push(tagId);
      setFilters(newFilters);
    }

    let { tags } = newSearchParams;
    if (!tags) {
      newSearchParams.tags = `${tagId}`;
      return setSearchParams(newSearchParams);
    }
    tags = tags.split(',');

    if (tags.find((id) => +id === tagId)) {
      tags = tags.filter((id) => +id !== tagId);
      tags.length > 0 ? (newSearchParams.tags = tags.join()) : delete newSearchParams.tags;
      return setSearchParams(newSearchParams);
    }
    tags.push(tagId);
    newSearchParams.tags = tags.join();
    setSearchParams(newSearchParams);
  };

  // для изменения высоты селектов при ресайзе
  const windowWidth = useWindowWidth();

  return (
    <ProjectListFilters>
      <ProjectListTags>
        {tags?.map((tag, i) => (
          <Tag
            as="span"
            key={i}
            tag={tag}
            onClick={() => handleTagSelect(tag.id)}
            $active={filters.tags.includes(tag.id)}
          />
        ))}
      </ProjectListTags>
      {/* временно убрали этот функционал */}
      {/*<ProjectFiltersRow>*/}
      {/*  <ProjectFiltersCol>*/}
      {/*    <DateRange>*/}
      {/*      <DateRangeTitle>Период начала:</DateRangeTitle>*/}
      {/*      <StyledDatePicker*/}
      {/*        position="start"*/}
      {/*        placeholderText="от"*/}
      {/*        dateFormat="dd.MM.yyyy"*/}
      {/*        onChange={(date) => handleDateChange(date, 'date_start_from')}*/}
      {/*        selected={filters.date_start_from}*/}
      {/*      />*/}
      {/*      <StyledDatePicker*/}
      {/*        position="end"*/}
      {/*        placeholderText="до"*/}
      {/*        dateFormat="dd.MM.yyyy"*/}
      {/*        onChange={(date) => handleDateChange(date, 'date_start_to')}*/}
      {/*        selected={filters.date_start_to}*/}
      {/*      />*/}
      {/*      <IconCalendar size={13} icon={iconCalendar} />*/}
      {/*      <IconArrow size={12} icon={iconArrow} />*/}
      {/*    </DateRange>*/}
      {/*    <DateRange>*/}
      {/*      <DateRangeTitle>Период окончания:</DateRangeTitle>*/}
      {/*      <StyledDatePicker*/}
      {/*        position="start"*/}
      {/*        placeholderText="от"*/}
      {/*        dateFormat="dd.MM.yyyy"*/}
      {/*        onChange={(date) => handleDateChange(date, 'date_finish_from')}*/}
      {/*        selected={filters.date_finish_from}*/}
      {/*      />*/}
      {/*      <StyledDatePicker*/}
      {/*        position="end"*/}
      {/*        placeholderText="до"*/}
      {/*        dateFormat="dd.MM.yyyy"*/}
      {/*        onChange={(date) => handleDateChange(date, 'date_finish_to')}*/}
      {/*        selected={filters.date_finish_to}*/}
      {/*      />*/}
      {/*      <IconCalendar size={13} icon={iconCalendar} />*/}
      {/*      <IconArrow size={12} icon={iconArrow} />*/}
      {/*    </DateRange>*/}
      {/*    <DateRange>*/}
      {/*      <DateRangeTitle>Дата создания:</DateRangeTitle>*/}
      {/*      <StyledDatePicker*/}
      {/*        position="start"*/}
      {/*        placeholderText="от"*/}
      {/*        dateFormat="dd.MM.yyyy"*/}
      {/*        onChange={(date) => handleDateChange(date, 'date_created_from')}*/}
      {/*        selected={filters.date_created_from}*/}
      {/*      />*/}
      {/*      <StyledDatePicker*/}
      {/*        position="end"*/}
      {/*        placeholderText="до"*/}
      {/*        dateFormat="dd.MM.yyyy"*/}
      {/*        onChange={(date) => handleDateChange(date, 'date_created_to')}*/}
      {/*        selected={filters.date_created_to}*/}
      {/*      />*/}
      {/*      <IconCalendar size={13} icon={iconCalendar} />*/}
      {/*      <IconArrow size={12} icon={iconArrow} />*/}
      {/*    </DateRange>*/}
      {/*  </ProjectFiltersCol>*/}

      {/*  <ProjectFiltersCol>*/}
      {/*    <ProjectFilterSelect*/}
      {/*      valuesArr={users}*/}
      {/*      value="last_name"*/}
      {/*      value2="first_name"*/}
      {/*      handleChange={handleResponsibleChange}*/}
      {/*      selectedValue={filters.responsible}*/}
      {/*      placeholder="Любой ответственный"*/}
      {/*      windowWidth={windowWidth}*/}
      {/*    />*/}
      {/*    <ProjectFilterSelect*/}
      {/*      valuesArr={users}*/}
      {/*      value="last_name"*/}
      {/*      value2="first_name"*/}
      {/*      handleChange={handleExecutorChange}*/}
      {/*      placeholder="Любой исполнитель"*/}
      {/*      selectedValue={filters.executor}*/}
      {/*      windowWidth={windowWidth}*/}
      {/*    />*/}
      {/*    <ProjectFilterSelect*/}
      {/*      valuesArr={users}*/}
      {/*      value="last_name"*/}
      {/*      value2="first_name"*/}
      {/*      handleChange={handleCreatorChange}*/}
      {/*      placeholder="Любой создатель"*/}
      {/*      selectedValue={filters.creator}*/}
      {/*      windowWidth={windowWidth}*/}
      {/*    />*/}
      {/*  </ProjectFiltersCol>*/}
      {/*</ProjectFiltersRow>*/}
    </ProjectListFilters>
  );
};

export default ProjectListFilter;
