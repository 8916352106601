import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import Preloader from '@components/preloaders/Preloader';
import { getUsers } from 'src/redux/features/usersSlice';
import { useSocketContext } from 'src/contexts/SocketContext';
import UserProfileModalContainer from 'src/windows/profile/UserProfileModalContainer';

import TeamFilterForm from './TeamFilter';
import TeamList from './TeamList';
import TeamTree from './TeamTree';
import {Team} from "./style";

// основная часть контента для страницы team
const TeamMain = () => {
  const { socket, addToRoom, leaveRoom, onDepartmentChange, offDepartmentChange } = useSocketContext();
  const dispatch = useDispatch();
  const [view, setView] = useState({ value: 'list', label: 'Списком' });

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setSearchParams({});
  }, []);

  useEffect(() => {
    if (socket?.connected) {
      if (dispatch) {
        addToRoom('departments_list');

        onDepartmentChange((_data, action) => {
          if (action === 'added' || action === 'edited') dispatch(getUsers());
        });

        return () => {
          leaveRoom('departments_list');
          offDepartmentChange();
        };
      }
    }
  }, [socket, onDepartmentChange, dispatch, addToRoom, leaveRoom, offDepartmentChange]);

  const { employees, departments, positions, isLoading } = useSelector((state) => state.users);
  const [filteredEmployees, setFilteredEmployees] = useState();
  const filterEmployees = (position, dept, name) => {
    let filteredValues;
    filteredValues = employees.filter((emp) => {
      let doesMatch = true;
      const searchTerms = name?.split(' ').map((searchTerm) => searchTerm.toLowerCase());

      if (searchTerms?.length) {
        let isFound = false;
        let loweredName = emp.first_name ? emp.first_name.toLowerCase() : '';
        loweredName += emp.last_name ? emp.last_name.toLowerCase() : '';
        for (const searchTerm of searchTerms) {
          if (loweredName.includes(searchTerm)) {
            isFound = true;
            break;
          }
        }
        doesMatch = isFound;
      }
      if (position && emp.position_id !== +position) doesMatch = false;
      if (dept && emp.department_id !== +dept) doesMatch = false;
      return doesMatch;
    });
    setFilteredEmployees(filteredValues);
  };

  return (
    <>
      <Team>
          {isLoading && <Preloader />}

          <TeamFilterForm
            department={{
              name: 'department',
              icon: true,
              placeholder: 'Все отделы',
              type: 'text',
              options: departments
                ? [{ title: 'Все отделы', id: '' }, ...departments]
                : [{ title: 'Все отделы', id: '' }],
            }}
            position={{
              name: 'position',
              icon: true,
              label: 'Должность',
              placeholder: 'Все должности',
              type: 'select',
              options: positions
                ? [{ title: 'Все должности', id: '' }, ...positions]
                : [{ title: 'Все должности', id: '' }],
            }}
            sorting={{
              name: 'position_id',
              icon: true,
              label: 'Сортировать по',
              placeholder: 'Сортировать по',
              // label: t(`${translateKey}.position`),
              type: 'select',
              options: ['по дате создания', 'по дате изменения'],
            }}
            filterEmployees={filterEmployees}
            view={view}
            setView={setView}
          />
          {employees && view.value === 'list' && <TeamList data={filteredEmployees || employees}></TeamList>}
          {employees && view.value === 'tree' && <TeamTree />}
      </Team>
      {userId && <UserProfileModalContainer profileId={userId} onClose={() => navigate('/team/users')} />}
    </>
  );
};

export default TeamMain;
