import { Input } from '@shared/components';

import iconSearch from '@assets/images/icons/search.svg';

import { StyledIconSearch, StyledSearch } from '@shared/components/Search/styles';

const Search = ({ size, register, onSubmit, isLoading, ...rest }) => {
  return (
    <StyledSearch size={size}>
      <Input register={register} {...rest} onKeyUp={(e) => e.key === 'Enter' && onSubmit && onSubmit()} />
      <StyledIconSearch
        icon={iconSearch}
        onClick={onSubmit}
        disabled
        $isLoading={isLoading}
        style={{ pointerEvents: 'none' }}
      />
    </StyledSearch>
  );
};

export default Search;
