import { useCallback, useContext, useState, useMemo, memo } from 'react';
import axios from 'axios';
import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  StorageButtonBack,
  StorageContent,
  StorageHeader,
  StorageList,
  StorageTitle,
} from '@components/storage/styles';
import iconTrash from '@assets/images/icons/delete.svg';
import { Button, Container } from '@shared/components';

import SnackbarContext from '../../contexts/SnackbarContext';
import { files_url } from '../../settings/base-url';
import Preloader from '../preloaders/Preloader';

import { getStorageTree } from '../../redux/features/storageSlice';

import FileDetailsRow from './FileDetailsRow';
import FolderDetailsRow from './FolderDetailsRow';

const StorageTrash = ({ renderUserName, storageId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [trashData, setTrashData] = useState(null);
  const { storageData } = useSelector((state) => state.storage);
  const [openTrashStack, setOpenTrashStack] = useState([]);

  const { showSnackbar } = useContext(SnackbarContext);
  const isLoadingRef = useRef();

  const getTrashData = useCallback(() => {
    if (!showSnackbar || !storageId || isLoadingRef.current) return;
    setIsLoading(true);
    setTrashData(null);
    axios
      .get(`/api/trash_tree/${storageId}`)
      .then((r) => {
        setTrashData(r.data.storage_tree);
        setOpenTrashStack((s) => {
          const newStack = [...s];
          newStack[0] = r.data.storage_tree;
          return newStack;
        });
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при загрузке данных корзины');
      })
      .finally(() => setIsLoading(false));
  }, [showSnackbar, storageId]);

  useEffect(() => {
    getTrashData();
  }, [getTrashData]);

  const folderPath = useMemo(() => {
    if (openTrashStack.length <= 1) return null;

    let path = `${files_url}/storages/trash/folders/${storageId}/${openTrashStack[1].id}`;
    for (let i = 1; i < openTrashStack.length; i++) {
      path += `/${openTrashStack[i].title}`;
    }
    return path;
  }, [openTrashStack, storageId]);

  const removeItemInLastStackElement = ({ dataType, dataId }) => {
    setOpenTrashStack((s) => {
      const newStack = [...s];
      const lastIndex = newStack.length - 1;
      newStack[lastIndex][dataType] = newStack[lastIndex][dataType].filter((data) => data.id !== dataId);
      return newStack;
    });
  };

  const cleanTrash = () => {
    axios
      .patch('/api/clean_trash', {
        storage_id: storageId,
      })
      .then(() => {
        setOpenTrashStack([]);
        setTrashData(null);
        getTrashData();
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при очистке корзины');
      });
  };

  const dispatch = useDispatch();

  const restoreAll = () => {
    axios
      .patch('/api/restore_all_from_trash', {
        storage_id: storageId,
      })
      .then(() => {
        setOpenTrashStack([]);
        setTrashData(null);
        getTrashData();
        dispatch(getStorageTree({ storageId, showSnackbar }));
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при восстановлении всех файлов');
      });
  };

  return (
    <StorageContent>
      <StorageHeader>
        <StorageTitle tag="h2" size={4} icon={iconTrash}>
          Корзина
        </StorageTitle>
        {trashData && (
          <Container gap="12px" align="center">
            <Button size="sm" color="secondary" onClick={cleanTrash}>
              Очистить
            </Button>
            <Button size="sm" color="secondary" onClick={restoreAll}>
              Восстановить все
            </Button>
          </Container>
        )}
      </StorageHeader>
      {isLoading && <Preloader />}
      {!isLoading && openTrashStack.length > 0 && (
        <section>
          {openTrashStack.length > 1 && (
            <StorageButtonBack
              onClick={() => {
                setOpenTrashStack((s) => {
                  return s.slice(0, -1);
                });
              }}
            >
              На уровень выше
            </StorageButtonBack>
          )}
          <StorageList>
            {openTrashStack.at(-1).folders?.map((folder) => (
              <FolderDetailsRow
                storageId={storageId}
                key={folder.id}
                folder={folder}
                renderUserName={renderUserName}
                openTrashStack={openTrashStack}
                removeItem={removeItemInLastStackElement}
                modifiedFolderOpen={() => setOpenTrashStack((s) => [...s, folder])}
                userCanWrite={storageData?.can_write}
              />
            ))}
          </StorageList>
          <StorageList>
            {openTrashStack[openTrashStack.length - 1].files?.map((fileData) => (
              <FileDetailsRow
                storageId={storageId}
                key={fileData.id}
                fileData={fileData}
                renderUserName={renderUserName}
                openTrashStack={openTrashStack}
                folderPath={folderPath}
                removeItem={removeItemInLastStackElement}
                userCanWrite={storageData?.can_write}
              />
            ))}
          </StorageList>
        </section>
      )}
    </StorageContent>
  );
};

export default memo(StorageTrash);
