import { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';

import { useContext } from 'react';
import { Container, Label, Modal } from '@shared/components';
import DeleteCircleButton from '@shared/components/DeleteCircleButton';
import iconDelete from '@assets/images/icons/delete-circle.svg';
import { StyledTag, StyledTagsWrapper } from '@components/chat/message/styles';

import { StyledTagInput, StyledTagSelect, StyledTagSelectItem, TagDropdown } from '@components/tags/style';
import SnackbarContext from 'src/contexts/SnackbarContext';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import { checkAuthError, useEventTriggerOnEscPress, useOutsideTrigger } from 'src/utilize/helper-functions';
import useLogout from 'src/hooks/useLogout';

const MessageTagsModal = ({ onClose, messageTags, messageId }) => {
  const tags = useSelector((state) => state.tags.tagsList);

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      chat_message_tags: messageTags,
    },
  });

  const [isSending, setIsSending] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const logout = useLogout();

  const sendTags = ({ chat_message_tags }) => {
    const tagsToSend = chat_message_tags.map((tag) => tag.tag_id);
    setIsSending(true);
    axios
      .patch('/api/chat_message_tags/edit', {
        chat_message_id: messageId,
        chat_message_tags: tagsToSend,
      })
      .then(() => onClose())
      .catch((error) => {
        setIsSending(false);
        if (checkAuthError(error)) logout();
        else showSnackbar('Ошибка при редактировании тэгов', 'fail');
      });
  };

  const [dropdown, toggleDropdown] = useState(false);

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handleModalClose = useCallback(() => {
    if (dropdown) return toggleDropdown(false);

    if (isDirty) {
      setModalClosePrompt(true);
    } else onClose();
  }, [isDirty, onClose, dropdown]);

  useEventTriggerOnEscPress(handleModalClose);

  const dropdownSelectRef = useRef();
  const dropdownInputRef = useRef();

  const refsArray = useRef([dropdownSelectRef, dropdownInputRef]);

  const collapseDropdown = useCallback(() => toggleDropdown(false), []);

  useOutsideTrigger(refsArray.current, collapseDropdown, dropdown);

  return (
    <>
      <Modal
        title="Поставить тэги"
        onSave={handleSubmit(sendTags)}
        onClose={handleModalClose}
        disabledSaveButton={isSending || !isDirty}
        confirmButtonText={isSending ? 'Отправка...' : 'Сохранить'}
      >
        <Controller
          name="chat_message_tags"
          control={control}
          render={({ field: { value, onChange } }) => {
            const handleChange = (tagToAdd) => {
              onChange([...value, { ...tagToAdd, tag_id: tagToAdd.id }]);
            };

            const handleDelete = (tagId) => {
              onChange(value.filter((v) => v.tag_id !== tagId));
            };

            const filteredTags = tags?.filter((tag) => value.every((v) => v.tag_id !== tag.id));

            return (
              <>
                <StyledTagsWrapper as="ul">
                  {value.map((tag) => (
                    <Container as="li" align="center">
                      <StyledTag color={tag.color}>{tag.name}</StyledTag>
                      <DeleteCircleButton
                        size={16}
                        icon={iconDelete}
                        type="button"
                        onClick={() => handleDelete(tag.tag_id)}
                      />
                    </Container>
                  ))}
                </StyledTagsWrapper>
                <TagDropdown $show={dropdown} onClick={() => toggleDropdown(!dropdown)} ref={dropdownInputRef}>
                  <Label>Добавить новый тэг</Label>
                  <StyledTagInput onClick={() => toggleDropdown(!dropdown)}>
                    <StyledTag color={filteredTags[0]?.color}>{filteredTags[0]?.name}</StyledTag>
                  </StyledTagInput>
                  <StyledTagSelect ref={dropdownSelectRef}>
                    {filteredTags?.map((tag, i) => (
                      <StyledTagSelectItem key={i} data-option={tag.id} onClick={() => handleChange(tag)}>
                        <StyledTag color={tag.color}>{tag.name}</StyledTag>
                      </StyledTagSelectItem>
                    ))}
                  </StyledTagSelect>
                </TagDropdown>
              </>
            );
          }}
        />
      </Modal>

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={onClose}
          actionText={'Уверены, что хотите закрыть окно без сохранения?'}
        />
      )}
    </>
  );
};

export default MessageTagsModal;
