import { rtkQueryApi } from './rtkQueryApi';

export const employeesApi = rtkQueryApi.injectEndpoints({
  tagTypes: ['Employees', 'Salaries', 'Payments', 'Filtered', 'EmployeesDismissals', 'PortalAllRights'],
  endpoints: (builder) => ({
    getSalariesList: builder.query({
      query: () => '/api/employee_salaries_list',
      providesTags: ['Employees', 'Salaries'],
    }),
    setSalary: builder.mutation({
      query: (body) => ({
        url: '/api/employee_salaries/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Employees', 'Salaries'],
    }),
    getPaymentsList: builder.query({
      query: () => '/api/employee_payouts_per_year',
      providesTags: ['Employees', 'Payments'],
    }),
    getFilteredPaymentsList: builder.query({
      query: ({ dateFrom, dateTo, departmentId }) =>
        `/api/employee_payouts_per_period/${dateFrom}/${dateTo}/${departmentId}`,
      providesTags: ['Employees', 'Payments', 'Filtered'],
    }),
    getUserPaymentsList: builder.query({
      query: ({ employeeId }) => `/api/employee_payouts_by_employee/${employeeId}`,
      providesTags: ['Employees', 'Payments'],
    }),
    getUserPaymentsLastTenDays: builder.query({
      query: () => `/api/employee_payouts_per_ten_days`,
      providesTags: ['Employees', 'Payments'],
    }),
    addPayment: builder.mutation({
      query: (body) => ({
        url: '/api/employee_payouts/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Employees', 'Payments'],
    }),
    editPayment: builder.mutation({
      query: (body) => ({
        url: '/api/employee_payouts/edit',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Employees', 'Payments'],
    }),
    deletePayment: builder.mutation({
      query: (body) => ({
        url: '/api/employee_payouts/delete',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Employees', 'Payments'],
    }),
    getEmployeesDismissals: builder.query({
      query: () => ({
        url: '/api/employee_dismissal',
      }),
      transformResponse: (response) => {
        return response.employeeDismissal.reduce((acc, dismissal) => {
          if (dismissal.canceled) return acc;
          acc[dismissal.employee_id] = dismissal;
          return acc;
        }, {});
      },
      providesTags: ['EmployeesDismissals'],
    }),
    getDismissalReasons: builder.query({
      query: () => ({
        url: '/api/employee_dismissal_reasons',
        providesTags: ['DismissalReasons'],
      }),
    }),
    dismissEmployee: builder.mutation({
      query: (body) => ({
        url: '/api/employee_dismissal/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['EmployeesDismissals'],
    }),
    editEmployeeDimissal: builder.mutation({
      query: (body) => ({
        url: '/api/employee_dismissal/edit',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EmployeesDismissals'],
    }),
    cancelEmployeeDismissal: builder.mutation({
      query: (body) => ({
        url: '/api/employee_dismissal_canceled',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EmployeesDismissals'],
    }),
    getPortalRightsList: builder.query({
      query: () => ({
        url: '/api/all_rights',
        providesTags: ['PortalAllRights'],
      }),
    }),
  }),
});

export const {
  useGetEmployeesDismissalsQuery,
  useGetSalariesListQuery,
  useSetSalaryMutation,
  useGetPaymentsListQuery,
  useGetFilteredPaymentsListQuery,
  useGetUserPaymentsListQuery,
  useAddPaymentMutation,
  useEditPaymentMutation,
  useGetUserPaymentsLastTenDaysQuery,
  useDeletePaymentMutation,
  useGetDismissalReasonsQuery,
  useDismissEmployeeMutation,
  useEditEmployeeDimissalMutation,
  useCancelEmployeeDismissalMutation,
  useGetPortalRightsListQuery,
} = employeesApi;
