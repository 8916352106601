import { useEffect, useState, memo } from 'react';
import { Separator } from '@shared/components';

import { SiteInnerLinkInstallApp } from './styles';
import AppInstallInstructions from './AppInstallInstructions/AppInstallInstructions';

function NavListItemInstallAppFC() {
  const [deferredPrompt, setDeferredPrompt] = useState();

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  const [instructionsModal, setInstructionsModal] = useState();

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt?.prompt();
      return;
    }
    setInstructionsModal(true);
  };

  return (
    <>
      <Separator />
      <SiteInnerLinkInstallApp
        role="menuitem"
        onClick={handleInstallClick}
        onKeyUp={(e) => e.key === 'Enter' && handleInstallClick()}
        tabIndex="0"
      >
        Установить приложение
      </SiteInnerLinkInstallApp>

      {instructionsModal && <AppInstallInstructions onClose={() => setInstructionsModal(false)} />}
    </>
  );
}

const NavListItemInstallApp = memo(NavListItemInstallAppFC);

export default NavListItemInstallApp;
