import styled from 'styled-components';
import {Text} from '@shared/components';


export const Salary = styled.section`
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 24px;
  
  @media (max-width: 550px) {
    padding: 0 12px;
  }
`

export const StyledUserWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledTableWrapper = styled.div`
  overflow-x: auto;
  cursor: grab;
`

export const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  margin-bottom: 24px;
  min-width: 550px;
  
  @media (max-width: 1023px) {
    table-layout: auto;
  }
`

export const StyledStickyCell = styled.td`
  position: sticky;
  left: 0;
  background-color: ${({theme}) => theme.lightest};
  border: none;
`

export const StyledTitle = styled(Text)`
  font-weight: 700;
  padding: 24px 0 12px;
  position: sticky;
  left: 0;
`

export const StyledTableHeader = styled.tr`
  background-color: ${({theme}) => theme.light};
  font-weight: 600;

  & td:first-child {
    background-color: ${({theme}) => theme.light};
  }

  @media (max-width: 767px) {
    display: none;
  }
`

export const StyledTableFooter = styled.tfoot`
  background-color: ${({theme}) => theme.light};
  font-weight: 600;

  & td:first-child {
    background-color: ${({theme}) => theme.light};
  }
`

export const StyledTableCol = styled.td`
  padding: 16px;
  vertical-align: top;
  min-width: 160px;

  &:first-child {
    position: sticky;
    left: 0;
    min-width: 200px;
  }
  
  @media (max-width: 1023px) {
    &:first-child {
      background-color: ${({theme}) => theme.lightest};
    }
  }

  @media (max-width: 767px) {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    min-width: 160px;
  }
`

export const StyledTableUsername = styled.span`
  align-self: center;
`

export const StyledTableDescription = styled.span`
  display: none;
  
  @media (max-width: 767px) {
    display: block;
    color: ${({theme}) => theme.dark};
  }
`

export const StyledTablePosition = styled.span`
  background-color: #e6f7ff;
  border: 1px solid ${({theme}) => theme.main};
  border-radius: 2px;
  color: ${({theme}) => theme.main};
  padding: 1px 8px;
  width: max-content;
  
  @media (max-width: 767px) {
    background-color: transparent;
    border-color: transparent;
    color: ${({theme}) => theme.darkest};
    padding: 0;
  }
`