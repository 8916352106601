import { StyledButtonIcon } from './style';

// position - left или right (с какой стороны будет иконка)
// icon - ссылка на файл с иконкой
// size - ширина иконки в px
// color: green | red | light | outlined (если не добавлять атрибут - кнопка будет синяя)

export const ButtonWithIcon = (props) => {
  return (
    <StyledButtonIcon
      position={props.position}
      color={props.color}
      icon={props.icon}
      size={props.size}
      onClick={props.onClick}
    >
      {props.children}
    </StyledButtonIcon>
  );
};
