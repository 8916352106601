import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { Label } from '@shared/components/Label/Label';
import { Textarea } from '@shared/components/Textarea/Textarea';

// инпут куда пишем результат
const ResultTextareaField = (props) => {
  const { editedItemResult, setValue, height } = props;
  const { field } = useController(props);
  const { label, name } = props.settings;

  // отображаем предыдущее значение поля, если мы редактируем
  useEffect(() => {
    if (editedItemResult) {
      setValue(`${name}`, editedItemResult.description);
    }
  }, [editedItemResult, setValue, name]);

  return (
    <div style={{ display: 'grid' }}>
      <Label htmlFor={name}>{label}</Label>
      <Textarea id={name} rows="2" style={{ height: height }} {...field} />
    </div>
  );
};

export default ResultTextareaField;
