import styled from 'styled-components';

export const StyledFormTab = styled.div`
  font-size: 12px;
  padding: 0 8px;
  background-color: ${({ open, theme }) => (open ? theme.successLight : theme.light)};
  border: 1px solid ${({ open, theme }) => (open ? '#b7eb8f' : theme.border)};
  color: ${({ open, theme }) => (open ? theme.success : theme.darkest)};
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  width: max-content;
`;
