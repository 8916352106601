import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import { Modal } from '@shared/components';

import useAuth from '../../hooks/useAuth';
import SnackbarContext from '../../contexts/SnackbarContext';

import FolderVersionsList from './FolderVersionsList';
import FolderVersionDetails from './FolderVersionDetails';

const FolderVersionsModal = ({ close, currentFolderData, renderUserName, userCanWrite, handleFolderSelect }) => {
  useEventTriggerOnEscPress(close);
  const [folderVersions, setFolderVersions] = useState();
  const [isLoadingVersions, setIsLoadingVersions] = useState(true);
  const [folderVersionDetails, setFolderVersionDetails] = useState();
  const [parentFolderStack, setParentFolderStack] = useState([]);
  const auth = useAuth();

  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (currentFolderData) {
      axios
        .get(`/api/storage_folder_history/${currentFolderData.id}`)
        .then((r) => {
          const { storage_folder_history } = r.data;
          setFolderVersions(storage_folder_history);
          setIsLoadingVersions(false);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при получении данных о версиях папки');
          setIsLoadingVersions(false);
        });
    }
  }, [currentFolderData, showSnackbar]);

  if (!auth?.token) return '';
  return (
    <Modal title="История версий" onClose={close} withoutButtons>
      {folderVersionDetails ? (
        <FolderVersionDetails
          folderVersionDetails={folderVersionDetails}
          renderUserName={renderUserName}
          setFolderVersionDetails={setFolderVersionDetails}
          parentFolderStack={parentFolderStack}
          setParentFolderStack={setParentFolderStack}
        />
      ) : (
        <FolderVersionsList
          renderUserName={renderUserName}
          setFolderVersionDetails={setFolderVersionDetails}
          folderVersions={folderVersions}
          currentFolderData={currentFolderData}
          isLoadingVersions={isLoadingVersions}
          parentFolderStack={parentFolderStack}
          setParentFolderStack={setParentFolderStack}
          userCanWrite={userCanWrite}
          onCloseModal={close}
          handleFolderSelect={handleFolderSelect}
        />
      )}
    </Modal>
  );
};

export default FolderVersionsModal;
