import React, { useState } from 'react';

import { Container } from '../Container';

import { StyledWrapper } from './styles';

export const Dropdown = ({ trigger = 'click', overlay, children, width, position = 'left', marginTop }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div style={{ position: 'relative', display: 'inline-flex' }}>
        <Container
          onMouseLeave={trigger === 'hover' ? () => setVisible(false) : undefined}
          onMouseOver={trigger === 'hover' ? () => setVisible(true) : undefined}
          onClick={() => setVisible((prev) => !prev)}
          onBlur={() => {
            setVisible(false);
          }}
        >
          {children}
        </Container>

        <StyledWrapper width={width} position={position} marginTop={marginTop} $visible={visible}>
          {overlay}
        </StyledWrapper>
      </div>
    </>
  );
};
