import { memo, useMemo, useRef, useState, useEffect } from 'react';
import { number, string, object, arrayOf } from 'prop-types';

import { Avatar } from '@shared/components/Avatar';
import LocalDateTime from '@components/shared/LocalDateTime';
import { useTranslation } from 'react-i18next';

import { renderMessage } from 'src/utilize/draftjs-helper-functions';
import FolderLink from '@components/files/FolderLink';
import useAuth from 'src/hooks/useAuth';
import { useFormatMessageFiles } from 'src/utilize/message-helper-functions';
import AttachedFile from '@components/files/AttachedFile';
import ImageGallery from 'src/windows/images/ImageGallery';

import {
  StyledDeletedMessage,
  StyledEditMessageButton,
  StyledMessageAttach,
  StyledMessageBody,
  StyledMessageDate,
  StyledMessageHeader,
  StyledMessageInfo,
  StyledMessageUsername,
  StyledQuote,
  StyledQuoteDate,
  StyledQuoteName,
  StyledReadMoreButton,
  StyledTag,
  StyledTagsWrapper,
  StyledThumbWrapper,
} from '../styles';

import { ForwardedStatus, StyledForwardedBox, StyledForwardedMessage } from './style';

ForwardedMessageDataFC.propTypes = {
  employee_id: number,
  employee_first_name: string,
  employee_last_name: string,
  is_guest: number,
  projectType: string,
  projectData: object,
  date_created: string,
  deleted: number,
  reply_text: string,
  editor_state: string,
  reply_date: string,
  id: number,
  reply_first_name: string,
  reply_last_name: string,
  message_tags: arrayOf(object),
  title: string,
  message_storage_folders: arrayOf(object),
  message_files: arrayOf(object),
  edited: number,
};

function ForwardedMessageDataFC({
  employee_id,
  employee_first_name,
  employee_last_name,
  is_guest,
  projectType,
  projectData,
  date_created,
  deleted,
  reply_text,
  editor_state,
  reply_date,
  id: messageId,
  reply_first_name,
  reply_last_name,
  message_tags,
  title,
  message_storage_folders,
  message_files,
  edited,
}) {
  const { t } = useTranslation();

  const fullName = useMemo(
    () => `${employee_first_name || ''} ${employee_last_name || ''}`,
    [employee_first_name, employee_last_name],
  );

  const localDateCreated = useMemo(() => <LocalDateTime dateTime={date_created} />, [date_created]);

  const messageRef = useRef();

  const [hideableMessage, setHideableMessage] = useState({
    hideable: false,
    display: 'show',
  });

  // если высота сообщения больше 100 пихелей, то свернуть сообщение
  useEffect(() => {
    if (messageRef?.current) {
      const messageHeight = Number(getComputedStyle(messageRef.current).height.slice(0, -2));

      if (messageHeight >= 100) {
        setHideableMessage({
          hideable: true,
          display: 'hide',
        });
      } else {
        setHideableMessage((h) => {
          if (h.hideable) {
            return { hideable: false, display: 'show' };
          }
          return h;
        });
      }
    }
  }, [reply_text, editor_state]);

  const auth = useAuth();

  const [openImgId, setOpenImgId] = useState(null);

  const { allAttachedFiles, gallerySourcesArr } = useFormatMessageFiles(message_files, messageId, auth?.token);

  return (
    <>
      <StyledForwardedBox>
        <ForwardedStatus>Пересланное сообщение</ForwardedStatus>
        <StyledForwardedMessage>
          <div>
            <StyledMessageHeader>
              <Avatar
                userId={employee_id}
                firstName={employee_first_name}
                lastName={employee_last_name}
                projectType={projectType}
                projectId={projectData?.id}
                is_guest={is_guest}
                projectData={projectData}
                showProfileOnClick
              />

              <StyledMessageInfo>
                <StyledMessageUsername>{fullName}</StyledMessageUsername>
                <StyledMessageDate>{localDateCreated}</StyledMessageDate>
                <StyledThumbWrapper>
                  {edited && <StyledEditMessageButton>{t('FormButtons.ed_')}</StyledEditMessageButton>}
                </StyledThumbWrapper>
              </StyledMessageInfo>
            </StyledMessageHeader>

            {deleted === 1 ? (
              <StyledMessageBody>
                <StyledDeletedMessage>Сообщение удалено</StyledDeletedMessage>
              </StyledMessageBody>
            ) : (
              <>
                <StyledMessageBody
                  $state={hideableMessage.display} // показать или скрыть длинное сообщение
                  ref={messageRef}
                >
                  {reply_text && reply_date && (reply_first_name || reply_last_name) && (
                    <StyledQuote style={{ cursor: 'unset' }}>
                      <StyledQuoteName>{`${reply_first_name || ''} ${reply_last_name || ''} `}</StyledQuoteName>{' '}
                      <StyledQuoteDate>
                        <LocalDateTime dateTime={reply_date} />
                      </StyledQuoteDate>
                      <div>{reply_text}</div>
                    </StyledQuote>
                  )}

                  {Boolean(message_tags?.length) && (
                    <StyledTagsWrapper>
                      {message_tags.map((tag) => {
                        return (
                          <StyledTag key={tag.id} color={tag.color}>
                            {tag.name}
                          </StyledTag>
                        );
                      })}
                    </StyledTagsWrapper>
                  )}

                  {title && (
                    <div>
                      <strong>{title}</strong>
                    </div>
                  )}

                  {/*<MessagePoll />*/}

                  {renderMessage(editor_state)}
                </StyledMessageBody>

                {hideableMessage.hideable && (
                  <StyledReadMoreButton
                    onClick={() => {
                      if (messageRef?.current) {
                        setHideableMessage((h) => ({
                          ...h,
                          display: h.display === 'hide' ? 'show' : 'hide',
                        }));
                      }
                    }}
                  >
                    {t(`FormButtons.${hideableMessage.display === 'hide' ? 'read_all' : 'fold'}`)}
                  </StyledReadMoreButton>
                )}

                {/* Отображение файлов, прикрепленных к этому сообщению */}

                {message_storage_folders?.map((folderData) => (
                  <StyledMessageAttach key={folderData.id}>
                    <FolderLink folderData={folderData} />
                  </StyledMessageAttach>
                ))}

                {message_files?.length > 0 && (
                  <StyledMessageAttach>
                    {allAttachedFiles?.map((file) => {
                      return (
                        <AttachedFile
                          fileUrl={file.url}
                          fileName={file.file}
                          fileId={file.id}
                          key={file.id}
                          storageFileId={file.from_storage}
                          setOpenImgId={setOpenImgId}
                          contextMenu={['storageTransfer']}
                          creator_id={employee_id}
                        />
                      );
                    })}
                  </StyledMessageAttach>
                )}
              </>
            )}
          </div>
        </StyledForwardedMessage>
      </StyledForwardedBox>

      {openImgId && gallerySourcesArr?.length && (
        <ImageGallery imgItems={gallerySourcesArr} openImgId={openImgId} close={() => setOpenImgId(null)} />
      )}
    </>
  );
}

const ForwardedMessageData = memo(ForwardedMessageDataFC);

export default ForwardedMessageData;
