import Resumable from 'resumablejs';
import useAuth from 'src/hooks/useAuth';
import { server_url } from 'src/settings/base-url';

import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { getStorageData, getStorageTree } from 'src/redux/features/storageSlice';

import { getQueryData } from './lib';

export const useResumable = ({ storageId, folderId, setFolderUploadStatus, onClose }) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  const queryData = getQueryData({ storageId, folderId });

  const resumable = new Resumable({
    target: `${server_url}/api/storage_folder/add?token=${token}`,
    testTarget: `${server_url}/api/storage_folder/test_chunk?token=${token}`,
    testChunksParams: { ...queryData },
    chunkSize: 2 * 1024 * 1024,
    throttleProgressCallbacks: 100,
    query: queryData,
    simultaneousUploads: 1,
  });

  resumable.on('fileProgress', (currentFile) => {
    const currentProgress = parseInt(Math.round(currentFile.progress() * 100));
    setFolderUploadStatus((prev) => ({ ...prev, progress: currentProgress }));
  });

  resumable.on('complete', function () {
    setTimeout(() => {
      dispatch(getStorageData({ storageId, showSnackbar }));
      dispatch(getStorageTree({ storageId, showSnackbar }));
      onClose();
    }, 1000);
  });

  resumable.on('error', function (message, file) {
    showSnackbar('Возникла ошибка при загрузке папки', 'error');
  });

  return resumable;
};
